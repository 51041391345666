import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  TextField,
} from "@material-ui/core";
import BrandedCardTitle from "./BrandedCardTitle";
import { useDropzone } from "react-dropzone";
import dropIcon from "../../../images/Breefly_Icons-DragDrop_Crop.svg";
import {
  faUpload
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearIndeterminate from '../../Briefs/components/LinearIndeterminate';
import SnackBarMessage from '../../SignIn/components/RegisterSnackBar';
import spaceShip from  '../../../images/spaceship.svg'

export default function BulkSubscriberForm({
  openBulk,
  setBulkOpen,
  apiManager,
  getData,
  org,
  classes,
  forTeam,
  seats,
  user,
  setOuterSnack,
}) {

  //Form variables and handler
  const [fileToProcess, setFileToProcess] = useState('');
  const [bulkNote, setBulkNote] = useState('');
  const [processing, setProcessing] = useState(false);
  const [isSeatsError, setSeatsError] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const handleChange = event => {
    setBulkNote(event.target.value)
  }

  //validate form and disable/enable submit button
  const [disabled, setDisabled] = useState(true)
  const validateForm = () => {
    if(fileToProcess.length > 0){
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }
  useEffect(() => {
    validateForm();
  }, [fileToProcess]);

  const [snack, setSnack] = useState({isOpen: false, isSuccessfull: true, message: ''});
  const [forceRefresh, setForceRefresh] = useState(false);

  const closeSnack = () => {
    setSnack({isOpen: false, isSuccessfull: false, message: ''});
    setForceRefresh(!forceRefresh);
    setBulkOpen(false);
  }

  const openSnack = (isSuccessfull, message) => {
    setSnack({isOpen: true, isSuccessfull, message});
  }

  const bulkInviteAffiliates = async() => {
    // const inviteData = fileToProcess[0];
    const note = bulkNote;
    // const response = await apiManager.affiliateBulkInvite(inviteData, note, org._id);
   // setBulkOpen(false)
   setProcessing('uploading');
   let csvFile = fileToProcess[0];
  
   let s3Info = {};
   if (csvFile && csvFile.type) {

       csvFile.dataType = 'bulkInvite';
       csvFile.dataSubType = forTeam ? 'teamMembers' : 'affiliates';
       csvFile.note = note;
       s3Info = await apiManager.getUploadKey(csvFile);

       await apiManager.uploadToS3(s3Info.url, csvFile);

       const fileInfo = await apiManager.processCSV(s3Info.assetId, org._id, org.stats.usedSeats, org.seats.count);
      
        if(forTeam) {
            if( fileInfo.processed.error && !fileInfo.processed.seats) {
                let message = fileInfo.processed.message;
                if( fileInfo.processed.badPeople && fileInfo.processed.badPeople.length > 0) {
                    message = 'Sorry, there were errors found in the csv file uploaded. ';
                    let emails = 0;
                    let names = 0;
                    fileInfo.processed.badPeople.forEach(person => {
                        if(person.message.includes('email')) emails++;
                        if(person.message.includes('name')) names++;
                    })
                    if(emails > 0) {
                        message += `Email formatting issues: ${emails}. `;
                    }
                    if(names > 0 ) {
                        message += `Missing name issues: ${names}.`
                    }
                }
                openSnack(false, message);
            } else if (fileInfo.processed.error && fileInfo.processed.seats){
              setSeatsError(fileInfo.processed)
            } else{
              setSuccess(true)
            }
        } else {
            setOuterSnack({isOpen: true, isSuccessfull: fileInfo.error === false, message: fileInfo.message});
            setBulkOpen(false)
        }
        setProcessing(false);
     //  await apiManager.getAsset(s3Info.assetId);

   }
  }

  const downloadTemplate = () => {
    const csvTemplate = forTeam
        ? ["First Name","Last Name","Email","Role (enter 'Admin' or leave empty for Team Members)"] //set the data
        : ["First Name","Last Name","Email","Company ID","User ID"]; //set the data
        //: ["First Name","Last Name","Email","Company ID","User ID","Promo Code"]; //with promocode
  
    let csvContent =  "data:text/csv;charset=utf-8," + csvTemplate //combine data with doc type, use map if more than one row
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute( "download", 
        forTeam 
        ? "breefly_team_upload_invitation_template.csv"
        : "subscribers_upload_invitation_template.csv"
        ); //change file name here
    document.body.appendChild(link);
    link.click() //trigger download
  }


//Dropzone uploader
  const RenderUploadFile = props => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections
    } = useDropzone({
      accept: ".csv",
      onDrop: acceptedFiles => {
        setFileToProcess(acceptedFiles);
      }
    });
    return (
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        {...getRootProps({})}
        className="file-upload-holder"
      >
        <input {...getInputProps()} />
        <Grid item xs={12} style={{ paddingBottom: 12 }}>
          {/* <FontAwesomeIcon icon={faPlusCircle} /> */}
          {/* <FontAwesomeIcon icon={faUpload} style={{ fontSize: 30 }} /> */}
          <img src={dropIcon} width={56} height={56} />
        </Grid>
        <Grid item xs={12}>
          <span>Drag and drop here to upload</span>
          <br />
          <span style={{ color: "#6F6F6F", fontSize: 10 }}>CSV files only</span>
        </Grid>
        <Grid item xs={12} style={{ margin: "16px 0px" }}>
          <Button color="primary" variant="contained" className={"brandColor2"}>
            <FontAwesomeIcon icon={faUpload} style={{ marginRight: 8 }} />
            Or choose your files
          </Button>
          {fileToProcess.length > 0 ? (
            <div style={{marginTop: 24}}>
            <span>Current file: {fileToProcess[0].name}</span>
            </div>
          ) : ''
          }
        </Grid>
      </Grid>
    );
  };
  
  const [cardTitle, setCardTitle] = useState('Send a Group List Invite')
  useEffect(() => {
    //card title logic goes here
    if(!!isSeatsError){
      setCardTitle("Let's grow your team")
    } else if(!!isSuccess) {
      setCardTitle("We're on it!")
    }else{ 
      setCardTitle("Send a Group List Invite")
    }
  },[isSeatsError, isSuccess])
  const renderSendInvites = () => {
    return(
    <>
            <div style={{ marginBottom: 20 }}>
            <span style={{ textAlign: "center" }}>
              Use this <a onClick={downloadTemplate}>template</a> for list formatting, then
            </span>
          </div>
          <RenderUploadFile />
          {!forTeam && <hr />}
           {!forTeam && <div className="note-field">
              <span style={{ marginTop: 24 }}>
                Add a personal message below
              </span>
              <TextField name="note" label="Note" variant='outlined' placeholder="Add a message" multiline rows={3} fullWidth value={bulkNote} onChange={handleChange} style={{marginTop: 24}}/>
          </div>
          }
          </>
    )
  }
  const [seatsToAdd, setSeatsToAdd] = useState(0);
  const [currentSub, setCurrentSub] = useState('')
  useEffect(() => {
    const sub = {...user.subscriptions.filter(el => el.active === 1)[0]};
    setCurrentSub(sub)
  },[])
  const stripeCrossgradeSubscription = async (currentSub, newSeats) => {
    setProcessing(true);
    const subId = currentSub.session.id
    const stripePrice = currentSub.session.plan.id;
    const quantity = currentSub.session.quantity
    //final quantity is the subscriptions quantity (seats / 5) + new number of seats / 5 rounded up 
    const finalQuantity = quantity + Math.ceil(newSeats / 5)
    console.log(subId, quantity, finalQuantity)
      try {
        await apiManager.crossgradeStripeSubscription(subId, stripePrice, stripePrice, finalQuantity);
        setTimeout(async () => {
          const stripeSub = await apiManager.getSubscription();
          console.log('new stripe sub', stripeSub)
         
          //make sure the new quantity is correct, if so, show success and redo the upload
          if(stripeSub && stripeSub.session.quantity === finalQuantity){
            const result = await apiManager.reAuthenticate({email: user.email})
            setProcessing(false)
            setSeatsError('')
            openSnack(true, 'Upgrade success. Seats added. Please try uploading your team list again.')
            getData();
          } else {
             //otherwise throw and error message with snackbar
             setProcessing(false)
             openSnack(false, 'There was an error upgrading your subscription. Please try again or contact support@breefly.com')
          }
        }, 5000);
      } catch(e) {
        console.log('upgrade error', e.message);
        openSnack(false, 'There was an error upgrading your subscription. Please try again or contact support@breefly.com')
        setProcessing(false);
      }
    //}
  };
  const handleSeatChange = e => {
    let num = Number(e.target.value)
      setSeatsToAdd(num)
  }
  const renderSeatsError = () => {
    const amt = currentSub.session.quantity + Math.ceil(seatsToAdd / 5)
    const price = user.hostOrgId ? (225 * amt) : (295 * amt);
    
    return(
      <>
      <div className="teamSeatError">
        {`To invite the full list of users to join your team, you’ll need to add ${Math.ceil(isSeatsError.seats / 5) * 5} seat${isSeatsError.seats > 1 ? 's' : ''} to your account as they are sold in bundles of 5.`}
      </div>
      <div className="teamSeatError teamSeatError__bold">
        Type the number of seats below to add them
      </div>
      <form className="teamSeatError">
        <TextField 
          id="outlined-basic" 
          label="Seats" 
          variant="outlined" 
          helperText={`New monthly subscription: $${price}`}
          value={seatsToAdd}
          onBlur={e => setSeatsToAdd(Math.ceil(seatsToAdd / 5) * 5)}
          inputProps={{
            step: 5
          }}
          onChange={handleSeatChange}
          type="number"
        />
      </form>
      {user.orgRole !== 'owner' && <div className="teamSeatError" style={{color: "var(--brand-color2)"}}>Only Team Owners can add seats</div>}
      </>
    )
  }
  const renderSuccess = () => {
    return(
      <div className="teamSeatSuccess">
        <div className="teamSeatSuccess__text">Your invitations will go out shortly. You can monitor team member sign ups via the Team Management link in the main navigation bar.</div>
        <img src={spaceShip} />
      </div>
    )
  }
  const handleClose = () => {
    getData();
    setBulkOpen(false)
    
  }
  return (
    <Dialog
      open={openBulk}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xs"}
      aria-labelledby="Team Alert"
    >
      <Card className={`branded card`}>
        <BrandedCardTitle title={cardTitle} />
        {processing
        ?
        <> 
                <div style={{minHeight: 300, padding: 24, textAlign: 'center', marginTop: 100}}>
                    <LinearIndeterminate />
                    <br />
                    {processing}
                </div>
        </>
        :
        <>
        <CardContent className="file-upload-card-content">
          {!!isSeatsError ? 
            renderSeatsError() 
            : 
            !!isSuccess ? 
              renderSuccess() 
              : 
              renderSendInvites()
          }
        </CardContent>

        {!isSuccess && <DialogActions>
          <Button onClick={() => 
            {
              setBulkOpen(false); 
              setFileToProcess('')
              }
          }
          className="cancel-button">
            Cancel
          </Button>

          {!!isSeatsError ? 
            <Button
              onClick={() => stripeCrossgradeSubscription(currentSub, seatsToAdd)}
              color="primary"
              variant="text"
              disabled={isSeatsError.seats > seatsToAdd || user.orgRole !== 'owner'}
              className={isSeatsError.seats > seatsToAdd || user.orgRole !== 'owner' ? "no-upload" : ""}
            >
              Add Seats
            </Button>
            :
            <Button
              onClick={bulkInviteAffiliates}
              //color={ "primary" }
              variant="text"
              disabled={disabled}
              className={disabled ? "no-upload" : ""}
            >
              Send Invitations
            </Button> 
          }
        </DialogActions> }
        </>
        }
      </Card>
      <SnackBarMessage  snack={snack} closeModal={closeSnack} classes={classes} closeTime={15000} />
    </Dialog>
  );
}
