import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

import {
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import TermsAndConditions from "../Modals/TermsAndConditions";
import RegisterSnackBar from "../SignIn/components/RegisterSnackBar";

import axios from "axios";
import { API_LOCAL, FAKE_ORGANIZATION, BREEFLY_LOGO } from "../../constants";
import "../SignUp/css/style.css";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";

// import image from './logo.png';
import LocalSettings from "../../services/LocalSettings";

// import defaultPortalImage from "../../images/pma-portal.jpg";
// import defaultPortalLogo from "../../images/pma-logo.png";
// import LoadingIndicator from "../../components/LoadingIndicator";
import teamSubPortalImage from "../../images/SignUp/signup-image-02.png";
import teamSinglePaymentPortalImage from "../../images/SignUp/signup-image-03.png";

import GDPR from "../../layouts/Main/components/GDPR";
import "./css/style.scss";
import individualTop from "../../images/SignUp/individualTop.png";
import teamTop from "../../images/SignUp/teamTop.png";
import person from "../../images/SignUp/Person.png";
import airplane from "../../images/SignUp/Airplane.svg";
import messages from "../../images/SignUp/Messages.svg";
import MuiAlert from '@material-ui/lab/Alert';
import eventTracker from "../../utils/event-tracker";
import { getParameterByName } from '../../utils/utils';
import Notification from "../Modals/Notification";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const logoImage = BREEFLY_LOGO;

const WelcomeDialog = props => {
  const { open, setOpen, branding } = props;
  let hl, par, but;
  let el = document.createElement("html");
  el.innerHTML = branding;
  hl = el.getElementsByTagName("h2");
  par = el.getElementsByTagName("p");
  but = el.getElementsByTagName("button");
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="welcome-title"
      PaperProps={{
        style: {
          backgroundColor: "#ffffffe3",
          maxWidth: 500,
          textAlign: "center",
          margin: "16px 24px",
          fontSize: 14
        }
      }}
    >
      <DialogTitle id="welcome-title">{hl[0].innerText}</DialogTitle>
      <DialogContent>
        <DialogContentText className="sign-up-text">
          {par[0].innerText}
          <br />
          <br />
          <p>
            Please enter a team name (or use the one provided), email address
            and password to complete the sign up process.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          className="sign-up-portal"
          color="primary"
          onClick={() => setOpen(false)}
          style={{ margin: 16 }}
        >
          {but[0].innerText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const schema = {
  firstname: {
    presence: { allowEmpty: false, message: "^First name is required" },
    length: {
      maximum: 32
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: "^Last name is required" },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 128
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20,
      minimum: 8
    }
  },
  confirm: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20,
      minimum: 8
    },
    equality: {
      attribute: 'password',
      message: '^Passwords do not match'
    }
    
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true
  },
  accounttype: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 1
    }
  },
  teamname: {
    presence: { allowEmpty: false, message: "^Team name is required" },
    length: {
      maximum: 50,
      minimum: 10
    }
  }
};

const schemaAffiliate = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 128
    }
  },
  teamname: {
    presence: { allowEmpty: false, message: "^Team name is required" },
    length: {
      maximum: 50,
      minimum: 10
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20,
      minimum: 6
    }
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    "& .MuiLink-root": {
      color: "#22b9ea"
    },
    "& .MuiTypography-gutterBottom": {
      marginBottom: 24
    },
    "& .MuiButton-containedPrimary": {
      backgroundColor: "#22b9ea !important",
      // width: '80%',
      // marginLeft: '20%'
      maxWidth: 225
    },
    "& .MuiButton-label": {
      color: "#eee !important"
    }
  },
  grid: {
    height: "100%",
    alignCenter: "center"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  contentContainer: {},
  content: {
    height: "90%",
    // margin: 'auto',
    textAlign: "center",
    maxWidth: "700px"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginTop: 20,
    marginBottom: 15
  },
  tagLine: {
    color: "#333",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    marginBottom: 25,
    color: "var(--brand-color2) !important",
    "@media (max-width: 820px)": {
      fontSize: 14,
      marginBottom: 15
    }
  },
  choose: {
    color: '#888',
    width: '100%',
    textAlign: 'left',
    marginBottom: 22,
  },
  formMessage: {
    color: '#888',
    width: '100%',
    fontSize: 10,
    textAlign: 'left',
    paddingLeft: 8,
    paddingTop: 12,
  },
  panelSpacer: {
    width: 1,
    height: "10vh",
    minHeight: 48
  },
  contentBody: {
    flexGrow: 1,
    // display: 'flex',
    width: "90vw",
    // minWidth: 300,
    maxWidth: 450
  },
  form: {
    // borderColor: '#00BFFF',
    // borderStyle: 'solid',
    // borderWidth: 'medium',
    // paddingTop: 50,
    // borderRadius: "8px",
    // textAlign: "center",
    // alignItems: "center",
    // position: "relative",
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingBottom: 50,
    // [theme.breakpoints.down("sm")]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // },
    // background: "#fff"
  },
  title: {
    marginTop: theme.spacing(3)
  },
  policy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "4px 0px"
  },
  policyCheckbox: {
    // marginLeft: '-14px'
    fontSize: 12
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  pageBody: {
    backgroundColor: "#000",
    height: "100%"
  },
  emailField: {
    marginBottom: 24
  },
  textField: {
    maxWidth: 225,
    // fontSize: 12,
    marginTop: theme.spacing(2),
    // '& .MuiInputLabel-root':{
    //   fontSize: 12
    // },
    // '& .MuiInputBase-root':{
    //   fontSize: 12
    // },
    "@media (max-width: 820px)": {
      maxWidth: 280
    },
    welcomeModal: {
      backgroundColor: "#000000bf"
    }
  }
}));
// Stripe Config
const { products, STRIPE_PUBLIC_KEY } = require('../../constants');

const SignUp = props => {
  const { history, updateUser, apiManager, isRemoved, isExpired, isSuspended } = props;
  
  const token = sessionStorage.getItem("token");
  const useFullImages = true;
  const [defaultUserType, setDefaultUserType] = React.useState(false);

  const [isModalOpen, setOpen] = React.useState(false);
  const [isRemovedModalOpen, setRemovedOpen] = React.useState(true);
  const [message, setMessage] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccessfull, setSuccess] = React.useState(false);
  const classes = useStyles();
  const [inviteId, setInviteId] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [isTeam, setTeam] = useState(true);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);
  const [isAffiliate, setAffiliate] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [notificationOpen, setNoficationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  // const [branding, setOrgBranding] = useState({});
  const [branding, setBrandSettings] = useState(
    LocalSettings.defaultSettings.branding
  );
  useEffect(() => {
    const color1 = branding.primary;
    const color2 = branding.secondary;

    if (color1 && color2) {
      const rootDoc = document.documentElement;
      rootDoc.style.setProperty("--brand-color1", color1);
      rootDoc.style.setProperty("--brand-color2", color2);
    }
  }, [branding]);

  const [init, setInit] = useState(false);
  const validateToken = async () => {
    const url = API_LOCAL + "users/auth/token";
    const result = await axios.post(url, { token });

    if (result && result.data && result.data.email) {
      setFormState(formState => ({
        ...formState,
        isSuccessfull: true,
        isModalOpen: true,
        snackMessage: "Sign Up Successful"
      }));
      setMessage("Sign up successful");
      setOpen(true);

      await updateUser(result.data);
      // updateIntercom(result.data);
      sessionStorage.setItem("token", result.data.token);

      const { search } = history.location;
      let redirect = "/briefs";
      if (search && search.indexOf("?redirect=") > -1) {
        // redirect = search.replace("?redirect=", "");
      }
      if(!isRemoved){
        history.push(redirect);
      }
      //window.location = redirect;
    }
  };
  // look for a paramter or
  //look for a token on load
  useEffect(() => {
    const param = getParameterByName('t');
    const resellerCode = getParameterByName('rc');
    setDefaultUserType(param);
    if(param) {
      const accountType = getAccountData(null, param);
      if(accountType.active  && accountType.param === param) {
        // double check!
        // set the default account type;
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            accounttype: accountType.value
          },
        }));
      }
    }

    if (resellerCode) {
      validateResellerCode(resellerCode);
    }

    if(token && !isLoading){
      const orgId = sessionStorage.getItem("organizationId");
      const orgBranding = sessionStorage.getItem("orgBranding");
      sessionStorage.clear();
      if (orgId) {
        sessionStorage.setItem("organizationId", orgId);
      }
      if (orgBranding) {
        sessionStorage.setItem("orgBranding", orgBranding);
      }
      validateToken();
    }
  },[]);

  const validateResellerCode = async (code) => {
    const response = await apiManager.getPromoCode(code);
    ;
    const isValid = response && response.code;
    if (isValid) {
      if (response.hasExpired) {
        setNotificationMessage(`We're sorry but promo code ${code} has expired. Please contact your reseller for a new code.`);
        setNoficationOpen(true);
      } else {
        setIsReseller(code);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            promocode: code
          },
        }));
      }
    } else {
      setNotificationMessage(`We're sorry but the promo code ${code} isn't currently active at Breefly. Please contact your reseller for a new code.`);
      setNoficationOpen(true);
   
    }
  }

  
  // get invite id.. or if not valid, return to breefly view
  const tryToLoadBrand = async () => {
    // see if a parameter
    const urls = history.location.pathname.split("/");
    let orgId = urls[urls.length - 2].replace("#", "");
    let memberId = null;
    if (orgId && FAKE_ORGANIZATION) {
      orgId = "5f0cb1f4fa81270007c41ca6";
    }
    if (orgId && orgId !== "signup") {
      setInviteId(orgId);

      setOrgId(orgId);

      // If a memberId URL path item exists set the state's memberId,
      // cause this signup is an affiliate invite
      if (urls.length === 4) {
        memberId = urls[urls.length - 1];
        setMemberId(memberId);
        setAffiliate(true);

        // Set up invite message (different message for members of existing teams than affiliates)
      }

      setIsLoadingBrand(true);

      // NOTE USED????: Get brief invite
      // const inviteData = await apiManager.getInvite(orgId);
      const brandData = await apiManager.getPortalBranding({
        key: "affiliate",
        orgId,
        memberId
      });

      if (
        brandData &&
        brandData.branding &&
        brandData.branding.portalbg &&
        brandData.branding.portallogo
      ) {
        brandData.branding.html = brandData.html || {};

        // Set branding to this org's logo and background
        // setBrandSettings(orgBranding);
        setBrandSettings(brandData.branding);
      }

      // Set up if a team... if existing team, display team name, otherwise, show empty team field
      setTeam(true);

      setIsLoadingBrand(false);
    } else {
      let response = await apiManager.getSignInBranding();
      if (response && response.branding) {
        response.branding.html = response.html || {};
      }
      response && response.branding && setBrandSettings(response.branding);
      setIsLoadingBrand(false);
    }
  };

  // validate it, then set it.  if not valid, switch to generic sign in portal.
  useEffect(() => {
    if (inviteId === false || inviteId === 'false') {
      const id = tryToLoadBrand();
    }
  }, [inviteId]);

  const accountInformation = [
    {
      active: false,
      param: 'ind',
      display: 'Individual',
      value: 'individual',
      image: '',
      message: 'Monthly subscription for individuals',
      productName: 'individualPlan',
    },
    {
      active: true,
      param: 'tsp6x5',
      display: 'Single Payment',
      value: 'teamSinglePayment',
      image: teamSinglePaymentPortalImage,
      message: 'Enjoy a 14-day free trial. Then a one time payment for six months of access. No credit card required. Cancel anytime.',
      productName: 'singlePrice6x5',  // team single payment 6 months x 5 seats TODO: John Dawes fix in constants.js
    },
    {
      active: true,
      param: 'tm',
      display: 'Monthly Subscription',
      value: 'team',
      image: teamSubPortalImage,
      message: 'Enjoy a 14-day free trial. Billed monthly after. No credit card required. Cancel anytime.',
      productName: 'standAloneOrgPlan',
    },
   
  ];

  const getAccountData = (type, param = null) => {
    let info = accountInformation[0];
    accountInformation.forEach(account => {
      if (type === account.value || account.param === param) {
        info = account;
      }
    })
    return(info);
  }


  const [formState, setFormState] = useState({
    isValid: false,
    values: { accounttype: "teamSinglePayment" },
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  useEffect(() => {
    const errors = validate(
      formState.values,
      isAffiliate ? schemaAffiliate : schema
    );

    formState.values.accounttype !== "individual" ? setTeam(true) : setTeam(false);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      isModalOpen: false
    }));
  };
  const isLength = (value, min) => {
    return value && value.length >= min;
  };

  // const updateIntercom = user => {
  //   window.intercomSettings = {
  //     app_id: "zcl62xdv",
  //     name: `${user.firstname} ${user.lastname}`,
  //     email: `${user.email}`,
  //     created_at: `${user.created}`
  //   };
  //   window.Intercom("boot", window.intercomSettings);
  //   sessionStorage.setItem("intercom", JSON.stringify(window.intercomSettings));
  // };

  const validateForm = () => {
    let isSuccess = true;
    let msg = `Invalid form data, please fix and try again`;

    const {
      firstname,
      lastname,
      email,
      password,
      confirm,
      policy,
      accounttype,
      teamname
    } = formState.values;

    isSuccess =
      isLength(firstname, 1) &&
      isLength(lastname, 1) &&
      isLength(email, 4) &&
      isLength(password, 8) &&
      policy === true &&
      isLength(accounttype, 1);

    let errorCount = 0;

    if (!isLength(firstname, 1)) {
      formState.touched["firstname"] = true;
      errorCount++;
    }

    if (!isLength(lastname, 1)) {
      formState.touched["lastname"] = true;
      errorCount++;
    }

    if (!isLength(email, 4)) {
      formState.touched["email"] = true;
      errorCount++;
    }

    if (!policy) {
      formState.touched["policy"] = true;
      errorCount++;
    }

    if ((!isLength(accounttype), 1)) {
      formState.touched["accounttype"] = true;
      errorCount++;
    }
    if (accounttype !== "individual" && !isLength(teamname, 8)) {
      formState.touched["teamname"] = true;
      errorCount++;
    }

    if (!isLength(password, 6)) {
      formState.touched["password"] = true;

      formState.touched["confirm"] = true;

      if (errorCount === 0) {
        msg = `Password must be at least 8 alpha numeric characters`;
      }
    }

    if (password !== confirm) {
      formState.touched["confirm"] = true;
      formState.touched["password"] = true;

      formState.errors["password"] = ["Passwords do not match"];
      formState.errors["confirm"] = ["Passwords do not match"];

      if (errorCount === 0) {
        msg = `Passwords do not match`;
      }
      isSuccess = false;
    }

    setFormState(formState);

    if (!isSuccess) {
      setSuccess(false);
      setLoading(false);
      setMessage(msg);
      setOpen(true);
    }

    return isSuccess;
  };

  const validateAffiliateForm = () => {
    let isSuccess = true;
    let msg = `Invalid affiliate form data, please fix and try again`;
    const { email, teamname, password, policy } = formState.values;

    isSuccess =
      isLength(teamname, 1) && isLength(password, 6) && policy === true;

    let errorCount = 0;

    if (!isLength(email, 4)) {
      formState.touched["email"] = true;
      errorCount++;
    }

    if (!isLength(teamname, 1)) {
      formState.touched["teamname"] = true;
      errorCount++;
    }

    if (!isLength(password, 1)) {
      formState.touched["password"] = true;
      errorCount++;
    }

    if (!policy) {
      formState.touched["policy"] = true;
      errorCount++;
    }

    setFormState(formState);

    if (!isSuccess) {
      setSuccess(false);
      setLoading(false);
      setMessage(msg);
      setOpen(true);
    }

    return isSuccess;
  };
  //delay to wait for webhook. pass in delay in millisecs
  const delay = async (ms) => {
    return await new Promise(resolve => setTimeout(resolve, ms))
  }
  //checks for webhook to complete 3 times
  const checkForStripeUpdate = async () => {
      let stripeSub;
      let stripeID;
      let count = 1;
      while (count < 3){
        await delay(1000 * count);
        stripeSub = await apiManager.getSubscription();
        stripeID = stripeSub && stripeSub.active && stripeSub.session && stripeSub.session.plan && stripeSub.session.plan.id ? stripeSub.session.plan.id : '';
        console.log(`try ${count} `, stripeID)
        if(stripeID){
          count = 5
          return stripeID
        }
      }
  };

  const registerClick = async () => {
    const formValidate = isAffiliate ? validateAffiliateForm() : validateForm();
    const accountInfo = getAccountData(formState.values.accounttype);
   
    let nonAffiliateProductPlan = ''
    if (!isAffiliate) nonAffiliateProductPlan = products[accountInfo.productName];

    if (formValidate) {
      setLoading(false);
      const url =
        API_LOCAL +
        (isAffiliate ? "organizations/affiliates/create" : "users/auth/signup");

      try {
        setLoading(true);
        setSuccess(false);

        if (isAffiliate) {
          formState.values.memberId = memberId;
          formState.values.orgId = orgId;
        }

        formState.values.email = formState.values.email.toLowerCase();

        if (formState.values.accounttype === "individual") {
          delete formState.values.teamname;
        }

        const result = await axios.post(url, formState.values, {
          headers: apiManager.getHeaders()
        });

        if (result && result.data && result.data.email) {

          sessionStorage.setItem("token", result.data.token);

          // TODO: John Dawes.. need to update this part to handle at least 3 products: individual, team (monthly), teamSinglePayment-6x5 (six months, five seats)
          const price = isAffiliate ? products.affiliateOrgPlan : nonAffiliateProductPlan;
        
          // Use the user.trialength in db / Otherwise ==> get default system trial length (from settings key: 'trial-length' and accounttype)
          // TODO: John Dawes.. need to update this part to handle accountType ===  teamSinglePayment
          const trial = result.data.triallength ? result.data.triallength : await apiManager.getTrialLength(formState.values.accounttype);

          //create the subscription
          const subscriptionResult = await apiManager.createStripeSubscription(price, 1, trial);
          
          //make sure it goes through, wait for the webhook to do its work
          const webhookFinished = await checkForStripeUpdate();
          
          //reautenticate so that the payload includes new subscriptions or teams
          const updatedUser = await apiManager.reAuthenticate({email: result.data.email})
          
          //Log signIn event
          eventTracker('Account Created', result.data)
          
          //update the user data at the route level so its passed through app
          const complete = await updateUser(updatedUser);
          sessionStorage.setItem('token', updatedUser.token);
          // updateIntercom(updatedUser);
          setLoading(false);

          if (complete){
            setFormState(formState => ({
              ...formState,
              isSuccessfull: true,
              isModalOpen: true,
              snackMessage: "Sign Up Successful"
            }));
            setMessage("Sign Up Successful");
            setSuccess(true);
            setOpen(true);

            const { search } = history.location;
            let redirect = "/briefs";
            if (search && search.indexOf("?redirect=") > -1) {
              //redirect = search.replace("?redirect=", "");
            }

            // window.location = redirect;
            history.push(redirect);
          }
        } else {
          let errorMessage = "Network error";
          if (result && result.data && result.data.msg) {
            errorMessage = result.data.msg;
          } else if (result && result.data && result.data.message) {
            errorMessage = result.data.message;
          }
          setFormState(formState => ({
            ...formState,
            error: "Incorrect user name and/or password",
            isSuccessfull: false,
            isModalOpen: true,
            snackMessage: errorMessage
          }));
          setMessage(errorMessage);
          setSuccess(false);
          setOpen(true);
          setLoading(false);
        }
      } catch (ex) {
        console.log(ex);
        setFormState(formState => ({
          ...formState,
          error: ex.message,
          isSuccessfull: false,
          isModalOpen: true,
          snackMessage: ex.message
        }));
        setSuccess(false);
        setMessage(ex.message);
        setOpen(true);
        setLoading(false);

      }
    }
  };

  const closeModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSuccess(true);
  };


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [alertOpen, setAlertOpen] = useState(false);


  const [randomTeam, setRandomTeam] = useState(
    "BreeflyTeam+" + Math.round(Math.random() * (100000 - 10000) + 10000)
  );

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        teamname: randomTeam
      }
    }));
    setAlertOpen(true);
  }, []);



  

  const renderPortalImage = () => {
    const accountInfo = getAccountData(formState.values.accounttype);
    const portalImage = accountInfo.image;
   //;
    
    return (
      <div
        className="rightPanel"
        style={{
          backgroundImage: `url(${
            branding && branding.portalbg
              ? branding.portalbg
              : portalImage
          })`
        }}
      >

        {!portalImage && (
        <div style={{ width: "100%" }}>
          
          <img src={person} className="person"></img>
          <div
            className={
              isTeam
                ? "marketing-message marketing-message-team"
                : "marketing-message marketing-message-individual"
            }
          >          
          <img src={messages} className="messages"></img>

            <img
              src={
                isTeam ? teamTop : individualTop
              }
              className={
                isTeam
                  ? "cartoon-image cartoon-image-team"
                  : "cartoon-image cartoon-image-individual"
              }
            ></img>
            <p className="insideTypeMsg">
              {isTeam
                ? "How Modern Teams Are Sharing Insights"
                : "Insights Reporting For The Modern Workforce"}{" "}
            </p>
            <img src={airplane} className="airplane"></img>
          </div>
        </div>
        )}

        {branding.html && branding.html.portalMessage && (
          <WelcomeDialog
            open={alertOpen}
            setOpen={setAlertOpen}
            branding={branding.html.portalMessage}
          />
        )}

        {branding.portallogo && (
          <img
            src={branding.portallogo}
            alt="organization logo"
            className="orgLogo"
          />
        )}
      </div>
    );
    //}
  };

  const renderSignUpPanel = () => {
    const accountInfo = getAccountData(formState.values.accounttype);
    const formMessage = accountInfo.message;

    return (
      <div className="leftPanel">
        <form className={`${classes.form} sign-up-form`}>
          <img
            src={logoImage}
            alt="Breefly.com"
            width="150px"
            align="middle"
            className={classes.logoImage}
          />
          <div className={classes.tagLine}>Insights > Action</div>
          <div className={classes.choose}>{!isLoading ? 'Select a plan:' : (<>&nbsp;</>)}</div>
          {isLoading ? <>
            <CircularProgress color="primary" /> </> : (<>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            className="accountType"
            required
          >
            <InputLabel id="select-account-type-label">
              {isAffiliate ? "Account type" : "Select an account type"}
            </InputLabel>
                <Select
                  labelId="select-account-type-label"
                  id="select-account-type"
                  value={formState.values.accounttype}
                  onChange={handleChange}
                  name="accounttype"
                  label="Select an account type"
                  placeholder="Select an account type"
                  required
                >
                  {accountInformation.filter(st => (st.active)).map(subType => (
                      <MenuItem value={subType.value} key={subType.value}>{subType.display}</MenuItem>
                  ))}
          
    
            </Select>
          </FormControl>
          <div className={classes.formMessage}>{formMessage}</div>
          {isAffiliate ? (
            ""
          ) : (
            <TextField
              className={classes.textField}
              error={hasError("firstname")}
              fullWidth
              helperText={
                hasError("firstname") ? formState.errors.firstname[0] : null
              }
              label="First name"
              name="firstname"
              onChange={handleChange}
              type="text"
              value={formState.values.firstname || ""}
              variant="outlined"
              size="small"
              required
            />
          )}
          {isAffiliate ? (
            ""
          ) : (
            <TextField
              className={classes.textField}
              error={hasError("lastname")}
              fullWidth
              helperText={
                hasError("lastname") ? formState.errors.lastname[0] : null
              }
              label="Last name"
              name="lastname"
              onChange={handleChange}
              type="text"
              value={formState.values.lastname || ""}
              variant="outlined"
              size="small"
              required
            />
          )}
          {isTeam && (
            <TextField
              className={classes.textField}
              error={hasError("teamname")}
              fullWidth
              helperText={
                hasError("teamname") ? formState.errors.teamname[0] : null
              }
              label="Team name"
              name="teamname"
              onChange={handleChange}
              type="text"
              value={formState.values.teamname || ""}
              variant="outlined"
              size="small"
              required
            />
          )}
          <TextField
            className={classes.textField}
            error={hasError("email")}
            fullWidth
            helperText={hasError("email") ? formState.errors.email[0] : null}
            label="Email address"
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email || ""}
            variant="outlined"
            size="small"
            required
          />
          <TextField
            className={classes.textField}
            error={hasError("password")}
            fullWidth
            helperText={
              hasError("password") ? formState.errors.password[0] : null
            }
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ""}
            variant="outlined"
            size="small"
            required
          />
          {isAffiliate ? (
            ""
          ) : (
            <TextField
              className={classes.textField}
              error={hasError("confirm")}
              fullWidth
              helperText={
                hasError("confirm") ? formState.errors.confirm[0] : null
              }
              label="Confirm Password"
              name="confirm"
              onChange={handleChange}
              type="password"
              value={formState.values.confirm || ""}
              variant="outlined"
              size="small"
              required
            />
          )}
          {isAffiliate ? (
            ""
          ) : (
            <TextField
              className={classes.textField}
              fullWidth
              label="Promo Code"
              name="promocode"
              onChange={handleChange}
              type="text"
              value={formState.values.promocode || ""}
              variant="outlined"
              size="small"
              disabled={isReseller}
            />
          )}
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <div className={classes.policy}>
              <Checkbox
                checked={formState.values.policy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="policy"
                onChange={handleChange}
                required
              />
              <TermsAndConditions />
            </div>
            {hasError("policy") && (
              <FormHelperText error>
                {formState.errors.policy[0]}
              </FormHelperText>
            )}
          </div>

          <Button
            color="primary"
            size="medium"
            fullWidth
            type="submit"
            disabled={!formState.isValid}
            variant="contained"
            onClick={registerClick}
          >
            Sign Up
          </Button>
          </>) }
          <RegisterSnackBar
            isOpen={isModalOpen}
            isSuccessfull={isSuccessfull}
            message={message}
            closeModal={closeModal}
          />
          {branding.html && branding.html.portalMessage ? '' : <Typography
            color="textSecondary"
            variant="body1"
            style={{ marginTop: "15px" }}
          >
            Have an account?{" "}
            <Link
              component={RouterLink}
              to={
                isAffiliate
                  ? `/signin/${orgId}/${memberId}`
                  : `/signin/${inviteId && inviteId !== 'false' ? inviteId : ''}`
              }
              variant="h7"
            >
              Sign In
            </Link>
          </Typography>}
        </form>
      </div>
    );
  };
  return (
    <div className={`${classes.root} portal`}>
      {isLoadingBrand? (
        <div className="loadingBrand">
          <LinearIndeterminate />
        </div>
      ) : (
        <>
          {renderSignUpPanel()}
          {renderPortalImage()}
          <Notification 
            isOpen={notificationOpen}
            message={notificationMessage}
            closeModal={() => (setNoficationOpen(false))} 
          /> 
        </>
      )}
      <GDPR />
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withRouter(SignUp);
