import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import LocalSettings from '../../services/LocalSettings'; 

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        '& .MuiButton-containedPrimary': {
            height: 40,
            width: 136,
           // backgroundColor: #22b9ea !important',
            // width: '60%',
            // marginLeft: '20%'
            '@media (max-width: 500px)': {
                // width: '40%',
                // minWidth: 150,
               },
        },
        '& .MuiButton-label': {
            color: '#eee !important',
        },
    },

}));

export default function CreateBriefButton({ handleClick, isMobile, isEdit }) {
    const classes = useStyles();
    const isEditButton = isEdit ? true : false;

    const handleButtonClick = (event) => {
        handleClick();
    };

    return (
        <span className={classes.root}>
            <Button
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                onClick={handleButtonClick}
            >
                {isEditButton ? `Edit Brief` : isMobile ? `Create` : `Create a Brief`}
            </Button>
        </span>
    );
}