import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import FavoriteBrief from '../../Modals/FavoriteBrief';
import BriefThumbnail from '../../Briefs/components/BriefThumbnail';
// import ShareModal from '../../Brief/components/ShareModal'
import CardMenu from './CardMenu'
import RegisterSnackBar from '../../SignIn/components/RegisterSnackBar';
import InviteExistingUser from '../../Brief/components/InviteExistingUser'
import { IconButton } from '@material-ui/core';
import SubscriptionModal from '../../../components/SubscriptionModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment  } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    marginTop: 24,
    borderRadius: 3,
    //maxWidth: 345,
  },
  media: {
    height: 140,
  },
  briefblock: {
      background: '#fff', 
      // padding: '20px', 
      // marginBottom: '10px', 
      minHeight: 88,
      width: '100%',
      // paddingBottom: 4,
      position: 'relative',
      borderRadius: 3,

  },
  title: {
      display: 'inline-block',
      fontWeight: 600,
      fontSize: 18,
      //height: 90,
      verticalAlign: 'top',
      paddingTop: 36, 
      width: 'calc(100% - 300px)',
  },
  titleellipsis: {
    maxWidth: 467,
    overflow:'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap', 
     fontSize: 14,
     fontWeight: 700,
  },
  date: {
      fontWeight: 400,
      fontSize: 14,
  },
  controlblock: {
      position: 'absolute',
      right: 20,
      top: 36,
      textAlign: 'right',
  },
  upDown: {
    fontSize: 13,
    display: 'inline-block',
    marginLeft: 24,
    marginTop: 27,
  },
  caret: {
      marginLeft: 12,
      fontSize: 18,
  },
  icon: {
    fontSize: 14,
    marginRight: -6,
    marginTop: -2,
  },
  noMentions: {
    height: 1,
    width: 50,
  }
});

export default function BriefRow({ history, brief, apiManager, isMobile, deleteBrief, refreshChild, refreshParent, user }) {
  const classes = useStyles(); 
  const [id, setId] = React.useState(brief._id);
  const DEFAULT_SNACK = {
    isOpen: false,
    message: '',
    isSuccessfull: false
  }
  const [snack, setSnack] = useState(DEFAULT_SNACK)

  // for now use comments
  // const mentions = brief.mentions && brief.mentions > 0 ? brief.mentions : false;
  const mentions = brief.comments && brief.comments > 0 ? brief.comments : false;  
    const closeModal = async () => {
        setSnack({...snack, isOpen: false});
        refreshChild();
      }
    
      const openModal = async (isSuccessfull, message) => {
        
        setSnack({...snack, isOpen: true, isSuccessfull: isSuccessfull, message: message});
        refreshChild();
      }
//   const clickEdit = () => {
//     history.push(`/brief/edit/${brief._id}`)
//   }
  const handlePublicSave = async (invites) => {
    invites.id = id;
    
    const result = await apiManager.invitePublicUser(invites, id);
    if(result){  
      openModal(true, 'User(s) have been sent the guest link!')
    }
    else { 
      openModal(false, 'User(s) have NOT been emailed! Please check the emails and try again. ')
    }
  }
  const detailsClick = () => {

  }

  const handleFavorite = () => {

  }
  const [inviteError, setInviteError] = useState('')
  const handleSave = async (invites) => {
    
    invites.id = id;
     
    const result = await apiManager.inviteUser(invites, id);
    if (result.msg){
      setInviteError(result.msg)
    } else {
      if(result.nonMemberEmails.length === 0){  
        openModal(true, 'User(s) have been invited!')
      }
      else if (result.nonMemberEmails.length !== 0 && result.memberEmails.length !== 0){ 
        openModal(false, `Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `)
      } else {
        openModal(false, `User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')}`)
      }
    }
  }


  const toggleMenu = () => {

  }

  const viewBrief = () => {
    window.location = `/brief/details/${brief._id}`
  }
 
  const getImageFromComponents = (components, useIcon = false) => {
    const images = components.filter(function(component){ 
        return component && component.type === 1; 
    })
  
    const placeholder = useIcon ? null :  'https://via.placeholder.com/350x150';
    return (images.length > 0 ? images[0].content.url : placeholder);
  }

  const firstPhotoUrl = getImageFromComponents(brief.components, true);


  const { invite } = brief;
  const access = invite && invite.access ? invite.access : 'admin';
  function AccountTypeCheck(access){
    switch(access){
      case 'admin': 
        return 'OWNER';
      case 'editor':
        return 'EDITOR';
      case 'viewer':
        return 'VIEWER';
    }
  }
  const style = invite ? { display: 'block' } : { display: 'block' };


  return (
    <Paper elevation={1}>
    <RegisterSnackBar  snack={snack} closeModal={closeModal} classes={classes} />
      <div className={classes.briefblock}>
          {/* <div style={{ display: 'inline-block', height: 90, width: 150, paddingLeft: 30, backgroundColor: 'rgb(10,18,65)', marginRight: 20 }}>
              <img src={firstPhotoUrl ? firstPhotoUrl : defaultImage} height={90} alt="Breefly.com" /><br />
          </div> */}
          <div onClick={viewBrief} style={{ cursor: 'pointer'}} >
          <BriefThumbnail brief={brief} size="md"  />
          <div className={classes.title}>
          <div style={{maxWidth: 467}}>
          <div style = {{ fontSize: 'small', fontWeight:400}}> {brief.Owner.firstname} {brief.Owner.lastname}</div>
              <div className={classes.titleellipsis}>
              {brief.title ? brief.title : `Brief created on ${moment(brief.created).format('MM-DD-YYYY')}`}
              </div>
              </div>
              
              <div className={classes.date}>
            {moment(brief.updated).format('MMM DD, YYYY')}
              </div>
              </div>
          </div>
          <div style={style}>
              {/* Owner: {brief.Owner.firstname} {brief.Owner.lastname}<br />
              { brief.Owner.account }< br />
              { brief.Owner.email }<br /> */}
              {/* { invite && invite.access.length > 0 ? invite.access.toUpperCase() : "OWNER" } */}
              </div>

          <div className={classes.controlblock}>
          <div style={{ display: 'inline-flex'}}> 
          <div style={{display: 'inline-block', fontSize: 14, fontWeight: 400, padding: '8px 127px 0px 8px', verticalAlign: 'top'}}>
              {AccountTypeCheck(access)}
          </div>
            {mentions 
            ?
            <IconButton aria-label="close" className={classes.icon} onClick={viewBrief}>
 
                {mentions}&nbsp;<FontAwesomeIcon icon={faComment} color={`var(--brand-color2)`} />
                </IconButton>
            :
            <div className={classes.noMentions}> </div>
          }
          <FavoriteBrief classes={classes} brief={brief} apiManager={apiManager} refreshChild={refreshChild} />

          { access != 'viewer' ? 
          <InviteExistingUser classes={classes} history={history} brief={brief} apiManager={apiManager}  isMobile={isMobile} handleSave={handleSave} handlePublicSave={handlePublicSave} user={user}/>
              : null }


          <CardMenu history={history} brief={brief} hideEdit={isMobile} deleteBrief={deleteBrief} access={access} apiManager={apiManager} refreshParent={refreshParent} user={user}/>
          </div>
              
              </div>
          {inviteError && 
            <SubscriptionModal 
              open={Boolean(inviteError)}
              message={inviteError}
              onClose={() => setInviteError('')}
              user={user}
              modalTitle={"sharing"}
            />}
      </div>
    </Paper>
  );
}