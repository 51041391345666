import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Button } from "@material-ui/core";

import BriefCard from "./components/BriefCard";
import BriefRow from "./components/BriefRow";
import BriefCaseCard from "./components/BriefCaseCard";
import PaginationRounded from "../../components/PaginationRounded";

import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart, faThLarge, faList } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateBriefButton from "../../components/CreateBriefButton";
import Search from "./components/Search";
import LinearIndeterminate from "./components/LinearIndeterminate";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import "../Brief/css/style.css";
import SubscriptionModal from "../../components/SubscriptionModal";
import eventTracker from "../../utils/event-tracker";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints]: {
      values: { sm: 750 }
    }
    // padding: 25,

    // '& .MuiTextField-root': {
    // },
    // '& .MuiOutlinedInput-root': {
    //     height: 44,
    // },
    // '& .MuiInputLabel-outlined': {
    //     paddingTop: 2,
    // },,
  },
  searchbar: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    // padding: '0px 16px',
    //height: 100,
    //paddingTop: 8,
    "@media (max-width: 650px)": {
      textAlign: "left",
      paddingLeft: 46,
      height: 80
    }
  },
  searchform: {
    width: "50%",
    paddingLeft: 16,
    "& label": {
      marginLeft: 16
    },
    "@media (max-width: 860px)": {
      width: "33%"
    },
    "@media (max-width: 500px)": {
      width: "40%",
      minWidth: 180
    }
  },
  primaryCTA: {
    position: "absolute",
    top: 0,
    left: 0,
    "@media (max-width: 650px)": {
      position: "unset",
      marginTop: 24
    }
  },
  mobileCTA: {
    position: "absolute",
    top: 8,
    right: -24
  },
  favDesktop: {
    fontSize: 14,
    color: "#333"
  },
  favMobile: {
    fontSize: 14,
    color: "#333",
    width: "100%"
  },
  viewButton: {
    fontSize: 14,
    marginRight: 16,
    //fontWeight: 400,
    padding: 0,
    "& span": {
      fontWeight: 400,
      color: "inherit"
    }
  },
  invisibleCover: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: '#88888888',
    borderRadius: 4,
    cursor: 'pointer'
  }
}));

export default function Briefs(props) {
  const classes = useStyles();

  const { user, apiManager, history, isMobile, refreshChild } = props;
  const brandStr = localStorage.getItem('orgBranding');
  const branding = brandStr ? JSON.parse(brandStr) : null;
  const [briefs, setBriefs] = React.useState([]);
  const [brief, setBrief] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isCaseView, setIsCaseView] = React.useState(false);
  const [isFavorites, setIsFavorites] = React.useState(false);
  const [isGrid, setIsGrid] = React.useState(true);
  const [isList, setIsList] = React.useState(false);
  const [init, setInit] = React.useState(false);

  const [skip, setSkip] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(isMobile ? 24 : 12);
  const [count, setCount] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [testTemplateBrief, setTestTemplateBrief] = React.useState(false);

  const getBriefs = async (
    newLimit,
    newPage,
    newSearch,
    isFavoritesOverride
  ) => {
    const sendLimit = newLimit ? newLimit : limit;
    const sendPage = newPage != null ? newPage : page;
    setIsLoading(true);

    if (newSearch != null) {
      setSearch(newSearch);
    }
    const result = await apiManager.getBriefs({
      skip: sendLimit * sendPage,
      limit: sendLimit,
      search: newSearch != null ? newSearch : search,
      isFavorites:
        isFavoritesOverride != null ? isFavoritesOverride : isFavorites
    });
    if (result && result.briefs) {
      let { briefs, count } = result;
      setBriefs(briefs);
      setCount(count);
    }
    setIsLoading(false);
  };

  if (!init && briefs.length == 0) {
    setInit(true);
    getBriefs();
  }

  const renderGrid = async () => {
    setIsGrid(true);
    setIsList(false);
    //setIsFavorites(false);
    await getBriefs();
  };

  // const updateIntercom = user => {
  //   window.intercomSettings = {
  //     app_id: "zcl62xdv",
  //     name: `${user.firstname} ${user.lastname}`,
  //     email: `${user.email}`,
  //     created_at: `${user.created}`
  //   };
  //   window.Intercom("boot", window.intercomSettings);
  //   sessionStorage.setItem("intercom", JSON.stringify(window.intercomSettings));
  // };

  // updateIntercom(user);

  const renderList = async () => {
    setIsLoading(true);
    setIsGrid(false);
    setIsList(true);
    //setIsFavorites(false);
    await getBriefs();
  };

  const renderFavorites = async () => {
    setIsLoading(true);
    setIsFavorites(!isFavorites);
    //setIsGrid(false);
    //setIsList(false);
    setPage(0);
    await getBriefs(null, 0, search, !isFavorites);
  };

  const updateSharing = async brief => {
    setIsLoading(true);
    await apiManager.updateBrief(brief);
    await getBriefs();
  };

  const updatePage = async (event, page) => {
    setIsLoading(true);
    setPage(page - 1);
    await getBriefs(limit, page - 1, search);
  };

  const updateLimit = async limit => {
    setIsLoading(true);
    setLimit(limit);
    setPage(0);
    await getBriefs(limit, 0, search);
  };

  const deleteBrief = async brief => {
    setIsLoading(true);
    await apiManager.deleteBrief(brief);
    await getBriefs();
  };

  const detailsClick = brief => {

      if (brief.visible) {
          setBrief(brief);
          history.push(`/brief/details/${brief._id}`);
      }
  };
  const [createError, setCreateError] = React.useState('')
  const createClick = async () => {
    setIsLoading(true);

    const brief = {
      title: "Enter your title...",
      tags: [],
      briefcase: { value: 0 },
      components: [
        {
          type: 0,
          content: {
            text: "Enter your title..."
          },
          position: 0
        }
      ]
    };
    const result = await apiManager.createBrief(brief);
    if (result && result.insertedCount > 0) {
      //history.push(`/brief/edit/${result._id}`);
      eventTracker('Brief Creation', user, 'briefs');
      window.location = `/brief/edit/${result._id}`;
    } else {
      if(result && result.msg){
        setCreateError(result.msg)
      } else {
        //   alert(result && result.msg ? result.msg : 'An error has occurred')
        setSnackMessage(
        result && result.msg ? result.msg : "An error has occurred"
        );
        setSnackBar(true);
      }
    }
    setIsLoading(false);
  };

  const pageCount = count > 1 ? Math.ceil(count / limit) : 1;

  const viewDetails = id => {
    history.push(`/brief/details/${id}`);
  };

  // testing template brief
  const createTemplateBrief = async () => {
      const response = await apiManager.createTemplateBrief('new user');
      await getBriefs();
  }

  const render = () => {
    return (
      <div className={classes.root}>
        {/* searchbar */}
        <div className={classes.searchbar}>
          <Search
            setPage={setPage}
            getBriefs={getBriefs}
            classes={classes}
            limit={limit}
            page={page}
            refreshChild={refreshChild}
          />
          <div className={isMobile ? classes.mobileCTA : classes.primaryCTA}>
            <CreateBriefButton handleClick={createClick} isMobile={isMobile} />
          </div>
        </div>

        {/* logo */}
            {branding && branding.logo && (
                <Grid container >
                    <Grid item xs style={{ marginTop: 48 }}>
                        <div style={{ float: 'right' }}>
                            <img src={branding.logo} width='125px' />
                        </div>
                    </Grid>
                </Grid>
            
            )}
           {/* test button for hubspot */}
            {/* <div style={{width: '100%', textAlign: 'right'}}>
            <Button onClick={() => (eventTracker('Sign In', user, 'sign-in'))}>Test Hubspot</Button>
            </div> */}

        {/* view and pagination */}
        <Grid container spacing={4}>
          <Grid item xs style={{ marginTop: 48 }}>
            {!isMobile && (
              <>
                <Button
                  aria-label="grid"
                  onClick={renderGrid}
                  className={classes.viewButton}
                  style={{
                    color: isGrid ? "#333" : "#bbb"
                  }}
                >
                  <div style={{ fontSize: 14, marginRight: 8 }}>
                    <FontAwesomeIcon icon={faThLarge} />
                  </div>
                  Grid
                </Button>

                <Button
                  aria-label="list"
                  onClick={renderList}
                  className={classes.viewButton}
                  style={{ color: isList ? "#333" : "#bbb" }}
                >
                  <div style={{ fontSize: 14, marginRight: 10 }}>
                    <FontAwesomeIcon icon={faList} />
                  </div>
                  List
                </Button>
              </>
            )}

            <Button
              aria-label="favorite"
              onClick={renderFavorites}
              className={`${classes.viewButton} ${
                isMobile ? classes.favMobile : classes.favDesktop
              }`}
              style={{ color: isFavorites ? "#333" : "#bbb" }}
            >
              <div style={{ fontSize: 14, marginRight: 8 }}>
                {isFavorites ? (
                  <FontAwesomeIcon icon={solidHeart} color="red" />
                ) : (
                  <FontAwesomeIcon icon={faHeart} color="primary" />
                )}
              </div>
              Favorites
            </Button>
          </Grid>
          <Grid
            item
            sm
            xs={12}
            style={
              !isMobile
                ? { textAlign: "right", marginTop: 48 }
                : { marginTop: 8 }
            }
          >
            {!isMobile && count > 0 && (
              <>
                {count} Brief{count === 1 ? "" : "s"}
              </>
            )}
            <PaginationRounded
              totalItems={count}
              count={count / limit}
              updateLimit={updateLimit}
              limit={limit}
              page={page}
              updatePage={updatePage}
              isMobile={isMobile}
              style={{}}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ display: !isLoading ? "none" : "block" }}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={Math.random()}
          >
            <LinearIndeterminate />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 24 }}>
            
          {testTemplateBrief && (
              <div>
              <Button 
                onClick={createTemplateBrief}
                className={classes.viewButton}
                style={{
                  color: isGrid ? "#333" : "#bbb"
                }}
                >Test Create Template Brief
                </Button>
              </div>
          )}
          {briefs
            ? briefs.map(function(value) {
                //if (isFavorites === true && !value.isFavorite) {
                //    return null
                //} else {
                return (
                  <Grid
                    item
                    xl={isGrid ? 3 : 12}
                    lg={isGrid ? 3 : 12}
                    md={isGrid ? 4 : 12}
                    sm={isGrid ? 6 : 12}
                    xs={12}
                    key={Math.random()}
                  >
                    {isCaseView && (
                      <BriefCaseCard
                        brief={value}
                        detailsClick={e => detailsClick(value)}
                        history={history}
                      />
                    )}

                        {!isCaseView && (
                            <div style={{ position: 'relative' }}>
                                {isGrid ? (
                                    <div >
                                        <BriefCard
                                            brief={value}
                                            history={history}
                                            isMobile={isMobile}
                                            refreshParent={viewDetails}
                                            detailsClick={e => detailsClick(value)}
                                            deleteBrief={deleteBrief}
                                            updateSharing={updateSharing}
                                            apiManager={apiManager}
                                            refreshChild={refreshChild}
                                            user={user}
                                            hideClass={classes.invisibleCover}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ position: 'relative' }}>
                                        <BriefRow
                                            brief={value}
                                            detailsClick={e => detailsClick(value)}
                                            apiManager={apiManager}
                                            deleteBrief={deleteBrief}
                                            isMobile={isMobile}
                                            refreshChild={refreshChild}
                                            refreshParent={viewDetails}
                                            user={user}

                                        />
                                        {!value.visible && (
                                            <div className={classes.invisibleCover}
                                            onClick={() => setCreateError('You have reached your plan limit. Please upgrade to view/edit this brief.')}

                                            ></div>
                                        )

                                        }
                                    </div>
                                )}
                            </div>
                        )}
                  </Grid>
                );
                //}
              })
            : null}
        </Grid>

        <Snackbar
          open={showSnackBar}
          autoHideDuration={3000}
          className="error"
          severity="info"
          onClose={() => setSnackBar(false)}
          variant="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Alert severity="error">{snackMessage}</Alert>
        </Snackbar>
        {createError && 
          <SubscriptionModal 
              open={Boolean(createError)}
              message={createError}
              onClose={() => setCreateError('')}
              user={user}
              modalTitle={"briefs"}
            />
        }
      </div>
    );
  };

  return render();
}
