import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import Fab from '@material-ui/core/Fab';
// import NavFabIcon from '@material-ui/icons/KeyboardArrowRight';
import { Sidebar, Footer, GDPR } from './components';

// import menuIcon from '../../images/Breefly_2020-Icon_150x150_White.png';
import menuIcon from '../../images/FabNavIcon.png';
import './css/style.css'


const useStyles = makeStyles(theme => ({
  root: {
    //paddingTop: 56,
     height: '100%',
   // minHeight: '90vh',
    [theme.breakpoints.up('sm')]: {
      //paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 120,
    paddingRight: 120,
    '@media (max-width: 500px)': {
        paddingLeft: 18,
        paddingRight: 18,
       },
  },
  content: {
    // height: '100%',
   minHeight: '90vh',
    maxWidth: 1062,
    margin: 'auto',
    paddingTop: 32,
    '@media (max-width: 450px)': {
        maxWidth: '90%'
       },
  },
  menu: {
    position: 'fixed',
    left: '24px',
    top: '24px',
    zIndex: 1000,
    backgroundColor: `#000055`,
    '@media (max-width: 500px)': {
        left: '12px'
       },
  },
  body: {
    background: '#fff'
  }

}));

const Main = props => {
  const { children, user, isMobile, apiManager } = props;
  const fabPos = useRef(null);
  console.log(fabPos)
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);


  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = openSidebar;

  return (
    <div
      id="main-layout"
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
    
      })}
    >
          <Fab color="primary" aria-label="add" className={classes.menu}
           onClick={handleSidebarOpen}
           ref={fabPos}
          >
              {/* <NavFabIcon
                  onClick={handleSidebarOpen}
              /> */}
              <img
                  src={menuIcon}
                  style={{ width: 26 }}
                   className={''}
                  alt={`Open Breefly Menu`}
              />
          </Fab>
     
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar} 
        user={user}
        isMobile={isMobile}
      />

      <main className={classes.content}>
        {children}
      </main>
      <Footer />
      <GDPR />
      
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default Main;
