import React, { useState } from 'react';
import axios from 'axios';
import LinearIndeterminate from '../../Briefs/components/LinearIndeterminate'

// import { makeStyles } from '@material-ui/core/styles';

import {
  TextField,
} from '@material-ui/core';

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     height: 140,
//     width: 100,
//   },
//   control: {
//     padding: theme.spacing(2),
//   },
//   isSelected: {
//       border: '1px solid #ccc',
//       borderRadius: '50%'
//   }
// }));



export default function Video({ component, renderIcons, renderVideoTag, funcs, apiManager, selectComponent }) { 
    const [init, setInit] = React.useState(false);
    
    const { url, alt, assetId, size, align, isUploading } = component.content;
    const [isVideoValid, setIsVideoValid] = React.useState(url && url.length > 0 && (url.indexOf('blob:') > -1 || url.indexOf('.mp4') > -1) );
    const [isLoading, setIsLoading] = React.useState(false); 
    const [newUrl, setNewUrl ] = React.useState('')
    let wstyle = { width: `${size}px` };

    let isMobile = false;
    if (size == "100%" || size == "75%" || size == "50%" || size == "25%"){
      wstyle = {width: `${size}`}
    }
    if (isMobile) {
        wstyle = { maxWidth: 300 }
    }

    const getAsset = async() => {
      let asset = await apiManager.getAsset(assetId);
      if(asset && asset.url){
        setNewUrl(asset.url)
        setIsVideoValid(true)
      }
    }

    const checkVideo = async() => {
        try {
            let result = await axios.get(url);
            if(result.isAxiosError && result.message != 'Network Error'){
               
            }
            else {
                setIsVideoValid(true)
            }
        }
        catch(ex){
            console.log(ex);
            setIsVideoValid(false)
        }
    }

    if(!init){
        setInit(true);
        //checkVideo();
        //getAsset();
    }

  return (
    <div style={{ position: 'relative' }}>
       {/* { renderIcons() } */}
       <div style={{ textAlign: `${align}`, margin: '20px 0px', height: '100%', width: '100%'}} >
        { !isVideoValid ? 
          <div>
            <img src={url} width={600} />
            </div> 
          : 
          renderVideoTag(url ? url : newUrl, wstyle) 
        }
       </div>
       <div tabIndex={`${component.content.assetId}`} style={{position: 'relative'}} onFocus={e => selectComponent(component)} onBlur={e => selectComponent(component)}>
        <div style={{position: 'absolute', height: `300px`, width: '100%', background: 'transparent', zIndex: 1, top: '-322px' }}>
        </div>
      </div>
    </div>
  );
}
