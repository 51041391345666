import React, { useEffect, useContext, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { TwitterPicker, SketchPicker } from "react-color";
import {
  faCamera,
  faChartPie,
  faPlay,
  faEdit,
  faLink,
  faItalic,
  faBold,
  faQuoteLeft,
  faCloudUpload,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Box,
  Checkbox,
  Paper,
  FormLabel,
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Tooltip
} from "@material-ui/core";
import ChartData from "./ChartData";
import LinearIndeterminate from "../../Briefs/components/LinearIndeterminate";
import "../css/right-bar.scss";

import barChart from "../../../images/ChartIcons/Bar_chart_icon.svg";
import columnChart from "../../../images/ChartIcons/Column_chart_icon.svg";
import donutChart from "../../../images/ChartIcons/Donut_chart_icon.svg";
import gaugeChart from "../../../images/ChartIcons/Gauge_chart_icon.svg";
import lineChart from "../../../images/ChartIcons/Line_chart_icon.svg";
import pieChart from "../../../images/ChartIcons/Pie_chart_icon.svg";
import pyramidChart from "../../../images/ChartIcons/Pyramid_chart_icon.svg";
import wordCloud from "../../../images/ChartIcons/Word_cloud_icon.svg";
import RightBarWarning from "./RightBarWarning";
const useStyles = makeStyles({
  list: {
    width: 300
  },
  fullList: {
    width: "auto"
  }
});

export default function Rightbar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  let {
    isOpen,
    onClose,
    settings,
    component,
    funcs,
    brief,
    apiManager,
    renderedChartData,
    editProduceFloralChart
  } = props;
  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });

    onClose();
    setContent(false);
    setTitle(content.title);
    component = null;
  };

  const cancelEdits = side => {
    // reset the content here:

    setState({ ...state, [side]: false });
    setTitle(content.title);
    setAlt(content.alt);
    setSubtitle(content.subtitle)
    setChartType(content.chartType);
    onClose();
  };

  const [openRightbar, setOpenRightbar] = React.useState(false);
  const [share, setShare] = React.useState({ isPublic: false });

  let [content, setContent] = React.useState(false);
  let [contentId, setContentId] = React.useState(false);

  const [dataPoints, setDataPoints] = React.useState("[]");
  const [title, setTitle] = React.useState(content.title);
  const [alt, setAlt] = React.useState(content.alt || "");
  const [subtitle, setSubtitle] = React.useState('');
  const [showLegend, setShowLegend] = React.useState(true);
  const [dataset, setDataset] = React.useState("produce");
  const [aisleName, setAisleName] = React.useState(null);
  const [chartGeo, setChartGeo] = React.useState(null);
  const [chartTime, setChartTime] = React.useState(null);
  const [chartType, setChartType] = React.useState('pyramid');
  const [chartProducts, setChartProducts] = React.useState([]);
  const [datasetName, setDatasetName] = React.useState("");
  const [text, setText] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [datasetInfo, setDatasetInfo] = React.useState(false);
  const [catIndex, setCatIndex] = React.useState(0);
  const [chartSize, setChartSize] = React.useState(600);
  const [chartColor, setChartColor] = React.useState("");
  const [isWarnWordCloudOpen, setWarnWordCloudOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const chartCards = [
    { name: "Bar", icon: barChart, value: "bar" },
    { name: "Column", icon: columnChart, value: "column" },
    { name: "Pie", icon: pieChart, value: "pie" },
    { name: "Line", icon: lineChart, value: "line" },
    { name: "Donut", icon: donutChart, value: "donut" },
    { name: "Pyramid", icon: pyramidChart, value: "pyramid" },
    { name: "Gauge", icon: gaugeChart, value: "gauge" },
    { name: "Word Cloud", icon: wordCloud, value: "word" }
  ];
  const updateTitle = e => {
    setTitle(e.target.value);
  };

  const updateSubtitle = e => {
    setSubtitle(e.target.value);
  };

  const [isOpenChartData, setOpenChartData] = React.useState(false);
  const [customAsset, setCustomAsset] = React.useState(false);

  useEffect(() => {
    setIsLoading(true)
    if (!content && component && component.content) {
      let { content } = component;
      setContent(content);
      setTitle(content.title);
      setSubtitle(content.subtitle);
      setText(content.text);
      setUrl(content.url);
      setAlt(content.alt);
      setChartType(content.chartType);
      setChartColor(content.chartColor);
      setShare({ isPublic: false });
      setOpenRightbar(false);
      setDataPoints(JSON.stringify(content.dataPoints, null, 2));
    }
    setIsLoading(false)
  }, [component]);

  if (isOpen && !openRightbar) {
    toggleDrawer("right", true);
  }

  const closeRightbar = () => {};

  const renderFontSettings = () => {
    const { content } = component;
    return (
      <Grid container>
        <Grid item>
          <Typography style={{ padding: "20px" }} variant="body1">
            Font settings
          </Typography>{" "}
        </Grid>
        <List>
          <ListItem
            onClick={e => funcs.addItalic(component)}
            button
            key="italics"
          >
            <ListItemIcon>
              {" "}
              <FontAwesomeIcon
                icon={faItalic}
                color={component.content.italic ? "#24a0ed" : ""}
              />
            </ListItemIcon>
            <ListItemText primary="Toggle italics" />
          </ListItem>
          <ListItem button key="bold" onClick={e => funcs.addBold(component)}>
            <ListItemIcon>
              {" "}
              <FontAwesomeIcon
                icon={faBold}
                color={component.content.bold ? "#24a0ed" : ""}
              />
            </ListItemIcon>
            <ListItemText primary="Toggle bold" />
          </ListItem>
          <ListItem
            button
            key="blockquote"
            onClick={e => funcs.addQuote(component)}
          >
            <ListItemIcon>
              {" "}
              <FontAwesomeIcon
                icon={faQuoteLeft}
                color={component.content.quote ? "#24a0ed" : ""}
              />
            </ListItemIcon>
            <ListItemText primary="Toggle Block quote" />
          </ListItem>
          <ListItem
            button
            key="visible"
            onClick={e => funcs.addEdit(component)}
          >
            <ListItemIcon>
              {" "}
              <FontAwesomeIcon
                icon={faEdit}
                color={component.content.visible ? "#24a0ed" : ""}
              />
            </ListItemIcon>
            <ListItemText primary="Toggle Edit Mode" />
          </ListItem>
        </List>
        <Box style={{ padding: "20px" }}>
          <span gutterBottom>Font size</span>{" "}
          <Select
            value={content.size}
            onChange={e => funcs.addSize(component, e.target.value)}
          >
            <MenuItem value={10}>small</MenuItem>
            <MenuItem value={20}>normal</MenuItem>
            <MenuItem value={30}>large</MenuItem>
            <MenuItem value={40}>xlarge</MenuItem>
          </Select>
        </Box>
      </Grid>
    );
  };

  const renderImage = () => {
    const { content } = component;
    const { url, assetId } = content;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Box className="right-bar__box">
          <div className="right-bar__title">
            <FontAwesomeIcon
              icon={faCamera}
              size="sm"
              style={{ marginRight: 12 }}
            />
            Image Settings
          </div>
        </Box>

        <Box className="right-bar__box">
          <span className="right-bar__select-label">Set Image Width</span>
          <Select
            value={content.size}
            onChange={e => funcs.addSize(component, e.target.value)}
            fullWidth
          >
            <MenuItem value="100%">100%</MenuItem>
            <MenuItem value="75%">75%</MenuItem>
            <MenuItem value="50%">50%</MenuItem>
            <MenuItem value="25%">25%</MenuItem>
          </Select>
        </Box>
        <Box className="right-bar__box right-bar__box--top">
          <span className="right-bar__select-label">Set Image Alignment</span>
          <Select
            name="align"
            fullWidth
            value={content.align}
            onChange={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
          >
            <MenuItem value={"center"}>Center</MenuItem>
            <MenuItem value={"left"}>Left</MenuItem>
            <MenuItem value={"right"}>Right</MenuItem>
          </Select>
        </Box>

        <Box className="right-bar__box right-bar__box--top">
          <span className="right-bar__select-label">Set Alternative Text</span>
          <TextField
            fullWidth
            name="alt"
            value={alt}
            onChange={e => setAlt(e.target.value)}
            onBlur={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
            type="text"
          />
        </Box>

        <div className="right-bar__button-holder">
          <Button onClick={() => cancelEdits("right", false)}>
            <span className="right-bar__button--cancel">Close</span>
          </Button>
          <Button onClick={() => cancelEdits("right", false)} color="primary">
            <span>Update</span>
          </Button>
        </div>
      </Grid>
    );
  };

  const renderVideo = () => {
    const { content } = component;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Box className="right-bar__box">
          <div className="right-bar__title">
            <FontAwesomeIcon
              icon={faPlay}
              size="sm"
              style={{ marginRight: 12 }}
            />
            Video settings
          </div>
        </Box>
        <Box className="right-bar__box">
          <span className="right-bar__select-label">Set Video Width</span>
          <Select
            value={content.size}
            onChange={e => funcs.addSize(component, e.target.value)}
            fullWidth
          >
            <MenuItem value="100%">100%</MenuItem>
            <MenuItem value="75%">75%</MenuItem>
            <MenuItem value="50%">50%</MenuItem>
            <MenuItem value="25%">25%</MenuItem>
          </Select>
        </Box>
        <Box className="right-bar__box right-bar__box--top">
          <span className="right-bar__select-label">Set Video Alignment</span>
          <Select
            fullWidth
            name="align"
            value={content.align}
            onChange={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
          >
            <MenuItem value={"center"}>Center</MenuItem>
            <MenuItem value={"left"}>Left</MenuItem>
            <MenuItem value={"right"}>Right</MenuItem>
          </Select>
        </Box>
        <Box className="right-bar__box right-bar__box--top">
          <span className="right-bar__select-label">Set Alternative Text</span>
          <TextField
            fullWidth
            name="alt"
            value={alt}
            onChange={e => setAlt(e.target.value)}
            onBlur={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
            type="text"
          />
        </Box>
        {/* <div style={{ display: "none" }}>
          <Box style={{ padding: "20px" }}>
            <TextField
              className={classes.textField}
              style={{ width: 200 }}
              label="Thumbnail"
              name="thumbnail"
              value={content.title}
              onBlur={e =>
                funcs.setContentValue(component, e.target.name, e.target.value)
              }
              type="text"
              variant="outlined"
            />
          </Box>
          <div>&nbsp;</div>

          <TextField
            className={classes.textField}
            style={{ width: 200 }}
            label="Asset Id"
            onChange={e => setUrl(e.target.value)}
            onBlur={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
            name="assetId"
            value={`${content.assetId}`}
            type="text"
            variant="outlined"
          />
        </div> */}

        <div className="right-bar__button-holder">
          <Button onClick={() => cancelEdits("right", false)}>
            <span className="right-bar__button--cancel">Close</span>
          </Button>
          <Button onClick={() => cancelEdits("right", false)} color="primary">
            <span>Update</span>
          </Button>
        </div>
      </Grid>
    );
  };

  const openChartData = () => {
    setOpenChartData(true);
  };

  const closeChartData = () => {
    setOpenChartData(false);
  };

  const updateChartData = async (component, form, type) => {
    setOpenChartData(false);
    await funcs.updateChartData(component, form, type);
  };

  const updateChart = async () => {
    setContent(false);
    const updatedComponent = { ...component };
    updatedComponent.content.chartType = chartType;
    updatedComponent.content.title = title;
    updatedComponent.content.subtitle = subtitle;
    updatedComponent.content.chartColor = chartColor;
    updatedComponent.content.chartType = chartType;

    await funcs.updateChartData(updatedComponent, null);
  };
  //   const updateChartTitle = async value => {
  //     setContent(false);
  //     component.content.title = value;
  //     await funcs.updateChartData(component, null);
  //   };

  //   const updateChartType = async value => {
  //     setContent(false);
  //     component.content.chartType = value;
  //     await funcs.updateChartData(component, null);
  //   };
  
  const comparableCharts = ['bar', 'column', 'line'];
  const handleEditCharts = (e, isCustom) => {
    if(isCustom){
      if (customAsset && customAsset.customDataPoints){
        setWarnCompareData({open: true, type: '', isChartData: true})
      } else {
        editProduceFloralChart()
      }
    } else {
      openChartData();
    }
  }
  const renderChart = (isCustom) => {
    let chartCardList = chartCards;
    if (isCustom){
      const { content } = component;
      if (!customAsset || contentId !== content.assetId) {
        loadCustomAsset(content.assetId);
      }
      //remove workcloud if custom chart
      chartCardList = chartCardList.filter(el => el.value !== 'word')

      if (datasetInfo) {
        // find catIndex;
        datasetInfo.forEach((aisle, index) => {
          if (aisle.name === aisleName && catIndex !== index) {
            setCatIndex(index);
            setAisleName(aisle.name);
            // setDatasetName(set.name);
          }
        });
      }
    }

    return (
      <Grid container style={{ padding: 24 }}>
        <Grid item xs={12} style={{ marginBottom: 48 }}>
          <Typography variant="body1">
            <FontAwesomeIcon icon={faChartPie} style={{ marginRight: 12 }} />
            Chart Settings
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TextField
            className={classes.textField}
            size="small"
            label="Chart Title"
            name="title"
            fullWidth
            value={title}
            onChange={e => updateTitle(e)}
            //onBlur={e => updateChartTitle(e.target.value)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <TextField
            className={classes.textField}
            size={"small"}
            label="Source"
            name="subtitle"
            fullWidth
            value={isCustom ? "IRI Integrated Fresh" : subtitle || ""}
            multiline
            onChange={e => updateSubtitle(e)}
            disabled={isCustom}
            //onBlur={e => updateChartTitle(e.target.value)}
            type="text"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 24 }}>
          <span>Select Chart Type</span>
          <Select
            fullWidth
            size="small"
            name="chartType"
            value={chartType || ""}
            onChange={e => handleChartTypeChange(e, isCustom)}
          >
            {chartCardList.map(card => (
              <MenuItem
                key={`chartType-${card.name}`}
                value={card.value}
                className="right-bar__chart-select"
              >
                <img src={card.icon} className="right-bar__chart-icon" />
                {card.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 24 }}>
          <FormLabel htmlFor="chart-color">Color</FormLabel>
          <div className="right-bar__picker-holder">
            <TwitterPicker
              triangle="hide"
              id="chart-color-picker"
              color={chartColor}
              width="250px"
              colors={["#22AEE8", "#49BA35", "#FFA22E", "#F52727", "#900DCB"]}
              onChangeComplete={handleChartColorChange}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: 20,
            textAlign: "left"
          }}
        >
          <Button
            variant="outlined"
            onClick={e => handleEditCharts(e, isCustom)}
            className={"brandColor1"}
            fullWidth
            size="large"
            disabled={isLoading}
          >
            <FontAwesomeIcon
              icon={faEdit}
              size="sm"
              style={{ marginRight: 12, color: "white" }}
            />
            <span style={{ color: "white" }}>Edit Chart Data</span>
          </Button>
        </Grid>
        {isCustom && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: 20,
              textAlign: "left"
            }}
          >
            <Tooltip
              title={
                !comparableCharts.includes(chartType)
                  ? `Comparison charts are only available for: \n 
            column, bar, & line graphs`
                  : ""
              }
              placement="left"
              arrow
            >
              <Button
                variant="outlined"
                onClick={
                  comparableCharts.includes(chartType) ? openChartData : null
                }
                className={
                  comparableCharts.includes(chartType)
                    ? "brandColor2"
                    : "right-bar__warningButton"
                }
                fullWidth
                size="large"
                disabled={isLoading}
              >
                <FontAwesomeIcon
                  icon={faCloudUpload}
                  size="sm"
                  style={{
                    marginRight: 12,
                    color: comparableCharts.includes(chartType)
                      ? "white"
                      : "#bfbfbf"
                  }}
                />
                <span
                  style={{
                    color: comparableCharts.includes(chartType)
                      ? "white"
                      : "#bfbfbf"
                  }}
                >
                  {customAsset && customAsset.customDataPoints
                    ? "Edit comparison data"
                    : "Add your data to chart"}
                </span>
              </Button>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: 20
            }}
          >
            <Button
              onClick={() => cancelEdits("right", false)}
              style={{ marginRight: 15 }}
            >
              <span style={{ color: "#757575" }}>Close</span>
            </Button>
            <Button
              onClick={isCustom ? prepareCustomChart : updateChart}
              color="primary"
            >
              <span style={{ fontWeight: 600 }}>Update</span>
            </Button>
          </div>
        </Grid>
        {isOpenChartData && !isEmpty(component) && (
          <ChartData
            isOpen={isOpenChartData}
            handleClose={closeChartData}
            component={component}
            classes={classes}
            updateChartData={updateChartData}
            updateCustomChart={prepareCustomChart}
            chartType={chartType}
            renderedChartData={renderedChartData}
            isRightBar
          />
        )}
        {isWarnWordCloudOpen && (
          <Dialog
            open={isWarnWordCloudOpen}
            onClose={() => {
              setWarnWordCloudOpen(false);
            }}
          >
            <DialogTitle id="warning-dialog-title">Heads Up!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Changing your word cloud chart to a another chart type will
                convert the text you entered, into chart specific data, and
                can't be switched back. This means you won't be able to easily
                edit the text as a paragraph.
                <br />
                <br />
                <span style={{ fontWeight: 800 }}>Do you want to proceed?</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setChartType("word");
                  setWarnWordCloudOpen(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setWarnWordCloudOpen(false);
                }}
                color="primary"
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {warnCompareData.open && (warnCompareData.isChartData ? (
          //Warning for editing chart data, will loose comparisons
          <RightBarWarning
            isOpen={warnCompareData.open}
            onClose={() => {
              setWarnCompareData({ type: '', open: false});
            }}
            continueAction={() => {
              setWarnCompareData({ type: '', open: false });
              editProduceFloralChart();
            }}
            title="Careful!"
            message="If you edit this chart, your comparision data will be lost."
          />
        ):(
          //Warning for changing chart type
          <RightBarWarning
            isOpen={warnCompareData.open}
            onClose={() => {
              setWarnCompareData({ ...warnCompareData, open: false });
            }}
            continueAction={() => {
              setChartType(warnCompareData.type);
              setWarnCompareData({ type: warnCompareData.type, open: false });
            }}
            title="Careful!"
            message="If you change to another chart type, your comparison data will not be visible. Comparisons are only supported by column, bar, and line charts."
          />
        ))}
      </Grid>
    );
  };

  const handleColorChange = (color, event) => {
    funcs.addColor(component, color.hex);
  };
  const handleChartColorChange = (color, event) => {
    setChartColor(color.hex);
  };
  const [warnCompareData, setWarnCompareData] = useState({type: '', open: false})
  const handleChartTypeChange = async (e, isCustom) => {
     
    const type = e.target.value;
    let data;
    // if (type === "word" && content.chartType !== "word") {
    //   data =
    //     "This is the some example text for the word cloud. Welcome to Breefly. Breefly. Breefly.";
    // }
    if(isCustom && !comparableCharts.includes(type) && customAsset && customAsset.customDataPoints){
      console.log("bad ideas", type, isCustom)
      setWarnCompareData({open: true, type: type})
      return ''
    }
    if( type !== "word" && typeof content.dataPoints === 'string') {
        setWarnWordCloudOpen(true);
    }
    if (type !== "word" && content.chartType === "word") {
        //;
      // data = [{ x: 0, y: 123, label: "default label" }];
      data = 'fromWord';
    }
    
    setChartType(type);
    //await funcs.updateChartData(updatedComponent, data, type);
  };
  // const handleChangeProducts = event => {
  //   let { value } = event.target;
  //   const wasAll = chartProducts.length === 1 && chartProducts[0] === "all";
  //   const wasAllConv =
  //     chartProducts.length === 1 && chartProducts[0] === "all conventional";
  //   const wasAllOrg =
  //     chartProducts.length === 1 && chartProducts[0] === "all organic";
  //   const wasEvery = chartProducts.length === 1 && chartProducts[0] === "every";

  //   if (value.length === 2 && (wasAll || wasAllConv || wasAllOrg || wasEvery)) {
  //     value.shift();
  //   } else {
  //     if (
  //       value.includes("all") ||
  //       value.includes("all conventional") ||
  //       value.includes("all organic")
  //     ) {
  //       value = [value[value.length - 1]];
  //     } else if (value.includes("every")) {
  //       value = ["every"];
  //     }
  //   }

  //   setChartProducts(value);
  // };

  const loadCustomAsset = async assetId => {
    const { content } = component;
    const response = await apiManager.getPMIChartAssets(assetId);

    const assetSettings = response.assets[0].settings;
    if (content.customSettings) {
      assetSettings.chartOptions = response.assets[0].settings.chartOptions;
    } else {
      assetSettings.category = assetSettings.dataset;
    }
    console.log('asset settings ', assetSettings)
    if (!datasetInfo || datasetInfo.dataset !== assetSettings.dataset) {
      const datasetInfoResponse = await apiManager.getPMIDatasetsInfo(
        assetSettings.dataset
      );
      setDatasetInfo(
        datasetInfoResponse &&
          datasetInfoResponse.info &&
          datasetInfoResponse.info.datasets
          ? datasetInfoResponse.info.datasets
          : false
      );
    }
      
    setContentId(assetId);
    setCustomAsset(assetSettings); // this is the original asset
    setTitle(assetSettings.title);
    setSubtitle(assetSettings.subtitle);
    setDataset(assetSettings.dataset);
    setChartGeo(assetSettings.geo);
    setChartTime(assetSettings.time);
    setChartType(assetSettings.type);
    setChartProducts(assetSettings.products);
    setChartSize(component.content.size);
    setChartColor(assetSettings.chartColor);
      
    if (assetSettings.category) {
      // backwards compatible
      setAisleName(
        assetSettings.category.includes("veg") ? "VEGETABLES" : "FRUIT"
      );
    }
    if (
      component.content.customSettings &&
      component.content.customSettings.aisleName
    ) {
      setAisleName(component.content.customSettings.aisleName);
    } else if (assetSettings["Aisle Name"]) {
      setAisleName(assetSettings["Aisle Name"]);
    }
    setShowLegend(
      component.content.hideLegend && component.content.hideLegend === true
        ? false
        : true
    );
  };
  useEffect(() => {
    console.log('chart type',  chartType)
  }, [chartType])
  const changeAisleName = e => {
    // have to change products here.. set to all.
    const name = e.target.value;
    setAisleName(name);
    setChartProducts(["all"]);
  };

  const prepareCustomChart = (side, parsed, removeCompare) => {
  
    console.log('updating custom chart', component.content.settings, parsed)
   
    component.content.settings = {
      title: title,
      dataset: dataset,
      subtitle: `${
        dataset === "floral" ? "FLORAL" : aisleName
      }: ${chartGeo} - ${chartTime}`,
      type: chartType,
      "aisle name": aisleName,
      geo: chartGeo,
      time: chartTime,
      dateRange: null,
      metrics: customAsset.metrics,
      products: chartProducts,
      chartType,
      chartColor: chartColor,
      //parsed comes from editing comparison data, its its not there, use the existing customDataPoints
      customDataPoints: parsed ? parsed : component.content.settings.customDataPoints ? component.content.settings.customDataPoints : null, 
    };
    if (dataset === "produce") {
      component.content.settings.aisleName = aisleName;
    }
    if(removeCompare){
      delete component.content.settings.customDataPoints;
    }
    setState({ ...state, [side]: false });
    funcs.updateCustomChart(component);
  };

  const renderLink = () => {
    const { content } = component;

    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Box className="right-bar__box">
          <div className="right-bar__title">
            <FontAwesomeIcon
              icon={faLink}
              size="sm"
              style={{ marginRight: 12 }}
            />
            Link Settings
          </div>
        </Box>
        <Box className="right-bar__box">
          <TextField
            className={classes.textField}
            label="Link Title"
            name="text"
            onChange={e => setText(e.target.value)}
            onBlur={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
            value={text}
            type="text"
            fullWidth
          />
          <div>&nbsp;</div>
          <TextField
            className={classes.textField}
            label="Link Url"
            name="url"
            value={`${url}`}
            onChange={e => setUrl(e.target.value)}
            onBlur={e =>
              funcs.setContentValue(component, e.target.name, e.target.value)
            }
            type="text"
            fullWidth
          />
        </Box>
        <div>&nbsp;</div>
        <Box className="right-bar__box">
          <span>Select Button Color</span>
          <div className="right-bar__picker-holder">
            <SketchPicker
              //triangle='hide'
              color={content.background}
              width="225px"
              presetColors={[
                "#61bd6d",
                "#19bc9c",
                "#54acd2",
                "#2c82c9",
                "#9365b8",
                "#475577",
                "#cccccc",
                "#41a85f",
                "#00a885",
                "#3d8eb9",
                "#2969b0",
                "#553982",
                "#28324e",
                "#000000",
                "#f7da64"
              ]}
              onChangeComplete={handleColorChange}
            />
          </div>
        </Box>
        <div className="right-bar__button-holder">
          <Button onClick={() => cancelEdits("right", false)}>
            <span className="right-bar__button--cancel">Close</span>
          </Button>
          <Button onClick={() => cancelEdits("right", false)} color="primary">
            <span>Update</span>
          </Button>
        </div>
      </Grid>
    );
  };

  const renderByType = () => {
    let html = "";
    switch (settings.type) {
      case 0:
        html = "";
        break;
      case 1:
        html = renderImage();
        break;
      case 2:
        html = renderFontSettings();
        break;
      case 3:
        html = renderVideo();
        break;
      case 4:
        html = renderChart();
        break;
      case 5:
        html = renderLink();
        break;
      case 6:
        html = renderChart(true);
        break;
    }
    //})
    return (
      <div className={classes.list} role="presentation">
        {html}
        {/* <div class="tribute-container" style="top: 161px; left: 821.188px; position: absolute; display: block;"></div> */}
      </div>
    );
  };

  const onOpen = () => {
    // setContent(false)
    setTitle("");
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer("right", false)}
        onOpen={onOpen}
        className="right-bar"
      >
        <form>{renderByType()}</form>
      </Drawer>
    </div>
  );
}
