import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
// import People from './People/People'
// import Chart from './People/components/Chart'

import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  InputBase,
  TextField,
  Snackbar,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { faCog, faSearch, faPencil } from "@fortawesome/pro-light-svg-icons";
import MuiAlert from "@material-ui/lab/Alert";
import BrandedCardTitle from "./components/BrandedCardTitle";
import MembersList from "./People/components/MembersList";
import MembersInvite from "./People/components/MembersInvite";
import AddSeats from "../../components/AddSeats";
import AddPhoto from "../Modals/AddPhoto";
import LocalSettings from "../../services/LocalSettings";
import { SketchPicker } from "react-color";

// import { FAKE_ORGANIZATION } from "../../constants";

import "./style.css";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubscriptionModal from "../../components/SubscriptionModal";
import BulkSubscriber from "../DataDetails/components/BulkSubscriberForm";
import eventTracker from "../../utils/event-tracker";

const SimpleTextField = withStyles({
  root: {
    width: "75%",
    marginBottom: 26,
    "& label": {
      fontSize: 12
    },
    "& input": {
      fontSize: 12,
      color: "#000000"
    },
    "& label.Mui-focused": {
      color: "#000000 !important",
      fontSize: 8
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #000000"
    },
    "& .MuiInput-underline.Mui-error:after": {
      transform: "scaleX(1)",
      borderBottomColor: "red !important"
    }
  }
})(TextField);
// const SimpleCheckbox = withStyles({
//     root: {
//         color: '#ACACAC',
//         '&$checked': {
//             color: '#707070',
//         },
//     },
// })((props) => <Checkbox color="default" {...props} />);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = theme => ({
  root: {
    //   padding: theme.spacing(4),
    minHeight: "90vh"
  },
  img: {
    maxWidth: "600px"
  },
  card: {
    padding: 24
  },
  search: {
    color: "#757575",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",

    height: 32
  },
  searchIcon: {
    height: "100%",
    margin: "0px 8px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#757575"
  },
  inputRoot: {
    color: "#757575",
    width: "200px",
    "&.Mui-focused": {
      width: "250px"
    },
    transition: theme.transitions.create("width")
  },
  inputInput: {
    fontSize: 12,
    width: "100%"
  },
  addSeat: {
    root: {
      fontSize: 14
    },
    justifyContent: "flex-end"
  },
  cp_popover: {
    position: "absolute",
    zIndex: "2"
  },
  cp_cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  },
  cp_title: {
    margin: 12
  },
  cp_container: {
    height: 900
  },
  cp_secondary_margin: {
    marginTop: "370px !important"
  }
});

const OrganizationDetails = props => {
  const { history, apiManager, classes, user, match, updateUser } = props;

  // const [spacing, setSpacing] = React.useState(2);
  // const [search, setSearch] = React.useState('');
  // const [limit, setLimit] = React.useState(25);
  // const [skip, setSkip] = React.useState(0);
  const [org, setOrganization] = React.useState(false);
  const [color1, setColor1] = React.useState("");
  const [color2, setColor2] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [portalImage, setPortalImage] = React.useState("");
  // const [updated, setUpdated] = React.useState(new Date().toDateString())
  const [searchTerm, setSearchTerm] = React.useState("");
  const [invitesOpen, setInvitesOpen] = useState(false);
  const [inviteType, setInviteType] = useState("members");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertError, setAlertError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Alert");
  const [alertProgress, setAlertProgress] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(false);
  const [uneditedMember, setUneditedMember] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [refreshMembers, doMemberRefresh] = useState(false);
  const [refreshAffiliates, doAffiliateRefresh] = useState(false);
  const [downgradeSeats, setDowngradeSeats] = useState({
    isOpen: false,
    seats: ""
  });
  const [addTeamError, setAddTeamError] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isUpdatePortalImage, setIsUpdatePortalImage] = useState(false);
  const [editTeamName, setEditTeamName] = useState({
    isOpen: false,
    value: ""
  });

  const [openBulkUpload, setOpenBulkUpload] = useState(false);

  const [editable, setEditable] = useState("none");
  const [isSixMonth, setIsSixMonth] = useState(false);

  // const rootDoc = document.documentElement;
  // rootDoc.style.setProperty('--brand-color1', color1);
  // settings('set', 'orgBranding', { logo: null, color1: 'red', color2: 'green' })

  const getData = async overrideSearch => {
    // DEPRECATED:
    // const paths = history.location.pathname.split('/');
    // const id = paths[paths.length - 1]

    const params = {
      id: user.orgId
    };

    // if (FAKE_ORGANIZATION) {
    //   params.id = "5ef8e4dc2c27b96d8173db7a";
    // }

    const orgData = await apiManager.getOrganization(params);
    if (orgData) {
      setColor1(
        orgData && orgData.branding && orgData.branding.primary
          ? orgData.branding.primary
          : ""
      );
      setColor2(
        orgData && orgData.branding && orgData.branding.secondary
          ? orgData.branding.secondary
          : ""
      );
      setLogo(
        orgData && orgData.branding && orgData.branding.portallogo
          ? orgData.branding.portallogo
          : ""
      );
      setPortalImage(
        orgData && orgData.branding && orgData.branding.portalbg
          ? orgData.branding.portalbg
          : ""
      );
      setOrganization(orgData);
    }
  };

  //LOAD DATA ONCE MOUNTED
  useEffect(() => {
    getData();
    match.params[0] &&
      setDowngradeSeats({ isOpen: true, seats: match.params[0] }); //Only used if downgrading from subs page
    // eslint-disable-next-line react-hooks/exhaustive-deps

    let edit = 'all';   // default, team owner/admin of non-host-org or non-affiliate
    if(user.hostOrgId) {
        // then, there is a host org.
        if( user.hostOrgId !== user.orgId) {
            // then an affiliate team owner or admin (co-owner)
            edit = 'name only'; // affiliate team owners and admins can only edit the name of their organization
        } else if (user.orgRole === 'co-owner') {
          //  edit = 'none';
          // co-owner has same permissions as owner
        }
    }
    setEditable(edit);
    if(user.accounttype == 'teamSinglePayment'){
        setIsSixMonth(true)
      }

  }, []);

  const deleteAsset = async asset => {
    await apiManager.deleteUser(asset);
    await getData();
  };

  const updateAsset = async user => {
    await apiManager.createOrganization(user);
    await getData();
  };

  const viewPeople = async e => {
    history.push("/organizations/details/people/5ef8e4dc2c27b96d8173db7a");
  };

  const viewTeams = async e => {
    history.push("/organizations/details/teams/5ef8e4dc2c27b96d8173db7a");
  };

  if (!org || !org.company) {
    return null;
  }

  const { firstname, lastname, company } = org.company;
  const { teamDataPoints, memberDataPoints } = org.teams;
  const { owners, members, contractors, affiliates, usedSeats } = org.stats;

  const onSearchInput = e => {
    setSearchTerm(e.target.value);
  };

  const inviteTeam = () => {
    //check if there are any seats availible... if not pop the subscription modal
    if (usedSeats < org.seats.count) {
      setInviteType("members");
      setInvitesOpen(true);
    } else {
      setAddTeamError(
        "Sorry, you cannot add team members. Your plan has no more available seats. Please upgrade your plan or contact Breefly to expand your team."
      );
    }
  };

  // this is the callback for when the membersInvite component closes
  const closeInvites = async inviteList => {
    setInvitesOpen(false);
    if (inviteList === 'bulk') {
        setOpenBulkUpload(true);
    } else if (inviteList) {
      setAlertTitle("Invitations");
      setAlertMessage(`Sending ${inviteList.length} invites`);
      setAlertError(false);
      setAlertProgress(true);
      setAlertOpen(true);
      // now call for the invites to happen...
      const response = await apiManager.orgBulkInvite(inviteList, org._id);
      setAlertProgress(false);
      setAlertMessage("Invites sent");
      eventTracker('User Invited to Team', user, 'team-management')

      // Refesh Member Data
      inviteType === "members"
        ? doMemberRefresh(!refreshMembers)
        : doAffiliateRefresh(!refreshAffiliates);

      // Refresh Org Data
      getData();
    }
  };

  const inviteAffiliates = () => {
    setInviteType("affiliates");
    setInvitesOpen(true);
  };

  const typeList = {
    member: "team member",
    owner: "client",
    "co-owner": "admin"
  };

  const updateMember = async (member, action, affiliate) => {
    console.log("action ", action, member, user);
    setAlertMessage(`${action} ${member.firstname} ${member.lastname}`);
    setAlertTitle(`Updating ${member.firstname} ${member.lastname}`);
    if (action !== "Editing") {
      setAlertError(false);
      setAlertProgress(true);
      setAlertOpen(true);
      const payload = {
        orgId: org._id,
        member,
        action,
        affiliate: affiliate || false
      };
      const response = await apiManager.orgUpdateMember(payload);
      console.log(response);

      if (response) {
        if(member.user_id === user._id){
          updateUser({...user, firstname: member.firstname, lastname: member.lastname})
        }
        getData();
        doMemberRefresh(!refreshMembers);
      }
      setAlertOpen(false);
      setAlertProgress(false);

      // Refresh Org Data

      //   setAlertProgress(false);
      //   setAlertMessage(`${action !== 'Edited' ? '(Not implemented yet) - Message would be: ' : ''}Successfully updated ${member.firstname} ${member.lastname} (${typeList[member.type]})`);
    } else {
      // note that if the editing dialog is not canceled (i.e. user clicks UPDATE)
      // then this function is called agin with action = 'Edit'
      // setAlertTitle(`Editing ${member.firstname} ${member.lastname}`);
      setAlertTitle("Member Profile");
      setSelectedMember(member);
      setUneditedMember(member);
      setEditOpen(true);
    }
  };

  const saveMemberEdits = async () => {
    setEditOpen(false);
    updateMember(selectedMember, "Edited", null);
  };

  const cancelEditTab = () => {
    setEditOpen(false);
    updateMember(uneditedMember)
  }


  const updateBrandData = async branding => {
    const params = org;
    params.branding.primary = branding.color1;
    params.branding.secondary = branding.color2;
    params.branding.portallogo = branding.logo;
    params.branding.portalbg = branding.portalImage;
    const update = await apiManager.updateOrganization(params);
  };

  const setBrandValue = async (item, value) => {
    const brandStr = localStorage.getItem("orgBranding");
    let branding = {};
    if (brandStr) {
      branding = JSON.parse(brandStr);
    }
    branding[item] = value;
    LocalSettings.setSetting("orgBranding", branding);
    await updateBrandData(branding);
  };

  const showColorPicker = type => {
    setDisplayColorPicker(type);
  };

  const handleColorChange = (color, type) => {
    if (type === "primary") {
      setColor1(color.hex);
      setBrandValue("color1", color.hex);
    } else {
      setColor2(color.hex);
      setBrandValue("color2", color.hex);
    }
  };
  const updateTeamName = async () => {
    const params = { ...org };
    params.company.company = editTeamName.value;
    try {
      const update = await apiManager.updateOrganization(params);
      if (update) {
        setOrganization(params);
        updateUser({...user, orgName: editTeamName.value})
      }
    } catch (e) {
      alert(e.message);
    }
    setEditTeamName({ ...editTeamName, isOpen: false });
  };
  const onEditInput = (value, key) => {
    selectedMember[key] = value;
    setSelectedMember(selectedMember);
    setRefresh(!refresh);
  };

  const handleImageSave = async (image, type) => {
    if (type === "logo") {
      setLogo(image.url);
    } else {
      setPortalImage(image.url);
    }
    setBrandValue(type, image.s3Asset ? image.s3Asset.url : image.url);
    setIsUpdatePortalImage(false)
  };

  const closeAddPhoto = () => {};

  const openChangeImage = type => {
    setIsUpdatePortalImage(type);
  };

  const closeChangeImage = () => {
    const c = isUpdatePortalImage;
    setIsUpdatePortalImage(false);
  };

  return (
    <div className={`${classes.root} branded`}>
      {org && org.branding && org.branding.portallogo ? (
        <div className="pageLogo" style={{ marginTop: -56 }}>
          <img src={logo} width="125px" alt="decorative logo" />
        </div>
      ) : (
        <div className="pageLogo" style={{ marginTop: -56 }}>
          <h3>{org.company.company}</h3>
        </div>
      )}
      <h4 style={{ marginTop: 80, marginBottom: 40 }}>
        {/* Team Management for {org.company.company} */}
        Team Account Settings for {org.company.company}
        <Button
          onClick={() => setEditTeamName({ ...editTeamName, isOpen: true })}
        >
          <FontAwesomeIcon
            icon={faPencil}
            style={{ fontSize: 12, marginBottom: 4 }}
          />
        </Button>
      </h4>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Card className={`card`}>
            <BrandedCardTitle title={"Branding"} color="brandColor1" />
            <div
              className={`brandCard ${
                displayColorPicker ? classes.cp_container : null
              }`}
            >
              <div className="uploadedImage">
                {portalImage && portalImage !== "" ? (
                  <div
                    onClick={
                      !isUpdatePortalImage
                        ? () => {
                            openChangeImage("portalImage");
                          }
                        : null
                    }
                  >
                    <AddPhoto
                      handleSave={image => {
                        handleImageSave(image, "portalImage");
                      }}
                      classes={classes}
                      isOpen={isUpdatePortalImage === "portalImage"}
                      handleClose={closeChangeImage}
                      replace
                    />
                    <img
                      src={portalImage}
                      width="100%"
                      alt="selected background for site branding"
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <AddPhoto
                      handleSave={image => {
                        handleImageSave(image, "portalImage");
                      }}
                      closePanel={closeAddPhoto}
                      classes={classes}
                    ></AddPhoto>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: 24 }}>Home Page Image</div>
              <div className="uploadedImage">
                {logo && logo !== "" ? (
                  <div
                    onClick={
                      !isUpdatePortalImage
                        ? () => {
                            openChangeImage("logo");
                          }
                        : null
                    }
                  >
                    <AddPhoto
                      handleSave={image => {
                        handleImageSave(image, "logo");
                      }}
                      classes={classes}
                      isOpen={isUpdatePortalImage === "logo"}
                      handleClose={closeChangeImage}
                      replace
                    />

                    <img
                      src={logo}
                      width="100%"
                      alt="selected logo for site branding"
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <AddPhoto
                      handleSave={image => {
                        handleImageSave(image, "logo");
                      }}
                      closePanel={closeAddPhoto}
                      classes={classes}
                    ></AddPhoto>
                  </div>
                )}
              </div>
              <div>Logo</div>

              {displayColorPicker !== "primary" ? (
                <div
                  className="colorPanel"
                  onClick={() => showColorPicker("primary")}
                >
                  Primary Color
                  <div className="colorSwatch" style={{ background: color1 }} />
                </div>
              ) : (
                <div className={classes.cp_popover}>
                  <div
                    className={classes.cp_cover}
                    onClick={() => showColorPicker(false)}
                  />
                  <div className={classes.cp_title}>Select Primary Color</div>
                  <SketchPicker
                    color={color1}
                    onChange={color => {
                      handleColorChange(color, "primary");
                    }}
                  />
                </div>
              )}
              {displayColorPicker !== "secondary" ? (
                <div
                  className={`colorPanel ${
                    displayColorPicker === "primary"
                      ? classes.cp_secondary_margin
                      : null
                  }`}
                  onClick={() => showColorPicker("secondary")}
                >
                  Secondary Color
                  <div className="colorSwatch" style={{ background: color2 }} />
                </div>
              ) : (
                <div className={classes.cp_popover}>
                  <div
                    className={classes.cp_cover}
                    onClick={() => showColorPicker(false)}
                  />
                  <div className={classes.cp_title}>Select Secondary Color</div>
                  <SketchPicker
                    color={color2}
                    onChange={color => {
                      handleColorChange(color, "secondary");
                    }}
                  />
                </div>
              )}
            </div>
            <CardActions>
              {/* <div class={`cardBottomLeft`}>
                Updated {updated}
              </div>*/}
            </CardActions>
          </Card>
        </Grid>
        {/* MEMBERS */}
        <Grid item xs={12} sm={9}>
          <Card className={`card`}>
            <BrandedCardTitle
              title={"My Team"}
              cta={"Setup your team"}
              color="brandColor1"
              icon={faCog}
              callback={inviteTeam}
            />
            <CardContent className={classes.card}>
              <div className="cardTop">
                <span>
                  Enrolled Team Members: {usedSeats} of {org.seats.count}
                </span>
                <div className={`cardSearch ${classes.search}`}>
                  <div className={classes.searchIcon}>
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <InputBase
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    placeholder="Search"
                    onChange={onSearchInput}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </div>
              <MembersList
                apiManager={apiManager}
                org={org}
                updateMember={updateMember}
                searchTerm={searchTerm}
                refreshData={refreshMembers}
              />
            </CardContent>
            <CardActions className={classes.addSeat}>
              {(user.orgRole == 'owner' && !isSixMonth) &&
              <AddSeats
                left={64}
                history={history}
                downgrade={downgradeSeats.seats}
              />}
            </CardActions>
          </Card>

          <div style={{ height: 18, width: 1 }} />

          {/* AFFILIATES */}
          {/* <Card className={`card`}>
            <BrandedCardTitle title={'Subscribers'} cta={'Invite Subscribers'} color='brandColor1' icon={faEnvelope} callback={inviteAffiliates} isSubscriberList={false} />
            <CardContent>
              <div>
                <div style={{ fontSize: 12, fontStyle: 'italic', marginBottom: 18 }}>
                  (Subscribers are Breefly users that can subscribe to your data, but pay for their own subscriptions)
                                </div>
                /* <input
                                    type='text'
                                    className='cardSearch'
                                    placeholder="&#x1F50D; Search"
                                    onKeyDown={onSearchInput}
                                /> *
              </div>
              <MembersList apiManager={apiManager} org={org} updateMember={updateMember} affiliates searchTerm={false} refreshData={refreshAffiliates} isSubscriberList={true} />
            </CardContent>
            <CardActions>
              /* <div class={`cardBottomLeft`}>
                                Updated {updated}
                            </div> *
            </CardActions>
          </Card> */}
        </Grid>
      </Grid>
      {/* <div style={{ position: 'relative', height: 400 }}>
        <div style={{ height: 400, width: 500, float: 'left', paddingRight: 20 }}> */}
      {/* <Chart title={'People'} chartType={'pie'} dataPoints={memberDataPoints}  /><br /> */}
      {false && (
        <div style={{ marginTop: 24 }}>
          <Button
            variant="contained"
            style={{ marginRight: 24 }}
            onClick={viewPeople}
          >
            Edit People
          </Button>

          <Button
            variant="contained"
            style={{ marginRight: 24 }}
            onClick={viewTeams}
          >
            Edit Teams
          </Button>

          <Button variant="contained" style={{ marginRight: 24 }}>
            Upload Data
          </Button>

          <div style={{ marginTop: 48 }}>
            <h4>Add Ons:</h4>
            <div>list goes here...</div>
          </div>
        </div>
      )}

      {invitesOpen && <MembersInvite
        apiManager={apiManager}
        user={user}
        org={org}
        open={invitesOpen}
        close={closeInvites}
        inviteType={inviteType}
        remainingSeats={org.seats.count - usedSeats}
      />}
      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="Team Alert"
      >
        <Card className={`branded card`}>
          <BrandedCardTitle title={alertTitle} />
          <CardContent>
            <div style={{ fontSize: 18, padding: 24, minWidth: 300 }}>
              {alertError ? (
                <>
                  <span style={{ color: "darkred" }}>alertMessage</span>
                </>
              ) : (
                <>{alertMessage}</>
              )}
              {alertProgress && (
                <div>
                  <LinearIndeterminate />
                </div>
              )}
            </div>
          </CardContent>

          <DialogActions>
            <Button onClick={() => setAlertOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="Member Edit"
      >
        <Card className={`branded card`}>
          <BrandedCardTitle title={alertTitle} />
          <CardContent>
            {selectedMember && (
              <Grid container direction="row">
                <Grid item xs={4} className="edit-user-label">
                  <p>First Name</p>
                  <p>Last Name</p>
                  <p style={{color: '#7b7b7b'}}>Email</p>
                  <p>Role</p>
                  {/* <p>Data Access</p>  */}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7} style={{ marginTop: 28 }}>
                  <SimpleTextField
                    onInput={event =>
                      onEditInput(event.target.value, "firstname")
                    }
                    className="inviteInput"
                    name="first"
                    type="text"
                    placeholder="First Name"
                    value={selectedMember.firstname}
                  />
                  <SimpleTextField
                    onChange={event =>
                      onEditInput(event.target.value, "lastname")
                    }
                    className="inviteInput"
                    name="last"
                    placeholder="Last Name"
                    value={selectedMember.lastname}
                  />
                  <SimpleTextField
                    onChange=""
                    className="inviteInput"
                    name="email"
                    disabled
                    style={{color: '#7b7b7b'}}
                    placeholder="hello@breefly.com"
                    value={selectedMember.email.toLowerCase()}
                  />
                  <Select
                    name="type"
                    value={selectedMember.type}
                    onChange={event => onEditInput(event.target.value, "type")}
                    size={"small"}
                    className="inviteSelect"
                    disabled={
                      selectedMember.type === "owner" ||
                      selectedMember.email === user.email
                    }
                    style={{ width: 180, fontSize: 12 }}
                  >
                    {/* <MenuItem style={{ fontSize: 13, display: `${user.orgRole === 'owner' ? '' : 'none'}` }} value="owner">Co-Owner</MenuItem>
                     */}
                    <MenuItem style={{ fontSize: 13 }} value="co-owner">
                      Admin
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value="member">
                      Team Member
                    </MenuItem>
                    {selectedMember.type === "owner" && (
                      <MenuItem style={{ fontSize: 13 }} value="owner">
                        Owner
                      </MenuItem>
                    )}
                  </Select>
                  {/* <FormGroup style={{marginTop: 24}}>
                      <FormControlLabel className='edit-user-label'
                      control={
                        <SimpleCheckbox
                        defaultChecked
                        name='sentiment'
                        size="small"
                        />  
                      }
                      label="Consumer Sentiment"/>
                      <FormControlLabel 
                      className='edit-user-label'
                      control={
                        <SimpleCheckbox
                        defaultChecked
                        name='iri-floral'
                        size="small"
                        />  
                      
                      }
                      label="IRI Floral"/>
                      <FormControlLabel 
                      className='edit-user-label'
                      control={
                        <SimpleCheckbox
                        name='iri-produce'
                        size="small"
                        />  
                      }
                      label="IRI Produce"/>
                    </FormGroup> */}
                </Grid>
                {/* <div className='edit-user-label' style={{ fontSize: 18, padding: 24, minWidth: 300 }}>
                <div style={{ marginBottom: 18 }}>
                  
                </div>
                <div style={{ marginBottom: 18 }}>
                </div>
                <div style={{ marginBottom: 18 }}>
                </div>
                <div> 
                  
                  <input  onChange={((event) => listInput(event, index))} className='inviteInput' name="role" type="text" placeholder="Role" value={person.role}/> 
                </div>
                <div style={{ marginTop: 12, marginLeft: -13, fontSize: 15 }}>
                  
                </div>
              </div>*/}
              </Grid>
            )}
          </CardContent>

          <DialogActions>
            <Button onClick={() => cancelEditTab()}>
              <span style={{ color: "grey" }}>Cancel</span>
            </Button>
            <Button onClick={() => saveMemberEdits()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
      {/* Edit Team Name Dialog */}
      <Dialog
        open={editTeamName.isOpen}
        onClose={() => setEditTeamName({ value: "", isOpen: false })}
        aria-labelledby="edit-team-name-dialog"
      >
        <DialogTitle id="edit-team-name-dialog">Edit Team Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update your team name with something fresh. <br />
            Current team name: <b>{org.company.company}</b>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="teamname"
            label="New Team Name"
            type="text"
            fullWidth
            value={editTeamName.value}
            onChange={e =>
              setEditTeamName({ ...editTeamName, value: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTeamName({ value: "", isOpen: false })}>
            <span style={{ color: "darkgray" }}>Cancel</span>
          </Button>
          <Button
            onClick={updateTeamName}
            color="primary"
            disabled={!editTeamName.value}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={downgradeSeats.isOpen}
        autoHideDuration={6000}
        onClose={() => setDowngradeSeats({ ...downgradeSeats, isOpen: false })}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Alert severity="warning">
          Please remove {downgradeSeats.seats} users before downgrading.
        </Alert>
      </Snackbar>
      {addTeamError && (
        <SubscriptionModal
          open={Boolean(addTeamError)}
          message={addTeamError}
          onClose={() => setAddTeamError("")}
          user={user}
          modalTitle={"team"}
        />
      )}
      {openBulkUpload && (
        <BulkSubscriber 
            forTeam={true}
            openBulk={openBulkUpload} 
            setBulkOpen={setOpenBulkUpload} 
            apiManager={apiManager} 
            org={org} 
            classes={classes}
            seats={org.seats.count}
            user={user}
            getData={getData}
        />
      )

      }
    </div>
  );
};

OrganizationDetails.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(OrganizationDetails);
