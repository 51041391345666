import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Menu,
  MenuItem,
  Select,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuidv4 from "uuid/v4";
import DeleteDialog from './DeleteDialog'
import LoadingIndicator from "../../../components/LoadingIndicator";
import { cos } from "@amcharts/amcharts4/.internal/core/utils/Math";

export default function VizCard(props) {
  const {
    datasetInfo,
    index,
    setDataIndex,
    cardData,
    handleAdd,
    handleChange,
    handleDelete,
    apiManager,
    isEditing, 
    length,
  } = props;
  const [itemData, setItemData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [aisleIndex, setaisleIndex] = React.useState(0);
  const [query, setQuery] = React.useState({
    dataset: null,
    aisleName: null,
    geo: null,
    time: null,
    products: null
  });
  const [force, setForce] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [queryDate, setQueryDate] = React.useState('52 w/e 3.29.2020');
  const [dateRange, setDateRange] = React.useState(52);

  const testQuery = {
    dataset: 'floral',
          aisleName: '',
          geo: 'Total US - Multi Outlet',
          product: 'DEPT-FLORAL',
          metrics: ['Price per Unit', 'Price per Unit %Change vs YA' ],
          key: uuidv4(),
          queryDate: "4 we 11-29-20",   
  }
  const loadCardData = async () => {
    setIsLoading(true);
    const { dataset, aisleName, geo, product, metrics, queryDate } = cardData;
    const data = await apiManager.getPMIChartData({
      dataset, 
      aisleName,
      geo,
      time: queryDate,
      products: [product],
    });
    // find aisleIndex;
    datasetInfo.info.datasets.forEach((set, index) => {
      if (set.name === cardData.dataset.toUpperCase()) {
        setaisleIndex(index);
      }
    });
    setItemData(data && data.dataset && data.dataset[0] || '');
    if (!data || !data.dataset || data.dataset.length === 0) {
      const emptyItem = {};
      emptyItem[metrics[0]] = "N/A";
      emptyItem[metrics[1]] = "N/A";
      setItemData(emptyItem);
    }
    if (!isEditing) setIsLoading(false);
  };

  if (
    !anchorEl &&
    (query.dataset !== cardData.dataset ||
      query.geo !== cardData.geo ||
      query.products[0] !== cardData.product ||
      query.time !== cardData.queryDate || 
      query.aisleName !== cardData.aisleName)
  ) {
    // then updated
    setInit(false);
    setQuery({
      dataset: cardData.dataset,
      aisleName: cardData.aisleName,
      geo: cardData.geo,
      time: cardData.queryDate,
      products: [cardData.product]
    });
  }
  if (!init) {
    setInit(true);
    loadCardData();
  }
 
  //   if (typeof isEditing === 'number') {
  //     console.log('New Card')
  //     setIsLoading(true);
  //     setTimeout(() => {
  //       const ael = document.getElementById(`vizCard-${isEditing}`);
  //       if (ael) {
  //         setAnchorEl(document.getElementById(`vizCard-${isEditing}`));
  //         console.log(anchorEl);
  //         editClick();
  //       }
  //     }, 1000);
  //   }
  // }
  
  const formatNumber = (number, label) => {
    let num = number;
    let finalNum = num;
    let dollar = "";
    let percent = "";
    if (isNaN(number)) {    

      if (number === "N/A" || number === undefined) {
        return number;
      }
      dollar = number.includes("$") ? "$" : "";
      percent = number.includes("%") ? "%" : "";
      number = number
        .replace("$", "")
        .replace("%", "")
        .replace(/,/g, "");
      num = Number(number).toFixed(2);
    } else {
      let rounded = Number(num).toFixed(2);
      num = rounded;
      finalNum = `${rounded}`; // force to string
    }
    if (dollar === "" && (label.includes("$") || label.includes("Price"))) {
      dollar = "$";
    }
    if (percent === "" && label.includes("%")) {
      percent = "%";
      dollar = "";
    }

    if (num > 1000000000) {
      finalNum = `${(num / 1000000000).toFixed(2)}B`;
    } else if (num > 1000000) {
      finalNum = `${(num / 1000000).toFixed(2)}M`;
    } else if (num > 1000) {
      finalNum = `${(num / 1000).toFixed(2)}K`;
    } else {
      finalNum = num;
    }
    finalNum = finalNum
      .replace(".00B", "B")
      .replace(".00M", "M")
      .replace(".00K", "K");
    let finalString = `${dollar}${finalNum}${percent}`
      .replace("%%", "%")
      .replace("$$", "$");
    if (finalString.includes("$") && finalString.includes("%")) {
      finalString = finalString.replace("$", "");
    }
    return finalString;
  };
  const [settingsOpen, setSettingsOpen] = useState(false);
  const openSettings = e => {
    setSettingsOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const closeSettings = () => {
    //setAnchorEl(false)
    handleClose();
  };
  const [isNew, setIsNew] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const addClick = () => {
    setIsNew(true);
    handleAdd();
    handleClose(false); 
  };
  
  const [editOpen, setEditOpen] = useState(false);
  const editClick = () => {
    setEditOpen(true);
    //setIsLoading(true);
  };
  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteViz = () => {
    //some api call to delete
    if (handleDelete) {
      handleDelete(cardData, index);
    }
    handleClose();
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setDeleteOpen(false);
    setAddOpen(false);
    setEditOpen(false);
    setSettingsOpen(false);
    setIsNew(false);
  };

  const updateCard = () => {
    if (handleChange) {
        handleChange(cardData, isNew ? (length - 1) : index, false).then((response)=> {
        setIsLoading(false);
        handleClose();
      });
    }
  };

  const updateSettings = () => {
    closeSettings();
    updateCard();
  };
  

  const updateAisle = e => {
    cardData.dataset = e.target.value.toLowerCase();
    setForce(!force);

    const aisle = e.target.value;
    // find aisleIndex;
      datasetInfo.info.datasets.forEach((set, index) => {
      if (set.name === aisle) {
        if (index !== aisleIndex) {
          setaisleIndex(index);
          console.log(datasetInfo.info.datasets[index])
          // now have to fix up other card data
          cardData.aisleName = (aisle === 'FLORAL') ? '' : aisle;
          cardData.geo = datasetInfo.info.datasets[index].geos[0];
          cardData.product = datasetInfo.info.datasets[index].products[0];
          cardData.metrics = [
            datasetInfo.info.datasets[index].metrics[0],
            datasetInfo.info.datasets[index].metrics[1]
          ];
          cardData.queryDate = datasetInfo.info.datasets[index].times[1];
          //  closeSettings();
          //  loadCardData();
        }
      }
    })
  };
  
  const handleSelectDateSet = event => {
      let newDate = event.target.value;
      setDateRange(newDate)
      // if( newDate === 52) {
      //     setQueryDate('52 w/e 3.29.2020');
      // } else if{
      //     setQueryDate('13 w/e 3.29.2020');
      // }
  }
  const updateProduct = e => {
    cardData.product = e.target.value;
    setForce(!force);
  };

  const updateGeo = e => {
    cardData.geo = e.target.value;
    setForce(!force);
  };

  const updateMetric1 = e => {
    cardData.metrics[0] = e.target.value;
    setForce(!force);
  };

  const updateMetric2 = e => {
    cardData.metrics[1] = e.target.value;
    setForce(!force);
  };
  const updateDate = e => {
    cardData.queryDate = e.target.value;
    setForce(!force);
  };
  return (
    <Grid item xs={12} sm={6} md={3}>
      <div className="card">
        <span>
          {itemData && (
            <span>
              <div
                className={"branded cardtop brandColor1"}
                style={{ paddingLeft: 12, paddingRight: 12 }}
              >
                <div className="title">
                  {itemData.Product ? itemData.Product : "Not Available"}
                  <Button
                    id={`vizCard-${index}`}
                    className="menu"
                    onClick={openSettings}
                    style={{ padding: 0, paddingLeft: 18, minWidth: 1 }}
                  >
                    <FontAwesomeIcon icon={faEllipsisV} color={"white"} />
                  </Button>
                </div>
                <div className="subtitle">
                  {cardData.geo ? cardData.geo : "  "}</div>
              </div>
              {isLoading ? (
                <div>
                  <LoadingIndicator />
                </div>
              ) : (
                <div
                  className="cardcontent"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="metricTitle">{cardData.metrics[0]}</div>
                  <div className="value">
                    {formatNumber(
                      itemData[cardData.metrics[0]],
                      cardData.metrics[0]
                    )}
                  </div>
                  <div className="metricTitle">{cardData.metrics[1]}</div>
                  <div className="value">
                    {formatNumber(
                      itemData[cardData.metrics[1]],
                      cardData.metrics[1]
                    )}
                  </div>
                  <div className="dateRange">
                    {cardData.queryDate}
                  </div>
                </div>
              )}
            </span>
          )}
        </span>
      </div>
      {/* VizCard Menu */}
      <Menu
        id={`viz-card-menu-${index}`}
        anchorEl={anchorEl}
        // keepMounted
        open={settingsOpen}
        onClose={closeSettings}
      >
        <MenuItem onClick={editClick}>Edit Card</MenuItem>
        <MenuItem onClick={addClick}>Add Card</MenuItem>
        <MenuItem onClick={e => setDeleteOpen(true)}>Delete</MenuItem>
      </Menu>
      {/* Delete Dialog */}
      <DeleteDialog 
        open={deleteOpen}
        onClose={handleClose}
        isChart={false} 
        handleClose={handleClose}
        del={deleteViz}
        />
      {/* Edit Menu */}
      <Menu
        id={`viz-card-menu-${index}`}
        anchorEl={anchorEl}
        // keepMounted
        open={editOpen}
        onClose={closeSettings}
        className="editVizDialog"
      >
        <div className="holder">
          <div className="title">Customize this Card</div>
          <div className="gentle-flex">
            <Select
              className="vizCardSelector"
              name="align"
              //variant="outlined"
              value={cardData.dataset.toUpperCase()}
              onChange={updateAisle}
            >
              {datasetInfo &&
                datasetInfo.info.datasets.map(type => (
                  <MenuItem
                    value={type.name}
                    key={uuidv4()}
                    style={{ fontSize: 14 }}
                  >
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
            <Select
              className="vizCardSelector"
              name="align"
              //variant="outlined"
              value={cardData.product}
              onChange={updateProduct}
            >
              {datasetInfo &&
                datasetInfo.info.datasets[aisleIndex].products.map(product => (
                  <MenuItem
                    value={product}
                    key={uuidv4()}
                    style={{ fontSize: 12 }}
                  >
                    {product}
                  </MenuItem>
                ))}
            </Select>
            <Select
              className="vizCardSelector"
              name="align"
              //variant="outlined"
              value={cardData.geo}
              onChange={updateGeo}
            >
              {datasetInfo &&
                datasetInfo.info.datasets[aisleIndex].geos.map(geo => (
                  <MenuItem value={geo} key={uuidv4()} style={{ fontSize: 14 }}>
                    {geo}
                  </MenuItem>
                ))}
            </Select>
            <Select
              className="vizCardSelector"
              name="align"
              //variant="outlined"
              value={cardData.metrics[0]}
              onChange={updateMetric1}
            >
              {datasetInfo &&
                datasetInfo.info.datasets[aisleIndex].metrics.map(metric => (
                  <MenuItem
                    value={metric}
                    key={uuidv4()}
                    style={{ fontSize: 14 }}
                  >
                    {metric}
                  </MenuItem>
                ))}
            </Select>
            <Select
              className="vizCardSelector"
              name="align"
              //variant="outlined"
              value={cardData.metrics[1]}
              onChange={updateMetric2}
            >
              {datasetInfo &&
                datasetInfo.info.datasets[aisleIndex].metrics.map(metric => (
                  <MenuItem
                    value={metric}
                    key={uuidv4()}
                    style={{ fontSize: 14 }}
                  >
                    {metric}
                  </MenuItem>
                ))}
            </Select>
                <Select
                  className="vizCardSelector"
                  name="time"
                  labelId="timeframe"
                  id="timeframe-select"
                  value={cardData.queryDate}
                  onChange={updateDate}
                >
                  {datasetInfo && datasetInfo.info.datasets[aisleIndex].times.map(time => 
                    <MenuItem 
                      key={time} 
                      value={time}
                      style={{ fontSize: 14 }}>
                        {time}
                      </MenuItem>)}
                    </Select>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            float: "right",
            marginBottom: 20,
            marginRight: 16
          }}
        >
          <Button
            variant="text" //color='primary'
            size="small"
            onClick={updateSettings}
            style={{ float: "right" }}
          >
            Update
          </Button>
        </div>
      </Menu>
    </Grid>
  );
}
