import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
 
import moment from 'moment';

import RemoveUser from './RemoveUser';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PaginationRounded from '../../../components/PaginationRounded';
import BriefThumbnail from '../../Briefs/components/BriefThumbnail';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Grid,
  IconButton,
  Avatar,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  media: {
    height: 140,
  },
  avatar: {
      width: 60,
      height: 60,
      boxShadow: '1px 1px 10px #888888'
  },
  upDown: {
      fontSize: 28,
      display: 'inline-block',
      marginLeft: 24,
  },
  briefsection: {
      marginTop: 32,
      marginLeft: 4,
      marginBottom: 12,
  },
  brieftable: {
      fontSize: 18,
  },
  briefrow: {
    paddingTop: 12,
    paddingBottom: 14,
    borderBottom: '1px solid gray'
  },
  titlerow: {
      fontWeight: 900,

  },
  selectlabel: {
      fontSize: 11,
      fontWeight: 200,
  },
  checkbox: {
      paddingLeft: 8,
      marginRight: 12,
  },
  title: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
  },
  menuitem: {
      fontSize: 14,
      color: '#666'
  },
  avatarcolor1: {
    backgroundColor: '#25C2F4',
  },
  avatarcolor2: {
    backgroundColor: '#0052DD',
  }
});

/*
    Avatar colors:
    Primary Set – You can choose
#25C2F4
#0052DD
#000032

Secondary Set – If you need a few more
#EF8726
#54951C
*/
export default function PersonCard(props) {
  const [detailsOn, setDetails] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userBriefs, setBriefs] = React.useState( []);
  const [checkAll, setCheckAll] = React.useState(false);
  const [forcer, forceUpdate] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [totalItems, setTotalItems] = React.useState(0);
  const [isLoadingBriefs, setLoadingBriefs] = React.useState(true);
  const classes = useStyles();
  
  const { person, apiManager, hideDetails, handleDelete, updateAccess, refreshChild } = props;
  const initials = person.userData && person.userData.firstname ? `${person.userData.firstname.toUpperCase().charAt(0)}${person.userData.lastname.toUpperCase().charAt(0)}` : person.email.substring(0, 2);
  const data = props;
 

  const brief = props.brief;

  const detailsClick = async (person) => {
      setDetails(!detailsOn);
      setLoadingBriefs(true);
     // const briefs = await apiManager.getBriefs( person.email);
     const response = await apiManager.getUserSharesByAuthor({skip: 0, limit: 50, search: '', email: person.email });
      if (response) {
          const { briefs, count } = response;
          let briefData = [];
          setTotalItems(count);
          setPageCount(  count > 1 ? Math.ceil(count/limit) : 1);

          briefs.forEach((ub) => {
              ub.selected = false;
              briefData.push(ub);
          });
      
          setBriefs(briefData);
      }
      setLoadingBriefs(false);


      // ******** for testing...
    //   setBriefs([
    //       {title: 'Brief 1', date: 'Mar 03, 2020', selected: false},
    //       {title: 'Brief 2', date: 'Dec 21, 2019', selected: true}
    //   ]);
  //  data.detailsClick();
  }

  const handleCheck = name => event => {
        if (name === 'checkAll') {
            setCheckAll(!checkAll);
            userBriefs.forEach((ub) => {
                ub.selected = !checkAll;
            })
        } else {
            forceUpdate(!forcer);
            if (userBriefs[name].selected) {
                userBriefs[name].selected = !userBriefs[name].selected; // in this case name is the index
            } else {
                userBriefs[name].selected = true;
            }
            
            // setBriefs(userBriefs);
        }
  };
 
  const updateLimit = (newLimit) => {
    setLimit( newLimit );
  }

  const updatePage = () => {

  }


  const executeSelection = async (access) => {
      
    person.access = access;
    await updateAccess(person);
    setAnchorEl(null);
    refreshChild()

  }

  const openMenu =  event => {
    setAnchorEl(event.currentTarget);
  }
 
  const closeMenu = () => {
        setAnchorEl(null);
  }

  const renderNoBriefs = () => {
    return ( <div><br />This user has no access to any of your briefs</div>)
  }

  const renderBrief = (brief, index) => {
  
    const { briefData, access } = brief;
    if(!briefData.components){
      return null;
    }
    const title = briefData.components[0].content.text;
    return (
     <Grid container spacing={1} alignItems="flex-start" className={classes.briefrow}>
    <Grid item xs={1}>
    <Checkbox
        checked={brief.selected}
        onChange={handleCheck(index)}
        value={`briefChecked-${index}`}
        color="primary"
    />
    </Grid>
    <Grid item xs={6}>
    <BriefThumbnail brief={briefData} size="sm" />
       <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}>{title}</div>
    </Grid>
    <Grid item xs={2}>
        {briefData.created ? moment(briefData.created).format('MMM DD, YYYY hh:mm') : 'N/A'}
      </Grid>
    <Grid item xs={2}>
        { access }
    </Grid>
    <Grid item xs={1}>
    <div style={{ float: 'right', marginTop: 20, marginRight: 8 }}>
    <IconButton aria-label="close" className={classes.icon} size="small">
      <FontAwesomeIcon icon={faEllipsisV} color={'primary'} onClick={openMenu} />
      </IconButton> 
      </div>

    <Menu
        id="people-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
    > 
        <MenuItem onClick={e => executeSelection('viewer')} className={classes.menuitem}>Viewer</MenuItem>
        <MenuItem onClick={e => executeSelection('editor')} className={classes.menuitem}>Editor</MenuItem>
        <MenuItem onClick={e => executeSelection('admin')} className={classes.menuitem}>Co-Owner</MenuItem>
        { person.firstname ?  
        <MenuItem onClick={e => executeSelection('admin')} className={classes.menuitem}>Resend Invite</MenuItem>
        : null }
        <MenuItem onClick={e => executeSelection('admin')} className={classes.menuitem}>Remove Access</MenuItem>
    </Menu>
    </Grid>
</Grid>)
  }

  const renderBriefs = () => {
    return ( <div>This user has access to the following briefs:
      <div className={classes.brieftable}>
      <Grid container spacing={0} alignItems="flex-start" className={classes.briefrow}>
          <Grid item xs={1}>
                    <div className={classes.selectlabel}>Select All</div>
                    <Checkbox
                        checked={checkAll}
                        onChange={handleCheck('checkAll')}
                        value="checkAll"
                        color="primary"
                    />
          </Grid>

          <Grid item xs={6} style={{paddingTop: 22, paddingLeft: 96}}>
              Brief Title
          </Grid>
          <Grid item xs={2} style={{paddingTop: 22}}>
                    Publish Date
          </Grid>
          <Grid item xs={2} style={{paddingTop: 22}}>
                    Access
          </Grid>
          <Grid item xs={1}>
                    {/* <div style={{ float: 'right', marginTop: 15 }}>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMenu}>
                            Manage <i class="fa fa-caret-down" style={{marginLeft: 12}}></i>
                        </Button>
                    </div> */}
                     <div style={{ float: 'right', marginTop: 20, marginRight: 8 }}>
                    <IconButton aria-label="close" className={classes.icon} size="small">
                      <FontAwesomeIcon icon={faEllipsisV} color={'primary'} onClick={openMenu} />
                      </IconButton> 
                      </div>

                    <Menu
                        id="people-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                    >
        <MenuItem onClick={e => executeSelection('viewer')} className={classes.menuitem}>Viewer</MenuItem>
        <MenuItem onClick={e => executeSelection('editor')} className={classes.menuitem}>Editor</MenuItem>
        <MenuItem onClick={e => executeSelection('admin')} className={classes.menuitem}>Co-Owner</MenuItem>
                    </Menu>
                </Grid>
      </Grid>
            {userBriefs.map((b, index) => (
                renderBrief(b, index)
      ))

            }
          {userBriefs && userBriefs.length > 0 && (
              <Grid item xs={12}>
                  <div style={{ textAlign: 'right', marginTop: 8, fontSize: 14 }}>
                  {`${totalItems} Brief${totalItems === 1 ? '' : 's'} `} <PaginationRounded totalItems={totalItems} count={pageCount} updateLimit={updateLimit} limit={limit} page={page} updatePage={updatePage} />
                  </div>
              </Grid>
          )}
      </div>

  </div>)
  }

  const display = hideDetails == false ? 'inline-block' : 'none';

  return (
     <div style={{ background: '#fff', padding: '20px 20px 60px 20px', marginBottom: '10px'}}>
        <div style={{ float: 'left', paddingRight: '20px' }}>
        <Avatar
          alt="Person"
          className={`${classes.avatar} ${classes.avatarcolor1} brandColor2`} 
         >
        {initials}
        </Avatar>
        </div>
        <span>
          <Typography gutterBottom variant="h5" component="h2">
          { `${person.userData && person.userData.firstname ? person.userData.firstname : ''} ${person.userData && person.userData.lastname ? person.userData.lastname : ''}`}
         
          {/* { person._id } */}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {person.email} | Invited: { moment(person.created).format('MM-DD-YYYY') } { hideDetails == true ? ' | Access: ' + person.access : '' }  
          </Typography>
          </span>
          <div style={{ float: 'right', height: '50px', paddingRight: '20px', position: 'relative', top: '-50px' }}>
             <RemoveUser person={person} apiManager={apiManager} handleDelete={e => handleDelete(person)} classes={classes}/>
              <div onClick={e => detailsClick(person)} className={classes.upDown} style={{ display: `${display}` }}>
                  {detailsOn ? 
                   <i className="fa fa-caret-down"></i>
              :
                    <i className="fa fa-caret-up"></i>
                  }
              </div>
          </div>
          { hideDetails == false && detailsOn && (
              <div className={classes.briefsection}>
                  {isLoadingBriefs ? <LoadingIndicator /> : userBriefs.length === 0 ? renderNoBriefs() : renderBriefs() }
                   

              </div>
          )

          }
      </div>
  );
}