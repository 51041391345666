import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles'; 
import { Button } from '@material-ui/core'
import TeamGrid from './components/TeamGrid'
import CreateTeam from './components/CreateTeam'
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

const styles = (theme => ({
    root: {
      padding: theme.spacing(4),
      minHeight: '90vh'
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const Teams =  (props) => {
  const { history, apiManager, classes, user } = props; 

  const [id, setId] = React.useState('');
  const [spacing, setSpacing] = React.useState(2);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(25); 
  const [skip, setSkip] = React.useState(0); 
  const [teams, setTeams] = React.useState(false);  
  const [org, setOrganization] = React.useState(false);  
  const [plugins, setPlugins] = React.useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  const getData = async (overrideSearch) => {
      const paths = history.location.pathname.split('/');
      const id = paths[paths.length - 1]
      
    const params = {
        id
    }
    
    

    const teams = await apiManager.getTeams(params);
   
    if(teams){
      setTeams(teams);
    }

    const plugins = await apiManager.getPlugins({ skip: 0, limit: 25, search: '' });
    setPlugins(plugins);

    const org = await apiManager.getOrganization(params);
    
    if(org){
      setOrganization(org);
      setId(id)
    }
    
  }
  
   

  if(!org){
    getData();
  }

  const deleteAsset = async (asset) => {
      await apiManager.deleteUser(asset);
      await getData();
  }
 

  const updateAsset = async (team) => { 
      await apiManager.updateTeam(team);
      await getData();
  } 
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const goBack = () => {
    history.push(`/owner/${id}`)
  }

 
 
 

  const addTeam = async(team) => {
    const paths = history.location.pathname.split('/');
    const id = paths[paths.length - 1]
    team.id = id;
    await apiManager.createTeam(team);
    await getData();
  }

  const handlePlugins = plugins => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ['right']: plugins
      } 
    }));
  }


  return ( 
    <div className={classes.root}>
      <div>
        <Button 
        onClick={goBack}>
        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 12}}/>BACK
        </Button>
        <h4>TEAMS</h4>
      </div>
      <div>
       <CreateTeam handleUpdate={addTeam} plugins={plugins} handlePlugins={handlePlugins} asset={{ plugins: [] }} />
      </div>
       <br /><br />
     
      <TeamGrid apiManager={apiManager} org={org} teams={teams} plugins={plugins} handlePlugins={handlePlugins} apiManager={apiManager} />
        
    </div>
  );
};

Teams.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(Teams);
