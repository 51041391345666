import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ExpiredTrialModal(props) {
  const { classes, funcs, open, history, diff } = props;

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={funcs.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Let's make this official!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Your free trial has ended, but this doesn't have to end. Upgrade now to continue using Breefly without limits or interruptions. Simply click below to select your plan.</p>
              <p>Not ready? No worries. You can keep a basic account with limited features. When you're ready, you can upgrade anytime via the Billing page. </p>
            </DialogContentText>
              
          </DialogContent>
          <DialogActions>
            <Button onClick={funcs.handleClose} color="primary">
            <span style={{fontWeight: 300}}>Cancel</span>
            </Button>
            <Button onClick={funcs.stripePaymentInfo} color="primary">
              Upgrade Now
            </Button>
          </DialogActions>
        </Dialog>
    );
}
