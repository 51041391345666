import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, 
  Box,
  Link, 
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core'; 
import TextField from '@material-ui/core/TextField'; 

import Autocomplete from '@material-ui/lab/Autocomplete';

import HelpIcon from '@material-ui/icons/Help';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 
import { DeleteBrief, TagBrief } from '../Modals';
 
import { faFont, faCamera, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import moment from 'moment';

const styles = (theme => ({
    root: {
      padding: theme.spacing(4),
      minHeight: '90vh'
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const Impersonate =  (props) => {
  const { history, apiManager, classes, updateUser } = props; 

  const [token, setToken] = React.useState(''); 
  const [spacing, setSpacing] = React.useState(2);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(12); 
  const [skip, setSkip] = React.useState(0); 
  const [assets, setAssets] = React.useState(false);  
  
  const getData = async (overrideSearch) => {
    const params = {
      limit,
      skip,
      search: overrideSearch ? overrideSearch : search
    }
     
    const token = history.location.search.replace("?token=", "");
    

    if(token){
      sessionStorage.setItem('token', token);

      const user = await apiManager.getUserByToken(token);
 
      if(user){
        updateUser(user);

        history.push('/account');
      }
      else {
        //history.push('/error')
      }
    }
    else {
      history.push('/error')
    }

    //const userAssets = await apiManager.getLogs(params);
    setAssets([]);
  }
 
  if(!assets){
    getData();
  }

  const handleDelete = async (asset) => {
      await apiManager.deleteEmail(asset);
      await getData();
  }
 
  const clickEdit = () => {
       
  }

  const updateSearch = async (isPhoto) => {
    
    await getData();
  }

 
 
  const renderImpersonate = () => {
    if(!assets) {  
      return null 
    }
    const html = (assets.map(function(value){
      return <Grid
      item
      lg={2}
      sm={2}
      xl={2}
      xs={2}
      spacing={spacing}
    > 
      { token }
    </Grid>
    }))
    return html;
  }

  
  
 
  return ( 
    <div className={classes.root}>
      
    <Grid
      container
      spacing={4}
    >
         
    
           
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
        <Grid
         container   spacing={spacing}
        >
           { renderImpersonate() }
 
            </Grid> </Grid>
   
      </Grid> 
    </div>
  );
};

Impersonate.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func,
  apiManager: PropTypes.object
};

export default withStyles(styles)(Impersonate);
