import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'; 
import { 
  Grid,
  TextField,  
  IconButton
} from '@material-ui/core';

import { faUserAlt, faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PersonPanel from "./components/PersonPanel"
import BriefPanel from "./components/BriefPanel"
import PaginationRounded from '../../components/PaginationRounded';
import CreateBriefButton from '../../components/CreateBriefButton';

import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';

import '../People/css/style.css';
import SubscriptionModal from '../../components/SubscriptionModal';
import eventTracker from "../../utils/event-tracker";

const styles = (theme => ({
    root: {
        display: 'unset',
        // padding: theme.spacing(4),
        // minHeight: '90vh',
        // '& .MuiTextField-root': {
        // },
        // '& .MuiOutlinedInput-root': {
        //     height: 44,
        // },
        // '& .MuiInputLabel-outlined': {
        //     paddingTop: 2,
        // }
    }, 
    img: {
        maxWidth: '600px'
    },
    viewby: {
        display: 'inline-block',
        marginLeft: 10,
        fontWeight: 400,
    },
    textField: {
    },
    searchbar: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        height: 100, 
        paddingTop: 8,
        '@media (max-width: 650px)': {
            textAlign: 'left',
            paddingLeft: 46,
            height: 80,
        },
    },
    searchform: {
        width: '50%',
        '@media (max-width: 860px)': {
            width: '33%',
        },
        '@media (max-width: 500px)': {
            width: '40%',
            minWidth: 180,
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            marginTop: 24,
        },
    }, 
    mobileCTA: {
        position: 'absolute',
        top: 8,
        right: -24,
  
    },
  }));

const People = (props) => {
  const { history, apiManager, classes,  isMobile, refreshChild, user } = props;

 
  const data = props;

  const [isPeople, setIsPeople] = React.useState(true); 
  const [search, setSearch] = React.useState('');
  const [helperText, setHelperText] = React.useState('');
  const [briefCount, setBriefCount] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [isTyping, setIsTyping] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(isMobile ? 24 : 4);
  
  const [people, setPeople] = React.useState(false);
  const [briefs, setBriefs] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
 
  const getPeople = async (newLimit, newPage, newSearch) => {
    const sendLimit = newLimit ? newLimit : limit;
    const sendPage = newPage != null ? newPage : page;
    
    const data = await apiManager.getPeople({skip: (sendLimit * sendPage), limit: sendLimit, search: newSearch ? newSearch : search});
   
    
    
    if(data){
      const { people, count } = data;
      setPeople(people);
   
      setUserCount( count );
    }
    else {
      setTimeout(function() {
        //getPeople();
      }, 2000)
    }
 
  }

  window.isTyping = false;

  const getBriefs = async (newLimit, newPage, newSearch) => {
    const sendLimit = newLimit != null ? newLimit : limit;
    const sendPage = newPage != null ? newPage : page;

      // *************************** TODO: look to see if search starts with title: or tags: or name:  ********************* //
      // *************************** note prompt says to separate tags with commas so search would be: tags: tag1, tag2,tag3 , tag4, ****** //

      const response = await apiManager.getMySharedBriefs({skip: (sendLimit * sendPage), limit: sendLimit, search: newSearch ? newSearch : search});
      if (response) {
            const { briefs, count } = response;
            setBriefCount(count);
            setBriefs(briefs);
      } else {
          setBriefs([]); // so we know we've tried.
      }
  }

  if(!init && !people){
    if(!init){
      getPeople();
      setInit(true)     
    }  
  }
 
 
  const handleChangeSearch = async (e) => {
      const newString = e.target.value;
      const key = e.key;
      setSearch(newString);
      let ht = '';
      if (newString.length > 0) {
          ht = `Search by email, first name, last name or brief title` //titles and tags or filter with 'name:', 'title:', or 'tags:'`;
          if (newString.toLowerCase().startsWith('title:')) {
            ht = 'Search titles';
          } else if (newString.toLowerCase().startsWith('tags:')) {
              ht = 'Search tags separated by commas (e.g. tag1, tag2, etc.)';
          } else if (newString.toLowerCase().startsWith('name:')) {
            ht = 'Search for people';
        }
      }
      setHelperText(ht);
      
      
      
  }

  const handleSearchKeyPress = async(e) => {
      const key = e.key;
      if( key === 'Enter' && search !== '') {
        setPage(0);
        if(isPeople){
          await getPeople(limit, 0, search);
        }
        else {
          await getBriefs(limit, 0, search);
        }
      }
  }

  const updateLimit = (newLimit) => {
    setLimit( newLimit );
    if(isPeople){
      getPeople(newLimit, page);
    }
    else {
      getBriefs(newLimit, page);
    }
  }

  const updatePage = (event, page) => {
    
    setPage(page - 1);
    if(isPeople){
      getPeople(limit, page - 1);
    }
    else {
      getBriefs(limit, page - 1);
    }
  }

  const updateAccess = async (person) => {
    await apiManager.updateAccess(person);
    //await getBriefs();
    //await getPeople();
  }
  

  const handleDelete = async (person) => {
   
    await apiManager.deleteInvite(person);
    await getPeople();
  }
  const [createError, setCreateError] = useState('');
  const createBrief = async () => {
    const brief = {
      title: 'Enter your title...',
      tags: [],
      briefcase: { value: 0 },
      components: [
          {
              "type": (0),
              "content": {
                  "text": "Enter your title..."
              },
              "position": (0)
          }
      ]
    }
    const result = await apiManager.createBrief(brief);
    eventTracker('Brief Creation', user, 'acces-management')
    if (result && result.insertedCount > 0) {
        history.push(`/brief/edit/${result._id}`);
    }
    else {
      if(result && result.msg) {
        setCreateError(result.msg)
      } else {
       //  alert(result && result.msg ? result.msg : 'An error has occurred')
       setSnackMessage(result && result.msg ? result.msg : 'Sorry, an error has occurred');
       setSnackBar(true);
      }
    }
  }

  const renderPeople = () => {
    if (!people) {
       return null;
    } 
    if (people && people.length === 0) {
        return (
            <span style={{paddingTop: 16, paddingLeft: 16}}>Sorry, no results. Refine your search and try again, or try sharing a brief</span>)
        }

    const html = people.map(function(value){
      return ( <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        key={Math.random()}
      > 
      <PersonPanel person={value} apiManager={apiManager}  hideDetails={false} handleDelete={handleDelete} updateAccess={updateAccess} refreshChild={refreshChild} isMobile={isMobile} />
      <br />
      </Grid>)
    }) 
    return html;
  }
 
  const handleStopShare = async (brief) => {

    await apiManager.deleteShare(brief);
    await getBriefs();
  }
  

  const renderBriefs = () => {
    if( briefs === false) {
 
        return null;
    }

    if ( briefs.length === 0) return 'Sorry, no results. Refine your search and try again, or try sharing a brief';

    const html = briefs.map(function(value){
     
      const brief = value.briefData;
      return ( <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        key={Math.random()}
      > 
      <BriefPanel brief={value} apiManager={apiManager}  isMobile={isMobile} hideDetails={false} handleDelete={handleStopShare} updateAccess={updateAccess} refreshChild={refreshChild}  isMobile={isMobile} user={user}/>
        <br />
      </Grid>)
    }) 
    return html;
  }
 
  const setFilter = async (isPeople) => {
    setIsPeople(isPeople);
    await setPage(0);
    //await setSearch('')
    if(isPeople){
      await getPeople(limit, 0, '');
    }
    else {
      await getBriefs(limit, 0, '');
    }
  }
  
 
  return ( 
    <div className={classes.root}>

    { /* searchbar */}
    <div className={classes.searchbar}> 
         <TextField  size='small'
                    className={classes.searchform} 
                    fullWidth 
                    label="Search"
                    name="search"
                    value={search}
                    onChange={handleChangeSearch}
                    onKeyPress={handleSearchKeyPress}
                    helperText={helperText}
                    type="" 
                    variant="outlined"
                  />
                  <div className={isMobile ? classes.mobileCTA : classes.primaryCTA}>
                        <CreateBriefButton
                            handleClick={createBrief}
                            isMobile={isMobile}
                        />
                    </div>
    </div>

        { /* main content */}
    <Grid
      container
      spacing={4}
    >
         
         <Grid
          item
            sm xs={12}
        > 
                  {/* <div style={{color: '#22b9ea', display: 'inline-block', marginLeft: 6, marginRight: 18, fontWeight: 500, fontSize: 16, verticalAlign: 'middle'}}>
                            MANAGE ACCESS 
                 </div> */}
                 <div style={{textAlign: `${isMobile ? 'center' : 'left'}`}}>
                  <IconButton aria-label="grid"
                            onClick={e =>setFilter(true)}
                            style={{fontSize: 14, color:`${isPeople ? '#333' : '#bbb'}` }}
                        >
                            <div style={{ fontSize: 22, marginRight: 8 }}>
                                <FontAwesomeIcon size="sm" icon={faUserAlt} />
                            </div>
                            {isMobile ? 'By User' : 'View by User'}
                        </IconButton>
                        <IconButton aria-label="grid"
                            onClick={e =>setFilter(false)}
                            style={{fontSize: 14, color:`${!isPeople ? '#333' : '#bbb'}`}}
                        >
                            <div style={{ fontSize: 22, marginRight: 8 }}>
                                <FontAwesomeIcon size="sm" icon={faCopy} />
                            </div>
                           {isMobile ? 'By Brief' : 'View by Brief'}
                    </IconButton>
                </div>

            </Grid> 
              <Grid item sm xs={12}>
                  <div style={{ textAlign: 'right' }}>
                      {!isMobile && ((isPeople && userCount > 0) || (!isPeople && briefCount > 0)) &&  (
                          <>
                              {isPeople ? `${userCount} User${userCount === 1 ? '' : 's'} ` : `${briefCount} Brief${briefCount === 1 ? '' : 's'}`}
                          </>
                      )}
                      <PaginationRounded totalItems={isPeople ? userCount : briefCount} count={isPeople ? (userCount / limit) : (briefCount / limit)} updateLimit={updateLimit} limit={limit} page={page} updatePage={updatePage} isMobile={isMobile} />
                  </div>
              </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <div style={{marginTop: `${isMobile ? '-24px' : 0}`}}>
        { isPeople ? renderPeople() : renderBriefs() }
        </div>
        </Grid>
      </Grid> 
      <Snackbar
                open={showSnackBar}
                autoHideDuration={3000}
                className="error"
                severity="info"
                onClose={()=> setSnackBar(false)}
                variant="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error">{snackMessage}</Alert>
                
            </Snackbar>
            {createError && <SubscriptionModal 
              open={Boolean(createError)}
              message={createError}
              onClose={() => setCreateError('')}
              user={user}
              modalTitle={"briefs"}
            />}
    </div>
  );
};

People.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(People);
