import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../style.css'

export default function BrandedCardTitle(props) {
  

  const [user, updateUser] = React.useState(false);

  const { title, cta, icon, callback, color, pagination } = props;
 
  return (
    <div className={`branded cardtop ${color ? color : 'brandColor2'}`}> 
        {title}
        {cta && (
            <div className={`cardCTA`} onClick={callback}>
                {icon && (
                   <FontAwesomeIcon icon={icon} style={{marginRight: 12}} />
                )}{cta}
            </div>
        )}
        {pagination && (
            <div className={`cardCTA`}>
                {pagination}
            </div>
        )}
    </div>
  );
}
