import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  TextField
} from "@material-ui/core";
import BrandedCardTitle from "./BrandedCardTitle";
import validator from "email-validator";

export default function InviteSubscriberForm({
  openInvite,
  setOpenInvite,
  apiManager,
  openSnack,
  // org,
  user
}) {
  //Form data and handler
  const [inviteFormData, setInviteFormDetail] = useState({
    firstname: "",
    lastname: "",
    email: "",
    type: "affiliate",
    companyid: "",
    userid: "",
    promocode: "",
    note: ""
  });

  const [isDirty, setDirty] = useState({
    email: false,
    firstname: false,
    lastname: false,
    promocode: false,
  });


  const clearForm = () => {
    setInviteFormDetail({
      firstname: "",
      lastname: "",
      email: "",
      type: "affiliate",
      companyid: "",
      userid: "",
      promocode: "",
      note: ""
    });
  };

  const handleInviteChange = event => {
    const {name, value} = event.target;
    isDirty[name] = true;
    setDirty(isDirty);
    setInviteFormDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  //API Call on submit
  const inviteAffiliate = async () => {
    const inviteList = [inviteFormData];
    const response = await apiManager.inviteAffiliate(inviteList, user.orgId);
    console.log(response)
    if(response && response.msg === "invalid promo code"){
      openSnack(false, response.details)
      setOpenInvite(false);
    } else {
      clearForm();
      setOpenInvite(false);
    }
    
  };

  //Validate form and disable/enable submit button
  const [disabled, setDisabled] = useState(true);
  const validateInputs = () => {
    let f = inviteFormData.firstname.length > 0;
    let l = inviteFormData.lastname.length > 0;
    let e = validator.validate(inviteFormData.email);
    let t = inviteFormData.type === "affiliate";
    // let p = inviteFormData.promocode.length > 3;
    if (f && l && e && t) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    validateInputs();
  }, [inviteFormData]);
  return (
    <Dialog
      open={openInvite}
      onClose={() => setOpenInvite(false)}
      fullWidth={true}
      maxWidth={"xs"}
      aria-labelledby="Team Alert"
    >
      <Card className={`branded card`}>
        <BrandedCardTitle title="Invite a Subscriber" />
        <CardContent className="file-upload-card-content">
          <div className="file-upload-data-container two">
            <div className="column">
              <span style={{ paddingBottom: 40, marginTop: 22 }}>
                First Name
              </span>
              <span style={{ paddingBottom: 44 }}>Last Name</span>
              <span style={{ paddingBottom: 42 }}>Email</span>
              <span style={{ paddingBottom: 70 }}>Company ID</span>
              <span style={{ paddingBottom: 60 }}>User ID</span>
              {/* <span style={{ paddingBottom: 60 }}>Promo Code</span> */}
              <span>Add a note</span>
              {/* <span style={{ paddingBottom: 14 }}>Data Access</span> */}
            </div>
            <div className="column">
              <TextField
                required
                name="firstname"
                label="First Name"
                error={inviteFormData.firstname.length === 0}
                fullWidth
                value={inviteFormData.firstName}
                onChange={handleInviteChange}
              />
              <TextField
                required
                name="lastname"
                label="Last Name"
                fullWidth
                error={inviteFormData.lastname.length === 0}
                value={inviteFormData.lastName}
                onChange={handleInviteChange}
              />
              <TextField
                required
                name="email"
                label="Email"
                error={validator.validate(inviteFormData.email) ? false : true}
                fullWidth
                value={inviteFormData.email}
                onChange={handleInviteChange}
              />
              <TextField
                name="companyid"
                label="Company ID"
                fullWidth
                value={inviteFormData.companyid}
                onChange={handleInviteChange}
                helperText="Optional"
              />
              <TextField
                name="userid"
                label="User ID"
                fullWidth
                value={inviteFormData.userid}
                onChange={handleInviteChange}
                helperText="Optional"
              />
              {/* <TextField
                // required
                name="promocode"
                label="Promo Code"
                fullWidth
                value={inviteFormData.promocode}
                // error={inviteFormData.promocode.length === 0}
                onChange={handleInviteChange}
                helperText="Optional"
              /> */}
              <TextField
                name="note"
                label="Note"
                placeholder="Add a message"
                multiline
                rows={4}
                fullWidth
                value={inviteFormData.note}
                onChange={handleInviteChange}
              />
            </div>
          </div>
        </CardContent>

        <DialogActions>
          <Button
            onClick={() => {
              clearForm();
              setOpenInvite(false);
            }}
            className="cancel-button"
          >
            Cancel
          </Button>

          <Button
            onClick={inviteAffiliate}
            className={disabled ? "no-upload" : ""}
            variant="text"
            disabled={disabled}
            //className="brandColor2"
          >
            Invite
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
}
