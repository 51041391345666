import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import {
  Grid,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import TermsAndConditions from '../Modals/TermsAndConditions'
import RegisterSnackBar from './components/RegisterSnackBar'
import CircularProgressButton from './components/CircularProgressButton'

import axios from 'axios';
import { API_LOCAL } from '../../constants';
import './css/style.css';

// import image from './logo.png';
import logoImage from '../../images/Breefly_Logo-Horiz.svg';

const schema = {
  firstname: {
    presence: { allowEmpty: false, message: '^First name is required' },
    length: {
      maximum: 32
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: '^Last name is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 128
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 20,
      minimum: 6
    }
  },
  confirm: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 20,
      minimum: 8
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    //height: '100vh',
    //minHeight: '100%',
    '& .MuiLink-root': {
      color: '#22b9ea',
    },
    '& .MuiTypography-gutterBottom': {
      marginBottom: 24,
    },
  },
  grid: {
    height: '100%',
    alignCenter: 'center'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  contentContainer: {},
  content: {
    height: '90%',
    // margin: 'auto',
    textAlign: 'center',
    maxWidth: '700px'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginBottom: 30,
  },
  tagLine: {
    color: '#333',
    fontWeight: 500,
    fontSize: 22,
    marginBottom: 16,
    '@media (max-width: 820px)': {
      fontSize: 18,
    },
  },
  panelSpacer: {
    width: 1,
    height: '10vh',
    minHeight: 48,
  },
  contentBody: {
    flexGrow: 1,
    // display: 'flex', 
    width: '90vw',
    // minWidth: 300,
    maxWidth: 450,

  },
  form: {
    // borderColor: '#00BFFF',
    // borderStyle: 'solid',
    // borderWidth: 'medium',
    paddingTop: 50,
    borderRadius: '8px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    background: '#fff'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  policy: {
    marginTop: theme.spacing(1),
    // display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  policyCheckbox: {
    // marginLeft: '-14px'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pageBody: {
    backgroundColor: '#000',
    height: '100%'
  },
  emailField: {
    marginBottom: 24,
  },
  textField: {
    maxWidth: 350,
    marginTop: theme.spacing(2),
    '@media (max-width: 820px)': {
      maxWidth: 280,
    },
  }
}));

const SignUp = props => {
  const { history, updateUser } = props;

  const token = sessionStorage.getItem('token');
  const [isModalOpen, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccessfull, setSuccess] = React.useState(false);
  const classes = useStyles();

  const [init, setInit] = useState(false);
  const validateToken = async () => {

    const url = (API_LOCAL) + 'users/auth/token';
    const result = await axios.post(url, { token })

    if (result && result.data && result.data.email) {
      updateUser(result.data);
      // updateIntercom(result.data);
      sessionStorage.setItem('token', result.data.token);
      const { search } = history.location;
      let redirect = '/briefs';
      if (search && search.indexOf("?redirect=") > -1) {
        redirect = search.replace("?redirect=", "");
      }
      //history.push(redirect);
      window.location = redirect;
    }
  }

  if (!init && token) {
    setInit(true)
    const orgId = sessionStorage.getItem('organizationId');
    const orgBranding = sessionStorage.getItem('orgBranding');
    sessionStorage.clear();
    if (orgId) { sessionStorage.setItem('organizationId', orgId); }
    if (orgBranding) { sessionStorage.setItem('orgBranding', orgBranding); }
    validateToken();
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const isLength = (value, min) => {
    return value && value.length >= min;
  }

  // const updateIntercom = (user) => {
  //   window.intercomSettings = {
  //     app_id: "zcl62xdv",
  //     name: `${user.firstname} ${user.lastname}`,
  //     email: `${user.email}`,
  //     created_at: `${user.created}`
  //   }
  //   window.Intercom('boot', window.intercomSettings);
  //   sessionStorage.setItem('intercom', JSON.stringify(window.intercomSettings));
  // }

  const validateForm = () => {
    let isSuccess = true;
    let msg = `Invalid form data, please fix and try again`;

    const { firstname, lastname, email, password, confirm, policy } = formState.values;

    isSuccess = (isLength(firstname, 1) && isLength(lastname, 1) && isLength(lastname, 4) && isLength(password, 6) && policy === true)

    let errorCount = 0;

    if (!isLength(firstname, 1)) {
      formState.touched['firstname'] = true;
      errorCount++;
    }

    if (!isLength(lastname, 1)) {
      formState.touched['lastname'] = true;
      errorCount++;
    }

    if (!isLength(email, 4)) {
      formState.touched['email'] = true;
      errorCount++;
    }

    if (!policy) {
      formState.touched['policy'] = true;
      errorCount++;
    }

    if (!isLength(password, 6)) {
      formState.touched['password'] = true;

      formState.touched['confirm'] = true;

      if (errorCount === 0) {
        msg = `Password must be at least 6 alpha numeric characters`
      }
    }

    if (password !== confirm) {
      formState.touched['confirm'] = true;
      formState.touched['password'] = true;

      formState.errors['password'] = ['Passwords do not match'];
      formState.errors['confirm'] = ['Passwords do not match'];

      if (errorCount === 0) {
        msg = `Passwords do not match`
      }
      isSuccess = false;
    }


    setFormState(formState)

    if (!isSuccess) {
      setSuccess(false);
      setLoading(false);
      setMessage(msg);
      setOpen(true)
    }


    return isSuccess;
  }

  const registerClick = async (event) => {
    event.preventDefault();

    if (validateForm()) {

      setLoading(false);
      const url = (API_LOCAL) + '/users/auth/signup';

      try {
        setLoading(true);
        setSuccess(false);
        const result = await axios.post(url, formState.values)
        setLoading(false);

        if (result && result.data && result.data.email) {

          await updateUser(result.data);
          // updateIntercom(result.data);
          sessionStorage.setItem('token', result.data.token);
          //history.push('/briefs'); 
          const { search } = history.location;
          let redirect = '/briefs';
          if (search && search.indexOf("?redirect=") > -1) {
            //redirect = search.replace("?redirect=", "");
          }
          window.location = redirect
        }
        else {
          setSuccess(false);
          setLoading(false);
          setMessage(`${result.data.code}: ${result.data.msg}`);
        }



      }
      catch (ex) {
        console.log(ex);
        setFormState(formState => ({
          ...formState,
          error: ex
        }));
        setSuccess(false);
        setLoading(false);
        //setMessage(ex);

      }
      setOpen(true)
    }
  };

  const closeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <style>{
        `.pageBody { background: linear-gradient(#000055, black) }
      .MuiButton-containedPrimary, .MuiButton-label {
        color: #000 !important;
      
        background-color: transparent !important; 
      }
      `
      }</style>
      <Grid
        className={classes.grid}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.panelSpacer} />
            <div className={classes.contentBody}>
              <form
                className={classes.form}
              >
                <Typography
                  color="textSecondary"
                  align="center"
                  paragraph
                >
                  <img src={logoImage} alt="Breefly.com" width="200px" align='middle' className={classes.logoImage} />
                  <div className={classes.tagLine}>Complete your sign up:</div>
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="standard"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('firstname')}
                  fullWidth
                  helperText={
                    hasError('firstname') ? formState.errors.firstname[0] : null
                  }
                  label="First name"
                  name="firstname"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstname || ''}
                  variant="standard"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('lastname')}
                  fullWidth
                  helperText={
                    hasError('lastname') ? formState.errors.lastname[0] : null
                  }
                  label="Last name"
                  name="lastname"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastname || ''}
                  variant="standard"
                />

                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="standard"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('confirm')}
                  fullWidth
                  helperText={
                    hasError('confirm') ? formState.errors.confirm[0] : null
                  }
                  label="Confirm Password"
                  name="confirm"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.confirm || ''}
                  variant="standard"
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <TermsAndConditions />
                </div>
                {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}

                <CircularProgressButton isLoading={isLoading} onClick={registerClick} ></CircularProgressButton>
                <RegisterSnackBar isOpen={isModalOpen} isSuccessfull={isSuccessfull} message={message} closeModal={closeModal} />
                <Typography
                  color="textSecondary"
                  variant="body1"
                  style={{ marginTop: '15px' }}
                >
                  Have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/signin"
                    variant="h7"
                  >
                    Sign In
                  </Link>
                </Typography>
              </form>
            </div>
          </div>

        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withRouter(SignUp);
