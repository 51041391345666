import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  body: {
    fontSize: 14
  },
  typography: {
    fontSize: 24
  },
  breakpoints: {
      values: {
    xs: 0,
    sm: 750,
    md: 990,
    lg: 1234,
    xl: 1400,
      }
  },
  palette: {
    type: 'light',
    default: {
      main: '#eef',
      light: '#f0f',
      dark: '#888'
    },
    primary: {
      light: '#ddf',
    //  main: '#333',
      main: '#22b9ea',
      color:'#22b9ea',
      dark: '#1183ca',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#222'
    },
    appBar: {
      color: 'primary'
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#ccf',
          fontWeight: 300,
          fontSize: 16
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '#fff'
          },
          '&:hover:not($disabled):before': {
            borderBottom: '#f00'
          },
          '&:after': {
            borderBottom: '2px solid grey'
          }
        }
      }, 
      MuiButton: { // Name of the component ⚛️ / style sheet
            label: { // Name of the rule
                color: '#000', // Some CSS
            },
            containedPrimary: {
                backgroundColor: 'yellow !important'
            }
        },
    }
  }
});

export default theme;
