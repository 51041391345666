import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';  
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';   
import {
  Grid,
  Button,
  IconButton,
  TextField, 
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  media: {
    height: 140,
    maxWidth: 300
  },
});

export default function EnterpriseCard(props) {
  const classes = useStyles();
  const { history, apiManager, asset, updateAsset, deleteAsset, updatePassword } = props;
  const data = props;

  const[isPreviewOpen, setPreviewOpen] = React.useState(false);
  

  const detailsClick = () => {
    history.push(`/plugins/detail/${asset._id}`)
  }
 
 
  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={detailsClick}
      > 
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          { asset.name } 
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Menu: { asset.menu } <br /> 
            Path: { asset.path }  <br />
            Enabled: { asset.enabled ? 'true' : 'false' } <br /> 
            { asset._id }  
            <hr />
          </Typography>
          Created: { moment(asset.created).format('MM/DD/YYYY') }<br />
        Updated: { moment(asset.updated).format('MM/DD/YYYY') } 
        </CardContent>
      </CardActionArea>
      <CardActions> 
      </CardActions> 
    </Card>
  );
}