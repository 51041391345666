import React, { useState, useEffect, useContext } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import { withStyles } from "@material-ui/styles";
import ItemTypes from "./ItemTypes";
import DeleteComponent from "./DeleteComponent";
import { IconButton, Tooltip } from "@material-ui/core";
import Chart from "./Chart";
import { faCog, faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { faSort } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorConvertToHTML from "./EditorConvertToHTML";
import Video from "./Video";
import ImageComponent from "./Image";

import ChartDisplay from "../../Visualizations/components/ChartDisplay";
import SentimentChart from "../../Visualizations/SentimentChart";
import { mostReadable } from "@ctrl/tinycolor";

//import menuIcon from '../../../images/Breefly_2020-Icon_150x150_White.png';
import dragChartImage from "../../../images/dragChartImage.png";
import dragTextImage from "../../../images/dragText.jpg";

//import dragLinkImage from '../../../images/dragLink.png';
//import { red } from '@material-ui/core/colors';
//import { text } from '@fortawesome/fontawesome-svg-core';

const style = {
  // padding: '0.5rem 1rem',
  marginBottom: ".5rem",
  backgroundColor: "transparent",
  cursor: "move",
  width: "100%"
};

const styles = theme => ({
  editIconSet: {
    position: "absolute",
    right: -50,
    "@media (max-width: 500px)": {
      right: -35
    }
  },
  refreshIcon: {
    position: "absolute",
    top: 75,
    right: -47,
    "@media (max-width: 500px)": {
      right: -32
    }
  }
});

const Card = props => {
  const {
    id,
    moveCard,
    findCard,
    setHoverCard,
    doDrop,
    component,
    classes,
    updateComponent,
    openSettings,
    funcs,
    isMobile,
    apiManager,
    refreshChild,
    isHoverComponent,
    hoverPosition,
    onTestDrop,
    handleCloseSentimentChart,
    updateChartData,
    openProduceFloral,
    setOpenProduceFloral,
    user,
    updateCustomChart
  } = props;
  const [values, setValues] = React.useState([]);
  const [isSelf, setIsSelf] = React.useState(false);
  const [showingTip, setShowingTip] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [originalIndex, setOriginalIndex] = React.useState(0);
  const [textDrag, setTextDrag] = React.useState(false);
  const [showControlPanel, setShowControlPanel] = React.useState(false);

  if (values.length == 0) {
    values[component.position] = component.content.text
      ? component.content.text
      : component.content.assetId;
  }

  const setSelected = () => {
    setTimeout(() => {
      if (!deleteOpen) {
        setShowingTip(!showingTip);
      }
    }, 500);
  };

  const onTextSelected = () => {
    setShowingTip(true);
  };

  const onTextBlur = () => {
    setShowingTip(false);
    //   setTimeout( () => {
    //     if( !deleteOpen) {
    //         console.log('on text blur after time out')
    //         setShowingTip(false);
    //     }
    // }, 100)
  };

  const onTextDragSelected = () => {
    setTextDrag(true);
  };

  const onTextDragBlur = () => {
    setTextDrag(false);
  };

  // const unsetSelected = () => {

  // }

  const renderByType = () => {
    const type = component.type;
    switch (type) {
      case 0:
        // title
        return renderTitle();
      case 1:
        // image
        return renderImage();

      case 2:
        // paragraph
        return renderText();

      case 3:
        return renderVideo();
      // video

      case 4:
        return renderChart();
      // chart

      case 5:
        // link
        return renderLink();

      case 6:
        // link
        console.log("custon chart");
        return renderCustomChart();
      default:
        return null;
    }
  };

  // const renderDeleteIcons = (isParagraph) => {
  //     const rightX = isMobile ? '-34px' : '-50px'
  //     const top = isMobile ? '0px' : isParagraph ? '40px' : '0px'
  //     return (<div style={{ position: 'absolute', right: rightX, top }}>
  //         <DeleteComponent classes={classes} updateComponent={e => updateComponent(values, true, false, component)} />
  //     </div>);
  // }

  const openSettingsPanel = e => {
    const t = e.currentTarget.getBoundingClientRect();
    window.scrollTo({
      top: window.pageYOffset + t.y,
      behavior: "smooth"
    });
    e.preventDefault();
    refreshChild();
    // Special case, do NOT do this if the component is type 6 AND is a sentiment chart
    if (
      component.type === 6 &&
      component.content &&
      component.content.dataset === "sentiment"
    ) {
      setShowControlPanel(true); // passed to <SentimentChart>
    } else {
      openSettings(component);
      funcs.refreshImage(component);
    }
  };

  // const checkImage = path => new Promise(resolve => {
  //     const img = new Image();
  //     img.onload = () => resolve({ path, status: 'ok' });
  //     img.onerror = () => resolve({ path, status: 'error' });

  //     img.src = path;
  // });

  // const renderRefresh = () => {
  //     return (<div className={classes.refreshIcon}>
  //         <IconButton aria-label="close" className={classes.icon} style={{ fontSize: '15px' }} onClick={e => funcs.refreshImage(component)} >
  //             <FontAwesomeIcon icon={faCircle} />
  //         </IconButton>

  //     </div>);
  // }

  const updateSentimentChart = data => {
    setShowControlPanel(false);
    component.content.customSettings = data.chartSettings;
    handleCloseSentimentChart(component);
    window.scrollBy({
      top: -250,
      behavior: "smooth"
    });
  };

  const closeSentimentEdit = () => {
    setShowControlPanel(false);
  };

  const onDeleteOpen = e => {
    e.preventDefault();
    setDeleteOpen(true);
  };

  const onDeleteClosed = () => {
    setDeleteOpen(false);
  };

  const renderIcons = (
    photo = null,
    video = null,
    settings = true,
    chartEditing = false
  ) => {
    if (isDragging) {
      return null;
    }
    return (
      <div>
        {photo && (
          <div style={{ display: "inline-block" }}>
            <IconButton
              aria-label="update photo"
              style={{ fontSize: "15px", color: "white" }}
              onClick={e => funcs.changeImage(component, e)}
            >
              <FontAwesomeIcon icon={faCloudUpload} />
            </IconButton>
          </div>
        )}
        {video && (
          <div style={{ display: "inline-block" }}>
            <IconButton
              aria-label="update video"
              style={{ fontSize: "15px", color: "white" }}
              onClick={e => funcs.changeVideo(component, e)}
            >
              <FontAwesomeIcon icon={faCloudUpload} />
            </IconButton>
          </div>
        )}

        {settings && (
          <div style={{ display: "inline-block" }}>
            <IconButton
              aria-label="settings"
              style={{ fontSize: "15px", color: "white" }}
              onClick={openSettingsPanel}
            >
              <FontAwesomeIcon icon={faCog} />
            </IconButton>
          </div>
        )}
        <div style={{ display: "inline-block" }}>
          <DeleteComponent
            classes={classes}
            updateComponent={e =>
              updateComponent(values, true, false, component)
            }
            color={"white"}
            onDeleteOpen={onDeleteOpen}
            onDeleteClosed={onDeleteClosed}
          />
        </div>
      </div>
    );
  };

  const renderVideoTag = (url, wstyle) => {
    let html = "";
    html = (
      <video controls style={wstyle}>
        <source src={url} />
        Your browser does not support the video tag.
      </video>
    );

    return html;
  };

  const renderVideo = () => {
    return (
      <>
        <DragPreviewImage connect={preview} src={null} />
        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>{renderIcons(false, true)}</div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>

        <div className={`${showingTip ? "editSelectComponent" : null}`}>
          <Video
            component={component}
            renderIcons={renderIcons}
            renderVideoTag={renderVideoTag}
            funcs={funcs}
            apiManager={apiManager}
            selectComponent={setSelected}
          />
        </div>
      </>
    );
  };

  const renderTitle = () => {
    const { text, alt, assetId, size, align } = component.content;
    return (
      <div style={{ position: "relative" }}>
        {renderIcons()}
        {text}
      </div>
    );
  };

  const renderChart = () => {
    const {
      assetId,
      title,
      subtitle,
      chartType,
      dataPoints,
      size,
      chartColor,
      wordCloud,
      unit
    } = component.content;
    const { id, position } = component;

    return (
      <>
        <DragPreviewImage connect={preview} src={dragChartImage} />

        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>
                {renderIcons(false, false, true, true)}
              </div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>

        <div
          tabIndex={`${id}123${position}`}
          onFocus={onTextSelected}
          onBlur={onTextBlur}
          className={`${showingTip ? "editSelectComponent" : null}`}
        >
          <div style={{ position: "relative" }}>
            <div>
              <Chart
                dataPoints={dataPoints}
                title={title}
                subtitle={subtitle}
                chartType={chartType}
                size={size}
                chartColor={chartColor}
                wordCloud={wordCloud}
                key={assetId}
                unit={unit}
              />
              <div
                id="testchart"
                style={{
                  position: "absolute",
                  height: `400px`,
                  width: "100%",
                  background: "transparent",
                  zIndex: 1,
                  top: "0px"
                }}
              ></div>
            </div>
            {/* {chartSettingsOpen && 
                        <TemplatesDialog 
                        classes={classes} 
                        apiManager={apiManager} 
                        insertOnly={true} 
                        startAtStep={1} 
                        isOpen={chartSettingsOpen} 
                        handleClose={handleCloseCreateChartDialog} 
                        />} */}
          </div>
        </div>
      </>
    );
  };
  const renderCustomChart = () => {
    const {
      assetId,
      size,
      align,
      customSettings,
      settings,
      dataset
    } = component.content;
    return (
      <>
        <DragPreviewImage connect={preview} src={dragChartImage} />

        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>
                {renderIcons(false, false, true, true)}
              </div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>

        <div className={`${showingTip ? "editSelectComponent" : null}`}>
          <div
            style={{
              position: "relative",
              width: "100%",
              padding: 12,
              background: "white"
            }}
          >
            <div
              tabIndex={`${assetId}`}
              id={`chartDivTitle-${assetId}`}
              onBlur={setSelected}
              onFocus={setSelected}
            ></div>
            <div
              tabIndex={`${assetId}1`}
              id={`editChartDiv-${assetId}`}
              onFocus={setSelected}
              onBlur={setSelected}
              style={{
                marginTop: 14,
                minHeight: size,
                textAlign: "center",
                fontSize: 12
              }}
            >
              {dataset && dataset === "sentiment" ? (
                <SentimentChart
                  chartDiv={`editChartDiv-${assetId}`}
                  chartDivTitle={null} // {`chartDivTitle-${assetId}`}
                  chartSettings={customSettings || null}
                  chartData={null}
                  chartAssetId={assetId}
                  appSettings={null}
                  apiManager={apiManager}
                  inBrief={true}
                  selectComponent={setSelected}
                  isEditing={showControlPanel}
                  cancelUpdate={closeSentimentEdit}
                  updateChart={updateSentimentChart}
                />
              ) : (
                <ChartDisplay
                  chartDiv={`editChartDiv-${assetId}`}
                  chartDivTitle={`chartDivTitle-${assetId}`}
                  chartSettings={settings || null}
                  chartData={null}
                  chartAssetId={assetId}
                  appSettings={null}
                  apiManager={apiManager}
                  selectComponent={setSelected}
                  inBrief
                  component={component}
                  setPassed={props.setPassed}
                  isEditing
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLink = () => {
    const {
      url,
      text,
      bold,
      size,
      italic,
      background,
      color
    } = component.content;
    const { id, position } = component;
    let textColor = color ? color : "#000";
    let buttonColor = background ? background : "transparent";
    textColor = mostReadable(buttonColor, [textColor], {
      includeFallbackColors: true
    }).toHexString();

    const style = {
      padding: "13px 26px",
      minWidth: 200,
      borderRadius: 3,
      fontSize: `11px`,
      fontStyle: italic ? "italic" : "normal",
      fontWeight: bold ? "bold" : "inherit",
      backgroundColor: buttonColor,
      color: textColor,
      border: "none"
    };
    const divStyle = {
      textAlign: "center",
      padding: 24
    };

    return (
      <>
        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>{renderIcons(false)}</div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>

        <div
          tabIndex={`${id}456${position}`}
          className={`${showingTip ? "editSelectComponent" : null}`}
          onFocus={setSelected}
          onBlur={setSelected}
        >
          <div style={divStyle}>{<button style={style}>{text}</button>}</div>
        </div>
      </>
    );
  };

  const renderImage = () => {
    const { url, alt, assetId, size, align } = component.content;

    let myImage = component.content.url;
    const imageTagId = document.getElementById(component.content.assetId);
    if (imageTagId) {
      let width = imageTagId.naturalWidth;
      let height = imageTagId.naturalHeight;
      if (width < 400 || width > 1000) {
        myImage = null;
      }
    }

    return (
      <>
        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>{renderIcons(true)}</div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>

        <div
          className={`${
            showingTip && !draggingInProgress ? "editSelectComponent" : null
          }`}
        >
          <ImageComponent
            component={component}
            renderIcons={renderIcons}
            classes={classes}
            funcs={funcs}
            selectComponent={setSelected}
          />
        </div>
      </>
    );
  };

  const updateTextComponent = values => {
    //  updateComponent(values, false, false, component);
    setTimeout(() => {
      if (!deleteOpen) {
        updateComponent(values, false, false, component);
      }
    }, 100);
  };

  const renderText = () => {
    const {
      text,
      variant,
      bold,
      italic,
      size,
      quote,
      visible
    } = component.content;
    const { id, position } = component;
    return (
      <>
        <DragPreviewImage connect={preview} src={dragTextImage} />
        <Tooltip
          title={
            <>
              <div style={{ fontSize: 24 }}>
                {renderIcons(false, false, false)}
              </div>
            </>
          }
          arrow
          placement="top"
          interactive
          open={(showingTip && !isDragging) || deleteOpen}
          className={`tipNoWrap`}
        >
          <div />
        </Tooltip>
        <div
          id={`${id}abc${position}`}
          tabIndex={`${id}abc${position}`}
          className={`${
            showingTip && !draggingInProgress ? "editSelectComponent" : null
          }`}
          onFocus={onTextSelected}
          onBlur={onTextBlur}
          style={{ position: "relative", marginBottom: 18, cursor: "text" }}
        >
          <EditorConvertToHTML
            component={component}
            onBlur={updateTextComponent}
            toolbarHidden={!showingTip}
            updateComponent={updateComponent}
            values={values}
          />
          <div
            tabIndex={`${id}abc-dragger${position}`}
            onFocus={onTextDragSelected}
            onBlur={onTextDragBlur}
            style={{
              position: "absolute",
              top: showingTip ? 22 : -64,
              right: 34
            }}
          >
            <FontAwesomeIcon
              icon={faSort}
              size="lg"
              style={{
                position: "absolute",
                top: 74,
                left: 8,
                fontSize: 24,
                cursor: "move"
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const [draggingInProgress, setDraggingInProgress] = React.useState(false);
  const [dropOn, setDropOn] = React.useState(14);

  const [{ isDragging, opacity }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.CARD,
      id,
      originalIndex,
      position: component.position,
      component
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: !!monitor.isDragging()
    }),
    canDrag: () => {
      const { type } = component;
      if (type === 2) {
        if (textDrag) {
          return true;
        }
        return false;
      }
      return true;
    },
    begin: () => {
      setIsSelf(true);
      setDraggingInProgress(true);
    },
    isDragging: monitor => {
      setDraggingInProgress(true);
      const item = monitor.getItem();
      //console.log(`IS DRAGGING - position: ${item.position}`)
      setOriginalIndex(item.position);
    },
    end: (dropResult, monitor) => {
      const item = monitor.getItem();
      const { id: droppedId, originalIndex, position } = item;
      const didDrop = monitor.didDrop();
      const result = dropResult;
      //console.log(`dropped ${position} on ${droppedId} / hoverPosition ${hoverPosition}`)

      if (!didDrop) {
      } else {
        const dropon = dropOn;

        setIsSelf(false);
      }
      setIsSelf(false);
      setDraggingInProgress(false);
    }
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
    hover({ id: draggedId }) {
      const hp = setHoverCard(id, component.position);
      setDropOn(hp);

      if (draggedId !== id) {
        setIsSelf(false);
      } else {
        setIsSelf(true);
      }
    },
    drop: onTestDrop
  });

  const renderCard = () => {
    const opacityStyle =
      isSelf && draggingInProgress ? { opacity: 0.0 } : { opacity: 1 };
    if (component.type == 0) {
      style.display = "none";
      return;
    } else {
      style.display = "block";
    }

    const showDebugPanel = false;
    return (
      <>
        {showDebugPanel && (
          <div style={{ width: "100%", height: 50, background: "red" }}>
            {`isSelf: ${isSelf}, isOver: ${isOver}, hoverPosition: ${component.position}/${originalIndex}, isDragging: ${draggingInProgress}`}
          </div>
        )}

        <div ref={preview} style={{ ...style, opacityStyle }}>
          <div ref={drag}>
            <div ref={drop}>
              {isOver &&
                draggingInProgress &&
                component.position < originalIndex && (
                  <span>
                    <div className={`editDragTarget`} />
                  </span>
                )}
              {renderByType()}
            </div>
          </div>
        </div>
        {isOver && draggingInProgress && component.position > originalIndex && (
          <div className={`editDragTarget`} />
        )}
      </>
    );
  };

  return <div>{renderCard()}</div>;
};

export default withStyles(styles)(Card);
