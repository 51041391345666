import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Select, MenuItem, Tooltip, FormControl, FormControlLabel, InputLabel, FormHelperText, Grid, Checkbox, Chip, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, FormGroup } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import segmentList from '../../datasets/pmaSentimentSegments.json';
import questionList from '../../datasets/pmaSentimentQuestions.json';
import isEmpty from 'lodash/isEmpty';

import './style.scss';


import LinearIndeterminate from '../Briefs/components/LinearIndeterminate'  // '../../Briefs/components/LinearIndeterminate'


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper'
am4core.addLicense("CH290132791")

const styles = theme => ({
    filter: {
        fontSize: 14,
        marginRight: 24,
        color: "#888;",
        "&.active": {
          color: "#333"
        }
      },
    select: {
        paddingBottom: 24,
        fontSize: 12,
        width: '100%'
    },
    check: {
        fontSize: 12,
    },
    icon:{
        width: 16,
        height: 16,
    },
    direction:{
        "& .MuiButton-label": {
            color: "#888 !important",
            fontSize: 10
          },
    },
})

export class SentimentChart extends React.PureComponent {
    constructor(...args) {
        super(...args);

        const {  chartDiv, chartSettings, chartData, component, appSettings, hideLegend, chartAssetId, selectComponent } = this.props;	
        //this.chartSettings = chartSettings ? chartSettings : {type: 'donut'};	
	
        this.state = {
            apiManager: this.props.apiManager,
            isLoading: true,
            chartDiv: `sentiment-chart-${new Date()}-${Math.random()}`,
            //chartAssetId,
            // segmentName: `Segment: ${'Apple Buyers'}`,
            segmentName: '',
            pmiAsset: '',
            questionIndex: 0,
            questionText: '',
            response: { questionText: '' },
            totalResponses: '',
            chartDrawn: false,
            queryType: 'simpleSegment',
            panelTabIndex: 0,
            segmentQuery: [0],
            surveySelect: 'S1',
            segmentSelect: '',
            sections: [],
            querySegments: [
            ],
            compareTo: [],
            isUpdating: false,
            //filterDirty: {1: false, 2:false, 3: false},
            displaySegments: [],
            isExpanded: false,
        }
    }

    loadChartDataForBrief = async() => {
        let { chartSettings, chartAssetId } = this.props;
        const pmiAsset = await this.props.apiManager.getPMIChartAssets(chartAssetId);

        if (!chartSettings && chartAssetId) {
            // let response;
            //     response = await this.props.apiManager.getPMIChartAssets(chartAssetId);
            // chartSettings = response.assets[0].settings;
            chartSettings = pmiAsset.assets[0].settings;
        }
        let toUpdate = [];
        if (chartSettings && chartSettings.querySegments) {
 
            chartSettings.querySegments.forEach(type => {
                const segmentType = (type.type === 'Products' || type.type === 'Product') ? 'Survey Products' : (type.type === 'Age' ? 'Age Group' : type.type);

                const segments =  segmentList.segments.filter(segment =>
                    segment.segment === segmentType
                 ) 
                 //console.log('segments', segments)
                const displayNames = [];
                
                segments[0].items.forEach(item => displayNames[`${item.columnName}-${item.value}`] = item.name )

                type.items.map(el => {
                    const columnName = Object.keys(el)[0];
                    const value = Object.values(el)[0]
                    const displayName = displayNames[`${columnName}-${value}`]
                    toUpdate.push(
                        {
                            state: type.type === "Products" ? "Survey Products" : type.type === 'Age' ? "Age Group" : type.type,
                            columnName,
                            value,
                            displayName,
                        })
                })
            })
            //console.log('to update ', toUpdate)

            toUpdate && toUpdate.length > 0 && toUpdate.map(
                el => {

                    //console.log('sentiment el iterable: ', el.state, this.state[el.state])
                    let temp = this.state[el.state] && [...this.state[el.state]];

                    !isEmpty(temp) && temp.forEach((i, index) => 

                        {
                           

                            if ((i.columnName === el.displayName) || i.columnName === el.columnName && i.value.toString() === el.value || i.columnName === el.columnName && i.columnName === "All")
                            {
                            temp[index][1] = true;  
                            }
                        this.setState({
                            [el.state]: temp,
                            //filterDirty: {...this.state.filterDirty, 1: true}
                        })
                    })})}

        this.setState({
            chartSettings,
            compareTo: chartSettings && chartSettings.compareTo && chartSettings.compareTo,
            pmiAsset: pmiAsset.assets[0],
            querySegments: chartSettings.querySegments,
            segmentQuery: [0],
            surveySelect: chartSettings.question,
            // panelTabIndex: chartSettings.subSegment !== '' ? 1 : 0,
            panelTabIndex: 0, //hide subSubSegmnet for now
            segmentName: chartSettings.segmentName,
            displaySegments: toUpdate,
        }, async() => {
            let response;
            const query={
                    question: chartSettings.question ? chartSettings.question : 'S1',  // e.g. "D1, Q1B"
                    querySegments: chartSettings.querySegments && chartSettings.querySegments > 0 ? chartSettings.querySegments : this.state.querySegments,
                    //To Do figure out where subsegment is coming in
                    subSegment: chartSettings.subSegment ? chartSettings.subSegment : '', // e.g. "D2", "MRKFlagF",
                    compareTo: [], // [] or [[],[]]
                }
                try{
                //console.log('query', query)
                response = await this.state.apiManager.getPMISentimentResponse(query);

                } catch (e){
                    console.log('loading error: ', e)
                }finally {
                    this.setState({ isLoading: false, response: response && response.assets ? response.assets : { responses: null, questionText: 'No Resonses' } }, () => {
                        this.drawChart();
                    });
                }
            })
    }
    populateInitialData = async () => {
        const sections = segmentList.segments.filter(segment => 
            segment.show === true
         )         
           sections.forEach(el => {
             // let trimmed = el.segment.replace(/\s/g,'').toLowerCase();
             let options = [];
               el.items.map(option => {
                 let obj = {
                   name: option.name,
                   1: false,
                   2: false,
                   3: false,
                   columnName: option.columnName,
                   value: option.value,
                   key: Math.random()
                 };
                 options.push(obj);
               });
               if( el.segment === 'Products') {
                   el.segment = 'Product';
               }
               if( el.segment === 'Age') {
                el.segment = 'Age Group';
                }
             this.setState({
               [el.segment]: options
             }, async() => 
                {
                if( this.props.inBrief ) {
                    await this.loadChartDataForBrief();
                } else {
                    await this.loadChartDataForBrief();
                }
            });
           }
         );
    }
    componentDidMount = async () => {
        await this.populateInitialData();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if(nextState.isExpanded !== this.state.isExpanded){
            this.drawChart();
        }
        return true;
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.filterDirty === nextState.filterDirty){
    //         return false
    //     }
    //     // if (this.state.chartSettings === nextState.chartSettings){
    //     //     return false
    //     // }
    //     // if (this.state.response === nextState.this.state.response){
    //     //     return false
    //     // }
    //     return true;
    // }
    componentWillUnmount = () => {

    }

    panelTabNames = [
        "Survey", "Segmentation" // , "Compare Segements"
    ]

    getData = async (type = 'simpleSegment') => {

        const names = {
            'simpleSegment': `Segment: Apple Buyers`,
            'complexSegment': `Segment: Apple Buyers and Banana Buyers, Aged 18-45`,
            'deepDive': `Segment: Apple Buyers by Aged 18-45`
        }
        this.setState({
            isLoading: true,
            // segmentName: names[type],
            queryType: type,

        })
            let sub = segmentList.segments.filter(el => el.subsegmentName === this.state.segmentSelect).map(i => i.items[0].columnName)
            this.getSections().map(i => this.getAllSelected(i));
            const querySegments = this.updateQuery();
           let response;
            const query={
                    question: this.state.surveySelect ? this.state.surveySelect : 'S1',  // e.g. "D1, Q1B"
                    querySegments: querySegments && querySegments.querySegments && querySegments.querySegments.length > 0 ? querySegments.querySegments : this.state.querySegments,
                    subSegment: this.state.panelTabIndex === 1 ? this.getSubSegmentColumn() : '', // e.g. "D2", "MRKFlagF",
                    compareTo: querySegments && querySegments.compareTo && querySegments.compareTo.length > 0 ? querySegments.compareTo : this.state.compareTo, // [] or [[],[]]
                }
                try {
                    response = await this.state.apiManager.getPMISentimentResponse(query);
                    //console.log("get data response", response)

                } catch (e){
                    console.log(e)
                }finally {
                    this.setState({ isLoading: false, response: response && response.assets ? response.assets : { responses: null, questionText: 'No Resonses' } }, () => {
                        this.drawChart();
                    });
                    window.scrollBy({
                        top:-500,
                        behavior: 'smooth'
                    })
                    return true;
                }
    }

    drawChart = () => {
        const { chartDiv, response, queryType } = this.state;
        const { chartSettings } = this.state;
        const chartData = response.responses;
        let dataLength = Object.keys(chartData).filter(el => (el !== '_id' || el !== 'n')).length

        //console.log('chart data', chartData)
        let totalResponses = 0;
        const isDeepDive = (queryType === 'deepDive' || Array.isArray(chartData)) ? true : false;
        am4core.options.autoDispose = true;
        
        // if (this.state.chartObject) {
        //     this.state.chartObject.dispose();
        // }

        const chartType = chartSettings.type || 'bar'
        let chart;
        let series;
        // pie series
        if (chartType === 'donut' || chartType === 'pie') {
            chart = am4core.create(chartDiv, am4charts.PieChart);

        }

        // xy series
        if (chartType === 'bar') {
            chart = am4core.create(chartDiv, am4charts.XYChart);
        }

        //pyramid series
        if (chartType === 'pyramid') {
            chart = am4core.create(chartDiv, am4charts.SlicedChart)
        }

        // xy series
        let categoryAxis;
        if (chartType === 'bar') {

            categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "type";
            //categoryAxis.title.text = "Response";
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.grid.template.disabled = true;
            if(!this.props.inBrief){
                categoryAxis.renderer.labels.template.disabled = true;
            }
            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Count";

            // Create series
            series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryY = "type";
            series.dataFields.valueX = "value"
            series.columns.template.tooltipText = "{categoryY}\nValue: {valueX}";
            
            // series.tooltip.label.wrap = true;

  
           
                
                // series.dataFields.categoryX = "type";
                if (isDeepDive) {
                categoryAxis.dataFields.categoryY = "category";
                categoryAxis.dataItems.template.text = "{type}";
                series.dataFields.valueX = "category";
                series.columns.template.tooltipText = "{segment}: {type}\nValue: {valueY}";

                // create range (the additional label at the bottom)
                var rangeTemplate = categoryAxis.axisRanges.template;
                rangeTemplate.tick.disabled = false;
                rangeTemplate.tick.location = 0;
                rangeTemplate.tick.strokeOpacity = 0.6;
                rangeTemplate.tick.length = 60;
                rangeTemplate.grid.strokeOpacity = 0.5;
                rangeTemplate.label.tooltip = new am4core.Tooltip();
                rangeTemplate.label.tooltip.dy = -10;
                rangeTemplate.label.cloneTooltip = false;
            }

            // series.name = "Name";


            //   series.stackToNegative = false;
            const colors = {}
            series.columns.template.adapter.add("fill", function (fill, target) {
                if (target.dataItem && (target.dataItem.valueY < 0)) {
                    return am4core.color("#a55");
                }
                else {
                    var name = target.dataItem.dataContext.type;
                    if (!colors[name]) {
                        colors[name] = chart.colors.next();
                    }
                    target.stroke = colors[name];
                    return colors[name];
                }
            });



            categoryAxis.renderer.grid.template.location = 0;
            // categoryAxis.renderer.labels.template.rotation = -60;
            categoryAxis.renderer.labels.template.truncate = true;
            categoryAxis.renderer.labels.template.maxWidth = 80;
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontSize = "10px";
            categoryAxis.renderer.minGridDistance = 12;
            categoryAxis.sortBySeries = series;

            var columnTemplate = series.columns.template;

            columnTemplate.fillOpacity = .8;
            columnTemplate.strokeOpacity = 0;
        }
        if (chartType === 'donut' || chartType === 'pie') {
            series = chart.series.push(new am4charts.PieSeries());
            if(chartType !== 'pie'){
                if (chart.data.length < 7) {
                    series.innerRadius = am4core.percent(50);
                } else {
                    series.innerRadius = am4core.percent(25);
                    //series.radius = 120;
                }
            }
            series.dataFields.value = "value";
            series.dataFields.category = "type";
            series.labels.template.fontSize = "11px";
            series.labels.template.maxWidth = 125;
            series.labels.template.wrap = true;
            if(!this.props.inBrief){
                series.labels.template.disabled = true;
                //chart.radius = am4core.percent(75);
            }
            if(dataLength > 10 && this.props.inBrief){
                let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
                grouper.limit = 10;
                grouper.groupName = "Other (click twice to see)";
                grouper.clickBehavior = "zoom";  
                    grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
                        if(ev.visible){
                            series.labels.template.disabled = true;
                            series.ticks.template.disabled = true;                           
                        }
                        this.setState({isExpanded: ev.visible})
                  }, this);                 
                 
            }

        }
        if(chartType === 'pyramid') {
            series = chart.series.push(new am4charts.PyramidSeries())
            series.dataFields.value = "value";
            series.dataFields.category = "type";
            series.alignLabels = true;
            series.valueIs = "height";
            series.labels.template.fontSize = "10px";
            series.labels.template.fontWeight = 300;
            series.labels.template.paddingBottom = 5;
            series.labels.template.paddingTop = 5;
            series.labels.template.maxWidth = 150; 
            series.labels.template.wrap = true;
            series.bottomWidth = '75%'
            if(!this.props.inBrief){
                series.labels.template.disabled = true;
                series.ticks.template.disabled = true;
                //chart.radius = am4core.percent(75);
            }
            // series.labels.template.adapter.add('hidden', (text, target, key) => {
            //     return console.log('label key',key)
            // })
            let dataLength = Object.keys(chartData).filter(el => (el !== '_id' || el !== 'n')).length
            if(dataLength > 10 && this.props.inBrief){
                //console.log('grouper', chart.data)
                let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
                grouper.limit = 10;
                grouper.groupName = "Other (click twice to see)";
                grouper.clickBehavior = "zoom";  
                    grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
                        //console.log(ev)
                        if(ev.visible){
                            series.labels.template.disabled = true;
                            series.ticks.template.disabled = true;                           
                        }
                        this.setState({isExpanded: ev.visible})
                  }, this);                 
                 
            }

        }
        // grab the data now.
        if (!isDeepDive) {

            chartData && Object.keys(chartData).map(lineItemKey => {
                if (lineItemKey === '_n') {
                    totalResponses = chartData[lineItemKey];
                } else if (lineItemKey !== '_id') {
                    const item = {
                        type: lineItemKey
                    };
                    item.value = chartData[lineItemKey];
                    chart.data.push(item)
                }
            })
        } else {

            // process data and prepare it for the chart         
           
            chartData.sort((a, b) => {
                if (a._id > b._id) return 1;
                if (a._id < b._id) return -1;
                return 0;
            });

            for (var segment in chartData) {
                var segmentData = chartData[segment];
                const tempArray = [];
                Object.keys(segmentData).map(lineItemKey => {

                    if (lineItemKey === '_n') {
                        totalResponses = segmentData[lineItemKey];
                    } else if (lineItemKey !== '_id' && lineItemKey !== '_segment') {
                        const item = {
                            type: lineItemKey
                        };
                        item.value = segmentData[lineItemKey];
                        item.segment = segmentData._segment;
                        item.category = `${segmentData._segment}-${lineItemKey}`;
                        chart.data.push(item)
                        tempArray.push(item);

                    }
                })
                // create range (the additional label at the bottom)
                var range = categoryAxis.axisRanges.create();
                range.category = tempArray[0].category;
                range.endCategory = tempArray[tempArray.length - 1].category;
                range.label.text = tempArray[0].segment;
                range.label.dy = 70;
                range.label.truncate = true;
                range.label.fontWeight = "bold";
                range.label.fontSize = '24px';
                range.label.rotation = 0;
                range.label.scale = 1.75;
                range.label.tooltipText = tempArray[0].segment;


                range.label.adapter.add("maxWidth", function (maxWidth, target) {
                    var range = target.dataItem;
                    var startPosition = categoryAxis.categoryToPosition(range.category, 0);
                    var endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                    var startX = categoryAxis.positionToCoordinate(startPosition);
                    var endX = categoryAxis.positionToCoordinate(endPosition);
                    return endX - startX;
                })
            }
        }

        

        // And, for a good measure, let's add a legend
        // if (chartType !== 'bar' &&  chartType !== 'donut' && chartType !== 'pie' && !this.state.hideLegend) {
        //     chart.legend = new am4charts.Legend();
        // }
        this.setState({
            totalResponses,
            chartDrawn: true,
            chartObject: chart
        });

        // see if we've been asked to populate the title block
        if (this.props.chartDivTitle) {
    
            const el = document.getElementById(this.props.chartDivTitle);
            if (el) {
                el.innerHTML = `<b>${chartSettings.title}</b><br/>${chartSettings.geo} - ${chartSettings.time}`;
            }
        }


    }
    getSubSegmentColumn = () => {
        let sub = segmentList.segments.filter(el => el.subsegmentName === this.state.segmentSelect).map(i => i.items[0].columnName)
        return sub.toString();
    }
    getSections = () => {
        this.setState({
            isUpdating: true
        })
        const sections = segmentList.segments.filter(segment => 
            segment.show === true
         )
         return sections
    }
    querySegments = [[],[],[]];
    getAllSelected = section => {
        //get the current state of selections for each section
        let data = this.state[section.segment] ? [...this.state[section.segment]] : '';
        //fill the array with the selected items from each category
        for(let i=0; i < 3; i++) {
        let match =  data && data.filter(el => el[i+1] === true)
        //make the object for the query
        const seg = {
            type: section.segment,
            items: match
            }
        //push to temp array
        this.querySegments[i].push(seg)
        }
    }
    updateQuery = () => {
    //filter out the empty categories then set the two states
    let q1 = this.querySegments[0].filter(el => el.items.length > 0)
    let q2 = this.querySegments[1].filter(el => el.items.length > 0)
    let q3 = this.querySegments[2].filter(el => el.items.length > 0)

    if(q1.length !== 0 || q2.length !== 0 || q2.length !== 0) {
        this.setSegmentNames(q1, q2, q3)
    }
    q1.forEach((product, index) => {
        product.items.forEach((item, index) => {
            const newItem = {};
            newItem[item.columnName] = item.value.toString();
            product.items[index] = newItem;
        })
    })
    q2.forEach((product, index) => {
        product.items.forEach((item, index) => {
            const newItem = {};
            newItem[item.columnName] = item.value.toString();
            product.items[index] = newItem;
        })
    })
    q3.forEach((product, index) => {
        product.items.forEach((item, index) => {
            const newItem = {};
            newItem[item.columnName] = item.value.toString();
            product.items[index] = newItem;
        })
    })
      this.setState({
          querySegments: q1,
          compareTo: [q2, q3]
      }, ()=> {
      this.setState({
        isUpdating: false
    })
    })
    this.setState({
        isUpdating: false
    })
      this.querySegments = [[],[],[]];
      return ({querySegments: q1, compareTo: [q2,q3]});
    }

    setSegmentNames = (...args) => {
     
        let name = {
            0: [],
            1: [],
            2: []
        };
        args.forEach((seg, index) => 
            seg.forEach(category => 
                category.items.forEach(el => 
                    name[index].push(el.name)))) 
        this.setState({
            segmentName: name
        })    
    }
    handleSave = () => {
        // queryType: 'simpleSegment',
        // panelTabIndex: 0,
        // segmentQuery: [0],

        let finished = this.getData(this.state.queryType)
        
        finished.then(() => {
        let payload ={
            chartSettings: this.state.chartSettings,
        }
        payload.chartSettings.querySegments = this.state.querySegments;
        payload.chartSettings.compareTo = this.state.compareTo;
        payload.chartSettings.question = this.state.surveySelect;
        payload.chartSettings.panelTabIndex = this.state.panelTabIndex;
        payload.chartSettings.subSegment = this.getSubSegmentColumn();
        payload.chartSettings.segmentName = this.state.segmentName;
        this.props.updateChart(payload)
        this.updateAsset();
        })
    }

        
    updateAsset = async () => {
        //update pmiChartAssets
        let updatedAsset = {...this.state.pmiAsset}
        updatedAsset.settings.querySegments = this.state.querySegments;
        updatedAsset.settings.compareTo = this.state.compareTo;
        updatedAsset.settings.question = this.state.surveySelect;
        updatedAsset.settings.panelTabIndex = this.state.panelTabIndex;
        updatedAsset.settings.subSegment = this.getSubSegmentColumn();
        updatedAsset.settings.segmentName = this.state.segmentName;
        await this.props.apiManager.updatePMIChartAssets(updatedAsset)
    }

    simpleSegement = async () => {
        await this.getData('simpleSegment')
    }

    complexSegement = async () => {
        await this.getData('complexSegment')
    }

    deepDive = async () => {
        await this.getData('deepDive')
    }
    nextQuestion = amt => {

        const filteredQuestions = questionList["Question List"].filter(question => question.skip !== true)
        if(this.state.questionIndex === 17 && amt === 1){
            this.setState({
                questionIndex: 0,
                surveySelect: filteredQuestions[0]["Question ID"]
            },
            () =>
                this.simpleSegement())
        } else if(this.state.questionIndex === 0 && amt === -1){
            this.setState({
                questionIndex: 17,
                surveySelect: filteredQuestions[17]["Question ID"]
            },
            () =>
            this.simpleSegement())
        } else {
            this.setState(prevState => ({
                questionIndex: prevState.questionIndex + amt,
                surveySelect: filteredQuestions[prevState.questionIndex + amt]["Question ID"]
            }),
            () =>
            this.simpleSegement())
        }
    }
    handleChange = (event, select) => {
        if(select === 'survey'){
            this.setState({
                surveySelect: event.target.value
            })
        }

        if(select === 'segment'){
            this.setState({
                segmentSelect: event.target.value
            },)
        }
    }
    handleSelect = (e, segment, i, context, option) => {
        //console.log(segment, i, context)
        const temp = [...this.state[segment]];

        temp[i][context] = e.target.checked
           this.setState({
            //filterDirty: {...this.state.filterDirty, [context]: true},
            [segment]: temp,
            })
    }
    clearAll = context => {
        const sections = segmentList.segments.filter(segment => 
            segment.show === true
         )
        let cleared = sections.map(section => this.state[section.segment]);
        cleared.map(group => {
            let clearGroup = [...group];
                clearGroup.map(option => {
                    option[context] = false;
                })
            this.setState({
                [group]: clearGroup
                })
            })            
        this.setState({ 
            segmentQuery: [],
            //filterDirty: {...this.state.filterDirty, [context]: false}
            //not needed, don't leave blank
            // surveySelect: '',
            // segmentSelect: '',
         })
    }
    renderSegments = context => {
        const { classes } = this.props;
        const sections = segmentList.segments.filter(segment => {
            // if (segment.segment === "Survey Products"){
            //     segment.segment = 'Product'
            // }
            return segment.show === true
        } 
        );

        return(
            <>
            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <h4 style={{marginLeft: -8, marginBottom: 12}}>Filter options</h4> */}
                {/* CLEAR ALL BUTTON */}
                {/* {this.state.filterDirty[context] ?
                    < Button
                        aria-label="clear all"
                        onClick={() => this.clearAll(context)}
                        variant='text'
                        size = 'small'
                        >
                        X Clear Options
                    </Button>
                    : <span style={{height: 24}}> </span>
                }
            </div> */}
            {sections.map((section, sectionIndex) => 
            <Grid container direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
                key={section.segment + sectionIndex + context}>
            <Grid item xs={"auto"} key={sectionIndex} style={{width: '100%'}}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <span className="expansion">{section.segment}</span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <FormGroup row>
                    {this.state[section.segment] && this.state[section.segment].map((option, index) =>    
                        <FormControlLabel
                            key={option + index + context}
                            control={
                                <Checkbox 
                                    checked={option[context]} 
                                    onChange={e => this.handleSelect(e, section.segment, index, context, option)}
                                    name={option.name}
                                    color='primary'
                                    style={{
                                        transform: 'scale(.7)'
                                        }}
                            />}
                            label={<span style={{fontSize: 12}}>{option.name}</span>}
                            />
                    )}        
                    </FormGroup>        
                </ExpansionPanelDetails>
                </ExpansionPanel>
                </Grid>
            </Grid>
            )}
            </>
        )
    }
    renderCompareTabs = () => {
      return (
            <div>
            <h4>Segment 1 <span style={{fontSize: 12}}>- {this.state.segmentName[0] ? this.state.segmentName[0].length + " Segments Selected" : ''}
            </span></h4>
            <div>{this.renderSegments(1)}</div>
            <hr />
            <h4>Segment 2 <span style={{fontSize: 12}}>- {this.state.segmentName[1] ? this.state.segmentName[1].length + " Segments Selected" : ''}  </span></h4>
            <hr />
            <div>{this.renderSegments(2)}</div>
            <h4>Segment 3 <span style={{fontSize: 12}}>- {this.state.segmentName[2] ? this.state.segmentName[2].length + " Segments Selected" : ''}</span></h4>
            <div>{this.renderSegments(3)}</div>
            </div>
      );
    };
    renderSegmentNames = () => {
        const { panelTabIndex, segmentName, totalResponses } = this.state;
      
        // const s1 = segmentName && segmentName[0] && segmentName[0].length > 0 && segmentName[0].join(', ').concat(' buyers')
        // const s2 = segmentName && segmentName[1] && segmentName[1].length > 0 && segmentName[1].join(', ')
        // const s3 = segmentName && segmentName[2] && segmentName[2].length > 0 && segmentName[2].join(', ')
        if(panelTabIndex !== 2){
            return(
            // <div className="response-segment">{s1 ? s1 : "All respondents"} - Total responses: {this.state.totalResponses}
              <div className="response-segment">{totalResponses} response{totalResponses === 1 ? "" : "s"}
                <br />
                   {/* <span className="fyi">(Use settings to filter by segment)</span> */}
            </div>
            )
        } else {
            return(
                <>
                {/* <h5 style={{ marginBottom: 12 }}>Segment 1: {s1 ? s1 : "No segment selected"} (Total responses: {this.state.totalResponses})</h5>
                <h5 style={{ marginBottom: 12 }}>Segment 2: {s2 ? s2 : "No segment selected"} (Total responses: {this.state.totalResponses})</h5>
                <h5 style={{ marginBottom: 12 }}>Segment 3: {s3 ? s3 : "No segment selected"} (Total responses: {this.state.totalResponses})</h5> */}
                </>
            )
        }
    }
    renderControlPanel = () => {
        const { panelTabIndex } = this.state;
        const { classes } = this.props;
        const currentQuestion = questionList["Question List"].filter(q => q["Question ID"] === this.state.surveySelect).flat()

        return (
            <div style={{borderRadius: 5, padding: 24, textAlign: 'left', width: '100%' }}>
                <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                    <div className="settings-title">Chart Settings</div>
                    
                </div>
                {/* "tab" selectors*/}
                {/* <div style={{marginBottom: 18}}>
                    {this.panelTabNames.map((tab, index) => (
                        <Button
                            aria-label="grid"
                            onClick={() => { 
                                this.setState({ 
                                    panelTabIndex: index 
                                    })
                            }}
                            classes={{
                                label: `sentiment ${classes.filter} ${
                                panelTabIndex === index ? "active" : ""
                                }`
                            }}
                            key={tab}
                        >
                            {tab}
                        </Button>
                    ))}
                </div> */}

                {/* input(not for survey) */}
                <Grid 
                    container 
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                    >
                {/* question */}
                    <Grid item xs={12} style={{width: '100%'}}>
                        <ExpansionPanel >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <span className="expansion">
                                    {currentQuestion && currentQuestion[0] && currentQuestion[0].short}
                                </span>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                        <FormControl 
                        classes={{
                            root: classes.select
                        }}
                        >
                            <InputLabel id="survey-select-label">Survey Question</InputLabel>
                                <Select
                                labelId="survey-select-label"
                                id="squestion"
                                name="squestion"
                                onChange={e => this.handleChange(e, 'survey')}
                                value={this.state.surveySelect}
                                >
                                    {questionList["Question List"].map((question, index) => (question && !question.skip && (
                                    <MenuItem value={question['Question ID']} key={index}>{question.short}</MenuItem>)
                                ))}
                                </Select>
                            <FormHelperText>Select a survey question</FormHelperText>
                        </FormControl>
                        </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    {panelTabIndex !== 0 && (
                    <Grid item xs={12} sm={5} style={{width: "100%"}}>
                        <FormControl 
                        required={this.state.panelTabIndex === 1}
                        classes={{
                            root: classes.select
                        }}>
                            <InputLabel id="subsegment-select-label">Separate by Segment:</InputLabel>
                                <Select
                                labelId="subsegment-select-label"
                                id="subsegment"
                                name="subsegment"
                                value={this.state.segmentSelect}
                                onChange={e => this.handleChange(e, 'segment')}
                                >
                                    {segmentList.segments.map((segment, index) => (
                                            segment.subsegmentName && (
                                            <MenuItem value={segment.subsegmentName} key={segment + index}>
                                                {segment.subsegmentName}
                                            </MenuItem>
                                            )
                                    ))}
                                </Select>
                            <FormHelperText>Subdivide by results</FormHelperText>
                        </FormControl>
                    </Grid> )}
                </Grid>
               

                {/* Segmentation */}
                {!this.state.isLoading && segmentList && 
                this.state.panelTabIndex === 2 ? this.renderCompareTabs() : this.renderSegments(1)}
            </div>
        )
    }
    handleCancelEdits = async () => {
        this.props.cancelUpdate()
        await this.populateInitialData();
        if( this.props.inBrief ) {
            await this.loadChartDataForBrief();
        } else {
            await this.getData();
        }
        
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="sentiment">
                <div className="title-center">Consumer Sentiment</div>
                {this.state.chartSettings && this.state.chartSettings.fullSurvey && (
                <div id="button-holder" className={`button-holder ${classes.direction}`}>
                    {/* <div className="question-title">Survey Question:</div> */}
                    
                        <>
                            <Button variant="text" onClick={() => this.nextQuestion(-1)}>
                                <ChevronLeftIcon /> 
                                Previous Question
                            </Button>
                            <Button variant="text" onClick={() => this.nextQuestion(1)}>
                                Next Question
                                <ChevronRightIcon /> 
                            </Button>
                        </>
                </div>
                )}
                <div className="surveyQuestion" style={{ marginBottom: 12 }}>
                    {this.state.response.questionText}
                </div>
                {this.renderSegmentNames()}
                <div style={{ width: '99%', textAlign: 'center' }}>
                    {this.state.isLoading && (
                        <div>
                            loading chart...
                            <br /><br />
                            <LinearIndeterminate />
                        </div>
                    )}
                    <div id={this.state.chartDiv} style={{ width: '100%', minHeight: 400 }}>
                    </div>
                    
                    {this.props.isEditing&& this.renderControlPanel()}
                    <div id="button-holder" className="bottom-button-holder">
                    {(this.props.inBrief && this.props.isEditing) && (
                        <>
                            <Button variant="text" onClick={this.handleCancelEdits} className="second" size="small">Cancel</Button>
                            <Button variant="text" className="second" size="small" onClick={() => this.getData(this.state.queryType)}>Preview Chart</Button>
                            <Button variant="text" className="save" size="small" onClick={this.handleSave}>Update</Button>
                            </>
                    )}
                    </div>
                    <div className="sentiment-category-panel">
                        <div className="sentiment-category">
                            <div className="sentiment-category-title">Products</div>
                            {this.state.displaySegments.map(seg => (
                                <>
                                { (seg.state === 'Products' || seg.state === 'Product' || seg.state === "Survey Products") && (
                                  <Tooltip title={seg.displayName} placement="right" arrow key={seg.displayName + this.state.chartDiv}>
                                    <div className='sentiment-item'>{seg.displayName}</div>
                                    </Tooltip>
                                )}
                                </>
                            ))}
                        </div>
                        <div className="sentiment-category">
                            <div className="sentiment-category-title">Channels</div>
                            {this.state.displaySegments.map(seg => (
                                <>
                                { seg.state === 'Channel' && (
                                  <Tooltip title={seg.displayName} disableInteractive placement="right-start" arrow key={seg.displayName + this.state.chartDiv}>
                                  <div className='sentiment-item'>{seg.displayName}</div>
                                  </Tooltip>
                                )}
                                </>
                            ))}
                        </div>
                        <div className="sentiment-category">
                            <div className="sentiment-category-title">Gender</div>
                            {this.state.displaySegments.map(seg => (
                                <>
                                { seg.state === 'Gender' && (<div key={seg.displayName + this.state.chartDiv}>{seg.displayName}</div>)}
                                </>
                            ))}
                        </div>
                        <div className="sentiment-category">
                            <div className="sentiment-category-title">Age</div>
                            {this.state.displaySegments.map(seg => (
                                <>
                                { (seg.state === 'Age' || seg.state === 'Age Group') && (<div key={seg.displayName + this.state.chartDiv}>{seg.displayName}</div>)}
                                </>
                            ))}
                        </div>

                    </div>
                    <div style={{fontSize: 10, textAlign: 'left', paddingLeft: 15, paddingTop: 10, fontWeight: 300}}>
                        <b>Source:</b> IRI Panel Custom Survey for IFPA
                    </div>
                    {/* TEMPORARY INTERFACE */}
                    {/* {!this.props.inBrief && (
                        <>

                            <Button variant='outlined' onClick={this.simpleSegement} style={{ marginRight: 18 }}>Single Segment</Button>
                            <Button variant='outlined' onClick={this.complexSegement} style={{ marginRight: 18 }}>Complex Segment</Button>
                            <Button variant='outlined' onClick={this.deepDive} style={{ marginRight: 18 }}>Deep Dive</Button>
                        </>
                    )} */}
                </div>
            </div>
        );
    }
};

SentimentChart.propTypes = {
    user: PropTypes.object
};

export default withStyles(styles)(SentimentChart);
