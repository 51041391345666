import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";

/**
 * Warning modal for changes to custom charts
 * @param {bool} isOpen - open/close state of the modal.
 * @param {function} onClose - function to close the modal.
 * @param {function} continueAction - action if user clicks continue.
 * @param {string} title - Title text of modal.
 * @param {string} message - Body text of modal.
 */
const RightBarWarning = props => {
  const {
    isOpen,
    onClose,
    continueAction,
    title,
    message
  } = props;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="warning-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={continueAction} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
RightBarWarning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  continueAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};
export default RightBarWarning;
