import React, { useState, useEffect } from "react";
import { API_LOCAL } from '../../constants'; 
import axios from 'axios';
import eventTracker from "../../utils/event-tracker";
//THIS MOVES THE REDIRECT FROM THE BACKEND TO THE FRONT END, AND MAKES THE SUCCESS API CALL
const Success = props => {
    const {history, match, user} = props;
    useEffect(() => {
        const completeSubscription = async () => {
            try{            
                const url = `${API_LOCAL}subscriptions/success${history.location.search}`;
                const result = await axios.get(url)
                if (result) {
                    eventTracker('New subscription', user, 'registration')
                    history.push('/subscriptions')
                }
            } catch(e){
                console.log(e)
                history.push('/subscriptions')
            }
        }
        completeSubscription();
    },[])

    return(
        <div></div>
    )
}
export default Success;