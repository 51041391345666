import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RemoveUser(props) {
  const [open, setOpen] = React.useState(false);

  const [user, updateUser] = React.useState(false);

  const [password, handleChange] = React.useState(false);
  const { classes, person, apiManager, handleDelete } = props;

  const [text, handleText] = React.useState(`Are you sure you want to remove ${person.email} from your network? Once deleted, the user will no longer be able to access any of your content.`);

  const data = props;
 

  const handleClickOpen = e => {
    e.stopPropagation();
    setOpen(true);
     
  };

  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const deleteComponent = e => { 
    e.stopPropagation();
    setOpen(false);
    handleDelete();
  };

 

  return (
    <span style={{ position: 'absolute', right: '50px', top: '21px' }}>
    

              <IconButton aria-label="close" className={classes.icon} onClick={handleClickOpen}  style={{ fontSize: '15px'}} >
<FontAwesomeIcon icon={faTrash} />
</IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style = {{textAlign: 'left'}}>Heads Up!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={deleteComponent} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
