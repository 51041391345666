import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes'; 
import {Elements, StripeProvider} from 'react-stripe-elements';
 
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import { STRIPE_PUBLIC_KEY } from './constants'; 

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  state = {
    user: null
  }

  render() { 
    //const muiTheme = createMuiTheme(theme);
    return ( 
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <Router history={browserHistory}>
        <DndProvider backend={Backend}>
        <Elements>
        <ThemeProvider theme={theme}>
          <Routes  />
          </ThemeProvider> 
        </Elements>
        </DndProvider>
        </Router>
      </StripeProvider>
    );
  }
}
