import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Delete(props) {
    const [open, setOpen] = React.useState(false);

    const [user, updateUser] = React.useState(false);

    const [password, handleChange] = React.useState(false);
    const { classes, updateComponent } = props;

    const componentName = 'element';
    const [text, handleText] = React.useState(`Are you sure you want to delete this ${componentName}? Once deleted it can not be recovered or restored.`);

    const data = props;


    const handleClickOpen = (e) => {
        if(props.onDeleteOpen) {
            props.onDeleteOpen(e);
        }
        setOpen(true);

    };

    const handleClose = () => {
        if(props.onDeleteClosed) {
            props.onDeleteClosed();
        }
        setOpen(false);
    };

    const deleteComponent = (event) => {
        updateComponent();
        setOpen(false);
    };



    return (
        <span>
            <IconButton aria-label="close" onClick={handleClickOpen} style={{ fontSize: '15px', color: props.color }}  >
                <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
            <Dialog open={open} onClose={handleClose} style={{zIndex: 2000}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete {componentName}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        <span style={{ fontWeight: 300 }}>Cancel</span>
                    </Button>
                    <Button onClick={deleteComponent} color="primary">
                        Delete
          </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
