import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiLink-root": {
      color: "#22b9ea"
    }
  },
  policyText: {
    //marginTop: 10,
    display: "inline-block",
    fontSize: 12,
    alignItems: "center",
    textAlign: "left",
    verticalAlign: "top",
    maxWidth: "80%"
  }
}));

export default function TermsAndConditions(props) {
  const data = props;

  let isOpen = data.isOpen;

  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        className={classes.policyText}
        color="textSecondary"
        variant="body1"
      >
        I accept the{" "}
        <a href="/terms-and-conditions" target="terms">
          Terms and Conditions
        </a>
      </Typography>
      <Dialog
        open={open || isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Terms and Conditions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
