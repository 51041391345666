import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {}
}));

const schema = {
  firstname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  phone: {
    presence: { allowEmpty: true, message: 'must be 10-digits' },
    length: {
      maximum: 10,
      minimum: 0
    }
  },
};

const AccountDetails = props => {
  const { user, updateUser, apiManager, funcs, className, ...rest } = props;

  const { firstname, lastname, email, phone } = user;

  const classes = useStyles();

  // const [values, setValues] = useState({
  //   ...user
  // });

  // const [error, setError] = useState();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstname,
      lastname,
      email,
      phone: phone || '' // Older acccounts have phone as null from DB
    },
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    console.log(errors)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));

    console.log('.', formState)
  }, [formState.values]);

  const handleChange = event => {
    const { name, value } = event.target;
    // if (name != 'phone' || value.length == 0 || (name == 'phone' && !isNaN((value)) && value.length < 11)) {
    //   // setValues({
    //   //   ...values,
    //   //   [name]: value
    //   // });
    //   setFormState({
    //     values: {
    //       ...formState.values,
    //       [name]: value
    //     },

    //   });
    // }

    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));

  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const updateDetails = async () => {

    try {
      if (formState.isValid) {
        const result = await apiManager.updateAccountDetails(formState.values);

        if (result && result.data && result.data._id) {
          //setError("Profile details have been updated!")
          funcs.openModal(true, `Profile details have been updated!`)
          updateUser(result.data);
        }
        else {
          funcs.openModal(false, `Profile details have NOT been updated!`)
        }
      } else {
        console.log('FORM: ', formState);
        funcs.openModal(false, `Profile values not valid`);
      }

    }
    catch (ex) {
      console.log(ex);
      // setError(ex)
    }
  }



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        {/* <CardHeader
          subheader="Edit your profile information"
          title="Profile"
        /> */}
        <div style={{marginLeft: 24, marginBottom: 12}}>
          <h3 style={{fontSize: 20}}>Profile</h3>
          <span>Edit your profile information</span>
        </div>
        <CardContent style={{marginLeft: 12, marginRight: 12}}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="First name"
                error={hasError('firstname')}
                margin="dense"
                name="firstname"
                onChange={handleChange}
                required
                value={formState.values.firstname || ''}
                helperText={
                  hasError('firstname') ? formState.errors.firstname[0] : null
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                error={hasError('lastname')}
                margin="dense"
                name="lastname"
                onChange={handleChange}
                required
                value={formState.values.lastname || ''}
                helperText={
                  hasError('lastname') ? formState.errors.lastname[0] : null
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                error={hasError('email')}
                margin="dense"
                name="email"
                onChange={handleChange}
                //required
                disabled
                value={formState.values.email || ''}
                helperText={
                  hasError('email') ? formState.errors.email[0] : null
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone"
                error={hasError('phone')}
                margin="dense"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={handleChange}
                name="phone"
                value={formState.values.phone || ''}
                helperText={
                  hasError('phone') ? formState.errors.phone[0] : null
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{marginLeft: 16, marginBottom: 12}}>
          <Button
            variant="outlined"
            onClick={updateDetails}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
  updateUser: PropTypes.func
};

export default AccountDetails;
