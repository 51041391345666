import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Avatar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import moment from 'moment';
import RemoveUser from './RemoveUser';
import RenderBriefs from './RenderBriefs';
import InviteExistingUser from '../../Brief/components/InviteExistingUser' 

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  avatar: {
      width: 60,
      height: 60,
      boxShadow: '1px 1px 10px #888888'
  },
  upDown: {
      fontSize: 28,
      display: 'inline-block',
      marginLeft: 24,
  },
  briefsection: {
      marginTop: 32,
      marginLeft: 4,
      marginBottom: 12,
  },
  brieftable: {
      fontSize: 18,
  },
  briefrow: {
    paddingTop: 12,
    paddingBottom: 14,
    borderBottom: '1px solid gray'
  },
  titlerow: {
      fontWeight: 900,

  },
  selectlabel: {
      fontSize: 11,
      fontWeight: 200,
  },
  checkbox: {
      paddingLeft: 8,
      marginRight: 12,
  },
  title: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
  },
  menuitem: {
      fontSize: 14,
      color: '#666'
  },
  avatarcolor1: {
    backgroundColor: '#25C2F4',
  },
  avatarcolor2: {
    backgroundColor: '#0052DD',
  },
  trashIcon: {
    position: 'absolute',
    right: '50px',
    top: '21px'
  },
  userName: {
      fontWeight: 600,
      fontSize: 16,
      height: 18,
  },
  email: {
      fontSize: 11,
  }
}));

export default function PersonPanel({ person, apiManager, hideDetails, handleDelete, updateAccess, refreshChild, isMobile }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let initials = person.userData && person.userData.firstname ? `${person.userData.firstname.toUpperCase().charAt(0)}${person.userData.lastname.toUpperCase().charAt(0)}` : person.email.substring(0, 2);
  initials = initials.replace('@', '');
  
    const handleChange = (panel) => (event, isExpanded) => {
      event.stopPropagation()
        if (!isMobile) {
            setExpanded(isExpanded ? panel : false);
        }
    };

  const handleSave = () => {

  }

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={isMobile ? null :<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
       {!isMobile && (
          <div style={{ float: 'left', paddingRight: '20px' }}>
        <Avatar
          alt="Person"
          className={`${classes.avatar} ${classes.avatarcolor1} brandColor2`} 
         >
        {initials}
        </Avatar>

        </div>
        )}
        <span>
          {/* <Typography  variant="h5" component="h5">
          { `${person.userData && person.userData.firstname ? person.userData.firstname : ''} ${person.userData && person.userData.lastname ? person.userData.lastname : ''}`}
          </Typography> */}
                    {/* <Typography variant="body3" color="textSecondary" component="p">
            {person.email}{ !person.firstname ?  <p> { moment(person.created).format('MM-DD-YYYY') }</p>: null }  
          </Typography> */}
          <div className={classes.userName}>
          { `${person.userData && person.userData.firstname ? person.userData.firstname : ''} ${person.userData && person.userData.lastname ? person.userData.lastname : ''}`}        
          </div>
          <div className={classes.email}>
          {person.email}{ !person.firstname ?  <p> { moment(person.created).format('MM-DD-YYYY') }</p>: null }            
          </div>

          </span> 

          <div style={isMobile ? {position: 'absolute', right: -42, top: 0} : null}>
          <RemoveUser person={person} apiManager={apiManager} handleDelete={e => handleDelete(person)} classes={classes} />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <span style={{ width: '100%' }}>
                <RenderBriefs person={person} apiManager={apiManager} updateAccess={updateAccess} refreshChild={refreshChild} isOpen={expanded === 'panel1'} />
            </span>
      
        </ExpansionPanelDetails>
      </ExpansionPanel> 
    </div>
  );
}