//This component handles all photo uploads
//Pass props 'replace' if replacing an image
//See EditBrief.js for replace example

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import dropIcon  from "../../../images/Breefly_Icons-DragDrop.svg"
import {
  Grid,
  Button,
  IconButton,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@material-ui/core";

import {
  faCamera,
  faPlusCircle,
  faCloudUploadAlt
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiManager from "../../../ApiManager";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  button: {
    backgroundColor: "#22AEE8",
    width: 184,
    height: 40,
    color: "#ffffff!important",
    "&:hover": {
      backgroundColor: "#0A1241"
    }
  }
}));

export default function AddPhoto(props) {
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(2);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const { isOpen, handleSave, handleClose, replace } = props;
  const [assets, setAssets] = React.useState([]);
  const [selectedAsset, setSelectedAsset] = React.useState(false);
  //const [message, setMessage] = React.useState(false);
  const [file, setFile] = React.useState(false);
  const [url, setUrl] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [uploadImage, setUploadImage] = React.useState(false);
  
  const getAssets = async () => {
    const userAssets = await apiManager.getAssets(true, { limit: 25, skip: 0 });
    const { assets, count } = userAssets;
    setAssets(assets);
  };
  if (isOpen == true && open == false) {
    setOpen(true);
    setSelectedAsset(false);
    setFile(false);
    setUrl(false);
    getAssets();
  } else if (isOpen == false && open == true) {
    setOpen(false);
  }
  const addPhoto = async () => {
    setOpen(false);
   
    let imageUrl = url;
    let s3Info = {};
    let completedUpload = null;
    if (file && file.type) {
      s3Info = await apiManager.getUploadKey(file);

      await apiManager.uploadToS3(s3Info.url, file);

      completedUpload = await apiManager.getAsset(s3Info.assetId);
    }
    await handleSave({
      url: imageUrl,
      alt: "",
      assetId: s3Info.assetId ? s3Info.assetId : selectedAsset._id,
      s3Asset: completedUpload
    });
  };

  const openModal = async () => {
    const userAssets = await apiManager.getAssets(true, { limit: 25, skip: 0 });
    const { assets, count } = userAssets;
    setAssets(assets);
    setOpen(true);
    props.closePanel();
  };

  const closeModal = () => {
    if (replace) {
      handleClose();
    }

    setUrl(false);
    setUploadImage(false);
    setOpen(false);
  };

  const selectImage = image => {
    setUrl(image.url);
    setSelectedAsset(image);
  };

  const captureFile = async (acceptedFile) => {
    const file = acceptedFile[0];
    try {
      setUrl(URL.createObjectURL(file));
      setFile(file);
      setSelectedAsset({});
      setUploadImage(true);
      let newImage = {
        name: file.name,
        url: URL.createObjectURL(file)
      };

      //   assets.unshift({ url: URL.createObjectURL(file) });
      setAssets(assets => [newImage, ...assets]);
      selectImage(newImage);

      // use below if you want to attempt to immediately use the image.. though it doesn't really work.
      // const result = await handleSave({
      //   url: URL.createObjectURL(file),
      //   alt: ""
      // });
      // addPhoto();
    } catch (ex) {}
  };


  const RenderUploadFile = props => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png, image/gif",
      onDrop: acceptedFiles => {
        captureFile(acceptedFiles);
      }
    });
    return (
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        {...getRootProps({})}
        className="photo-upload-holder"
      >
        <input {...getInputProps()} />
        <Grid item xs={12}>
          {/* <FontAwesomeIcon icon={faPlusCircle} /> */}
          <img src={dropIcon} alt="drag and drop here" width="120px" align="middle" />
        </Grid>
        <Grid item xs={12} style={{marginTop: -8, marginBottom: 16}}>
          <span>Drag and drop here to upload</span>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 16}}>
        <Button
            className={classes.button}
            color="primary"
            variant="contained"
            className={"brandColor2"}
          >
            <FontAwesomeIcon
              icon={faCloudUploadAlt}
              style={{ marginRight: 8 }}
            />
            <span>Or choose your files</span>
          </Button>
        </Grid>
      </Grid>
    );
  };

  //NOT USED
  const renderImagePreview = () => {
    return url && url.length > 0 ? (
      <>
        <Grid container justify="center" key={Math.random()}>
          <img
            src={url}
            style={{
              paddingTop: "20px",
              margin: "0 auto",
              paddingBottom: 24,
              maxWidth: "300px",
              maxHeight: "300px"
            }}
          />
        </Grid>
        <div
          style={{ width: "100%", margin: 24, borderTop: "1px solid #ddd" }}
        />
      </>
    ) : (
      <Grid container justify="center" spacing={spacing}>
        <FontAwesomeIcon icon={faCamera} />
      </Grid>
    );
  };
  const RenderAssetGrid = props => {
    const { images } = props;
    if (!images || images.length === 0) {
      return null;
    }
    return images.map(value => (
      <Grid
        item
        xl={4}
        lg={4}
        sm={6}
        xs={12}
        key={Math.random()}
        style={{ minHeight: 104 }}
      >
        <div
          style={{
            border: ` 2px solid ${
              value === selectedAsset ? "lightblue" : "white"
            }`,
            padding: 1,
            minWidth: 150,
            minHeight: 104,
            textAlign: "center"
          }}
        >
          <Link href="#" onClick={e => selectImage(value, e)}>
            <img src={value.url} width="146px" />
            {/* <img src={defaultImage} width="146px" />  */}
          </Link>
        </div>
      </Grid>
    ));
  };
  useEffect(() => () => {
    assets.forEach(el => URL.revokeObjectURL(el.preview));
  }, [assets]);
  return (
    <div className={classes.root}>
      {!replace && (
        <IconButton
          aria-label="close"
          className={classes.icon}
          onClick={openModal}
        >
          <FontAwesomeIcon icon={faCamera} />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <div style={{ padding: 25 }}>
          <DialogTitle id="form-dialog-title" >
            Add / Replace an Image
          </DialogTitle>
          <DialogContent>
            {/* {renderImagePreview()} */}
            <RenderUploadFile />

            <hr />
              <div
                style={{
                  fontSize: 14,
                  paddingTop: 10,
                  margin: "12px 0px",
                  fontWeight: 400,
                  textAlign: "center"
                }}
              >
                Or select an image
              </div>
            <Grid
              style={{ maxHeight: "300px" }}
              container
              justify="left"
              spacing={spacing}
            >
              {/* {renderUploadFile()} */}
              <RenderAssetGrid images={assets} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              <span style={{ fontWeight: 300 }}>Cancel</span>
            </Button>
            <Button
              onClick={addPhoto}
              color="primary"
              variant="text"
              disabled={selectedAsset === false}
            >
              <span style={{ color: `${selectedAsset ? "#333" : "#ccc"}` }}>
                {replace ? "Change Image" : "Add Image"}
              </span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
