import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  InputBase,
  ListItemIcon
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faUser,
  faCommentAlt,
  faWallet,
  faChartNetwork,
  faFileAlt,
  faShare,
  faCaretDown,
  faGlobe
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateBriefButton from "../../components/CreateBriefButton";
import Search from "./components/Search";
import LinearIndeterminate from "./components/LinearIndeterminate";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";

import "./style.css";
import SharedBriefs from "../Briefs";
import { withStyles } from "@material-ui/styles";
import { VerticalAlignBottom } from "@material-ui/icons";
import SubscriptionModal from "../../components/SubscriptionModal";
import eventTracker from "../../utils/event-tracker";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints]: {
      values: { md: 1130 }
    },
    // padding: 25,

    // '& .MuiTextField-root': {
    // },
    // '& .MuiOutlinedInput-root': {
    //     height: 44,
    // },
    // '& .MuiInputLabel-outlined': {
    //     paddingTop: 2,
    // },,
    textField: {}
  },
  searchbar: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    height: 100,
    paddingTop: 8,
    "@media (max-width: 650px)": {
      textAlign: "left",
      paddingLeft: 46,
      height: 80
    }
  },
  searchform: {
    width: "50%",
    "@media (max-width: 860px)": {
      width: "33%"
    },
    "@media (max-width: 500px)": {
      width: "40%",
      minWidth: 180
    }
  },
  primaryCTA: {
    position: "absolute",
    top: 8,
    left: 0,
    "@media (max-width: 650px)": {
      position: "unset",
      marginTop: 24
    }
  },
  mobileCTA: {
    position: "absolute",
    top: 8,
    right: -24
  },
  favDesktop: {
    fontSize: 14,
    color: "#888;",
    "&.active": {
      color: "#333"
    }
  },
  favMobile: {
    fontSize: 14,
    //color: '#333',
    width: "100%"
  }
}));

export default function Analytics(props) {
  const classes = useStyles();

  const { user, apiManager, history, isMobile, refreshChild } = props;

  // const isAdmin = user.roles.includes("admin");
  // const isOrgAdmin =
  //   user.orgId && (user.orgRole === "owner" || user.orgRole === "co-owner");
  const isAdmin = user & user.roles && user.roles.length > 0 ? user.roles.includes('admin') : false;
  const brandStr = localStorage.getItem('orgBranding');
  const branding = brandStr ? JSON.parse(brandStr) : null;
 const isOrgAdmin = user.orgId && (user.orgRole === "owner" || user.orgRole === "co-owner");

  const [briefs, setBriefs] = React.useState([]);
  const [brief, setBrief] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isCaseView, setIsCaseView] = React.useState(false);
  const [isFavorites, setIsFavorites] = React.useState(false);
  const [isGrid, setIsGrid] = React.useState(true);
  const [init, setInit] = React.useState(false);

  const [skip, setSkip] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(isMobile ? 24 : 12);
  const [count, setCount] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [toDate, setTo] = React.useState(
    moment()
      .add(0, "d")
      .format("MM/DD/YYYY")
  );
  const [fromDate, setFrom] = React.useState(
    moment()
      .subtract(5, "y")
      .format("MM/DD/YYYY")
  );
  const [dateRange, setDateRange] = React.useState(1000);
  const [createdBriefs, setCreatedBriefs] = React.useState(0);
  const [searchedBriefs, setSearchedBriefs] = React.useState([]);
  const [networkShares, setNetworkShares] = React.useState([]);
  const [networkShareCount, setNetworkShareCount] = React.useState(0);
  const [publicShares, setPublicShares] = React.useState([]);
  const [favorites, setFavorites] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  const [searches, setSearches] = React.useState([]);
  //const [filter, setFilter] = React.useState("me");

  let initFilter = '';
  if( isAdmin) { 
      initFilter = 'system-wide';
  } else  if( isOrgAdmin ) {
      initFilter = 'team-use'
  }
  const [activeFilter, setActiveFilter] = React.useState(initFilter);
  //companyId = selected company, companyList = all companies in system
  const [companyId, setcompanyId] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);

  const getReports = async (
    newLimit,
    newPage,
    newSearch,
    isFavoritesOverride
  ) => {
    // const sendLimit = newLimit ? newLimit : limit;
    // const sendPage = newPage != null ? newPage : page;
    setIsLoading(true);

    // if (newSearch != null) {
    //     setSearch(newSearch)
    // }

    // need to get all 5 reports
    const resultCreated = await apiManager.getReport({
      type: 0,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });
    const resultCommented = await apiManager.getReport({
      type: 1,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });
    const resultNetwork = await apiManager.getReport({
      type: 2,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });
    const resultFavorites = await apiManager.getReport({
      type: 3,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });
    const resultPublic = await apiManager.getReport({
      type: 4,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });
    const resultSearches = await apiManager.getReport({
      type: 5,
      from: fromDate,
      to: toDate,
      filter: activeFilter,
      companyId: companyId
    });

    if (resultCreated) {
      setCreatedBriefs(resultCreated.created);
    }
    if (resultCommented) {
      setComments(resultCommented.comments); // has .comments and .inNetwork arrays
    }

    if (resultNetwork) {
        setNetworkShareCount(resultNetwork.inNetworkShareCount ? resultNetwork.inNetworkShareCount : 0)
        setNetworkShares(resultNetwork.inNetwork);
    }
    if (resultPublic) {
      setPublicShares(resultPublic.public);
    }
    if (resultFavorites) {
      setFavorites(resultFavorites.favorites);
    }
    if (resultSearches) {
      setSearches(resultSearches.public);
    }
    // if (result && result.briefs) {
    //     let { briefs, count } = result;
    //     setBriefs(briefs);
    //     setCount(count);
    // }
    setIsLoading(false);
  };

  const updatePage = async (event, page) => {
    setIsLoading(true);
    await getReports(limit, page - 1, search);
  };

  const getCompanyList = async () => {
    const list = await apiManager.getOrganizationList();
    setCompanyList(list);
  };

  if (!init && briefs.length == 0) {
    setInit(true);
    updatePage();
    //if (isAdmin {
    if (isAdmin || isOrgAdmin) {
      getCompanyList();
    }
  }
  //unused currently
  // const handleSetFilter = filter => {
  //   setFilter(filter);
  //   updatePage();
  // };

  const initializeFilter = () => {
    // if(activeFilter === "") {
    //   if (isAdmin) {
    //     setActiveFilter('system-wide');
    //   }
    //   else if (isOrgAdmin) {
    //     setActiveFilter('team-use');
    //   }
    // }
  }
  initializeFilter();
  //set filter and rerender page on change
  const handleActiveFilter = e => {
    setActiveFilter(e.currentTarget.id);
    setcompanyId("");
  };

  // set company id and rerender page
  const handleMenuClick = e => {
    setActiveFilter("");
    setcompanyId(e.target.value);
  };

  // set company id and rerender page
  const handleTimeMenu = e => {
    setFrom(
      moment()
        .subtract(e.target.value, "d")
        .format("MM/DD/YYYY")
    );
    //setFrom(date);
    setDateRange(e.target.value);
  };
  useEffect(() => {
    updatePage();
  },[fromDate, activeFilter, companyId])

  const FilterItem = props => {
    return (
      <Button
        aria-label="favorite"
        // onClick={renderFavorites}
        id={props.name}
        classes={{
          label: `analytics ${classes.favDesktop} ${
            activeFilter === props.name ? "active" : ""
          }`
        }}
        // className={}
        onClick={handleActiveFilter}
      >
        <div style={{ fontSize: 18, marginRight: 8 }}>
          <FontAwesomeIcon icon={props.icon} color="primary" />
        </div>
        {props.text}
      </Button>
    );
  };
  const CompanySelect = withStyles({
    root: {
      minWidth: 120,
      verticalAlign: "baseline",
      marginLeft: 10,
      fontSize: 14,
      color: "#888;",
    },
    label: {
      fontSize: 14,
      color: "#888;",
      "&:focus": {
        color: "var(--brand-color1)"
      }
    },
    select: {
      fontSize: 14,
      color: "#333;",
      padding: "0px 10px 0px 10px",
      "&:before": {
        borderColor: "#888"
      },
      "&:after": {
        borderColor: "#888"
      }
    },
    hover: {
      "&:hover": {
        fontSize: 14,
        borderColor: "var(--brand-color1)"
      }
    }
  })(({ classes }) => (
    <FormControl className={classes.root}>
      <InputLabel id="company-accounts" className={classes.label}>
        {" "}
        <FontAwesomeIcon
          icon={faBuilding}
          //color="primary"
          style={{ marginRight: 10 }}
        />
        {companyId === null ? "" : "Company"}
      </InputLabel>
      <Select
        value={companyId}
        className={classes.select}
        onChange={handleMenuClick}
      >
        <MenuItem aria-label="None" id="none" value="" />
        <MenuItem id="all" value="all">
          All{" "}
        </MenuItem>
        {companyList.map((i, index) => (
          <MenuItem id={i._id} key={index} value={i._id}>
            {i.companyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ));

  const TimeSelect = withStyles({
    root: {
      minWidth: 75,
      verticalAlign: "baseline",
      marginLeft: 10,
      fontSize: 14,
      color: "#888;",
      marginTop: 20,
      marginBottom: 36
    },
    label: {
      fontSize: 14,
      color: "#888;",
      "&:focus": {
        color: "#49A8A0"
      }
    },
    select: {
      fontSize: 14,
      color: "#888;",
      padding: "0px 10px 0px 10px",
      "&:before": {
        borderColor: "#888"
      },
      "&:after": {
        borderColor: "#888"
      }
    },
    hover: {
      "&:hover": {
        fontSize: 14,
        borderColor: "#49A8A0"
      }
    }
  })(({ classes }) => (
    <div style={{ marginLeft: 8, display: 'inline-block' }}>
      <FontAwesomeIcon
        icon={faCalendarAlt}
        //color="primary"
        style={{ color: "#888" }}
      />
      <FormControl className={classes.root}>
        {/* <FontAwesomeIcon
          icon={faCalendarAlt}
          //color="primary"
          style={{ margin: "0px 10px" }}
        /> */}
        <Select
          //displayEmpty
          value={dateRange}
          className={classes.select}
          onChange={handleTimeMenu}
        >
          <MenuItem id="today" value="1">
            Today
          </MenuItem>
          <MenuItem id="last-7" value="7">
            Last 7 Days
          </MenuItem>
          <MenuItem id="today" value="30">
            Last 30 Days
          </MenuItem>
          <MenuItem id="today" value="90">
            Last 90 Days
          </MenuItem>
          <MenuItem id="all" value="1000">
            All Time
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  ));
  // const updateIntercom = user => {
  //   window.intercomSettings = {
  //     app_id: "zcl62xdv",
  //     name: `${user.firstname} ${user.lastname}`,
  //     email: `${user.email}`,
  //     created_at: `${user.created}`
  //   };
  //   window.Intercom("boot", window.intercomSettings);
  //   sessionStorage.setItem("intercom", JSON.stringify(window.intercomSettings));
  // };

  // updateIntercom(user);

  const detailsClick = brief => {
    history.push(`/brief/details/${brief._id}`);
  };
  const [createError, setCreateError] = React.useState('')
  const createClick = async () => {
    setIsLoading(true);

    const brief = {
      title: "Enter your title...",
      tags: [],
      briefcase: { value: 0 },
      components: [
        {
          type: 0,
          content: {
            text: "Enter your title..."
          },
          position: 0
        }
      ]
    };
    const result = await apiManager.createBrief(brief);
    if (result && result.insertedCount > 0) {
      //history.push(`/brief/edit/${result._id}`);
      eventTracker('Brief Creation', user, 'analytics')
      window.location = `/brief/edit/${result._id}`;
    } else {
      if(result && result.msg){
        setCreateError(result.msg)
      } else {
        setSnackMessage(
          result && result.msg ? result.msg : "An error has occurred"
        );
        setSnackBar(true);
      }      
    }
    setIsLoading(false);
  };

  const viewDetails = id => {
    history.push(`/brief/details/${id}`);
  };

  const showList = type => {
    let list = networkShares;
    if (type === "searches") {
      list = searches;
    } else if (type === "favorites") {
      list = favorites;
    } else if (type === "comments") {
      list = comments;
    }

    // sort the list

    if(list.length > 0) {
        list = list.sort((a, b) => b.count - a.count);
    }
    const defaultListSize = 20;
    if (list.length < defaultListSize) {
      const grayTitle =
        type !== "searches" ? "Brief title to come" : "Future search term";
      for (let i = list.length; i < defaultListSize; i++) {
        list.push({ count: 0, title: grayTitle, _id: null });
      }
    }

    if (type === "searches") {
      return (
        <div className="list">
          <div className="listItem">
            <div className="listTitle">Total </div>
            <div className="listValue" style={{ height: "auto" }}>
              Search Term
            </div>
          </div>
          {list.map((item, index) => (
            <div
              key={index} className={`listItem ${item.count === 0 ? "itemInactive" : ""}`}
            >
              <div className="listTitle">{item.count}</div>
              <div className="listValue">
                <div
                  className={`ellipsize ${
                    item.count === 0 ? "itemInactive" : ""
                  } `}
                >
                  {item._id}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="list">
        <div className="listItem">
          <div className="listTitle">Total {type}</div>
          <div className="listValue" style={{ height: "auto" }}>
            Brief Title
          </div>
        </div>
        {list.map((item, index) => (
          <div key={index} className={`listItem ${item.count === 0 ? "itemInactive" : ""}`}>
            <div className="listTitle">{item.count}</div>
            <div className="listValue">
              <div
                onClick={item.count > 0 ? () => detailsClick(item) : null}
                className={`ellipsize ${
                  item.count === 0 ? "itemInactive" : ""
                } `}
              >
               {item.title && item.title !== '' ? item.title : '<no title>'}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const render = () => {
    return (
      <div className={`${classes.root} analytics`}>
        {/* searchbar */}
        <div className={classes.searchbar}>
          {/* <Search setPage={setPage} getBriefs={getBriefs} classes={classes} limit={limit} page={page} refreshChild={refreshChild}/> */}
          <div className={isMobile ? classes.mobileCTA : classes.primaryCTA}>
            <CreateBriefButton handleClick={createClick} isMobile={isMobile} />
          </div>
        </div>

        {/* logo */}
        {branding && branding.logo && (
                <Grid container >
                    <Grid item xs style={{ marginTop: 48 }}>
                        <div style={{ float: 'right' }}>
                            <img src={branding.logo} width='125px' />
                        </div>
                    </Grid>
                </Grid>
            )}

        {/* Filter Picker */}
        <Grid container spacing={4} style={{ marginBottom: 18, marginTop: -24 }}>
          {/* different view depending on role */}
          {/* <h3 className="analytics">User Insights</h3> */}

          <Grid item xs={12}>
            {(isAdmin || isOrgAdmin) && (
              <>
                {isAdmin ? (
                  <>
                    {/* ADMIN FILTER MENU */}
                    <FilterItem
                      name="system-wide"
                      text="System Wide"
                      icon={faGlobe}
                    />
                    <FilterItem
                      name="individual-accounts"
                      text="Individual Accounts"
                      icon={faUser}
                    />
                    <FilterItem
                      name="team-accounts"
                      text="Team Accounts"
                      icon={faUsers}
                    />
                    <CompanySelect />
                    <br />
                  </>
                ) : (
                  <>
                  {user.onTeam && (user.hostOrg && user.orgAffiliates.length == 0 )? (
                    <>
                    <FilterItem
                      name="team-use"
                      text="Team Use"
                      icon={faUsers}
                    />
                    <FilterItem
                      name="company-use"
                      text="Company Use"
                      icon={faBuilding}
                    />
                    <FilterItem
                      name="subscriber-use"
                      text="Subscriber Use"
                      icon={faWallet}
                    />
                    <br />
                    </>
                  ) : (user.onTeam ||true) &&
                    <div style={{paddingLeft: 8, fontSize: 16, fontWeight:500}}> Your team's combined activity: </div>
                    }
                  </>
                )}
              </>
            )}
            {/* ALL OTHERS FILTER MENU */}
            <TimeSelect />
            {/* <Button
              aria-label="favorite"
              // onClick={renderFavorites}
              className={classes.favDesktop}
            >
              <div style={{ fontSize: 18, marginRight: 8 }}>
                <FontAwesomeIcon icon={faCalendarAlt} color="primary" />
              </div>
              {dateRange}
              {dateRange === "Custom" && (
                <span>
                  : From <b>{fromDate}</b> to <b>{toDate}</b>
                </span>
              )}
            </Button> */}
          </Grid>
        </Grid>

        {/* top 3 panels */}
        <Grid
          container
          spacing={4}
          style={{ display: isLoading ? "none" : "" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <div className="card">
              <div className="content">
                Briefs Created
                <div className="value">{createdBriefs}</div>
              </div>
              <div className={"brandColor2 icon"}>
                <FontAwesomeIcon icon={faFileAlt} color="primary" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="card">
              <div className="content">
                Network Shares
                <div className="value">{networkShareCount}</div>
              </div>
              <div className={"brandColor2 icon"}>
                <FontAwesomeIcon icon={faChartNetwork} color="primary" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="card">
              <div className="content">
                Public Shares
                <div className="value">{publicShares.length}</div>
              </div>
              <div className={"brandColor2 icon"}>
                <FontAwesomeIcon icon={faShare} color="primary" />
              </div>
            </div>
          </Grid>
        </Grid>
        {/* top 10 lists */}
        <Grid
          container
          spacing={4}
          style={{ display: isLoading ? "none" : "" }}
        >
          <Grid item xs={12} sm={6}>
            <div className="card tallCard">
              <div className="content"></div>
              <div className={"icon topBar brandColor1"}>
                Most Searched Terms
                <div className={"topBarSubtitle"}>
                  Top search terms used by your network
                </div>
              </div>
              {showList("searches")}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="card tallCard">
              <div className="content"></div>
              <div className={"icon topBar brandColor1"}>
                Most Commented Briefs
                <div className={"topBarSubtitle"}>
                  Top briefs commented on by your network
                </div>
              </div>
              {showList("comments")}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="card tallCard">
              <div className="content"></div>
              <div className={"icon topBar brandColor1"}>
                Most Shared Briefs
                <div className={"topBarSubtitle"}>
                  Top briefs shared by your network
                </div>
              </div>
              {showList("shares")}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="card tallCard">
              <div className="content"></div>
              <div className={"icon topBar brandColor1"}>
                Most Favorited Briefs
                <div className={"topBarSubtitle"}>
                  Top briefs favorited by your network
                </div>
              </div>
              {showList("favorites")}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ display: !isLoading ? "none" : "block" }}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <LinearIndeterminate />
          </Grid>
        </Grid>
        <Snackbar
          open={showSnackBar}
          autoHideDuration={3000}
          className="error"
          severity="info"
          onClose={() => setSnackBar(false)}
          variant="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Alert severity="error">{snackMessage}</Alert>
        </Snackbar>
        {createError && 
          <SubscriptionModal 
              open={Boolean(createError)}
              message={createError}
              onClose={() => setCreateError('')}
              user={user}
              modalTitle={"briefs"}
            />
        }
      </div>
    );
  };

  return render();
}
