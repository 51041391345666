/*THIS COMPONENT MIMICS /BRIEF/BRIEFDETAIL.js Please ensure that the brief rendering sections stay in sync */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    IconButton,
    Box
} from '@material-ui/core';
import Rightbar from './components/Rightbar'
import Notification from '../Modals/Notification';
import Chart from '../Brief/components/Chart';
import 'typeface-roboto';
import axios from 'axios';
import { API_LOCAL } from '../../constants';
import TopBanner from './components/TopBanner'
import TopButtons from './components/TopButtons'
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate'

import { mostReadable } from '@ctrl/tinycolor'

import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ChartDisplay from '../Visualizations/components/ChartDisplay';
import SentimentChart from '../Visualizations/SentimentChart';
import {extractSubDomain} from '../../helpers/domainNames'

let apiManager = {
    token: '',
    getHeaders: function () {
        return {
            token: this.token,
            skip: 0,
            limit: 10
        }
    },
    getBrief: async function (id, token) {
        const url = (API_LOCAL) + `briefs/public/get/${id}/${token}`
        try {

            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getComments: async function (briefId) {
        const userToken = sessionStorage.getItem('token');
        if (userToken) {
            this.token = userToken;
        }
        const url = (API_LOCAL) + `comments/get/${briefId}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createComment: async function (data) {
        data.token = this.token;
        const userToken = sessionStorage.getItem('token');
        if (userToken) {
            this.token = userToken;
        }
        const { brief } = data;
        const url = (API_LOCAL) + `comments/create/${brief}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateComment: async function (data, isLike) {

        const userToken = sessionStorage.getItem('token');
        if (userToken) {
            this.token = userToken;
        }
        const { _id } = data;
        const url = (API_LOCAL) + `comments/${isLike ? 'like' : 'dislike'}/${_id}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getUserToken: async function () {
        const userToken = sessionStorage.getItem('token');
        if (userToken) {
            this.token = userToken;
        }
        const url = (API_LOCAL) + `users/token/validate`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIChartAssets: async function (asset = null) {

        const param = asset ? `?assetId=${asset}` : '';
        const url = `${API_LOCAL}datasets/pmi/shared/get-user-assets${param}`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.get(url, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIChartData: async function (filter) {
        const url = (API_LOCAL) + `datasets/pmi/filter`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, filter, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMISentimentResponse: async function (query) {
        const url = `${API_LOCAL}datasets/pmi/sentiment/get-question-response`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, { query }, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
}


const styles = (theme => ({
    root: {
        //   padding: theme.spacing(4),
        //   minHeight: '90vh'
    },
    img: {
        maxWidth: '600px',
        '@media (max-width: 500px)': {
            maxWidth: 300,
        },
    },
    searchbar: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        height: 100,
        paddingTop: 8,
        '@media (max-width: 650px)': {

        },
    },
    searchform: {
        width: 600,
        '@media (max-width: 1025px)': {
            width: 500,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 725px)': {
            width: '50%',
            minWidth: 150,
        },
        '@media (max-width: 650px)': {
            width: '55%',
            minWidth: 150,
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            width: 100,
            marginLeft: 42,
        },
    },
    briefArea: {
        width: 630,
        maxWidth: 630,
        '@media (max-width: 1025px)': {
            width: 500,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 850px)': {
            width: '100%',
            minWidth: 150,
        },
        '@media (max-width: 500px)': {
            width: '80%',
            minWidth: 150,
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 24,
        margin: "24px 20px 0px 20px"
    },
    author: {
        fontWeight: 450,
        fontSize: 16,
        marginRight: 550,
    },
    controlBlock: {
        marginBottom: 48,
        width: 630,
        '@media (max-width: 1025px)': {
            width: 530,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 850px)': {
            width: '100%',
            minWidth: 150,
        },
        '@media (max-width: 500px)': {
            width: 'calc(100% - 42px)',
            minWidth: 150,
        },
    },
    commentButton: {
        position: 'fixed',
        right: '25px',
        top: '25px',
        '@media (max-width: 500px)': {
            right: '16px'
        },
    },
    commentButtonMentions: {
      position: 'fixed',
      right: '68px',
      top: '51px',
      backgroundColor: 'var(--brand-color2)',
      borderRadius: '50%',
      width: 20,
      height: 20,
      color: 'white',
      fontSize: '10px',
      letterSpacing: '0,025em',
      paddingTop: 3,
      textAlign: 'center',
      fontWeight: 500,
      '@media (max-width: 500px)': {
          right: '16px'
      },
    },
    paragraph: {
        // fontWeight: 500,
        fontSize: 14,
    },
    hostOrgSpacer: {
      width: 1,
      height: 100,
    }
}));


const BriefDetail = (props) => {
    const { history, classes, isMobile } = props;


    const [isModalOpen, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [isRightOpen, setOpenRightbar] = useState(false);
    const [funcs, setFuncs] = React.useState(false);
    const [isHostOrg, setHostOrg] = React.useState(false);
    const [brief, setBrief] = React.useState(false);
    const [token, setToken] = React.useState('');
    const [briefId, setBriefId] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const [user, setUser] = React.useState({});
    const [belongsToOrg, setBelongsToOrg] = React.useState(true);
    const [author, setAuthor] = React.useState('');
    const [mentions, setMentions] = React.useState(false);

    useEffect(() => {
       const subDomain = extractSubDomain();
       if(subDomain && subDomain !== '' ) { // (subDomain === 'insite' || subDomain === 'insite-stage' || subDomain === 'pma' || subDomain === "pma-stage")){
           setHostOrg(true);
       }
    },[])
    const closeModal = () => {
        setOpen(false)
    }

    const openRightbar = () => {
        setFuncs({
            addComment,
            likeComment,
            dislikeComment
        })
        setOpenRightbar(true)
    }

    const onRightClose = () => {
        setOpenRightbar(false)
    }


    const getBrief = async () => {
        setIsLoading(true);
        const urls = history.location.pathname.split('/');
        const id = urls[urls.length - 2];
        const token = urls[urls.length - 1];
        setToken(token);
        setBriefId(id);
        apiManager.token = token;
        await setBrief({})
        const brief = await apiManager.getBrief(id, token);
        console.log(brief);

        if (!brief) {
            setTimeout(async function () {
                await getBrief()
            }, 2000)
        }

        const comments = await apiManager.getComments(id);
        setComments(comments);
        if(comments && comments.length > 0) {
          let m = 0;
          comments.forEach(comment => {
            if(comment.mentions && comment.mentions.includes(user.email)) {
              m++;
            }
          })
          setMentions(m > 0 ? m : false);

          // USING COUNT OF COMMENTS RATHER THAN MENTIONS
          setMentions(comments.length);
        }
        if (brief && brief._id) {
            await validateToken();
            await getUser();
            await setBrief(brief);
            if( !brief.belongsToOrg) {
                setBelongsToOrg(false);
            }
            setIsLoading(false);
            setAuthor(`${brief.Owner.firstName} ${brief.Owner.lastName}`)

        }
        else if (brief && !brief._id) {
            setIsLoading(false);
        }
    }

    const validateToken = async () => {
        const user = await apiManager.getUserToken();
        if (user.account) {
            setUser(user)

        }
    }

    const getUser = async () => {
        return true;
    }

    const renderNotAvailable = () => {
        return (<div style={{margin: 32, fontSize: 18}}>Sorry, this brief is no longer available. <br /><br />Please contact your administrator for help.</div>)
    }

    const renderTitle = (data, key) => {
        const { text } = data;
        return (
          <div key={key} style={{ width: '100%', marginTop: 30}} >
            <div className={classes.title}>
                {text}
            </div>
        </div>
        )
    }
    const renderImage = (data, key) => {
        const { url, alt, assetId, size, align } = data;
        return (<div key={key} style={{ width: '100%', textAlign: align, padding: '20px 0px' }}>
            <img src={url} alt={alt} className={classes.img} style={{ width: size }} />

        </div>)
    }
    const renderParagraph = (data, key) => {
        const { text } = data;
        return (<div key={key} style={{width: '100%'}} >


            <div dangerouslySetInnerHTML={{ __html: `${text}` }} style={{ width: '100%', padding: '20px' }} className={classes.paragraph} />

        </div>)
    }
    const renderVideo = (data, key) => {
        const { url, alt, assetId, size, align } = data;

        let wstyle = { width: `${size}px` };
        
        if (size == "100%" || size == "75%" || size == "50%" || size == "25%"){
            wstyle = {width: `${size}`}
        }
        if (isMobile) {
            wstyle = { maxWidth: 300 }
        } else   if (size == "100%" || size == "75%" || size == "50%" || size == "25%"){
            wstyle = {width: `${size}`}
        }
        let isThumbnail = false;
        if(url && (url.indexOf('.png') > -1 || url.indexOf('.jpg') > -1 || url.indexOf('.jpeg') > -1 )){
            isThumbnail = true;
        }

        let output = (<video alt={alt} src={url} controls style={wstyle} alt={alt}>Your browser does not support the video tag.</video>)

        if(isThumbnail){
            output = <img src={url} width={600} alt={alt}/>
        }

        return (url ? <div key={key} style={{ textAlign: `${align}`, width: '100%', padding: '20px 0px' }}>
            { output }
        </div> : null)
    }
    const renderChart = (data, key) => {
        return (<Box 
            key={key} alignSelf="stretch"><div style={{ textAlign: 'center', margin: "24px 0px"}}>
                <Chart title={data.title} subtitle={data.subtitle} chartType={data.chartType} dataPoints={data.dataPoints} size={data.size} chartColor={data.chartColor} unit={data.unit} key={key}/>
            </div>
            </Box>)
    }

    const renderCustomChart = (component, key) => {
        const { assetId, align, customSettings, settings} = component;
        const size = component.dataset && component.dataset === 'sentiment' ? component.size+120 : component.size;
          return (
              <div style={{ position: 'relative', width: '100%', padding: 12, background: 'white', marginBottom: 32 }} key={key} >
                    <div id={`chartDivTitle-${assetId}`}></div>
                    <div id={`chartDiv-${assetId}`} style={{ marginTop: 14, minHeight: size, textAlign: 'center', fontSize: 12 }} > 
                    {component.dataset && component.dataset === 'sentiment'
                            ?
                            <SentimentChart
                            chartDiv={`editChartDiv-${assetId}`}
                            chartDivTitle={null} // {`chartDivTitle-${assetId}`}
                            chartSettings={customSettings || null}
                            chartData={null}
                            chartAssetId={assetId}
                            appSettings={null}
                            apiManager={apiManager}
                            inBrief={true} 
                            // selectComponent={setSelected}
                            />
                            :
                                <ChartDisplay 
                                        chartDiv={`chartDiv-${assetId}`} 
                                        chartDivTitle={`chartDivTitle-${assetId}`}
                                        chartSettings={settings || null} 
                                        chartData={null} 
                                        chartAssetId={assetId}
                                        appSettings={null}  
                                        apiManager={apiManager}
                                        inBrief
                                        />      
                            }       
                    </div>
              </div>
          )
    }

    const renderLink = (content, key=`rlink-${Math.random(1000)}`) => {
        const { text, bold='bold', size='11', italic, button, background, color } = content;
        let {url} = content;
        let textColor = color ? color : '#000';
        let buttonColor = background ? background : 'transparent';
        if (buttonColor === '#5F92FF') {
            textColor = '#fff';
            buttonColor = '#22AEE8';
        }
        textColor = mostReadable(buttonColor, [textColor], {includeFallbackColors: true}).toHexString();

        const style = {
            padding: '13px 26px',
            minWidth: 200,
            borderRadius: 3,
            fontSize: `11px`,
            fontStyle: italic ? 'italic' : 'normal',
            fontWeight: bold ? 'bold' : 'inherit',
            backgroundColor: buttonColor,
            color: textColor,
            border: 'none',
        }
        const divStyle = {
            textAlign: 'center',

        }

        url = (url == "Add the link URL") ? 'www.breefly.com' : url

        if( !url.startsWith('http')) {
            url = `https://${url}`;
        }
        return (<div key={key} style={{ position: 'relative' }}>
            <div style={divStyle}  >
                <a href={url}  target="_blank">
                    {<button style={style}>
                        <span>{text}</span>
                        </button>}
                </a>
            </div><br />

        </div>)
    }

    const renderByType = (component, index) => {
        if (!component) {
            return null;
        }
        let { type, content, key } = component;
        if (index === 0) {
            type = 0;
        }
        switch (type) {
            case 0:
                // title
                return renderTitle(content, key);

            case 1:
                // image
                return renderImage(content, key);

            case 2:
                // paragraph
                return renderParagraph(content, key);

            case 3:
                return renderVideo(content, key)
            // video

            case 4:
                return renderChart(content, key)
            // chart

            case 5:
                // link
                return renderLink(content, key)
            case 6:
                // custom chart
                return renderCustomChart(content, key)
            default:
                return null;
        }
    }

    // const renderComments = () => {
    //     return (<div><Typography variant="body1" gutterBottom>
    //     Comments
    //   </Typography></div>);
    // }

    const renderCommentsButton = () => {
        if (isLoading || !brief._id) {
            return null;
        }
        return (
          <div className='commentButton' style={{ position: 'fixed', right: 24, top: 12 }}>
            <IconButton style={{ color: "#fff", backgroundColor: "#000055" }} aria-label="close" className={classes.icon} onClick={openRightbar}>
                <FontAwesomeIcon size={'lg'} icon={faCommentAlt} style={{ paddingTop: 4 }} />
            </IconButton>
            {mentions && (
            <div className={classes.commentButtonMentions}>
              {mentions}
            </div>
          )}
        </div>
        );
    }
    const addComment = async (comment, commentText, mentions) => {
        apiManager.token = token;
        const insert = {
            brief: briefId,
            comment,
            commentText,
            mentions,
        }

        const result = await apiManager.createComment(insert);

        const comments = await apiManager.getComments(briefId);
        setComments(comments);
    }

    const dislikeComment = async (comment) => {
        await apiManager.updateComment(comment, false);
        const comments = await apiManager.getComments(briefId);
        setComments(comments);
    }

    const likeComment = async (comment) => {
        await apiManager.updateComment(comment, true);
        const comments = await apiManager.getComments(briefId);
        setComments(comments);
    }

    const renderBrief = (components) => {


        const data = components.map(function (value, index) {
            return renderByType(value, index);
        })

        return (<Grid
            container
            spacing={4}
            alignItems='center'
            justify='center'
            direction='column'
        >
            {data}
        </Grid>)
    }


    if (!brief) {

        getBrief()
    }



    return (
        <div>
            <div> <TopBanner belongsToOrg={belongsToOrg}/></div>
            {!isHostOrg 
            ?
                <div> <TopButtons brief={briefId} /></div>
            :
              <div className={classes.hostOrgSpacer}> </div>
            } 
            <div className={classes.root} >

            { /* view and controls */}
            
            <Grid
                container
                spacing={4}
                alignItems='center'
                justify='center'
                direction='column'
            >
                
                { /* content of brief */}
                <Grid
                    container
                    className={classes.briefArea}
                    direction="column"
                    alignItems="center"
                    alignContent="stretch"
                //zeroMinWidth="false"
                //justify="center"
                >
                    
                    {isLoading ?
                        <Grid
                        container
                        spacing={2}
                        style={{ display: !isLoading ? 'none' : 'block' }}
                        >
                    <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            
                            ><LinearIndeterminate /></Grid>
                    </Grid>
                        :
                        <Grid
                            container
                            spacing={4}
                            alignItems='center'
                            justify='center'
                            direction='column'
                        >
                            <div className={classes.author}>{author}</div>
                            {brief.components ? renderBrief(brief.components) : renderNotAvailable()}
                        </Grid>
                    }
                </Grid>
            </Grid>

                <Notification isOpen={isModalOpen} message={message} closeModal={closeModal} />
                {renderCommentsButton()}
                <Rightbar isOpen={isRightOpen} brief={brief} onClose={onRightClose} funcs={funcs} comments={comments} user={user} isPublic/>
            </div></div>
    );
};

BriefDetail.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
};

export default withStyles(styles)(BriefDetail);
