import React from "react";
import { Button } from "@material-ui/core";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddSeats = props => {
  const { history, downgrade } = props;
  const handleClick = () => {
    history.push('/subscriptions')
  }
  return (
    <Button
      className="button-add-seats"
      size="medium"
      onClick={handleClick}
      style={{marginLeft: props.left}}
    >
      <FontAwesomeIcon icon={downgrade? faMinusCircle : faPlusCircle} className="button-add-seats icon" />
      <span className="button-add-seats cta">{downgrade ? "Complete Downgrade" : "Add more seats"}</span>
    </Button>
  );
};
export default AddSeats;
