import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Paper,
  CardContent,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio
} from "@material-ui/core";
import {
  faPen,
  faCloudUpload,
  faPlusCircle,
  faSlidersV,
  faFlowerDaffodil,
  faCarrot,
  faHeart,
  faSearchDollar,
  faCalendar,
  faMinusCircle,
  faMapMarked,
  faTimesCircle
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TwitterPicker } from "react-color";
import barChart from "../../../images/ChartIcons/Bar_chart_icon.svg";
import columnChart from "../../../images/ChartIcons/Column_chart_icon.svg";
import donutChart from "../../../images/ChartIcons/Donut_chart_icon.svg";
import gaugeChart from "../../../images/ChartIcons/Gauge_chart_icon.svg";
import lineChart from "../../../images/ChartIcons/Line_chart_icon.svg";
import pieChart from "../../../images/ChartIcons/Pie_chart_icon.svg";
import pyramidChart from "../../../images/ChartIcons/Pyramid_chart_icon.svg";
import wordCloud from "../../../images/ChartIcons/Word_cloud_icon.svg";

import { useDropzone } from "react-dropzone";
import LoadingIndicator from "../../../components/LoadingIndicator";
import BrandedCardTitle from "../../OrganizationDetails/components/BrandedCardTitle";

import dropIcon from "../../../images/Breefly_Icons-DragDrop_Crop.svg";
import Papa from "papaparse";
import { isEmpty } from "validate.js";
import questionList from '../../../datasets/pmaSentimentQuestions.json';
import segmentList from '../../../datasets/pmaSentimentSegments.json';

import csvTemplate from '../../../assets/csvChartTemplate.csv'
const defaultChartImage = require("../../../images/defaultChartImage.png");
const tinycolor = require("tinycolor2");

const TemplatesDialog = React.memo((props) => {
  const classes = props.classes;

  const {
    isOpen,
    handleClose,
    apiManager,
    allowStandardChart,
    insertOnly,
    user,
    startAtStep = 0,
    isEditing
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [selected, setSelected] = React.useState(-1);
  const [panelTabIndex, setPanelTabIndex] = React.useState(0);
  const [dataset, setDataset] = useState('')

  const affiliate = user && user.orgAffiliates && user.orgAffiliates.length > 0;
  const pma = Boolean(user && user.hostOrg);

  const nextSteps = pma ? [2,3] : [1,2,3];

  const dataSetCards = [
    { name: "Your Own", icon: faSlidersV, value: "own" },
    { name: "Floral", icon: faFlowerDaffodil, value: "floral" },
    { name: "Produce", icon: faCarrot, value: "produce" },
    { name: "Sentiment", icon: faHeart, value: "sentiment" },
  ];
  const chartCards = [
    { name: "Bar", icon: barChart, value: "bar" },
    { name: "Column", icon: columnChart, value: "column" },
    { name: "Pie", icon: pieChart, value: "pie" },
    { name: "Line", icon: lineChart, value: "line" },
    { name: "Donut", icon: donutChart, value: "donut" },
    { name: "Pyramid", icon: pyramidChart, value: "pyramid" },
    { name: "Gauge", icon: gaugeChart, value: "gauge" },
    { name: "Word Cloud", icon: wordCloud, value: "word" }
  ];
  const sentimentCards = [
    { name: "Bar", icon: barChart, value: "bar" },
    { name: "Donut", icon: donutChart, value: "donut" },
    { name: "Pie", icon: pieChart, value: "pie" },
    { name: "Pyramid", icon: pyramidChart, value: "pyramid" },
  ];
  const dataSourceCards = [
    { name: "Manually", icon: faPen },
    { name: "Upload", icon: faCloudUpload }
  ];
  const genderList = segmentList.segments.filter(el => el.segment === "Gender")
  const ageList = segmentList.segments.filter(el => el.segment === "Age Group")
  const channelList = segmentList.segments.filter(el => el.segment === "Channel")
  const productList = segmentList.segments.filter(el => el.segment === "Products")
  const surveyProductList = segmentList.segments.filter(el => el.segment === "Survey Products")

  const sentimentOptions = [
    { name: "Products", icon: faCarrot, value: "Products", subHead: 'Select Product(s)', size: 4, list: productList},
    { name: "Channel", icon: faSearchDollar, value: "Channel", subHead: 'Select Channel(s)', size: 4, list: channelList },
    { name: "Gender", icon: faHeart, value: "Gender", subHead: 'Select Gender(s)', size: 2, list: genderList },
    { name: "Age", icon: faCalendar, value: "Age", subHead: 'Select Age(s)', size: 2, list: ageList },
  ]

  useEffect(() => {
    if(pma || affiliate) {
      getPMIData();
    }
  }, [])

  //populate everything if editing a PMA Floral or Produce chart
  useEffect(() => {
    if(!isLoading && isEditing){
     
      const {chartSettings: {chartType, dataset, time, type, geo, products, metrics} } = props || '';
      setDataset(dataset)
      setChartType(chartType);
      const formatProducts = (prods) => {
        let obj = {}
        prods.map(el => {
          obj[el] = true
        })
        return obj
      }
      if(dataset === 'produce'){
        setProduceForm({products: formatProducts(products), times: time, geos: geo, metrics: metrics})
      }
      if(dataset === 'floral'){
        setFloralForm({products: formatProducts(products), times: time, geos: geo, metrics: metrics})
      }
    }
  }, [isEditing])

  const [produceList, setProduceList] = useState({products: [], times:[], geos: [], metrics: []})
  const [floralList, setFloralList] = useState({products: [], times:[], geos: [], metrics: []})

  const produceOptions = [
    { name: "Products", icon: faCarrot, value: "products", subHead: 'Select Product(s)', size: 4, list: produceList.products},
    { name: "Metric", icon: faSearchDollar, value: "metrics", subHead: 'Select a Metric', size: 4, list: produceList.metrics },
    { name: "Region", icon: faMapMarked, value: "geos", subHead: 'Select a Region', size: 2, list: produceList.geos },
    { name: "Period", icon: faCalendar, value: "times", subHead: 'Select a Period', size: 2, list: produceList.times },
  ]
  const floralOptions = [
    { name: "Products", icon: faCarrot, value: "products", subHead: 'Select Product(s)', size: 4, list: floralList.products},
    { name: "Metric", icon: faSearchDollar, value: "metrics", subHead: 'Select a Metric', size: 4, list: floralList.metrics },
    { name: "Region", icon: faMapMarked, value: "geos", subHead: 'Select a Region', size: 2, list: floralList.geos },
    { name: "Period", icon: faCalendar, value: "times", subHead: 'Select a Period', size: 2, list: floralList.times },
  ]
  const getPMIData = async () => {
    setIsLoading(true)
    const datasetInfoProduce = await apiManager.getPMIDatasetsInfo('produce');
    const datasetInfoFloral = await apiManager.getPMIDatasetsInfo('floral');
    console.log(datasetInfoProduce)
    const additionalProductsProduce = ['All Products', 'All Conventional Fruit', 'All Organic Fruit', 'All Conventional Vegetables', 'All Organic Vegetables']
    
    if(datasetInfoProduce && datasetInfoProduce.info && datasetInfoProduce.info.datasets && datasetInfoProduce.info.datasets[0]){
      let products = [...additionalProductsProduce, ...datasetInfoProduce.info.datasets[0].products, ...datasetInfoProduce.info.datasets[1].products, ];
      products = [...new Set(products)].sort((a, b) =>  a.toLowerCase().localeCompare(b.toLowerCase()))

      let times = [...datasetInfoProduce.info.datasets[0].times, ...datasetInfoProduce.info.datasets[1].times]
      times = [...new Set(times)].sort((a, b) =>  a.toLowerCase().localeCompare(b.toLowerCase()))

      let geos = [...datasetInfoProduce.info.datasets[0].geos, ...datasetInfoProduce.info.datasets[1].geos]
      geos = [...new Set(geos)].sort((a, b) =>  a.toLowerCase().localeCompare(b.toLowerCase()))


      let metrics = [...datasetInfoProduce.info.datasets[0].metrics, ...datasetInfoProduce.info.datasets[1].metrics]
      metrics = [...new Set(metrics)].sort((a, b) =>  a.toLowerCase().localeCompare(b.toLowerCase()))

     setProduceList({
       products, 
       times, 
       geos, 
       metrics
      })
    }
     setFloralList({
       products: ['All Products', ...datasetInfoFloral.info.datasets[0].products],
       times: [...datasetInfoFloral.info.datasets[0].times],
       geos: [...datasetInfoFloral.info.datasets[0].geos],
       metrics: [...datasetInfoFloral.info.datasets[0].metrics]
      })
      setIsLoading(false)
    }
  const defaultProduceForm = {
    products: {},
    times: '',
    geos: '',
    metrics: ''
  }
  const [produceForm, setProduceForm] = useState({...defaultProduceForm
  })

  const defaultFloralForm = {
    products: {},
    times: '',
    geos: '',
    metrics: ''
  }
  const [floralForm, setFloralForm] = useState({...defaultFloralForm
  })
 
  const defaultSentimentForm = {
    Question: '',
    Products: {},
    Channel: {},
    Gender: {},
    Age: {}
  }
  const [sentimentForm, setSentimentForm] = useState({...defaultSentimentForm
  })

  // manage steps.
  const [stepCount, setStepCount] = useState(4)
  useEffect(() => {
    if(dataset === 'own'){
      setStepCount(5)
    } else {
      setStepCount(4)
    }
  }, [dataset]);
  const [currentStep, setCurrentStep] = React.useState(startAtStep);
  const stepNames = pma
    ? ["Select a data set to begin", "Now select a chart type to design", "Next Step 3", "Select Chart Type"]
    : [
        "Now select a chart type to design",
        "",
        "How would you like to add data?",
        "Enter your data below"
      ];

  const chartTitle = isEditing ? "Edit Chart" : "Add Chart";

  //To Do: depreciate 
  // const getChartTemplates = async () => {
  //   const response = await apiManager.getPMIChartTemplates();
  //   setTemplates(
  //     response && response.templates && response.templates.length > 0
  //       ? response.templates
  //       : false
  //   );
  // };
  // useEffect(() => {
  //   setInit(true);
  //   getChartTemplates();
  // }, []);

  const advanceStep = amount => {
    if (chartType === "word" && currentStep === 3) {
      amount = -2;
    }
    setCurrentStep(currentStep + amount);
  };
  const getFinalSegments = () => {
    let newArray = [];
    let newValues;
    Object.keys(sentimentForm).filter(key => key !== 'Question').map(key => newArray.push(key));
    newValues = Object.keys(sentimentForm).filter(item => typeof sentimentForm[item] !== 'string').map(item => sentimentForm[item]).map(el => Object.values(el).filter(i => i !== ''))
    let finalSegments = newArray.map((el, index) => ({
      type: newArray[index],
      items: [...newValues[index]]
    }))
   
    return finalSegments
  }
//   [
//     {
//         "type" : "Product", 
//         "items" : [
//             {
//                 "Lettuce Buyers" : "1"
//             }
//         ]
//     }, 
//     {
//         "type" : "Gender", 
//         "items" : [
//             {
//                 "D1" : "1"
//             }
//         ]
//     }, 
//     {
//         "type" : "Age Group", 
//         "items" : [
//             {
//                 "MRK_Age1" : "4"
//             }
//         ]
//     }, 
//     {
//         "type" : "Channel", 
//         "items" : [
//             {
//                 "Q2" : "1"
//             }
//         ]
//     }
// ]

  const closeModal = async (action, e) => {
    let dataPoints;
    let customDataPoints
    let dataUnit = unit;
    if (dataSource === "Upload" && action !== "cancel") {
      const uploaded = await uploadThisCSV();
      if (uploaded){

      dataPoints = uploaded.data;
      dataUnit = uploaded.unit;
      }
    } else {
      dataPoints = dataInput;
    }
    
  let data;
    if(pma && dataset !== "own") {
    dataPoints = dataPoints.map((point, index) => ({
      y: parseInt(point.value),
      label: point.label,
      x: index
    }));

    data = {
      title: formState.title || '',
      subtitle: 'Source: IRI TSV + Perimeter model data. If you have questions, comments, or would like to discuss a request for additional information please reach out to IRi via the following email address: customerinteractioncenter@iriworldwide.com.', //`${data.geo} - ${data.time}`,
      type: chartType,
      dataset: dataset,
      chartType,
      chartColor,
      geo: null, //data.geo || null,
      time: '13 we 12-27-20', //data.time || null,
      dateRange: null,
      metrics: null, //data.metrics || null,
      products: null, //data.products || null,
      chartOptions: [
        "Clustered Bar", 
        "Stacked Bar"
      ], //data.chartOptions || null,
    }
    // if (data.aisleName) {
    //   asset.settings.aisleName = data.aisleName;
    //   asset.settings["Aisle Name"] = data.aisleName;
    // }

    if (data.dataset && data.dataset === "sentiment") {
      data.question = sentimentForm.Question;
      data.fullSurvey = false;
      const finalSegments = getFinalSegments()
      data.querySegments = finalSegments;
      data.compareTo = [];
      data.subSegment = "";
    }
    if (data.dataset && data.dataset === "produce") {
      const filteredProducts = Object
      data.geo = produceForm.geos;
      data.time = produceForm.times;
      data.metrics = produceForm.metrics;
      //remove false values that have been deselected
      data.products = Object.keys(produceForm.products).filter(product => produceForm.products[product] !== false);
    }
    if (data.dataset && data.dataset === "floral") {
      data.geo = floralForm.geos;
      data.time = floralForm.times;
      data.metrics = floralForm.metrics;
      //remove false values that have been deselected
      data.products = Object.keys(floralForm.products).filter(product => floralForm.products[product] !== false);
    }
    handleClose(action, data, pma);
    // reset state:
    setInit(false);
    setSelected(-1);
    setCurrentStep(startAtStep);
    setPanelTabIndex(0);
} else {
    if (!isEmpty(dataPoints)){
      //format the user inputs
      dataPoints = dataPoints.map((point, index) => ({
        y: point.value && parseFloat(point.value.replace(/,/g, '')),
        label: point.label,
        x: index
      }));
      dataPoints = dataPoints.filter(point => {
        return point.y !== "" && point.label !== ''
      })
      //To do: save uploaded file path and ID in component
    data = {
        content: {
          title: formState.title,
          dataSource: formState.dataSource,
          subtitle: formState.source,
          chartType,
          unit: dataUnit,
          chartColor
        },
        //chartColor,
        dataPoints,
        wordCloudList
      };
    }

      handleClose(action, data, false);
      // reset state:
      setInit(false);
      setSelected(-1);
      setCurrentStep(startAtStep);
      setPanelTabIndex(0);
    }
  };
  
  const clickedOnTemplate = index => {
    setSelected(index);
  };
  //Dialog Values
  const [isValid, setValid] = useState(false);
  const [chartType, setChartType] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [formState, setFormState] = useState({
    title: "",
    source: ""
  });
  const [chartColor, setChartColor] = useState("#22AEE8");
  const [wordCloudList, setWordCloudList] = useState("");
  const [fileToUpload, setFileToUpload] = useState("");
  const [unit, setUnit] = useState("number");
  const defaultData = { label: "", value: "" };
  const [dataInput, setDataInput] = useState([
    defaultData,
    defaultData,
    defaultData
  ]);
  const handleDataInput = (e, index) => {
    let items = [...dataInput];
    let newItem = {
      ...items[index],
      [e.target.name]: e.target.value
    };
    items[index] = newItem;
    setDataInput(items);
  };
  const handleFormChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };
  const handleColorChange = (color, event) => {
    setChartColor(color.hex);
  };
  // const panelTabNames = [
  //     "Produce", "Floral", "Sentiment", "Create Your Own"
  // ];
 
  useEffect(() => {
    currentStep === stepCount - 1 && validateForm();
  }, [
    chartType,
    dataSource,
    formState,
    chartColor,
    wordCloudList,
    fileToUpload,
    dataInput,
  ]);
  useEffect(() => {
    currentStep === 2 && dataset === 'sentiment' && validateSentimentForm();
    currentStep === 3 && dataset === 'produce' && validateProduceForm();
    currentStep === 3 && dataset === 'floral' && validateFloralForm();
    isEditing && validateEdit();
  }, [
    sentimentForm,
    produceForm,
    floralForm 
  ]);

  const validateForm = () => {
    let isValid;
    if (chartType === "word") {
      isValid =
        chartType &&
        //formState.title && formState.title.length >=3 &&
        wordCloudList &&
        wordCloudList.length > 5;
    } else if (dataSource === "Manually") {
      isValid =
        chartType &&
        dataSource &&
        //formState.title && formState.title.length > 3 &&
        //formState.source && formState.source.length > 3 &&
        dataInput &&
        dataInput[0].label &&
        dataInput[0].value;
      //chartColor && tinycolor(chartColor).isValid();
    } else {
      isValid =
        chartType &&
        dataSource &&
        //formState.title && formState.title.length > 3 &&
        //formState.source && formState.source.length > 3 &&
        fileToUpload &&
        !isEmpty(fileToUpload);
    }
    setValid(Boolean(isValid));
  };
  const validateSentimentForm = () => {
    let isValid;
    isValid = 
      dataset === 'sentiment' &&
      chartType
      sentimentForm && 
      sentimentForm.Question &&
      // !isEmpty(sentimentForm.Products) &&
      // !isEmpty(sentimentForm.Channel) &&
      // !isEmpty(sentimentForm.Gender) &&
      // !isEmpty(sentimentForm.Age)
    setValid(Boolean(isValid))
  }
  const validateProduceForm = () => {
    let isValid;
    isValid = 
      dataset === 'produce' &&
      chartType &&
      produceForm && 
      !isEmpty(produceForm.products) &&
      Object.values(produceForm.products).includes(true) &&
      produceForm.metrics &&
      produceForm.geos &&
      produceForm.times
    setValid(Boolean(isValid))
  }
  const validateFloralForm = () => {
    let isValid;
    isValid = 
      dataset === 'floral' &&
      chartType &&
      floralForm && 
      !isEmpty(floralForm.products) &&
      Object.values(floralForm.products).includes(true) &&
      floralForm.metrics &&
      floralForm.geos &&
      floralForm.times
    setValid(Boolean(isValid))
  }
  const validateEdit = () => {
    let isValid;
    if(dataset === 'floral'){
      isValid = floralForm && 
      !isEmpty(floralForm.products) &&
      Object.values(floralForm.products).includes(true) &&
      floralForm.metrics &&
      floralForm.geos &&
      floralForm.times
    } else {
      isValid = produceForm && 
      !isEmpty(produceForm.products) &&
      Object.values(produceForm.products).includes(true) &&
      produceForm.metrics &&
      produceForm.geos &&
      produceForm.times
    }
    setValid(Boolean(isValid))
  }
  const renderStep0 = () => {
    let cards = dataSetCards;
    if (props.isViz){
      cards = cards.filter(card => card.value !== 'own')
    }
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          className={"viz templateContainer templateContainer--tight"}
        >
          <Grid
                item
                xs={12} className="viz-cardTitle">{stepNames[currentStep]}</Grid>
          <>
            {cards.map(card => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={`templateCard-${card.name}`}
              >
                <Button onClick={() => {
                    setDataset(card.value);
                    // figure out if going to step 1 or step 2
                    advanceStep(1);
                    }
                }>
                  <div
                    className={
                      dataset === card.value
                        ? "templateCard--selected"
                        : "templateCard"
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                          icon={card.icon}
                          style={{ fontSize: 41, color:'var(--brand-color1)'}}
                        />
                    </div>
                    <div className="templateName" style={{marginTop: 10}}>{card.name}</div>
                  </div>
                </Button>
              </Grid>
            ))}
          </>
        </Grid>
      </DialogContent>
    );
  };

  const renderStep1 = () => {
    let cards = chartCards;
    if(pma && dataset !== 'own'){
      cards = cards.filter(card =>card.value !== 'word')
    }
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className="viz templateContainer viz--tall"
        >
          <div className="viz-cardTitle">{stepNames[currentStep]}</div>
          <>
            {cards.map(card => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={`templateCard-${card.name}`}
              >
                <Button onClick={() => {
                  setChartType(card.value);
                  advanceStep(1) 
                  }}>
                  <div
                    //Need to rework this styling
                    className={
                      chartType === card.value
                        ? "templateCard--selected"
                        : "templateCard"
                    }
                  >
                    <div>
                      <img src={card.icon} />
                    </div>
                    <div className="templateName" style={{marginTop: 10}}>{card.name}</div>
                  </div>
                </Button>
              </Grid>
            ))}
          </>
        </Grid>
      </DialogContent>
    );
  };
  const renderStepSentimentType = () => {
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className="viz templateContainer templateContainer--tight"
        >
          <div className="viz-cardTitle">{stepNames[currentStep]}</div>
          <>
            {sentimentCards.map(card => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={`templateCard-${card.name}`}
              >
                <Button onClick={() => {
                    setChartType(card.value);
                    advanceStep(1);
                    }}>
                  <div
                    //Need to rework this styling
                    className={
                      chartType === card.value
                        ? "templateCard--selected"
                        : "templateCard"
                    }
                  >
                    <div>
                      <img src={card.icon} />
                    </div>
                    <div className="templateName" style={{marginTop: 10}}>{card.name}</div>
                  </div>
                </Button>
              </Grid>
            ))}
          </>
        </Grid>
      </DialogContent>
    );
  };
  
  const handleSentimentChange = (e, value, item) => {
   if (e.currentTarget.type === 'checkbox'){
    setSentimentForm({
      ...sentimentForm,
      [value]: {
        ...sentimentForm[value],
        [e.currentTarget.name]: e.currentTarget.checked ? {[item.columnName]: item.value} : ''
      }
    })
   } else {
    setSentimentForm({
      ...sentimentForm,
        [e.target.name]: e.target.value
    })
   }
  }
  

  const renderSentimentOptions = () => {

    // be sure correct products in the list
    sentimentOptions[0].list = surveyProductList;
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className="viz templateContainer viz--tall"
          style={{paddingTop: 20}}
        >
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', flexFlow: 'row nowrap'}}>
              <FormControl style={{minWidth: '50%'}}>
                <InputLabel
                 id="template-survey-question-select"
                 >
                Select the survey question here to create a chart
                </InputLabel>
                <Select
                  labelId="template-survey-question-select"
                  value={sentimentForm.Question}
                  fullWidth
                  autoWidth
                  name="Question"
                  onChange={e => handleSentimentChange(e, 'Question')}
                  //displayEmpty
                >
                {questionList["Question List"].map(question => 
                  !question.skip && <MenuItem value={question["Question ID"]}>{question.short}</MenuItem>
                  )}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {sentimentOptions.map(option => 
                
                <Grid item xs={12} sm={option.size}>
                  <div>
                      <FontAwesomeIcon
                            icon={option.icon}
                            style={{ fontSize: 14, marginRight: 12}}
                          />
                          {option.name}
                    </div>
                    <div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{option.subHead}</FormLabel>
                      <FormGroup>
                      {option && option.list && option.list[0].items.map(item => 
                            <FormControlLabel
                          control={<Checkbox onChange={(e) => handleSentimentChange(e, option.name, item)} name={item.name} checked={sentimentForm[option.value][item.name]}
                          color="primary"
                          style={{
                              transform: 'scale(.7)'
                            }}
                            />}
                          label={<span style={{fontSize: 12}}>{item.name}</span>}
                        />
                        )}
                       
                      </FormGroup>
                    </FormControl>
                    </div>
                    
                </Grid>
                )}
              </Grid>
            </Grid>
        </Grid>
      </DialogContent>
    );
  };

  const handleProduceChange = (e, value, a, b) => {
    if(e.currentTarget.type === 'checkbox'){
      setProduceForm({
        ...produceForm,
        [value]: {
          ...produceForm[value],
          [e.currentTarget.name]: e.target.checked
        }
      })
    }
    if(e.currentTarget.type === 'radio'){
      setProduceForm({
        ...produceForm,
        [value]: e.currentTarget.value
      })
    }

  }
  const handleFloralChange = (e, value, item) => {
    if(e.currentTarget.type === 'checkbox'){
      setFloralForm({
        ...floralForm,
        [value]: {
          ...floralForm[value],
          [e.currentTarget.name]: e.target.checked
        }
      })
    }
    if(e.currentTarget.type === 'radio'){
      setFloralForm({
        ...floralForm,
        [value]: e.currentTarget.value
      })
    }
  }
  const renderProduceOptions = (dataset) => {
   
    let options;
    let form;
    if(dataset === 'produce'){
      options = produceOptions
      form = produceForm
    } 
    else{
      options = floralOptions
      form = floralForm

    }
    console.log('options', options)
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className="viz templateContainer viz--tall"
          style={{paddingTop: 20}}
        >
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', flexFlow: 'row nowrap'}}>
                <h3 style={{minWidth: '50%', textAlign: 'center'}}>Select what data you want to use</h3>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {options.map(option => 
                
                <Grid item xs={12} sm={option.size} key={option.name}>
                  <div>
                      <FontAwesomeIcon
                            icon={option.icon}
                            style={{ fontSize: 14, marginRight: 12}}
                          />
                          {option.name}
                    </div>
                    <div>
                    {option.name !== "Products" && 
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{option.subHead}</FormLabel>
                        <RadioGroup 
                          aria-label="metric" 
                          name={option.name} 
                          value={form[option.value]}  
                          onChange={(e) => dataset === 'produce' ? 
                            handleProduceChange(e, option.value) 
                            : handleFloralChange(e, option.value)}
                        >
                        {option && option.list && option.list[0] && option.list.map(item => 
                          <FormControlLabel
                            value={item}
                            key={item}
                            control={
                              <Radio 
                                color="primary" 
                                style={{
                                transform: 'scale(.7)',
                              }}/>
                              }
                            label={<span style={{fontSize: 12}}>{item}</span>}
                          />
                          )}
                        </RadioGroup>
                      </FormControl>
                    }
                    {option.name === "Products" && 
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{option.subHead}</FormLabel>
                        <FormGroup>
                        {option && option.list && option.list[0] && option.list.map((item, index) => 
                              <FormControlLabel
                              key={`${item}+${index}`}
                                control={
                                  <Checkbox 
                                    onChange={(e) => dataset === 'produce' ? handleProduceChange(e, option.value, item, form[option.value][item]) : handleFloralChange(e, option.value, item)} 
                                    name={item} 
                                    checked={!!form[option.value][item] || false}
                                    //checked={false}
                                    color="primary"
                                    style={{
                                        transform: 'scale(.7)'
                                      }}
                                  />}
                                label={<span style={{fontSize: 12}}>{item}</span>}
                              />
                          )}
                        
                        </FormGroup>
                      </FormControl>
                    }
                    </div>
                    
                </Grid>
                )}
              </Grid>
            </Grid>
        </Grid>
      </DialogContent>
    );
  };

  const renderStep2 = () => {
    return (
      <DialogContent>
        <div className="viz-chartDialogContainer">
          <form>
            <div className="viz--formBottomMargin">
              <label htmlFor="chart-title" className="viz-dialogLabel">
                Add a chart title
              </label>
              <TextField
                required
                id="chart-title"
                value={formState.title}
                onChange={handleFormChange}
                fullWidth
                placeholder="Enter your title here"
                name="title"
              />
            </div>
            <div className="viz--formBottomMargin">
              <label htmlFor="chart-source" className="viz-dialogLabel">
                Add a data source
              </label>
              <TextField
                id="chart-source"
                value={pma && dataset !== 'own' ? "IRI Integrated Fresh" : formState.source}
                onChange={handleFormChange}
                disabled={pma && dataset !== 'own'}
                fullWidth
                placeholder="Enter your data source here"
                name="source"
              />
            </div>
            <div className="viz-chartColorPicker">
              <label htmlFor="chart-color-picker" className="viz-dialogLabel">
                Select a color scheme
              </label>
              <TwitterPicker
                triangle="hide"
                id="chart-color-picker"
                color={chartColor}
                width="225px"
                colors={["#22AEE8", "#49BA35", "#FFA22E", "#F52727", "#900DCB"]}
                onChangeComplete={handleColorChange}
              />
            </div>
          </form>
        </div>
      </DialogContent>
    );
  };
  const renderStep3 = () => {
    if (chartType === "word") {
      setCurrentStep(3);
    }
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className="viz templateContainer"
        >
          <div className="viz-cardTitle">{stepNames[currentStep]}</div>
          <>
            {dataSourceCards.map(card => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={`templateCard-${card.name}`}
              >
                <Button onClick={() => {
                  setDataSource(card.name)
                  advanceStep(1)
                  }}>
                  <div
                    //Need to rework this styling
                    className={
                      dataSource === card.name
                        ? "templateCard--selected templateCard--source"
                        : "templateCard--source"
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={card.icon}
                        style={{ fontSize: 41 }}
                      />
                    </div>
                    <div className="templateName" style={{marginTop: 10}}>{card.name}</div>
                  </div>
                </Button>
              </Grid>
            ))}
          </>
        </Grid>
      </DialogContent>
    );
  };
  const renderStep4 = () => {
    if (chartType === "word") {
      return renderWordInput();
    } else if (dataSource === "Upload") {
      return <RenderUploader />;
    } else {
      return renderDataInput();
    }
  };
  //WORD CLOUD
  const renderWordInput = () => {
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="left"
          spacing={3}
          className="viz templateContainer"
        >
          <div className="viz-cardTitle viz-cardTitle--wordCloud">
            Enter your text below
          </div>
          <form style={{width: '100%'}}>
            <span>
              {/* Separate each phrase with a comma for proper word cloud charting */}
            </span>
            <TextField
              label={"Enter text here"}
              value={wordCloudList}
              onChange={e => setWordCloudList(e.target.value)}
              fullWidth
              multiline
              variant="outlined"
              className="viz-wordCloud-input"
              rows={12}
              style={{ marginTop: 24 }}
              className="viz-wordCloud-input"
            />
          </form>
        </Grid>
      </DialogContent>
    );
  };

  //CSV Parser
  const parseCSV = file => {
    return new Promise(resolve => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results, file) => {
          resolve(results);
        }
      });
    });
  };
  // CSV UPLOADER
  const uploadThisCSV = async () => {
    //Parse the CSV on FE then upload the file if valid.

    //setProcessing('uploading');
    let csvFile = fileToUpload[0];
   
    let parsed = await parseCSV(csvFile);

    const validHeaders = Boolean(parsed.meta && parsed.meta.fields && parsed.meta.fields[0] === 'label' && parsed.meta.fields[1] === "value" && parsed.meta.fields.length === 2);
    let s3Info = {};
    if (csvFile && csvFile.type && isEmpty(parsed.error) && validHeaders) {
      //const endDate = moment(selectedDate).format('MM-YYYY');
      csvFile.dataType = "user";
      csvFile.dataSubType = "upload";
      //csvFile.dateRange= `${formData.period} ending ${endDate}`;
      s3Info = await apiManager.getUploadKey(csvFile);

      await apiManager.uploadToS3(s3Info.url, csvFile);

      parsed.unit = 'number';
      parsed.data.forEach(point => {
         
          if(isNaN(point.value)) {
              // then need to convert it.
              let u = point.value.replace(/[01234567890., ]/g, '');
              parsed.unit = u === '$' ? "dollar" : (u === '%' ? "percent" : u);
              point.value = point.value.replace(/[^0-9.]/g, "");
          }
      })
      return parsed;
    }
    if (parsed.error && parsed.error[0]) {
      alert(parsed.error[0].message);
      return ''
    }
    if(!validHeaders){
      alert("Oops, looks like something is off! \n \n Templates are finicky, so be sure the only cells you're editing are the ones containing your data. If you have any issues uploading, visit the help center via the support tab below for tips on how to upload your data.")
      return ''
    }
  };
  const [rejectedFiles, setRejectedFiles] = useState([])
  const RenderUploader = () => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections,
      open
    } = useDropzone({
      noClick: true,
      maxFiles: 1,
      accept:
        ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
      onDrop: acceptedFiles => {
        setFileToUpload(acceptedFiles);
      }
    });
    if (!fileToUpload && !isEmpty(fileRejections)){
      setRejectedFiles(fileRejections)
    }
    const fileRejectionItems = rejectedFiles.map(({ file, errors }) => (
      <div key={file.path} style={{color: 'red'}}>
        <div>Error: File must be a .csv</div>
        <div>{file.path} - {file.size} bytes </div>
            
      </div>
    ));
    return (
      <CardContent className="file-upload-card-content" >
      <div {...getRootProps({})}>
        <input {...getInputProps()} />
        <div className="viz-cardTitle viz-cardTitle--wordCloud">
          Upload your data below
        </div>
        <div style={{ marginBottom: 20 }}>
          <span style={{ textAlign: "center" }}>
            Use this <a href={csvTemplate} download="breefly_data_template.csv">template</a> to format your data correctly.
            <br />
            Be sure to follow the instructions in the header row.
          </span>
        </div>
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          {...getRootProps({})}
          className="file-upload-holder"
        >
          <Grid item xs={12} style={{ paddingBottom: 12 }}>
            <img src={dropIcon} width={56} height={56} />
          </Grid>
          <Grid item xs={12}>
            <span>Then drag & drop your CSV file here to upload</span>
            <br />
            {/* <span style={{ color: "#6F6F6F", fontSize: 10 }}>CSV files only</span> */}
          </Grid>
          <Grid item xs={12} style={{ margin: "16px 0px" }}>
            <Button
              color="primary"
              variant="contained"
              className={"brandColor2"}
              onClick={open}
            >
              <FontAwesomeIcon
                icon={faCloudUpload}
                style={{ marginRight: 8 }}
              />
              Or locate your file here
            </Button>
            
            {fileToUpload.length > 0 ? (
              <div style={{ marginTop: 24, height: 40, background: '#f4f5ff', padding: 12 }}>
                <span>Current file: {fileToUpload[0].name}</span>
              </div>
            ) : (
              <div style={{ marginTop: 24 }}>
              <ul>{fileRejectionItems}</ul>
            </div>
            )}
          </Grid>
        </Grid>
        </div>
      </CardContent>
    );
  };
  const handleTabNewRow = e => {
    if(e.key === "Tab" && e.target.id == dataInput.length){
      setDataInput([...dataInput, defaultData])
    }
  }
  const handleRemoveRow = (data, index) => {
    let dataRemoveRow = [...data]
    if(dataRemoveRow.length>1){
      dataRemoveRow.splice(index, 1);
    } 
    setDataInput(dataRemoveRow)
  }
  const renderDataInput = () => {
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
          className="viz templateContainer"
        >
          <div className="viz-cardTitle viz-cardTitle--data">
            Enter your data below
          </div>
          <form>
            <div style={{ paddingTop: 24 }}>
              <span style={{ marginRight: 110 }}>
                Enter your labels below i.e. "Product A"
              </span>
              <FormControl style={{marginBottom: 20}}>
                <InputLabel>Unit</InputLabel>
                <Select
                  autoWidth
                  value={unit}
                  onChange={e => setUnit(e.target.value)}
                >
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="percent">% Percent</MenuItem>
                  <MenuItem value="dollar">$ Dollars</MenuItem>
                  <MenuItem value="euro">€ Euros</MenuItem>
                  <MenuItem value="pound">£ Pounds</MenuItem>
                </Select>
              </FormControl>
            </div>
            {dataInput.map((dataRow, index) => (
              <>
                <TextField
                  key={`Label ${index + 1}`}
                  label={`Label ${index + 1}`}
                  value={dataRow.label}
                  onChange={e => handleDataInput(e, index)}
                  style={{ width: "63%", marginRight: 25, marginBottom: 20 }}
                  name="label"
                />
                <TextField
                  key={`Value ${index + 1}`}
                  label={`Value ${index + 1}`}
                  value={dataRow.value}
                  id={index + 1}
                  onChange={e => handleDataInput(e, index)}
                  style={{ width: "23%", textAlign: "center", marginBottom: 20 }}
                  name="value"
                  onKeyDown={handleTabNewRow}
                />
                <Button
                  onClick={(e) => handleRemoveRow(dataInput,index)}
                  style = {{minWidth: '25px', maxWidth: '25px', marginTop: '25px'}}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                  />
                </Button>
              </>
            ))}
          </form>
          <div className="addButtonHolder">
          <Button
            onClick={() => setDataInput([...dataInput, defaultData])}
            style={{ padding: '6px 10px 6px 0px' }}

          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              size={"2x"}
              style={{ paddingRight: 10 }}
            />
            Add another row
          </Button>
          </div>
        </Grid>
      </DialogContent>
    );
  };
  const getWidth = () => {
    switch(true){
      case(dataset === 'sentiment' && currentStep === 2) : return 'md';
      case((dataset === 'produce' || dataset === 'floral') && currentStep === 3) : return 'md';
      default: return 'sm'
    }
  }
  const renderStep = (isPMA, step, dSet) => {
      switch(true) {
        case(isPMA && step === 0) : return renderStep0();
        //PMA NOT SENTIMENT
        case(isPMA && step === 1 && (dSet === 'floral' || dSet === 'produce' || dSet === 'own')) : return renderStep1();
        case(isPMA && step === 2 && (dSet === 'floral' || dSet === 'produce' || dSet === 'own')) : return renderStep2();
        //PMA OWN DATA
        case(isPMA && step === 3 && dSet === 'own') : return renderStep3();
        case(isPMA && step === 4 && dSet === 'own') : return renderStep4();
        //PMA Sentiment
        case(isPMA && step === 1 && dSet === 'sentiment') : return renderStepSentimentType();
        case(isPMA && step === 2 && dSet === 'sentiment') : return renderSentimentOptions();
        //PMA FLORAL AND PRODUCE
        case(isPMA && step === 3 && (dSet === 'floral' || dSet === 'produce')) : return renderProduceOptions(dSet);
        //not pma
        case(!isPMA && step === 0) : return renderStep1();
        case(!isPMA && step === 1) : return renderStep2();
        case(!isPMA && step === 2) : return renderStep3();
        case(!isPMA && step === 3) : return renderStep4();
      }
  }
  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason) => {if (reason !== 'backdropClick') { closeModal("cancel")}}}
      aria-labelledby="custom-chart-templates"
      fullWidth={true}
      maxWidth={getWidth()}
      className={"branded templatesDialog"}
    >
      <BrandedCardTitle
        title={chartTitle}
        pagination={!isEditing && `Step ${currentStep + 1} of ${stepCount}`}
      />
      {/* <DialogTitle id="form-dialog-title">Create A Chart</DialogTitle> */}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {renderStep(pma, currentStep, dataset)}
          <DialogActions className="viz templateActions">
            <Button onClick={() => closeModal("cancel")} color="primary">
              <span style={{ fontWeight: 300 }}>Cancel</span>
            </Button>
            {!(currentStep === 0 || isEditing) && (
              <Button
                onClick={() => advanceStep(-1)}
                color="primary"
                disabled={currentStep === 0}
              >
                <span>Back</span>
              </Button>
            )}
            {/* {insertOnly ? ( */}
              <>
                {(currentStep === stepCount - 1) || (currentStep === 2 && dataset === 'sentiment') ? (
                  <Button
                    onClick={isEditing ? () => closeModal("editAsset") : () => closeModal("addAsset")}
                    color="primary"
                    disabled={!isValid}
                  >
                    <span style={{ color: isValid ? "" : "#ccc" }}>
                      {isEditing ? "Update Chart" : "Create Chart"}
                    </span>
                  </Button>
                ) : (
                <>
                {(nextSteps.includes(currentStep)) ? <Button onClick={() => advanceStep(1)} color="primary">
                  <span>Next </span>
                </Button> : ''}
                </>
                )}
              </>
            {/* ) : (
              <>
                <Button
                  onClick={() => closeModal("createBrief")}
                  color="primary"
                  disabled={selected < 0}
                >
                  <span style={{ color: `${selected < 0 ? "#ccc" : ""}` }}>
                    Create in New Brief
                  </span>
                </Button>
                {/* <Button onClick={() => closeModal('addAsset')} color="primary" disabled={selected < 0}>
                                    <span style={{color: `${selected < 0 ? '#ccc' : ''}`}}>Add to Dashboard</span>
                                </Button> 
              </>
            )} */}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
})
export default TemplatesDialog;
