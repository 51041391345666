import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    IconButton,
    Snackbar 
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import RegisterSnackBar from '../SignIn/components/RegisterSnackBar';
import "./style.css";
import uuidv4 from 'uuid/v4';

// import AddPhoto from '../Modals/AddPhoto';  NEW DROPZONE UPLOADERS
// import AddVideo from '../Modals/AddVideo' NEW DROPZONE UPLOADERS
import Alert from '@material-ui/lab/Alert';

import AssetCard from './components/AssetCard';
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate'

import { faCamera, faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PaginationRounded from '../../components/PaginationRounded';
import CreateBriefButton from '../../components/CreateBriefButton';
import SubscriptionModal from '../../components/SubscriptionModal';
import eventTracker from "../../utils/event-tracker";

const DEFAULT_SNACK = {
    isOpen: false,
    message: '',
    isSuccessfull: false
  }

const styles = (theme => ({
    root: {
        [theme.breakpoints]: {
            values: { sm: 750 },
        },
    },
    searchbar: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        height: 100,
        paddingTop: 8,
        '@media (max-width: 650px)': {
            height: 164,
        },
    },
    searchform: {
        width: '50%',
        '@media (max-width: 860px)': {
            width: '33%',
        },
        '@media (max-width: 500px)': {
            width: '40%',
            minWidth: 150,
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            marginTop: 24,
        },
    }
}));

const Assets = (props) => {
    const { apiManager, classes, isMobile, refreshChild, history, user } = props;
    const data = props;

    const spacing = 2;
    const [search, setSearch] = React.useState('');
    const [assets, setAssets] = React.useState(false);
    const [init, setInit] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(1);
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(48);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showSnackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');

    const [helperText, setHelperText] = React.useState('');
    const [isPhotoFilter, setFilter] = React.useState(true);

    const [snack, setSnack] = useState(DEFAULT_SNACK)

    // const updateIntercom = (user) => { 
    //     window.intercomSettings = {
    //       app_id: "zcl62xdv",
    //       name: `${user.firstname} ${user.lastname}`, 
    //       email: `${user.email}`,
    //       created_at: `${user.created}` 
    //     }
    //     window.Intercom('boot', window.intercomSettings); 
    //   }

    //   updateIntercom(user);

    const closeModal = async () => {
    
        snack.isOpen = false;
        setSnack(snack);
        refreshChild();

        setTimeout(async function() {
            await getData(true);
        }, 1500)
      }
    
      const openModal = async (isSuccessfull, message) => {
        snack.isOpen = true;
        snack.isSuccessfull = isSuccessfull;
        snack.message = message;
        setSnack(snack);
        refreshChild();

       
      }

    const getData = async (override, newLimit, newPage, newSearch) => {
        setIsLoading(true)
        const sendLimit = 500;
        // const sendPage = newPage != null ? newPage : page;
        const sendPage = 0;
        const userAssets = await apiManager.getAssets(override != null ? override : isPhotoFilter, { skip: (sendLimit * sendPage), limit }, newSearch ? newSearch : search);
        if(userAssets){
            
            const { assets, count } = userAssets;
            setAssets(assets);
            setPageCount(count);
            
        }
        setIsLoading(false)
        
    }

    if (!init && !assets) {
        getData();
        setInit(true)

    }

    const [createError, setCreateError] = React.useState('')
    const createBrief = async () => {
        setIsLoading(true)

        const brief = {
            title: 'Enter your title...',
            tags: [],
            briefcase: { value: 0 },
            components: [
                {
                    "type": (0),
                    "content": {
                        "text": "Enter your title..."
                    },
                    "position": (0)
                }
            ]
        }
        const result = await apiManager.createBrief(brief);
        if (result && result.insertedCount > 0) {
            //history.push(`/brief/edit/${result._id}`);
            eventTracker('Brief Creation', user, 'assets')
            window.location = `/brief/edit/${result._id}`;
        }
        else {
            if(result && result.msg){
                setCreateError(result.msg)
            } else {
                //   alert(result && result.msg ? result.msg : 'An error has occurred')
            setSnackMessage(result && result.msg ? result.msg : 'An error has occurred');
            setSnackBar(true);
            }
        }
        setIsLoading(false)
    }
    // ********************** PAGINATION **************************** //
    const updateLimit = (newLimit) => {
        setLimit(newLimit);
        getData();
    }

    const updatePage = () => {

    }

    // ********************** SEARCH FUNCTIONS ********************** //
    const handleChangeSearch = e => {
        const newString = e.target.value;
        const key = e.key;
        setSearch(newString);
        let ht = '';
        if (newString.length > 0) {
            ht = `Search name and tags or filter with 'name:', 'photo', 'video',  or 'tags:'`;
            if (newString.toLowerCase().startsWith('name:')) {
                ht = 'Search name';
            } else if (newString.toLowerCase().startsWith('tags:')) {
                ht = 'Search tags separated by commas (e.g. tag1, tag2, etc.)';
            } else if (newString.toLowerCase().startsWith('photo:')) {
                ht = 'Search for photos';
            } else if (newString.toLowerCase().startsWith('video:')) {
                ht = 'Search for videos';
            }
        }
        setHelperText(ht);
    }

    const handleSearchKeyPress = async (e) => {
        const key = e.key;
        if (key === 'Enter' /* && search !== '' */) {
            // do search here...
            await getData(isPhotoFilter, page, limit, search);
        }
    }

    const clickEdit = async () => {
        openModal(true, 'Image successfully uploaded.')
        setFilter(true);
        await getData(true)
    }

    const updateSearch = async (isPhoto) => {
        setFilter(isPhoto);
        setAssets([]);
        await getData(isPhoto);
    }


    const videoSaved = async ({ url, assetId }) => {
        openModal(true, 'Your video is uploading. Continue to edit your brief and it’ll display as soon as it’s ready.')
        setFilter(false);
        setTimeout(async function() {
            await getData(false);
        }, 1500)
        
    }
 

    const renderAssetGrid = () => {
        if (!assets) {
           return null;
        } 
        if (assets && assets.length === 0) {
            return (
                <span style={{paddingTop: 16, paddingLeft: 16}}>Sorry, no results found. Refine your search and try again, or try adding an image or video to your next brief.</span>)
            }
    
        const html = (assets.map(function (asset) {
            return <Grid
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={uuidv4()}
            >
                <AssetCard 
                    asset={asset}
                    isPhotoFilter={isPhotoFilter}
                    refresh={getData}
                    apiManager={apiManager}
                    refreshData={refreshChild}
                    getData={getData}
                     />
            </Grid>
        }))
        return html;
    }

    const hasPhotos = assets.length;
    let plural;
    if(hasPhotos > 1) {
        plural = "Photos"
    } else{
        plural = "Photo"
    }

    return (
        <div className={classes.root}>
            { /* searchbar */}
            <div className={classes.searchbar}>
                <TextField size='small'
                    className={classes.searchform}
                    fullWidth
                    label="Search"
                    name="search"
                    value={search}
                    onChange={handleChangeSearch}
                    onKeyPress={handleSearchKeyPress}
                    helperText={helperText}
                    type=""
                    variant="outlined"
                />
                <div className={classes.primaryCTA}>
                    <CreateBriefButton
                        handleClick={createBrief}

                    />
                </div>
            </div>

            { /* main content */}
            <Grid
                container
                spacing={4}
            >

                { /* page controls */}
                <Grid
                    item
                    sm={5} xs={12}
                    style={{textAlign: 'left', paddingLeft: 16, paddingRight: 0, }}
                >
                    <Button aria-label="grid"
                        onClick={e => updateSearch(true)}
                    >
                        <div style={{ fontSize: 14, marginRight: 8, color: isPhotoFilter ? '#333' : '#bbb' }}>
                            <FontAwesomeIcon icon={faCamera} style={{marginRight: 8}}/>
                            Show photos
                            </div>
                        </Button>
                    <Button aria-label="grid"
                        onClick={e => updateSearch(false)}
                    >
                        <div style={{ fontSize: 14, marginRight: 8, color: !isPhotoFilter ? '#333' : '#bbb' }}>
                            <FontAwesomeIcon icon={faPlay} style={{marginRight: 8}}/>
                            Show videos
                            </div>
                    </Button>
                </Grid>
                {/* ADDPHOTO AND ADDVIDEO DISABLED 
                IF ADDED BACK, USE ADD PHOTO COMP FROM MODALS */}
                {/* <Grid
                    item
                    xs={2}
                    style={{textAlign: 'center', paddingLeft: 0, paddingRight: 0}}
                >
                    <AddPhoto className={classes.icon} handleSave={clickEdit} classes={classes}></AddPhoto>
                    <AddVideo className={classes.icon} handleSave={videoSaved} classes={classes}></AddVideo>

                </Grid> */}
                <Grid 
                item sm={7} xs={12}
                style={{textAlign: 'center', paddingLeft: 0, paddingRight: 0, margin: 'auto'}}
                >
                    {assets && assets.length > 0 && (
                    <div style={{ textAlign: 'right', marginRight: 16 }}>

                        {isPhotoFilter ? `${assets.length} ${plural}` : `${assets.length} video(s)`} 
                        {/* <PaginationRounded totalItems={isPhotoFilter ? assets.length : assets.length} count={pageCount} updateLimit={updateLimit} limit={limit} page={page} updatePage={updatePage} /> */}
                    </div>
                    )}
                </Grid>

                {/* ASSETS */}
            </Grid>
            <Grid
                    container
                    spacing={2}
                    style={{ display: !isLoading ? 'none' : 'block' }}
                    >
                <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            key={uuidv4()}
                        
                        ><LinearIndeterminate /></Grid>
                </Grid>
            <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
            >
                <Grid container spacing={2} > 
                    {renderAssetGrid()}
                </Grid>
            </Grid>
            <RegisterSnackBar  snack={snack} closeModal={closeModal} classes={classes} />
            <Snackbar
                open={showSnackBar}
                autoHideDuration={3000}
                className="error"
                severity="info"
                onClose={()=> setSnackBar(false)}
                variant="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error">{snackMessage}</Alert>
            </Snackbar>
            {createError && 
                <SubscriptionModal 
                    open={Boolean(createError)}
                    message={createError}
                    onClose={() => setCreateError('')}
                    user={user}
                    modalTitle={"briefs"}
                />
            }
        </div>
    );
};

Assets.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
};

export default withStyles(styles)(Assets);
