import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'; 
import {
  Grid,
  Button, 
} from '@material-ui/core'; 

import Notification from '../Modals/Notification'; 
 
import InviteExistingUser from './components/InviteExistingUser'
import PersonCard from '../People/components/PersonCard'
import MySnackbarContentWrapper from '../../components/SnackBar/SnackBar'
import SubscriptionModal from '../../components/SubscriptionModal';

const styles = (theme => ({
    root: {
      padding: theme.spacing(4)
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const BriefManage = (props) => {
  const { history, apiManager, isMobile, classes, user } = props;
 

  const [isModalOpen, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  
  const [invites, setInvites] = React.useState(false);

  const [isSnackOpen, setSnackOpen] = React.useState(false);
  const [variant, setVariant] = React.useState('error');



  const [init, setInit] = React.useState(false);

  const urls = history.location.pathname.split('/');
  const id = urls[urls.length - 1];

  const closeModal = () => {
    setOpen(false)
  }

  const closeSnack = () => {
    setSnackOpen(false);
  }

  const getInvites = async() => {
    
      const invites = await apiManager.getBriefPeople(id, { skip: 0, limit: 25 });
    
    if(invites){ 
      setInvites(invites)
    }
        
  }
 
  const cancelClick = async () => {
    history.push(`/brief/details/${id}`)
  }

  const handleDelete = async (person) => {
    const result = await apiManager.deleteInvite(person);
    if(result){ 
      setSnackOpen(true);
      setMessage('Users have been removed')
      setVariant('success')
      await getInvites();
    }
    else {
      setSnackOpen(true);
      setMessage('There was an error in the request.')
      setVariant('error')
    }
  }
  const [inviteError, setInviteError] = useState('')
  const handleSave = async (invites) => {
    
    invites.id = id;
     
    const result = await apiManager.inviteUser(invites, id);
    if (result.msg){
      setInviteError(result.msg)
    } else {
      if(result.nonMemberEmails.length === 0){  
        setSnackOpen(true);
        setMessage('Users have been invited')
        setVariant('success')
        await getInvites();
      }else if (result.nonMemberEmails.length !== 0 && result.memberEmails.length !== 0){ 
        setSnackOpen(true);
        setMessage(`Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `)
        setVariant('error')
      } else {
        setSnackOpen(true);
        setMessage(`User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')}`)
        setVariant('error')
      }
    }
  }
 
  
  
  const renderUsers = () => {
    if(!invites){
      return null;
    }

    const html = invites.map(function(value){
       
        return ( <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            
          >
             <PersonCard person={value} apiManager={apiManager} hideDetails={true} handleDelete={handleDelete} /> 
        
               </Grid>)
    })
    return html;
   
  }

  if(!init && !invites){
   
      
      setInit(true);
    getInvites();
  }

  return ( 
    <div className={classes.root}>
    <Grid
      container
      spacing={1}
    >
        
        <Grid
          item
          lg={2}
          sm={6}
          xl={2}
          xs={12}
        > 
                  <Button  
                    size="large"
                    type="submit"
                    variant="contained" 
                    onClick={cancelClick}
                  >
                    Back to brief
                  </Button> 

                
        </Grid> 
        <Grid
          item
          lg={2}
          sm={12}
          xl={2}
          xs={12}
        > 
              <InviteExistingUser classes={classes} apiManager={apiManager} getInvites={getInvites}  isMobile={isMobile} history={history} handleSave={handleSave}  user={user}/>
            </Grid>
  

   
        { renderUsers() }
 

      </Grid>
      <Notification isOpen={isModalOpen} message={message} closeModal={closeModal} />
      <MySnackbarContentWrapper onClose={closeSnack} variant={variant} message={message} isSnackOpen={isSnackOpen} />
      {inviteError && 
        <SubscriptionModal 
          open={Boolean(inviteError)}
          message={inviteError}
          onClose={() => setInviteError('')}
          user={user}
          modalTitle={"sharing"}
        />}
    </div>
  );
};

BriefManage.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(BriefManage);
