import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React, {useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';

const SubscriptionModal = props =>{
    const { user, isMobile, apiManager, open, message, onClose, modalTitle } = props;
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [modalDisplayTitle, setModalDisplayTitle] = useState('');

    const admin = user.orgRole === "owner" || user.orgRole === "co-owner";
    const affiliate = user.orgAffiliates && user.orgAffiliates.length > 0;
    const pma = user.hostOrg; 
    const owner = user.orgRole === "owner";
    let title = ""
    useEffect(() => {
      console.log(modalTitle)
    switch(modalTitle){
      case "sharing":
        setModalDisplayTitle("Sharing Limit Reached")
        break;
      case "briefs":
        setModalDisplayTitle("Brief Limit Reached")
        break;
      case "team":
        setModalDisplayTitle("Team Limit Reached")
        break;
    }
  },[modalTitle])

    useEffect(()=>{
      setIsOpen(open)
    },[open])
   
    const handleSubscribe = () =>{
      history.push("/subscriptions")
      setIsOpen(false)
    }
    const handleCancel = () =>{
      onClose();
    }

    const renderContactMessage = () => {
      return(
          <p>
            Contact the account owner {user.orgOwner} at <a href={`mailto:${user.orgOwnerEmail}`}>{user.orgOwnerEmail}</a> and ask them to upgrade your Breefly account today!
          </p>        
      )
    }
  if (pma && !owner) {
    return (
       <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
        <DialogTitle id="form-dialog-title">{modalDisplayTitle}</DialogTitle>
        <DialogContent>
            <p>{message}</p>
            {renderContactMessage()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={handleCancel} color="primary">
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    )
    } else {
      return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
          <DialogTitle id="form-dialog-title">{modalDisplayTitle}</DialogTitle>
          <DialogContent>
              <p>{message}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
            <span style={{fontWeight: 300}}>Cancel</span>
            </Button>
            <Button onClick={handleSubscribe} color="primary">
              Upgrade Plan
            </Button>
          </DialogActions>
        </Dialog>
      )
  }
}
SubscriptionModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default SubscriptionModal;
