import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/styles';

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  
  CardContent,
 
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  IconButton,
  TableContainer,
} from "@material-ui/core";

import validator from "email-validator";

import BrandedCardTitle from "../../components/BrandedCardTitle";
// import AddSeats from "../../../../components/AddSeats";


import {
  
  faTimesCircle,
 
  faUpload
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const schema = {
//     firstName: {
//       presence: { allowEmpty: false, message: 'is required' },
//       length: {
//         maximum: 32
//       }
//     },
//     lastName: {
//       presence: { allowEmpty: false, message: 'is required' },
//       length: {
//         maximum: 32
//       }
//     },
//     email: {
//       presence: { allowEmpty: false, message: 'is required' },
//       email: true,
//       length: {
//         maximum: 64
//       }
//     },
//     password: {
//       presence: { allowEmpty: false, message: 'is required' },
//       length: {
//         maximum: 128
//       }
//     },
//     policy: {
//       presence: { allowEmpty: false, message: 'is required' },
//       checked: true
//     }
//   };
const SimpleTextField = withStyles({
  root: {
    '& label':{
      fontSize: 12,
    },
    '& input':{
      fontSize: 12,
      color: '#000000 !important',
    },
    '& label.Mui-focused': {
      color: '#000000 !important',
      fontSize: 8,
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #000000',
    },
    '& .MuiInput-underline.Mui-error:after': {
      transform: 'scaleX(1)',
      borderBottomColor: 'red !important',
  }
  },
})(TextField);
const NoBorderCell = withStyles({
  root:{
    borderBottom: 'none',
    fontSize: 12,
  }
})(TableCell)
const MemberSelect = withStyles({
  select: {
      fontSize: 12,
      color: '#000000 !important',
  },
})(Select);

export default function MembersInvite(props) {
  const { open, close, inviteType, remainingSeats } = props;

  // const [formState, setFormState] = useState({
  //   isValid: false,
  //   values: {},
  //   touched: {},
  //   errors: {},
  //   isSuccessfull: false
  // });
  
  const [canInvite, setCanInvite] = React.useState(false);
  const [force, setForce] = React.useState(false);

  const [list, setList] = React.useState([{ firstname: "", lastname: "", email: "", type: "", state: false }
  ]);
  const rowTemplate = { firstname: "", lastname: "", email: "", type: "", state: false }

  //const allowBulkInvite = getParameterByName('bulkinvite');
    const [allowBulkInvite, setAllowBulkInvite] = useState(true);

  /*
  useEffect(() => {
    // const errors = validate(formState.values, schema);

    // setFormState(formState => ({
    //   ...formState,
    //   isValid: errors ? false : true,
    //   errors: errors || {}
    // }));
  }, list);
  */
 //set the number of rows to remaining seats
 useEffect(() => {
    let updatedList = [];
    for(let i=0; i<remainingSeats; i++){
      updatedList.push(rowTemplate)
    }
    setList(updatedList)
    const user = JSON.parse(sessionStorage.getItem('user'));
    console.log(user)
    if(user.accounttype === 'teamSinglePayment'){
      setAllowBulkInvite(false)
    }
 },[])


  const validateList = list => {
    let allValid = true;
    let atLeastOne = false;
    list.forEach(invite => {
      if (invite.state) {
        atLeastOne = true;
        if (invite.state !== "valid") {
          allValid = false;
        }
      }
    });

    setCanInvite(atLeastOne && allValid);
  };

  const listInput = (event, index) => {
    
    let newList = [...list]
    let newItem = {...newList[index]}
    newItem[event.target.name] = event.target.value
    

    // const l = updateList[index];
    // const v = event.target.value;
    // const name = event.target.name;
    // updateList[index][name] = v;

    if (inviteType === "affiliates") {
      newItem.type = "affiliate";
    }
    newItem.state = false;
    if (
      newItem.firstname.length + newItem.lastname.length + newItem.email.length + newItem.type.length >
      0
    ) {
      newItem.state = "dirty";
      if (
        newItem.firstname.length > 0 &&
        newItem.lastname.length > 0 &&
        newItem.email.length > 0 &&
        newItem.type.length > 0
      ) {
        if (validator.validate(newItem.email)) {
          newItem.state = "valid";
        } else {
          newItem.state = "invalid";
        }
      }
    }
    newList[index] = newItem

    //NOT USED. LIST LENGTH SET BASED ON REMAINING SEATS
    // finally see if we need another blank list row
    // const finalRow = list[list.length - 1];
    // if (
    //   finalRow.firstname.length +
    //     finalRow.lastname.length +
    //     finalRow.email.length +
    //     finalRow.type.length !==
    //   0
    // ) {
    //   list.push({
    //     firstname: "",
    //     lastname: "",
    //     email: "",
    //     type: "",
    //     state: false
    //   });
    // }
    setList(newList)
    validateList(newList);
  };

  const removeInvite = index => {
    list[index] = {
      firstname: "",
      lastname: "",
      email: "",
      type: "",
      state: false
    };
    validateList(list);
    setForce(!force);
  };

  const resetList = () => {
    let updatedList = [];
    for(let i=0; i<remainingSeats; i++){
      updatedList.push(rowTemplate)
    }
    setList(updatedList)
  };

  // Close the modal and sents the invites.
  const updateAction = async () => {
    // await handleUpdate(formState.values);

    // first create the payload of legit invites
    const inviteList = list.filter(person => person.state === "valid");

    resetList();
    close(inviteList);
  };

  const handleClose = () => {
    resetList();
    close(false);
  };

  const requestBulkInvite = () => {
      resetList();
      close('bulk');
  }

  return (
    <div className="branded">
      {/* <IconButton aria-label="close" onClick={openModal}>
      <FontAwesomeIcon icon={faPlus} />
    </IconButton> */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
      >
        <Card className={`branded card`}
        elevation={0}>
          <BrandedCardTitle
            title={
              inviteType === "members"
                ? "Setup your Team"
                : "Invite Subscribers"
            }
            cta={
                inviteType === "members"
                ?(allowBulkInvite ? "Upload a team member list" : null)
                : null
            }
            icon = {
                inviteType === "members"
                ? faUpload
                : null          
            }
            callback = {requestBulkInvite}
          />
          <CardContent>
            <div className='invite-top'>
              {/* {inviteType === "members" && (
                <>
                  Member Seats Available: {org.seats.count - org.stats.usedSeats}{" "}
                  of {org.seats.count}
                </>
              )}
              <AddSeats 
              left={64}/> */}
            </div>

            <TableContainer style={{maxHeight: '48vh', overflowY: 'scroll'}}>
              <Table
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <NoBorderCell>First Name</NoBorderCell>
                    <NoBorderCell>Last Name</NoBorderCell>
                    <NoBorderCell>Email</NoBorderCell>
                    <NoBorderCell>Role</NoBorderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((person, index) => {
                    return (
                      <TableRow key={index}>
                        <NoBorderCell>
                          <SimpleTextField
                            onChange={event => listInput(event, index)}
                            //className="inviteInput"
                            name="firstname"
                            fullWidth
                            //color="primary"
                            label="First Name"
                            value={list[index].firstname}
                          />
                        </NoBorderCell>
                        <NoBorderCell>
                          <SimpleTextField
                            onChange={event => listInput(event, index)}
                            //className="inviteInput"
                            name="lastname"
                            fullWidth
                            label="Last Name"
                            value={list[index].lastname}
                          />
                        </NoBorderCell>
                        <NoBorderCell>
                          <SimpleTextField
                            onChange={event => listInput(event, index)}
                            //className="inviteInput"
                            fullWidth
                            error={list[index].state === "invalid"}
                            name="email"
                            label="Email"
                            
                            value={list[index].email}
                          />
                        </NoBorderCell>
                        {inviteType === "members" && (
                          <NoBorderCell>
                            <MemberSelect
                              name="type"
                              value={list[index].type}
                              onChange={event => listInput(event, index)}
                              fullWidth
                              className="inviteInput"
                              style={{paddingTop: 8}}
                            >
                              {/* {user.orgRole === 'owner' && (
                                <MenuItem style={{ fontSize: 14 }} value="owner">Client</MenuItem>
                            )} */}
                              <MenuItem
                                style={{ fontSize: 12 }}
                                value="co-owner"
                              >
                                Administrator
                              </MenuItem>
                              <MenuItem style={{ fontSize: 12 }} value="member">
                                Team Member
                              </MenuItem>
                            </MemberSelect>
                            {/* <input  onChange={((event) => listInput(event, index))} className='inviteInput' name="role" type="text" placeholder="Role" value={person.role}/> */}
                          </NoBorderCell>
                        )}
                        <NoBorderCell
                        size='small'
                        padding='none'>
                          {person.state && (
                            <IconButton onClick={() => removeInvite(index)}
                              style={{fontSize: 16}}>
                              <FontAwesomeIcon
                                
                                icon={faTimesCircle}
                              />
                            </IconButton>
                          )}
                        </NoBorderCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          {/* <CardActions>
                            <div class={`cardBottomLeft`}>
                                Updated 
                            </div>
                        </CardActions> */}
        </Card>
        <DialogActions style={{marginRight: 20}}>
          <Button onClick={handleClose} color="primary" className='invite-button'>
            Cancel
          </Button>
          <Button
            onClick={updateAction}
            color="primary"
            disabled={!canInvite}
            style={{ cursor: "pointer" }}
            className='invite-button'
          >
            <div
              style={{
                cursor: "pointer",
                color: `${canInvite ? "#333" : "#ccc"}`
              }}
            >
              Invite
            </div>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
