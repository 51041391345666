import React from 'react'; 

import {
  IconButton,
} from '@material-ui/core'; 
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function FavoriteBrief(props) {
  //const [open, setOpen] = React.useState(false);
  let { apiManager, brief, refreshChild } = props;
  // const [title, setTitle] = React.useState(''); 
  const [init, setInit] = React.useState(false);
  const [favorite, setFavorite] = React.useState(brief.favorite ? brief.favorite : { isFavorite: false });

  if(!init && !favorite.isFavorite){
    setInit(true)
    setFavorite({ 
        isFavorite: false 
    })
  }

  const handleClickOpen = async () => {
   
    const payload ={
      brief: brief._id,
      isFavorite: !favorite.isFavorite
    }
    await apiManager.updateFavorites(payload);
    setFavorite({...favorite, isFavorite: payload.isFavorite})
    //  brief.favorite = favorite;
     //setOpen(brief.isFavorite);
     
     if(refreshChild){
      await refreshChild();
     }
  };

  return (
    <div>
      <IconButton variant="outlined" color="primary" onClick={handleClickOpen} style={{fontSize: 14, marginRight: -6}}>
        <FontAwesomeIcon icon={favorite.isFavorite ? solidHeart : faHeart}  color={ favorite.isFavorite ? 'red' : 'gray'} />
      </IconButton> 
    </div>
  );
}
