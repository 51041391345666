import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RemoveBrief(props) {
  const [open, setOpen] = React.useState(false);

  const [user, updateUser] = React.useState(false);

  const [password, handleChange] = React.useState(false);
  const { classes, brief, apiManager, handleDelete } = props;

  const [text, handleText] = React.useState(`Are you sure you want to stop sharing this brief? Doing so will immediately revoke access to it, and any users you shared this brief with no longer be able to access this content.`);

 

  const handleClickOpen = () => {
    setOpen(true);
     
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteComponent = (event) => { 
    setOpen(false);
    handleDelete(brief);
  };

 

  return (
    // <span style={{ position: 'absolute', right: '50px', top: '21px' }}>
    
    <span>
              <IconButton aria-label="close" className={classes.icon} onClick={handleClickOpen}  style={{ fontSize: '15px'}} >
<FontAwesomeIcon icon={faTrash} />
</IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Stop sharing?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={deleteComponent} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
