import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import {
  TextField,
} from '@material-ui/core';



export default function EditTitle(props) { 
  const { updateBrief, brief } = props; 
  const defaultText = brief && brief.components ? brief.components[0].content.text : '';
  const [label, setLabel] = React.useState(defaultText === '' ? 'Enter your title...' : defaultText);
  const[title, setTitle] = useState(defaultText);

  if(brief && brief.title && title.length === 0){ 
    setTitle(brief.title)
}


  const updateTitle = async (e) => {
      const newTitle = e.target.value === '' ? 'Enter your title...' : e.target.value;

      brief.title = newTitle;
      setLabel( e.target.value === 0 ?  'Enter your title...' : 'Title');
      brief.components[0].content.text = newTitle;
      setTitle(newTitle)
      await updateBrief(brief);
  }



  const onChangeTitle = (e) => {
      const title = e.target.value;
      setTitle(title)
      brief.title = e.target.value;
  }

  const onFocus = (e) => {

    if (title === 'Enter your title...') {
        setTitle('');
        brief.title = '';
    }

    setLabel('Title')

}

    return (
        <div className='editTitle'>
            <TextField
            className="editTitleFont"
            fullWidth
            label={label}
            placeholder="Enter your title..."
            name="0"
            style={{ marginBottom: '20px', fontSize: 24 }}
            value={title}
            onChange={e => onChangeTitle(e)}
            onFocus={e => onFocus(e)}
            onBlur={updateTitle}
            type="text"
            variant="outlined"
            required
        />
        </div>
    );
}
