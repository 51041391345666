
import React, { useState, useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper"

am4core.useTheme(am4themes_material);
am4core.addLicense("CH290132791")
const numFormatter = (value, unit) => {
  if (unit === "dollar") {
    value.numberFormatter.numberFormat = "$#,###.#####";
  } else if (unit === "euro") {
    value.numberFormatter.numberFormat = "€#,###.#####";
  } else if (unit === "pound") {
    value.numberFormatter.numberFormat = "£#,###.#####";
  } else  if (unit === "percent") {
    value.numberFormatter.numberFormat = "#,###.#####'%'";
  } else if ( unit !== 'number') {
    value.numberFormatter.numberFormat = `${unit}#,###.#####`; 
  }
};
const appendSeriesNum = chart => {
  chart.events.on("beforedatavalidated", function() {
    for(var i = 0; i < chart.data.length; i++) {
      chart.data[i].label += " (" + i + ")";
    }
  });
}
const removeLabelNum = axis => {
  axis.renderer.labels.template.adapter.add("textOutput", function(text) {
    if(text){
      return text.replace(/ \(.*/, "");
    }
    else{
      return ''
    }
  });
}
const tooltipAdapter = series => {
  series.adapter.add('tooltipText', (text, target) => {
    let data = target.tooltipDataItem.dataContext;
    if(target.tooltipDataItem.dataContext){
      return `${data.label.replace(/ \(.*/, "")}: ${data.y}`
    } else {
      return text
    }
  })
}
const cleanDataLabels = data => {
  return data.map(el => ({...el, label: el.label.replace(/ \(.*/, "")  }))
}
const Chart = props => {
  am4core.options.autoDispose = true;
  const chart = useRef(null);
  const { chartColor, dataPoints, unit } = props || "";
  const chartNum = Math.floor(Math.random() * 1000000);
  const chartDiv = `chartdiv-${chartNum}`;
  let height = 500;
    if( props.chartType === 'bar' && dataPoints.length > 30) {
        height = 20 * dataPoints.length;
    }
  const [isExpanded, setExpanded] = useState(false)
  useLayoutEffect(() => {
    //X is the same are chart in all am examples
    let x;



    //PIE AND DOUGHNUT CHART
    if (
      props.chartType === "pie" ||
      props.chartType === "doughnut" ||
      props.chartType === "donut"
    ) {
   
      x = am4core.create(chartDiv, am4charts.PieChart);
      //remove any series numbers data if it was there
      // const cleanUpLabels = props.dataPoints.map(el => ({...el, label: el.label.replace(/ \(.*/, "")  }))
      x.data = cleanDataLabels(props.dataPoints)
      numFormatter(x, unit)
      let pieSeries = x.series.push(new am4charts.PieSeries());
        if (chartColor) {
            pieSeries.colors.passOptions = {};
            pieSeries.colors.reuse = true;
            pieSeries.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
        }
      pieSeries.dataFields.value = "y";
      pieSeries.dataFields.category = "label";
      pieSeries.labels.template.maxWidth = 150;
      pieSeries.labels.template.wrap = true;

      pieSeries.slices.template.tooltipText = `{category}: {value.value}`;
      if(x.data.length > 10){
        let grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.limit = 10;
        grouper.groupName = "Other (click twice to see)";
        grouper.clickBehavior = "zoom";  
            grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
              if(ev.visible){
                pieSeries.labels.template.disabled = true;
                pieSeries.ticks.template.disabled = true;                           
              }
              setExpanded(ev.visible)
          }, this);                 
         
      }
      if (props.chartType === "doughnut" || props.chartType === "donut") {
        x.innerRadius = am4core.percent(40);
      }
      x.radius = am4core.percent(65);
      //x.legend = new am4charts.Legend();

    }
    //
    if (props.chartType === "line" || props.chartType === "scatter") {
      x = am4core.create(chartDiv, am4charts.XYChart);
      x.data = cleanDataLabels(props.dataPoints)
      appendSeriesNum(x)
      numFormatter(x, unit)

        if (chartColor) {
            x.colors.passOptions = {};
            x.colors.reuse = true;
            x.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
        }

      let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label"
      categoryAxis.renderer.minGridDistance = 40
      removeLabelNum(categoryAxis)
      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

      let series = x.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "y";
      series.dataFields.categoryX = "label"

      //tooltip
      //series.tooltipText = "{categoryX}: {valueY}";
      categoryAxis.cursorTooltipEnabled = false;
      tooltipAdapter(series)
      series.tooltip.pointerOrientation = "vertical";
      if (props.chartType === "scatter") {
        series.strokeOpacity = 0;
      }
      let bullet = series.bullets.push(new am4charts.Bullet());
      let dot = bullet.createChild(am4core.Circle);
      dot.horizontalCenter = "middle";
      dot.verticalCenter = "middle";
      dot.strokeWidth = 0;
      dot.fill = x.colors.getIndex(0);
      dot.width = 10;
      dot.height = 19;

      // let dotLabel = series.bullets.push(new am4charts.LabelBullet());
      // dotLabel.label.text = "{y}";
      // dotLabel.label.horizontalCenter = "center";
      // dotLabel.label.dx = 10;
      
      x.cursor = new am4charts.XYCursor();

      //x.legend = new am4charts.Legend();
    }
    if (props.chartType === "word") {
      x = am4core.create(chartDiv, am4plugins_wordCloud.WordCloud);
      
      let series = x.series.push(new am4plugins_wordCloud.WordCloudSeries());
      
      series.accuracy = 5;
     series.step = 15;
     series.rotationThreshold = 0.7;
     series.maxCount = 200;
     series.labels.template.margin(4, 4, 4, 4);

      series.fontSize = 200;
      series.maxFontSize = am4core.percent(30);

      series.minFontSize = am4core.percent(4);
      series.maxFontSize = am4core.percent(24);
    

      if( typeof props.dataPoints === 'string') {
        series.minWordLength = 2;
        series.excludeWords = ["a", "the", "an", "to", 'of', 'and', 'is', 'in', 'be', 'this', 'that', 'has','it','at', 'have', 'as'];
        series.text = props.dataPoints;
      } else {
          // it's an array
          series.data = [];

          props.dataPoints.forEach(point => {
            series.data.push({ tag: point.label, weight: point.y });
          });

          series.dataFields.word = 'tag';
          series.dataFields.value = 'weight';

        //   if (series.data.length > 18) {
        //     series.data.splice(0, series.data.length - 18);
        //     series.minFontSize = am4core.percent(6);
        //   }
        //   if (series.data.length < 10) {
        //     series.minFontSize = am4core.percent(7);
        //     series.maxFontSize = am4core.percent(13);
        //   }
          series.randomness = 0.1;
      }
      series.colors = new am4core.ColorSet();
      series.colors.passOptions = {};
      series.colors.reuse = true;
      if (chartColor) {
        series.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2) , am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)]
      }
      series.angles = [0, -90];
      // series.fontWeight = "700";
    }
    if (props.chartType === "gauge") {
      //data should be in percent
      //dataPoints.label = label
      //dataPoints.y = value
      //dataPoints.full = added below 100%
      x = am4core.create(chartDiv, am4charts.RadarChart);
      if (chartColor) {
        x.colors.passOptions = {};
        x.colors.reuse = true;
        x.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
    }

      //Gauge full is calculated off the max value, if greater than 100, full = maxValue otherwise default to 100
      let maxValue = props.dataPoints.map(point => point.y)
      maxValue = Math.max(...maxValue)
      const gaugeData = props.dataPoints.map(point => ({
        ...point,
        full: maxValue > 100 ? maxValue : 100
      })
      );
      x.data = gaugeData;
      x.startAngle = -90;
      x.endAngle = 180;
      x.innerRadius = am4core.percent(20);
      appendSeriesNum(x)
      numFormatter(x, unit)

      // Create axes
      let categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
      removeLabelNum(categoryAxis);
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.fontWeight = 500;
      categoryAxis.renderer.labels.template.adapter.add("fill", function(
        fill,
        target
      ) {
        return target.dataItem.index >= 0
          ? x.colors.getIndex(target.dataItem.index)
          : fill;
      });
      categoryAxis.renderer.minGridDistance = 10;

      let valueAxis = x.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = maxValue > 100 ? maxValue : 100;
      valueAxis.strictMinMax = true;
      let series1 = x.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = "full";
      series1.dataFields.categoryY = "label";
      series1.clustered = false;
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
        "alternativeBackground"
      );
      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.cornerRadiusTopLeft = 20;
      series1.columns.template.strokeWidth = 0;
      series1.columns.template.radarColumn.cornerRadius = 20;

      let series2 = x.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = "y";

      series2.dataFields.categoryY = "label";
      series2.clustered = false;
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.tooltipText = "{label}: [bold]{y}[/]";
      series2.columns.template.adapter.add("tooltipText", (text, target) => {
          let data = target.tooltipDataItem.dataContext;
          if(target.tooltipDataItem.dataContext){
            return `${data.label.replace(/ \(.*/, "")}: ${data.y}`
          } else {
            return text
          }
        })
      series2.columns.template.radarColumn.cornerRadius = 20;
      series2.columns.template.adapter.add("fill", function(fill, target) {
        return x.colors.getIndex(target.dataItem.index);
      });
      // Add cursor
      // let bullet = series2.bullets.push(new am4charts.LabelBullet())
      // bullet.interactionsEnabled = false
      // bullet.dx = 10;
      // bullet.dy= 10
      // bullet.label.text = '{valueX}'
      x.cursor = new am4charts.RadarCursor();
    }
    if (props.chartType === "pyramid") {
      //dataPoints.y = value
      //dataPoints.label = label
      x = am4core.create(chartDiv, am4charts.SlicedChart);
      
      x.data = cleanDataLabels(props.dataPoints)
      numFormatter(x, unit)
      let series = x.series.push(new am4charts.PyramidSeries());
      if (chartColor) {
        series.colors.passOptions = {};
        series.colors.reuse = true;
        series.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
    }
      series.dataFields.value = "y";
      series.dataFields.category = "label";
      series.alignLabels = true;
      series.valueIs = "height";
      series.slices.template.tooltipText = "Value: {value.value}";
      if(x.data.length > 10){
        let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.limit = 10;
        grouper.groupName = "Other (click twice to see)";
        grouper.clickBehavior = "zoom";  
            grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
              if(ev.visible){
                series.labels.template.disabled = true;
                series.ticks.template.disabled = true;                           
              }
              setExpanded(ev.visible)
          }, this);                 
         
      }
    }
    if (props.chartType === "bar") {
      x = am4core.create(chartDiv, am4charts.XYChart);
      numFormatter(x, unit);
      x.data = cleanDataLabels(props.dataPoints)

      //append series number to label
      appendSeriesNum(x);

        if (chartColor) {
            x.colors.passOptions = {};
            x.colors.reuse = true;
            x.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
        }


      let categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.minGridDistance = 1;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.grid.template.disabled = true;

      removeLabelNum(categoryAxis);
  
      let valueAxis = x.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.labels.template.horizontalCenter = "right";


      let series = x.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "label";
      series.dataFields.valueX = "y";
      //series.tooltipText = "{categoryY}: {valueX.value}";
      categoryAxis.cursorTooltipEnabled = false;
      tooltipAdapter(series)
      
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusBottomRight = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.horizontalCenter = "left";
      labelBullet.label.dx = 30;
      labelBullet.label.text = "{values.valueX.value}";
      labelBullet.label.truncate = false;
      labelBullet.label.hideOversized = false;
      labelBullet.locationX = 1;

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      series.columns.template.adapter.add("fill", function(fill, target) {
        return x.colors.getIndex(target.dataItem.index);
      });

      categoryAxis.sortBySeries = series;
      x.cursor = new am4charts.XYCursor();

    }
    if (props.chartType === "column") {
      // Create chart instance
      x = am4core.create(chartDiv, am4charts.XYChart);
      if (chartColor) {
        x.colors.passOptions = {};
        x.colors.reuse = true;
        x.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
    }
      x.scrollbarX = new am4core.Scrollbar();

      // Add data
      x.data = cleanDataLabels(props.dataPoints)
      //append series number to label
      appendSeriesNum(x)
     
      // Create axes
      let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.minHeight = 110;

      //remove text from labels
      removeLabelNum(categoryAxis)

      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      let series = x.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = "y";
      numFormatter(series, unit);
      series.dataFields.categoryX = "label";
      //series.tooltipText = "{categoryX}: {valueY}[/]";
      categoryAxis.cursorTooltipEnabled = false;
      tooltipAdapter(series)

      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = "vertical";

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      let hoverState = series.columns.template.column.states.create("hover");
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add("fill", function(fill, target) {
        return x.colors.getIndex(target.dataItem.index);
      });
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      
      labelBullet.label.verticalCenter = "bottom";
      labelBullet.dy = 20;
      labelBullet.label.text = '{valueY}'
      labelBullet.label.fill = am4core.color('#ffffff')

      // Cursor
      x.cursor = new am4charts.XYCursor();

      x.maskBullets = false;
    }
    x.padding(10, 10, 20, 10);

    //Chart Title
    let title = x.titles.create();
    title.text = `[bold]${props.title}[/]`;
    title.fontSize = 14;
    title.marginBottom = 24;

    //Chart Bottom Text
    let label = x.createChild(am4core.Label);
    label.text = `${props.subtitle}`;
    label.align = "left";
    label.width = 615;
    label.wrap = true;
    label.paddingTop = 20;

    chart.current = x;
    // return () => {
    //   x.dispose();
    // };
  }, [isExpanded]);


  return (
    <div
      id={chartDiv}
      style={{
        backgroundColor: "white",
        width: "100%",
        height: `${height}px`,
        padding: 14
      }}
    ></div>
  );
};
export default Chart;


