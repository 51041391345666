import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

 

const styles = (theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export class Dashboard extends React.PureComponent {
  constructor(...args) {
    super(...args);
    this._logoutTimerID = null;
    this._logoutIntervalID = null;

    const { user, league, payout } = this.props;
    this.state = {
      user,
      league,
      payout
    }
  }
  

  render() {
    const { classes } = this.props;
    const { user,
      league,
      payout
    } = this.state;
    return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          Welcome to your Christmas Gift
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
         
        </Grid> 
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
         
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        > 
        Dashboard
          </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        > 
        </Grid>
        
      </Grid>
    </div>
  );
    }
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withStyles(styles)(Dashboard);
