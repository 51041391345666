import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  IconButton,
  Link, 
  Dialog,
  Menu, 
  Avatar,
  TextField,
  Switch,
  MenuItem,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { faFont, faCamera, faPlay, faUser, faLink, faTimesCircle, faShare } from "@fortawesome/free-solid-svg-icons";
 
import apiManager from '../../../ApiManager';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  isSelected: {
      border: '1px solid #ccc',
      borderRadius: '50%'
  }
}));



export default function RowMenu(props) { 
  const { history, classes, executeSelection, brief, person } = props; 
   
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu =  event => { 
    setAnchorEl(event.currentTarget);
  }

  const close = () => {
    setAnchorEl(null);
  }
 
  const closeMenu = (event, access) => {
        setAnchorEl(null);
        if(access){
            executeSelection(access, brief ? brief : person)
        }
  }

  return (
    <div style={{ float: 'right',  marginRight: 8, position: 'relative' }}>
                        <IconButton aria-label="close" className={classes.icon} size="small" onClick={e => openMenu(e)} >
                        <FontAwesomeIcon icon={faEllipsisV} color={'primary'} />
                        </IconButton> 

                        <Menu
                        id="people-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={close}
                    >
                        <MenuItem onClick={e => closeMenu(e, 'deleted')} className={classes.menuitem} >Remove Access</MenuItem>
        <MenuItem onClick={e => closeMenu(e, 'viewer')} className={classes.menuitem}>Viewer</MenuItem>
        <MenuItem onClick={e => closeMenu(e, 'editor')} className={classes.menuitem}>Editor</MenuItem>
        <MenuItem onClick={e => closeMenu(e, 'admin')} className={classes.menuitem}>Co-Owner</MenuItem>
                    </Menu>
                        </div>
  );
}
