import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";

import ForgotPassword from "../Modals/ForgotPassword";
import RegisterSnackBar from "../SignIn/components/RegisterSnackBar";
import SignInButton from "../SignIn/SignInButton";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";
import { getParameterByName } from "../../utils/utils";
import "./css/style.scss";
import person from "../../images/SignIn/Person.png";
import airplane from "../../images/SignUp/Airplane.svg";
import messages from "../../images/SignUp/Messages.svg";
import topImage from "../../images/SignIn/topImage.png";
import face from "../../images/SignUp/removed_face.svg";
import questionMark from "../../images/SignUp/question_mark.svg"

import {
  Button,
  TextField,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import axios from "axios";

import { API_LOCAL, FAKE_ORGANIZATION } from "../../constants";

import LocalSettings from "../../services/LocalSettings";

import GDPR from "../../layouts/Main/components/GDPR";
import { USE_LOCAL, BREEFLY_LOGO } from "../../constants";
import eventTracker from "../../utils/event-tracker";
// import logoImage from '../../images/Breefly_Logo-Horiz.svg';
const logoImage = BREEFLY_LOGO;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const WelcomeDialog = props => {
  const { open, setOpen, branding } = props;
  let hl, par, but;
  let el = document.createElement("html");
  el.innerHTML = branding;
  hl = el.getElementsByTagName("h2");
  par = el.getElementsByTagName("p");
  but = el.getElementsByTagName("button");
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="welcome-title"
      PaperProps={{
        style: {
          backgroundColor: "#ffffffe3",
          maxWidth: 500,
          textAlign: "center",
          margin: "16px 24px",
          fontSize: 14
        }
      }}
    >
      <DialogTitle id="welcome-title">
        {/* {hl[0].innerText} */}
        Welcome to Breefly!
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="sign-up-text">
          {par[0].innerText}
          <br />
          <br />
          <p>
            Please sign in with the email and password provided in your welcome
            email to complete the sign up process.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          className="sign-up-portal"
          color="primary"
          onClick={() => setOpen(false)}
          style={{ margin: 16 }}
        >
          {/* {but[0].innerText} */}
          Let's get started!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20
    }
  }
};

makeStyles(theme => {
  //console.log(theme)
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    minHeight: 900,
    "& .MuiLink-root": {
      color: "#22b9ea"
    }
  },
  grid: {
    height: "100%"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    // height: '72%',
    // display: 'flex',
    flexDirection: "column"
  },
  panelSpacer: {
    width: 1,
    height: "15vh",
    minHeight: 50
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginBottom: 30
  },
  tagLine: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 16,
    color: "#EA3397",
    "@media (max-width: 820px)": {
      fontSize: 16
    }
  },
  contentBody: {
    flexGrow: 1,
    // display: 'flex',
    width: "80vw",
    // minWidth: 300,
    maxWidth: 400
  },
  form: {
    // borderColor: '#00BFFF',
    // borderStyle: 'solid',
    // borderWidth: 'medium',
    borderRadius: "8px",
    position: "relative",
    // top: '40%',
    textAlign: "center",
    alignItems: "center",
    paddingTop: 32,
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingBottom: 48,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    background: "#fff"
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  suggestion: {
    marginTop: theme.spacing(2)
  },
  emailField: {
    marginBottom: 24
  },
  textField: {
    maxWidth: 350,
    marginTop: theme.spacing(1),
    "@media (max-width: 820px)": {
      maxWidth: 280
    }
  }
  //   overrides: {
  //     // Style sheet name ⚛️
  //     MuiButton: {
  //       // Name of the rule
  //       text: {
  //         // Some CSS
  //         color: 'white',
  //       },
  //     },
  //   },
}));

// const updateIntercom = user => {
//   window.intercomSettings = {
//     app_id: "zcl62xdv",
//     name: `${user.firstname} ${user.lastname}`,
//     email: `${user.email}`,
//     created_at: `${user.created}`
//   };
//   window.Intercom("boot", window.intercomSettings);
//   sessionStorage.setItem("intercom", JSON.stringify(window.intercomSettings));
// };

const SignInPortal = props => {
  const {
    history,
    updateUser,
    match,
    apiManager,
    settings,
    isRemoved,
    isExpired,
    isSuspended
  } = props;

  const tcode = getParameterByName("tcode");

  if (tcode) {
    localStorage.setItem("tcode", tcode);
  } else {
    localStorage.removeItem("tcode");
  }
  const [isRemovedModalOpen, setRemovedOpen] = React.useState(true);
  const classes = useStyles();
  const [token, setToken] = useState("");
  const [init, setInit] = useState(false);
  const [inviteId, setInviteId] = useState(false);
  const [isTeam, setTeam] = useState(false);
  const [isAffiliate, setAffiliate] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);
  const [branding, setBrandSettings] = useState(
    LocalSettings.defaultSettings.branding
  );

  const validateToken = async () => {
    const url = API_LOCAL + "users/auth/token";
    const result = await axios.post(
      url,
      { token },
      { headers: apiManager.getHeaders() }
    );

    if (result && result.data && result.data.email) {
      updateUser(result.data);
      // updateIntercom(result.data);
      setToken(result.data.token);
      sessionStorage.setItem("token", result.data.token);
      const { search } = history.location;
      //console.log('search: ',search)
      let redirect = "/briefs";
      if (search && search.indexOf("?redirect=") > -1) {
        redirect = search.replace("?redirect=", "");
      }
      //history.push(redirect);
      window.location = redirect;
    }
  };

  // get invite id.. or if not valid, return to breefly view
  const tryToLoadBrand = async () => {
    // see if a parameter
    const urls = history.location.pathname.split("/");
    const commandIndex = urls.indexOf("signin");
    let orgIdFromParam =
      commandIndex > 0 && urls[commandIndex + 1]
        ? urls[commandIndex + 1].replace("#", "")
        : false;
    const memberParam =
      commandIndex > 0 && urls[commandIndex + 2]
        ? urls[commandIndex + 2]
        : false;

    let memberId = null;
    if (orgIdFromParam && orgIdFromParam !== "signin") {
      if (FAKE_ORGANIZATION) {
        orgIdFromParam = "5f0cb1f4fa81270007c41ca6";
      }

      setInviteId(orgIdFromParam);

      setOrgId(orgIdFromParam);

      // If a memberId URL path item exists set the state's memberId,
      // cause this signup is an affiliate invite
      if (urls.length === 4) {
        memberId = urls[urls.length - 1];
        setMemberId(memberId);
        setAffiliate(false);
      }

      setIsLoadingBrand(true);
      const brandData = await apiManager.getPortalBranding({
        key: "team-member",
        orgId: orgIdFromParam,
        memberId: memberId
      });

      //   if( brandData && brandData.html) {
      //     updatedBranding.html = brandData.html || {}
      //     setBrandSettings(updatedBranding);
      //   }

      if (
        brandData &&
        brandData.branding &&
        brandData.branding.portalbg &&
        brandData.branding.portallogo
      ) {
        brandData.branding.html = brandData.html || {};
        setBrandSettings(brandData.branding);
        setAlertOpen(true);
      }
      setIsLoadingBrand(false);
    } else {
      let response;
      const orgId = localStorage.getItem("organizationId");
      if (orgId) {
        response = await apiManager.getOrganizationBranding(
          JSON.parse(orgId).value
        );
      }
      if (!orgId || !response) {
        response = await apiManager.getSignInBranding();
      }
      if (response && response.branding) {
        response.branding.html = response.html || {};
      }
      response && response.branding && setBrandSettings(response.branding);
      setIsLoadingBrand(false);
    }
  };

  //   const getBrandSettings = async (invite) => {
  //     // const brandSettings = await settings('get', 'orgBranding', null);
  //     // setBrandSettings(brandSettings);
  //     let response = await apiManager.getSignInBranding();

  //     if(response && response.branding) {
  //       response.branding.html = (response.html || {})
  //     }
  //     response && response.branding && setBrandSettings(response.branding)

  //     if (invite) {
  //         setInviteId(0);
  //         const id = tryToLoadBrand(response.branding)
  //     }
  //     setIsLoadingBrand(false);
  //     return;
  //   }

  useEffect(() => {
    const color1 = branding.primary;
    const color2 = branding.secondary;

    if (color1 && color2) {
      const rootDoc = document.documentElement;
      rootDoc.style.setProperty("--brand-color1", color1);
      rootDoc.style.setProperty("--brand-color2", color2);
    }
  }, [branding]);

  if (!init) {
    setInit(true);
    if (token) {
      const orgId = sessionStorage.getItem("organizationId");
      const orgBranding = sessionStorage.getItem("orgBranding");
      sessionStorage.clear();
      if (orgId) {
        sessionStorage.setItem("organizationId", orgId);
      }
      if (orgBranding) {
        sessionStorage.setItem("orgBranding", orgBranding);
      }
      validateToken();
    }
    //   const updatedBranding = getBrandSettings(inviteId === false);

    //    validate parameter if it exists as an invite  if not valid, switch to generic sign in portal.
    if (inviteId === false || inviteId === 'false') {
      setInviteId(0);
      const id = tryToLoadBrand();
    }
    apiManager.getSettings();
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false,
    isModalOpen: false,
    snackMessage: ""
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      isModalOpen: false
    }));
  };

  const registerClick = async event => {
    event.preventDefault();
    const invitedParam = inviteId && inviteId !== 0 && inviteId !== 'false' ? inviteId : '';
    ;
    history.push(
      isAffiliate ? `/signup/${orgId}/${memberId}` : `/signup/${invitedParam}`
    );
  };

  const handleSignIn = async event => {
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      error: ""
    }));
    const { email, password } = formState.values;
    const url = API_LOCAL + "users/auth/login";

    try {
      const result = await axios.post(
        url,
        { email, password },
        { headers: apiManager.getHeaders() }
      );
      if (result && result.data && result.data.email) {
        setFormState(formState => ({
          ...formState,
          isSuccessfull: true,
          isModalOpen: true,
          snackMessage: "Sign In Successful"
        }));
        let redirect = "";
        updateUser(result.data);

        // updateIntercom(result.data);
        sessionStorage.setItem("token", result.data.token);
        LocalSettings.getAB();
        await eventTracker("Sign In", result.data, "sign-in");
        const { search } = history.location;

        const subIndex =
          result.data &&
          result.data.subscriptions &&
          result.data.subscriptions.length > 0
            ? result.data.subscriptions.length - 1
            : false;
        if (search && search.indexOf("?redirect=") > -1) {
          redirect = search.replace("?redirect=", "");
        }
        if (
          (subIndex || subIndex === 0) &&
          result.data.subscriptions[subIndex].session
        ) {
          const thisSub = result.data.subscriptions[subIndex];
          debugger;
          if (thisSub.active === 0 || (thisSub.session.status === 'past_due' && !thisSub.hasCardOnFile)) {
            //look for inactive sub, then make sure they are billing admin
            if (
              result.data.orgRole === "owner" ||
              result.data.onTeam === false
            ) {
              if (thisSub.session.status === 'past_due') {
                if (thisSub.hasCardOnFile) {
                  // oh, card is on file, don't bother
                  result.data.collectCard = false;
                } else {
                  // trial has expired, but no card entered.
                  redirect = "/subscriptions/expired";
                }
              } else {
                // still trialling
                redirect = "/subscriptions/expired";
              }
            } else {
              redirect = "/team-expired";
            }
          } else {
            redirect = "/briefs";
          }
        } else redirect = "/briefs";
        if (result.data.collectCard) {
          redirect = "/subscriptions/card";
        }
        
        //console.log(redirect)
        // If there was a redirect included in the response - execute it - wrong domain login
        if (
          result.data.redirectURL &&
          !USE_LOCAL &&
          result.data.redirectURL !== window.location.origin
        )
          window.location = `${result.data.redirectURL}${redirect}`;
        else history.push(redirect);
        //history.push(redirect);
      } else {
        if (result && result.data && result.data.type === "revoked") {
          history.push("/removed");
        }
        if (result && result.data && result.data.type === "suspended") {
          history.push("/suspended");
        }
        if (result && result.data && result.data.type === "setup") history.push(result.data.redirect);
        setFormState(formState => ({
          ...formState,
          error: "Incorrect user name and/or password",
          isSuccessfull: false,
          isModalOpen: true,
          snackMessage:
            result && result.data ? result.data.message : "Network error"
        }));
        //   {
        //     "code": 403,
        //     "message": "Access to Team Removed.",
        //     "redirectURL": "http://localhost:3000"
        // }
        
      }
    } catch (ex) {
      console.log(ex);
      setFormState(formState => ({
        ...formState,
        error: ex.message,
        isSuccessfull: false,
        isModalOpen: true,
        snackMessage: ex.message
      }));
    }
  };

  const forgotPasswordClick = async event => {
    event.preventDefault();
  };
  const closeModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFormState(formState => ({
      ...formState,
      isModalOpen: false
    }));
  };
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    // if(match.params && match.params[0] && branding & branding.html){
    if (!isLoadingBrand && match.params && match.params[0]) {
      setAlertOpen(true);
    }
  }, []);
  const hasError = field => {
    if (!formState.touched || !formState.errors) {
      return false;
    }
    return formState.touched[field] && formState.errors[field] ? true : false;
  };
  const renderPortalImage = () => {
    if (isRemoved) {
      return (
        <div className="rightPanel">
          <div className="removed__block">
            {isExpired ? (
              <>
                <Snackbar
                  open={isRemovedModalOpen}
                  onClose={() => setRemovedOpen(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert onClose={() => setRemovedOpen(false)} severity="error">
                    Team Subscription has Expired
                  </Alert>
                </Snackbar>
                <div style={{ display: "flex" }}>
                  <img
                    src={questionMark}
                    height="56px"
                    width="56px"
                    className="removed__image removed__image--question"
                  />
                  <img
                    src={face}
                    height="140px"
                    width="140px"
                    className="removed__image removed__image--face"
                  />
                </div>
                <h4 className="removed__headline">Hmm, that's strange.</h4>
                <p className=" removed__body removed__body--16">
                  It appears that your team's subscription has expired or been
                  canceled. Please contact your team owner directly to regain
                  access.
                </p>
                {/* <p className=" removed__body removed__body--16" style={{ paddingTop: 10 }}>If you'd like to create a new team or individual account, sign up now. To do so you'll need to use an email address not previously used on Breefly.</p> */}
              </>
            ) : (
              <>
                <Snackbar
                  open={isRemovedModalOpen}
                  onClose={() => setRemovedOpen(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert onClose={() => setRemovedOpen(false)} severity="error">
                    {isSuspended ? `Team Subscription Suspended` : `Access to Team Removed`}
                  </Alert>
                </Snackbar>
                <div style={{ display: "flex" }}>
                  <img
                    src={questionMark}
                    height="56px"
                    width="56px"
                    className="removed__image removed__image--question"
                  />
                  <img
                    src={face}
                    height="140px"
                    width="140px"
                    className="removed__image removed__image--face"
                  />
                </div>
                <h4 className="removed__headline">Hmm, that's strange.</h4>
                {isSuspended ? (
                  <>
                    <p className=" removed__body removed__body--16">
                      It appears that your team's access has been suspended.{" "}
                      <br />
                      Please contact the InSite administrator at:
                      insite@breefly.com.
                    </p>
                  </>
                ) : (
                  <>
                    <p className=" removed__body removed__body--16">
                      It appears that your team access has been disabled or
                      revoked. Please contact your team's admin directly to
                      regain access.
                    </p>
                    {/* <p
                      className=" removed__body removed__body--16"
                      style={{ paddingTop: 10 }}
                    >
                      If you'd like to create a new team or individual account,
                      sign up now. To do so you'll need to use an email address
                      not previously used on Breefly.
                    </p> */}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="rightPanel"
          style={{
            backgroundImage: `url(${
              branding && branding.portalbg
                ? branding.portalbg
                : //: LocalSettings.defaultSettings.branding.portalImage
                  ""
            })`
          }}
        >
          {branding && !branding.portalbg && (
            <div style={{ width: "100%" }}>
              <img src={person} className="personIm"></img>
              <div className="marketing-message">
                <img src={messages} className="messages"></img>
                <img src={topImage} className="cartoon-image"></img>
                <p className="insideTypeMsg">
                  Welcome Back You Reporting Rockstar!
                </p>
                <img src={airplane} className="airplane"></img>
              </div>
            </div>
          )}
          <div>
            {branding.html && branding.html.portalMessage && (
              <WelcomeDialog
                open={alertOpen}
                setOpen={setAlertOpen}
                branding={branding.html.portalMessage}
              />
            )}

            {branding.portallogo && (
              <img
                src={branding.portallogo}
                alt="organization logo"
                className="orgLogo"
              />
            )}
          </div>
        </div>
      );
    }
  };

  const renderSignUpPanel = () => {
    const b = branding;
    return (
      <div className="leftPanel">
        <div className="sign-up-form" style={{ minHeight: 500 }}>
          <form
            className={classes.form}
            onSubmit={handleSignIn}
            style={{ paddingBottom: 0 }}
          >
            <Typography
              className={classes.title}
              // variant="h6"
              align="center"
            >
              <img
                src={logoImage}
                alt="Breefly.com"
                width="220px"
                align="middle"
                className={classes.logoImage}
              />
              <div className={classes.tagLine}>
                Turn your insights into action.
              </div>
            </Typography>
            <TextField
              className={`${classes.emailField} ${classes.textField}`}
              error={hasError("email")}
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              required
              label="Email"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ""}
              variant="outlined"
              autoFocus
              size="small"
            />
            <TextField
              className={classes.textField}
              error={hasError("password")}
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              required
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
              variant="outlined"
              size="small"
            />

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <ForgotPassword />
            </div>
            <br />

            <div style={{ textAlign: "center" }}>
              <RegisterSnackBar
                isOpen={formState.isModalOpen}
                isSuccessfull={formState.isSuccessfull}
                message={formState.snackMessage}
                closeModal={closeModal}
              />
              <SignInButton handleSignIn={handleSignIn} />
            </div>
            {(!branding || !branding.domain) && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {" "}
                Don't have an account?
                <Link
                  href={
                    isAffiliate
                      ? `/signup/${orgId}/${memberId}`
                      : `/signup/${inviteId}`
                  }
                  onClick={registerClick}
                >
                  {" "}
                  Sign Up
                </Link>
              </div>
            )}
          </form>
          <div style={{ textAlign: "center", marginTop: 8 }}>
            <Link href="/terms-and-conditions" target="_blank">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`${classes.root} portal`}>
      {isLoadingBrand ? (
        <div className="loadingBrand">
          <LinearIndeterminate />
        </div>
      ) : (
        <>
          {renderSignUpPanel()}
          {renderPortalImage()}
        </>
      )}
      <GDPR />
    </div>
  );
};

SignInPortal.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withRouter(SignInPortal);
