import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import validate from 'validate.js';
import '../css/invite.scss'

import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    Switch,
    Snackbar,
    Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import Tribute from 'tributejs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faShare, faEnvelope, faCopy } from "@fortawesome/pro-light-svg-icons";
import { faGlobeAmericas} from '@fortawesome/free-solid-svg-icons';
import uuidv4 from 'uuid/v4';
import SubscriptionModal from '../../../components/SubscriptionModal'
import LocalSettings from '../../../services/LocalSettings';
import eventTracker from '../../../utils/event-tracker';

import Tag from '../../../components/Tags';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    isSelected: {
        border: '1px solid #ccc',
        borderRadius: '50%'
    },
    invite: {
        //fontWeight: 500,
        fontSize: 12,
        marginBottom: 14,
        textAlign: 'left',
        width: '100%'
    },
    inviteBox: {
        display: 'inline-block',
        minHeight: 42,
        width: '100%',
        // padding: 8,
        // border: '1px solid #ccc',
        borderRadius: 3,
        marginBottom: 18,
    },
    linkBox: {
        minHeight: 42,
        maxWidth: 480,
        minWidth: 250,
        padding: 8,
        border: '1px solid #ccc',
        borderRadius: 3,
        marginBottom: 18,
    },
    hint: {
        color: '#ccc'
    },
    // pick: {
    //     color: '#22b9ea',
    // },
    shareicon: {
        fontSize: 16,
        padding: 10,
        borderRadius: '50%',
        marginTop: -1,
        marginLeft: 20,
        height: 42,
        cursor: 'pointer',
        '&:hover' : {
            background: '#eee'
        }
    },
    sharemessage: {
        fontSize: 16,
        marginRight: 8,
        paddingTop: 6,
        display: 'inline-block',
    },
    icon:{
        fontSize: 14,
        marginRight: -6,
    },
    tributeTextArea: {
      maxHeight: 200,
      overflow: 'auto',
      
      // width: 200,
      // minHeight: 100,
      // border: '1px solid #dddddd',
      // borderRadius: 5,
      // padding: 6,
   },
}));

export default function InviteExistingUser(props) {
    const spacing = 2;
    const fullWidth = true;
    const maxWidth = 'sm'; 
    const { brief, handleSave, setIsEdit, handlePublicSave, isMobile, isInviteSuccessOpen, apiManager, user } = props;

    const [open, setOpen] = React.useState(false);
    // const [token, setToken] = React.useState(uuidv4());
    const [token, setToken] = React.useState(brief.sharing && brief.sharing.token ? brief.sharing.token : uuidv4());
    const [emailList, setEmails] = React.useState([]);
    const [nextEmail, setNextEmail] = React.useState('');
    const [message, setPersonalMessage] = React.useState('');
    const [shareMode, setShareMode] = React.useState('invite'); // or  or sharelink (step 2 of modal for sharing)

    const [access, handleAccess] = React.useState('viewer');

    const [usePublicLink, setUsePublicLink] = React.useState(false);
    const [publicLink, setPublicLink] = React.useState('');
    const [showSnack, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('Error');
    const [snackType, setSnackType] = React.useState('Success');
    const [limitShares, setLimitShares] = useState(false);
    const [wasPasted, setWasPasted] = useState(false);
    const [ab, setAb] = useState({orgs: [], saved: [], emails: [], unloaded: true})
    const [loadedAb, setIsLoadedAb] = useState(false);
    useEffect(() => {
        if(user && user.subscriptions && user.subscriptions.length > 0){
            const activeSub = user.subscriptions.find(sub => sub.active === 1)
            if(!activeSub){
              // double check if the sub is past_due but card exists
              if (user.subscriptions[0].session.status !== 'past_due' || !user.subscriptions[0].hasCardOnFile) {
                setLimitShares(true)
              }
            }
        }

        const upl = usePublicLink;
    },[open])

    const loadAb = async() => {
      const addressBook = await LocalSettings.getAB();
      if( addressBook ) setAb(addressBook);
    }

    const localClasses = useStyles();
    const [shareError, setShareError] = useState('')

    const openModal = async (event) => {
        const updatedBrief = await apiManager.getBrief(brief._id)

        event.stopPropagation();
        await loadAb();
        if(setIsEdit != null){
            setIsEdit(true)
        }
          // grab the share token or create a new one
            setToken(updatedBrief && updatedBrief.sharing && updatedBrief.sharing.token && updatedBrief.sharing.token.length > 0 ? updatedBrief.sharing.token : uuidv4())

            // Use subdomain in browser to create ROOTURL
            let rootURL = `${window.location.origin}/brief/`; //'https://pma.breefly.com/brief/';
            let briefLink = updatedBrief && updatedBrief.publicLink ? updatedBrief.publicLink : (`${rootURL}${brief._id}/${token}`) 
            

            setPublicLink(briefLink);
            // setUsePublicLink(false); // by default, don't show the link
            setPersonalMessage('');
            setOpen(true)
    }
    const closeModal = (event) => {
        event.stopPropagation();
        setEmails([]);
        setNextEmail('');
        setOpen(false)
    }

    const handleCloseSnackbar = () => {
        setSnackBar(false);
      }

    // const selectUser = (person) => {
    //     const selections = (emails.length === 0) ? [] : emails.indexOf(",") > -1 ? emails.split(',') : [emails];
    //     selections.push(person.email);
    //     handleChange(selections.join(', '))
    // }

    const sendInvites = (event) => {
        event.stopPropagation();

        if(!limitShares || (limitShares && emailList.length <= 3)){
            setOpen(false)
            //get emails convert to lower cose
            const emails = emailList.length > 0 ? emailList.map(el =>({email: el.email.toLowerCase(), type: el.type})) : [nextEmail].map(el => ({email: el.email.toLowerCase(), type: el.type}));
            const data = {
                emails,
                message,
                access,
                brief: brief._id,
                fromAB: true,
            }
            handleSave(data);
            eventTracker('Brief Share', user, 'invite-users')
            setEmails([]);
            setNextEmail('');
        } else {
            setShareError('You have reached your sharing limit. Please upgrade to share this brief with more users.')
        }
    }

    const sendPublicInvites = (event) => {
        event.stopPropagation();
        if(!limitShares || (limitShares && emailList.length <= 3)){
            setOpen(false)
            const emails = emailList.length > 0 ? emailList.map(el => ({email: el.email.toLowerCase(), type: el.type})) : [nextEmail].map(el => ({email: el.email.toLowerCase(), type: el.type}));
            const data = {
                emails,
                message,
                access,
                brief: brief._id
            }
            handlePublicSave(data);
            eventTracker('Guest Link Brief Share', user, 'invite-users')
            setEmails([]);
            setNextEmail('');
            setShareMode('invite')
        } else {
            setShareError('You have reached your sharing limit. Please upgrade to share this brief with more users.')
        }
    }

    const handleSelectPublicLink = async (event) => {
        event.stopPropagation();
        const newValue = !usePublicLink
        setUsePublicLink(newValue);

        let share = {
            publicLink,
            token,
            _id: brief._id
        }
        
      if (newValue === true) {
        if (!token) {

          const newToken = token || uuidv4();
          let rootURL = `${window.location.origin}/brief/`; // 'https://pma.breefly.com/brief/';
          // if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'prod') {
          //     rootURL = 'https://pma.breefly.com/brief/';
          // }
          const url = `${rootURL}${brief._id}/${newToken}`;
          setPublicLink(url)
          share.publicLink = url;
          share.token = newToken;
        }
      }
        else {
            // setPublicLink('');
            // setToken('')
            // share.publicLink = '';
            // share.token = '';
        }
   
      if (newValue === true) {
        const response = await apiManager.updatePublicShare(share);
        if (response && response.updatedCount === 0) {
          share.publicLink = '';
          share.token = '';
          setShareError(response.msg)
          setUsePublicLink(false)
          setPublicLink('');
          // setToken('')

        }
      }
    }

    // **************** tribute section: handle type ahead
   const tribute = new Tribute({
      trigger: '@',
      // element to target for @mentions
      iframe: null,

      // class added in the flyout menu for active item
      selectClass: 'tribute-highlight',

      // class added to the menu container
      containerClass: 'tribute-container',

      // class added to each list item
      itemClass: 'tribute-item',

      // function called on select that returns the content to insert
      //   selectTemplate: function (item) {
      //     return '@' + item.original.value;
      //   },
      selectTemplate: function (item) {
        handleTributeSelection(item)
        // return (
        //     '<span contenteditable="false" style="color: #2d6bc8;">' +
        //     item.original.value +
        //     "</span>"
        // );
      },

      // template for displaying item in menu
      menuItemTemplate: function (item) {
        return item.string;
      },

      // template for when no match is found (optional),
      // If no template is provided, menu is hidden.
      noMatchTemplate: null,

      // specify an alternative parent container for the menu
      // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
      // default container is the body
      menuContainer: document.body,

      // column to search against in the object (accepts function or string)
      lookup: 'key',

      // column that contains the content to insert by default
      fillAttr: 'value',

      // REQUIRED: array of objects to match
      // values: [
      //   { key: "Phil Heartman", value: "Mr. Heartman", type: "org" },
      //   { key: "Gordon Ramsey", value: "Mr. Ramsey", type: "list" },
      //   { key: "jill@smith.com", value: "jill@smith2.com", type: "email" }
      // ],
      values: [],

      searchOpts: {
        pre: '<span>',
        post: '</span>',
        skip: false // true will skip local search, useful if doing server-side search
      },

      // specify the minimum number of characters that must be typed before menu appears
      menuShowMinLength: 0
    });


  if(!loadedAb) {
    loadAb();
    setIsLoadedAb(true);
  }

  const attachTribute = () => {
  
    const c = tribute.collection[0];
    const v = tribute.collection[0].values;
 
      let el = document.getElementById("testtribute");
      if( el) {
         if (v.length === 0 && ab.tlist && ab.tlist.length > 0) {
          // c.values.concat(ab.tlist);
          ab.tlist.forEach(item => {
            c.values.push(item)
          })
        }
        tribute.attach(el);
      }
  }


    // **************** end of tribute section
    
    const handleEmailKeypress = e => {
        e.stopPropagation();
        const key = e.which;
        if(!wasPasted) {
          if ((key === 32 || key === 13 || key === 188 || key === 186) && nextEmail !== '') {
            // remove , and extraneous stuff at end
            const email = nextEmail.replace(/[,;]/g, '').trim();
            const invalid = validate.single(email, { presence: true, email: true });
            if (!invalid) {
              const newEmail = { email, type: 'email' }
              const newList = emailList;
              let found = false;
              newList.forEach(e => { if (e.email === email) found = true })
              if (!found) {
                newList.push(newEmail);
                setEmails(newList);
              }  else {
                setSnackType('Error');
                setSnackMessage('Email already entered.');
                setSnackBar(true);
              }

              setNextEmail('');
              if (shareMode === 'invite') {   // still on original modal.
                setUsePublicLink(false);
              }
            } else {
              setSnackType('Error');
              setSnackMessage('Invalid email address format');
              setSnackBar(true);
            }
          }
      } else {
        setWasPasted(false);
      }
    }

    const handlePastedEmails = e => {
      setWasPasted(true);
      e.stopPropagation();
      setNextEmail('');
      const paste = (e.clipboardData || window.clipboardData).getData('text');
      let emails = paste.split(',');
      if( emails && emails.length > 0) {
        emails = emails.map(email => ({email: email.replace(/[,;]/g, '').trim().toLowerCase(), type: 'email'}));
        const newList = emailList.concat(emails);
        setEmails(newList);
        if (shareMode === 'invite') {   // still on original modal.
            setUsePublicLink(false);
        }
      }
 
    }

    const handleBlur = e => {
      if(wasPasted) {
        setWasPasted(false)
      } else {
        const invalidEmail = validate.single(nextEmail, {presence: true, email: true})
        if (!invalidEmail) {
          const email = nextEmail.replace(/[,;]/g, '').trim();
            const newEmail =  {email, type: 'email'}
            const newList = emailList;
          let found = false;
          newList.forEach(e => { if (e.email === email) found = true })
          if (!found) {
            newList.push(newEmail);
            setEmails(newList);
          } else {
            setSnackType('Error');
            setSnackMessage('Email already entered.');
            setSnackBar(true);
          }
            setNextEmail('');
            if (shareMode === 'invite') {   // still on original modal.
                setUsePublicLink(false);
            }
        }
      }
    }

  const handleTributeSelection = item => {
    if (wasPasted) {
      setWasPasted(false)
    } else {
      if (!item) {
        const n = nextEmail;
      } else {
        const email = item.original.value;
        const type = item.original.type;

        const newEmail = { email: `${email}`, type }
        const newList = emailList;
        let found = false;
        newList.forEach(e => { if (e.email === email) found = true })
        if (!found) {
          newList.push(newEmail);
          setEmails(newList);
        } else {
          setSnackType('Error');
          setSnackMessage('Email already entered.');
          setSnackBar(true);
        }
        setNextEmail('');
        if (shareMode === 'invite') {   // still on original modal.
          setUsePublicLink(false);
        }
      }

    }
  }

    const handleEmailInput = e => {
      if( wasPasted) {
        setWasPasted(false)
      } else {
          setNextEmail(e.target.value);
          if (e.target.value != '' && shareMode === 'invite') {  // still on original modal.
            setUsePublicLink(false);
          }
      }
    }
    const validateEmails = () => {
        if(emailList.length === 0){
            return validate.single(nextEmail.email, {presence: true, email: true})
        }
        else {
        let validated = emailList.map(email => {
           if(email.type === 'email') {
            return validate.single(email.email, {presence: true, email: true});
           }
           return undefined;
        })
        const invalid = validated.filter(el => el !== undefined)
        if(invalid.length === 0 && emailList.length > 0){
            return false
        } else {
            return validated
        }
        }
        

    }
    const removeEmail = emailToDelete => {
        const newEmailList = emailList.filter(e => e.email.trim() !== emailToDelete.trim());
        setEmails(newEmailList);
    }

    const copyToClipboard = (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(publicLink);
        setSnackType('Success');
        setSnackMessage('Copied to clipboard');
        setSnackBar(true);
      };

    const shareLinkViaEmail = (event) => {
        event.stopPropagation();
        setShareMode('sharelink');
    }

    const stopPropagation = (event) => {
        event.stopPropagation();
    }

    const cancelShareLink = (event) => {
        event.stopPropagation();
        setShareMode('invite');
        // setUsePublicLink(false);
        setEmails([]);
        // setToken('')
    }
    const handleClose = (event) => {
        cancelShareLink(event) 
        closeModal(event)
    }
    const handleErrorClose = event => {
        setShareError('')
    }
    return (
        <span >
            <IconButton aria-label="close" className={localClasses.icon} onClick={openModal}>
                <FontAwesomeIcon icon={faShare} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title"
                fullWidth={fullWidth}
                maxWidth={maxWidth} 
                className="shareModal"
            >
                <DialogTitle id="form-dialog-title">
                    <div className="flex-row-space flex-row-space--top">
                    {shareMode === 'invite'
                    ? <>Share this Brief</>
                    : <>Send Link to this Brief</>
                    }
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ margin: 6 }}>
                    <Grid
                        container justify="left" spacing={spacing}
                    >
                        {/* ************************************ INVITE EMAIL BOX ******************** */}
                        <div className={localClasses.invite}>
                            {shareMode === 'invite'
                            ? <>Share with other Breefly users</>
                            : <>Email Guest Link</>
                            }
                        </div>
                        <div className="flex-row-space">
                         <TextField
                            className={`email ${localClasses.tributeTextArea}`}
                            id="testtribute"
                            type="text"
                            fullWidth
                            //style={{ width: !isMobile ?  '375px' : '150px' }}
                            // onFocus={stopPropagation}
                            onFocus={attachTribute}
                            onBlur={handleBlur}
                            value={nextEmail}
                            placeholder={emailList.length === 0 && nextEmail === '' ? `${isMobile ? `Enter Emails` : `Enter email addresses separated by commas` }` : ''}
                            onKeyUp={handleEmailKeypress}
                            onChange={handleEmailInput}
                            onPaste={handlePastedEmails}
                            classes={`${localClasses.red} ${localClasses.tributeTextArea}`}
                            helperText="Separate emails with a ',' or hit enter"
                            />
                        {/* ******************************************* Roles for invitees */}
                        {!usePublicLink && (
                        <Select
                            label="Access level"
                            id="access"
                            value={access}
                            onChange={e => handleAccess(e.target.value)}
                            className="access"
                        >
                            <MenuItem value={'viewer'} className="">Invite as Viewer</MenuItem>
                            <MenuItem value={'editor'} className="">Invite as Editor</MenuItem>
                            <MenuItem value={'admin'} className="">Invite as Co-Owner</MenuItem>
                        </Select>
                        )}
                        <div className="email-container">
                            {emailList.map((nextE) =>
                                <div>
                                    <Tag tagName={nextE.email} removeTag={removeEmail} color={nextE.type === 'email' ? '' : 'var(--brand-color1)'} />
                                </div>
                            )}
                            </div>
                        </div>
                        { /* ********************************** optional message box */}
                        {(!usePublicLink || shareMode === 'sharelink')  && (emailList.length > 0 || nextEmail.length > 0) && (
                        <TextField
                            id="message"
                            label="Add a personal note"
                            placeholder="Enter an message to include with your invite."
                            className="note"
                            type="text"
                            fullWidth
                            multiline
                            variant='outlined'
                            value={message}
                            rows="4"
                            onChange={e => setPersonalMessage(e.target.value)}
                            style={{marginTop: 24, marginBottom: 24}}
                        /> )}

                        
                        <hr />
                        {/* ****************************** switch and link for public sharing */}
                    {emailList.length === 0 && nextEmail.length === 0 && shareMode === 'invite' && (
                        <>
                        <div style={{textAlign: 'left', marginBottom: 18, width: '100%'}}>
                        <h3>Guest Share Link</h3>
                        <Switch
                            checked={usePublicLink}
                            onChange={handleSelectPublicLink}
                            name="checkedB"
                            color="primary"
                            size="small"

                        />
                        &nbsp;
                        <span style={usePublicLink ? {color: 'var(--brand-color2)'} : null}>Create Link 
                            <Tooltip 
                                title="Create a guest link to share this brief with individuals outside of Breefly"
                                aria-label="Create a guest link to share this brief with individuals outside of Breefly"
                                arrow
                                placement="top-start"
                                >
                                <HelpIcon className="questionIcon" />
                            </Tooltip>
                        </span>

                                </div>
                                {usePublicLink && (
                                    <>
                                    <div className={localClasses.linkBox}>
                                        <a href={publicLink} style={{color: 'var(--brand-color2 )', wordBreak: 'break-all'}}>{publicLink}</a>
                                    </div>
                                    {/* <div className={localClasses.shareicon} style={{marginLeft: `${isMobile ? 0 : 12}`}} onClick={copyToClipboard}>
                                          <FontAwesomeIcon size={'md'} icon={faCopy}  />
                                    </div> */}
                                    <div className={localClasses.shareicon} onClick={shareLinkViaEmail}>
                                          <FontAwesomeIcon size={'md'} icon={faEnvelope}  />
                                    </div>
                                    <div>
                                    <div className={localClasses.sharemessage}>
                                          <FontAwesomeIcon size={'md'} icon={faGlobeAmericas}  />
                                    </div>
                                    Anyone with this link can view and comment on this brief.       
                                    </div>
                                    </>                   
                        )}
                            </>
                        )}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {!usePublicLink && (
                        <>
                      {/* Roles for invitees  moved out of dialog actions*/}
                     {/* {emailList.length > 0 &&  (
                        <div style={{textAlign: 'left', width: '100%', marginBottom: 4, paddingLeft: 17, fontSize: 16}}>
                        <div style={{display: 'inline-block', verticalAlign: 'initial'}}>
                        </div>
                        <Select
                            label="Access level"
                            id="access"
                            value={access}
                            onChange={e => handleAccess(e.target.value)}
                        >
                            <MenuItem value={'viewer'} className={localClasses.pick}>Invite as Viewer</MenuItem>
                            <MenuItem value={'editor'} className={localClasses.pick}>Invite as Editor</MenuItem>
                            <MenuItem value={'admin'} className={localClasses.pick}>Invite as Co-Owner</MenuItem>
                        </Select>
                        </div>
                        )} */}
                            <div style={{width: '100%', color: 'red', marginLeft: 15}}>
                            {emailList.length > 0 && validateEmails() && validateEmails().map((error, index) => {return error && emailList[index].email + ' ' + error + '. ' })}</div>
                            <Button onClick={closeModal} color="primary">
                                <span style={{ fontWeight: 300 }}>Cancel</span>
                            </Button>
                            <Button className="publicShare" onClick={sendInvites} color="primary" type="text" disabled={validateEmails()}>
                                <span>Share</span>
                            </Button>
                        </>
                    )}
                    {shareMode === 'sharelink' && (
                        <>
                        <Button onClick={cancelShareLink} color="primary">
                            <span style={{ fontWeight: 300 }}>Cancel</span>
                        </Button>
                        <Button className="publicShare" onClick={sendPublicInvites} color="primary" disabled={validateEmails()}>
                            Share with Guests
                        </Button>
                        </>
                    )}
                </DialogActions>
         <Snackbar
            open={showSnack}
            autoHideDuration={3000}
            className="success"
            severity="info"
            onClose={handleCloseSnackbar}
            variant={snackType === 'Success' ? "success" : "error"}
            message={snackMessage}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
          />
          {shareError && 
          <SubscriptionModal 
            open={Boolean(shareError)}
            message={shareError}
            onClose={handleErrorClose}
            user={user}
            modalTitle={"sharing"}
          />}
            </Dialog>
        </span>
    );
}
