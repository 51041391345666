import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/styles';
import { Button, Typography, Link } from '@material-ui/core';
import moment from 'moment';
import {PRODUCT_SHORT_NAME} from '../../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
      position: 'fixed',
      top: 'calc(100vh - 150px)',
      left: 0,
      height: 150,
      width: '100%',
      background: '#3d3d3df5',
      color: 'white',
      borderTop: '1px solid #3d3d3d',
      zIndex: 2000,
      padding: 32,
      paddingRight: 140, // make room for intercom!
      fontSize: 16,
  },
  cookieLink: {
      color: '#23b9ea',
      fontWeight: 'bold',
      '&:hover': {
          color: '#26c9ff'
      },
      '&:focus': {
        color: '#26c9ff'
    },
  }
}));

const GDPR = props => {
  const { className, ...rest } = props;
  const expiresInDays = 360;

  const cookieName = `acceptedCookiesOn-${PRODUCT_SHORT_NAME}`;

  const [acceptedCookies, setAcceptedCookies] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  const classes = useStyles();

  const setCookie = () => {

    const d = new Date();
    d.setTime(d.getTime() + (expiresInDays*24*60*60*1000));
    const expires = `expires=${d.toUTCString()}`;
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    document.cookie = `${cookieName}=${new Date().toISOString()};${expires};path=/`;
    setAcceptedCookies(true);
  }

  const getCookie = () => {
    var name = `${cookieName}=`;
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  if( !mounted ) {
      setMounted(true);
    setAcceptedCookies(getCookie() !== '');
  }

  if(acceptedCookies) {
      return null;
  }
  return (
    <div className={clsx(classes.root, className)} >

    <table style={{marginTop: 12}}>
        <tr>
            <td style={{paddingRight: 12}}>
        We’ll use cookies to improve and customize your experience if you continue to browse.

Is it ok if we use cookies to optimize your experience? <br />
<b>Learn more in our <a href='https://www.termsfeed.com/live/0b4108aa-cf17-4b6f-a029-2df4e5d1f0c7' target="_blank" className={classes.cookieLink}>Cookies Policy </a> 
and our <a href='/terms-and-conditions' target="_blank" className={classes.cookieLink}>Privacy Policy</a>.</b>
        </td>
        <td style={{width: 160, verticalAlign: 'top'}}>
    <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={setCookie}

    >
        Yes, accept cookies
    </Button>
    </td>
    </tr>
    </table>
    </div>
  );
};

GDPR.propTypes = {
  className: PropTypes.string
};

export default GDPR;
