import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Select, Menu, MenuItem, IconButton, TextField } from '@material-ui/core';

import { faChartPie, faPencil, faCheckCircle, faEllipsisV, faTimesCircle, faEllipsisVAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateBriefButton from '../../components/CreateBriefButton';
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import BrandedCardTitle from '../OrganizationDetails/components/BrandedCardTitle';
import ChartDisplay from './components/ChartDisplay'
import './style.scss';
import SubscriptionModal from '../../components/SubscriptionModal';


const useStyles = makeStyles(theme => ({
    root: {
        width: 'calc(100vw - 418px)',
        maxWidth: '1062px',
        [theme.breakpoints]: {
            values: { md: 1130 },
        },
        '& .MuiTab-fullWidth': {
            minWidth: 'unset'
        },
        textField: {
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            marginTop: 24,
        },
    },
    mobileCTA: {
        position: 'absolute',
        top: 8,
        right: -24,

    },
    favDesktop: {
        fontSize: 14, color: '#333',
    },
    favMobile: {
        fontSize: 14, color: '#333',
        width: '100%'
    }
}));


export default function ChartBuilder(props) {

    const classes = useStyles();

    const { user, apiManager, history, isMobile, refreshChild, settings } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [init, setInit] = React.useState(false);

    const [showSnackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [toDate, setTo] = React.useState(moment().format('MM/DD/YYYY'));
    const [fromDate, setFrom] = React.useState(moment().subtract(30, 'd').format('MM/DD/YYYY'));
    const [dateRange, setDateRange] = React.useState(52);


    const [selectedDatasetName, setDatasetName] = React.useState('POS Insights Chart Builder');
    const [isOwner, setOwner] = React.useState(true);
    const [dataset, setDataset] = React.useState(false);
    const [currentDataset, setCurrentDataset] = React.useState(null);
    const [category, setCategory] = React.useState('none');
    const [categoryName, setCategoryName] = React.useState('');
    const [geo, setGeo] = React.useState('none');
    const [time, setTime] = React.useState('none');
    const [metrics, setMetrics] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [force, setForce] = React.useState(true);
    const [chartData, setChartData] = React.useState(false);
    const [chartType, setChartType] = React.useState('Donut');
    const [chartTitle, setChartTitle] = React.useState('');
    const [subTitle, setSubtitle] = React.useState('');
    const [isEditingTitle, setIsEditingTitle] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [assetData, setAssetData] = React.useState(false);
    const [doPageUpdate, setDoPageUpdate] = React.useState(false);




    const chartTypes = [
       'Donut', 'Bar',
    ]

    const getIdFromUrl = () => {
        const components = history.location.search.split('=');
        const id = components[components.length - 1];
        return id;
    }

    const loadByAssetId = async(assetId) => {
        const response = await apiManager.getPMIChartAssets(assetId);
        const chartSettings = response.assets[0].settings;
        chartSettings.category = chartSettings.dataset;
      //  setCategory(chartSettings.category)
        setGeo(chartSettings.geo);
        setTime(chartSettings.time);
        setMetrics(chartSettings.metrics);
        setProducts(chartSettings.products);
        setAssetData(chartSettings);
    }

    const getInfo = async (newLimit, newPage, newSearch, isFavoritesOverride) => {

        const assetId = getIdFromUrl();

        setIsLoading(true);
        const datasetInfo = await apiManager.getPMIDatasetsInfo();
        setDataset(datasetInfo && datasetInfo.info && datasetInfo.info.datasets ? datasetInfo.info.datasets : false);

        if( assetId ) {
           await loadByAssetId(assetId);
    
        }
        setIsLoading(false);
    }

    const cancelBuilder = () => {
        history.push(`/visualizer`);
    }

    const updatePage = async (newCategory = null) => {
        // check for valid field data
        if ((category !== 'none' || newCategory) && geo !== 'none' && metrics.length > 0 && time !== 'none' && products.length > 0) {
            setIsLoading(true)
            const chartData = await apiManager.getPMIChartData({
                category: (newCategory || category), geo, time, metrics, products
            });


            setChartData(chartData);
            setIsLoading(false);
        }
    }

    const updateCategory = (e, category = null) => {
 
        const newCategory = typeof category === 'string' ? category : e.target.value;
 
        setCategory(newCategory);

        // get the current data set and set it
        dataset.forEach((set, index) => {
            if( set.category === newCategory) {
                setCategoryName( set.name );
                setCurrentDataset(set);

            }
        })

        updatePage();
    }

    if (assetData && dataset && geo !== 'none' && metrics.length > 0 && time !== 'none' && products.length > 0) {
        if (category === 'none') {
     
            updateCategory(null, assetData.category);
            updatePage(assetData.category)
        }
    }

    if (!init) {
        setInit(true);
        getInfo();
    }



    const updateChartType = (e) => {
        setChartType(e.target.value);
        updatePage();
    }

    const updateGeo = (e) => {
        setGeo(e.target.value);
        updatePage();
    }

    const updateTime = (e) => {
        setTime(e.target.value);
        updatePage();
    }

    const updateMetric = (e) => {
        const selected = e.target.value;

        if (selected === 'none' ) {
            return;
        }
        setIsLoading(true);
     
        const metrics = [selected];
 
        setTimeout(() => {
            setMetrics(metrics);
            setIsLoading(false);
            }, 
        150);

    }

    const updateTitle = (e) => {
        setChartTitle(e.target.value);
    }

    const updateSubtitle = (e) => {
        setSubtitle(e.target.value);
    }

    const updateProducts = (e, oldValue, index) => {
        const selected = e.target.value;
        if (selected === 'none') {
            return;
        }
        if (selected === 'all') {
            products.unshift('all');
            products.length = 1;
            setProducts(products);
            setForce(!force);           
        } else if (selected === 'remove') {
            products.splice(index, 1);
            setProducts(products);
            setForce(!force);
        } else if (!products.includes[selected]) {
            if (index >= 0) {
                products[index] = selected
            } else {
                products.push(selected);
            }
            setProducts(products);
            setForce(!force);
        }
        updatePage();
    }

    const editTitle = (type) => {
        setIsEditingTitle(type);
    }

    // const updateIntercom = (user) => {
    //     window.intercomSettings = {
    //         app_id: "zcl62xdv",
    //         name: `${user.firstname} ${user.lastname}`,
    //         email: `${user.email}`,
    //         created_at: `${user.created}`
    //     }
    //     window.Intercom('boot', window.intercomSettings);
    //     sessionStorage.setItem('intercom', JSON.stringify(window.intercomSettings));
    // }

    // updateIntercom(user);

    const toggleMenu = (event) => {
        if (anchorEl) {
            setAnchorEl(false)
        } else {
            setAnchorEl(event.currentTarget);
        }
      };

    const closeModal = () => {
        // setCreateDialogOpen(false);
    }

    const [createError, setCreateError] = React.useState('')
    const createClick = async () => {
        setIsLoading(true)

        // create a brief with a default chart for now...
        const brief = {
            title: 'PMA Insite Chart Brief',
            tags: [],
            briefcase: { value: 0 },
            components: [
                {
                    "type": (0),
                    "content": {
                        "text": "Enter your title..."
                    },
                    "position": (0)
                },
                {
                    "type": (4),
                    "content": {
                        "title": "This is a PMA Insite chart",
                        "subTitle": "Chart subtitle",
                        "chartType": "pie",
                        "size": (30),
                        "source": "user",     // could be plugin/url
                        "dataset": {
                            organization: user.org,
                            plugin: "pma1",
                            category: "",
                            subcategory: "",
                            dateFrom: "",
                            dateTo: "",
                            keys: [],
                        },
                        "dataPoints": [
                            {
                                "y": 79.45,
                                "label": "Apples",
                                "x": (0)
                            },
                            {
                                "y": 7.31,
                                "label": "Bananas",
                                "x": (1)
                            },
                            {
                                "y": 7.06,
                                "label": "Grapes",
                                "x": (2)
                            },
                            {
                                "y": 4.91,
                                "label": "Oranges",
                                "x": (3)
                            },
                            {
                                "y": 1.26,
                                "label": "Others",
                                "x": (4)
                            }
                        ],
                    },
                    "position": (1),
                    key: uuidv4()
                }
            ]
        }
        const result = await apiManager.createBrief(brief);
        if (result && result.insertedCount > 0) {
            //history.push(`/brief/edit/${result._id}`);
            closeModal();
            window.location = `/brief/edit/${result._id}`;
        }
        else {
            if(result && result.msg) {
                setCreateError(result.msg)
            } else {
                //   alert(result && result.msg ? result.msg : 'An error has occurred')
                setSnackMessage(result && result.msg ? result.msg : 'An error has occurred');
                setSnackBar(true);
            } 
        }
        setIsLoading(false)
    }

    const viewDetails = (id) => {
        history.push(`/brief/details/${id}`)
    }

    const handleSelectDataSet = (setId) => {
        setDateRange(setId);
    }

    const saveAsAsset = async (e) => {
        setAnchorEl(false);
    }

    const renderSettingsPanel = () => {
        if( isLoading) {
            return null;
        }
        return (
            <div className="settings">
                <div>
                    <FontAwesomeIcon size={'sm'} icon={faChartPie} /> Chart Settings
                </div>
                <div className={'form'}>
                      {/* Chart Type */}
                        <div className='box'>
                        <div className='label'>Chart Type</div>
                        <Select
                            className='selector'
                            name="align"
                            variant="outlined"
                            value={chartType}
                            onChange={updateChartType}
                        >
                            {chartTypes.map(type => (
                                <MenuItem value={type}  style={{ fontSize: 14 }}>{type}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {/* Category */}
                    <div className='box'>
                        <div className='label'>Category</div>
                        <Select
                            className='selector'
                            name="align"
                            variant="outlined"
                            value={category}
                            onChange={updateCategory}
                        >
                            <MenuItem style={{ fontSize: 14 }} value='none'>
                                <em>Category...</em>
                            </MenuItem>
                            {dataset.map((set) => (
                                <MenuItem style={{ fontSize: 14 }} value={set.category} >{set.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {/* don't show other selectors unless there is a category */}
                    {category !== 'none' && (
                        <>
                            {/* Geography */}
                            < div className='box'>
                                <div className='label'>Geography/Location</div>
                                <Select
                                    className='selector'
                                    name="align"
                                    variant="outlined"
                                    value={geo}
                                    onChange={updateGeo}
                                >
                                    <MenuItem style={{ fontSize: 14 }} value='none'>
                                        <em>Geography...</em>
                                    </MenuItem>
                                    {currentDataset.geos.map(geo => (
                                        <MenuItem style={{ fontSize: 14 }} value={geo}>{geo}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                            {/* Period */}
                            <div className='box'>
                                <div className='label'>Time Period</div>
                                <Select
                                    className='selector'
                                    name="align"
                                    variant="outlined"
                                    value={time}
                                    onChange={updateTime}
                                >
                                    <MenuItem style={{ fontSize: 14 }} value='none'>
                                        <em>Period...</em>
                                    </MenuItem>
                                    {currentDataset.times.map(time => (
                                        <MenuItem style={{ fontSize: 14 }} value={time}>{time}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                            {/* Metric */}
                            <div className='box'>
                                <div className='label'>Metric</div>
                                <Select
                                    className='selector'
                                    name="align"
                                    variant="outlined"
                                    value={metrics.length === 0 ? 'none' : metrics[0]}
                                    onChange={updateMetric}
                                >
                                    <MenuItem style={{ fontSize: 14 }} value='none'>
                                        <em>Metric...</em>
                                    </MenuItem>
                                    {currentDataset.metrics.map(metric => (
                                        <MenuItem style={{ fontSize: 14 }} value={metric}>{metric.replace(/\\/g, '')}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                            {/* Products */}
                            <div className='box'>
                                <div className='label'>Product(s)</div>
                                {products.map((selectedProduct, index) => (
                                    <div className='box'>
                                        <Select
                                            className='selector'
                                            name="align"
                                            variant="outlined"
                                            value={products[index]}
                                            onChange={(e) => updateProducts(e, selectedProduct, index)}
                                        >
                                            <MenuItem style={{ fontSize: 14 }} value='remove'>
                                                <em>Remove...</em>
                                            </MenuItem>
                                            <MenuItem style={{ fontSize: 14 }} value='all'>
                                                <em>All {categoryName}</em>
                                            </MenuItem>
                                            {currentDataset.products.map(product => (
                                                <MenuItem style={{ fontSize: 14 }} value={product}>{product}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                ))
                                }
                                {products[0] !== 'all' && (
                                <Select
                                    className='selector'
                                    name="align"
                                    variant="outlined"
                                    value={'none'}
                                    onChange={(e) => updateProducts(e, 'none', -1)}
                                >
                                    <MenuItem style={{ fontSize: 14 }} value='none'>
                                        <em>Add Product...</em>
                                    </MenuItem>
                                    <MenuItem style={{ fontSize: 14 }} value='all'>
                                        <em>All {categoryName}</em>
                                    </MenuItem>
                                    {currentDataset.products.map(product => (
                                        <MenuItem style={{ fontSize: 14 }} value={product}>{product}</MenuItem>
                                    ))}
                                </Select>
                                )}
                            </div>
                        </>
                    )}
                    {/* <div className='upload'>
                    {isOwner && (
                        <div className='upload'>
                            <Button variant='contained' color="primary" size="small">Upload Data Set</Button>
                        </div>
                    )}
                    </div> */}

                            
                </div>
            </div>
        )
    }

    const render = () => {
        return (
            <div className={`${classes.root} viz branded`}>
                { /* main CTA and searchbar */}
                {/* <div className={classes.searchbar}> */}
                {/* <Search setPage={setPage} getBriefs={getBriefs} classes={classes} limit={limit} page={page} refreshChild={refreshChild}/> */}
                <div className={'pageTitle'}>
                    {selectedDatasetName}
                    {/* </div> */}
                    <Button key="editTitle" aria-label="editTitle" color="inherit" onClick={cancelBuilder} style={{ float: 'right', marginTop: -20, color: 'gray' }}>
                                     <span style={{fontWeight:400, fontSize: 14 }}>close</span>&nbsp;<FontAwesomeIcon size={'lg'} icon={faTimesCircle} />
                    </Button>
                </div>

                {isLoading ?
                    < Grid
                        container
                        spacing={2}
                        style={{ display: !isLoading ? 'none' : 'block' }}
                    >
                        {/* loading indicator */}
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            key={Math.random()}

                        >
                            <LinearIndeterminate />
                        </Grid>
                    </Grid>
                    :
                    <>
                      <div className={'chartPanel'}>
                        {/* chart */}
                        {chartData && (

                            <div style={{ marginTop: 0, width: '100%', textAlign: 'left', fontSize: 16 }}>
                              {/* title */}
                                {isEditingTitle === 'title' ?
                                    <>
                                    <TextField 
                                        id="chart-title"
                                        // label="Filled" 
                                         variant="filled" 
                                         helperText="Title for this chart"
                                         margin="dense"
                                         name="chartTitle"
                                         onChange={updateTitle}
                                         required
                                         value={chartTitle}
                                         />
                                         <IconButton key="editTitle" aria-label="editTitle" color="inherit" onClick={() => setIsEditingTitle(false)} style={{ marginTop: 14, fontSize: 18 }}>
                                            <FontAwesomeIcon size={'lg'} icon={faCheckCircle} />
                                        </IconButton>
                                        {/* <IconButton key="editTitle" aria-label="editTitle" color="inherit" onClick={() => editTitle('title')} style={{ marginTop: 14, fontSize: 18 }}>
                                            <FontAwesomeIcon size={'lg'} icon={faTimesCircle} />
                                        </IconButton> */}
                                      </>
                                    :
                                    <>
                                        {chartTitle !== ''
                                            ? <span style={{ fontWeight: 600, fontSize: 18 }}>{chartTitle}</span>
                                            : <span style={{ fontWeight: 600, fontSize: 18 }}>{categoryName}: {geo}</span>
                                        }
                                        <IconButton key="editTitle" aria-label="editTitle" color="inherit" onClick={() => editTitle('title')} style={{ fontSize: 16, marginTop: -10 }}>
                                            <FontAwesomeIcon size={'sm'} icon={faPencil} />
                                        </IconButton>
                                    </>
                                }
                                <br />
                                {isEditingTitle === 'subTitle' ?
                                    <>
                                                                        <TextField 
                                        id="chart-title"
                                         variant="filled" 
                                         helperText="Subtitle for this chart"
                                         margin="dense"
                                         name="chartTitle"
                                         onChange={updateSubtitle}
                                         required
                                         value={subTitle}
                                         />
                                         <IconButton key="editSubtitle" aria-label="editSubtitle" color="inherit" onClick={() => setIsEditingTitle(false)} style={{ marginTop: 14, fontSize: 18 }}>
                                            <FontAwesomeIcon size={'lg'} icon={faCheckCircle} />
                                        </IconButton>
                                        {/* <IconButton key="editTitle" aria-label="editTitle" color="inherit" onClick={() => editTitle('title')} style={{ marginTop: 14, fontSize: 18 }}>
                                            <FontAwesomeIcon size={'lg'} icon={faTimesCircle} />
                                        </IconButton> */}
                                    </>
                                    :
                                    <>
                                        {subTitle !== '' 
                                          ? <span>{subTitle}</span>
                                          : <span>{metrics[0].replace(/\\/g, "")} - {time.replace('w/e', 'weeks ending')}</span>
                                        }
                                        <IconButton key="editSubtitle" aria-label="editSubTitle" color="inherit" onClick={() => editTitle('subTitle')} style={{ fontSize: 16, marginTop: -10 }}>
                                            <FontAwesomeIcon size={'sm'} icon={faPencil} />
                                        </IconButton>
                                    </>
                                }
                                <div className={'chartMenu'}>
                                        <IconButton key="menuIcon" aria-label="menuIcon" color="inherit"  aria-haspopup="true"onClick={toggleMenu} style={{ fontSize: 18 }}>
                                            <FontAwesomeIcon size={'lg'} icon={faEllipsisV} />
                                        </IconButton>
                                        <Menu
                                            id="chart-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={toggleMenu}
                                        >
                                            <MenuItem style={{fontSize: 14}} onClick={saveAsAsset}>Save on Dashboard</MenuItem>
                                            <MenuItem style={{fontSize: 14}} onClick={toggleMenu}>Create brief</MenuItem>
                                            <MenuItem style={{fontSize: 14}} onClick={cancelBuilder}>Discard</MenuItem>
                                        </Menu>
                                </div>
                            </div>
                        )}
                        {!chartData && (
                            <div style={{ marginTop: 100, textAlign: 'center', fontSize: 14, fontStyle: 'italic' }}>Please complete the chart settings on the right</div>
                        )}
                        <div id='builderDiv' style={{ marginTop: 40, height: 450, textAlign: 'center', fontSize: 14 }}>
                            {chartData && (
                                <ChartDisplay 
                                    chartDiv={'builderDiv'} 
                                    chartSettings={{
                                            type: chartType,
                                            category,
                                            geo,
                                            time,
                                            metrics,
                                            products
                                    }} 
                                    chartData={chartData} 
                                    appSettings={settings}
                                    
                                    />
                            )}
                        </div>
                      </div>
                        {renderSettingsPanel()}
                    </>

                }
                <Snackbar
                    open={showSnackBar}
                    autoHideDuration={3000}
                    className="error"
                    severity="info"
                    onClose={() => setSnackBar(false)}
                    variant="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="error">{snackMessage}</Alert>
                </Snackbar>
                {createError && 
                    <SubscriptionModal 
                    open={Boolean(createError)}
                    message={createError}
                    onClose={() => setCreateError('')}
                    user={user}
                    modalTitle={"briefs"}
                    />
                }
            </div>
        );
    }

    return (render());
};


