//This component handles all video upload
//Pass props 'replace' if replacing a video
//See EditBrief.js for replace example

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import dropIcon  from "../../../images/Breefly_Icons-DragDrop.svg"
import {
  Grid,
  Button,
  IconButton,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@material-ui/core";

import {
  faPlay,
  faPlusCircle,
  faCloudUploadAlt
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiManager from "../../../ApiManager";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  button: {
    //backgroundColor: "#22AEE8",
    width: 184,
    height: 40,
    color: "#ffffff!important",
    "&:hover": {
      backgroundColor: "#0A1241"
    }
  }
}));

export default function AddVideo(props) {
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(2);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const { isOpen, handleSave, handleClose, replace } = props;
  const [assets, setAssets] = React.useState([]);
  const [selectedAsset, setSelectedAsset] = React.useState(false);
  const [file, setFile] = React.useState(false);
  const [url, setUrl] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  const getAssets = async () => {
    const userAssets = await apiManager.getAssets(false, {
      limit: 25,
      skip: 0
    });
    const { assets, count } = userAssets;
    setAssets(assets);
  };
  if (isOpen == true && open == false) {
    setOpen(true);
    setSelectedAsset(false);
    setUrl(false);
    getAssets();
  } else if (isOpen == false && open == true) {
    setOpen(false);
  }

  const addVideo = async () => {
    setOpen(false);
    let imageUrl = url;
    let isNew = false;
    let s3Info = {};
    if (file && file.type) {
      s3Info = await apiManager.getUploadKey(file);

      await apiManager.uploadToS3(s3Info.url, file, s3Info.assetId);

      //const asset = await apiManager.setAsset(s3Info.assetId);

      //if(asset){
      //    imageUrl = asset.url;
      //}
    }
    //if(!s3Info.assetId)
    await handleSave({
      url: isNew ? "" : imageUrl,
      alt: "",
      assetId: s3Info.assetId ? s3Info.assetId : selectedAsset._id
    });
  };

  const openModal = async () => {
    const userAssets = await apiManager.getAssets(false, {
      limit: 25,
      skip: 0
    });
    const { assets, count } = userAssets;
    setAssets(assets);
    setOpen(true);
    props.closePanel();
  };

  const closeModal = () => {
    if (replace) {
      handleClose();
    }
    setUrl(false);
    setOpen(false);
    setSelectedAsset({});
  };

  const selectVideo = image => {
    setSelectedAsset(image);
    setUrl(image.url);
  };

  const captureFile = acceptedFile => {
    const file = acceptedFile[0];
    try {
      setUrl(URL.createObjectURL(file));
      setFile(file);
      setSelectedAsset(false);
      let newVideo = {
        name: file.name,
        url: URL.createObjectURL(file)
      };
      setAssets(assets => [newVideo, ...assets]);
      selectVideo(newVideo);
    } catch (ex) {}
  };

  const RenderUploadFile = props => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections
    } = useDropzone({
      accept: "video/mp4",
      onDrop: acceptedFiles => {
        captureFile(acceptedFiles);
      }
    });
    return (
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        {...getRootProps({})}
        className="photo-upload-holder"
      >
        <input {...getInputProps()} />
        <Grid item xs={12}>
          {/* <FontAwesomeIcon icon={faPlusCircle} /> */}
          <img src={dropIcon} alt="drag and drop here" width="120px" align="middle" />
        </Grid>
        <Grid item xs={12} style={{marginTop: -8, marginBottom: 16}}>
          <span>Drag and drop here to upload</span>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 16}}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            className={"brandColor2"}
          >
            <FontAwesomeIcon
              icon={faCloudUploadAlt}
              style={{ marginRight: 8 }}
            />
            <span>Or choose your files</span>
          </Button>
        </Grid>
      </Grid>
    );
  };
  // CURRENTLY UNUSED
  const RenderImagePreview = () => {
    return url && url.length > 0 ? (
      <>
        <Grid container justify="center">
          <video
            src={url}
            controls
            style={{
              maxWidth: "300px",
              maxHeight: "300px",
              paddingTop: "20px",
              margin: "0 auto",
              paddingBottom: 24
            }}
          >
            Your browser does not support the video tag.
          </video>
          {/* <div style={{ marginTop: 32 }}>
                        <Button onClick={addVideo} color="primary">
                            Add Video
                        </Button>
                    </div> */}
        </Grid>
        <div
          style={{ width: "100%", margin: 24, borderTop: "1px solid #ddd" }}
        />
      </>
    ) : (
      <Grid container justify="center" spacing={spacing}>
        <FontAwesomeIcon icon={faPlay} />
      </Grid>
    );
  };

  const RenderAssetGrid = props => {
    const { video } = props;
    if (!video || video.length === 0) {
      return null;
    }
    return video.map(value => (
      <Grid
        item
        xl={4}
        lg={4}
        sm={4}
        xs={12}
        // spacing={spacing}
        // style={{textAlign: 'center'}}
        key={Math.random()}
        style={{ minHeight: 180 }}
      >
        {/* <div onClick={e => selectVideo(value)} style={{ position: 'relative' }}>
                    <video src={value.url} controls style={{ maxWidth: '150px', maxHeight: '150px', zIndex: 0 }}>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div style={{ position: 'relative' }}>
                    <div onClick={e => selectVideo(value)} style={{ position: 'absolute', height: '100px', width: '100%', background: 'transparent', zIndex: 1, top: '-90px' }}></div>
                </div> */}
        <div
          onClick={e => selectVideo(value)}
          style={{
            border: ` 3px solid ${
              value === selectedAsset ? "lightblue" : "#ddd"
            }`,
            padding: 5,
            paddingBottom: 0,
            textAlign: "center",
            maxWidth: 200,
            maxHeight: 200
          }}
        >
          {/* <div style={{ position: "relative", madWidth }} > */}
          <video
            className="videoAsset"
            src={value.url}
            controls
            style={{
              margin: "0 auto",
              outline: "none"
            }}
          >
            Your browser does not support the video tag.
          </video>
          {/* <div onClick={e => selectVideo(value)} className="videoAssetCover">
              Select Video
            </div> */}
          {/* </div> */}
        </div>
      </Grid>
    ));
  };
  useEffect(() => () => {
    assets.forEach(el => URL.revokeObjectURL(el.preview));
  }, [assets]);
  return (
    <div className={classes.root}>
      {!replace && (
        <IconButton
          aria-label="close"
          className={classes.icon}
          onClick={openModal}
        >
          <FontAwesomeIcon icon={faPlay} />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <div style={{ padding: 25 }}>
          <DialogTitle id="form-dialog-title" >
            Add / Replace a video
          </DialogTitle>
          <DialogContent>
            <RenderUploadFile />
            <hr style={{marginTop: 40}}/>
              <div
                style={{
                  fontSize: 14,
                  margin: "12px 0px",
                  paddingTop: 10,
                  fontWeight: 400,
                  textAlign: "center"
                }}
              >
                Or select a video (Only mp4 supported):
              </div>
            <Grid
              style={{ maxHeight: "300px" }}
              container
              justify="flex-start"
              alignItems="flex-start"
              spacing={spacing}
            >
              <RenderAssetGrid video={assets} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              <span style={{ fontWeight: 300 }}>Cancel</span>
            </Button>
            <Button
              onClick={addVideo}
              color="primary"
              variant="text"
              disabled={selectedAsset === false}
            >
              <span style={{ color: `${selectedAsset ? "#333" : "#ccc"}` }}>
                {replace ? "Change Video" : "Add Video"}
              </span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
