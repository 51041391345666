//NO LONGER IN USE
//USE ADD VIDEO FROM MODAL COMPONENTS
//PASS IN PROP REPLACE IF REPLACING A VIDEO
//SEE ADD PANEL FOR EXAMPLE

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Grid,
    Button,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiManager from '../../../ApiManager';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

export default function ChangeVideo({ isOpen, handleSave, handleClose }) {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const [assets, setAssets] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(false);
    const [file, setFile] = React.useState(false);
    const [url, setUrl] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const getAssets = async () => {
        const userAssets = await apiManager.getAssets(false, { limit: 25, skip: 0 });
        const { assets, count } = userAssets;
        setAssets(assets);
    }

    if (isOpen == true && open == false) {
        setUrl(false);
        setOpen(true);
        setSelectedAsset(false);
        getAssets();
    }
    else if (isOpen == false && open == true) {
        setOpen(false)
    }




    const addPhoto = async () => {
        setOpen(false);
        let imageUrl = url;
        let s3Info = {};
        if (file && file.type) {

            s3Info = await apiManager.getUploadKey(file);

            await apiManager.uploadToS3(s3Info.url, file);

            await apiManager.getAsset(s3Info.assetId);

        }
        handleSave({ url: imageUrl, alt: '', assetId: s3Info.assetId ? s3Info.assetId : selectedAsset._id });
    }



    const selectImage = (image) => {

        setUrl(image.url);
        setSelectedAsset(image);
        //handleSave({ url: image.url, alt: '', assetId: image.assetId });
    }

    const captureFile = (event) => {

        console.log(event.target.files)
        const file = event.target.files[0];
        

        var regex = new RegExp(/(?:jpg|gif|png)/i);
        const match = regex.test(file.type)
        if (true || match === true) {
            setUrl(URL.createObjectURL(file))
            setFile(file)
            setSelectedAsset({});
            
        }
        else {
            //setMessage('This is not an image... Please upload an image')

        }

    }

    const renderUploadFile = () => {
        return (<Button
            variant="contained"
            component="label"
            color="secondary"
            style={{border: '1px solid #ddd', marginTop: 8, maxHeight: 93}}
        >
            <input
                type="file"
                style={{ display: "none" }}
                onChange={captureFile}
                accept=".mp4"
            />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div style={{ fontSize: '36px', lineHeight: '35px', fontWeight: 100 }}>
                +
            </div>
            <div style={{lineHeight: '20px'}}>Upload a Video</div>
        </div>
        </Button>)
    }

    const renderImagePreview = () => {

        return (url && url.length > 0 ?
            <>
                <Grid
                    container justify="center"
                >
                    <video controls style={{ width: `300px`, maxHeight: 80 }} >
                        <source src={url} />
    Your browser does not support the video tag.
  </video>
                    {/* <div style={{ marginTop: 32 }}>
                        <Button onClick={addPhoto} color="primary">
                            Change Video
                        </Button>
                    </div> */}
                    <div style={{ width: '100%', margin: 24, borderTop: '1px solid #ddd' }} />
                </Grid>
            </>
            : <Grid
                container justify="center"
                spacing={spacing}
            ><FontAwesomeIcon icon={faPlay} /></Grid>)
    }

    const renderAssetGrid = () => {
        const html = (assets.map(function (value) {
            const { url } = value;
            return <Grid
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                // spacing={spacing}
                key={Math.random()}
                // style={{ textAlign: 'center' }}
            >
                <div style={{border:` 2px solid ${value === selectedAsset ? 'lightblue' : 'white'}` , padding: 1, minWidth: 150, minHeight: 90, textAlign: 'center'}}>
                <div onClick={e => selectImage(value)} style={{ position: 'relative' }}>
                    <video style={{ width: `144px`, maxHeight: 80 }}  >
                        <source src={url} />
                            Your browser does not support the video tag.
                        </video>
                </div>
                <div style={{ position: 'relative' }}>
                    <div onClick={e => selectImage(value)} style={{ position: 'absolute', height: '90px', width: '100%', background: 'transparent', zIndex: 1, top: '-90px' }}></div>
                </div>
                </div>
            </Grid>
        }))
        return html;
    }

    return (
        <span>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <div style={{ padding: 25 }}>
                    <DialogTitle id="form-dialog-title">Replace Video</DialogTitle>
                    <DialogContent>

                        <Grid
                            container justify="center" spacing={spacing}
                        >
                                {/* {renderImagePreview()}
                                {renderUploadFile()} */}

                        </Grid>
                        <Grid

                            lg={12}
                            sm={12}
                            xl={12}
                            xs={12}
                        >

                          <div style={{ fontSize: 14, marginBottom: 16, paddingTop: 6, fontWeight: 400 }}>Select a video:</div>
     
                        </Grid>
                        <Grid
                            style={{ maxHeight: '300px' }}
                            container justify='left' spacing={spacing}
                        >
                            {renderUploadFile()} 
                            {assets && assets.length > 0 && (
                                <>
                                    {renderAssetGrid()}
                                </>
                            )}
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            <span style={{ fontWeight: 300 }}>Cancel</span>
                        </Button>
                        <Button 
                            onClick={addPhoto} 
                            color="primary"
                            variant="outlined"
                            disabled={selectedAsset === false}
                            >
                            <span style={{ color: `${selectedAsset ? '#333' : '#ccc'}`}}>
                              Replace with Selected Video
                            </span>
          </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </span>
    );
}
