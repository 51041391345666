/* /PUBLIC/BRIEFDETAIL.js is almost identical to the component. Please ensure that any changes to the way briefs are displayed here are also made to the /PUBLIC/BRIEFDETAIL file */

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import {
    Grid,
    IconButton,
    TextField,
    Box
} from '@material-ui/core';
import RightbarDetail from './components/RightbarDetail'
import Notification from '../Modals/Notification';
import Rightbar from '../Public/components/Rightbar'
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate'

import { TagBrief } from '../Modals';
import Chart from '../Brief/components/Chart';
import 'typeface-roboto';
import CardMenu from '../Briefs/components/CardMenu';
import CreateBriefButton from '../../components/CreateBriefButton';

import InviteExistingUser from '../Brief/components/InviteExistingUser'

import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt, faHeart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegisterSnackBar from '../SignIn/components/RegisterSnackBar';
import { mostReadable } from '@ctrl/tinycolor'
import InviteSuccess from './components/InviteSuccess'

import ChartDisplay from '../Visualizations/components/ChartDisplay';
import SentimentChart from '../Visualizations/SentimentChart';
import SubscriptionModal from '../../components/SubscriptionModal';
import { faComment  } from "@fortawesome/free-solid-svg-icons";
import eventTracker from '../../utils/event-tracker';

const DEFAULT_SNACK = {
    isOpen: false,
    message: '',
    isSuccessfull: false
}

const styles = (theme => ({
    root: {
        //   padding: theme.spacing(4),
        //   minHeight: '90vh'
    },
    img: {
        maxWidth: '600px',
        '@media (max-width: 500px)': {
            maxWidth: 300,
        },
    },
    searchbar: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        height: 100,
        paddingTop: 8,
        '@media (max-width: 650px)': {

        },
    },
    searchform: {
        width: 600,
        '@media (max-width: 1025px)': {
            width: 500,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 725px)': {
            width: '50%',
            minWidth: 150,
        },
        '@media (max-width: 650px)': {
            width: '55%',
            minWidth: 150,
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            width: 100,
            marginLeft: 42,
        },
    },
    briefArea: {
        width: 630,
        maxWidth: 630,
        '@media (max-width: 1025px)': {
            width: 500,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 850px)': {
            width: '100%',
            minWidth: 150,
        },
        '@media (max-width: 500px)': {
            width: '80%',
            minWidth: 150,
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 24,
        marginLeft: 16
    },
    author: {
        fontWeight: 350,
        fontSize: 16,
        left: 0,
    },
    controlBlock: {
        marginBottom: 48,
        width: 630,
        '@media (max-width: 1025px)': {
            width: 530,
        },
        '@media (max-width: 935px)': {
            width: '60%',
        },
        '@media (max-width: 850px)': {
            width: '100%',
            minWidth: 150,
        },
        '@media (max-width: 500px)': {
            width: 'calc(100% - 42px)',
            minWidth: 150,
        },
    },
    commentButton: {
        position: 'fixed',
        right: '25px',
        top: '25px',
        '@media (max-width: 500px)': {
            right: '16px'
        },
    },
    commentButtonMentions: {
      position: 'fixed',
      right: '68px',
      top: '65px',
      backgroundColor: 'var(--brand-color2)',
      borderRadius: '50%',
      width: 20,
      height: 20,
      color: 'white',
      fontSize: '10px',
      letterSpacing: '0,025em',
      paddingTop: 3,
      textAlign: 'center',
      fontWeight: 500,
      '@media (max-width: 500px)': {
          right: '16px'
      },
    },
    paragraph: {
       // fontWeight: 500,
        fontSize: 14,
    }
}));

const BriefDetail = props => {
    const { history, apiManager, classes, isMobile, user, refreshChild } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalOpen, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);

    const [brief, setBrief] = React.useState(false);
    const [id, setId] = React.useState('');
    const [funcs, setFuncs] = React.useState(false);
    const [isOpenRightbar, setOpenRightbar] = useState(false);
    const [isRightOpen, setIsRightOpen] = useState(false);

    const [settings, setSettings] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [access, setAccess] = React.useState('viewer');
    //const access = brief && brief.invite ? brief.invite.access : 'admin';
    const [isInviteSuccessOpen, setIsInviteSuccessOpen] = React.useState(false);

    const [comments, setComments] = React.useState('');

    const [snack, setSnack] = useState(DEFAULT_SNACK)
    const [canEdit, setCanEdit] = React.useState(true);
    const [Author, setAuthor] = useState('');
    const [mentions, setMentions] = React.useState(false);

    useEffect(() => {
        checkForSubscription()
    },[])

    useEffect(() => {
        if(brief && brief.Owner && brief.Owner.username == user.username){
                setAccess('admin')
        }
        else if(brief && brief.invite){
            setAccess(brief.invite.access)
        }
    },[brief])
    // const updateIntercom = (user) => { 
    //     window.intercomSettings = {
    //       app_id: "zcl62xdv",
    //       name: `${user.firstname} ${user.lastname}`, 
    //       email: `${user.email}`,
    //       created_at: `${user.created}` 
    //     }
    //     window.Intercom('boot', window.intercomSettings); 
    //   }

    const closeSnackModal = async () => {
            setSnack({...snack, isOpen: false});
            refreshChild();
    }

    const openModal = async (isSuccessfull, message) => {
        setSnack({...snack, isOpen: true, isSuccessfull: isSuccessfull, message: message});
        refreshChild();
        refreshChild();
    }

    const handleSearch = async (event) => {
        const search = event.target.value;
        setSearch(search);
        if (search.length > 2 || search.length == 0) {
            const { limit } = this.state;
            await this.getBriefs(null, null, search);
        }
    }

    const addComment = async (comment, commentText, newMentions) => {
        const insert = {
            brief: id,
            comment,
            commentText,
            mentions: newMentions,
        }

        const result = await apiManager.createComment(insert);

        const comments = await apiManager.getComments(id);
        eventTracker('New Comment', user, 'brief-detail');
        setComments(comments);
    }

    const dislikeComment = async (comment) => {
        await apiManager.updateComment(comment, false);
        const comments = await apiManager.getComments(id);
        setComments(comments);
    }

    const likeComment = async (comment) => {
        await apiManager.updateComment(comment, true);
        const comments = await apiManager.getComments(id);
        setComments(comments);
    }

    const openRightbar = () => {
        setFuncs({
            addComment,
            likeComment,
            dislikeComment
        })
        setIsRightOpen(true)
    }

    const onRightClose = () => {
        setIsRightOpen(false)
    }


    const handleRightbarClose = () => {
        setOpenRightbar(false);
    };

    const closeModal = () => {
        setOpen(false)
    }

    const getBrief = async () => {
        let brief = {};

        setIsLoading(true);
        if (id.length == 0) {
            const urlId = getIdFromUrl();
            await setId(urlId);
            brief = await apiManager.getBrief(urlId);
        }
        else {
            brief = await apiManager.getBrief(id);
        }


        
        if (brief) {
            setBrief(brief)
            await setId(brief._id);
            const comments = await apiManager.getComments(brief._id);
            setComments(comments);
            if(comments && comments.length > 0) {
              let m = 0;
              comments.forEach(comment => {
                if(comment.mentions && comment.mentions.includes(user.email)) {
                  m++;
                }
              })
              setMentions(m > 0 ? m : false);

              // USING COUNT OF COMMENTS RATHER THAN MENTIONS
              setMentions(comments.length);
           
            }
            setIsLoading(false);
            setAuthor(`${brief.Owner.firstName} ${brief.Owner.lastName}`)
        }
        else {
            setTimeout(function () {
                getBrief();
            }, 1000)
        }

    }

    const renderCommentsButton = () => {
      return (
        <div className='commentButton' className={classes.commentButton}>
          <IconButton style={{ color: "#fff", backgroundColor: "#000055" }} aria-label="close" className={classes.icon} onClick={openRightbar} >
            <FontAwesomeIcon size={'lg'} icon={faCommentAlt} style={{ paddingTop: 4 }} />
          </IconButton>
          {mentions && (
            <div className={classes.commentButtonMentions}>
              {mentions}
            </div>
          )}
        </div>);
    }
    const checkForSubscription = () => {
        if (user && user.subscriptions && user.subscriptions.length > 0) {
        //   const activeSubscription = user.subscriptions.filter(subscription => subscription.active === 1)
        //   activeSubscription && activeSubscription.length === 0 && setCanEdit(false)
        } 
      }

    const [editError, setEditError] = useState('')
    const clickEdit = () => {
        if (canEdit) {
            history.push(`/brief/edit/${id}`)
        }
        else {
            setEditError("Subscription Expired. Please subscribe to continue editing briefs")
        }
    }



    const updateTags = async (tags) => {
        brief.tags = tags.split(',')
        brief.tags = brief.tags.map((tag) => {
            return tag.trim();
        })

        await apiManager.updateBrief(brief);
        setBrief(brief);
    }

    const addFavorite = async () => {
        let favorite = brief.favorite ? brief.favorite : { isFavorite: false, brief: brief._id };
        favorite.isFavorite = !favorite.isFavorite;
        await apiManager.updateFavorites(favorite);
        setBrief({...brief, favorite})
    }

    const renderNotAvailable = () => {
        return (<div>Brief is not available, refresh the page</div>)
    }

    const renderTitle = (data, key) => {
        const { text } = data;
        return (
          <div key={key} style={{ width: '100%', marginBottom: 32 }} >
            <div className={classes.title}>
                {text}
            </div>
        </div>
        )
    }
    const renderImage = (data, key) => {
        const { url, alt, assetId, size, align } = data;
        return (<div key={key} style={{ width: '100%', textAlign: align, padding: '20px 0px' }}>
            <img src={url} alt={alt} className={classes.img} style={{ width: size }} />

        </div>)
    }
    const renderParagraph = (data, key) => {
        const { text } = data;
        return (<div key={key} style={{width: '100%'}} >


            <div dangerouslySetInnerHTML={{ __html: `${text}` }} style={{ width: '100%', padding: '20px' }} className={classes.paragraph} />

        </div>)
    }
    const renderVideo = (data, key) => {
        const { url, alt, assetId, size, align } = data;

        let wstyle = { width: `${size}px` };
        
        if (size == "100%" || size == "75%" || size == "50%" || size == "25%"){
            wstyle = {width: `${size}`}
        }
        if (isMobile) {
            wstyle = { maxWidth: 300 }
        }
        let isThumbnail = false;
        if(url && (url.indexOf('.png') > -1 || url.indexOf('.jpg') > -1 || url.indexOf('.jpeg') > -1)){
            isThumbnail = true;
        }

        let output = (<video alt={alt} src={url} controls style={wstyle} alt={alt}>Your browser does not support the video tag.</video>)

        if(isThumbnail){
            output = <img src={url} width={600} alt={alt}/>
        }

        return (url ? <div key={key} style={{ textAlign: `${align}`, width: '100%', padding: '20px 0px' }}>
            { output }
        </div> : null)
    }
    const renderChart = (data, key) => {
        return (<Box 
        key={key} alignSelf="stretch"><div style={{ textAlign: 'center', margin: "24px 0px"}}>
            <Chart title={data.title} subtitle={data.subtitle} chartType={data.chartType} dataPoints={data.dataPoints} size={data.size} chartColor={data.chartColor} unit={data.unit} key={key}/>
        </div>
        </Box>)
    }

    const renderCustomChart = (component, key) => {
        const { assetId, align, customSettings, settings} = component;
        const size = component.dataset && component.dataset === 'sentiment' ? component.size+120 : component.size;
          return (
              <div style={{ position: 'relative', width: '100%', padding: 12, background: 'white', marginBottom: 32 }} key={key} >
                    <div id={`chartDivTitle-${assetId}`}></div>
                    <div id={`chartDiv-${assetId}`} style={{ marginTop: 14, minHeight: size, textAlign: 'center', fontSize: 12 }} > 
                    {component.dataset && component.dataset === 'sentiment'
                            ?
                            <SentimentChart
                            chartDiv={`editChartDiv-${assetId}`}
                            chartDivTitle={null} // {`chartDivTitle-${assetId}`}
                            chartSettings={customSettings || null}
                            chartData={null}
                            chartAssetId={assetId}
                            appSettings={null}
                            apiManager={apiManager}
                            inBrief={true} 
                            // selectComponent={setSelected}
                            />
                            :
                                <ChartDisplay 
                                        chartDiv={`chartDiv-${assetId}`} 
                                        chartDivTitle={`chartDivTitle-${assetId}`}
                                        chartSettings={settings || null} 
                                        chartData={null} 
                                        chartAssetId={assetId}
                                        appSettings={null}  
                                        apiManager={apiManager}
                                        inBrief
                                        />      
                            }       
                    </div>
              </div>
          )
    }


    const renderLink = (content, key) => {
 
        const { text, bold='bold', size='11', italic, button, background, color } = content;
        let { url } = content;
        let textColor = color ? color : '#000';
        let buttonColor = background ? background : 'transparent';
        
        textColor = mostReadable(buttonColor, [textColor], {includeFallbackColors: true}).toHexString();

        const style = {
            padding: '13px 26px',
            minWidth: 200,
            borderRadius: 3,
            fontSize: `11px`,
            fontStyle: italic ? 'italic' : 'normal',
            fontWeight: bold ? 'bold' : 'inherit',
            backgroundColor: buttonColor,
            color: textColor,
            border: 'none',
        }
        const divStyle = {
            textAlign: 'center',

        }

        url = (url == "Add the link URL") ? 'www.breefly.com' : url

        if( !url.startsWith('http')) {
            url = `https://${url}`;
        }
        return (<div key={key} style={{ position: 'relative' }}>
            <div style={divStyle}  >
                <a href={url}  target="_blank">
                    {<button style={style}>
                        <span>{text}</span>
                        </button>}
                </a>
            </div><br />

        </div>)
    }

    const renderByType = (component, index) => {
        if (!component) {
            return null;
        }
        let { type, content, key } = component;
        if (index === 0) {
            type = 0;
        }
        switch (type) {
            case 0:
                // title
                return renderTitle(content, key);
                break;
            case 1:
                // image
                return renderImage(content, key);
                break;
            case 2:
                // paragraph
                return renderParagraph(content, key);
                break;
            case 3:
                return renderVideo(content, key)
                // video
                break;
            case 4:
                return renderChart(content, key)
                // chart
                break;
            case 5:
                // link
                return renderLink(content, key)
                break;
            case 6:
                // customChart
                return renderCustomChart(content, key)
            break;
        }
    }
    const [inviteError, setInviteError] = useState('')
    const handleSave = async (invites) => {

        invites.id = id;
        
        const result = await apiManager.inviteUser(invites, id);
        if (result.msg){
            setInviteError(result.msg)
          } else {
            if(result.nonMemberEmails.length === 0){  
                openModal(true, 'User(s) have been invited!')
            }
            else if (result.nonMemberEmails.length !== 0 && result.memberEmails.length !== 0){ 
                openModal(false, `Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `)
            } else {
                openModal(false, `User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')}`)
            }
        }
    }

    const handlePublicSave = async (invites) => {

        invites.id = id;

        const result = await apiManager.invitePublicUser(invites, id);

        if (result) {
            openModal(true, 'User(s) have been emailed the guest link!')
        }
        else {
            openModal(false, 'User(s) have NOT been emailed!')
        }
    }

    const renderBrief = (components) => {

        const html = components.map(function (value, index) {
            return renderByType(value, index);
        })

        return (html)
    };

    const deleteBrief = async () => {
        await apiManager.deleteBrief(brief);
        history.push('/briefs')
    }



    const getIdFromUrl = () => {
        const urls = history.location.pathname.split('/');
        const id = urls[urls.length - 1].replace("#", "");
        return id;
    }




    if (id.length == 0 && !brief) {
        const id = getIdFromUrl();
        setId(id);
        getBrief()
    }


    return (
        <div className={classes.root}>
            { /* searchbar */}
            <div className={classes.searchbar}>
                <TextField size='small'
                    className={classes.searchform}
                    label="Search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    type=""
                    variant="outlined"
                    style={{ display: 'none' }}
                />
                {access != 'viewer' ?
                    <div className={classes.primaryCTA}>
                        <CreateBriefButton
                            handleClick={clickEdit}
                            isMobile={false}
                            isEdit
                        />
                    </div>
                    :
                    null 
                }
            </div>

            { /* view and controls */}
            <Grid
                container
                spacing={4}
                alignItems='center'
                justify='center'
                direction='column'
            >
                { /* name and controls */}
                <Grid
                    container
                    className={classes.controlBlock}
                    alignItems="center"
                    direction='row'
                    justify='space-between'
                >
  
                    <Grid
                        item
                        //justify="right"
                    >
                        <div className={classes.author}>{Author}</div>
                        <Box
                            fontWeight="500"
                            fontStyle="normal"
                            fontSize={18}
                        >
                         {!isLoading && (
                             <span>
                            {brief && brief.Owner ? brief.Owner.account : 'Breefly, Inc.'}
                            </span>
                         )}
                        </Box>
                    </Grid>

                    <Grid
                        //justify="flext-start"
                    >
              {/* {mentions && (

                <div style={{ display: 'inline-block', verticalAlign: 'text-top' }}>
                  {mentions}  <FontAwesomeIcon icon={faComment} color={`var(--brand-color2)`} style={{fontSize: 18, marginLeft: 4}} />
                </div>
              )} */}
                    {brief.favorite && brief.favorite.isFavorite ? (
                        <IconButton aria-label="close" className={classes.icon} onClick={addFavorite}>
                            <FontAwesomeIcon size={'xs'} icon={solidHeart} color='red'  />
                        </IconButton> )
                                :
                        (<IconButton aria-label="close" className={classes.icon} onClick={addFavorite}>
                            <FontAwesomeIcon size={'xs'} icon={faHeart} color='primary'  />
                        </IconButton> )
                    }
                        {access != 'viewer' ?
                            <TagBrief classes={classes}  brief={brief} updateTags={updateTags} />
                            : null}

                        {access != 'viewer' ?
                            <InviteExistingUser  history={history} brief={brief} apiManager={apiManager} isMobile={isMobile} handleSave={handleSave} handlePublicSave={handlePublicSave} user={user}/>
                            : null}
                        {/* <IconButton aria-label="close" className={classes.icon} >
              <FontAwesomeIcon size={'xs'} icon={faMailBulk}  color={ (brief.invites && brief.invites.length > 0) ? '#24a0ed' : '' } onClick={handleInviteOpen} />
          </IconButton> */}

                        {/* <DeleteBrief classes={classes} /> */}
                        {access != 'viewer' && !isMobile ?
                            <CardMenu history={history} brief={brief} apiManager={apiManager} hideEdit={isMobile} deleteBrief={deleteBrief} access={access} user={user}/>
                            : null}
                    </Grid>

                </Grid>

                { /* content of brief */}
                <Grid
                    container
                    className={classes.briefArea}
                    direction="column"
                    alignItems="center"
                    alignContent="stretch"
                //zeroMinWidth="false"
                //justify="center"
                >
                    {isLoading ?
                        <Grid
                        container
                        spacing={2}
                        style={{ display: !isLoading ? 'none' : 'block' }}
                        >
                    <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            
                            ><LinearIndeterminate /></Grid>
                    </Grid>
                        :
                        <Grid
                            container
                            spacing={4}
                            alignItems='center'
                            justify='center'
                            direction='column'
                        >

                            {brief.components ? renderBrief(brief.components) : renderNotAvailable()}
                        </Grid>
                    }
                </Grid>
                <Notification isOpen={isModalOpen} message={message} closeModal={closeModal} />
                <RightbarDetail
                    isOpen={isOpenRightbar} onClose={handleRightbarClose} settings={settings} brief={brief} funcs={funcs} />

                <Rightbar isOpen={isRightOpen} brief={brief} onClose={onRightClose} isMobile={isMobile} funcs={funcs} comments={comments} user={user} />

                {renderCommentsButton()}

                <InviteSuccess isOpen={isInviteSuccessOpen} user={user}/>
            </Grid>
            <RegisterSnackBar snack={snack} closeModal={closeSnackModal}  />
            {(inviteError || editError)  && 
                <SubscriptionModal 
                    open={Boolean(inviteError) || Boolean(editError)}
                    message={inviteError || editError}
                    onClose={() => {
                        setInviteError(''); 
                        setEditError('')
                        }
                    }
                    user={user}
                    modalTitle={"sharing"}
                />}
        </div>
    );
};

BriefDetail.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
};

export default withStyles(styles)(BriefDetail);
