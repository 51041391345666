//COLUMN SORT AND SEARCH NEED TO BE DEBUGGED STILL

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import uuidv4 from "uuid/v4";
import moment from "moment";
import {
  MenuItem,
  Menu,
  IconButton,
  TableSortLabel,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  lighten,
  makeStyles
} from "@material-ui/core";

import TablePagination from "@material-ui/core/TablePagination";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
//import CreateUser from "./CreateUser";
import { faEllipsisV, faEnvelope, fa } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateElementAccess, updateSourceFile } from "typescript";

//import UploadInvite from "./UploadInvite";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Uploaded"
  },
  {
    id: "period",
    numeric: false,
    disablePadding: false,
    label: "Reporting Period"
  },
  { id: "dataset", numeric: false, disablePadding: false, label: "Dataset" },
  { id: "fileName", numeric: false, disablePadding: false, label: "File Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Uploaded By" },
  { id: "status", numeric: false, disablePadding: true, label: "" }
  // { id: 'member-status', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`member-table-cell member-table-head med-width ${
              headCell.id === "status" ? "last" : ""
            }`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  return null;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    // minWidth: 750,
  },
  menu: {
    "&.MuiMenuItem-root": {
      fontSize: 14
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function UploadsList({
  apiManager,
  org,
  affiliates,
  searchTerm,
  refreshData,
  filter,
  handleReplace
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(false);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextFile, setContextFile] = React.useState(false);
  const [oldSearchTeam, setOldSearchTerm] = React.useState(searchTerm);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  if (affiliates && headCells[3].id === "member-Role") {
    headCells.splice(3, 1);
  }
  //needed to make the sort work
  const capitalization = i => {
    if (typeof i === "string") {
      return i.charAt(0).toUpperCase() + i.slice(1);
    } else {
      return i;
    }
  };
  const getData = async () => {

    const uploadData = await apiManager.getCSVUploads();
    if (uploadData && uploadData.assets) {
      const rowData = uploadData.assets.map(row => ({
        date: moment(row.created).format('MM/DD/YY h:mm a'),
        period: row.fileInfo.dateRange,
        fileName: row.fileInfo.name,
        email: row.userName,
        status: '',
        dataset: row.fileInfo.dataSubType,
        key: row.key,
        url: row.url
      }));
      //FILTER DATA
      // const filteredRow = rowData.filter(i => i.category === filter);
      setRows(rowData);
    } else {
      setRows([]);
    }
  };

  // Initial Data and Refresh - i.e. componentDidUpdate
  useEffect(() => {
    getData();
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //Unused, for paginationn
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //Unused, for paginationn

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openContextMenu = (event, row, index) => {
    setContextFile(row);
    setAnchorEl(event.currentTarget);
  };

  //replace a file
  const setReplaceItem = event => {
    const itemToReplace = rows.filter(
      el => el.fileName === contextFile.fileName
    );
    handleReplace(itemToReplace);
    setAnchorEl(false);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const typeList = {
    member: "Member",
    owner: "Owner",
    "co-owner": "Administrator"
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (!rows) {
    return null;
  }

  if (page > 0 && searchTerm !== "" && oldSearchTeam !== searchTerm) {
    setOldSearchTerm(searchTerm);
    setPage(0);
  }
  const handleDownload = () => {
    let link = document.createElement("a");
    link.setAttribute("href", contextFile.url); //set url
    link.download = contextFile.fileName; //change file name here
    document.body.appendChild(link);
    link.click() //trigger download
  }
  return (
    <div className={classes.root}>
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer style={{ height: 300, paddingRight: 16 }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .filter(row => {
                if (searchTerm) {
                  const name = `${row.fileName} ${row.email}`;
                  if (name.toLowerCase().includes(searchTerm)) return 1;
                  return 0;
                }
                return 1;
              })
              .map((row, index) => {
                const isItemSelected = isSelected(row.fileName);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.fileName)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.key}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell> */}
                    <TableCell
                      align="left"
                      id={labelId}
                      scope="row"
                      className={`member-table-cell med-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`member-table-cell large-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.period}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                      className={`member-table-cell  large-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                   {row.dataset}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: "capitalize" }}
                      className={`member-table-cell  large-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.fileName}
                    </TableCell>
                    {!affiliates && (
                      <TableCell
                        align="left"
                        className={`member-table-cell large-width padding ${
                          row.status === 0 ? "inactiveUser" : ""
                        }`}
                      >
                        {row.email}
                      </TableCell>
                    )}

                    {/* <TableCell align="left" className={'member-table-cell small-width'}>
                              {affiliates ?
                                  <>
                                      {row.user.lastLogin === 'never' || row.type === 'affiliate' ? 'Pending' : 'Active'}
                                  </>
                                  :
                        <>
                        {/* {row.user.lastLogin === 'never' ? 'Pending' : 'Active'} *
                        </>
                        }
                      </TableCell> */}
                    <TableCell
                      align="right"
                      className={`member-table-cell lastpadding ${
                        row.status === "failed" ? "remindUser" : ""
                      }`}
                    >
                      <IconButton
                        size={"small"}
                        onClick={e => openContextMenu(e, row, index)}
                        style={{ fontSize: 16 }}
                      >
                        <FontAwesomeIcon
                          size={row.status === 0 ? "sm" : "lg"}
                          icon={faEllipsisV}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="context-menu"
        elevation={1}
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(false)}
      >
        <MenuItem className={classes.menu} onClick={() => setReplaceItem()}>
          Replace
        </MenuItem>
        <MenuItem
          className={classes.menu}
          onClick={handleDownload}
        >
          Download
        </MenuItem>
      </Menu>
      {/* Remove Pagination */}
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
    </div>
  );
}
