/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
    },
    item: {
        display: 'flex',
        padding: 0,
    },
    button: {
        // color: colors.blueGrey[800],
        color: 'white',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        cursor: 'pointer',
        fontSize: 12,
    },
    icon: {
        // color: theme.palette.icon,
        color: 'white',
        fontWeight: 100,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2, 1, 1),
    },
    linkname: {
        color: 'white',
        fontWeight: 400,
    },
    linkbox: {
        width: '100%',
        padding: theme.spacing(0, 2, 0, 0),
        marginBottom: theme.spacing(1),
        verticalAlign: 'middle',
        cursor: 'pointer',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#e8e8e888'
        }
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const { pages, className, isMobile, ...rest } = props;
    const classes = useStyles();

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            {pages.map((page, index) => (
                <React.Fragment key={`${page.title}-${index}`}>
                    {page.title !== '' ?
                        <>
                            {(!page.hideMobile || !isMobile) && (
                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                    key={page.title}
                                >
                                    <Button
                                        // activeClassName={classes.active}
                                        className={`${classes.button} ${classes.linkbox}`}
                                        component={CustomRouterLink}
                                        to={page.href}

                                    >

                                        <div className={classes.icon}>{page.icon}</div>
                                        <span className={classes.linkname}>{page.title}</span>

                                    </Button>
                                </ListItem>
                            )}
                        </>
                        :
                        <hr key={page.key} style={{ borderColor: 'rgba(255, 255, 255, 0.7)', margin: '8px 16px 16px 8px' }} />
                    }
                </React.Fragment>
            ))}
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
