import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, 
  Button,
  Link, 
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core'; 
import TextField from '@material-ui/core/TextField';
 

import Autocomplete from '@material-ui/lab/Autocomplete';

import HelpIcon from '@material-ui/icons/Help';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
  
 
import { faFont, faCamera, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import moment from 'moment';
import Card from './components/Card'; 

const styles = (theme => ({
    root: {
      padding: theme.spacing(4),
      minHeight: '90vh'
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const Members =  (props) => {
  const { history, apiManager, classes, user } = props; 

   
  const [spacing, setSpacing] = React.useState(2);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(25); 
  const [skip, setSkip] = React.useState(0); 
  const [assets, setAssets] = React.useState(false);  
  
  const getData = async (overrideSearch) => {
    const params = {
      limit,
      skip,
      search: overrideSearch ? overrideSearch : search
    }
    
    const userAssets = []; //await apiManager.getPlugins(params);
    setAssets(userAssets);
  }
  
   

  if(!assets){
    getData();
  }

  const deleteAsset = async (asset) => {
      await apiManager.deleteUser(asset);
      await getData();
  }
 
  const handleChange = async (e) => {
    const { value } = e.target;
    setSearch(value);
    await getData(value);
  }

  const updateSearch = async (isPhoto) => {
    
    await getData();
  }

  const updateAsset = async (plugin) => { 
      await apiManager.updatePlugin(plugin);
      await getData();
  } 

  const createAsset = async (plugin) => { 
    await apiManager.createPlugin(plugin);
    await getData();
} 
 
  const renderEmailGrid = () => {
    if(!assets) {  
      return null 
    }
    const html = (assets.map(function(value){
      return <Grid
      item
      lg={2}
      sm={2}
      xl={2}
      xs={2}
      spacing={spacing}
    >
    <Card asset={value} updateAsset={updateAsset} deleteAsset={deleteAsset} apiManager={apiManager}  history={history} />
      
    </Grid>
    }))
    return html;
  }


  return ( 
    <div className={classes.root}>
      
    <Grid
      container
      spacing={4}
    >
         
         <Grid
          item
          lg={2}
          xl={2}
          md={3}
          sm={4}
          xs={5}
        >
            
        </Grid>
        
        <Grid
          item
          lg={8}
          xl={8}
          md={8}
          sm={7}
          xs={7}
        > 
        <TextField  size='small'
                    className={classes.textField} 
                    fullWidth 
                    label="Filter"
                    name="search"
                    value={search}
                    onChange={handleChange}
                    type="" 
                    variant="outlined"
                  />
          </Grid>
           
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
        <Grid
         container 
         spacing={spacing}
        >
           { renderEmailGrid() }
 
            </Grid> </Grid>
   
      </Grid> 
    </div>
  );
};

Members.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(Members);
