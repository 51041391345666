import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Container, Grid, Button, TextField, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BriefCard from '../Briefs/components/BriefCard';
import BriefRow from '../Briefs/components/BriefRow';
import BriefCaseCard from '../Briefs/components/BriefCaseCard';
import PaginationRounded from '../../components/PaginationRounded';
import CreateBriefButton from '../../components/CreateBriefButton';
import { makeStyles } from '@material-ui/core/styles';

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../Brief/css/style.css';

 

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        '& .MuiTextField-root': {
        },
        '& .MuiOutlinedInput-root': {
            height: 44,
        },
        '& .MuiInputLabel-outlined': {
            paddingTop: 2,
        },
        textField: {
            marginTop: 2,
        }
    },
    searchbar: {
        marginLeft: 72,
        '@media (min-width: 1264px)': {
            marginLeft: 0,
        },
    }
}));

export default function SharedBriefs(props) {
     
    const classes = useStyles();

        const { isMobile, apiManager, history } = props;

        const [briefs, setBriefs] = React.useState([]);
        const [brief, setBrief] = React.useState({});

        const [isDetails, setIsDetails] = React.useState(false);
        const [isCaseView, setIsCaseView] = React.useState(false);
        const [isFavorites, setIsFavorites] = React.useState(false);
        const [isGrid, setIsGrid] = React.useState(true);
        const [init, setInit] = React.useState(false);

        const [skip, setSkip] = React.useState(0);
        const [page, setPage] = React.useState(0);
        const [limit, setLimit] = React.useState(10);
        const [count, setCount] = React.useState(1);
        const [search, setSearch] = React.useState('');

      

    const getBriefs = async (forceLimit, forcePage, forceSearch) => { 

        if (forcePage) {
            skip = forcePage * (forceLimit ? forceLimit : limit);
            if (forcePage == 1) {
                skip = 0;
            }
        }
         
        const result = await apiManager.getSharedBriefs({ skip, limit: (forceLimit ? forceLimit : limit), search: (forceSearch ? forceSearch : search) });
        
        if (result && result.briefs) { 
            let { briefs, count } = result;
            setBriefs(briefs);
            setCount(count);
        } 
        
    }

    if(!init && briefs.length == 0){
        setInit(true);
        getBriefs();
    }
 
    const renderGrid = async () => {
 
        setIsGrid(true);
        await getBriefs();
    }

    const renderList = async () => {
        setIsGrid(false);
        await getBriefs();
    }

    

    const renderFavorites = async () => { 
        
        setIsFavorites(!isFavorites);
        await getBriefs();
    }
   
    

    const updateSharing = async (brief) => {   
        await apiManager.updateBrief(brief);
        await getBriefs();
    }

    
    const handleChange = async (event) => {   
        const search = event.target.value;
        setSearch(search);
        if (search.length > 2 || search.length == 0) {
            
            await getBriefs(null, null, search);
        }
    }

     
    const updatePage = async (event) => {   
        const page = parseInt(event.target.innerText);
        setPage(page);
        const { limit } = this.state;
        await this.getBriefs(limit, page);
    }

    
    const updateLimit = async (limit) => {   
        
       setLimit(limit);

        await getBriefs(limit, page);
    }

    const deleteBrief = async (brief) => {     
        await apiManager.deleteBrief(brief);
        await getBriefs();
    }

    const detailsClick = async (brief) => {      
        setBrief(brief);
        history.push(`/brief/details/${brief._id}`);
    }

    const createClick = async () => {      
        const brief = {
            title: '',
            tags: [],
            briefcase: { value: 0 },
            components: [
                {
                    "type": (0),
                    "content": {
                        "text": ""
                    },
                    "position": (0)
                }
            ]
        }
        const result = await apiManager.createBrief(brief);
        history.push(`/brief/edit/${result._id}`);
        console.log(result);
    }

      
        const pageCount = count > 1 ? Math.ceil(count / limit) : 1;
        
        return (
            <div className={classes.root}>
                { /* searchbar */}
                <div className={classes.searchbar}>
                    <Grid
                        container
                        spacing={4}
                    >

                        <Grid
                            item
                            lg={2}
                            xl={2}
                            md={3}
                            sm={5}
                            xs={10}
                        >
                            {/* <Button
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={createClick}
                                className='breefly-btn-primary'
                            >
                                Create a brief
                            </Button> */}
                         <CreateBriefButton 
                                handleClick={createClick}

                            />
                        </Grid>

                        <Grid
                            item
                            lg={8}
                            xl={8}
                            md={9}
                            sm={7}
                            xs={10}
                        >
                            <TextField size='small'
                                className={classes.textField}
                                fullWidth
                                label="Search"
                                name="search"
                                value={search}
                                onChange={handleChange}
                                type=""
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </div>
                { /* view and pagination */}
                <Grid
                    container
                    spacing={4}>
                   
                    <Grid
                        item
                       xs
                    >
                        <div style={{color: '#22b9ea', display: 'inline-block', marginLeft: 6, marginRight: 18, fontWeight: 500, fontSize: 16, verticalAlign: 'middle'}}>
                            SHARED BRIEFS 
                            </div>
                        <IconButton aria-label="close"
                            onClick={renderGrid}
                        >
                            <GridOnIcon color={isGrid ? 'primary' : ''} />
                        </IconButton>Grid

                        <IconButton aria-label="close"
                            onClick={renderList}
                        >
                            <ListIcon color={!isGrid ? 'primary' : ''} />
                        </IconButton>List

                         <IconButton aria-label="close" className={classes.icon} >
                            <FontAwesomeIcon icon={faHeart} color={isFavorites ? 'red' : 'primary'} onClick={renderFavorites} />
                        </IconButton> Favorites

{/* <IconButton aria-label="close"
        onClick={this.renderBriefCase}
        >
<BusinessCenterIcon color={ isCaseView ? 'primary' : ''} /> 
</IconButton>Brief cases */}
                    </Grid>
                    {count > 0 && (
                        <Grid item sm xs={12} style={{ textAlign: 'right' }}>
                            {count} Brief${count === 1 ? '' : 's'} 
                            <PaginationRounded totalItems={count} count={pageCount} updateLimit={updateLimit} limit={limit} page={page} updatePage={updatePage} />
                        </Grid>
                    )}
            </Grid>

                 { /* view and pagination */}
                <Grid
                    container
                    spacing={4}>
                    {briefs ? briefs.map(function (obj) {
                        const value = obj.briefData;
                        if (isFavorites === true && !value.isFavorite) {
                            return null
                        } else {
                            return (<Grid
                                item
                                xl={isGrid ? 3 : 12}
                                lg={isGrid ? 3 : 12}
                                md={isGrid ? 4 : 12}
                                sm={isGrid ? 6 : 12}
                                xs={12}
                            >
                                {isCaseView && ( <BriefCaseCard brief={value} detailsClick={e => detailsClick(value)} history={history} /> )}
                                 
                                {!isCaseView && ( 
                                    <>
                                    {isGrid ? <BriefCard brief={value}  isMobile={isMobile} detailsClick={e => detailsClick(value)}  deleteBrief={deleteBrief} updateSharing={updateSharing} apiManager={apiManager} />
                                    : <BriefRow brief={value} detailsClick={e => detailsClick(value)} apiManager={apiManager}  isMobile={isMobile} deleteBrief={deleteBrief} />}
                                    </>
                                )}
                            </Grid>)
                        }
                    }) : null}

                </Grid>
            </div>
        );
    }
 
 