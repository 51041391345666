import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
// import ForgotPassword from '../Modals/ForgotPassword'
import {
    Grid,
    Button,
    TextField,
    Link,
    Typography
} from '@material-ui/core';
import axios from 'axios';

import { API_LOCAL } from '../../constants';
import LocalSettings from '../../services/LocalSettings';
import LoadingIndicator from '../../components/LoadingIndicator';
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate';


import { PROD_API_LAMBDA, USE_PROD, API_LAMBDA, BREEFLY_LOGO } from '../../constants';
// import logoImage from '../../images/Breefly_Logo-Horiz.svg';
const logoImage = BREEFLY_LOGO;


const schema = {
    confirm: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8
        },
        equality: 'password'
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8
        }
    }
};

makeStyles(theme => {
    console.log(theme)
});

const useStyles = makeStyles(theme => ({
    root: {
        //height: '100vh',
        //minHeight: 900,
        '& .MuiButton-containedPrimary': {
            backgroundColor: '#22b9ea !important',
            width: '60%',
        },
        '& .MuiButton-label': {
            color: '#eee !important',
        },
        '& .MuiLink-root': {
            color: '#22b9ea',
        },
    },
    grid: {
        height: '100%'
    },
    // quoteContainer: {
    //     [theme.breakpoints.down('md')]: {
    //         display: 'none'
    //     }
    // },
    // quote: {
    //     backgroundColor: theme.palette.neutral,
    //     height: '100%',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     //backgroundImage: 'url(/images/auth.jpg)',
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center'
    // },
    // quoteInner: {
    //     textAlign: 'center',
    //     flexBasis: '600px'
    // },
    // quoteText: {
    //     color: theme.palette.white,
    //     fontWeight: 300
    // },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    // bio: {
    //     color: theme.palette.white
    // },
    // contentContainer: {},
    content: {
        // height: '72%',
        // display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginBottom: 30,
    },
    tagLine: {
        fontWeight: 500,
        fontSize: 22,
        marginBottom: 16,
        '@media (max-width: 820px)': {
            fontSize: 18,
        },
    },
    panelSpacer: {
        width: 1,
        height: '15vh',
        minHeight: 50,
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',

    },
    form: {
        // borderColor: '#00BFFF',
        // borderStyle: 'solid',
        // borderWidth: 'medium',
        borderRadius: '8px',
        position: 'relative',
        top: '40%',
        textAlign: 'center',
        alignItems: 'center',
        paddingTop: 32,
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingBottom: 48,
        maxWidth: 400,
        minWidth: 350,

        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        background: '#fff'
    },
    // title: {
    //     //  marginTop: theme.spacing(3)
    //     marginTop: 18,
    // },
    // socialButtons: {
    //     marginTop: theme.spacing(3)
    // },
    // socialIcon: {
    //     marginRight: theme.spacing(1)
    // },
    suggestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                color: 'white',
            },
        },
    },
}));

const ResetPassword = (props) => {
    const { history, updateUser } = props;

    const data = props;

    const [error, handleError] = React.useState(false);

    const [status, setStatus] = React.useState('');

    const [isLoading, setIsLoading] = React.useState('true');
    const [isError, handleIsError] = React.useState(true);
    const [isValid, setIsValid] = React.useState(false);
    const [init, setInit] = React.useState(false);
    const [token, setToken] = React.useState(false);
    const [branding, setBrandSettings] = useState(
        LocalSettings.defaultSettings.branding
    )
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });




    const validateToken = async () => {
        // 

        const query = data.location.search.split('=');
        let token = '';
        if (query.length == 2) {
            token = query[1];
        }
        setToken(token)
        const headers = { token }
        const url = (API_LOCAL) + 'users/auth/validate/reset/token';

        try {
            const result = await axios.post(url, { token }, { headers });

            if (result && result.data && result.data.success) {
                handleIsError(false);
                setIsValid(true);
            }
            else {
                handleError(result.data.msg)
                handleIsError(true);
            }
        }
        catch (ex) {
            console.log(ex);
            handleError(ex.toString())
            handleIsError(true);
        }
       setIsLoading(false);
    }

    if (!init && !isValid) {
        setInit(true);
        validateToken();

    }

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);



    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };



    const handleSignIn = async event => {
        event.preventDefault();

        const { confirm, password } = formState.values;
        const url = (API_LOCAL) + '/users/auth/reset/password';

        if (confirm !== password) {

        }
        else if (confirm.length < 8) {

        }
        else {
            try {
                const result = await axios.post(url, { confirm, password, token })

                if (result && result.data && result.data.email) {
                    updateUser(result.data);
                    sessionStorage.setItem('token', result.data.token);
                    history.push('/briefs');
                }
                else {
                    setFormState(formState => ({
                        ...formState,
                        error: 'Invalid login info'
                    }));
                }
            }
            catch (ex) {
                console.log(ex);
                handleError(ex.toString())

            }
        }
    };

    const renderError = () => {
        return <div><h3>Oops, looks like there was a problem.</h3><p> {error} </p></div>;
    }

    const renderReset = () => {
        return (<div><Typography
            className={classes.title}
            variant="body1"
        >
            Enter your new password below:
            {formState.error}
        </Typography><TextField
                className={classes.textField}
                error={hasError('password')}
                fullWidth
                helperText={
                    hasError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={formState.values.password || ''}
            //   variant="outlined"
            />

            <TextField
                className={classes.textField}
                error={hasError('confirm')}
                fullWidth
                helperText={
                    hasError('confirm') ? formState.errors.confirm[0] : null
                }
                label="Confirm password"
                name="confirm"
                onChange={handleChange}
                type="password"
                value={formState.values.confirm || ''}
            //   variant="outlined"
            />
            <br />  <br />
            <div style={{ 'text-align': 'center' }}>
                <Button
                    //   className={classes.signInButton}
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                >
                    Reset password
                </Button>
            </div> </div>
        )
    }

    const handleLogin = () => {
        history.push('/signin');
    }

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    return (
        //   <div className={classes.root}>
        //      <style>{`.pageBody { background: linear-gradient(#000055, black) }
        //      .MuiButton-containedPrimary, .MuiButton-label {
        //       color: #000 !important;

        //       background-color: transparent !important; 
        //   }` }</style>
        <div
            className="fullCoverPanel"
            style={{
                backgroundImage: `url(${branding && branding.portalbg
                    ? branding.portalbg
                    : LocalSettings.defaultSettings.branding.portalImage
                    })`
            }}
        >
            <Grid
                className={classes.content}
                item
                lg={12}
                xs={12}
                alignItems="center"
                container
            >
                <div className={classes.content}>
                    <div className={classes.panelSpacer} />
                    <div className={classes.contentBody}>
                        <form
                            className={classes.form}
                            onSubmit={handleSignIn}
                        >
                            <Typography
                                className={classes.title}
                                variant="body1"
                            >
                                <img src={logoImage} alt="Breefly.com" width="220px" align='middle' className={classes.logoImage} />
                                <div className={classes.tagLine}>
                                    Insights reporting made easy.
                                </div>
                            </Typography>
                            {isLoading
                                ?
                                <div style={{width: 300, height: 250, paddingTop: 50, margin: 'auto'}}>Verifying...<br /><br />
                                    <LinearIndeterminate />
                                </div>
                                :
                                <>
                                    {isError && (
                                        <Typography
                                            className={classes.title}
                                            variant="body1"
                                        >
                                            {formState.error}
                                            <br />
                                        </Typography>
                                    )}

                                    <span style={{ color: 'blue' }}>{status}</span>

                                    {isError ? renderError() : renderReset()}

                                    <div style={{ 'textAlign': 'center' }}>
                                        <br /> Remembered your password? <span>
                                            <Link href="#" onClick={handleLogin}>
                                                Sign In
                                            </Link></span>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </Grid>

        </div>
    );
};

ResetPassword.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
};

export default withRouter(ResetPassword);
