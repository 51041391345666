import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import { SidebarNav } from "./components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faHomeAlt,
  faPhotoVideo,
  faUserFriends,
  faCog,
  faWallet,
  faSignOut,
  faUserChart,
  faDatabase,
  faAnalytics,
  faChartPieAlt,
  faChartNetwork,
  faChevronCircleLeft
} from "@fortawesome/pro-light-svg-icons";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";
import { FAKE_ORGANIZATION } from "../../../../constants";

const useStyles = makeStyles(theme => ({
  drawer: {
    background: "var(--brand-color1)",
    //'rgb(10,18,65)',
    [theme.breakpoints.up("lg")]: {
      //marginTop: 64,
      //height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3, 2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  iconPadding: {
    padding: theme.spacing(1, 2, 1, 1)
  },
  closebox: {
    cursor: "pointer",
    borderRadius: 5,
    marginBottom: 72,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#e8e8e888"
    },
    "@media (max-width: 500px)": {
      marginBottom: 0
    }
  }
}));

const Sidebar = props => {
  const { user, open, variant, onClose, className, isMobile, ...rest } = props;

  const classes = useStyles();
  let pages = [
    {
      title: "Briefs",
      hideMobile: false,
      href: "/briefs",
      icon: <FontAwesomeIcon onClick={onClose} icon={faHomeAlt} />
    },
    {
      title: "Analytics",
      href: "/analytics",
      hideMobile: false,
      hideFromSinglePayment: true,
      icon: <FontAwesomeIcon onClick={onClose} icon={faAnalytics} />
    },
    {
      title: "Insite Databases",
      href: "/visualizer",
      hideMobile: false,
      pma: true,
      icon: <FontAwesomeIcon onClick={onClose} icon={faChartPieAlt} />
    },
    {
      title: "Assets",
      href: "/assets",
      hideMobile: true,
      icon: <FontAwesomeIcon onClick={onClose} icon={faPhotoVideo} />
    },
    {
      title: "Manage Access",
      href: "/manage-access",
      hideMobile: false,
      icon: <FontAwesomeIcon onClick={onClose} icon={faChartNetwork} />
    },
    {
      title: "",
      key: "hr1" // this is a separator bar
    },
    {
      title: "Data Management",
      href: "/data",
      hideMobile: false,
      pma: true,
      owner: true,
      affiliate: true,
      icon: <FontAwesomeIcon onClick={onClose} icon={faDatabase} />
    },
    {
      title: "Team Management",
      href: "/owner",
      hideMobile: false,
      owner: true,
      individual: true,
      icon: (
        <FontAwesomeIcon onClick={onClose} icon={faUserFriends} />
      )
    },
    {
      title: "Billing",
      href: "/billing",
      sub: true,
      hideMobile: false,
      icon: <FontAwesomeIcon onClick={onClose} icon={faWallet} />
    },
    {
      title: "Account",
      href: "/account",
      hideMobile: false,
      icon: <FontAwesomeIcon onClick={onClose} icon={faCog} />
    },
    {
      title: "",
      key: "hr1" // this is a separator bar
    },
    {
      title: "Sign Out",
      href: "/logout",
      hideMobile: false,
      icon: <FontAwesomeIcon icon={faSignOut} />
    }
  ];

//   if (FAKE_ORGANIZATION) {
//     if (!user.roles) {
//       user.roles = [];
//     }
//     if (user.roles.indexOf("orgOwner") === -1) {
//       user.roles.push("orgOwner");
//       user.org = "pma";
//     }
//   }
  const admin = user.orgRole === "owner" || user.orgRole === "co-owner";
  //let orgMember = user.orgId && user.orgRole !== "revoked"; 
  const affiliate = user.orgAffiliates && user.orgAffiliates.length > 0;
  const pma = user.hostOrg; 
  const owner = user.orgRole === "owner";
  const downgraded = user.orgRole === "owner" && !user.onTeam
  const singlePaymentPlan = user.accounttype && user.accounttype === 'teamSinglePayment';

  if (!admin) {
    //hide data managment, team management, subscriptions
    pages = pages.filter(el => !el.owner);
  }
  //hide team management and analytics if owner without a team
  if (downgraded){
    pages = pages.filter(el => !el.individual)
  }
  if (affiliate) {
    //hide team management
    pages = pages.filter(el => !el.affiliate);
  }
  if (!pma) {
    //hide pma data
    pages = pages.filter(el => !el.pma);
  }
  if (!owner && user.onTeam) {
    //hide subs unless "owner"
    pages = pages.filter(el => !el.sub)
  }
  if(singlePaymentPlan) {
    pages = pages.filter(el => !el.hideFromSinglePayment);
  }

 
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        // className={clsx(classes.root, className)}
        className={`${classes.root} ${className} brandColor1`}
      >
          <div className={classes.closebox} onClick={onClose}>
                  {/* <IconButton aria-label="close"
                    style={{color: 'white', fontWeight: 100, }}
                  // onClick={onClose}
                  className={classes.iconPadding}
                  >
                      <HighlightOffIcon style={{height: 14, width: 14}}/>
                  </IconButton> */}
                  <FontAwesomeIcon  icon={faChevronCircleLeft} style={{color: 'white', marginRight: 24}} />
                  <div style={{display:'inline-block', verticalAlign: 'middle', color: 'white'}}>
                  Close
                  </div>
            </div>
        <SidebarNav
          className={classes.nav}
          pages={pages}
          isMobile={isMobile}
          onClick={onClose}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Sidebar;
