import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%' 
  },
  standardInfo: {
    color: '#fff',
    backgroundColor: 'purple'
  },
  icon: {
    color: '#fff',
    fontSize: '50px'
  }
}));

export default function TopBanner(props) {
  const classes = useStyles();
  const {belongsToOrg} = props;

    if (belongsToOrg) {

        return (
            <div className={classes.root}>
                <div className={classes.root}>
                    <style>{`.MuiAlert-standardInfo .MuiAlert-icon { color: #fff; font-size: 50px; }`}</style>
                    <Alert severity="info" className={classes.standardInfo}>
                        <h5>Welcome to Breefly</h5>
                        At Breefly you can create, share and collaborate with others. 
        </Alert>
                </div>
            </div>
        )
    }

  return (
    <div className={classes.root}> 
        <style>{`.MuiAlert-standardInfo .MuiAlert-icon { color: #fff; font-size: 50px; }`}</style>
        <Alert severity="info" className={classes.standardInfo}>
            <h5>Sign Up to get full access!</h5>
            Get full access to this brief so you can create, share and collaborate with others by signing up. Start your free trial today!
        </Alert> 
    </div>
  );
}