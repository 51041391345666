import React, { useState, useEffect } from "react";

import { Grid, Button } from "@material-ui/core";
import EditTitle from "./components/EditTitle";
import Notification from "../Modals/Notification";
import CustomizedSnackbar from "./components/CustomizedSnackbar";
import uuidv4 from "uuid/v4";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";
import moment from "moment";

import "./css/style.css";
import AddPanel from "./components/AddPanel";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Container from "./components/Container";
import ChartTemplatesDialog from "../Visualizations/components/templatesDialog";

const styles = theme => ({
  root: {
    // display: 'flex',
    "& .MuiButton-containedPrimary": {
      // backgroundColor: '#22b9ea !important',
      backgroundColor: "var(--brand-color2) !important",
      "@media (max-width: 500px)": {
        // width: '40%',
        // minWidth: 150,
      }
    },
    "& .MuiButton-containedPrimary:hover": {
      filter: "brightness(1.2)",
      //backgroundColor: 'var(--brand-color2) !important',
      "@media (max-width: 500px)": {
        // width: '40%',
        // minWidth: 150,
      }
    }
    // "& .MuiButton-label": {
    //   color: "#eee !important"
    // }
  },
  navBar: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    height: 100,
    paddingTop: 8
  },
  primaryCTA: {
    position: "absolute",
    top: 8,
    left: 0,
    "& .MuiButton-label": {
      color: "#eee !important"
    },
    "@media (max-width: 650px)": {
      position: "unset",
      width: 100,
      marginLeft: 42
    }
  },
  briefArea: {
    width: 630,
    maxWidth: 630,
    "@media (max-width: 1025px)": {
      width: 500
    },
    "@media (max-width: 935px)": {
      width: "60%"
    },
    "@media (max-width: 850px)": {
      width: "100%",
      minWidth: 150
    },
    "@media (max-width: 500px)": {
      width: "80%",
      minWidth: 150
    }
  },
  title: {
    fontWeight: 500,
    fontSize: 24
  },
  controlBlock: {
    marginBottom: 48,
    width: 630,
    "@media (max-width: 1025px)": {
      width: 530
    },
    "@media (max-width: 935px)": {
      width: "60%"
    },
    "@media (max-width: 850px)": {
      width: "100%",
      minWidth: 150
    },
    "@media (max-width: 500px)": {
      width: "calc(100% - 42px)",
      minWidth: 150
    }
  },
  filter: {
    fontSize: 14,
    marginRight: 24,
    color: "#888;",
    "&.active": {
      color: "#333"
    }
  }
});

const EditBrief = props => {
  const { history, apiManager, classes, isMobile, refreshChild, user } = props;
  
  const [isModalOpen, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [brief, setBrief] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [isEditImageOpen, setIsEditImageOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([]);
  const [settings, setSettings] = React.useState([]);
  const [component, setComponent] = React.useState([]);
  const [funcs, setFuncs] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isEditVideoOpen, setIsEditVideoOpen] = React.useState(false);
  const [imageComponent, setImageComponent] = React.useState({});
  const [isRefresh, setRefresh] = React.useState(true);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [isChartDialogOpen, setChartDialogOpen] = React.useState(false);
  const access = brief && brief.invite ? brief.invite.access : "admin";
  const permissions = user.roles === "owner";
  const org = user.org ? user.org : false;

  const [openRightbar, setOpenRightbar] = useState(false);
  // const updateIntercom = user => {
  //   window.intercomSettings = {
  //     app_id: "zcl62xdv",
  //     name: `${user.firstname} ${user.lastname}`,
  //     email: `${user.email}`,
  //     created_at: `${user.created}`
  //   };
  //   window.Intercom("boot", window.intercomSettings);
  // };
  useEffect(() => {
    console.log('mounted edit brief')
      getBrief();
      // updateIntercom(user);
  },[])
  

  

  const refreshImage = async component => {
    // component.content.url = null;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    refreshChild();

    //await getBrief();
  };

  const handleRightbarOpen = (component, funcs) => {
    const setting = {
      type: component.type
    };

    setComponent(component);
    setFuncs(funcs);
    setSettings(setting);
    setOpenRightbar(true);
    setRefresh(false);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const getBrief = async () => {
    // setIsLoading(true)
    const urls = history.location.pathname.split("/");
    const id = urls[urls.length - 1].replace("#", "");
    let data = await apiManager.getBrief(id);

    if (data) {
      data.key = Math.random();
      setBrief(data);
      setTitle(data.title);
      setIsLoading(false);
      refreshChild();
    } else {
    }
  };
  const deleteEmptyBrief = async brief => {
    await apiManager.deleteBrief(brief);
    history.push(`/briefs`);
  };
  const cancelClick = () => {
    if (brief.components.length <= 1 && brief.components[0].type === 0) {
      deleteEmptyBrief(brief);
    } else {
      if (brief.title == "") {
        alert("You must set a title for your brief before publishing.");
      } else {
        let id = "";
        if (brief && brief._id) {
          const urls = history.location.pathname.split("/");
          id = urls[urls.length - 1].replace("#", "");
        } else {
          id = brief._id;
        }

        setTimeout(function() {
          if (id) {
            history.push(`/brief/details/${id}`);
            //history.push(`/brief/edit/${result._id}`);
            //window.location = `/brief/details/${id}`;
          } else {
            history.push(`/briefs`);
            //window.location = `/briefs`;
          }
        }, 500);
      }
    }
  };

  const body = document.body,
    html = document.documentElement;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  const scrollBottom = () => {
    setTimeout(function() {
      window.scrollTo({
        top: 1000000,
        left: 0,
        behavior: "smooth"
      });
    }, 500);
  };

  const addImage = async image => {
    scrollBottom();
    setIsLoading(true);
    let { components } = brief === false ? { components: [] } : brief;
    const template = {
      type: 1,
      content: {
        url: image.url,
        alt: image.alt,
        assetId: image.assetId,
        size: "75%",
        align: "center",
        isUploading: true
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    //await getBrief();
    refreshChild();
    scrollBottom();
  };

  const addVideo = async image => {
    scrollBottom();
    setIsLoading(true);
    let { components } = brief === false ? { components: [] } : brief;
    const template = {
      type: 3,
      content: {
        url: image.url,
        alt: image.alt,
        assetId: image.assetId,
        size: "100%",
        align: "center",
        isUploading: true
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    //await getBrief();
    scrollBottom();
  };

  // ***************** add generic asset ****************** //
  const addAsset = async asset => {
    scrollBottom();
    setIsLoading(true);
    let { components } = brief === false ? { components: [] } : brief;
    const template = {
      type: asset.type, // 5 == pma chart
      content: {
        url: asset.url,
        alt: asset.alt,
        assetId: asset.assetId,
        size: 400,
        align: "center",
        isUploading: true,
        data: asset.customData
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    //await getBrief();
    refreshChild();
    scrollBottom();
  };

  const addParagraph = async event => {
    setIsLoading(true);
    event.preventDefault();
    let { components } = brief === false ? { components: [] } : brief;
    const template = {
      type: 2,
      content: {
        text: "", // Enter your paragraph content here
        placeholder: "Enter your paragraph here",
        variant: "body1",
        quote: false,
        italic: false,
        bold: false,
        size: 20,
        visible: true
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    scrollBottom();
    //await getBrief();

    //messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  };

  const addLink = async () => {
    setIsLoading(true);
    let { components } = brief === false ? { components: [] } : brief;
    const template = {
      type: 5,
      content: {
        text: "Customize this Button",
        url: "Add the link URL",
        title: "this is your title",
        size: 11,
        bold: false,
        italic: false,
        background: "#5F92FF",
        color: "#000",
        border: "none"
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    //await getBrief();
    scrollBottom();
  };

  // all the functions to support inserting a chart
  const insertStandardChart = async data => {
    setIsLoading(true);
    const {
      content: { title, dataSource, subtitle, chartType, unit, chartColor },
      dataPoints,
      wordCloudList
    } = data || "";

    let { components } = brief === false ? { components: [] } : brief;

    //pie, funnel, doughnut, line, scatter
    const template = {
      type: 4,
      content: {
        title,
        subtitle,
        chartType,
        size: 30,
        source: "user", // could be plugin/url
        chartColor,
        unit,
        dataset: {
          plugin: "none",
          category: "",
          subcategory: "",
          dateFrom: "",
          dateTo: "",
          keys: []
        },
        dataPoints: chartType === "word" ? wordCloudList : dataPoints
      },
      position: components.length,
      key: uuidv4()
    };
    components.push(template);

    await updateBrief(brief);
    // await getBrief();
    refreshChild();
    //scrollBottom()
  };

  const handleCloseChartDialog = async (action, data, isPma) => {
   
    console.log('edit brief data: ', data)
    setChartDialogOpen(false);
    if (action !== "cancel") {
      if (!isPma) {
        insertStandardChart(data);
      } else {
        // insert a custom asset
        // first create the asset!
        setIsLoading(true);

        let { components } = brief === false ? { components: [] } : brief;
        const asset = {
          key: uuidv4(),
          contentType: "customChartPMA",
          url: null,
          date: moment().valueOf(),
          deleted: null,
          dataset: data.dataset,
          settings: {
            title: data.title,
            subtitle: `${data.geo} - ${data.time}`,
            type: data.type,
            dataset: data.dataset,
            geo: data.geo,
            time: data.time,
            dateRange: null,
            metrics: data.metrics,
            products: data.products,
            chartColor: data.chartColor || null,
            chartOptions: data.chartOptions || null,
            plugin: {
              org: "PMA",
              dataset: data.dataset
            }
          }
        };
        if (data.aisleName) {
          asset.settings.aisleName = data.aisleName;
          asset.settings["Aisle Name"] = data.aisleName;
        }
        if (data.dataset === "sentiment") {
          asset.settings.question = data.question;
          asset.settings.fullSurvey = data.fullSurvey;
          asset.settings.querySegments = data.querySegments;
          asset.settings.compareTo = data.compareTo;
          asset.settings.fullSurvey = data.fullSurvey;
          asset.settings.subSegment = data.subSegment;
        }
        console.log('edit brief asset: ', asset)

        const newAsset = await apiManager.createPMIChartAsset(asset);

        const template = {
          type: 6,
          content: {
            title: data.title,
            subTitle: `${data.geo} - ${data.time}`,
            assetId: newAsset.createdId,
            size: 600,
            align: "center",
            dataset: data.dataset,
            settings: asset.settings
          },
          position: components.length,
          key: newAsset.result.key,
          date: newAsset.result.date
        };
        if (data.aisleName) {
          template.content.aisleName = data.aisleName;
          template.content["Aisle Name"] = data.aisleName;
        }

        components.push(template);
        await updateBrief(brief);
        refreshChild();
      }
    }
  };

  const addChart = async () => {
    scrollBottom();

    // TODO: test if user has access to custom data
    const hasCustomOptions =
      user.orgId || (user.orgAffiliates && user.orgAffiliates.length > 0);

    if (hasCustomOptions || true) {
      // use dialog...
      setChartDialogOpen(true);
    } else {
      insertStandardChart();
    }
  };

  const changeImage = async (component, e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditImageOpen(true);
    setImageComponent(component);
  };

  const changeVideo = async component => {
    setIsEditVideoOpen(true);
    setImageComponent(component);
  };

  const closeChangeImage = async () => {
    setIsEditImageOpen(false);
    setIsEditVideoOpen(false);
  };

  const updateImage = async image => {
    const { url, assetId } = image;
    let { position } = imageComponent;

    brief.components = brief.components.map(value => {
      if (value.position == position) {
        value.content.assetId = assetId;
        value.content.url = url;
      }
      return value;
    });

    /*
  let index = 0;
  brief.components.sort((a, b) => a.position - b.position);
  brief.components = brief.components.filter((comp, key) => {
     
    if(comp && comp.type){
     comp.position = index++;
    }
   return comp && comp.type;
 })

  
  */

    //console.log(image)

    await updateBrief(brief, true);

    setIsEditImageOpen(false);
    setIsEditVideoOpen(false);

    setTimeout(function() {
      refreshChild();
    }, 1000);

    //console.log(brief);
  };

  const updateBriefTitle = async (brief, isRefresh) => {
    //brief.title = title;
    setIsLoading(true);
    await apiManager.updateBrief(brief);
    setIsLoading(false);
  };

  const updateBrief = async (brief, isRefresh) => {
    setIsLoading(true);
    // await apiManager.updateBriefChart(brief);
    await apiManager.updateBrief(brief);
    setIsLoading(false);
  };

  const renderTitle = () => {
    console.log('render title')
    return (
      <div>
        <EditTitle updateBrief={updateBriefTitle} brief={brief} />
      </div>
    );
  };

  const renderBrief = () => {
    return (
      <Container
        brief={brief}
        history={history}
        refreshChild={refreshChild}
        isMobile={isMobile}
        changeVideo={changeVideo}
        changeImage={changeImage}
        refreshImage={refreshImage}
        apiManager={apiManager}
        updateBrief={updateBrief}
        msg={setMsg}
        openSettings={handleRightbarOpen}
        getBrief={getBrief}
        user={user}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          {access != "viewer" ? (
            <div className={classes.navBar}>
              <div className={classes.primaryCTA}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  onClick={cancelClick}
                  className={"brandColor2"}
                >
                  {brief &&
                  brief.components.length <= 1 &&
                  brief.components[0].type === 0
                    ? "Discard"
                    : "Publish"}
                </Button>
              </div>
            </div>
          ) : null}
        </Grid>

        <Grid container alignItems="center" justify="center" direction="column">
          <Grid
            container
            className={classes.briefArea}
            justify="center"
            // alignItems="stretch"

            direction="column"
            alignItems="center"
            alignContent="stretch"
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ paddingTop: 24, width: "100%" }}
            >
              {brief && renderTitle()}
              {brief && renderBrief()}
            </Grid>
          </Grid>
          {isLoading && <Grid
            container
            spacing={2}
            style={{ display: !isLoading ? "none" : "block" }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <LinearIndeterminate />
            </Grid>
          </Grid>}
        </Grid>
      </Grid>
      {isModalOpen && 
      <Notification
        isOpen={isModalOpen}
        message={message}
        closeModal={closeModal}
      />
      }

      {openSnackbar && 
      <CustomizedSnackbar
        open={openSnackbar}
        handleClose={e => setOpenSnackbar(false)}
      />
      }
      <AddPanel
        isMobile={isMobile}
        permissions={permissions}
        org={org}
        funcs={{
          addParagraph,
          addImage,
          addVideo,
          addChart,
          addLink,
          updateImage,
          isEditImageOpen,
          closeChangeImage,
          isEditVideoOpen,
          addAsset
        }}
      />
      {isChartDialogOpen && (
        <ChartTemplatesDialog
          classes={classes}
          apiManager={apiManager}
          isOpen={isChartDialogOpen}
          handleClose={handleCloseChartDialog}
          allowStandardChart
          insertOnly
          user={user}
        />
      )}
    </div>
  );
};

EditBrief.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(EditBrief);
