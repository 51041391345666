import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, Select, MenuItem, Link } from '@material-ui/core' 
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PluginSelector from '../../components/PluginSelector'

const schema = {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64
      }
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128
      }
    },
    policy: {
      presence: { allowEmpty: false, message: 'is required' },
      checked: true
    }
  };

export default function EditTeam(props) {
  
 
  const { classes, asset, handleUpdate, apiManager, plugins, handlePlugins } = props;

 

  const [open, setOpen] = React.useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.id]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.id]: true
      }
    }));
  };

  const updateAction = async () => { 
    await handleUpdate(formState.values);
    setOpen(false)
  }
 

  const handleClose = () => { 
    setOpen(false)
  };

 
  const openModal = () => {
    setOpen(true)
    const { name, access, plugins } = asset;
    const  formState = {
            values: {
                name,
                access,
                plugins
            }
        }
   
    setFormState(formState)
  }
 

  return (<span> 
       <IconButton aria-label="close" className={classes.icon} onClick={openModal}>
        <FontAwesomeIcon icon={faEdit} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit a team</DialogTitle>
        <DialogContent style={{width: '600px'}}>
          <DialogContentText>
          <Typography variant="h6" gutterBottom>
      Team name
    </Typography>
          <span><TextField  
            id="name"
            label="Team name"
            type="text"
            fullWidth
            onChange={handleChange} 
            value={formState.values.name || ''}
            variant="outlined"  
          /> </span>
          <br /> <br />
          <Typography variant="h6" gutterBottom>
      Team access level
    </Typography>
          <Select
                            label="Access level"
                            id="access"
                            value={formState.values.access}
                            onChange={handleChange}
                            style={{color: '#22b9ea'}}
                        >
                            <MenuItem value={'viewer'}>Viewer</MenuItem>
                            <MenuItem value={'editor'}>Editor</MenuItem>
                            <MenuItem value={'admin'}>Co-Owner</MenuItem>
                        </Select>
                        <br /> <br />
                 <Grid item xs={12} >
            <Typography variant="h6" gutterBottom>
      Select plugins
    </Typography>
                <PluginSelector plugins={plugins} asset={asset} handleChange={handlePlugins} />
            </Grid>
         
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateAction} color="primary">
            Update team
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
