import React from 'react';
//import { Grid } from '@material-ui/core';
//import IconButton from '@material-ui/core/IconButton';
import isEmpty from "lodash/isEmpty";

//import { faChartPie } from "@fortawesome/pro-light-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearIndeterminate from '../../Briefs/components/LinearIndeterminate'
////import moment from 'moment';
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import * as am4plugins_sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
//import RenderBriefInvites from '../../People/components/RenderBriefInvites';

am4core.addLicense("CH290132791")
const toTitleCase = (str) => {
    if(str){
        if(str.includes('click')){
            return str;
        }
            return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
    ).replace(/-aisle-floral\S*/g, '- Floral');
    } else {
        return null
    }
  }
function maybeDisposeChart(chartdiv) {
    // if (chartReg[chartdiv]) {
    //   chartReg[chartdiv].dispose();
    //   delete chartReg[chartdiv];
    // }
  }

class ChartDisplay extends React.Component {
    constructor(props) {		
        super();	
        const {  chartDiv, chartSettings, chartData, appSettings, hideLegend, chartAssetId, selectComponent } = props;	
        //this.chartSettings = props.chartSettings ? props.chartSettings : {};	
        this.state = {
            chartDiv: '', 
            chartSettings: '', 
            chartData: '',
            customChartData: '',
            appSettings: '',  
            hideLegend: '',  
            chartAssetId: '',
            isExpanded: false, 
        }
    }	

    loadChartData = async() => {
       
        let { chartSettings, chartAssetId, component } = this.props;
        let newChartSettings
        //get the asset from DB NOT component.
        if (chartAssetId) {
            let response;
            response = await this.props.apiManager.getPMIChartAssets(chartAssetId);
            newChartSettings = response.assets[0].settings;
            newChartSettings.category = newChartSettings.dataset;
        } else {
            newChartSettings = {...chartSettings}
        }
        
        let aisleName = 'AISLE-FRUIT';
        if( newChartSettings.category ) {
            if( newChartSettings.category === 'produce-veg') aisleName = 'AISLE-VEGETABLES';
            if( newChartSettings.category === 'floral') aisleName = 'AISLE-FLORAL';
        }
        if( newChartSettings.aisleName) aisleName = newChartSettings.aisleName;
        let dataset = newChartSettings.category ? newChartSettings.category : 'produce';

        //make sure that if floral dataset we are calling the thing
        if( aisleName !== 'AISLE-FLORAL' && newChartSettings.dataset === 'floral'){
            aisleName = 'AISLE-FLORAL';
            dataset = newChartSettings.dataset
        }
        const chartData = await this.props.apiManager.getPMIChartData({
                dataset, 
                aisleName, 
                geo: newChartSettings.geo, 
                time: newChartSettings.time, 
                metrics: newChartSettings.metrics, 
                products: newChartSettings.products
        });
        let customChartData;
        if (newChartSettings && newChartSettings.customDataPoints && !isEmpty(newChartSettings.customDataPoints)){
            customChartData = newChartSettings.customDataPoints
        }
        const isPercent = newChartSettings && newChartSettings.metrics && newChartSettings.metrics.toString().includes('%');
        this.setState({chartData, chartSettings: newChartSettings, customChartData, isPercent}, () => {
        });
    }
    componentDidMount = async () => {
        //Create Chart and Render	
        const {
                chartSettings, 
                chartData, 
                appSettings, 
                hideLegend, 
                chartAssetId, chartDiv } = this.props;
        //const { chartSettings} = this;
        am4core.options.autoDispose = true; 
        //am4core.options.queue = true; //load one at a time
        //am4core.options.onlyShowOnViewport = true; //lazy load
        am4core.options.animationsEnabled = false; //help wiht performance
        
        if( !chartData ) {
            await this.loadChartData();
            this.setState({
                appSettings, 
                hideLegend, 
                chartAssetId
             }, () => this.state.chartData && this.drawChart())
         } else {
            this.setState({
                chartData, 
                chartSettings,  
                appSettings, 
                hideLegend, 
                chartAssetId
             }, () => this.state.chartData && this.drawChart())
         }
         
    }	
    drawChart = () => {
        const { chartData, chartSettings } = this.state;
        const { inBrief, chartDiv } = this.props;
        //const chartType = chartSettings.type === "Donut" ? 'donut' : 'bar';
        let chartType = chartSettings.type.toLowerCase();
        if(chartType === 'clustered bar') {
          chartType = 'bar';
        }
        const chartColor = chartSettings.chartColor
        let chart;
        let series;


       
        // pie series
        if (chartType === 'donut' || chartType === 'pie' || chartType === "doughnut" ) {
            chart = am4core.create(chartDiv, am4charts.PieChart);
        }

        // xy series
        if (chartType === 'bar' || chartType === 'column'|| chartType === "line" || chartType === "scatter") {
            chart = am4core.create(chartDiv, am4charts.XYChart);
        }
        if (chartType === "pyramid") {
            chart = am4core.create(chartDiv, am4charts.SlicedChart);
        }
        if (chartType === "gauge") {
            chart = am4core.create(chartDiv, am4charts.RadarChart);
        }
        if (chartType === "word") {
            chart = am4core.create(chartDiv, am4plugins_wordCloud.WordCloud);
        }
        if (chartColor) {
            chart.colors.passOptions = {};
            chart.colors.reuse = true;
            chart.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
          }
        // grab the data now.
        chartData.dataset.forEach(lineItem => {
            const item = {
                product: lineItem.Product
            };
            item[chartSettings.metrics] = lineItem[chartSettings.metrics] ? lineItem[chartSettings.metrics] : 0;
            if(chart && chart.data) {
              chart.data.push(item)
            }
        })
        // look for 0 values...
        chartSettings.products.forEach(product => {
            if (product !== 'every' && !product.toLowerCase().includes('all')) {
                let found = false;
                chart.data.forEach(item => {
                    if (item.product === product) {
                        found = true;
                    }
                })
                if (!found) {
                  
                    const newItem = { product };
                    newItem[chartSettings.metrics] = 0.0;

                    chart.data.push(newItem);
                }
            }
        })
        if(this.state.customChartData && Array.isArray(this.state.customChartData)){
            chart.data = chart.data.map((el, index) => ({
                ...el,
                compare: this.state.customChartData[index] ? this.state.customChartData[index].y : ''
            }))
        }
        if (chartType === 'donut' || chartType === 'pie') {
            series = chart.series.push(new am4charts.PieSeries());
            if(chartType === 'donut'){
                    series.innerRadius = am4core.percent(30);
                    series.radius = am4core.percent(60)
            } else {
                series.radius = am4core.percent(55)
            }
            series.dataFields.value = chartSettings.metrics;
            // Add bottom label
            let label = chart.chartContainer.createChild(am4core.Label);
            label.text = chartSettings.metrics;
            label.x = am4core.percent(50)
            label.horizontalCenter = "middle"
            label.y = am4core.percent(101);

            series.dataFields.category = "product";
            series.labels.template.fontSize = "12px";
            series.labels.template.fontWeight = 300;
            series.labels.template.maxWidth = 150;
            series.labels.template.wrap = true;
            series.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(this.state.isPercent){
                series.slices.template.tooltipText = "Category: {category}\nValue: {value.value}%"
            } else {
                series.slices.template.tooltipText = "Category: {category}\nValue: {value.value}";
            }
            if (chartColor) {
                series.colors.passOptions = {};
                series.colors.reuse = true;
                series.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
            }
            if(!inBrief){
                series.labels.template.disabled = true;
                if(chartType === 'donut') series.innerRadius = am4core.percent(60);

                series.radius = am4core.percent(100)
            }
            if(chart.data.length > 10 && inBrief){
                let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
                grouper.limit = 10;
                grouper.groupName = "Other (click twice to see)";
                grouper.clickBehavior = "zoom";  
                    grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
                        if(ev.visible){
                            series.labels.template.disabled = true;
                            series.ticks.template.disabled = true;                            
                        } else {
                            series.labels.template.disabled = false;
                            series.ticks.template.disabled = false;
                        }
                        this.setState({isExpanded: ev.visible})
                  }, this);                 
                 
            }
          
        }


        // Create axes

        // xy series
        if (chartType === 'bar') {
            if (chartColor) {
                chart.colors.passOptions = {};
                chart.colors.reuse = true;
                chart.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
            }
            let item = { product: 'text negative'}
            item[chartSettings.metrics] = -100000000;
            // chart.data.push(item)
            
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "product";
            categoryAxis.renderer.inversed = true;
            //categoryAxis.title.text = "Product";
            
            categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(!inBrief){
                categoryAxis.renderer.labels.template.disabled = true;
            }
            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = chartSettings.metrics;
            if (this.state.isPercent){
                valueAxis.numberFormatter.numberFormat = "#.00"
            } 
            //console.log('bar chart data', chart.data)
            const createSeries = (field, name) =>{
                 // Create series
                series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueX = field;
                series.dataFields.categoryY = "product";
            }
           
            this.state.customChartData && createSeries('compare', 'user');
            createSeries(chartSettings.metrics, 'normal');
            //ADJUST HEIGHT BASED ON CONTENT
            // Set cell size in pixels
            let cellSize = 15;
            chart.events.on("datavalidated", function(ev) {

            // Get objects of interest
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
            });

            // series.name = "Name";
            // series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryY}\nValue: {valueX}";
            if(this.state.isPercent){
                series.columns.template.tooltipText = "Category: {categoryY}\nValue: {valueX}%"
            } else {
                series.columns.template.tooltipText = "Category: {categoryY}\nValue: {valueX}";
            }
            
            // series.stackToNegative = false;
            series.columns.template.adapter.add("fill", function (fill, target) {
                if (target.dataItem && (target.dataItem.valueY < 0)) {
                    return am4core.color("#a55");
                }
                else {
                    return chart.colors.getIndex(target.dataItem.index);
                }
            });
            categoryAxis.renderer.grid.template.location = 0;
            //categoryAxis.renderer.labels.template.rotation = -60;
            // categoryAxis.renderer.labels.template.verticalCenter = "left";
            // categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontSize = "12px";
            categoryAxis.renderer.labels.template.fontWeight = 300;
            categoryAxis.renderer.labels.template.maxWidth = 200;
            categoryAxis.renderer.labels.template.truncate =
            true;
            categoryAxis.renderer.minGridDistance = 1;

            var columnTemplate = series.columns.template;

            columnTemplate.fillOpacity = .8;
            columnTemplate.strokeOpacity = 0;
        }
        if (chartType === 'column') {

            let item = { product: 'text negative'}
            item[chartSettings.metrics] = -100000000;
            // chart.data.push(item)
            
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "product";
            categoryAxis.renderer.inversed = true;
            //categoryAxis.title.text = "Product";
            categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(!inBrief){
                categoryAxis.renderer.labels.template.disabled = true;
            }
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = chartSettings.metrics;
            if (this.state.isPercent){
                valueAxis.numberFormatter.numberFormat = "#.00"
            } 
            
            // Create series
            const createSeries = (field, name) =>{
                series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = field;
                series.dataFields.categoryX = "product";
                // series.name = "Name";
                // series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryY}\nValue: {valueX}";
                if(this.state.isPercent){
                    series.columns.template.tooltipText = "Category: {categoryY}\nValue: {valueY}%"
                } else {
                    series.columns.template.tooltipText = "Category: {categoryX}\nValue: {valueY}";
                }
                series.stackToNegative = false;
                series.columns.template.adapter.add("fill", function (fill, target) {
                    if (target.dataItem && (target.dataItem.valueY < 0)) {
                        return am4core.color("#a55");
                    }
                    else {
                        return chart.colors.getIndex(target.dataItem.index);
                    }
                });
            }
            
            
            this.state.customChartData && createSeries('compare', 'user');
            createSeries(chartSettings.metrics, 'normal');
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.labels.template.fontSize = "12px";
            categoryAxis.renderer.labels.template.fontWeight = 300;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.rotation = 270;
            if(inBrief){
                categoryAxis.renderer.minHeight = 110;
            }

            var columnTemplate = series.columns.template;

            columnTemplate.fillOpacity = .8;
            columnTemplate.strokeOpacity = 0;
            if(!this.props.isEditing){
                chart.scrollbarX = new am4core.Scrollbar();
                chart.scrollbarY = new am4core.Scrollbar();
            }
           
        }
        if (chartType === 'pyramid'){
            chart.paddingTop= 20;
            chart.paddingBottom = 30;
            let item = { product: 'text negative'}
            item[chartSettings.metrics] = -100000000;
            let series = chart.series.push(new am4charts.PyramidSeries());
            series.dataFields.value = chartSettings.metrics;
            series.dataFields.category = "product"
            series.alignLabels = true;
            series.valueIs = "height";
            series.labels.template.fontSize = "10px";
            series.labels.template.fontWeight = 300;
            series.labels.template.paddingBottom = 5;
            series.labels.template.paddingTop = 5;
            series.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(this.state.isPercent){
                series.slices.template.tooltipText = "Category: {category}\nValue: {value.value}%"
            } else {
                series.slices.template.tooltipText = "Category: {category}\nValue: {value.value}";
            }
            // Add bottom label
            let label = chart.chartContainer.createChild(am4core.Label);
            label.text = chartSettings.metrics;
            label.align = "center";
            label.x = am4core.percent(50)
            label.horizontalCenter = "middle"
            label.y = am4core.percent(101);
            if(chart.data.length > 10 && inBrief){
                let grouper = series.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
                grouper.limit = 10;
                grouper.groupName = "Other (click twice to see)";
                grouper.clickBehavior = "zoom";  
                    grouper.zoomOutButton.events.on("visibilitychanged", function(ev) {
                        if(ev.visible){
                            series.labels.template.disabled = true;
                            series.ticks.template.disabled = true;                            
                        } else {
                            series.labels.template.disabled = false;
                            series.ticks.template.disabled = false;
                        }
                        this.setState({isExpanded: ev.visible})
                  }, this);                 
                 
            }
            if (chartColor) {
                series.colors.passOptions = {};
                series.colors.reuse = true;
                series.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
            }
            
            if(!inBrief){
                series.labels.template.disabled = true;
                series.ticks.template.disabled = true;
            }
        }
        if (chartType === 'gauge'){
            if (chartColor) {
                chart.colors.passOptions = {};
                chart.colors.reuse = true;
                chart.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2), am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)];
            }
            //Gauge full is calculated off the max value, if greater than 100, full = maxValue otherwise default to 100
           
            let maxValue = chart.data.map(point => 
                typeof point[chartSettings.metrics] !== 'number' ? parseFloat(point[chartSettings.metrics].replace(/[$,]+/g,"")) : point[chartSettings.metrics] )
            maxValue = Math.max(...maxValue)

            const gaugeData = chart.data.map(point => ({
                ...point,
                full: maxValue > 100 ? maxValue : 100
            })
            );
            chart.data = gaugeData;
            chart.startAngle = -90;
            chart.endAngle = 180;
            chart.innerRadius = am4core.percent(20);

            // Create axes
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "product";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontWeight = 300;
            categoryAxis.renderer.labels.template.fontSize = "12px";
            categoryAxis.renderer.labels.template.adapter.add("fill", function(
                fill,
                target
            ) {
                return target.dataItem.index >= 0
                ? chart.colors.getIndex(target.dataItem.index)
                : fill;
            });
            categoryAxis.renderer.minGridDistance = 10;
            categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(!inBrief){
                categoryAxis.renderer.labels.template.disabled = true;
            }
            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.max = maxValue > 100 ? maxValue : 100;
            valueAxis.strictMinMax = true;
            //valueAxis.title.text = chartSettings.metrics
            let series1 = chart.series.push(new am4charts.RadarColumnSeries());
            series1.dataFields.valueX = chartSettings.metrics;
            series1.dataFields.categoryY = "product";
            series1.clustered = false;
            series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
                "alternativeBackground"
            );
            series1.columns.template.fillOpacity = 0.08;
            series1.columns.template.cornerRadiusTopLeft = 20;
            series1.columns.template.strokeWidth = 0;
            series1.columns.template.radarColumn.cornerRadius = 20;

            let series2 = chart.series.push(new am4charts.RadarColumnSeries());
            series2.dataFields.valueX = chartSettings.metrics;

            series2.dataFields.categoryY = "product";
            series2.clustered = false;
            series2.columns.template.strokeWidth = 0;
            series2.columns.template.tooltipText = `{product}: [bold]{${chartSettings.metrics}}[/]: {valueX}`;
            series2.columns.template.radarColumn.cornerRadius = 20;
            series2.columns.template.adapter.add("fill", function(fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            let label = chart.chartContainer.createChild(am4core.Label);
            label.text = chartSettings.metrics;
            label.align = "center";
            label.x = am4core.percent(50)
            label.horizontalCenter = "middle"
            label.y = am4core.percent(101);
            // Add cursor
            // let bullet = series2.bullets.push(new am4charts.LabelBullet())
            // bullet.interactionsEnabled = false
            // bullet.dx = 10;
            // bullet.dy= 10
            // bullet.label.text = '{valueX}'
            chart.cursor = new am4charts.RadarCursor();
        }
        if (chartType === "word") {
            
            let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
            
            series.accuracy = 5;
            series.step = 15;
            series.rotationThreshold = 0.7;
            series.maxCount = 200;
            series.labels.template.margin(4, 4, 4, 4);
            series.fontSize = 200;
            series.maxFontSize = am4core.percent(30);
            series.minFontSize = am4core.percent(4);
            series.maxFontSize = am4core.percent(24);
            // it's an array
            series.data = [];

            chart.data.forEach(point => {
            series.data.push({ tag: point.product, weight: point[chartSettings.metrics]});
            });

            series.dataFields.word = 'tag';
            series.dataFields.value = 'weight';

            series.randomness = 0.1;
    
            series.colors = new am4core.ColorSet();
            series.colors.passOptions = {};
            series.colors.reuse = true;
            if (chartColor) {
                series.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2) , am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)]
              }
            series.angles = [0, -90];
            // series.fontWeight = "700";
          }
          if (chartType === "line" || chartType === "scatter") {
      
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "product"
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.labels.template.fontSize = "12px";
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.rotation = 270;
            //categoryAxis.renderer.minHeight = 110;
            categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
                return toTitleCase(text)
            })
            if(!inBrief){
                categoryAxis.renderer.labels.template.disabled = true;
            }
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = chartSettings.metrics;
            if (this.state.isPercent){
                valueAxis.numberFormatter.numberFormat = "#.00'%'"
            } 
            let series2;
            const createSeries = (field, name, ser, color) => {
                ser = chart.series.push(new am4charts.LineSeries());
                ser.dataFields.valueY = field;
                ser.dataFields.categoryX = "product"
                
                //tooltip
                if(this.state.isPercent){
                    ser.tooltipText = `{categoryX}: {${field}}%`
                } else {
                    ser.tooltipText = `{categoryX}: {${field}}`;
                }
                ser.tooltipText = `{categoryX}: {${field}}`;
                ser.tooltip.pointerOrientation = "vertical";
                let bullet = ser.bullets.push(new am4charts.Bullet());
                let dot = bullet.createChild(am4core.Circle);
                dot.horizontalCenter = "middle";
                dot.verticalCenter = "middle";
                dot.strokeWidth = 0;
                dot.fill = chart.colors.getIndex(color);
                ser.stroke = chart.colors.getIndex(color);
                dot.width = 10;
                dot.height = 19;

                // if (chartColor) {
                //     ser.colors.list = [am4core.color(chartColor), am4core.color(chartColor).lighten(0.2) , am4core.color(chartColor).lighten(-0.2), am4core.color(chartColor).lighten(0.4), am4core.color(chartColor).lighten(-0.4)]
                //   }
            }
            this.state.customChartData && createSeries('compare', 'user', series, 0);
            createSeries(chartSettings.metrics, 'normal', series2, 2);

            if (chartType === "scatter") {
              series.strokeOpacity = 0;
            }
            
      
            // let dotLabel = series.bullets.push(new am4charts.LabelBullet());
            // dotLabel.label.text = "{y}";
            // dotLabel.label.horizontalCenter = "center";
            // dotLabel.label.dx = 10;
            
            chart.cursor = new am4charts.XYCursor();
                chart.scrollbarX = new am4core.Scrollbar();
                chart.scrollbarY = new am4core.Scrollbar();
            //x.legend = new am4charts.Legend();
          }
        // And, for a good measure, let's add a legend
        // if (chartType !=='bar' && !this.state.hideLegend && chart.data.length < 7) {
        //     chart.legend = new am4charts.Legend();
        // }
        let label = chart.createChild(am4core.Label);
        label.text = `[bold]Source:[/] IRI Integrated Fresh point-of-sale data combining FW + RW, Multi-Outlet geography. If you have questions or would like to request additional information, please contact IRI at: FreshProduce@iriworldwide.com`;
        label.align = "left";
        label.width = am4core.percent(95);
        label.fontWeight = 300;
        label.fontSize = 10; 
        label.wrap = true;
        label.paddingTop = 20;
        chart.paddingRight = 40;
        this.setState({chartObject: chart}); 
        
        chart.svgContainer.htmlElement.style.minHeight = 300;
        // see if we've been asked to populate the title block
        if (this.props.chartDivTitle) {
            const el = document.getElementById(this.props.chartDivTitle);
            let datasetAisle = "FLORAL: ";
            if( chartSettings.dataset === 'produce') {
                datasetAisle = chartSettings.aisleName ? `${chartSettings.aisleName}: ` : ''
            }
            if (el) {
                //el.innerHTML = `<b>${datasetAisle}${chartSettings.title}</b><br/>${chartSettings.geo} - ${chartSettings.time}`;
                el.innerHTML = `<b>${chartSettings.title}</b><br/>${chartSettings.geo} - ${chartSettings.time}`;
            }
        }
        if(this.props.isEditing){
            let updatedComponent = {...this.props.component}
            updatedComponent.content.pmaDataPoints = chart.data
            updatedComponent.content.metrics = chartSettings.metrics;
            this.props.setPassed(updatedComponent)
        }
    }
    
    shouldComponentUpdate(nextProps, nextState){
		//Check if Chart-options has changed and determine if component has to be updated
        if(nextProps.triggerExport){
            return true
        } else if(nextState.isExpanded !== this.state.isExpanded) {
            return true
        } else {
            return !(nextProps.chartSettings === this.props.chartSettings || nextState.chartSettings === this.state.chartSettings);
        }
    }
	componentDidUpdate(oldProps) {
       
         //EXPORT PNG
        if (this.props.triggerExport) {
            this.state.chartObject.exporting.export("png")
            this.props.handleClose();
            return ''
        } else {
            //Update Chart Options & Render
            this.chartSettings = this.props.chartSettings;
            this.state.chartData && this.drawChart();
        }
	}
    // componentWillUnmount() {
    //     //Destroy chart and remove reference 
    //     const { chartObject } = this.state;
    //     if (chartObject) {
    //         this.state.chartObject.dispose();
    //     }
    // }		


render() {
   
    return (
        <div style={{width: '95%', textAlign: 'center'}}>
            <div style ={{width:'30%'}}>
            loading chart...
            <LinearIndeterminate />
            </div>
        </div>
    )
}
}


export default ChartDisplay;
