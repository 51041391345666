import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ForgotPassword from '../Modals/ForgotPassword'
import RegisterSnackBar from '../SignIn/components/RegisterSnackBar'
import apiManager from '../../../src/ApiManager';
import {
    Container,
  Grid,
  Button, 
  TextField,
  Link,
  Typography
} from '@material-ui/core'; 
import axios from 'axios';

import { API_LOCAL } from '../../constants'; 
import { USE_LOCAL } from '../../constants'; 
import image from './logo.png';
import SignInButton from '../SignIn/SignInButton'
  
import { PROD_API_LAMBDA, USE_PROD, API_LAMBDA  } from '../../constants'; 
import '../SignUp/css/style.css'
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

makeStyles(theme => {
  console.log(theme)
});

const useStyles = makeStyles(theme => ({
  root: { 
    //height: '100vh',
    //minHeight: 900,
    '& .MuiLink-root': {
        color: '#22b9ea',
    },
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    // height: '72%',
    // display: 'flex',
    flexDirection: 'column'
  },
  panelSpacer: {
      width: 1,
      height: '15vh',
      minHeight: 50,
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
   // display: 'flex', 
    width: '45vw',
    minWidth: 320,
    maxWidth: 470,
    
  },
  form: {
    // borderColor: '#00BFFF',
    // borderStyle: 'solid',
    // borderWidth: 'medium',
    borderRadius: '8px',
    position: 'relative',
    top: '40%',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingBottom: 48,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    background: '#fff'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  suggestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: 'white',
      },
    },
  },
}));

const Invites = props => {
  const { history, updateUser } = props; 

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false, 
    isModalOpen : false, 
    snackMessage: '',
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const registerClick = async event => {
    event.preventDefault();
    history.push('/register');
  }

  const handleSignIn = async event => {
    event.preventDefault();
    setFormState(formState => ({
        ...formState,
        error: ''
      }));
    const { email, password } = formState.values;
    const url = (API_LOCAL) + 'users/auth/login';
    try {
      const result = await axios.post(url, { email, password }, { headers: apiManager.getHeaders() })
     
      if(result && result.data && result.data.email){ 
        setFormState(formState => ({
          ...formState,
          isSuccessfull:true,
          isModalOpen:true,
          snackMessage: "Sign In Successfull"
        }));
        updateUser(result.data);
        sessionStorage.setItem('token', result.data.token);
        history.push('/briefs');
      }
      else {
        setFormState(formState => ({
          ...formState,
          error: 'Incorrect user name and/or password asdfasdf',
          isSuccessfull:false,
          isModalOpen:true,
          snackMessage: result.data.message
        }));
        //updateUser({ name: 'test-user' });
        //history.push('/dashboard');
      }
    }
    catch(ex){
      console.log(ex);
      setFormState(formState => ({
        ...formState,
        error: ex.message,
        isSuccessfull:false,
        isModalOpen:true,
        snackMessage: ex.message
      }));
    }
   
  };

  const forgotPasswordClick = async(event) => {
    event.preventDefault();
  }
  const closeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFormState({isModalOpen: false})
  }

  const clickLogin = () => {
    history.push('/logout');
  }

  const clickSignUp = () => {
    history.push('/register');
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
    return (
      
      <div className={classes.root}>
        <style>{`.pageBody { background: linear-gradient(#000055, black) }
         .MuiButton-containedPrimary, .MuiButton-label {
          color: #000 !important;
        
          background-color: transparent !important; 
        
      }` }</style>
         <Grid
            className={classes.content}
            item
            lg={12}
            me={12}
            sm={12}
            xs={12}
            alignItems="center"
            container
          >
            <div className={classes.content}>
              <div className={classes.panelSpacer} />
              <div className={classes.contentBody}>
                <form
                  className={classes.form} 
                > 
                <h2>Invite accepted</h2>
                <p>Sign in or Sign up below to access your Brief!</p>
                <Button 
                    color="primary"  
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={clickSignUp}
                    className='breefly-btn-primary'
                  >
                    Sign Up
                  </Button> &nbsp;&nbsp;
                  <Button 
                    color="default"  
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={clickLogin}
                    className='breefly-btn-primary'
                  >
                    Sign in
                  </Button> 
                </form>
              </div>
            </div>
          </Grid>       
      </div>
    );
  };
  
  Invites.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
  };
  
  export default withRouter(Invites);
  