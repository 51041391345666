import { extractSubDomain, getStageByDomain } from './helpers';

// API
export const API_ROOT = process.env.PHASE === 'http://localhost:8020'
export const API_URL = '/api/v1';
export const IS_DEVELOPMENT = process.env.PHASE === 'development';

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout

// Determine stage this code is running on by domain
export const STAGE = getStageByDomain(); // default is stage;
console.log('STAGE: ==============> ', STAGE);
const LAMBDA_STAGE = 'https://06ny9blzvh.execute-api.us-east-1.amazonaws.com/stage/api/v1/';
const LAMBDA_PROD  = 'https://gu71dnob87.execute-api.us-east-1.amazonaws.com/prod/api/v1/';

let LAMBDA_URL = '';
switch (STAGE) {
  case 'stage':
    LAMBDA_URL = LAMBDA_STAGE;
    break;
  case 'prod':
    LAMBDA_URL = LAMBDA_PROD;
    break;
  default:
    LAMBDA_URL = LAMBDA_STAGE;
}
export const API_LAMBDA = LAMBDA_URL;

// export const API_LAMBDA = 'https://gu71dnob87.execute-api.us-east-1.amazonaws.com/prod/api/v1/';
// export const API_LAMBDA = 'https://06ny9blzvh.execute-api.us-east-1.amazonaws.com/stage/api/v1/';

export const USE_LOCAL = ('local' === STAGE)
export const USE_PROD = false;
export const API_LOCAL = !USE_LOCAL ? API_LAMBDA : 'http://localhost:8020/api/v1/';

// TODO: Delete line below after launch of new Stripe Plans to PROD: 4/1/2021 - JD ONLY
// export const STRIPE_PROD_PUBLIC_KEY = 'pk_live_tAGSRSbQFRMT3UTtHfHxplTE';
export const STRIPE_PUBLIC_KEY = ('prod' === STAGE) ? 'pk_live_tAGSRSbQFRMT3UTtHfHxplTE' : 'pk_test_VZq8ieqoxtCosnGNAIg6WpQF';

// Brand / SubDomain Config
export const SUBDOMAIN_LOCAL = 'stage'; // use PMA-local for pma subdomain, local for non-pma subdomain, curve10-stage, etc.
export const ORG_BRAND = USE_LOCAL ? SUBDOMAIN_LOCAL : extractSubDomain();

const stripeProducts = {
  stage: {
    // (Stripe v2 - Checkout Session) 
    // Orignal Products/Plans
    basicProduct: 'prod_FzudAFAdiH9JuG',
    proProduct: 'prod_FzueHHBfakPDyf',
    tmreProduct: 'prod_G6EosuQSNH9NSi',
    proProductPlan: 'plan_G4dQfZKxphcw7u',
    basicProductPlan: 'plan_G4dPmWtEuGNmFr',
    tmreProductPlan: 'plan_G6Ep3t7hi0yA0P',

    // (Stripe v3 - Checkout Session) 
    // New Stripe Pricing Schedule / Checkout Session Integration 
    // individualPlan: 'price_1IWlKtHI1WBKZbjakqSHRVIV', // $79/month
    individualPlan: 'price_1JUFd7HI1WBKZbjax1rWi9uV', // $79/Daily
    individualTrial: 14, // Days
    standAloneOrgPlan: 'price_1IWlMsHI1WBKZbjahu7X6tFX', // $295/month per 5 Seats
    standAloneOrgTrial: 30, // Days
    parentOrgPlan: 'price_1IWnvLHI1WBKZbja7bEAf8tX', // $225/month per 5 seats - Parent Org
    parentOrgTrial: 30, // Days
    affiliateOrgPlan: 'price_1IWqnrHI1WBKZbjaKtb1jo88', // $225/month per 5 seats - Affiliate Org
    affiliateOrgTrial: 30,
    singlePrice6x5: 'price_1L3556HI1WBKZbjaQw2pgBvl', // 14 day trial, $2000 for 6 months, 5 seats, non recurring
    singlePrice6x5Trial: 14,
  },
  prod: {
    // (Stripe v2 - Checkout Session) 
    // Orignal Products/Plans
    basicProduct: 'prod_G4dPIiMc5HkKJ8',
    proProduct: 'prod_G4dQBB9KsxxeNS',
    tmreProduct: 'prod_G6EosuQSNH9NSi',
    proProductPlan: 'plan_FVYYhmuUIRQ0w8',
    basicProductPlan: 'plan_G4dkgMJ99xZWyU',
    tmreProductPlan: 'plan_G6Ep3t7hi0yA0P',

    // (Stripe v3 - Checkout Session) 
    // New Stripe Pricing Schedule / Checkout Session Integration 
    individualPlan: 'price_1J6KBLHI1WBKZbja8VTTDEFx', // $79/month
    individualTrial: 14, // Days
    standAloneOrgPlan: 'price_1J6KBLHI1WBKZbjaZe3H7puh', // $295/month per 5 Seats
    standAloneOrgTrial: 30, // Days
    parentOrgPlan: 'price_1J6KBLHI1WBKZbjaYeCV22Gv', // $225/month per 5 seats - Parent Org
    parentOrgTrial: 30, // Days
    affiliateOrgPlan: 'price_1J6KBLHI1WBKZbjaD3JWyDaP', // $225/month per 5 seats - Affiliate Org
    affiliateOrgTrial: 30,
    teamSinglePaymentTrial: 14,
    singlePrice6x5: 'price_1LB2TnHI1WBKZbjaueDEcsyD', // 14 day trial, $2000 for 6 months, 5 seats, non recurring
    singlePrice6x5Trial: 14,
  }
}

export const products = ('prod' === STAGE) ? stripeProducts.prod : stripeProducts.stage;

// TODO: Replace in ADMINSITE and WWW with correct Plan names individualPlan ... etc.
export const STRIPE_BASIC_PRODUCT_ID = products.basicProduct;
export const STRIPE_BASIC_PLAN_ID = products.basicProductPlan;
export const STRIPE_PRO_PRODUCT_ID = products.proProduct;
export const STRIPE_PRO_PLAN_ID = products.proProductPlan;

export const FAKE_ORGANIZATION = false;

// LOGO and ICON
export const BREEFLY_ICON = 'https://file-upload-breefly.s3.amazonaws.com/00-logos/breefly_icon_current.png';
export const BREEFLY_LOGO = 'https://file-upload-breefly.s3.amazonaws.com/00-logos/breefly_logo_current.svg';

export const PRODUCT_SHORT_NAME = 'breefly';
export const VERSION = 'v3.09a'; // 10/09/22 JD - bulk invite email password fix
                                // 12/30/22 ed - remove intercom, add promo codes for reseller
                                // 01/17/23 ed - more updates for promo codes: admin tools. Also, fixed sign up page logo 
                                // 01/26/23/ CK - 553/554 charge date + button text
                                // 02/06/23 ED - 556: treat past_due the same as trialing

