import React, { useState, useCallback, createContext, useEffect } from "react";
import { useDrop } from "react-dnd";
import Card from "./Card";
import update from "immutability-helper";
import ItemTypes from "./ItemTypes";
import Rightbar from "./Rightbar";
import ChartData from "./ChartData";
import uuidv4 from "uuid/v4";
import moment from "moment";
import TemplatesDialog from "../../Visualizations/components/templatesDialog";
const style = {
  width: "100%"
};

const Container = props => {
  const [cards, setCards] = useState([]);
  const [init, setInit] = useState(false);
  const [openRightbar, setOpenRightbar] = useState(false);
  const [isOpenChartData, setOpenChartData] = useState(false);
  const [title, setTitle] = useState({});
  const [isHoverIndex, setIsHoverIndex] = React.useState(0);
  const [hoverPosition, setHoverPosition] = React.useState(8);
  const [component, setComponent] = React.useState([]);
  const [holder, setHolder] = useState({});
  let {
    brief,
    classes,
    apiManager,
    updateBrief,
    openSettings,
    getBrief,
    refreshImage,
    changeImage,
    changeVideo,
    refreshChild,
    history,
    user
  } = props;

  const openRightSettings = component => {
    setComponent({});

    setOpenRightbar(true);
    setComponent(component);
    refreshChild();
  };

  const handleRightbarClose = () => {
    setComponent({});
    setOpenRightbar(false);
    refreshChild();
  };

  const setComponetsToCards = () => {
    let output = [];
    if (brief.components && brief.components.length > 0) {
      output = brief.components.map(function(component) {
        if (component && component.position > -1 && component.key) {
          return { id: component.position, ...component };
        } else {
          return { id: component.position, ...component, key: uuidv4() };
        }
      });

      const title = output.filter(value => {
        return value.type == 0;
      })[0];

      setTitle(title);
    }

    output.sort((a, b) => {
      return a.position - b.position;
    });
    setCards(output);
  };

  if ((!init && brief) || (brief && cards.length !== brief.components.length)) {
    setInit(true);
    setComponetsToCards();
  }

  const moveCard = async (id, atIndex) => {
    const { card, index } = findCard(id);
    const droppedOnIndex = card.position;

    const newCards = update(cards, {
      $splice: [
        [index, 1],
        [atIndex, 0, card]
      ]
    });
    setCards(newCards);
    if (brief && newCards) {
      brief.components = newCards;
      await updateBrief(brief);
    }
  };

  const moveCardFromIndex = async (fromIndex, toIndex) => {
    const card = cards.filter(c => c.position === fromIndex)[0];

    const newCards = update(cards, {
      $splice: [
        [fromIndex, 1],
        [toIndex, 0, card]
      ]
    });

    newCards.forEach((card, index) => (card.position = index));
    setCards(newCards);
    if (brief && newCards) {
      brief.components = newCards;
      await updateBrief(brief);
    }
  };

  const handleDrop = useCallback(
    (droppedIndex, draggedItem) => {
      const { position } = draggedItem;
      moveCardFromIndex(position, droppedIndex);
    },
    [cards]
  );

  //const [passedData, setPassed] = useState([]);
  const setPassed = data => {
    holder.data = data;
  };
  const renderCards = () => {
    if (!cards) {
      return null;
    } else {
      return cards.map((card, index) => (
        <Card
          //   key={Math.random()}
          key={`brief-edit-card-${index}`}
          id={`${card.id}`}
          text={card.text}
          placeholder={"test placeholder"}
          moveCard={moveCard}
          findCard={findCard}
          setHoverCard={setHoverCard}
          doDrop={doDrop}
          component={card}
          classes={classes}
          updateComponent={updateComponent}
          addQuote={addQuote}
          addItalic={addItalic}
          addBold={addBold}
          addSize={addSize}
          openSettings={openRightSettings}
          funcs={funcs}
          isMobile={props.isMobile}
          apiManager={apiManager}
          handleCloseSentimentChart={handleCloseSentimentChart}
          refreshChild={refreshChild}
          isHoverComponent={hoverPosition > 0}
          hoverPosition={hoverPosition}
          onTestDrop={item => handleDrop(index, item)}
          setPassed={setPassed}
          openProduceFloral={openProduceFloral}
          setOpenProduceFloral={setOpenProduceFloral}
          user={user}
          updateCustomChart={updateCustomChart}
        />
      ));
    }
  };

  const addButton = async (component, value) => {
    component.content.button = component.content.button
      ? !component.content.button
      : true;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    //setCards([]);
  };

  const updateChart = async component => {
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief, true);
    //setCards([]);
  };

  const setContentValue = async (component, key, value) => {
    if (key == "dataPoints") {
      try {
        value = value
          .replace(/\\n/g, "\\n")
          .replace(/\\'/g, "\\'")
          .replace(/\\"/g, '\\"')
          .replace(/\\&/g, "\\&")
          .replace(/\\r/g, "\\r")
          .replace(/\\t/g, "\\t")
          .replace(/\\b/g, "\\b")
          .replace(/\\f/g, "\\f")
          .replace(/y:/g, '"y":')
          .replace(/label:/g, '"label":');

        value = JSON.parse(value);
      } catch (ex) {
        console.log(ex);
      }
    }

    brief.components = brief.components.map(comp => {
      if (comp && comp.position == component.position) {
        comp.content[key] = value;
      }
      return comp ? comp : { position: -1, content: {} };
    });

    brief.components = brief.components.filter((comp, key) => {
      if (comp && comp.type >= 0) {
        comp.position = key;
      }
      return comp && comp.type >= 0;
    });

    await updateBrief(brief, true);
    //await getBrief()
  };

  const addColor = async (component, value) => {
    component.content.background = value;
    if (
      value === "#00319B" ||
      value === "#000" ||
      value === "#B100FE" ||
      value === "#22AEE8"
    ) {
      component.content.color = "#fff";
    } else {
      component.content.color = "#000";
    }
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });

    await updateBrief(brief, true);
  };

  const addSize = async (component, value) => {
    component.content.size = value;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief, true);

    //setCards([]);
  };

  const addQuote = async component => {
    component.content.quote = component.content.quote
      ? !component.content.quote
      : true;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    //setCards([]);
  };

  const addBold = async component => {
    component.content.bold = component.content.bold
      ? !component.content.bold
      : true;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    //setCards([]);
  };

  const addEdit = async component => {
    component.content.visible = component.content.visible
      ? !component.content.visible
      : true;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    //setCards([]);
  };
  const updateAsset = async asset => {
    //update pmiChartAssets
    await apiManager.updatePMIChartAssets(asset);
  };
  const updateChartData = async (component, data, type) => {
    const {
      content: { chartType, dataPoints }
    } = component;

    // get the component
    let componentToUpdate;
    brief.components.map(nextComp => {
      if (nextComp.key && nextComp.key == component.key) {
        componentToUpdate = nextComp;
      }
    });
    if (data === null) {
      data = dataPoints;
      type = chartType;
    }

    if (data && data != null) {
      let filtered;
      if (type !== "word") {
       
        const hasDataPoints =
          componentToUpdate.content.dataPoints &&
          Array.isArray(componentToUpdate.content.dataPoints);
        if (hasDataPoints) {
          if (Array.isArray(data)) {
            filtered = data;
          } else {
            filtered = componentToUpdate.content.dataPoints;
          }
        } else {
          // first need to count up each word.
          const excludeWords = [
            "a",
            "the",
            "an",
            "to",
            "of",
            "and",
            "is",
            "in",
            "be",
            "this",
            "that",
            "has",
            "it",
            "at",
            "have",
            "as"
          ];
          const words = {};
          let paragraph =
            componentToUpdate.content.dataPoints &&
            typeof componentToUpdate.content.dataPoints === "string"
              ? componentToUpdate.content.dataPoints
              : "";
          paragraph = paragraph.replace(/[^a-zA-Z ]/g, " ");
          const allWords = paragraph.split(" ");
          allWords.forEach(w => {
            const word = w.trim().toLowerCase();
            //
            if (!excludeWords.includes(word)) {
              if (word in words) {
                words[word]++;
              } else {
                words[word] = 1;
              }
            }
          });
          filtered = Object.keys(words).map((word, index) => {
            return { x: index, y: words[word], label: word };
          });
         
        }
      } else {
       
        filtered = data;
      }
      componentToUpdate.content.dataPoints = filtered;
      componentToUpdate.content.hisoricalData = componentToUpdate.content
        .hisoricalData
        ? [
            { date: moment().valueOf(), data: filtered },
            ...component.content.hisoricalData
          ]
        : [{ date: moment().valueOf(), data: filtered }];
      componentToUpdate.content.title = component.content.title;
      componentToUpdate.content.unit = component.content.unit;
      // return componentToUpdate;
    } else {
      componentToUpdate.content.title = component.content.title;
      componentToUpdate.content.chartType = component.content.chartType;
      // return componentToUpdate;
    }

    setOpenChartData(false);
    setOpenRightbar(false);
    setComponent(false);
    await updateBrief(brief);
    //await getBrief();
    refreshChild();
    history.push(`/brief/edit/${brief._id}`);
  };

  const updateCustomChart = async comp => {
   
    brief.components.map(value => {
      if (value.key && value.key == comp.key) {
        value = component;
      }
      return value;
    });
    await updateAsset(comp.content);
    setOpenRightbar(false);
    setComponent(false);
    await updateBrief(brief);
    //await getBrief();
    refreshChild();
    history.push(`/brief/edit/${brief._id}`);
  };
  const handleCloseSentimentChart = async component => {
    funcs.updateCustomChart(component);
    brief.components.map(value => {
      if (value.key && value.key == component.key) {
        value = component;
      }
      return value;
    });
    setComponent(false);
    await updateBrief(brief);
    await getBrief();
    refreshChild();
    history.push(`/brief/edit/${brief._id}`);
  };

  const addItalic = async component => {
    component.content.italic = component.content.italic
      ? !component.content.italic
      : true;
    brief.components = brief.components.map(value => {
      if (value.position == component.position) {
        value = component;
      }
      return value;
    });
    await updateBrief(brief);
    //setCards([]);
  };

  const updateComponent = async (values, isDelete, isRefresh, component) => {
    const keys = Object.keys(values);
    const index = keys[0];

    if (isDelete) {
      brief.components = brief.components.filter(value => {
        return value.position != index;
      });

      let ndx = 0;

      brief.components.sort((a, b) => a.position - b.position);
      brief.components = brief.components.map((value, key) => {
        if (title && value.key != title.key) {
          value.position = key;
        }

        return value;
      });

      isRefresh = true;
    } else {
      const components = brief.components.map(value => {
        if (value.key == component.key) {
          const newValue = values[parseInt(index)];
          if (newValue.text) {
            value.content.text = newValue.text;
            value.content.editorState = newValue.editorState;
          } else {
            value.content.text = newValue;
          }
        }
        return value;
      });
      brief.components = components;
    }

    if (isRefresh) {
      await updateBrief(brief);
      await getBrief();
    } else {
      apiManager.updateBrief(brief);
    }
    //
  };

  const closeChartData = e => {
    //e.preventDefault()

    //setComponent({})
    setOpenChartData(false);
  };
  const openChartData = (component, e) => {
    e.preventDefault();

    setComponent(component);
    setOpenChartData(true);
  };

  const funcs = {
    addQuote,
    addBold,
    addItalic,
    addSize,
    addEdit,
    addButton,
    handleCloseSentimentChart,
    setContentValue,
    updateChart,
    addColor,
    refreshImage,
    changeImage,
    changeVideo,
    openChartData,
    updateChartData,
    updateCustomChart
  };

  const findCard = id => {
    const card = cards.filter(c => `${c.id}` === id)[0];
    return {
      card,
      index: cards.indexOf(card)
    };
  };

  let hoverIndex = 0;
  const showDebuggingPanel = false;

  const setHoverCard = (id, position) => {
    const { index: overIndex, card } = findCard(id);

    hoverIndex = overIndex;
    setIsHoverIndex(overIndex);
    setHoverPosition(card.position);
    console.log(`set hover position: ${card.position}`);
    return card.position;
    //   console.log(`set hover card id: ${id}, index: ${overIndex}, position: ${card.position}`)
  };

  const doDrop = async (droppedId, originalIndex) => {
    // setIsHoverIndex(0);
    moveCard(droppedId, originalIndex);
  };

  const dropped = async (item, monitor) => {
    if (brief && cards) {
      brief.components = cards;
      await updateBrief(brief);
    }
    return item;
  };

  const [, drop] = useDrop({
    accept: ItemTypes.CARD
  });
  const [openProduceFloral, setOpenProduceFloral] = useState(false);
  const editProduceFloral = e => {
    setOpenProduceFloral(true);
    setOpenRightbar(false);
  };
  const handleCloseCreateChartDialog = async (action, data, pma) => {
    setOpenProduceFloral(false);
    if(action !== 'cancel'){
      let updatedComponent = { ...component };
      updatedComponent.content.settings.geo = data.geo;
      updatedComponent.content.settings.metrics = data.metrics;
      updatedComponent.content.settings.time = data.time;
      updatedComponent.content.settings.products = data.products;
      delete updatedComponent.content.customDataPoints;
      delete updatedComponent.content.pmaDataPoints;
      updatedComponent.content.settings.customDataPoints = "";
      await updateCustomChart(updatedComponent);
    }
  };

  return (
    <>
      <div ref={drop} style={style} key={Math.random()}>
        {renderCards()}
        <div style={{ height: 200, width: 1, background: "transparent" }} />
      </div>
      {openRightbar && (
        <Rightbar
          isOpen={openRightbar}
          onClose={handleRightbarClose}
          settings={{ type: component.type }}
          component={component}
          funcs={funcs}
          apiManager={apiManager}
          currentChartData={holder.data}
          editProduceFloralChart={editProduceFloral}
        />
      )}
      {component && component.content && openProduceFloral && (
        <TemplatesDialog
          classes={classes}
          apiManager={apiManager}
          insertOnly={true}
          startAtStep={3}
          isOpen={openProduceFloral}
          handleClose={handleCloseCreateChartDialog}
          chartSettings={component.content.settings || null}
          chartData={null}
          chartAssetId={component.content.assetId}
          component={component}
          isEditing
          user={user}
        />
      )}
      {/* {isOpenChartData && 
      <ChartData
        isOpen={isOpenChartData}
        handleClose={e => setOpenChartData(closeChartData)}
        component={component}
        classes={classes}
        updateChartData={updateChartData}
        updateCustomChart={updateCustomChart}
      />} */}
      {showDebuggingPanel && (
        <div
          style={{
            background: "black",
            color: "white",
            position: "fixed",
            top: 400,
            left: 4,
            width: 100,
            minHeight: 100,
            border: "1px solid gray"
          }}
        >
          <table>
            <tr>
              <td style={{ padding: 4 }}>Dragging</td>
              <td>true</td>
            </tr>
            <tr>
              <td style={{ padding: 4 }}>Hover Id</td>
              <td>{hoverIndex}</td>
            </tr>
            <tr>
              <td style={{ padding: 4 }}>Hover Pos</td>
              <td>{hoverPosition}</td>
            </tr>
          </table>
        </div>
      )}
    </>
  );
};
export default Container;
