import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  Checkbox,
  InputBase,
  TextField,
  FormGroup,
  FormControlLabel,
  InputLabel,
  FormControl
} from "@material-ui/core";
import BrandedCardTitle from "./BrandedCardTitle";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment"
import { useDropzone } from "react-dropzone";
import dropIcon from "../../../images/Breefly_Icons-DragDrop_Crop.svg";
import {
  faEnvelope,
  faCog,
  faSearch,
  faPlusCircle,
  faPauseCircle,
  faCloudUploadAlt,
  faUpload
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearIndeterminate from '../../Briefs/components/LinearIndeterminate';

export default function DataUploadForm({
  handleChange,
  formData,
  uploadOpen,
  setUploadOpen,
  handleDateChange,
  selectedDate,
  setFileToUpload,
  fileToUpload,
  apiManager,
  onSuccess,
}) {
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);

  const validateForm = () => {
    let t = formData && formData.type && formData.type.length > 0;
    let l = formData && formData.period && formData.period.length > 0;
    let d = moment(selectedDate).isValid();
    let f = fileToUpload.length > 0;
  
    if(t && l && d && f){
      setDisabled(false);
    } else {
        setDisabled(true);
    }
  }
  useEffect(() => {
    validateForm();
  }, [formData, selectedDate, fileToUpload]);

  const uploadThisCSV = async () => {
    setProcessing('uploading');
    let csvFile = fileToUpload[0];
   
    let s3Info = {};
    if (csvFile && csvFile.type) {

        const endDate = moment(selectedDate).format('MM-YYYY');
 
        csvFile.dataType = 'dataset';
        csvFile.dataSubType = formData.type;
        csvFile.dateRange= `${formData.period} ending ${endDate}`;
        s3Info = await apiManager.getUploadKey(csvFile);

        await apiManager.uploadToS3(s3Info.url, csvFile);

        const fileInfo = await apiManager.processCSV(s3Info.assetId);
        if(onSuccess) {
          const rowsProcessed = `${fileInfo.validCSV.jsonRows} data rows processed and added to database.`;
          onSuccess(`${fileInfo.validCSV.error ? 'Data not added to database due to csv formatting errors.' : rowsProcessed}`);  // pop the alert.
        }
        setUploadOpen(false);
        setProcessing(false);
    }
}

  const RenderUploadFile = props => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections
    } = useDropzone({
      accept: ".csv",
      onDrop: acceptedFiles => {
        setFileToUpload(acceptedFiles);
      }
    });
    return (
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        {...getRootProps({})}
        className="file-upload-holder"
      >
        <input {...getInputProps()} />
        <Grid item xs={12} style={{ paddingBottom: 12 }}>
          {/* <FontAwesomeIcon icon={faPlusCircle} /> */}
          {/* <FontAwesomeIcon icon={faUpload} style={{ fontSize: 30 }} /> */}
          <img src={dropIcon} width={56} height={56} />
        </Grid>
        <Grid item xs={12}>
          <span>Drag and drop here to upload</span>
          <br />
          <span style={{ color: "#6F6F6F", fontSize: 10 }}>CSV files only</span>
        </Grid>
        <Grid item xs={12} style={{ margin: "16px 0px" }}>
          <Button color="primary" variant="contained" className={"brandColor2"}>
            <FontAwesomeIcon icon={faUpload} style={{ marginRight: 8 }} />
            Or choose your files
          </Button>
          {fileToUpload.length > 0 ? (
            <div style={{marginTop: 24}}>
            <span>Current file: {fileToUpload[0].name}</span>
            </div>
          ) : ''
          }
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      open={uploadOpen}
      onClose={() => setUploadOpen(false)}
      fullWidth={true}
      maxWidth={"xs"}
      aria-labelledby="Team Alert"
    >
      <Card className={`branded card`}>
        <BrandedCardTitle title="Add / Replace a Database" />
        {processing
        ?
        <> 
                <div style={{minHeight: 300, padding: 24, textAlign: 'center', marginTop: 100}}>
                    <LinearIndeterminate />
                    <br />
                    {processing}
                </div>
        </>
        :
        <>
        <CardContent className="file-upload-card-content">
          <div style={{ marginBottom: 20 }}>
            <span style={{ textAlign: "center" }}>
              Use this <a href="#">template</a> for list formatting, then
            </span>
          </div>
          <RenderUploadFile />
          <hr />
          <div className="file-upload-data-container">
            <div className="column">
              <span style={{ paddingBottom: 26, marginTop: 24 }}>
                Type of Data
              </span>
              <span style={{ paddingBottom: 34 }}>Length of Period</span>
              <span style={{ paddingBottom: 14 }}>Reporting Period</span>
            </div>
            <div className="column">
              <FormControl>
                <InputLabel id="data-type-helper">Type of Data</InputLabel>
                <Select
                  labelId="data-type-helper"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                >
                  <MenuItem value={"sentiment"}>Consumer Sentiment</MenuItem>
                  <MenuItem value={"floral"}>Floral</MenuItem>
                  <MenuItem value={"produce"}>Produce</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="data-period-helper">Length of Period</InputLabel>
                <Select
                  labelId="data-period-helper"
                  id="period"
                  name="period"
                  value={formData.period}
                  onChange={handleChange}
                >     
                    <MenuItem value={"month"} style={{display: `${formData.type !== 'sentiment' ? '' : 'none'}`}}>Month</MenuItem>
                   <MenuItem value={"quarter"}>Quarter</MenuItem>
                   <MenuItem value={"semi-year"} style={{display: `${formData.type !== 'sentiment' ? '' : 'none'}`}}>Semi Annual</MenuItem>
                  <MenuItem value={"all"} style={{display: `${formData.type !== 'sentiment' ? '' : 'none'}`}}>All Included</MenuItem>
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  margin="normal"
                  id="date"
                  name="date"
                  label="Select end of Reporting Period"
                  format="MM/yyyy"
                  value={selectedDate}
                  views={["year", "month"]}
                  onChange={handleDateChange}
                  disableFuture
                  className="data-date"
                  minDateMessage="Date must be in the past"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </CardContent>

        <DialogActions>
          <Button onClick={() => setUploadOpen(false)} className="cancel-button">
            Cancel
          </Button>

          <Button
            onClick={() => uploadThisCSV()}
            color="primary"
            variant="text"
           disabled={disabled}
           className={disabled ? "no-upload" : ""}
            //className="brandColor2"
          >
            Upload Now
          </Button>
        </DialogActions>
        </>
        }
      </Card>
    </Dialog>
  );
}
