import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import OwnerPanel from './components/OwnerPanel'
import MemberPanel from './components/MemberPanel'
import ContractorPanel from './components/ContractorPanel'
import AffiliatePanel from './components/AffiliatePanel'
import { Button } from '@material-ui/core'
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

const styles = (theme => ({
    root: {
      padding: theme.spacing(4),
      minHeight: '90vh'
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const People =  (props) => {
  const { history, apiManager, classes, user } = props; 

   
  const [spacing, setSpacing] = React.useState(2);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(25); 
  const [skip, setSkip] = React.useState(0); 
  const [org, setOrganization] = React.useState(false);  
  const [value, setValue] = React.useState(0);
  
  const getData = async (overrideSearch) => {
      const paths = history.location.pathname.split('/');
      const id = paths[paths.length - 1]
      
    const params = {
        id
    }
    
    const org = await apiManager.getOrganization(params);
    
    if(org){
      setOrganization(org);
    }
    
  }
  
   

  if(!org){
    getData();
  }

  const deleteAsset = async (asset) => {
      await apiManager.deleteUser(asset);
      await getData();
  }
 

  const updateAsset = async (user) => { 
      await apiManager.createOrganization(user);
      await getData();
  } 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const goBack = () => {
    history.push('/owner/5ef8e4dc2c27b96d8173db7a')
  }

  if(!org){
    return null;
  }

  return ( 
    <div className={classes.root}>
      <div>
        <Button 
        onClick={goBack}>
        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 12}}/>BACK
        </Button>
      </div>
      <h3>People</h3>
          <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Owners" {...a11yProps(0)} />
          <Tab label="Members" {...a11yProps(1)} />
          <Tab label="Contractors" {...a11yProps(2)} />
          <Tab label="Affiliates" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OwnerPanel apiManager={apiManager} org={org} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MemberPanel apiManager={apiManager} org={org} />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <ContractorPanel apiManager={apiManager} org={org} />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <AffiliatePanel apiManager={apiManager} org={org} />
      </TabPanel>

        
    </div>
  );
};

People.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(People);
