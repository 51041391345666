import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CancelSubscription from "./components/CancelSubscription";
import DowngradeModal from "./components/DowngradeModal";
import EnterpriseModal from "./components/EnterpriseModal";
import CollectPaymentModal from "./components/CollectPaymentModal";
import ConfirmationModal from "./components/ConfirmationModal";
import ExpiredTrialModal from "./components/ExpiredTrialModal";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";
import columnIcon from "../../icons/line-columns-duotone.svg";
import "./subscription.scss";
import { MenuItem, Select } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { API_LOCAL } from "../../constants";
import eventTracker from "../../utils/event-tracker";

const IS_LOCAL = window.location.hostname === "localhost";
const BASE_URL = IS_LOCAL
  ? "http://localhost:3000"
  : `${window.location.origin}`; // 'https://pma.breefly.com';
const API_BASE_URL = IS_LOCAL
  ? "http://localhost:8020"
  : "https://gu71dnob87.execute-api.us-east-1.amazonaws.com/prod";

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 10,
      listStyle: "disc",
      height: 250
    }
  },
  content: {
    margin: "auto",
    maxWidth: "1100px"
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(4, 0, 4),
    "@media (max-width: 500px)": {
      paddingBottom: 0,
      marginBottom: 24
    }
  },
  subscriptioncard: {
    height: "541px",
    marginRight: 24,
    "@media (max-width: 500px)": {
      maxWidth: "90vw",
      height: "500px"
    }
  },
  hasSubscriptioncard: {
    height: "553px",
    marginRight: 24,
    "@media (max-width: 500px)": {
      maxWidth: "90vw",
      height: "500px"
    }
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[200]
  },
  cardHeaderStandard: {
    backgroundColor: "#0a1241",
    color: "white",
    fontSize: 10
  },
  cardHeaderPlus: {
    backgroundColor: "#25C2F4",
    color: "white",
    fontSize: 32,
    height: 80
  },
  priceStandard: {
    fontSize: 20,
    fontWeight: 600,
    display: "inline-block",
    color: "#000055"
  },
  pricePlus: {
    fontWeight: 300,
    display: "inline-block",
    marginLeft: -16
  },
  priceDollar: {
    display: "inline-block",
    paddingTop: 11,
    color: "#000055",
    height: 38
  },
  priceEnterprise: {
    fontSize: 18,
    fontWeight: "600"
  },
  price2: {
    fontSize: 12
  },
  subPrice: {
    fontSize: 12,
    lineSpacing: 20
  },
  cta: {
    fontSize: 12,
    height: 15
  },
  cta2: {
    height: 15
  },
  cardPricing: {
    // display: 'flex',
    justifyContent: "center",
    alignItems: "baseline",
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

// TODO: Deprecated in favor of editing in constants.js
// const products = {
//   stage: {
//     basicProduct: "prod_G4djH5ERi6JXZQ",
//     proProduct: "prod_FVYPy1fr2ADLfH",
//     tmreProduct: "prod_G6EosuQSNH9NSi",
//     proProductPlan: "plan_FVYYhmuUIRQ0w8",
//     basicProductPlan: "plan_G4dkgMJ99xZWyU",
//     tmreProductPlan: "plan_G6Ep3t7hi0yA0P"
//   },
//   prod: {
//     basicProduct: "prod_G4dPIiMc5HkKJ8",
//     proProduct: "prod_G4dQBB9KsxxeNS",
//     tmreProduct: "prod_G6EosuQSNH9NSi",
//     proProductPlan: "plan_G4dQfZKxphcw7u",
//     basicProductPlan: "plan_G4dkgMJ99xZWyU",
//     tmreProductPlan: "plan_G6Ep3t7hi0yA0P"
//   }
// };

// Stripe Config
const { products, STRIPE_PUBLIC_KEY } = require("../../constants");

const tiers = [
  {
    title: "Individual",
    price: 79,
    type: 0,
    priceDenom: " USD",
    benefits: "Standard",
    seats: 1,
    subheader: "For independent research providers",
    currency: "$",
    phase: "stage",
    descriptionTitle: "25 Briefs",
    subscription: products.individualPlan,
    trial: products.individualTrial,
    description: [
      "Unlimited creating and sharing",
      "Create data visualizations",
      "Add media to briefs",
      "Add links to external files",
      "Share briefs publicly"
    ],
    buttonText: "Get started",
    buttonVariant: "contained"
  },
  {
    title: "Teams",
    // subheader: 'Most popular',
    subheader: "For small to mid-sized  businesses",
    currency: "$",
    benefits: "Standard",
    descriptionTitle: "Unlimited Briefs",
    type: 1,
    priceDenom: " USD",
    seats: 5,
    // subPrice: "Includes (5) user seat, billed monthly",
    subscription: products.standAloneOrgPlan, // TODO: how to decide to use parentOrgPlan
    trial: products.standAloneOrgTrial,
    description: [
      "Custom branded portal",
      "Reporting analytics",
      "Add clients to your team",
      "Priority support",
      "Add additional seats as needed to one account",
      <i>(Sold in multiples of five (5) user seats)</i>
    ],
    buttonText: "Create Your Team",
    buttonVariant: "contained"
  },
  {
    title: "Enterprise",
    currency: " ",
    price: " ",
    type: 2,
    benefits: "Plus",
    descriptionTitle: "Unlimited Briefs",
    subheader:
      "For large businesses looking to setup multiple teams and/or deploy proprietary data access",
    cta: "Contact our Sales team to get a price estimate and demo",
    subscription: products.parentOrgPlan, // TODO: This technically isn't a plan...but a contact page
    trial: products.parentOrgTrial,
    priceDenom: "",

    description: [
      "Private subdomain hosting ",
      "Deploy proprietary or licensed databases for your users to build data visualizations and briefs",
      " ​Monetize access to your databases via an integrated subscription paywall",
      "24/7 dedicated support"
    ],
    buttonText: "Contact Sales",
    buttonVariant: "outlined"
  }
];
const orgTiers = [
  {
    title: "Teams",
    // subheader: 'Most popular',
    subheader:
      "For mid to large-sized  businesses with multiple teams & clients",
    //price: 295, use teamPrice instead
    currency: "$",
    benefits: "Standard",
    descriptionTitle: "Unlimited Briefs",
    type: 1,
    priceDenom: " USD",
    seats: 5,
    // subPrice: "Includes (5) user seat, billed monthly",
    subscription: products.affiliateOrgPlan,
    description: [
      "Support via Email & Chat",
      "Monthly analytics reporting telling you who is engaging with your briefs",
      "Public Sharing Links to easily share with users outside of Breefly"
    ],
    buttonText: "Get started",
    buttonVariant: "contained"
  }
];
const singlePaymentTiers = [
  {
    title: "Single Payment",
    // subheader: 'Most popular',
    subheader:
      "Designed for project based insights reporting & sharing",
    currency: "$",
    benefits: "Standard",
    descriptionTitle: "Unlimited Briefs",
    type: 2,
    price: 2000,
    priceDenom: " USD",
    seats: 5,
    subscription: products.singlePrice6x5,
    trial: products.singlePrice6x5Trial,
    description: [
      "Single payment for six months of unlimited platform access",
      "Share briefs with guests for free",
      "Unlimited briefs creation, data visualizations, & collaboration"
    ],
    buttonText: "Create Your Team",
    buttonVariant: "contained"
  }
];
// const footers = [
//   {
//     title: "Company",
//     description: ["Team", "History", "Contact us", "Locations"]
//   },
//   {
//     title: "Features",
//     description: [
//       "Cool stuff",
//       "Random feature",
//       "Team feature",
//       "Developer stuff",
//       "Another one"
//     ]
//   },
//   {
//     title: "Resources",
//     description: [
//       "Resource",
//       "Resource name",
//       "Another resource",
//       "Final resource"
//     ]
//   },
//   {
//     title: "Legal",
//     description: ["Privacy policy", "Terms of use"]
//   }
// ];

const DEFAULT_MODAL = {
  isOpen: false
};

export default function Subscriptions(props) {
  const classes = useStyles();

  const {
    user,
    apiManager,
    refreshChild,
    isMobile,
    history,
    updateUser,
    location
  } = props;

  const [subscription, setSubscription] = React.useState('');
  const [subscriptionName, setSubscriptionName] = React.useState("");
  //const [init, setInit] = React.useState(false);
  const [modal, setModal] = React.useState(DEFAULT_MODAL);
  const [enterprise, setEnterprise] = React.useState({ isOpen: false });
  const [isLoading, setIsLoading] = React.useState(false);

  // VARIABLE FOR HOSTED ORG vs. Generic Team
  const [isHostedOrg, setHostedOrg] = useState(false);

  //TEMP VARIABLE TO FOR TESTING DOWNGRAD REDIRECT

  const [seatsUsed, setSeatsUsed] = useState(1);
  const [trialEnd, setTrialEnd] = useState(false);
  const [discount, setDiscount] = useState(1);
  const [discountType, setDiscountType] = useState("percent");
  const [isTrialing, setIsTrialing] = useState(false)
  const [promoCode, setPromoCode] = useState({});

  //const trialUsed = !isEmpty(user.subscriptions)
  useEffect(() => {    
    setIsTrialing(subscription && subscription.session && (subscription.session.status == "trialing" || (subscription.session.status == "past_due" && !subscription.hasCardOnFile)))
  }, [subscription])
  
  const isSinglePayment = props.user.accounttype === "teamSinglePayment";
  // nick names
  const subNames = {
    "Basic monthly": "Standard",
    "Plus (Monthly)": "Plus",
    "Plus (Monthly)": "Plus",
    "plus-monthly": "Plus",
    Enterprise: "Enterprise",
    "Enterprise Subscription": "Enterprise"
  };
  const productKeyNames = {
    // dev/stage:
    plan_G4dkgMJ99xZWyU: "Standard",
    "plan_FVYYhmuUIRQ0w8-p": "Plus",
    plan_FVYYhmuUIRQ0w8: "Enterprise",

    // TODO: Move to constants.js
    // New Stripe v3 pricing structure
    price_1IWlKtHI1WBKZbjakqSHRVIV: "Individual", //stage
    price_1JUFd7HI1WBKZbjax1rWi9uV: "Individual", // TEMP: stage daily coupon test
    price_1IWlMsHI1WBKZbjahu7X6tFX: "Teams", //stage
    price_1IWqnrHI1WBKZbjaKtb1jo88: "Teams", // another stage
    price_1L3556HI1WBKZbjaQw2pgBvl: "Single Payment", //stage singlePrice6x5
    price_1IWnvLHI1WBKZbja7bEAf8tX: "Enterprise", //stage
    price_1J6KBLHI1WBKZbja8VTTDEFx: "Individual", //prod
    price_1J6KBLHI1WBKZbjaZe3H7puh: "Teams", //prod
    price_1J6KBLHI1WBKZbjaYeCV22Gv: "Enterprise", //prod
    price_1J6KBLHI1WBKZbjaD3JWyDaP: "Teams", //prod affiliate
    price_1LB2TnHI1WBKZbjaueDEcsyD: "Single Payment", //prod singlePrice6x5

    // prod
    prod_G4djH5ERi6JXZQ: "Standard",
    prod_FVYPy1fr2ADLfH: "Plus",
    prod_FVYPy1fr2ADLfH: "Enterprise",
    // no subscription or expired
    none: "No Subscription"
  };

  // Need to use this outside of hooks
  const getSubscription = async () => {
    if (subscription && subscription.session) {
      console.log("Before: ", productKeyNames[subscription.session.plan.id]);
    }

    const stripeSub = await apiManager.getSubscription();
    // New subscription document because Stripe is no longer using checkout.session object
    // if (data && data.session && data.session.plan) {
    //   let nickname = subNames[data.session.display_items[0].plan.nickname]
    //     ? subNames[data.session.display_items[0].plan.nickname]
    //     : "";
    //   if (nickname === "") {
    //     const session = data.session.display_items;
    //     nickname = productKeyNames[data.session.display_items[0].plan.id]
    //       ? productKeyNames[data.session.display_items[0].plan.id]
    //       : "";
    //   }
    // }
    // Get current active stripe sub if any
    if (
      stripeSub &&
      stripeSub.session &&
      (stripeSub.active || stripeSub.session.status === 'past_due') &&
      stripeSub.session.plan
    ) {
      console.log("found sub: ", productKeyNames[stripeSub.session.plan.id]);

      setSubscriptionName(productKeyNames[stripeSub.session.plan.id]);
      //if its any type of team, set the seats
      if (
        stripeSub.session.plan.id === products.standAloneOrgPlan ||
        stripeSub.session.plan.id === products.affiliateOrgPlan ||
        stripeSub.session.plan.id === products.parentOrgPlan
      ) {
        const members = await apiManager.getMembers({
          _id: user.orgId,
          search: "members"
        });
        members && members.length && setSeatsUsed(members.length);
      }
      setSubscription(stripeSub);
      const numSeats = stripeSub.session.quantity * 5;
      setSeats(numSeats);
      setTeamPrice(stripeSub.session.quantity * (user.hostOrgId ? 225 : 295));
      setIsLoading(false);

      // see if there's a discount in effect
      if (
        user.discountTrack &&
        stripeSub.session.discount &&
        stripeSub.session.discount.coupon &&
        stripeSub.session.discount.coupon.id
      ) {
        const discountIndex =
          stripeSub.session.discount.coupon.id ===
          user.discountTrack.discountNew.coupon
            ? "discountNew"
            : "discountRenewal";
        const currentDiscount = user.discountTrack[discountIndex].amount;
        setDiscountType(
          user.discountTrack[discountIndex].type &&
            user.discountTrack[discountIndex].type === "amount"
            ? "amount"
            : "percent"
        ); // defensive coding for promocodes that don't have "type" yet
        setDiscount(
          user.discountTrack[discountIndex].type &&
            user.discountTrack[discountIndex].type === "amount"
            ? currentDiscount
            : 1 - currentDiscount
        );
      }

      // see if in a trial and, if so is trial ending/ended
      if (
        stripeSub.session.trial_end &&
        (stripeSub.session.status === "trialing" || (stripeSub.session.status === "past_due" && !stripeSub.hasCardOnFile)) &&
        !stripeSub.session.payment_method_types
      ) {
        const today = moment();
        let endDate = moment(stripeSub.session.trial_end);
        endDate =
          endDate.year() > 1970
            ? endDate
            : moment(stripeSub.session.trial_end * 1000);
        const days = endDate.diff(today, "days");
        let expiry = false;
        if (days < 0) {
          expiry = "Free trial has expired";
        } else if (days === 0) {
          expiry = "Free trial ends today";
        } else {
          expiry = `Free trial ends in ${days} days.`;
        }
        setTrialEnd(expiry);
      }

      return productKeyNames[stripeSub.session.plan.id];
    } else {
      setSubscription('');
      setIsLoading(false);
      setSubscriptionName("None");
      return "None";
    }
  };

  const [openPaymentModal, setPaymentModal] = useState("");
  const [expiredTrialModal, setExpiredTrialModal] = useState("");
  //Get subscription info once component mounts
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const result = await apiManager.reAuthenticate({ email: user.email });
      await getSubscription();

      // TODO: Move this to its own effect? Not sure if this is the best pattern (JD to Clayton)
      // Get user promocode details
      const codeResult = await apiManager.getPromoCode(user.promocode);
      setPromoCode(codeResult);
    })();
    //if trial is near expiring, pop modal to redirect to cc collection
    if (location.pathname == "/subscriptions/card") {
      setPaymentModal(true);
    }
    if (location.pathname == "/subscriptions/expired") {
      setExpiredTrialModal(true);
    }

    // see if hosted org
    if (user.hostOrgId) {
      setHostedOrg(true);
    }
  }, []);

  //update pricing and seats on subscription change
  const [teamPrice, setTeamPrice] = useState(isHostedOrg ? 225 : 295);
  const [seats, setSeats] = useState(5);
  const [currentSeats, setCurrentSeats] = useState(0);

  useEffect(() => {
    if (
      subscriptionName === "Teams" &&
      !isEmpty(subscription) &&
      !isHostedOrg
    ) {
      const {
        session: { quantity }
      } = subscription;
      quantity && setSeats(subscription.session.quantity * 5);
      quantity && setTeamPrice(295 * subscription.session.quantity);
    }
    if (subscriptionName === "Teams" && !isEmpty(subscription) && isHostedOrg) {
      const {
        session: { quantity }
      } = subscription;
      quantity && setSeats(subscription.session.quantity * 5);
      quantity && setTeamPrice(225 * subscription.session.quantity);
    }
    if (subscriptionName === "None") {
      setSeats(5);
      setTeamPrice(isHostedOrg ? 225 : 295);
    }
    if (subscription && subscription.session) {
      setCurrentSeats(subscription.session.quantity * 5);
    }
  }, [subscription, subscriptionName, isHostedOrg]);

  const [openDowngrade, setOpenDowngrade] = useState({
    isOpen: false,
    isTeams: false
  });

  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    title: "",
    message: ""
  });
  const closeConfirmationModal = async signout => {
    const result = await apiManager.reAuthenticate({ email: user.email });
    setConfirmationModal({ isOpen: false, title: "", message: "" });
    if (result) {
      updateUser(result);
      sessionStorage.setItem("token", result.token);
    }
  };
  // Verify when /webhook returns valid subscription
  const checkForStripeUpdate = async newSubscriptionName => {
    // Configure Interval
    let attempt = 1;
    const maxAttempts = 7;
    const intervalLength = 1000;

    // Start Spinner
    setIsLoading(true);

    let subStatus = setInterval(async () => {
      // Attempt User Sub Retrieval
      const newSubName = await getSubscription();

      console.log(`current: ${newSubName} new: ${newSubscriptionName}`);

      // TODO: "subscriptionName" state update is not passed down after getSubscription call???
      // TODO: React Hooks/State used wrong?
      if (newSubName === newSubscriptionName) {
        // Killer spinner => redirect???
        setIsLoading(false);

        // Stop the interval
        clearInterval(subStatus);

        if (newSubName === "None") {
          setConfirmationModal({
            isOpen: true,
            title: "Cancellation Successful",
            message: "These changes will take effect immediately."
          });
          eventTracker("Subscription Cancellation", user, "subscriptions");
        } else {
          setConfirmationModal({
            isOpen: true,
            title: "Subscription Change Successful",
            message: "Your change will take effect immediately."
          });
          eventTracker("Subscription Change", user, "registration");
        }

        return;
        // It's taking too long - something's wrong - alert the UI
      } else if (
        newSubName !== newSubscriptionName &&
        attempt === maxAttempts
      ) {
        // Something went wrong - reset
        setIsLoading(false);
        clearInterval(subStatus);

        // TODO: modal to contact???
        setConfirmationModal({
          isOpen: true,
          title: "Subscription Unsuccessful",
          message:
            "There was an error processing your subscription. Please contact us at suppport@breefly.com or message us using the button in the lower right corner"
        });

        return;
      }
      attempt++;
    }, intervalLength);
  };

  // Main stripe checkout function - Stripe - uses line_items and `price`
  // instead of 'plan' to support new product structure
  const stripeCheckout = async (price, quantity = 1, trial = 0) => {
    // const { active, session } = subscription;
    const successUrl = `${API_LOCAL}subscriptions/success/{CHECKOUT_SESSION_ID}/`;

    try {
      // Invoke spinner
      setIsLoading(true);

      // Call create-subscription for more custom experience in back-end
      const session = await apiManager
        .createStripeSubscription(price, quantity, trial)
        .catch(e => console.log(e.message));

      const newSubscriptionName = productKeyNames[price];
      await checkForStripeUpdate(newSubscriptionName, true); // Handles stopping the spinner
    } catch (e) {
      console.log(e.message);
    }
  };

  // Upgrade or Downgrade Subscription
  const stripeCrossgradeSubscription = async (
    currentSubId,
    newStripePrice,
    quantity = 1
  ) => {
    const currentSub = { ...subscription };
    // console.log(quantity)
    // if(seatsUsed > quantity * 5){
    //   setOpenDowngrade({isOpen: true, isTeams: true})
    // } else {
    try {
      // Launch the spinner
      setIsLoading(true);

      // alert(`${currentSubId}, ${newStripePrice}, ${qty / 5}, ${JSON.stringify(currentSub)}`);
      if (
        newStripePrice == "price_1JUFd7HI1WBKZbjax1rWi9uV" ||
        newStripePrice == "price_1J6KBLHI1WBKZbja8VTTDEFx"
      ) {
        await apiManager.removeTeam(user.email);
      }

      const updateStripeSub = await apiManager.crossgradeStripeSubscription(
        currentSubId,
        currentSub.session.id,
        newStripePrice,
        quantity
      );
      const newSubscriptionName = productKeyNames[newStripePrice];
      await checkForStripeUpdate(newSubscriptionName); // Handles stopping the spinner
    } catch (e) {
      console.log(e.message);
    }
    //}
  };

  // Collect Payment Info (Ends Current Trial / Reactivates Expired Trial)
  const stripePaymentInfo = async (
    event,
    priceParam,
    quantity = 1,
    trial = 0
  ) => {
    event.persist();
    const { active, session, customer } = subscription || "";
    //const successUrl = `${API_LOCAL}subscriptions/success/{CHECKOUT_SESSION_ID}/`;
    const successUrl = `${BASE_URL}/subscriptions/success`;
    const cancelUrl = BASE_URL + "/subscriptions";
    let customerId = customer;

    //look in user.subscriptions for a customer id
    const lastSubscription =
      !isEmpty(user.subscriptions) &&
      user.subscriptions[user.subscriptions.length - 1];
    if (!customerId) {
      customerId = lastSubscription && lastSubscription.customer;
      console.log(lastSubscription);
    }
    if (!priceParam) {
      priceParam =
        lastSubscription &&
        lastSubscription.session &&
        lastSubscription.session.plan &&
        lastSubscription.session.plan.id;
      quantity =
        lastSubscription &&
        lastSubscription.session &&
        lastSubscription.session.quantity;
    }
    try {
      let checkoutSession = {};
      // TODO: Check if there is a subscription and return an error
      // Generate checkout session for this customer's subscription
      if (
        session &&
        (session.status === "trialing" || session.status === "past_due" || session.status === "active")
      ) {
        checkoutSession = {
          payment_method_types: ["card"],
          //line_items: [
          //  {price: priceParam, quantity: quantity},
          //],
          mode: "setup",
          customer: customerId,
          setup_intent_data: {
            metadata: {
              customer_id: customerId,
              subscription_id: session && session.id ? session.id : ""
            }
          },
          subscription_data: {
            metadata: {}
          },
          success_url:
            `${successUrl}?id={CHECKOUT_SESSION_ID}&user=` +
            encodeURIComponent(user.token),
          cancel_url: cancelUrl
        };
      } else {
        checkoutSession = {
          payment_method_types: ["card"],
          line_items: [{ price: priceParam, quantity: quantity }],
          mode: "subscription",
          customer: customerId,
          subscription_data: {
            metadata: {}
          },
          success_url:
            `${successUrl}?id={CHECKOUT_SESSION_ID}&user=` +
            encodeURIComponent(user.token),
          cancel_url: cancelUrl
        };
      }
      // let checkoutSession = {
      //   payment_method_types: ['card'],
      //   mode: 'setup',
      //   customer: customer,
      //   setup_intent_data: {
      //     metadata: {
      //       customer_id: customer,
      //       subscription_id: session.id,
      //     },
      //   },
      //   success_url: `${successUrl}?id={CHECKOUT_SESSION_ID}&user=` + encodeURIComponent(user.token),
      //   cancel_url: cancelUrl,
      // };

      // Check if a discount is part of the trial code add it to the checkout session
      // if (promoCode && promoCode.discountNew && promoCode.discountNew.coupon) {
      //   checkoutSession.discounts = [{
      //     coupon: promoCode.discountNew.coupon,
      //   }];
      // }

      // if (trialUsed) {
      //   checkoutSession.subscription_data = checkoutSession.setup_intent_data
      //   delete checkoutSession.setup_intent_data
      // }
      const checkoutResult = await apiManager.createCheckoutSession(
        checkoutSession
      );
      // Redirect to Stripe for payment info collection
      stripe.redirectToCheckout({ sessionId: checkoutResult.id });
      handleClose();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleClose = async () => {
    setModal({ ...modal, isOpen: false });
    setEnterprise({ ...enterprise, isOpen: false });
    setOpenDowngrade({ ...openDowngrade, isOpen: false });
    setExpiredTrialModal("");
    setPaymentModal("");
  };

  const cancelSubscription = async () => {
    setModal({ ...modal, isOpen: true });
    //need to rewrite the cancel sub modal open logic
    //To Do commented out for now
    // await apiManager.cancelSubscription();
    // await getSubscription();
    // handleClose();
  };

  //Open the downgrade modal
  const downgrade = isTeams => {
    setOpenDowngrade({ ...openDowngrade, isOpen: true, isTeams: isTeams });
  };

  const downgradeStripeSubscription = async () => {
    handleClose();
    await stripeCrossgradeSubscription(
      subscription.session.id,
      subscription.session.plan.id,
      seats / 5
    );
  };

  const downgradeToIndividual = async () => {
    handleClose();
    await stripeCrossgradeSubscription(
      subscription.session.id,
      products.individualPlan
    );
  };

  const cancelStripeSubscription = async () => {
    handleClose();
    //need to rewrite the cancel sub modal open logic
    //To Do commented out for now
    setIsLoading(true);
    await apiManager.cancelStripeSubscription(subscription.session.id);
    await checkForStripeUpdate("None");
  };

  const funcs = {
    downgradeStripeSubscription,
    cancelStripeSubscription,
    handleClose,
    downgradeToIndividual,
    stripePaymentInfo
  };

  const enterpriseModal = () => {
    setEnterprise({ ...enterprise, isOpen: true });
    // refreshChild();
  };

  //   const handleTest = () => {
  //     return(
  //       <>
  //      <Select
  //             labelId="test-team-types"
  //             id="test-team-types"
  //             value={subscriptionName}
  //             onChange={e => setSubscriptionName(e.target.value)}
  //           >
  //             <MenuItem value={"None"}>None</MenuItem>
  //             <MenuItem value={"Individual"}>Individual</MenuItem>
  //             <MenuItem value={"Teams"}>Teams</MenuItem>
  //             <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
  //       </Select>
  //       <Select
  //             labelId="test-team-types"
  //             id="test-team-org"
  //             value={isHostedOrg}
  //             onChange={e => setHostedOrg(e.target.value)}
  //           >
  //             <MenuItem value={false}>Stand Alone</MenuItem>
  //             <MenuItem value={true}>Org Team</MenuItem>
  //       </Select>
  //       </>
  //     )
  //   }

  const handlePaymentInfoTest = () => {
    //if(isTrialing || isHostedOrg) {
    //only collect payment if trialing, otherwise need to use subscribe buttons
    if (isTrialing && !isSinglePayment) {
      return (
        <Button
          type="submit"
          variant="contained"
          className="breefly-btn btn-gray"
          id={`checkout-button-${products.individualPlan}`}
          onClick={e => stripePaymentInfo(e)}
        >
          Enter Payment Info
        </Button>
      );
    }
  };

  const renderContactUsForOffer = () => {

    const mailto = `mailto:sales@breefly.com?subject=Monthly offer inquiry!&body=Hello,
    %0D%0A%0D%0A
    I’m interested in switching to a monthly subscription plan. Please email me back with more information.`;

    return (
      <div style={{width: 300, margin: '24px auto', textAlign: 'center', lineHeight: '22px'}}>
        Save 12% with a monthly subscription plan.
        <br />
        Contact us at <a href={mailto}>sales@breefly.com</a> to learn more.
        <div>
          <Button variant="outlined" href={mailto} style={{margin: 16}}>Learn More</Button>
        </div>
      </div>
    )
}

  const renderPrice = tier => {
    console.log(tier.price);
    let price = 0;
    if (discountType === "percent") {
      price = tier.price
        ? (tier.price * discount).toFixed(2)
        : (teamPrice * discount).toFixed(2);
    } else {
      // 'amount'
      price = tier.price
        ? (tier.price - discount).toFixed(2)
        : (teamPrice - discount).toFixed(2);
    }
    if (price.includes(".00")) {
      price = price.replace(".00", "");
    }
    return <span>{Number(price).toLocaleString()}</span>;
  };

  const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
  const tcode = localStorage.getItem("tcode"); ///signin?tcode=<codefromticket>
  // 9bZab = 30 days
  // N33p5 = 90 days

  //DETERMINE IF PMA ORG & ADJUST CONTENT ACCORDINGLY
  let cardContent = tiers;
  if (isHostedOrg) {
    cardContent = orgTiers;
  }
  if (isSinglePayment) {
    cardContent = singlePaymentTiers;
  }
  const renderSinglePaymentButtons = () => {
    console.log('singe sub ', subscription, isTrialing)
    let buttonContent = { 
      cta: "Purchase Again", 
      action: e => stripePaymentInfo(e, products.singlePrice6x5, 1), 
      class: 'btn-blue',
    }
    if (isLoading){
      buttonContent = { 
        cta: "Loading", 
        action: e => console.log('not active'),
        class: 'btn-blue',
        disabled: true
      }
    }
    if (isTrialing) {
      if(subscription && subscription.hasCardOnFile) {
        buttonContent = { 
          cta: "In a Free Trial", 
          action: () => console.log('clicked'),
          class: 'btn-white',
          disabled: true
        }
      } else {
        buttonContent = { 
          cta: "Enter Payment Info", 
          action: e => stripePaymentInfo(e, products.singlePrice6x5, 1), //end trial get money
          class: 'btn-blue'
        }
      }
    }
    if (!subscription && props.user.subscriptions && !isEmpty(props.user.subscriptions)){
      buttonContent = { 
        cta: `Restore Full Access`, //TO DO: confirm end date from the subsction document
        action: e => stripePaymentInfo(e, products.singlePrice6x5, 1), 
        class: 'btn-blue',
      }
    }
    if (subscription && !isTrialing) {
      buttonContent = { 
        cta: `Expires on ${subscription && subscription.session ? moment.unix(subscription.session.current_period_end + 10000).format('MM/DD/YY')
          : 'N/A'}`, //TO DO: confirm end date from the subsction document
        action: () => console.log('clicked'), 
        class: 'btn-white',
        disabled: true
      }
    } 

    return (
      <>
      {!expiredTrialModal && 
      <Button
        className={`breefly-btn ${buttonContent.class}`}
        type="submit"
        variant="contained"
        id={`checkout-button-singlePlaymentPlan`}
        onClick={buttonContent.action} // {buttonContent.action) may need to pass something to this
        disabled={buttonContent.disabled}
      >
        {buttonContent.cta}
      </Button>}
      </>
    )
  }
  const renderCheckoutButton = tier => {
    const { title } = tier;
    let teamPrice;
    if (isHostedOrg) {
      teamPrice = products.affiliateOrgPlan;
    } else {
      teamPrice = products.standAloneOrgPlan;
    }
    let buttonInfo = {
      individual: {
        click: e => stripePaymentInfo(e, products.individualPlan, 1, 0),
        label: user.orgRole == "owner" ? "Downgrade Account" : "Get Started"
      },
      teams: {
        click: e => stripePaymentInfo(e, teamPrice, seats / 5, 0),
        label: "Subscribe Your Team"
      },
      enterprise: {
        click: enterpriseModal,
        label: "Contact Us"
      }
    };
    if (subscriptionName === "Individual") {
      buttonInfo = {
        ...buttonInfo,
        individual: { click: cancelSubscription, label: "Cancel Subscription" },
        teams: {
          click: () =>
            stripeCrossgradeSubscription(
              subscription.session.id,
              products.standAloneOrgPlan,
              seats / 5
            ),
          label: "Upgrade Subscription"
        }
      };
    }
    if (subscriptionName === "Teams") {
      if (isHostedOrg) {
        buttonInfo = {
          ...buttonInfo,
          teams: { click: cancelSubscription, label: "Cancel Subscription" }
        };
      } else {
        if (seats !== 0) {
          const current =
            subscription &&
            subscription.session &&
            subscription.session.quantity
              ? subscription.session.quantity
              : "";

          buttonInfo = {
            ...buttonInfo,
            individual: {
              click: () => downgrade(false),
              label: "Downgrade Subscription"
            },
            teams: {
              click:
                current <= seats / 5
                  ? () =>
                      stripeCrossgradeSubscription(
                        subscription.session.id,
                        products.standAloneOrgPlan,
                        seats / 5
                      )
                  : seatsUsed - seats > 0
                  ? () => downgrade(true)
                  : () =>
                      stripeCrossgradeSubscription(
                        subscription.session.id,
                        products.standAloneOrgPlan,
                        seats / 5
                      ),
              label:
                current === seats / 5
                  ? "Current Plan"
                  : current <= seats / 5
                  ? "Add Seats"
                  : "Remove Seats"
            }
          };
        } else {
          buttonInfo = {
            ...buttonInfo,
            individual: {
              click: () => downgrade(true),
              label: "Downgrade Subscription"
            },
            teams: { click: cancelSubscription, label: "Cancel Subscription" }
          };
        }
      }
    }

    // if(teamPrice !== 295 && !isHostedOrg){
    //   buttonInfo =
    //     {...buttonInfo,
    //       teams: {click: () => stripeCrossgradeSubscription(subscription.session.id, products.standAloneOrgPlan, seats / 5), label: 'Adjust Seats'}
    //     }
    // }
    if (seats === 0) {
      buttonInfo = {
        ...buttonInfo,
        teams: { click: cancelStripeSubscription, label: "Cancel Subscription" }
      };
    }
    if (subscriptionName === "Enterprise") {
      buttonInfo = {
        ...buttonInfo,
        individual: { click: enterpriseModal, label: "Contact Us" },
        teams: { click: enterpriseModal, label: "Contact Us" }
      };
    }
    if (tcode === "N33p5" && subscriptionName === "None") {
      buttonInfo.teams.label = "Start 90 Free Day Trial";
      buttonInfo.individual.label = "Start 90 Free Day Trial";
    }
    if (tcode === "9bZab" && subscriptionName === "None") {
      buttonInfo.individual.label = "Start 30 Free Day Trial";
      buttonInfo.teams.label = "Start 30 Free Day Trial";
    }

    let html = "<div></div>";
    if (title === "Individual") {
      html = (
        <Button
          type="submit"
          variant="contained"
          className="breefly-btn btn-gray"
          id={`checkout-button-${products.individualPlan}`}
          onClick={buttonInfo.individual.click}
        >
          {/* {isSubscription &&
          isSubscription.id !== "no-subscription" &&
          tier.title !== subscriptionName
            ? "Switch to Standard"
            : defaultButtonText} */}
          {buttonInfo.individual.label}
        </Button>
      );
    } else if (title === "Teams") {
      html = (
        <Button
          className="breefly-btn btn-blue"
          type="submit"
          variant="contained"
          id={`checkout-button-${products.standAloneOrgPlan}`}
          onClick={buttonInfo.teams.click}
          disabled={buttonInfo.teams.label === "Current Plan" ? true : false}
        >
          {/* {isSubscription &&
          isSubscription.id !== "no-subscription" &&
          tier.title !== subscriptionName
            ? switchPlus
            : defaultButtonText} */}
          {buttonInfo.teams.label}
        </Button>
      );
    } else if (title === "Enterprise") {
      html = (
        <Button
          className="breefly-btn btn-white"
          type="submit"
          variant="contained"
          id={`checkout-button-${products.parentOrgPlan}`}
          onClick={buttonInfo.enterprise.click}
        >
          {/* {isSubscription &&
          isSubscription.id !== "no-subscription" &&
          tier.title !== subscriptionName
            ? "Contact to Upgrade"
            : defaultButtonText} */}
          {buttonInfo.enterprise.label}
        </Button>
      );
    }
    return html;
  };

  // To be deprecated after launch: JD
  // const stripeStandardCheckout = () => {
  //   const { active, session } = subscription;
  //   // let id = "";
  //   // if (session && session.display_items && session.display_items.length > 0) {
  //   //   id = session.display_items[0].plan.id;
  //   // }

  //   // if (active == 1 && id == tiers[0].subscription) {
  //   //   modal.isOpen = true;
  //   //   setModal(modal);
  //   //   refreshChild();
  //   // } else {
  //     const successUrl = `${API_BASE_URL}/api/v1/subscriptions/success/{CHECKOUT_SESSION_ID}/`;

  //     stripe.redirectToCheckout({
  //       items: [{ plan: products.individualPlan, quantity: 1 }],
  //       successUrl:
  //         `${successUrl}?id={CHECKOUT_SESSION_ID}&user=` +
  //         encodeURIComponent(user.token),
  //       cancelUrl: BASE_URL + "/subscriptions"
  //     });
  //   // }
  // };

  // To be deprecated after launch: JD
  // const stripePlusCheckout = () => {
  //   const { active, session } = subscription;
  //   const quantity = seats / 5;
  //   // let id = "";
  //   // if (session && session.display_items && session.display_items.length > 0) {
  //   //   id = session.display_items[0].plan.id;
  //   // }

  //   // if (active == 1 && id == tiers[1].subscription) {
  //   //   modal.isOpen = true;
  //   //   setModal(modal);
  //   //   refreshChild();
  //   // } else {
  //     const successUrl = `${API_BASE_URL}/api/v1/subscriptions/success/{CHECKOUT_SESSION_ID}/`;

  //     stripe.redirectToCheckout({
  //       items: [{ plan: products.standAloneOrgPlan, quantity }],
  //       successUrl:
  //         `${successUrl}?id={CHECKOUT_SESSION_ID}&user=` +
  //         encodeURIComponent(user.token),
  //       cancelUrl: BASE_URL + "/subscriptions"
  //     });
  //   //}
  // };

  const renderSubscription = tier => {
    //if (subscription && subscription.session) {
    // there's one subscription... see which it is...
    let expiry = false;
    if (tier.title === subscriptionName || subscriptionName === "Enterprise") {
      expiry = trialEnd;
    }
    const titleHeight = trialEnd ? 54 : 48;
    return (
      <div
        style={{
          marginLeft: 24,
          height: tier.title !== subscriptionName ? titleHeight : ""
        }}
      >
        <h3 style={{ lineHeight: "12px" }}>
          <span style={{ fontSize: 16, color: "#22b9ea" }}>
            {isSinglePayment && subscription
              ? "CURRENT PLAN" 
              : tier.title === subscriptionName || subscriptionName === "Enterprise"
                ? "CURRENT SUBSCRIPTION"
                : ""}
            {trialEnd && (
              <span style={{ fontSize: 12, fontStyle: "italic" }}>
                <br />
                {expiry}
              </span>
            )}
          </span>
        </h3>
      </div>
    );
    //}
    //return null;
  };

  const isSubscription =
    subscription &&
    subscription.session &&
    subscription.session.id != "no-subscription";

  const renderStandard = () => {
    return null;
  };

  const renderAffiliate = () => {
    return null;
  };

  // if(isHostedOrg){
  //   setTeamPrice(225)
  // }
  const handleChange = event => {
    setSeats(event.target.value);
    setTeamPrice((event.target.value / 5) * (isHostedOrg ? 225 : 295));
  };

  const handleTeamsClick = event => {
    if (
      subscription.session.status == "trialing" ||
      subscription.session.status == "active"
    ) {
      //see if we need to remove seats
      if (seats < currentSeats) {
        downgrade(true);
        //stripeCrossgradeSubscription(subscription.session.id, subscription.session.plan.id, seats / 5)
      } else {
        stripeCrossgradeSubscription(
          subscription.session.id,
          subscription.session.plan.id,
          seats / 5
        );
      }
    } else {
      stripePaymentInfo(event, subscription.session.plan.id, seats / 5);
    }
  };

  const renderSeatSelect = tier => {
    if (tier.title === "Individual") {
      return "Includes (1) seat";
    } 
    if (tier.title === "Single Payment") {
      return "Includes (5) seats";
    }
      return (
        <div>
          <span>Includes </span>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={seats}
            onChange={handleChange}
          >
            {/* <MenuItem value={0}>Cancel Subscription</MenuItem> */}
            {[...Array(61)].map((x, i) => (
              <MenuItem value={5 * i}>
                {i === 0 ? "Cancel Subscription" : 5 * i}
              </MenuItem>
            ))}
            {/* 
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem> */}
          </Select>
          seats, billed monthly
        </div>
      );
  };
  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        component="main"
        style={{ marginBottom: 48 }}
        className="subscription"
      >
        {/* Hero H1 */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={`${classes.heroContent}`}
        >
          {isMobile ? (
            <Grid
              xs={12}
              sm={10}
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
                marginBottom: 12
              }}
            >
              Choose the plan that best suits your teams needs
            </Grid>
          ) : (
            <Grid item xs={12} sm={9} style={{ marginRight: 38 }}>
              <h1>
                {isSinglePayment
                  ? "Your Plan Type"
                  : "Choose the plan that best suits your teams needs"}
              </h1>
              <h4>
                {isSinglePayment
                  ? "Questions? Contact us directly at: support@breefly.com"
                  : "Pay by month or the year, and cancel at any time."}
              </h4>
            </Grid>
          )}
        </Grid>

        {/* End hero unit */}
        <Grid
          container
          spacing={2}
          style={{ display: !isLoading ? "none" : "block" }}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={Math.random()}
            style={{ marginBottom: 18 }}
          >
            <LinearIndeterminate />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={(isHostedOrg || isSinglePayment) && "centerCard"}>
          {cardContent.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item xs={12} sm={8} md={4} key={Math.random()}>
              <Card
                className={`${
                  subscription && subscription.session
                    ? classes.hasSubscriptioncard
                    : classes.subscriptioncard
                } stripeCard`}
              >
                <div style={{ height: "8px", background: "#000055" }}></div>
                {renderSubscription(tier)}
                <CardHeader
                  style={{ color: "#000055", margin: "16px, 16px 0px" }}
                  title={tier.title}
                  subheaderTypographyProps={{
                    variant: "body1",
                    color: "textPrimary"
                  }}
                  subheader={tier.subheader}
                />
                <CardContent style={{ marginTop: 16 }}>
                  <div style={{ marginBottom: tier.cta ? 50 : 24 }}>
                    {tier.cta && (
                      <div
                        className={
                          tier.price === " " ? classes.cta2 : classes.cta
                        }
                      >
                        {tier.cta}
                      </div>
                    )}
                    {tier.title !== "Enterprise" && (
                      <>
                        {/* <div className={classes.priceDollar}>{tier.currency}</div> */}
                        <div className="price">
                          <sup style={{ fontSize: 12, fontWeight: 400 }}>$</sup>
                          {renderPrice(tier)}
                          {tier.priceDenom}
                          {discount !== 1 && (
                            <span
                              style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                fontWeight: 400
                              }}
                            >
                              &nbsp;(with discount applied)
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {tier.title !== "Enterprise" && (
                      <div className="small">{renderSeatSelect(tier)}</div>
                    )}
                  </div>
                  <h6>Top Features:</h6>
                  {tier.description.map(line => (
                    <div className="" key={line} style={{ margin: "10px 0px" }}>
                      {line}
                    </div>
                  ))}
                </CardContent>
                <CardActions>
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginBottom: 24,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    {/* {renderSubscription(tier)} */}
                    {isHostedOrg &&
                      seats != currentSeats &&
                      subscriptionName !== "None" && (
                        <Button
                          className="breefly-btn btn-gray"
                          type="submit"
                          variant="contained"
                          id={`checkout-button-${products.affiliateOrgPlan}`}
                          onClick={e => handleTeamsClick(e)}
                          style={{ marginBottom: 16 }}
                        >
                          {/* {isSubscription &&
                      isSubscription.id !== "no-subscription" &&
                      tier.title !== subscriptionName
                        ? switchPlus
                        : defaultButtonText} */}
                          Update Seats
                        </Button>
                      )}
                     {isSinglePayment && renderSinglePaymentButtons()} 
                    {!isSinglePayment && renderCheckoutButton(tier)}
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        {renderContactUsForOffer()}

        <Grid container direction="row" justifyContent="center" alignItems="center">
          {!isHostedOrg && !isSinglePayment && (
            <Grid xs={10} className="bottom">
              <h6>
                <img
                  src={columnIcon}
                  alt="compare log"
                  className="column-icon"
                />
                <a
                  href={
                    subscription &&
                    subscription.subDomain &&
                    (subscription.subDomain.includes("pma") ||
                      subscription.subDomain.includes("insite"))
                      ? "https://www.breefly.io/pricing-pma"
                      : "https://www.breefly.io/pricing"
                  }
                >
                  View a full comparison list
                </a>
              </h6>
            </Grid>
          )}
        </Grid>

        {/* Test tools remove for production */}

        <Grid item xs={12} className="bottom">
          {/* <p>{handleTest()}</p> */}
          {handlePaymentInfoTest()}
        </Grid>
      </Container>
      <CancelSubscription classes={classes} modal={modal} funcs={funcs} />
      {openPaymentModal && (
        <CollectPaymentModal
          classes={classes}
          open={openPaymentModal}
          funcs={funcs}
        />
      )}
      {openDowngrade.isOpen && (
        <DowngradeModal
          classes={classes}
          modal={openDowngrade}
          closeModal={setOpenDowngrade}
          funcs={funcs}
          history={history}
          diff={seatsUsed - seats}
        />
      )}
      {expiredTrialModal && (
        <ExpiredTrialModal open={expiredTrialModal} funcs={funcs} />
      )}
      <EnterpriseModal
        classes={classes}
        modal={enterprise}
        funcs={funcs}
        apiManager={apiManager}
      />
      {confirmationModal.isOpen && (
        <ConfirmationModal
          closeConfirmationModal={closeConfirmationModal}
          confirmationModal={confirmationModal}
        />
      )}
    </div>
  );
}
