import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import IconButton from '@material-ui/core/IconButton';
//import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
//import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };

const classes = {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: red[600],
    },
    info: {
      backgroundColor: orange[600],
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    snack: {
      position: 'fixed',
      zIndex: 1,
      left: '50%'
    }
  };



function MySnackbarContentWrapper(props) {
   
    const { className, message, onClose, variant, isSnackOpen, ...other } = props;
    const Icon = variantIcon[variant];

    const renderSnack = () => {
      return (<SnackbarContent
        className={`${clsx(classes[variant], className)} ` + classes.snack}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            <span className="snackbar-message">{message}</span>
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={clsx(classes.icon, classes.iconVariant)} />
          </IconButton>,
        ]}
        {...other}
      />)
    }
  
    return (
      isSnackOpen ? renderSnack() : null
    );
  }
  
  MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  };

  export default MySnackbarContentWrapper;

 