import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";
import moment from "moment";
import {
  faFont,
  faItalic,
  faBold,
  faQuoteLeft,
  faEdit,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { products } from "../../../../constants";
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountName = props => {
  const {
    className,
    user,
    funcs,
    subscription,
    subscriptionName,
    apiManager,
    ...rest
  } = props;
  const [productName, setProductName] = React.useState("");
  const [subscribedUser, setSubscribedUser] = React.useState(false);
  useEffect(() => {

    if (subscription && subscription.plan && subscription.plan.id) {
      let subPlan = subscription.plan.id
      if (subPlan == products.individualPlan) {
        setProductName('Individual Account');
        setSubscribedUser(true);
      }
      if (subPlan == products.standAloneOrgPlan
        || subPlan == products.affiliateOrgPlan) {
        setProductName('Team Account');
        setSubscribedUser(true);
      }
      if (subPlan == products.parentOrgPlan) {
        setProductName('Enterprise Account');
        setSubscribedUser(true);
      }
      if (subPlan == products.singlePrice6x5) {
        setProductName('Team Single Payment Account');
        setSubscribedUser(true);
      }
    } else {
      if (user.accounttype && user.accounttype === 'teamSinglePayment') {
        setProductName('Team Single Payment Account');
      } else if (isTeam || (user.accounttype && user.accounttype === 'team')) {
        setProductName('Monthly Team Account');
      } else {
        setProductName('Individual Account');
      }
    }
  }, [subscription]);
  const isTrialing =
    (subscription && subscription.status == "trialing") || false;
  const showChargeDate =
    productName !== "Team Single Payment Account" ||
    (productName === "Team Single Payment Account" && isTrialing);
  const isTeam = user.orgId || false;
  const orgName = user.orgName || null;
  const orgOwner = user.orgOwner || null;
  const hasSubscription =
    subscription && subscription.id !== "no-subscription" ? true : false;
  const accountName = isTeam
    ? `Plan`
    : !hasSubscription
    ? "Individual Account (unsubscribed)"
    : `Individual Subscription: ${subscriptionName}`;
  const subscriptionStatus =
    subscription &&
    subscription.items &&
    subscription.items.data &&
    subscription.items.data[0] &&
    subscription.items.data[0].plan &&
    subscription.items.data[0].plan.active
      ? "Active"
      : "Inactive";
  const data = props;
  let CTA = "Upgrade Now";
  if (hasSubscription) {
    // not team, so individual and subscribed
    CTA = "Manage Your Plan";
  }
  if (isTeam && user.orgRole !== "owner") {
    CTA = false;
  }
  const classes = useStyles();

  const [values, setValues] = useState({
    account: user.account,
    accountConfirm: user.accountConfirm
  });

  const [error, setError] = useState();

  const upgradeAccount = () => {
    window.location = "/subscriptions";
  };

  const updateAccount = async () => {
    try {
      const result = await apiManager.updateAccount(values);

      if (result && result.account) {
        funcs.openModal(true, `Account name has been updated!`);

        data.updateUser(result);
      } else {
        funcs.openModal(false, `Account name has NOT been updated!`);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const renderEdit = () => {
    return (
      <div>
        <Button variant="outlined" onClick={updateAccount}>
          Update
        </Button>
      </div>
    );
  };

  const renderDisabled = () => {
    return (
      <div>
        <Button variant="outlined" onClick={upgradeAccount}>
          {CTA}
        </Button>
        {/* <HtmlTooltip
            placement='top'
            title={
                <React.Fragment>
                    <Typography color="inherit">Upgrade your subscription</Typography>
                    {"You must upgrade your subscription in order to set an Account Name. The default value is Breefly, Inc."}
                </React.Fragment>
            }
        >
            <IconButton aria-label="info">
                <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </IconButton>
        </HtmlTooltip> */}
      </div>
    );
  };

  const renderManage = () => {
    return (
      <div>
        {CTA && (
          <Button variant="outlined" onClick={upgradeAccount}>
            {CTA}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <div style={{ marginLeft: 24, marginBottom: 12 }}>
          <h3 style={{ fontSize: 20 }}>{accountName}</h3>
          {isTeam && user.orgRole !== "owner" && (
            <h5>
              <i>Your team owner is {orgOwner}</i>
            </h5>
          )}
        </div>

        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            {error}
          </Grid>
        </Grid>
        <CardContent style={{ marginLeft: 12, marginRight: 12 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {hasSubscription ? (
                  <>
                    <TableCell>Your Plan</TableCell>
                    <TableCell align="right">Start Date</TableCell>
                    {showChargeDate && (
                      <TableCell align="right">Next Charge Date</TableCell>
                    )}
                    <TableCell align="right">Status</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>Your Plan Status for {productName}</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {hasSubscription ? (
                  <>
                    <TableCell component="th" scope="row">
                      {subscribedUser ? productName : subscriptionName}
                    </TableCell>
                    <TableCell align="right">
                      {moment(subscription.start_date * 1000).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    {showChargeDate && (
                      <TableCell align="right">
                        {moment(subscription.current_period_end * 1000).format(
                          "MM/DD/YYYY"
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      style={{ textTransform: "capitalize" }}
                      align="right"
                    >
                      {subscriptionStatus}
                    </TableCell>
                  </>
                ) : (
                  <TableCell align="left">
                    {isTeam && user.orgStatus == "active" ? (
                      user.orgRole == "owner" ? (
                        <b>
                          Your team, {user.teamname}, does not have an active
                          plan. Upgrade today!
                        </b>
                      ) : (
                        <span>
                          {user.orgRole == "member"
                            ? `${productName} - Team Member`
                            : `${productName} - Admin`}
                        </span>
                      )
                    ) : (
                      <b>You do not have an active plan. Upgrade today!</b>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
          {/* <Grid
          container
          spacing={2}>
            <Grid
            item
            xs={12}
            sm={6}>
              <TextField
                fullWidth
                label="New Account Name"
                name="account"
                onChange={handleChange}
                type="text"
                disabled={subscription && !subscription.active}
                value={values.account}
              /> 
            </Grid>
            <Grid
            item
            xs={12}
            sm={6}>
              <TextField
                fullWidth
                label="Confirm Account Name"
                name="accountConfirm"
                onChange={handleChange}
                type="text"
                error={values.accountConfirm != values.account}
                disabled={subscription && !subscription.active}
                value={values.accountConfirm}
              /> 
            </Grid>
          </Grid > */}
        </CardContent>
        <CardActions style={{ marginLeft: 16, marginBottom: 12 }}>
          {/* { values.account === values.accountConfirm ? renderEdit() : renderDisabled() }  */}
          {/* { subscription && subscription.active === 1 && values.account === values.accountConfirm ? renderEdit() : renderDisabled() }  */}
          {renderManage()}
        </CardActions>
      </form>
    </Card>
  );
};

AccountName.propTypes = {
  className: PropTypes.string
};

export default AccountName;
