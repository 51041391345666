import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RowMenu from "./RowMenu";
import { IconButton, Avatar, LinearProgress, } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export default function RenderBriefInvites({
  brief,
  apiManager,
  //hideDetails,
  //handleDelete,
  updateAccess,
  //refreshChild,
  isOpen
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [people, setPeople] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [forcer, forceUpdate] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  //const [formValues, setFormValues] = React.useState([]);

  const getPeople = async () => {
    setIsLoading(true);
    const response = await apiManager.getBriefUsers(brief, {
      skip: 0,
      limit: 25,
      isFavorites: false,
      search: ""
    });
    if (response) {
      const { data, count } = response;
      let people = [];
      //setTotalItems(count);
      //setPageCount(  count > 1 ? Math.ceil(count/limit) : 1);

      data.forEach(ub => {
        ub.selected = false;
        people.push(ub);
      });
      setPeople(people);
    }
    setIsLoading(false);
  };

  if (isOpen && !init && !people) {
    setInit(true);
    getPeople();
  }

  const executeBulkSelection = async access => {
    await Promise.all(
      people.map(async invite => {
        const data = invite && invite.briefData ? invite.briefData : {};

        if (invite && invite.selected) {
          invite.brief = data._id;
          invite.id = data._id;

          invite.access = access;

          await updateAccess(invite);
        }
      })
    );

    setAnchorEl(null);
    await getPeople();
  };

  const executeSelection = async (access, invite) => {
    if (invite) {
      const data = invite && invite.briefData ? invite.briefData : {};

      invite.brief = data._id;
      invite.id = data._id;

      invite.access = access;

      await updateAccess(invite);
    }

    await getPeople();
  };

  const handleCheck = name => event => {
    if (name === "checkAll") {
      const value = !checkAll;
      setCheckAll(value);
      people.forEach(ub => {
        ub.selected = value;
      });
    } else {
      //setCheckAll(false);
      people.forEach(ub => {
        if (ub._id == name) {
          ub.selected = !ub.selected;
        }
      });
      forceUpdate(!forcer);
    }
  };

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const renderBriefs = () => {
    if(isLoading) {
      return(<div style={{width: '80%', margin: '4px auto'}}>
        <LinearProgress />
      </div>)
    }

    if (!people || people.length === 0) {
      return (  <span>
        This brief has not been shared yet
        </span>)
    }
    return (
      <span>
        This brief has been shared with the following users:
        <span>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    name="checkAll"
                    onChange={handleCheck("checkAll")}
                    value={checkAll}
                    color="primary"
                  />
                  Select All
                </TableCell>
                {/* <TableCell align="left"></TableCell> */}
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Invited</TableCell>
                <TableCell align="left">Access</TableCell>
                <TableCell align="left">
                  {" "}
                  <div
                    style={{
                      float: "right",
                      marginRight: 8,
                      position: "relative"
                    }}
                  >
                    <IconButton
                      aria-label="close"
                      className={classes.icon}
                      size="small"
                      onClick={openMenu}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} color={"primary"} />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            {people.map((person, index) => (
              <TableRow key={Math.random()}>
                <TableCell align="left" style={{ width: 150 }}>
                  <Checkbox
                    name={person._id}
                    checked={person && person.selected}
                    onChange={handleCheck(person._id)}
                    value="checkAll"
                    color="primary"
                  />
                  <div style={{ display: "inline-block", marginLeft: 12 }}>
                    <Avatar
                      alt="Person"
                      className={`${classes.avatar} ${classes.avatarcolor1}`}
                    >
                      {person && person.firstname
                        ? `${person.firstname
                            .toUpperCase()
                            .charAt(0)}${person.lastname
                            .toUpperCase()
                            .charAt(0)}`
                        : person.email.substring(0, 2)}
                    </Avatar>
                  </div>
                </TableCell>
                {/* <TableCell align="left">
  
                    </TableCell> */}
                <TableCell align="left">
                  {person.firstname} {person.lastname}
                </TableCell>
                <TableCell align="left">{person.email}</TableCell>
                <TableCell align="left">
                  {moment(person.created).format("MM-DD-YYYY")}
                </TableCell>
                <TableCell align="left" style={{textTransform: 'capitalize'}}>
                  {person.access == "admin" ? "Co-Owner" : person.access}
                </TableCell>
                <TableCell align="left">
                  <RowMenu
                    executeSelection={executeSelection}
                    classes={classes}
                    person={person}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>

          <Menu
            id="people-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem
              onClick={e => executeBulkSelection("deleted")}
              className={classes.menuitem}
            >
              Remove Access
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("viewer")}
              className={classes.menuitem}
            >
              Viewer
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("editor")}
              className={classes.menuitem}
            >
              Editor
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("admin")}
              className={classes.menuitem}
            >
              Co-Owner
            </MenuItem>
          </Menu>
        </span>
      </span>
    );
  };

  return renderBriefs();
}
