import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
// import People from './People/People'
// import Chart from './People/components/Chart'

import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  InputBase,
  TextField,
  Dialog, DialogActions
  
} from "@material-ui/core";


import {
  faEnvelope,
  faCog,
  faSearch,
  faPlusCircle,
  faPauseCircle,
  faCloudUploadAlt,
  faUpload,
  faUsers
  
} from "@fortawesome/pro-light-svg-icons";

import BrandedCardTitle from "./components/BrandedCardTitle";
import UploadsList from "./components/UploadsList";
import AffiliateList from "./components/AffiliateList";
import InviteSubscriber from "./components/InviteSubscriberForm"
import BulkSubscriber from "./components/BulkSubscriberForm"

import DataForm from "./components/DataUploadForm";
import { FAKE_ORGANIZATION } from "../../constants";
import "./style.scss";
import LinearIndeterminate from "../Briefs/components/LinearIndeterminate";
import SnackBarMessage from '../SignIn/components/RegisterSnackBar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SimpleTextField = withStyles({
  root: {
    marginBottom: 26,
    "& label": {
      fontSize: 12
    },
    "& input": {
      fontSize: 12,
      color: "#000000 !important"
    },
    "& label.Mui-focused": {
      color: "#000000 !important",
      fontSize: 8
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #000000"
    },
    "& .MuiInput-underline.Mui-error:after": {
      transform: "scaleX(1)",
      borderBottomColor: "red !important"
    }
  }
})(TextField);
const SimpleCheckbox = withStyles({
  root: {
    color: "#ACACAC",
    "&$checked": {
      color: "#707070"
    }
  }
})(props => <Checkbox color="default" {...props} />);
const styles = theme => ({
  root: {
    //   padding: theme.spacing(4),
    minHeight: "90vh"
  },
  img: {
    maxWidth: "600px"
  },
  card: {
    padding: 24
  },
  search: {
    color: "#757575",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginRight: 16,
    height: 32
  },
  searchIcon: {
    height: "100%",
    margin: "0px 8px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#757575"
  },
  inputRoot: {
    color: "#757575",
    width: "200px",
    "&.Mui-focused": {
      width: "250px"
    },
    transition: theme.transitions.create("width")
  },
  inputInput: {
    fontSize: 12,
    width: "100%"
  },
  addSeat: {
    root: {
      fontSize: 14
    },
    justifyContent: "flex-end"
  }
});

const DataDetails = props => {
  const { history, apiManager, classes, user, settings } = props;

  const [spacing, setSpacing] = React.useState(2);
  const [search, setSearch] = React.useState("");
  const [limit, setLimit] = React.useState(25);
  const [skip, setSkip] = React.useState(0);
  const [uploads, setUpload] = React.useState(false); 
  const [filter, setFilter] = React.useState("sentiment"); //filter disabled
  // const [suspendType, setSuspendType] = React.useState(""); suspend not implemented
  const [initialLoad, setInitialLoad] = useState(false);
  const [org, setOrganization] = React.useState(false);
  const [color1, setColor1] = React.useState("#000055");
  const [updated, setUpdated] = React.useState(new Date().toDateString());
  const [searchTerm, setSearchTerm] = React.useState("");
  const [fileToUpload, setFileToUpload] = React.useState("");
  const [suspendOpen, setSuspendOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertError, setAlertError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Alert");
  const [alertProgress, setAlertProgress] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [snack, setSnack] = useState({isOpen: false, isSuccessfull: true, message: ''});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    period: "",
  });
  const [fileToReplace, setFileToReplace] = useState('');

  const [selectedMember, setSelectedMember] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshMembers, doMemberRefresh] = useState(false);
  const [refreshAffiliates, doAffiliateRefresh] = useState(false);
  const [affiliateLoginHistory, setAffiliateHistory] = useState({ "within60Days": 0, "within30Days": 0, "within24Hours": 0 });

  const rootDoc = document.documentElement;
  
  //Get org data
    const getData = async (overrideSearch) => {
      // DEPRECATED:
      // const paths = history.location.pathname.split('/');
      // const id = paths[paths.length - 1]
  
      const params = {
        'id': user.orgId
      }
  
      if (FAKE_ORGANIZATION) {
        params.id = "5ef8e4dc2c27b96d8173db7a";
      }
  
      const orgData = await apiManager.getOrganization(params);
  
      if (orgData) {
        setOrganization(orgData);
      }

      const affilliateLogins = await apiManager.getAffliliateLoginHistory();

      if (affilliateLogins) {
        setAffiliateHistory(affilliateLogins);
      }
      
  };

  //set initial data
  useEffect(() => {
    getData();
  }, []);

  const closeSnack = async () => {
    setSnack({isOpen: false, isSuccessfull: false, messsage: ''});
  }

  const openSnack = async (isSuccessfull, message) => {
    setSnack({isOpen: true, isSuccessfull, message});
    setBulkOpen(false)
  }


  //open data upload modal
  const openUpload = event => {
    setUploadOpen(true);
  };

  //data modal form state handler
  const handleChange = event => {
    const {name, value} = event.target;
    let {period} = formData;

    if( value === 'sentiment') {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            ['period']: 'quarter'
          }))
    } else {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
          }))
    }


  }

  //open replace data modal
  const handleReplace = file => {
    setFileToReplace(file[0].fileName);
    setFormData({
      type: file[0].category,
      period: file[0].period.includes("Q") ? "quarter" : '', //update once data names are final
    })
    setSelectedDate(file[0].date)
    setUploadOpen(true);
  };

  const onSearchInput = e => {
    setSearchTerm(e.target.value);
  };
  
  //filter currently inactive
  const handleFilter = e => {
    setFilter(e.currentTarget.id);
  };

 //open invite subscriber modal
  const [openInvite, setOpenInvite] = React.useState(false);
  const openInviteModal = event => {
    setOpenInvite(true);
  }

//open bulk subscriber invite modal
  const [openBulk, setBulkOpen] = useState(false);
    

  const updateAffiliate = async (action, affiliate, message) => {
    setAlertTitle(`Update for ${affiliate.fullName}`);
    setAlertMessage(`${message}`);
    setAlertOpen(true);
  };

  const closeAlertDialog = () => {
    setAlertOpen(false);
  }

  //Render data filters
  const FilterData = props => {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className="bottom-padding">
          <Button
            id="sentiment"
            onClick={handleFilter}
            className={filter === "sentiment" ? "" : "filter-disabled"}
          >
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
            Consumer Sentiment
          </Button>
        </Grid>
        <Grid item>
          <Button
            id="floral"
            onClick={handleFilter}
            className={filter === "floral" ? "" : "filter-disabled"}
          >
            <FontAwesomeIcon icon={faCog} style={{ marginRight: 8 }} />
            IRI Floral
          </Button>
        </Grid>
        <Grid item>
          <Button
            id="produce"
            onClick={handleFilter}
            className={filter === "produce" ? "" : "filter-disabled"}
          >
            <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />
            IRI Produce
          </Button>
        </Grid>
      </Grid>
    );
  };

  //Render dropzone uploader 
  const FileUploadButton = props => {
    return (
      <Button>
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          className="file-upload-button"
          style={{ width: 192, outline: 'none' }}
          onClick={openUpload}
        >
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faUpload} style={{ fontSize: 30 }} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <p>Upload New Data</p>
            <span>CSV files only</span>
          </Grid>
        </Grid>
      </Button>
    );
  };

  const onSuccess = (message = 'Upload complete.') => {
    setAlertTitle(`Upload data`);
    setAlertMessage(message);
    setAlertOpen(true);
  }

  //Render Bulk Invite Button
  const BulkInviteButton = props => {
    return (
      <Button
        onClick={() => setBulkOpen(true)}
      >
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          className="file-upload-button"
          style={{ width: 192, outline: 'none' }}
          
        >
          <Grid item xs={12}>
            {/* <FontAwesomeIcon icon={faPlusCircle} /> */}
            <FontAwesomeIcon icon={faUsers} style={{ fontSize: 30 }} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <p>Send a group list invite</p>
          </Grid>
        </Grid>
      </Button>
    );
  };

  return (
    <div className={`${classes.root} branded`}>
      {org && org.branding && org.branding.portallogo && (
          <div className='pageLogo' style={{marginTop:-56}}>
            <img src={org.branding.portallogo} width='125px' />
          </div>
        )}
      <h4 style={{marginTop: 80, marginBottom: 40}}>
        {/* Team Management for {org.company.company} */}
        Database Management
      </h4>
      {/* <FilterData /> */}
      <Grid container spacing={2}>
        {/* Data Management */}
        <Grid item xs={12}>
          <Card className={`card`}>
          {/* <BrandedCardTitle
              title={"Manage Data"}
              cta={""}
              cta={"Suspend Database"}
              color="brandColor1"
              icon={faPauseCircle}
              callback={suspendDatabase}
              callback={}
            /> */}
            <BrandedCardTitle
              title={"Manage Data"}
              cta={""}
              color="brandColor1"
              icon={faPauseCircle}
              callback={''}
            />
            <CardContent className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className="cardTop">
                    <span>Data Sets</span>
                  </div>
                  <FileUploadButton />
                </Grid>
                <Grid item xs={9} >
                  <div className="cardTop">
                    <span>Upload History</span>
                    <div className={`cardSearch ${classes.search}`}>
                      <div className={classes.searchIcon}>
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <InputBase
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput
                        }}
                        placeholder="Search"
                        onChange={onSearchInput}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </div>
                  </div>
                  <UploadsList
                    apiManager={apiManager}
                    org={org}
                    searchTerm={searchTerm}
                    refreshData={refreshMembers}
                    filter={filter}
                    handleReplace={handleReplace}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.addSeat}>
              {/* CARD ACTION PLACE HOLDER*/}
            </CardActions>
          </Card>

          <div style={{ height: 18, width: 1 }} />
        </Grid>
        {/* Affiliate Management */}
        <Grid item xs={12}>
          <Card className={`card`}>
            <BrandedCardTitle
              title={"Manage Subscribers"}
              cta={"Invite a Subscriber"}
              color="brandColor1"
              icon={faEnvelope}
              callback={openInviteModal}
            />
            <CardContent className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className="cardTop">
                    <span>Logins: {affiliateLoginHistory.within24Hours} (1d)&nbsp;&nbsp;{affiliateLoginHistory.within30Days} (30d)&nbsp;&nbsp;{affiliateLoginHistory.within60Days} (60d)</span>
                  </div>
                  <BulkInviteButton />
                </Grid>
                <Grid item xs={9} >
                  <div className="cardTop">
                    <span>Subscribers to this data set</span>
                    <div className={`cardSearch ${classes.search}`}>
                      <div className={classes.searchIcon}>
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <InputBase
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput
                        }}
                        placeholder="Search"
                        onChange={onSearchInput}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </div>
                  </div>
                    <AffiliateList
                      apiManager={apiManager}
                      org={org}
                      user={user}
                      affiliates
                      searchTerm={searchTerm}
                      refreshData={openInvite || openBulk}
                      isSubscriberList={true}
                      updateAffiliate={updateAffiliate}
                    />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.addSeat}>
              {/* CARD ACTION PLACE HOLDER*/}
            </CardActions>
          </Card>

          <div style={{ height: 18, width: 1 }} />
        </Grid>
      </Grid>
      {/* SUSPEND DATABASE DIALOG
      <Dialog
        open={suspendOpen}
        onClose={() => setSuspendOpen(false)}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="Team Alert"
      >
        <Card className={`branded card`}>
          <BrandedCardTitle title={alertTitle} />
          <CardContent>
            <div style={{ fontSize: 18, padding: 24, minWidth: 300 }}>
              {alertError ? (
                <>
                  <span style={{ color: "darkred" }}>alertMessage</span>
                </>
              ) : (
                <>{alertMessage}</>
              )}
              {alertProgress && (
                <div>
                  <LinearIndeterminate />
                </div>
              )}
            </div>
          </CardContent>

          <DialogActions>
            <Button onClick={() => setSuspendOpen(false)} color="primary">
              Close
            </Button>
            {suspendOpen ? (
              <Button
                onClick={() => setSuspendOpen(false)}
                color="primary"
                variant="contained"
                className="brandColor2"
              >
                Suspend
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Card>
      </Dialog>
       */}

       {/* alert dialog */}
       <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="Team Alert"
      >
        <Card className={`branded card`}>
          <BrandedCardTitle title={alertTitle} />
          <CardContent>
            <div style={{ fontSize: 18, padding: 24, minWidth: 300 }}>
              {alertError ? (
                <>
                  <span style={{ color: "darkred" }}>alertMessage</span>
                </>
              ) : (
                <>{alertMessage}</>
              )}
              {alertProgress && (
                <div>
                  <LinearIndeterminate />
                </div>
              )}
            </div>
          </CardContent>

          <DialogActions>
            <Button onClick={closeAlertDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Card>
      </Dialog>

      <DataForm onSuccess={onSuccess} handleChange={handleChange} formData={formData} uploadOpen={uploadOpen} apiManager={apiManager} setUploadOpen={setUploadOpen} handleDateChange={setSelectedDate} selectedDate={selectedDate} setFileToUpload={setFileToUpload} fileToUpload={fileToUpload} />
      <InviteSubscriber apiManager={apiManager} openInvite={openInvite} setOpenInvite={setOpenInvite} org={org} user={user}/>
      <BulkSubscriber 
        openBulk={openBulk} 
        setBulkOpen={setBulkOpen} 
        apiManager={apiManager} 
        openPageSnack = {openSnack}
        org={org} 
        classes={classes}
        user={user}
        setOuterSnack={setSnack}
        />
      <SnackBarMessage  snack={snack} closeModal={closeSnack} classes={classes} closeTime={15000} />
    </div>
  );
};

DataDetails.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(DataDetails);
