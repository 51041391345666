import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { faComment, faThumbsDown, faThumbsUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Grid,
    Button,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import Tribute from 'tributejs';
import LocalSettings from '../../../services/LocalSettings';

const useStyles = makeStyles({
    list: {
        // minWidth: '800px'
        maxWidth: 350,
        minWidth: 250,
    },
    fullList: {
        width: 'auto',
    },
    userName: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    dateStamp: {
        textTransform: 'uppercase'
    },
    commentBody: {
        margin: '12px 0px',
        fontWeight: 500,
    },
    tributeTextArea: {
       width: 200,
       minHeight: 100,
       border: '1px solid #dddddd',
       borderRadius: 5,
       padding: 6,
    },
});

export default function Rightbar(props) {
    const classes = useStyles();
    const isPublic = props.isPublic;
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [el, setEl] = React.useState(false);
    const [mentions, setMentions] = React.useState([]);

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });

        onClose();
    };

    const { isOpen, onClose, comments, funcs, isMobile, user } = props;
    const [openRightbar, setOpenRightbar] = React.useState(false);
    const [comment, setComment] = React.useState('');

    if (isOpen && !openRightbar) {
        toggleDrawer('right', true)
    }

    const renderComments = () => {
  
        return (
        <Grid>
            <Grid item>
            <Typography style={{ padding: '20px' }} variant="body1">
            <FontAwesomeIcon size={'small'} style={{marginRight: 10}} icon={faComment} /> Comments
                <IconButton edge="start" color="inherit" size='small' onClick={toggleDrawer('right', false)} aria-label="close" style={{ position: 'absolute', top: 12, right: 12, color: '#555' }}>
                        <CloseIcon />
                    </IconButton>
                </Typography>

            </Grid>
            {renderAddComments()}
            {renderUserComments()}

        </Grid>
        )
    }

    const likeComment = (comment) => {
        const { user, dislikes } = comment;
        funcs.likeComment(comment)

    }

    const dislikeComment = (comment) => {   
        funcs.dislikeComment(comment)
    }


    const addNewComment = () => {
     
      // remove mentions that have been removed by the user using the backspace
      for(let m=mentions.length-1; m>=0; m--) {
        if(!el.innerHTML.includes(mentions[m])) {
          mentions.splice(m, 1);
        }
      }
     
        funcs.addComment(el.innerHTML, comment, mentions)
        setComment('')
        el.innerHTML = '';
        el.innerText = '';
    }

    const renderUserComments = () => {
        if (!comments) {
            return null;
        }
    const html = comments.map((data) => {
        const { created, userData, likes, dislikes, mentions } = data;
        let {comment} = data;
        const isLiked = likes ? likes.filter(function (value) {
            return value._id === user._id
        }).length > 0 : false;
        const isDisLiked = dislikes ? dislikes.filter(function (value) {
            return value._id === user._id
        }).length > 0 : false;

        if (isPublic) {
          const m = mentions;
          if(mentions && mentions.length> 0) {
            mentions.forEach(m => {
              const atSign = m.indexOf('@');
              const replace = m.substr(0, atSign);
              comment = comment.replace(m, replace);

            })
          }
        }

        return (<Grid style={{ padding: '20px' }}
            ><Grid item
            >
                    <div className={classes.userName}>{userData && userData.firstname ? `${userData.firstname} ${userData.lastname}.` : userData && userData.email ? userData.email : 'Unknown user'}</div>
                    <div className={classes.dateStamp}>{moment(created).format('MMM DD, YYYY     hh:mm')}</div>
                    {/* <div className={classes.commentBody}  >{comment}</div> */}
                    <div className={classes.commentBody} dangerouslySetInnerHTML={{ __html: `${comment}` }} />
                    <div>
                        <IconButton aria-label="close" className={classes.icon} color={isLiked ? 'green' : ''} onClick={e => likeComment(data)} >
                            <FontAwesomeIcon size={'xs'} icon={faThumbsUp} color={isLiked ? 'green' : ''}/>
                        </IconButton>  {likes ? likes.length : 0}
                        <IconButton aria-label="close" className={classes.icon} color={isDisLiked ? 'red' : ''} onClick={e => dislikeComment(data)} >
                            <FontAwesomeIcon size={'xs'} icon={faThumbsDown} color={isDisLiked ? 'red' : ''} />
                        </IconButton>   {dislikes ? dislikes.length : 0}
                    </div>
                    <hr />
                </Grid>
            </Grid>)
        })
        return html;
    }


  const tribute = new Tribute({
    trigger: '@',
    // element to target for @mentions
    iframe: null,

    // class added in the flyout menu for active item
    selectClass: 'tribute-highlight',

    // class added to the menu container
    containerClass: 'tribute-container',

    // class added to each list item
    itemClass: 'tribute-item',

    // function called on select that returns the content to insert
    //   selectTemplate: function (item) {
    //     return '@' + item.original.value;
    //   },
    selectTemplate: function (item) {
      if(!mentions.includes(item.original.value)) {
        const m = mentions;
        m.push(item.original.value)
        setMentions(m);
      }
      return (
        '<span contenteditable="false" class="mention-color">' +
        item.original.value +
        "</span>"
      );
    },

    // template for displaying item in menu
    menuItemTemplate: function (item) {
      return item.string;
    },

    // template for when no match is found (optional),
    // If no template is provided, menu is hidden.
    noMatchTemplate: null,

    // specify an alternative parent container for the menu
    // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
    // default container is the body
    menuContainer: document.body,

    // column to search against in the object (accepts function or string)
    lookup: 'key',

    // column that contains the content to insert by default
    fillAttr: 'value',

    // REQUIRED: array of objects to match
    values: [],

    searchOpts: {
      pre: '<span>',
      post: '</span>',
      skip: false // true will skip local search, useful if doing server-side search
    },

    // specify the minimum number of characters that must be typed before menu appears
    menuShowMinLength: 0
  });

  const attachTribute = async() => {

    const c = tribute.collection[0];
    const v = tribute.collection[0].values;
 
      let el = document.getElementById("testtribute");
      if( el) {
         if (v.length === 0) {
          // c.values.concat(ab.tlist);
          const ab = await LocalSettings.getAB();
          ab.tlist.forEach(item => {
            if (item.type === 'email') {
              c.values.push(item)
            }
          })
        }
        tribute.attach(el);
      }
  }


    const handleCommentInput = e => {
        const newText = e.target.innerText;
        setEl(e.target);
        setComment(newText);
    }

    const renderAddComments = () => {
        return (
        <Grid>
            <Grid item style={{ padding: '20px' }}>
                <Typography variant="body1" style={{marginBottom: 8}}>Add a comment</Typography> 

                <p  
                    id='testtribute' 
                    className={classes.tributeTextArea} 
                    contentEditable="true" 
                    onFocus={attachTribute} 
                    data-tribute='true'
                    placeholder="Enter your comment..."
                    onInput={handleCommentInput}
                >
                </p>

                {/* <TextField

                    className={classes.textField}
                    fullWidth
                    label="Comment"
                    name="0"
                    type="text"
                    variant="outlined"
                    multiline={true}
                    rows={5}
                    value={comment}
                    // required
                    onChange={e => setComment(e.target.value)}
                    style={{ width: '100%', paddingBottom: '10px' }}
                    // helperText={comment === '' ? 'Please enter your comment' : ''}
                /> */}
                <Button
                    size="large"
                    type="submit"
                  //  variant="contained"
                    onClick={addNewComment}
                    disabled={comment === ''}
                    style={{float: 'right'}}
                >
                   {comment !== '' && (<>Add Comment</>)} 
                </Button>
            </Grid>
        </Grid>)
    }


    const renderByType = () => {
        const html = renderComments();
        return (<div
            className={classes.list}
            role="presentation"
        >
            {html}
          </div>);
    }


    return (
        <div>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer('right', false)} >
                {renderByType()}
            </Drawer>
        </div>
    );
}