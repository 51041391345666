import apiManager from '../ApiManager';
import defaultPortalImage from '../images/breefly-portal.jpg';

const LocalSettings = {

    defaultSettings: {
        branding: {
            logo: '',
            portalImage: defaultPortalImage,
            color1: '#0a1241',
            color2: '#EA3397',
            org: null,
        }
    },

      // sets logo, color1, and color2
  useBrandSettings: (values = null) => {

    const logo = values.logo ? values.logo : LocalSettings.defaultSettings.branding.logo;
    const color1 = values.color1 ? values.color1 : LocalSettings.defaultSettings.branding.color1;
    const color2 = values.color2 ? values.color2 : LocalSettings.defaultSettings.branding.color2;

    const rootDoc  = document.documentElement;
    rootDoc.style.setProperty('--brand-color1', color1);
    rootDoc.style.setProperty('--brand-color2', color2);
  }, 

  setSetting: (setting, value = {value: null}) => {
    let valueObj = value;
    if(typeof value === 'string') {
        valueObj = {value: value};
    }

    const response = window.localStorage.setItem(setting, JSON.stringify(valueObj));
    if (setting === 'orgBranding') {
        LocalSettings.useBrandSettings(value);
    }
  },


  clearSetting: (setting) => {
    window.localStorage.removeItem(setting);
  },



// get a settings.. either from memory or from a service
// if getting from a service, set the setting when done
  getSetting: async (setting, param = null) => {
    let response = window.localStorage.getItem(setting);

    if (response) {
        response = JSON.parse(response);
    } else if( setting === 'orgBranding' ) {
        const orgId = window.localStorage.getItem('organizationId');
        let branding = null;

        if( orgId ) {
            const oldBranding = window.localStorage.getItem('orgBranding');
            if( oldBranding ) {
                response = JSON.parse(oldBranding);
                LocalSettings.setSetting(setting, response);
                return response;
            }
            branding = await apiManager.getOrganizationBranding(JSON.parse(orgId).value);
        }

        if (!branding) {
            response = LocalSettings.defaultSettings.branding;
        } else {
            branding.org = JSON.parse(orgId).value;
            response = branding;
        }
        LocalSettings.setSetting(setting, response);

    } else if (LocalSettings.defaultSettings[setting]) {
        response = {value: LocalSettings.defaultSettings[setting]};
        LocalSettings.setSetting(setting, response);
    }
    return response;
},
  getAB: async (setting, param = null) => {
    if (!LocalSettings.ab ) {
      const result = await apiManager.getAddressBook();

      if (result && result.addressBook) {
        LocalSettings.ab = result.addressBook;
      }
    }

    return LocalSettings.ab;
  },
  resetAB: () => {
    if(LocalSettings.ab) {
      delete LocalSettings.ab;
    }
  }
}

    
export default LocalSettings;