// Stripe URLs down to just hostname
function extractHostname(url,tld) {
  let hostname;

  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
  }else {
      hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];

  //find & remove "?"
  hostname = hostname.split('?')[0];

  if(tld){
    let hostnames = hostname.split('.');
    hostname = hostnames[hostnames.length-2] + '.' + hostnames[hostnames.length-1];
  }

  return hostname;
}

// Extracts subdomain from TLD e.g. ('pma.breefly.com') -> 'pma'
function extractSubDomain() {
  const url = window.location.hostname
  const domain = '.breefly.com'
  // If this app is running on localhost or the parent brand, bailout
  if (url.indexOf('localhost') > -1 || url.indexOf('www') > -1 || url.indexOf(domain) == -1 ) return '';

  // Get the subdomain - e.g. pma 
  // let hostname = extractHostname(url);
  // let subDomain = hostname.split(domain)[0];
  let subDomain = url.split(domain)[0];
  subDomain.replace(/\./g,'',);

  return (subDomain.toLowerCase() !== 'stage') ? subDomain : '';
}

function getStageByDomain() {
  const url = window.location.hostname

  // Determine deployment stage by browser's location
  if (url.indexOf('localhost') > -1) return 'local'; // 'localhost:8020/api/v1';
  else if (url.indexOf('stage.') > -1) return 'stage'; // e.g. 'pma-stage.breefly.com' 'stage.breefly.com'
  else return 'prod'; // e.g. `www.breefly.com` || `pma.breefly.com`

}

export {
  extractHostname, extractSubDomain, getStageByDomain
};