import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core';
import axios from 'axios';
import { API_LOCAL } from '../../../../constants'; 
const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = props => {
  const { user, updateUser, funcs,  className, ...rest } = props;

  const classes = useStyles();


  const [values, setValues] = useState({
    email: false,
    sms: false,
    init: false
  } );

  const [error, setError] = useState();


  const updateNotifications = async() => {
     
    const headers = {
      'token': sessionStorage.getItem('token')
    }
    const url = (API_LOCAL) + 'users/notifications/update';
  
    try {
      const result = await axios.post(url, { values }, { headers });
     
      if(result && result.data && result.data._id){
        funcs.openModal(true, `Notifications have been updated!`)

        updateUser(result.data);
      }
      else {
        funcs.openModal(false, `Notifications have NOT been updated!`)
      }
    }
    catch(ex){
      console.log(ex);
    }
  }

  const handleChange = event => { 
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  if(!values.init){
    setError('')
    let notifications = user.notifications ? user.notifications : { email: false, sms: false, init: true };
    notifications.init = true;
    setValues({...notifications})
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Manage your notifications"
          title="Notifications"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
              
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="enabled"
                    onChange={handleChange} 
                    checked={values.email}
                  />
                }
                label="Email"
                name="email" 
              />
              
             
             
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
       {error }
      </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updateNotifications}
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
