import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    //display: 'flex',
    //alignItems: 'center',
    display: 'inline-block',
    '& .MuiButton-containedPrimary': {
      backgroundColor: '#22b9ea !important',
    },
    '& .MuiButton-containedSecondary': {
      backgroundColor: '#8c8c8c !important',
    },
    '& .MuiButton-label': {
      color: '#eee !important',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '25%',
    left: '42%',
    //marginTop: 12,
    //marginLeft: 12,
  },
  overrides: {
    MuiButton: { // Name of the component ⚛️ / style sheet
      label: { // Name of the rule
        color: 'white', // Some CSS
      },
    },
  },
}));

export default function CircularIntegration({ isLoading, isSuccessfull, onClick, btnLabel, timer, isValid }) {
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: isSuccessfull,
  });
  
  return (
    <div className={classes.root}>
      <div className={classes.wrapper} >
        <Button
          className={buttonClassname}
          variant="contained"
          color={isValid ? "secondary" : "primary"}
          fullWidth
          size='large's
          type="submit"
          disabled={isValid}
          onClick={onClick}
        >
          {btnLabel || 'Sign Up'}
        </Button>
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}