import React, { useState } from "react";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu
} from "@material-ui/core";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuidv4 from "uuid/v4";
import moment from "moment";
import ChartDisplay from "./ChartDisplay";
import DeleteDialog from "./DeleteDialog";
import SentimentChart from "../SentimentChart";
import domtoimage from 'dom-to-image'

export default function ChartCard(props) {
  const {
    settings,
    apiManager,
    asset,
    index,
    createBriefWithChart,
    hideAsset,
    duplicateChart
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [exportChart, setExportChart] = useState(false);
  const [vizMenuOpen, setVizMenuOpen] = useState(false);
  const openVizMenu = e => {
    setVizMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteClick = () => {
    setDeleteOpen(true);
    setVizMenuOpen(false);
  };
  const handleDelete = () => {
    hideAsset(asset);
    setDeleteOpen(false);
  };
  const filterButton = (node) => {
    return (node.tagName !== 'BUTTON')
  }
  const downloadClick = async () => {
      //if(asset.settings.dataset === "sentiment") {
        const div = `#vizDiv1-${index}`;
        let link = document.createElement('a');
        link.download = `chart-${asset.settings.title || ''}-${asset.settings.subtitle || ''}.png`
        //window.scrollTo(0,0);
        domtoimage.toPng(document.querySelector(div), {bgcolor: '#ffffff', filter: filterButton})
        .then(dataUrl => {
            link.href= dataUrl;
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
            handleClose();
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
          handleClose();
        });
    //   } else {
    //     setExportChart(true);
    //   }
  };
  const handleDuplicate = () => {
    let newAsset = { ...asset };
    newAsset.key = uuidv4();
    newAsset.date = moment().valueOf();
    delete newAsset._id;
    duplicateChart(newAsset);
    handleClose();
  };
  const createBriefClick = () => {
    handleClose();
    createBriefWithChart(
      asset._id,
      asset.settings.title,
      asset.settings.subtitle,
      asset.settings.time,
      asset.settings.dataset,
      asset.settings,
      asset.key,
      asset.date
    );
  };
  const handleClose = () => {
    setDeleteOpen(false);
    setVizMenuOpen(false);
    setExportChart(false);
  };
  return (
    <div
      className="card"
      id={`vizDiv1-${index}`}
      style={{ padding: 16, height: "100%", width: "100%" }}
    >
      <Button
        id={`vizCard-${index}`}
        className="menu"
        onClick={openVizMenu}
        style={{ padding: 0, paddingLeft: 18, minWidth: 1, fontSize: 27 }}
      >
        <FontAwesomeIcon icon={faEllipsisV} color={"#757575"} />
      </Button>
      <div className="title">{asset.settings.title}</div>
      {asset.dataset !== "sentiment" && (
        <div style={{ fontStyle: "italic", fontWeight: 400, fontSize: 14 }}>
          {asset.settings.subtitle}
        </div>
      )}
      <div
        id={`vizDiv-${index}`}
        style={{
          marginTop: 14,
          height: "100%",
          minHeight: 300,
          textAlign: "center",
          fontSize: 12
        }}
      >
        {asset.settings.dataset === "sentiment" ? (
          <SentimentChart
            chartDiv={`vizDiv-${index}`}
            chartDivTitle={null} // {`chartDivTitle-${index}`}
            chartSettings={asset.settings}
            chartData={null}
            chartAssetId={asset._id}
            appSettings={null}
            apiManager={apiManager}
            inBrief={false}
            hideLegend
            triggerExport={exportChart}
            handleClose={handleClose}
            // selectComponent={setSelected}
          />
        ) : (
          <ChartDisplay
            chartDiv={`vizDiv-${index}`}
            chartSettings={{
              type: asset.settings.type,
              category: asset.settings.dataset,
              geo: asset.settings.geo,
              time: asset.settings.time,
              metrics: asset.settings.metrics,
              products: asset.settings.products
            }}
            chartData={null}
            chartAssetId={asset._id}
            appSettings={settings}
            apiManager={apiManager}
            hideLegend
            triggerExport={exportChart}
            handleClose={handleClose}
          />
        )}
      </div>
      {/* Chart Menu */}
      <Menu
        id={`viz-card-menu-${index}`}
        anchorEl={anchorEl}
        // keepMounted
        open={vizMenuOpen}
        onClose={handleClose}
      >
        {/* <MenuItem >Edit Chart</MenuItem> */}
        <MenuItem onClick={createBriefClick}>Create a brief</MenuItem>
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={downloadClick}>Download</MenuItem>
        <MenuItem onClick={deleteClick}>Delete</MenuItem>
        {/* delete does not have endpoint */}
      </Menu>
      <DeleteDialog
        open={deleteOpen}
        onClose={handleClose}
        isChart={true}
        handleClose={handleClose}
        del={handleDelete}
      />
    </div>
  );
}
