import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function Search({ classes, getBriefs, setPage, limit, page, refreshChild }) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleChange = async (event) => {
    const search = event.target.value;
    setSearch(search);
    if(search.length === 0){
        await getBriefs(limit, 0, '');
        refreshChild()
    }
}

const handleBlur = async () =>{
  
}

const handleSearchKeyPress = async(e) => {
    const key = e.key;
    if( key === 'Enter') {
    
      setPage(0); 
      await getBriefs(limit, page, search);
      
    }
}

  return (
    <span>
     <TextField size='small'
                        className={classes.searchform}
                        label="Search"
                        name="search"
                        value={search}
                        onChange={handleChange}
                        onKeyUp={handleSearchKeyPress}
                        onBlur={handleBlur}
                        type=""
                        variant="outlined"
                    />
    </span>
  );
}