import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-containedPrimary': {
        backgroundColor: '#22b9ea !important',
        width: '100%',
    },
    '& .MuiButton-label': {
        color: '#eee !important',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '65%',
    left: '48%',
    //marginTop: -12,
    //marginLeft: -12,
  },
}));

export default function SignInButton({ handleSignIn }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  // const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = (event) => {
    if (!loading) {
      // setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        // setSuccess(true);
        setLoading(false);
      }, 1500);
      handleSignIn(event);
    }
  };

  return (
    <div className={classes.root}>
      
      <Button
                    color="primary"  
                    size="medium"
                    fullWidth
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    onClick={handleButtonClick} 
                  >
                    Sign In
                  </Button> 
 
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
       
    </div>
  );
}