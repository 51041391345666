import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import AssetMenu from './AssetMenu';
import BriefThumbnail from '../../Briefs/components/BriefThumbnail';
import moment from 'moment';


const useStyles = makeStyles({
    root: {
        width: 250,
        //margin: 'auto'
        marginBottom: 8,
        borderRadius: 2,
    },
    media: {
        height: 300,
        maxWidth: 450,
        maxHeight: 350
    },
    content: {
        position: 'relative',
        top: '-300px',
        left: '450px',
        height: '0px'
    }
});

export default function AssetCard(props) {
    const classes = useStyles();
    const { asset, apiManager, isPhotoFilter, refreshData, getData  } = props;
    const { url, contentType, _id, key, created, size, expires, name, tags } = asset;
    const data = props;
    const [reset, setReset] = React.useState(true);
    
    // ********************** SHOW URL? ********************** //
    const showFileURL = false;

    // ********************** handlers ************************ //
    const handleDelete = async (asset) => {
        await apiManager.deleteAsset(asset);
        await getData();
        if(refreshData){
            await refreshData();
        }
    }

    const handleTags = async () => {
        if(refreshData){
            await refreshData();
        }
        setReset(!reset)
    }

    const updateName = async (asset) => {
        await apiManager.updateAsset(asset);
        await getData();
        if(refreshData){
            await refreshData();
        }
        setReset(!reset)
    }

    return (
        <Card className={classes.root}>


            {/* {isPhotoFilter ? renderPhoto(value) : renderVideo(value)} */}
            {isPhotoFilter
                ?
                <div style={{ textAlign: `left` }}>
                     <BriefThumbnail image={url} size="sq350"/>
                    {/* <div style={{ backgroundImage: `url(${url})`, height: '100px', width: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}></div> */}
                </div>
                :
                <div style={{ textAlign: 'center', backgroundColor: 'black' }}>
                    <video src={url} controls style={{ height: `150px` }} >
                        Your browser does not support the video tag.
                    </video>

                </div>
            }
            <CardContent style={{display:'block', padding: 0}}>
                {/* <div style={{
                    height: 44,
                    marginBottom: 8,
                    marginTop: -12,
                    fontSize: 14,
                    fontWeight: 600, 
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>
                    {name ? name : '<unnamed>'}
                </div> */}
                {showFileURL && (
                    <ToolTip title={url}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: 20
                        }}>
                            File: {url}
                        </div>
                    </ToolTip>
                )}
            {/* Tags: {tags ? tags.join(', ') : ''}<br /> */}
            {/* Added on {moment(created).format('MMM DD, YYYY')} */}
            </CardContent>
            <CardActions>
                <div style={{ display: 'inline-block', width: 140, padding: 8 }}>
                    {/* {moment(created).format('MMM D, YYYY')} */}
                    {name ? name : '<unnamed>'}
                </div>
                <div style={{ width: '100%', textAlign: 'right' }}>

                    <div style={{ display: 'inline-flex' }}>
                        <span>
                            <AssetMenu 
                                classes={classes} 
                                asset={asset} 
                                handleDelete={handleDelete} handleTags={handleTags} updateName={updateName} updateTags={updateName} 
                                reset={reset} />
                        </span>
                   </div>
                </div>
            </CardActions>
        </Card>
    );
}