import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import apiManager from './ApiManager';
import { FAKE_ORGANIZATION } from './constants';

import {
  // SignUp as SignUpView,
  SignUpPortal as SignUpPortalView,
  // SignIn as SignInView,
  SignInPortal as SignInPortalView,
  NotFound as NotFoundView,
  Subscriptions as SubscriptionsView,
  Briefs as BriefsView,
  Account as AccountView,
  ResetPassword as ResetView,
  Invites as InvitesView,
  Assets as AssetsView,
  People as PeopleView,
  PublicBriefDetail as PublicBriefView,
  TermsAndConditions as TermsView,
  Impersonate as ImpersonateView,
  Error as ErrorView,
  SharedBriefs as SharedBriefsView,
  Analytics as Analytics,
  Logout as LogoutView,
  Owner as OwnerView,
  OrganizationDetails as OrganizationDetails,
  DataDetails as DataDetails,
  OrgPeopleView as OrgPeopleView,
  OrgTeamsView as OrgTeamsView,
  Visualizations as Visualizations,
  SentimentChart as SentimentChart,
  Success as Success
} from './views';

import {
  BriefDetail as BriefDetailView,
  BriefManage as BriefManagelView,
  EditBrief as EditBriefView
} from './views/Brief'

import {
  ChartBuilder as ChartBuilderView,

} from './views/Visualizations'

import LocalSettings from './services/LocalSettings'
import { async } from 'validate.js';


class Routes extends React.PureComponent {
  constructor(props) {
      super(props);
    const { user } = this.props;

    // const isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    const isMobile = (navigator.userAgent.includes('iPhone')) || (navigator.userAgent.includes('Android')) || (navigator.userAgent.includes('IEMobile'));

    this.state = {
      user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : (user ? user : {}),
      apiManager,
      refresh: false,
      isMobile,
    };

    this.updateUser = this.updateUser.bind(this);
    this.refreshChild = this.refreshChild.bind(this);
    this.settings = this.settings.bind(this);

    // reduce the color blinking on reload
    const rootDoc = document.documentElement;
    rootDoc.style.setProperty('--brand-color1', '#0a1241');
    rootDoc.style.setProperty('--brand-color2', '#EA3397');
  }

  componentDidMount = async () => {
    // set branding for current user
    await this.setCurrentUserBranding();
    
    //ZenDesk Widget based on hostname
    const script = document.createElement("script");
    script.async = true;
    
    // switch to correct zen desk. use 1st option for pma or insite subdomains
    if(window.location.hostname === "pma-stage.breefly.com" || window.location.hostname === "pma.breefly.com" || window.location.hostname.includes('insite')){
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=9d52ac57-a7f3-47fe-ade5-120527745ca9";
    } else {
      script.src="https://static.zdassets.com/ekr/snippet.js?key=e6b640e6-4146-4fd9-9543-eaf9b4759c7d"
    }
    script.id = 'ze-snippet';

    //add snippet to head
    document.head.appendChild(script);

    //get brand colors and apply to widget
    let c = getComputedStyle(document.documentElement).getPropertyValue('--brand-color1')
    console.log(c)
          window.zESettings = {
              color: { 
                theme: c,
                launcherText: '#ffffff', 
              },
              offset: {
                horizontal: '24px',
                vertical: '24px'
              }
          }
        LocalSettings.getAB();
  }
  setCurrentUserBranding = async () => {
    const oldOrgId = await this.settings('get', 'organizationId');
    let oldBranding = await this.settings('get', 'orgBranding');

    if (this.state.user.lastLogin) {
      let userOrgId = this.state.user.orgId;
      if (FAKE_ORGANIZATION) {
        userOrgId = '5ef8e4dc2c27b96d8173db7a'
      }
      if (userOrgId) {
        // user has an organization
        // then see if it's the same as what's already in storage
        if (oldBranding && oldBranding.org && oldBranding.org === userOrgId) {
          // already the right branding
          if( !oldBranding.color1) {
            const brand = await apiManager.getOrganizationBranding(oldBranding.org);
            if( brand ) {
                oldBranding = brand.branding;
            }
          }
          this.settings('set', 'orgBranding', oldBranding);


          // check if branding has changed...
          // await this.readOrgBranding( userOrgId )

        } else {
          // a different organization, read in branding for this user.

          await this.readOrgBranding(userOrgId)
        }
      } else {
        // no userOrgId, use the default branding
        this.settings('set', 'orgBranding', { logo: null, color1: null, color2: null, org: null })
      }
    }

  }

  readOrgBranding = async (orgId) => {
    try {
      const newBranding = await apiManager.getOrganizationBranding(orgId);
      if (newBranding) {
        this.settings('set', 'orgBranding', {
          logo: newBranding.branding.portallogo,
          portalImage: newBranding.branding.portalbg,
          color1: newBranding.branding.primary,
          color2: newBranding.branding.secondary,
          org: orgId,
        });
      } else {
        console.log('try a 2nd time. branding: ', newBranding);
        // try one more time!
        const newBranding2 = await apiManager.getOrganizationBranding(orgId);
        if (newBranding2) {
          this.settings('set', 'orgBranding', {
            logo: newBranding2.branding.portallogo,
            portalImage: newBranding2.branding.portalbg,
            color1: newBranding2.branding.primary,
            color2: newBranding2.branding.secondary,
            org: orgId,
          });
        } else {
          console.log('set to default #1. branding: ', newBranding, newBranding2);
          this.settings('set', 'orgBranding', { logo: null, color1: null, color2: null, org: null })
        }
      }
    } catch (err) {
      console.log("read branding error: ", err);
    }
  }

  componentDidUpdate = async () => {
    const { user } = this.state;
    const { history } = this.props;
    if (!user || !user.userName && history) {
      history.push('/login');
    }
  }

  refreshChild() {
    const { refresh } = this.state;
   // this.setState({ refresh: !refresh })
  }

  updateUser = async (user) => {
    user.initials = `${user.firstname.toUpperCase().charAt(0)}${user.lastname.toUpperCase().charAt(0)}`;
    sessionStorage.setItem('user', JSON.stringify(user));
    this.setState({ user }, async () => {
      // now read in the branding if related to this user.
      // const branding = await this.settings('get', 'orgBranding');
      if (user.orgId) {
        this.settings('set', 'organizationId', user.orgId);
        await this.setCurrentUserBranding();
        return true;
      }
      return true;

    });

   

    // if (FAKE_ORGANIZATION) {
    //   if (!user.roles) {
    //     user.roles = [];
    //   }
    //   if (user && user.roles && user.roles.indexOf("orgOwner") === -1) {
    //     user.roles.push("orgOwner");
    //     user.roles.push("owner");
    //     user.org = "pma";
    //     this.setState({ user }, async () => {
    //       // now read in the branding if related to this user.
    //       // const branding = await this.settings('get', 'orgBranding');
    //       await this.setCurrentUserBranding();
    //     });
    //   }
    //   this.setState(user);
    //   sessionStorage.setItem('user', JSON.stringify(user));
    // }
    return true;
  }



  settings = async (action, setting, value = { value: null }) => {
    if (action === 'get') {
      const response = await LocalSettings.getSetting(setting);
      return response;
    } else if (action === 'set') {
      LocalSettings.setSetting(setting, value);
    }
  }


  render() {
    const { user, apiManager, history, isMobile } = this.state;

    return <Switch>
      {user && user.orgRole ? (
      <Redirect
        exact
        from="/"
        to="/briefs"
        isMobile={isMobile}
      />) : (<Redirect
        exact
        from="/"
        to="/signin"
        isMobile={isMobile}
      />)}
      <RouteWithLayout
        component={BriefsView}
        exact
        layout={MainLayout}
        path="/briefs"
        user={user}
        apiManager={apiManager}
        refreshChild={this.refreshChild}
        history={history}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={BriefDetailView}
        exact
        layout={MainLayout}
        path="/brief/details/*"
        user={user}
        apiManager={apiManager}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={EditBriefView}
        exact
        layout={MainLayout}
        path="/brief/edit/*"
        user={user}
        apiManager={apiManager}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={BriefManagelView}
        exact
        layout={MainLayout}
        path="/brief/manage/*"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
        user={user}
        updateUser={this.updateUser}
        refreshChild={this.refreshChild}
        apiManager={apiManager}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={PeopleView}
        exact
        layout={MainLayout}
        path="/manage-access"
        user={user}
        apiManager={apiManager}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={AssetsView}
        exact
        layout={MainLayout}
        path="/assets"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
      <RouteWithLayout
        component={Analytics}
        exact
        layout={MainLayout}
        path="/analytics"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
      <RouteWithLayout
        component={Visualizations}
        exact
        layout={MainLayout}
        path="/visualizer"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
            <RouteWithLayout
        component={SentimentChart}
        exact
        layout={MainLayout}
        path="/sentiment-chart"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
      <RouteWithLayout
        component={ChartBuilderView}
        exact
        layout={MainLayout}
        path="/chart-builder"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
      <RouteWithLayout
        component={AssetsView}
        exact
        layout={MainLayout}
        path="/assets"
        user={user}
        apiManager={apiManager}
        isMobile={isMobile}
        user={user}
        refreshChild={this.refreshChild}
        settings={this.settings}
      />
      <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/billing"
        user={user}
        apiManager={apiManager}
        updateUser={this.updateUser}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
       <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions"
        user={user}
        apiManager={apiManager}
        updateUser={this.updateUser}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
      <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions/card"
        user={user}
        apiManager={apiManager}
        updateUser={this.updateUser}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
        isCollectPayment
      />
      <Route
        path="/subscriptions/success"
        exact
        render={props => <Success {...props} 
        updateUser={this.updateUser} 
        isMobile={isMobile} 
        settings={this.settings} 
        user={user}/>}
      />
      <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions/expired"
        user={user}
        apiManager={apiManager}
        updateUser={this.updateUser}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
        isCollectPayment
      />
      <RouteWithLayout
        component={SharedBriefsView}
        exact
        layout={MainLayout}
        path="/shared-briefs"
        user={user}
        apiManager={apiManager}
        refreshChild={this.refreshChild}
        isMobile={isMobile}
        settings={this.settings}
      />
      <Route
        exact
        path="/brief/*"
        render={props => <PublicBriefView {...props}
          apiManager={apiManager}
          isMobile={isMobile}
          settings={this.settings}
        />}

      />
      <Route
        exact
        path="/signin"
        render={props => <SignInPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
        />}
      />
      <Route
        exact
        path="/signin/*"
        render={props => <SignInPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
        />}
      />
      <Route
        exact
        path="/signup"
        render={props => <SignUpPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
        />}
      />
      <Route
        exact
        path="/signup/*"
        render={props => <SignUpPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
        />}
      />
      <Route
        exact
        path="/removed"
        render={props => <SignInPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
          isRemoved
        />}
      />
            <Route
        exact
        path="/suspended"
        render={props => <SignInPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
          isRemoved
          isSuspended
        />}
      />
       <Route
        exact
        path="/team-expired"
        render={props => <SignInPortalView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
          apiManager={apiManager}
          isRemoved
          isExpired
        />}
      />
      <Route
        exact
        path="/invite"
        render={props => <InvitesView {...props}
          updateUser={this.updateUser}
          isMobile={isMobile}
          settings={this.settings}
        />}

      />
      <Route
        exact
        path="/impersonate"
        render={props => <ImpersonateView {...props}
          updateUser={this.updateUser}
          apiManager={apiManager}
          isMobile={isMobile}
          settings={this.settings}
        />}

      />
      <Route
        exact
        path="/terms-and-conditions"
        render={props => <TermsView {...props} isMobile={isMobile} settings={this.settings} />}
      />
      <Route
        exact
        path="/privacy"
        render={props => <TermsView {...props} isMobile={isMobile} settings={this.settings} />}
      />
      <Route
        exact
        path="/logout"
        render={props => <LogoutView {...props} updateUser={this.updateUser} isMobile={isMobile} settings={this.settings} />}
      />
      <Route
        exact
        path="/reset"
        render={props => <ResetView {...props} updateUser={this.updateUser} isMobile={isMobile} settings={this.settings} />}
      />
      <Route
        exact
        path="/not-found"
        render={props => <NotFoundView {...props} updateUser={this.updateUser} isMobile={isMobile} settings={this.settings} />}
      />
      <RouteWithLayout
        component={ErrorView}
        exact
        layout={MinimalLayout}
        path="/error"
        isMobile={isMobile}
        settings={this.settings}
      />
      {user && user.orgRole && user.orgRole.indexOf('owner') > -1 && (
        <>
          <RouteWithLayout
            component={OrganizationDetails}
            exact
            layout={MainLayout}
            path="/owner"
            user={user}
            apiManager={apiManager}
            refreshChild={this.refreshChild}
            updateUser={this.updateUser}
            history={history}
            isMobile={isMobile}
            settings={this.settings}
          />
          <RouteWithLayout
            component={DataDetails}
            exact
            layout={MainLayout}
            path="/data"
            user={user}
            apiManager={apiManager}
            refreshChild={this.refreshChild}
            history={history}
            isMobile={isMobile}
            settings={this.settings}
          />
          <RouteWithLayout
            component={OrganizationDetails}
            exact
            layout={MainLayout}
            path="/owner/*"
            user={user}
            apiManager={apiManager}
            refreshChild={this.refreshChild}
            history={history}
            isMobile={isMobile}
            settings={this.settings}
          />
          <RouteWithLayout
            component={OrgPeopleView}
            exact
            layout={MainLayout}
            path="/organizations/details/people/*"
            user={user}
            apiManager={apiManager}
            isMobile={isMobile}
            settings={this.settings}
          />
          <RouteWithLayout
            component={OrgTeamsView}
            exact
            layout={MainLayout}
            path="/organizations/details/teams/*"
            user={user}
            apiManager={apiManager}
            isMobile={isMobile}
            settings={this.settings}
          />
        </>
      )}

      <Redirect to="/not-found" />
    </Switch>
  }
};

Routes.propTypes = {
  user: PropTypes.object,
};

export default Routes;
