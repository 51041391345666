import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {Link} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function RegisterSnackBar({ closeModal, isOpen, message, isSuccessfull}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isSuccessfull ?  <Snackbar open={isOpen}  onClose={closeModal}>
        <Alert onClose={closeModal} severity="success">
          { message } <Link
                    component={RouterLink}
                    to="/signin"
                    variant="h6"
                  >
                    Sign In
                  </Link>
        </Alert>
      </Snackbar>
      :
      <Snackbar open={isOpen} onClose={closeModal}>
        <Alert onClose={closeModal} severity="error">
         { message }
        </Alert>
      </Snackbar>
      }
    </div>
  );
}