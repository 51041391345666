import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'; 
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {   faShare  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid, 
  TextField, 
  Button, 
  Typography, 
} from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Rightbar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });

    onClose();
  };

  let { isOpen, onClose, settings, component, funcs, brief } = props;
  const [openRightbar, setOpenRightbar] = React.useState(false);
  const [share, setShare] = React.useState({ public: false });

  let [content, setContent] = React.useState(false);
 

  if(!content && component && component.content){
    let { content } = component; 
    setContent(content);
    //setTitle(content.title);
    //setText(content.text);
    //setUrl(content.url);
    setOpenRightbar(false);
    setShare({ isPublic: false })

  }

  const addSharingClick = () => {
    const { isPublic } = share;
    share.isPublic = !isPublic;
    setShare(share);
    funcs.addSharing(share)
  }
 
  if(isOpen && !openRightbar){
    toggleDrawer('right', true)
  }

  const renderSharing  = () => {
 
    return (  <Grid    container
      ><Grid  item
      ><Typography fullWidth style={{padding: '20px'}} variant="body1">Sharing settings</Typography> </Grid>
      <List><ListItem button key='sharing' onClick={addSharingClick}>
      <ListItemIcon> <FontAwesomeIcon icon={faShare} color={ brief && brief.shares && brief.shares.length > 0 ? '#24a0ed': '' } /> </ListItemIcon>
      <ListItemText primary='Enable public url' />
    </ListItem></List>
    <Grid   
        ><Grid  item fullWidth
        ><Typography style={{padding: '20px'}} variant="body1">Set a new security token</Typography> <TextField
        className={classes.textField} 
        fullWidth 
        label="Token" 
        name="0" 
        type="text" 
        variant="outlined"  
        required 
        style={{ width: '100%', paddingBottom: '10px'}}
      /><Button 
      color="primary"
      
      size="large"
      type="submit"
      variant="contained"  
    >
      Update
    </Button></Grid>
       </Grid>
    
    <Grid   
    ><Grid  item fullWidth
    ><Typography style={{padding: '20px'}} variant="body1">Public url</Typography>
     <a href='/brief/5e573e2ef2f20800070661f2/5e573e2ef2f20800070661f2'>/brief/5e573e2ef2f20800070661f2/5e573e2ef2f20800070661f2</a> 
    </Grid></Grid></Grid>)
  }




  const renderByType = () => {
    const setting = settings && settings.length > 0 ? settings[0] : {};
    let html = '';
   
      switch(setting.type){
        case 0:  
        html = renderSharing();
        break;
        case 1:  
        break;
        case 2:  
        break;
        case 3:  
        break;
        case 4:  
        break;
        case 5:  
        break;
        default: 
        break;
      } 
    //})
    return (<div
      className={classes.list}
      role="presentation">
       
       { html }
       
     
    </div>);
  }
 
 
  return (
    <div>
         
      
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer('right', false)}>
        { renderByType() }
      </Drawer>
    </div>
  );
}