import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import SubscriptionModal from '../../../components/SubscriptionModal'

export default function CardMenu({ classes, history, brief, deleteBrief, hideEdit, apiManager, access, refreshParent, user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const [canEdit, setCanEdit] = React.useState(true);
  const [editError, setEditError] = React.useState(false);


  useEffect(() => {
    checkForSubscription()
  },[])
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const checkForSubscription = () => {
    if (user && user.subscriptions) {
      const activeSubscription = user.subscriptions.filter(subscription => subscription.active === 1)
         // if they can see the brief, they can edit it.
      // activeSubscription && activeSubscription.length === 0 && setCanEdit(false)
    } else {
        // if they can see the brief, they can edit it.
        setCanEdit(true)
    }
  }
  const clickEdit = () => {
     
    if(access == 'viewer'){
      window.location = `/brief/details/${brief._id}`;
    }
    else {
      if(canEdit){
        window.location = `/brief/edit/${brief._id}`;
      }
      else{
        setEditError("Subscription Expired. Please subscribe to continue editing briefs")
      }
    }
      
    
  }

  const duplicateBrief = async () => {
    const result = await apiManager.duplicateBrief(brief);

    if(result && result.insertedCount > 0){
     
      if(refreshParent){
        await refreshParent(result._id);
      }
      else {
        history.push(`/briefs`)
      }
    }
    else {
      setEditError("Subscription Expired. Please subscribe to continue duplicating briefs")
      setSnackMessage(result && result.msg ? result.msg : 'An error has occurred');
      setSnackBar(true);
    }
    
   }

  const clickManage = () => {
    window.location = `/brief/manage/${brief._id}`;
  }

  const clickDuplicate = () => {
    duplicateBrief(brief);
    handleClose();
  }

  const clickDelete = () => {
    setOpen(true)
  }

  const handleDelete = () => {
    deleteBrief(brief);
    handleClose();
  }
  
  
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  return (
    <span>
     <IconButton aria-label="close"  onClick={handleClick}  style={{fontSize: 14, minWidth: 'unset', padding: 12, marginRight: 4}}>
        <FontAwesomeIcon icon={faEllipsisV} />
    </IconButton>
      <Menu
        id="card-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         { access == 'admin' ? 
        <MenuItem onClick={clickDuplicate}>Duplicate</MenuItem> 
        : null }
        {!hideEdit && (
             <MenuItem onClick={clickEdit}>{ access == 'viewer'  ? 'View' : 'Edit'}</MenuItem> 
        )}
        { access == 'admin' ? 
        <MenuItem onClick={clickDelete}>Delete</MenuItem>
        : null }
      </Menu>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete brief</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete this brief? Once deleted it is gone forever and can not be restored
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
        <Snackbar
                open={showSnackBar}
                autoHideDuration={3000}
                className="error"
                severity="info"
                onClose={()=> setSnackBar(false)}
                variant="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error">{snackMessage}</Alert>
            </Snackbar>
      </Dialog>
      {editError && 
        <SubscriptionModal 
          user={user} 
          open={Boolean(editError)}
          message={editError} 
          onClose={() => setEditError('')}
          modalTitle={"sharing"}
        />
      }
    </span>
  );
}