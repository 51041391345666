import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu'; 
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table'; 
// import { faFont, faShareAlt, faEllipsisV, faHeart, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV, faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TagAsset from './TagAsset';
import {
    Button, 
    TextField, 
  } from '@material-ui/core';


import moment from 'moment'
export default function AssetMenu({ classes, history, asset, handleDelete, handleTags, updateName, updateTags}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openInfo, setInfoOpen] = React.useState(false);
  const [openRename, setRenameOpen] = React.useState(false);
  const [openTags, setOpenTags] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [forceClose, setForceClose] = React.useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagClose = () => {
    handleClose();
  }

  const clickRename = () => { 
    setRenameOpen(true)
   
    setName(asset.name);
  }

  const clickTags = () => { 
    handleTags()
  }

  const clickInfo = () => {
    setInfoOpen(true)
  }

  const clickDelete = () => {
    setOpen(true)
  }

  const deleteClick = () => {
    
    handleDelete(asset);
    handleClose();
  }
  
  const renameClick  = () => {
    asset.name = name;
    updateName(asset) 
    handleClose();
    setOpenTags(false);
  }

  const setTags = (asset) => {
    updateTags(asset);
    handleClose();
    setForceClose(false);
  }
  
  const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setInfoOpen(false)
        setRenameOpen(false)
        setOpenTags(false);
    };

    const openTagModal = () => {
        //setAnchorEl(null);
        setOpenTags(true);  
    }

  return (
    <span>
     <IconButton aria-label="close"  onClick={handleClick} className={'MuiIconButton-root'}  >
        <FontAwesomeIcon size={"xs"} icon={faEllipsisV} />
    </IconButton>
      <Menu
        id="card-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='assetMenu'
      >
        <MenuItem onClick={clickRename}>Rename</MenuItem>
        <MenuItem onClick={openTagModal}>
          Add Tags
        </MenuItem>
        <MenuItem onClick={clickInfo}>File info</MenuItem>
        <a href={asset.url} style={{color: 'inherit', textDecoration: 'none'}}>
        <MenuItem >Download</MenuItem>
        </a>
        <MenuItem onClick={clickDelete}>Delete</MenuItem>
      </Menu>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
             maxWidth={'xs'} className="deleteAssetDialog">
        <DialogTitle id="form-dialog-title">Delete asset</DialogTitle>
        <DialogContent>
          <DialogContentText >
          Are you sure you want to delete this asset? Once deleted it is gone forever and can not be restored
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancel">
            Cancel
          </Button>
          <Button onClick={deleteClick} color="primary" style={{marginRight: 16}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRename} onClose={handleClose} aria-labelledby="form-dialog-title"
             fullWidth={true}
             maxWidth={'xs'}
      >
        <DialogTitle id="form-dialog-title">Rename</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div style={{fontWeight: 400, marginBottom: 16, color: '#333'}}>Custom name this asset</div>
          <TextField 
                    fullWidth 
                    label="Enter Name"
                    name="name"
                    onChange={e => setName(e.target.value)}
                    type="text"
                    value={name || ''}
                  />
          </DialogContentText> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={renameClick} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={openInfo} 
        onClose={handleClose} 
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">File info</DialogTitle>
        <DialogContent>
          <DialogContentText>
                      <Table style={{fontSize: 14, color: '#333'}}>
                          <tr>
                              <td className='td-name'>Name</td><td className='td-info'>{asset.name ? asset.name : '<unnamed>'}</td>
                          </tr>
                          <tr>
                              <td className='td-name'>Asset ID</td><td className='td-info'>{asset._id}</td>
                          </tr>
                          <tr>
                              <td className='td-name'>Type</td><td className='td-info'>{asset.contentType}</td>
                          </tr>
                          <tr>
                              <td className='td-name'>Size</td><td className='td-info'>{asset.size}</td>
                          </tr>
                          <tr>
                              <td className='td-name'>Expires</td><td className='td-info'>{moment(asset.expires).format('MMM DD, YYYY hh:mm')}</td>
                          </tr>
                          <tr>
                              <td className='td-name'>Download</td><td className='td-info'>
                                  {/* <IconButton aria-label="close" className={'MuiIconButton-root'} style={{marginLeft: -28}} > */}
                                  <a href={asset.url} target='image' style={{verticalAlign:'text-top'}}> 
                                      <FontAwesomeIcon size={"md"} icon={faDownload}   />
                                      </a>
                                  {/* </IconButton> */}
                              </td>
                          </tr>
                      </Table>
           {/* <div style={{ width: '500px' }}>
               id: { asset._id }<br />
               name: { asset.name }<br />
               type: { asset.contentType }<br />
               size: { asset.size }<br />
               expires: { moment(asset.expires).format('MMM DD, YYYY hh:mm') }<br /> 
               download: <a href={asset.url} target='image'>click here</a><br />
           </div> */}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button> 
        </DialogActions>
      </Dialog>
      <TagAsset 
            classes={classes} 
            updateTags={setTags} 
            asset={asset} 
            open={openTags} 
            handleTagClose={handleTagClose} 
      />
    </span>
  );
}