import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function RegisterSnackBar({ closeModal, isOpen, message, isSuccessfull, snack, closeTime}) {
  const classes = useStyles();
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeModal()
  }; 

  const renderSuccess = () => {
    return (<Snackbar key='success' open={(snack && snack.isOpen) || isOpen}  onClose={handleClose}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={closeTime || 6000}>
    <Alert onClose={closeModal} severity="success">
      { (snack && snack.message) || message }  
    </Alert>
  </Snackbar>)
  }

  const renderFailure = () => {
    return (<Snackbar key='failure' anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={(snack && snack.isOpen) || isOpen} onClose={handleClose} autoHideDuration={closeTime || 6000}>
    <Alert onClose={closeModal} severity="error">
    { (snack && snack.message) || message }  
    </Alert>
  </Snackbar>)
  } 
  return (
    <div className={classes.root}>
      { ((snack && snack.isSuccessfull) || isSuccessfull) ?  renderSuccess() : renderFailure() }
    </div>
  );
}