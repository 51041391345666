import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { VERSION, STAGE } from "../../constants";
import {
  AccountProfile,
  AccountDetails,
  Password,
  Notifications,
  AccountName
} from "./components";
import RegisterSnackBar from "../SignIn/components/RegisterSnackBar";
import CreateBriefButton from "../../components/CreateBriefButton";
import SubscriptionData from "../../datasets/subscriptionNames.json";
import stripeProducts from "../../constants";
import SubscriptionModal from "../../components/SubscriptionModal";
import eventTracker from "../../utils/event-tracker";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    fontSize: 14
  },
  primaryCTA: {
    position: "absolute",
    top: 30,
    left: 148,
    "@media (max-width: 650px)": {
      position: "unset",
      marginTop: 24
    }
  }
});

const useStyles = makeStyles(styles);

const DEFAULT_SNACK = {
  isOpen: false,
  message: "",
  isSuccessfull: false
};

const Account = props => {
  const { user, updateUser, apiManager, refreshChild } = props;
  const classes = useStyles();
  const [snack, setSnack] = useState(DEFAULT_SNACK);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [init, setInit] = useState(false);

  const [showSubscription, setShowSubscription] = useState(false);
  const [subscription, setSubscription] = React.useState({});
  const [subscriptionName, setSubscriptionName] = React.useState("");

  const closeModal = async () => {
    setSnack({ ...snack, isOpen: false });
    refreshChild();
    setForceRefresh(!forceRefresh);
  };
  const openModal = async (isSuccessfull, message) => {
    setSnack({ ...snack, isOpen: true, isSuccessfull, message });
    refreshChild();
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [showSnackBar, setSnackBar] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [createError, setCreateError] = React.useState("");
  const createBrief = async () => {
    setIsLoading(true);

    const brief = {
      title: "Enter your title...",
      tags: [],
      briefcase: { value: 0 },
      components: [
        {
          type: 0,
          content: {
            text: "Enter your title..."
          },
          position: 0
        }
      ]
    };
    const result = await apiManager.createBrief(brief);
    if (result && result.insertedCount > 0) {
      //history.push(`/brief/edit/${result._id}`);
      eventTracker('Brief Creation', user, 'account')
      window.location = `/brief/edit/${result._id}`;
    } else {
      if (result && result.msg) {
        setCreateError(result.msg);
      } else {
        //   alert(result && result.msg ? result.msg : 'An error has occurred')
        openModal(
          false,
          result && result.msg ? result.msg : "An error has occurred"
        );
      }
    }
    setIsLoading(false);
  };
  const updatePassword = async values => {
    try {
      const user = await apiManager.updatePassword(values);

      if (user && user._id) {
        openModal(true, `Password has been updated!`);

        await updateUser(user);
      } else {
        openModal(false, `Password was NOT updated!`);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const getSubscription = async () => {
    // setIsLoading(true)
    const data = await apiManager.getSubscription();

   

    // need to be backwards compatable
    let hasSubscription = false;
    let cancelled = false;
    let nickname = "";
    if (data && data.session) {
      if (data.active === 0) {
        cancelled = true;
      }
      if (data.session.display_items && data.session.display_items.length > 0) {
        data.session.plan = data.session.display_items[0].plan;
      }
      if (data.session.plan && data.session.plan.nickname) {
        nickname = data.session.plan.nickname;
        hasSubscription = true;
      }
    }
    if (hasSubscription) {
      // if (nickname === '') {
      //     const session = data.session.display_items;
      //     nickname = SubscriptionData.productKeyNames[data.session.display_items[0].plan.id] ? SubscriptionData.productKeyNames[data.session.display_items[0].plan.id] : '';
      // }

      //adjust the # of seats based on quanity of subs
      if (data.session.quantity !== 1) {
        nickname = nickname.replace("5", data.session.quantity * 5);
      }
      if (cancelled) {
        nickname = `${nickname} - CANCELLED`;
      }
      setSubscriptionName(nickname);
      setSubscription(data.session);
    } else {
      setSubscription(null);
    }
    setIsLoading(false);
    setShowSubscription(true);
  };
  // To Do: Depreciate
  // if(!init && !subscription.session){
  //   setInit(true)
  //   getSubscription();
  // }

  useEffect(() => {
    getSubscription();
  }, []);

  const funcs = {
    openModal,
    closeModal,
    updatePassword
  };

  return (
    <div className={classes.root}>
      <div className={classes.primaryCTA}>
        <CreateBriefButton handleClick={createBrief} />
      </div>
      <Grid container spacing={2} style={{ marginTop: 64 }}>
        <Grid item lg={2} md={2} xl={2} xs={12}>
          <AccountProfile
            user={user}
            updateUser={updateUser}
            apiManager={apiManager}
          />
        </Grid>
        <Grid item lg={10} md={10} xl={10} xs={12}>
          <RegisterSnackBar
            snack={snack}
            closeModal={closeModal}
            classes={classes}
          />
          <AccountDetails
            user={user}
            updateUser={updateUser}
            apiManager={apiManager}
            funcs={funcs}
          />
          <br />
          <Password
            user={user}
            updateUser={updateUser}
            apiManager={apiManager}
            funcs={funcs}
          />
          <br />
          {/* SUBSCRIPTION MANAGEMENT HERE */}
          {showSubscription && (
            <>
              <AccountName
                user={user}
                subscription={subscription}
                subscriptionName={subscriptionName}
                updateUser={updateUser}
                apiManager={apiManager}
                funcs={funcs}
              />
              <br />
            </>
          )}
          {createError && (
            <SubscriptionModal
              open={Boolean(createError)}
              message={createError}
              onClose={() => setCreateError("")}
              user={user}
              modalTitle={"briefs"}
            />
          )}
          <div style={{ float: "right", marginTop: 20, color: "#cccccc" }}>
            {VERSION} [{STAGE}]
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Account);
