import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // display: 'flex',
        '& .MuiButton-containedPrimary': {
            backgroundColor: '#22b9ea !important',
            // width: '60%',
            // marginLeft: '20%'
            '@media (max-width: 500px)': {
                // width: '40%',
                // minWidth: 150,
               },
        },
        '& .MuiButton-label': {
            color: '#eee !important',
        },
    },  
  standardInfo: {
    color: '#fff',
    backgroundColor: 'purple'
  },
  icon: {
    color: '#fff',
    fontSize: '50px'
  }
}));

export default function TopBanner({ brief }) {
  const classes = useStyles();

  const clickLogin = () => {
    window.location = `/signin?redirect=/brief/details/${brief}`;
  }

  const clickSignUp = () => {
    window.location = '/signup';
}

  return (
    <div className={classes.root}> 
         <Grid
        container
        spacing={4}
      > 
        <Grid
          item 
          style={{ padding: '40px'}}
        >
            <Button 
                    color="primary"  
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={clickSignUp}
                    className='breefly-btn-primary'
                  >
                    Sign Up
                  </Button> &nbsp;&nbsp;
                  <Button 
                    // color="default"  
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={clickLogin}
                    className='breefly-btn-primary'
                    style={{marginLeft: 18}}
                  >
                   <span style={{color: '#444'}}> Sign In </span>
                  </Button> 
        </Grid>
    </Grid>  
    </div>
  );
}