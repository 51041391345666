import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ToolTip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import TagIcon from '@material-ui/icons/LocalOfferOutlined';

import IconButton from '@material-ui/core/IconButton';
import Tag from '../../../components/Tags';

import { faTag } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './tagbrief.scss'
const BriefTagToolTip = withStyles((theme) => ({
  tooltip:{
    backgroundColor: 'var(--brand-color2) !important'
  },
  arrow:{
   color: 'var(--brand-color2) !important'
  }
}))(ToolTip)
export default function TagBrief(props) {
  const [open, setOpen] = React.useState(false);
  const [tags, setTags] = React.useState('');
  const [tagList, setTagList] = React.useState(null);

  // const text = React.useState(
  //     'Add tags, allowing you to search and categorize your briefs. You may enter multiple tags by separating them with a comma.');

  const { classes, brief, updateTags } = props;

  const handleClickOpen = () => {
    setOpen(true);
    if(brief){
      try {
        setTags(brief.tags.join(', '))
        setTagList(brief.tags);
      }
      catch(ex){
        setTags('')
        setTagList([])
      }
    }
  };

  const handleTagInput = input => {
        // now put the array back together
        const tl = input.split(',');
        const tlclean = tl.filter(tag => tag.trim() !== ''); 
        setTagList(tlclean);
        setTags(tl.join(','));
  }

  const removeTag = tagToDelete => {
        const newTagList = tagList.filter(tag => tag.trim() !== tagToDelete.trim());
        setTagList(newTagList);
        setTags(newTagList.join(','));
  }

  const handleClose = () => {
    setOpen(false);
  };

  const updateBrief = () => {
      let tagsClean = tags.replace(/,,/g, ',').replace(/, ,/g,',').replace(/,,,/g, ',').replace(/,,/g, ',');
      const l = tagsClean.length;

      if( tagsClean.length > 0 && tagsClean[tagsClean.length-1] === ',') {
          tagsClean = tagsClean.slice(0,-1);
      }
    updateTags(tagsClean);
    setOpen(false);
  };

  return (
    <span>
      <IconButton aria-label="close" className={classes.icon} >
              <FontAwesomeIcon size={'xs'} icon={faTag} onClick={handleClickOpen} />
      </IconButton>
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="add-tags" fullWidth maxWidth='xs'>
        <DialogTitle id="form-dialog-title" >
            <span className="add-tags__title">Add Tags</span>
            <BriefTagToolTip title="Adding tags allows you to search and categorize your briefs. Enter multiple tags by separating them with a comma." arrow placement="top-start">
              <Button>
                <FontAwesomeIcon size={'md'} icon={faQuestionCircle} className="add-tags__icon" />
              </Button>
            </BriefTagToolTip>
            <div className="add-tags__subhead">Separate tags with a comma</div>
        </DialogTitle>
        <DialogContent>
          <TextField 
            className="add-tags__input" 
            id="tags"
            label="Enter Tag"
            type="text"
            fullWidth
            value={tags}
            multiline
            onChange={e => handleTagInput(e.target.value)}
          /><br /><br /> 
        {tagList && tagList.length > 0 && (
            <div className="add-tags__tag-display">
                <div style={{fontWeight: 600, marginBottom: 12}}>Tags for this asset:</div>
                {tagList.map(tagName => (
                    <div style={{display: 'inline-block'}}>
                    <Tag tagName={tagName} removeTag={removeTag} className="add-tags__chip"/>
                    </div>
                ))} 
            </div>
        )}
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            <span className="add-tags__cancel">Cancel</span>
          </Button>
          <Button onClick={updateBrief} color="primary" className="add-tags__update">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
