import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h6">
              We're sorry, the page you are looking for doesn't exist.
            </Typography>
            <br /><br />
            <Typography variant="subtitle2">
              You might have bookmarked or mistyped the URL.  
            </Typography> 
            <div style={{marginTop: 48}}>
            <Button
                size={'md'}
                variant='contained'
                color='primary'
                href='/'
                >Breefly Home Page</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
