import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import IconButton from '@material-ui/core/IconButton';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function DeleteAll(props) {
  

 // const [user, updateUser] = React.useState(false);

 //  const [password, handleChange] = React.useState(false);
  const { classes, asset, handleDelete, 
    //apiManager 
        } = props;

 // const [text, handleText] = React.useState('Are you sure you want to delete this team? Once deleted it is gone forever and can not be restored');
  const text = 'Are you sure you want to delete this team? Once deleted it is gone forever and can not be restored';

  // const data = props;
 

  const [open, setOpen] = React.useState(false);

  const deleteAsset = () => {
    setOpen(false)
    asset.id = asset._id;
    handleDelete(asset);
  }

  const handleClose = () => { 
    setOpen(false)
  };

 
  const openModal = () => {
    setOpen(true)
  }
 

  return (<span> 
      <IconButton aria-label="close" className={classes.icon} onClick={openModal}>
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteAsset} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
