import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';  
import moment from 'moment';
import EditBriefCase from '../../Modals/EditBriefCase';
import {

  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
   //maxWidth: 345,
  },
  media: {
    height: 140,
  },
  bDate: {
    margin: 16,
  }
});

export default function BriefCaseCard(props) {
  const classes = useStyles();
  const { history } = props;
  const data = props;

  const brief = props.brief;

  const clickEdit = () => {
    history.push(`/brief/edit/${brief._id}`)
  }

  const detailsClick = () => {
    data.detailsClick();
  }

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={detailsClick}
      >
        <CardMedia
          className={classes.media}
          image={ brief.image }
          title={ brief.title }
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          { brief.name }
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            { brief.title }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
      className={classes.bDate} >
        { moment(brief.created).format('MM/DD/YYYY') }

  <EditBriefCase classes={classes} brief={brief} />
      </CardActions>
     
    </Card>
  );
}