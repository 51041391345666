import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationModal(props) {
  const { closeConfirmationModal, confirmationModal } = props;
  return (
      <Dialog open={confirmationModal.isOpen} onClose={() => closeConfirmationModal(true)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{confirmationModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmationModal.message}          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeConfirmationModal(true)} color="primary">
            <span style={{fontWeight: 300}}>Continue</span>
          </Button>
        </DialogActions>
      </Dialog>
  );
}
