import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '@material-ui/core' 
import IconButton from '@material-ui/core/IconButton';
 
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const schema = {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 32
      }
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64
      }
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128
      }
    },
    policy: {
      presence: { allowEmpty: false, message: 'is required' },
      checked: true
    }
  };

export default function EditUser(props) {
  
 
  const { classes, asset, handleUpdate, apiManager } = props;

 

  const [open, setOpen] = React.useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isSuccessfull: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.id]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.id]: true
      }
    }));
  };

  const updateAction = async () => { 
    await handleUpdate(formState.values);
    setOpen(false)
  }
 

  const handleClose = () => { 
    setOpen(false)
  };

 
  const openModal = () => {
    setOpen(true)
  
    const  formState = {
            values: {
                 
            }
        }
   
    setFormState(formState)
  }
 

  return (<span> 
      <IconButton aria-label="close" className={classes.icon} onClick={openModal}>
        <FontAwesomeIcon icon={faPlus} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Link Breefly user</DialogTitle>
        <DialogContent style={{width: '600px'}}>
          <DialogContentText>
              <div>*NOTE if email matches existing user that account will be used and info below will NOT be updated.</div>
          <span><TextField  
            id="firstname"
            label="First name"
            type="text"
            style={{ width: '49%'}}
            onChange={handleChange} 
            value={formState.values.firstname || ''}
            variant="outlined"  
          /> </span>
                  <span>   <TextField  
            id="lastname"
            label="Last name"
            type="text"
            style={{ width: '49%'}}
            onChange={handleChange} 
            value={formState.values.lastname || ''}
            variant="outlined"  
          /></span><br /> <br />   <span>
          <TextField  
            id="email"
            label="Email"
            type="text"
            style={{ width: '49%'}}
            onChange={handleChange} 
            value={formState.values.email || ''} 
            variant="outlined" 
          /></span>&nbsp;
          <span>
          <TextField  
  id="password"
  label="Password"
  type="text"
  style={{ width: '49%'}} 
  onChange={handleChange} 
  value={formState.values.password || ''}
  variant="outlined"  
/>  </span><br /> <br />
<span><TextField  
  id="account"
  label="Company name"
  type="text"
  onChange={handleChange} 
  value={formState.values.account || ''}
  style={{ width: '49%'}}
  variant="outlined"  
/></span>&nbsp;
          <span><TextField  
  id="phone"
  label="Phone"
  type="text"
  onChange={handleChange} 
  value={formState.values.phone || ''}
  style={{ width: '49%'}}
  variant="outlined"  
/></span><br /><br />  
         
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateAction} color="primary">
            Link Account
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
