import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'; 
import { makeStyles } from '@material-ui/styles'; 

import { API_LOCAL } from '../../constants'; 
import { USE_LOCAL } from '../../constants';  
 
import axios from 'axios';

makeStyles(theme => {
  console.log(theme)
});

const useStyles = makeStyles(theme => ({
  root: { 
    height: '100vh',
    minHeight: 900,
    '& .MuiLink-root': {
        color: '#22b9ea',
    },
  },
  
}));

const Logout = props => {
  const { history, updateUser } = props; 

  const classes = useStyles();

  const token = sessionStorage.getItem('token');
  const [init, setInit] = useState(false);
  const validateToken = async() => {
   
    const url = (API_LOCAL) + 'users/auth/logout';
    try {
    const result = await axios.post(url, { token })
    }
    catch(ex){

    }
    // window.intercomSettings = {
    //   app_id: "zcl62xdv"
    // };
    const orgId = sessionStorage.getItem('organizationId');
    const orgBranding = sessionStorage.getItem('orgBranding');
    sessionStorage.clear();
    if( orgId ) { sessionStorage.setItem('organizationId', orgId); }
    if( orgBranding) { sessionStorage.setItem('orgBranding', orgBranding); }
    window.location = '/signin';
  }
  
  if(!init && token){
    setInit(true)
    validateToken(); 
  }
  else {
    // window.intercomSettings = {
    //   app_id: "zcl62xdv"
    // };
    const orgId = sessionStorage.getItem('organizationId');
    const orgBranding = sessionStorage.getItem('orgBranding');
    sessionStorage.clear();
    if( orgId ) { sessionStorage.setItem('organizationId', orgId); }
    if( orgBranding) { sessionStorage.setItem('orgBranding', orgBranding); }
    window.location = '/signin';
  }

  const clickSignUp = () => {
    window.location = '/signin';
  }
  return (null)
}
  
  Logout.propTypes = {
    history: PropTypes.object,
    updateUser: PropTypes.func
  };
  
  export default withRouter(Logout);
  