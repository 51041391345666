import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  TextField,
  Divider,
  Button,
  Grid,
  LinearProgress
} from '@material-ui/core';
import axios from 'axios';
import { API_LOCAL } from '../../../../constants'; 
const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: { 
    margin: 'auto',
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  avatarcolor1: {
    backgroundColor: '#25C2F4',
  },
  avatarcolor2: {
    backgroundColor: '#0052DD',
  }
}));

const AccountProfile = props => {
  const { user, updateUser, apiManager, className, ...rest } = props;

  const data = props;

  const classes = useStyles();

  const [avatar, setAvatar] = useState('');
  const [username, setUsername] = useState('');

  const [error, setError] = useState();

  const initials = user && user.firstname ? `${user.firstname.toUpperCase().charAt(0)}${user.lastname.toUpperCase().charAt(0)}` : user.email.substring(0, 2);
  

  const handleChange = event => {
     setAvatar(event.target.value);
  };

  const updateAvatar = async() => {
     
    
    try {
      const result = await apiManager.updateAvatar({ avatar })
      
      if(result && result.data && result.data._id){
        setError("Avatar has been updated!")

        data.updateUser(result.data);
      }
      else {
        setError("Something went wrong, did you make a change?")
      }
    }
    catch(ex){
      console.log(ex);
    }
  }

  if(!avatar){
    if(user && user.avatar){
      setAvatar(user.avatar) 
    }
    else {
      setAvatar('https://via.placeholder.com/150')
    } 
      setUsername(user.username)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
       
        <Grid
            container
            spacing={3}
          >
             <Grid
              item
              md={12}
              xs={12}
            >
       {error }
      </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
        <Avatar
          alt="Person"
          className={`${classes.avatar} ${classes.avatarcolor1} brandColor1`} 
         // src={'https://via.placeholder.com/350x150'}
         >
        {initials}
        </Avatar>
      </Grid>
      <Grid
              item
              md={12}
              xs={12}
            >
           
       </Grid>
       
       </Grid>
       </div>
      </CardContent>
      <Divider />
     
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
  updateUser: PropTypes.func
};

export default (AccountProfile);
