import React, { useState } from 'react';
import axios from 'axios';
import LinearIndeterminate from '../../Briefs/components/LinearIndeterminate'

// import { makeStyles } from '@material-ui/core/styles';

import {
  TextField,
} from '@material-ui/core';

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     height: 140,
//     width: 100,
//   },
//   control: {
//     padding: theme.spacing(2),
//   },
//   isSelected: {
//       border: '1px solid #ccc',
//       borderRadius: '50%'
//   }
// }));



export default function Image({ component, renderIcons, classes, funcs, selectComponent }) { 
    const [init, setInit] = React.useState(false);
    const [isImageValid, setIsImageValid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false); 

    let isMobile = false;
    const { url, alt, assetId, size, align, isUploading } = component.content;
    let wstyle = { width: `${size}px`, maxWidth: 800 };
    if (isMobile) {
        wstyle = { maxWidth: 280 }
    }

    const renderImageTag = () => {
        return (
            // <a style={{textAlign: `${align}`}}  onClick={e => funcs.changeImage(component, e)}> 
            // <img src={url} alt={alt} className={classes.img} width={`${size}`} /></a>

            <div tabIndex={`${component.content.assetId}`} style={{ position: 'relative' }} className={`editSelect`} onFocus={e => selectComponent(component)} onBlur={selectComponent}>
            {/* <a style={{ textAlign: `${align}` }} onClick={e => selectComponent(component, e)}> */}
                <img id={component.content.assetId} src={url} alt={alt} className={classes.img} width={`${size}`} />
            </div>
        )
    }

    const checkImage = async() => {
        try {
          
            let result = await axios.get(url);
            
            if(result.isAxiosError && result.message != 'Network Error'){
                
            }
            else {
                setIsImageValid(true)
            }
        }
        catch(ex){
            console.log(ex);
            setIsImageValid(false)
        }
    }

    if(!init){
        setInit(true);
        //checkImage();
    }

  return (
      <div style={{ position: 'relative' }}>

          <div style={{ textAlign: `${align}`, padding: '20px 0px' }} >
              {!isImageValid ? <div>Please wait, loading image...</div> : renderImageTag(url, wstyle)}
          </div>


          {/* <div tabindex={`${component.content.assetId}`} style={{ position: 'relative' }} onFocus={e => selectComponent(component)} onBlur={selectComponent}>
              <div style={{ position: 'absolute', height: `300px`, width: '100%', background: 'transparent', zIndex: 1, top: '-322px' }}></div>
          </div>
      */}
    </div>
  );
}
