import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { 
  Link, 
} from '@material-ui/core'; 

import axios from 'axios'
import apiManager from '../../../ApiManager';
import { API_LOCAL } from '../../../constants'; 
import LoadingIndicator from '../../../components/LoadingIndicator'
 

export default function ForgotPassword(props) {
  const [open, setOpen] = React.useState(false);
  const [email, handleChange] = React.useState(false);
  const [error, handleError] = React.useState(false);
  const [isSuccess, handleIsSuccess] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [text, handleText] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const data = props;

  const handleClickOpen = () => {
    setOpen(true);
    handleError('')
    setStatus('')
    handleText('Enter the email address you use to sign in to reset your password.')
    handleIsSuccess(false)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (event) => { 
    event.preventDefault();
    setIsLoading(true);
    const headers = apiManager.getHeaders();
    const url = (API_LOCAL) + '/users/auth/password/forgot';
    handleError('')
    handleIsSuccess(false)
    try {
      const result = await axios.post(url, { email }, { headers });
      if(result && result.data && result.data.success == true){
        handleText('')
        setStatus(result.data.msg) 
        handleIsSuccess(true)
        setIsLoading(false)
      }
      else {
        handleError(result.data.msg)
        setIsLoading(false)

      }
    }
    catch(ex){
      console.log(ex); 
      handleError(ex.toString())
      setIsLoading(false)
    }

  };

 const renderRequestButton = () => {
   return (
   <DialogActions> 
    <Button onClick={handleClose} color="primary">
        <span style={{fontWeight: 300}}>Cancel</span>
      </Button>
      <Button onClick={handleUpdate} color="primary">
        Reset My Password
      </Button>
    </DialogActions>)
 }

 const renderSuccessButton = () => {
  return (<DialogActions> 
    <Button onClick={handleClose} color="primary">
        Close
      </Button> 
    </DialogActions>)
 }

 const renderTextField = () => {
   return (
   <TextField 
    margin="dense"
    id="email"
    label="Enter your email"
    type="text"
    fullWidth
    onChange={e => handleChange(e.target.value)}
  /> )
 }

  return (
    <div>
      <Link href="#" onClick={handleClickOpen}>
        <span style={{fontSize: 12}}>Forgot password?</span>
      </Link>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth>
      {isLoading ? (
        <div>
        <DialogContent>
            <LoadingIndicator />
        </DialogContent>
        </div>
          ) : (
        <div style={{padding: 30}}>
          <DialogTitle id="form-dialog-title">
            <span style={{fontWeight: 700}}>Password Reset</span>
          </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{fontSize: 16}}>{text}</div> <span style={{color: 'red'}}>{error}</span> <div style={{color: '#444', width: '650px'}}>{status}</div> 
          </DialogContentText>
          { isSuccess ? null : renderTextField() }
        </DialogContent>
        <>
        { isSuccess ? renderSuccessButton() : renderRequestButton() }
        </>
        </div>
          )}
      </Dialog>
    </div>
  );
}
