import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },

}));

export default function PaginationRounded(props) {
  const classes = useStyles();

  const [limit, setLimit] = React.useState('');

  const { updateLimit, isMobile } = props;

  const handleChange = event => {
    setLimit(event.target.value);
   
    updateLimit(event.target.value);
  };

  const { totalItems, count, page, updatePage } = props;

  if(limit === ''){
      setLimit(props.limit)
  }

  const pagestyles = isMobile ? {width: '90%' ,margin: 'auto', paddingTop: 0,marginTop: -24, marginBottom: 18} : { display:'inline-flex', margin: 6};
  return (
    <span className={classes.root}>
     
          {!isMobile && totalItems > 4 &&  (
              <>
                  <Select
                      labelId="demo-simple-select-label"
                      id="page-limit-select"
                      value={limit}
                      onChange={handleChange}
                      style={{ marginLeft: 18, marginRight: 12, width: 50 }}
                  >
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                      <MenuItem value={48}>48</MenuItem>
                  </Select>
                 per page
              </>
          )}
        { (Math.ceil(totalItems) > limit) && (
       <span>
          <Pagination count={Math.ceil(count)} shape="rounded" page={page ? page + 1 : 1} onChange={updatePage} style={pagestyles} /> 
        </span>
        )}

    </span>
  );
}