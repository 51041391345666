import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { faTrash, faArrowsAlt, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CancelSubscription(props) {
  

  const [user, updateUser] = React.useState(false);

  const [password, handleChange] = React.useState(false);
  const { classes, updateComponent, funcs, modal } = props;

  const [open, setOpen] = React.useState(modal.isOpen);
  const [text, handleText] = React.useState('Are you sure you want to cancel your subscription?');

  const data = props;
  

  return (
    <span>
 
      <Dialog open={modal.isOpen} onClose={funcs.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancel subscription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={funcs.handleClose} color="primary">
          <span style={{fontWeight: 300}}>Close</span>
          </Button>
          <Button onClick={funcs.cancelStripeSubscription} color="primary">
            Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
