import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  palette: {
    primary: {
      contrastText: '#fff'
    }
  }
}));

export default function OutlinedTag(props) {
  const classes = useStyles();

  const handleDelete = () => {
    props.removeTag(props.tagName);
  };

  const handleClick = () => {
    // console.info('You clicked the tag.');
  };

  return (
    <div className={classes.root}>
      <Chip label={props.tagName} onDelete={handleDelete}  variant="outlined" size="small" style={{color: `${props.color || ''}`}} />
    </div>
  );
}