import React, { useState, useEffect, Component, PropTypes } from 'react';
import { EditorState, 
    convertToRaw, 
    ContentState, 
    Modifier,
    convertFromHTML, convertFromRaw  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs'; 
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import DeleteComponent from './DeleteComponent';

class DeleteParagraphOption extends Component {
    // static propTypes = {
    //   onChange: PropTypes.func,
    //   editorState: PropTypes.object,
    // };
  
    // addStar: Function = (): void => {
    //   const { editorState, onChange } = this.props;
    //   const contentState = Modifier.replaceText(
    //     editorState.getCurrentContent(),
    //     editorState.getSelection(),
    //     '⭐',
    //     editorState.getCurrentInlineStyle(),
    //   );
    //   onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    // };
  
    onDeleteOpen = () => {
        this.props.onDeleteOpen();
    }

    onDeleteClosed = () => {
        this.props.onDeleteClosed();
    }

    render() {
      return (
          <>
          <DeleteComponent updateComponent={e => this.props.updateComponent(this.props.values, true, false, this.props.component)}
            onDeleteOpen={this.onDeleteOpen}
            onDeleteClosed={this.onDeleteClosed}
          />
        </>
      );
    }
  }
  


const toolbar = {
  options: ['inline', 'fontFamily', 'fontSize',  'list', 'textAlign', 
          'colorPicker', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
    bold: {   className: undefined },
    italic: {   className: undefined },
    underline: {   className: undefined }, 
  },
  blockType: {
    inDropdown: true,
    options: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Normal'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: { 
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: {   className: undefined },
    ordered: {  className: undefined },
    indent: {   className: undefined },
    outdent: {   className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: {   className: undefined },
    center: {  className: undefined },
    right: {  className: undefined },
    justify: {   className: undefined },
  },
  colorPicker: { 
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgb(227,119,199)', 'rgb(243,186,228)'],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    link: { className: undefined },
    unlink: { className: undefined },
    linkCallback: undefined
  },
  remove: {  className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { className: undefined },
    redo: { className: undefined },
  }
}


class EditorConvertToHTML extends Component {

    constructor(props) {
        super();
        const { onBlur, component, updateComponent, values, toolbarHidden } = props;
        const { text, editorState, variant, bold, italic, size, quote, visible } = component.content;

        this.state = {
            html: text,
            editorState: EditorState.createEmpty(),
            contentState: false,
            init: false,
            deleteOpen: false,
        }
        this.onChange = (editorState) => this.setState({editorState});
    }

    componentDidMount = () => {
        if (!this.props.component.content.editorState) {
            let state = stateFromHTML(this.state.html);
            console.log('grabbing the state')
 
            this.setState({ editorState: EditorState.createWithContent(state) });
        } else {
            const oldEditState = convertFromRaw(this.props.component.content.editorState);

            this.setState({ editorState: EditorState.createWithContent(oldEditState) });
        }
    }

  componentWillUnmount = () => {
    
  }


  onEditorStateChange = (editorState) =>  {
    console.log(`editor state change: `)
    console.log(this.state.editorState);
    console.log(this.state.contentState);

    this.setState({editorState});
  };

  onContentStateChange = (contentState) => { 
    this.setState({contentState});
  };

  

 // const blocksFromHTML = convertFromHTML(`<p><span class="left" style='color: red; !important'><i>Hey</i> this <strong>editor</strong> rocks 😀</span></p>`);
  
  onDeleteOpen = () => {
      this.setState({deleteOpen: true});
  }

  onDeleteClosed = () => {
    this.setState({deleteOpen: false});
  }

  saveContent = () => {

      if (!this.state.deleteOpen) {
       // const es = editorState.getCurrentContent(state);
        const es = this.state.editorState.getCurrentContent();
        console.log(es);
        console.log(convertToRaw(es));
        const html = draftToHtml(convertToRaw(es));
  
        let output = [];
      
        const { values, component } = this.props;
        values[this.props.component.position] = {text: html, editorState: convertToRaw(es)};
        this.props.onBlur(values, false, false, component)
      } else {

      }
  }

  handlePastedText = (text, html) => {
    // if they try to paste something they shouldn't let's handle it
    if (text.indexOf('Hour rhapsody utter savored') != -1) {

    //   // we'll add a message for the offending user to the editor state
    //   const newContent = Modifier.insertText(
    //     this.state.editorState.getCurrentContent(),
    //     this.state.editorState.getSelection(),
    //     'nice try, chump!'
    //   );

    //   // update our state with the new editor content
    //   this.onChange(EditorState.push(
    //     this.state.editorState,
    //     newContent,
    //     'insert-characters'
    //   ));

    // example of converting html
    // handlePastedText(text, html) {
    //     const { editorState } = state;
    //     const blocksFromHTML = convertFromHTML(handleMicrosoftNewlines(html));
    //     const newState = ContentState.createFromBlockArray(
    //         blocksFromHTML.contentBlocks,
    //         blocksFromHTML.entityMap,
    //     );
    //     const finalState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), newState.getBlockMap());
    //     onChange(EditorState.push(editorState, finalState, 'insert-fragment'));
    //     return 'handled';
    // }
 

    // just convert to plain text and store.
    const {editorState} = this.state;
    const blockMap = ContentState.createFromText(text.trim()).blockMap;
    const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap);
    this.onChange(EditorState.push(editorState, newState, 'insert-fragment'));
    return true;
    } else {
      return false;
    }
  }


    render = () => {
        return (
            <div >
                <Editor
                    draggable="false"
                    editorState={this.state.editorState}
                    wrapperClassName="brief-text-wrapper"
                    editorClassName="brief-text-editor"
                    handlePastedText={this.handlePastedText}
                    onEditorStateChange={this.onEditorStateChange}
                    onBlur={this.saveContent}
                    toolbar={toolbar}

                   toolbarHidden={this.props.toolbarHidden}
                    // toolbarCustomButtons={[<DeleteParagraphOption
                    //     updateComponent={this.props.updateComponent}
                    //     component={this.props.component}
                    //     values={this.props.values}
                    //     onDeleteOpen={this.onDeleteOpen}
                    //     onDeleteClosed={this.onDeleteClosed}
                    // />]}
                />
            </div>
        );
    }
}
  export default EditorConvertToHTML