import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { faEllipsisV, faEnvelope, fa } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: false,
    label: "First Name"
  },
  { id: "lastname", numeric: false, disablePadding: false, label: "Last Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  { id: "status", numeric: true, disablePadding: true, label: "" }
  // { id: 'member-status', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const {
    classes,
   // onSelectAllClick,
    order,
    orderBy,
   // numSelected,
   // rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`member-table-cell member-table-head med-width ${headCell.id === 'status' ? 'last' : ""}`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     // paddingLeft: theme.spacing(2),
//     // paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === "light"
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85)
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark
//         },
//   title: {
//     flex: "1 1 100%"
//   }
// }));

const EnhancedTableToolbar = props => {
  return null;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    // minWidth: 750,
  },
  menu: {
    "&.MuiMenuItem-root": {
      fontSize: 14
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function MembersList({
  apiManager,
  org,
  updateMember,
  affiliates,
  searchTerm,
  refreshData
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(false);
//  const [dense, setDense] = React.useState(true);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextUser, setContextUser] = React.useState(false);
  const [oldSearchTeam, setOldSearchTerm] = React.useState(searchTerm);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  if (affiliates && headCells[3].id === "member-Role") {
    headCells.splice(3, 1);
  }

  
  const capitalization = i => {
    if(typeof i === 'string') {
    return i.charAt(0).toUpperCase() + i.slice(1)
    } else {
      return i;
    }
  }
  const getData = async () => {
    const members = await apiManager.getMembers({
      _id: org._id,
      search: affiliates ? "affiliate" : "members"
    });
    if (members) {
      const rowData = members.map(i => ({
          user_id: i.user._id,
          type: i.user.type,
          org_id: i.org._id,
          lastLogin: i.user.lastLogin,
          firstname: capitalization(i.user.firstname),
          lastname: capitalization(i.user.lastname),
          email: i.user.email,
          status: ((typeof i.user.lastLogin === 'number' || i.user.lastLogin === undefined) ? i.user.lastLogin : 0 ),
      }));

      setRows(rowData);
    } else {
      setRows([]);
    }
  };

  // Initial Data and Refresh - i.e. componentDidUpdate
  useEffect(() => {
    getData();
  }, [refreshData]);

  const addUser = async user => {
    await apiManager.createMember({ org, user, type: "member" });
    await getData();
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openContextMenu = (event, user, index) => {
    setContextUser(user);
    setAnchorEl(event.currentTarget);
  };

  const onUpdateMember = action => {
    setAnchorEl(false);
    updateMember(contextUser, action, affiliates);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const typeList = {
    member: "Member",
    owner: "Owner",
    "co-owner": "Admin"
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (!rows) {
    return null;
  }

  if (page > 0 && searchTerm !== "" && oldSearchTeam !== searchTerm) {
    setOldSearchTerm(searchTerm);
    setPage(0);
  }

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar numSelected={selected.length} addUser={addUser} />
      <TableContainer style={{ overflowX: "hidden", height: 300 }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .filter(row => {
                if (searchTerm) {
                  const name = `${row.firstname} ${row.lastname}`;
                  if (name.toLowerCase().includes(searchTerm) || row.email.toLowerCase().includes(searchTerm)) return 1;
                  return 0;
                }
                return 1;
              })
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.firstname)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.user_id}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell> */}
                    <TableCell
                      align="left"
                      id={labelId}
                      scope="row"
                      className={`member-table-cell small-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.firstname}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`member-table-cell small-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.lastname}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`member-table-cell  med-width padding ${
                        row.status === 0 ? "inactiveUser" : ""
                      }`}
                    >
                      {row.email}
                    </TableCell>
                    {!affiliates && (
                        <TableCell align="left" className={`member-table-cell med-width padding ${row.status === 0 ? "inactiveUser" : ""}`}>
                        {affiliates ? 'subscriber' : typeList[row.type]}
                      </TableCell>
                      )}

                    {/* <TableCell align="left" className={'member-table-cell small-width'}>
                              {affiliates ?
                                  <>
                                      {row.user.lastLogin === 'never' || row.type === 'affiliate' ? 'Pending' : 'Active'}
                                  </>
                                  :
                        <>
                        {/* {row.user.lastLogin === 'never' ? 'Pending' : 'Active'} *
                        </>
                        }
                      </TableCell> */}
                    <TableCell
                      align="right"
                      className={`member-table-cell lastpadding ${
                        row.status === 0 ? "remindUser" : ""
                      }`}
                    >
                      <IconButton
                        size={"small"}
                        onClick={e => openContextMenu(e, row, index)}
                        style={{ fontSize: 16 }}
                      >
                        <FontAwesomeIcon
                          size={row.status === 0 ? "sm" : "lg"}
                          icon={
                            row.status === 0 ? faEnvelope : faEllipsisV
                          }
                        />
                      </IconButton>
                     
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
                        id="context-menu"
                        elevation={1}
                        anchorEl={anchorEl}
                        // keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(false)}
                      >
                        {contextUser.lastLogin === "never" && (
                          <>
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Editing")}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Resending")}
                            >
                              Resend Invite
                            </MenuItem>
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Revoking")}
                            >
                              Revoke Invitation
                            </MenuItem>
                          </>
                        )}
                        {contextUser.lastLogin !== "never" && !affiliates && (
                          <>
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Editing")}
                            >
                              Edit
                            </MenuItem>
                          {contextUser.type !== 'owner' && (
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Removing")}
                            >
                              Remove
                            </MenuItem>
                            )}
                          </>
                        )}
                        {contextUser.lastLogin !== "never" && affiliates && (
                          <>
                            <MenuItem
                              className={classes.menu}
                              onClick={() => onUpdateMember("Revoking")}
                            >
                              Revoke Access
                            </MenuItem>
                          </>
                        )}
                      </Menu>
      {/* Remove Pagination */}
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
    </div>
  );
}
