import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function EnterpriseModal(props) {
   
  const {  funcs, modal, apiManager } = props;

 
 // const [text, handleText] = React.useState('Thanks for contacting our customer success team. One of our business specialists will be sure to followup in the next 24 hours to answer any questions you have regarding an enterprise plan.');
  const text = 'Thanks for contacting our customer success team. One of our business specialists will be sure to followup in the next 24 hours to answer any questions you have regarding an enterprise plan.';

    const handleClose = async (e) => {
        funcs.handleClose(e);
        await apiManager.sendEnterpriseEmail();
        
    }
  

  return (
    <span>
 
      <Dialog open={modal.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">We're on it</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Close</span>
          </Button> 
        </DialogActions>
      </Dialog>
    </span>
  );
}
