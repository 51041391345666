import React from 'react';
import { Grid, Button, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Menu, } from '@material-ui/core';
export default function VizCard(props) {
    const{
        open,
        onClose,
        isChart,
        handleClose,
        del
    } = props;
    return(
        // delete dialog
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="viz-dialog-title"
        fullWidth={true}
        maxWidth={"xs"}
        className="deleteVisDialog"
      >
        <DialogTitle id="viz-dialog-title">
          Delete {isChart ? "Chart" : "Vizualization Card"}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this {isChart ? "chart" : "vizualization"}? This cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancel">
            Cancel
          </Button>
          <Button onClick={del} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
}