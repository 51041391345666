import React, { useState, useEffect } from 'react';
import apiManager from '../ApiManager';


const getBrowser = () => {
    const isMobile = (navigator.userAgent.includes('iPhone')) || (navigator.userAgent.includes('Android')) || (navigator.userAgent.includes('IEMobile'));
    let browser = ''
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) 
    {
        browser = 'Opera';
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 )
    {
        browser = 'Chrome';
    }
    else if(navigator.userAgent.indexOf("Safari") != -1)
    {
        browser = 'Safari';
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
    {
        browser = 'Firefox';
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
        browser = 'Internet Explorer'; 
    }  
    else 
    {
       browser = 'unknown';
    }
    return ({browser: browser, isMobile: isMobile})
}

function getLocation(position) {
    const {latitude, longitude} = position.coords
    console.log(latitude, longitude)
}
  /* eslint-enable */
export default async function eventTracker( trigger, user, page = 'unknown', description = 'no description', value = 0 ){
  const browser = getBrowser();
  //const location = navigator.geolocation.getCurrentPosition(getLocation)

  // isolate the domain
  const urlParts = document.URL.split('/');
  const domainParts = urlParts[2].split('.');
  if (domainParts[0].includes('local')) {
    domainParts[0] = 'local';
  }

    const eventLogData = {
        page,
        trigger,
        user,
        browser: browser.browser,
        isMobile: browser.isMobile,
        description,
        value,
        sourceDomain: domainParts[0],
    }
    const moreData = await apiManager.logEvent(eventLogData)

    // note, for GA page is used for the category.

    
     if(!domainParts[0].includes('local')) {
        // eslint-disable-next-line no-undef
        ga('send', 'event', `${domainParts[0]}-${page}`, trigger, description, value );
     }
}