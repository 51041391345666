import React from 'react';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'; 
import { withStyles } from '@material-ui/core/styles';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
// import image from '../SignIn/logo.png';
// import logoImage from '../../images/Breefly_Logo-Horiz.svg';

import { BREEFLY_LOGO } from '../../constants';
import termsAndConditionsHtml from './termsAndConditionsHtml';
import privacyPolicyHtml from './privacyPolicyHtml';

const logoImage = BREEFLY_LOGO;

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'left'
  },
}))(MuiExpansionPanelDetails);
  
const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    // maxWidth: '700px',
    width: '70vw',
    // maxWidth: 560,
    margin: 'auto',
    borderRadius: '8px',
    marginTop: 24,
    padding: '30px'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '90%',
    margin: 'auto',
    textAlign: 'center',
   // maxWidth: '700px'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    margin: 50,
  },
  panelSpacer: {
    width: 1,
    height: '10vh',
    minHeight: 48,
},
  contentBody: {
    flexGrow: 1,
    display: 'flex', 
    // width: '90vh',
    // maxWidth: 500,
  },
  form: {
   // borderColor: '#00BFFF',
    // borderStyle: 'solid',
    // borderWidth: 'medium',
    borderRadius: '8px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom:50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    background: '#fff'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
    width: "60%",
    color:'white',
  }, 
  buttonProgress: { 
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));
 

export default function TermsAndConditions(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

 

  return (
    <div className={classes.root}>
         <style>{ `.pageBody { background: #000055; }` }</style>
    <Grid
       className={classes.content}
       item
       lg={12}
       xs={12}
       alignItems="left" 
     >
       <div className={classes.content}>
         <div className={classes.contentBody}>
  
    <React.Fragment>
       
      <Container maxWidth="md" component="main" className={classes.heroContent}>
      <div>
         <Typography
                  color="textSecondary"
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <img src={logoImage} alt="Breefly.com" width="200px" align='middle' className={classes.logoImage} />
             
                </Typography>
        </div>
        <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom>
          Terms and Conditions
        </Typography>
        <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>End-User License Agreement (&quot;Agreement&quot;)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          <div class='terms'>
          <p>Please read this End-User License Agreement (&quot;Agreement&quot;) carefully before clicking the &quot;I Agree&quot; button, downloading or using Breefly (&quot;Application&quot;).</p>
					<p>By clicking the &quot;I Agree&quot; button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.</p>
					<p>This Agreement is a legal agreement between you (either an individual or a single entity) and Cemi Group LLC and it governs your use of the Application made available to you by Cemi Group LLC.</p>
					<p>If you do not agree to the terms of this Agreement, do not click on the &quot;I Agree&quot; button and do not download or use the Application.</p>
					<p>The Application is licensed, not sold, to you by Cemi Group LLC for use strictly in accordance with the terms of this Agreement.</p>
					<p><strong>License</strong></p>
					<p>Cemi Group LLC grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.</p>
					<p><strong>Restrictions</strong></p>
					<p>You agree not to, and you will not permit others to:</p>
					<ul>
					<li>license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.</li>
					<li>modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.</li>
					<li>remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Cemi Group LLC or its affiliates, partners, suppliers or the licensors of the Application.</li>
					</ul>
					<p><strong>Your Suggestions</strong></p>
					<p>Any feedback, comments, ideas, improvements or suggestions (collectively, &quot;Suggestions&quot;) provided by you to Cemi Group LLC with respect to the Application shall remain the sole and exclusive property of Cemi Group LLC.</p>
					<p>Cemi Group LLC shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>
					<p><strong>Modifications to Application</strong></p>
					<p>Cemi Group LLC reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.</p>
					<p><strong>Updates to Application</strong></p>
					<p>Cemi Group LLC may from time to time provide enhancements or improvements to the features/functionality of the Application, which may include patches, bug fixes, updates, upgrades and other modifications (&quot;Updates&quot;).</p>
					<p>Updates may modify or delete certain features and/or functionalities of the Application. You agree that Cemi Group LLC has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Application to you.</p>
					<p>You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement.</p>
					<p><strong>Third-Party Services</strong></p>
					<p>The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services (&quot;Third-Party Services&quot;).</p>
					<p>You acknowledge and agree that Cemi Group LLC shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Cemi Group LLC does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
					<p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
					<p><strong>Privacy Policy</strong></p>
					<p>Cemi Group LLC collects, stores, maintains, and shares information about you in accordance with its Privacy Policy, which is available at https://termsfeed.com/privacy-policy/b1b12f9d8fe1e1869c6a558ee7f8cf05. By accepting this Agreement, you acknowledge that you hereby agree and consent to the terms and conditions of our Privacy Policy.</p>
					<p><strong>Term and Termination</strong></p>
					<p>This Agreement shall remain in effect until terminated by you or Cemi Group LLC.</p>
					<p>Cemi Group LLC may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
					<p>This Agreement will terminate immediately, without prior notice from Cemi Group LLC, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your computer.</p>
					<p>Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your mobile device or from your computer.</p>
					<p>Termination of this Agreement will not limit any of Cemi Group LLC's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
					<p><strong>Indemnification</strong></p>
					<p>You agree to indemnify and hold Cemi Group LLC and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
					<p><strong>No Warranties</strong></p>
					<p>The Application is provided to you &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Cemi Group LLC, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Cemi Group LLC provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
					<p>Without limiting the foregoing, neither Cemi Group LLC nor any Cemi Group LLC's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of Cemi Group LLC are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
					<p>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>
					<p><strong>Limitation of Liability</strong></p>
					<p>Notwithstanding any damages that you might incur, the entire liability of Cemi Group LLC and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the Application.</p>
					<p>To the maximum extent permitted by applicable law, in no event shall Cemi Group LLC or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if Cemi Group LLC or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
					<p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
					<p><strong>Severability</strong></p>
					<p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
					<p><strong>Waiver</strong></p>
					<p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.</p>
					<p><strong>For U.S. Government End Users</strong></p>
					<p>The Application and related documentation are &quot;Commercial Items&quot;, as that term is defined under 48 C.F.R. §2.101, consisting of &quot;Commercial Computer Software&quot; and &quot;Commercial Computer Software Documentation&quot;, as such terms are used under 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. In accordance with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein.</p>
					<p><strong>Export Compliance</strong></p>
					<p>You may not export or re-export the Application except as authorized by United States law and the laws of the jurisdiction in which the Application was obtained.</p>
					<p>In particular, but without limitation, the Application may not be exported or re-exported (a) into or to a nation or a resident of any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List.</p>
					<p>By installing or using any component of the Application, you represent and warrant that you are not located in, under control of, or a national or resident of any such country or on any such list.</p>
					<p><strong>Amendments to this Agreement</strong></p>
					<p>Cemi Group LLC reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
					<p>By continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application.</p>
					<p><strong>Governing Law</strong></p>
					<p>The laws of Colorado, United States, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
					<p>This Agreement shall not be governed by the United Nations Convention on Contracts for the International Sale of Good.</p>
					<p><strong>Contact Information</strong></p>
					<p>If you have any questions about this Agreement, please contact us.</p>
					<p><strong>Entire Agreement</strong></p>
					<p>The Agreement constitutes the entire agreement between you and Cemi Group LLC regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Cemi Group LLC.</p>
					<p>You may be subject to additional terms and conditions that apply when you use or purchase other Cemi Group LLC's services, which Cemi Group LLC will provide to you at the time of such use or purchase.</p>
          </div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Terms of Use</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          {termsAndConditionsHtml()}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Subscription Services Agreement</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          <p>THIS AGREEMENT GOVERNS YOUR ACCESS TO AND USE OF OUR SERVICES.</p>
					<p>BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE 
					   TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>
					<p>This Agreement is effective between You and Us as of the date of You accepting this Agreement.</p>
					<p><strong>Definitions</strong></p>
					<p>"Affiliate" means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. "Control," for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.</p>
					<p>“Agreement” means this Subscription Services Agreement.</p>
					<p>“Content” means information obtained by Breefly from publicly available sources or third party content providers and made available to You through the Services, as more fully described in the Documentation.</p>
					<p>“Malicious Code” means code, files, scripts, agents or programs intended to do harm, including, for example, viruses, worms, time bombs, and Trojan horses.</p>
					<p>“Order Form” means an ordering document or online order specifying the Services to be provided hereunder that is entered into between You and Us or any of Our Affiliates, including any addenda and supplements thereto. By entering into an Order Form hereunder, an Affiliate agrees to be bound by the terms of this Agreement as if it were an original party hereto.</p>
					<p>“Purchased Services” means Services that You or Your Affiliate purchase under an Order Form, as distinguished from those provided pursuant to a free trial.</p>
					<p>“Services” means the products and services that are ordered by You under an Order Form, and made available online by Us, including associated offline or mobile components, as described in the Documentation. “Services” exclude Content and Third Party Applications.</p>
					<p>“Third Party Application” means a Web-based, mobile, offline or other software application functionality that is provided by You or a third party and interoperates with a Service, including, for example, an application that is developed by or for You.</p>
					<p>“User” means an individual who is authorized by You to use a Service, for whom You have purchased a subscription (or in the case of any Services provided by Us without charge, for whom a Service has been provisioned), and to whom You (or, when applicable, Us at Your request) have supplied a user identification and password (for Services utilizing authentication). 
					   Users may include, for example, Your employees, consultants, contractors and agents, and third parties with which You transact business.</p>
					<p>“We,” “Us” or “Our” means the Breefly company described in Section 10 (Who You Are Contracting With, Notices, Governing Law and Jurisdiction).</p>
					<p>“You” or “Your” means the company or other legal entity for which you are accepting this Agreement, and Affiliates of that company or entity which have signed Order Forms.</p>
					<p>“Your Data” means electronic data and information submitted by or for You to the Services, excluding Content and Thirty Party Applications.</p>
					<p><strong>1.	Our Responsibilities</strong></p>
					<p>1.1.	Provision of Purchased Services. We will (a) make the Services and Content available to You pursuant to this Agreement and the applicable Order Forms, (b) provide applicable standard support for the Services to You at no additional charge, and/or upgraded support if purchased, (c) use commercially reasonable efforts to make the online Services available 24 
					   hours a day, 7 days a week, except for: (i) planned downtime (of which We shall give advance electronic notice as provided in the Documentation), and (ii) any unavailability caused by circumstances beyond Our reasonable control, including, for example, an act of God, act of government, flood, fire, earthquake, civil unrest, act of terror, strike or other labor 
					   problem (other than one involving Our employees), Internet service provider failure or delay, or denial of service attack.</p>
					<p>1.2.	Protection of Your Data. We will maintain administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Your Data.  Those safeguards will include, but will not be limited to, measures for preventing access, use, modification or disclosure of Your Data by Our personnel except (a) to provide the Purchased 
					   Services and prevent or address service or technical problems, (b) as compelled by law in accordance with Section 5.3 (Compelled Disclosure) below, or (c) as You expressly permit in writing.</p>
					<p><strong>2.	Use of Services and Content</strong></p>
					<p>2.1	Subscriptions. Unless purchasing a Single Payment product, or otherwise provided in the 
applicable Order Form (a) Services and access to Content are purchased as subscriptions, (b) 
subscriptions may be added during a subscription term at the same pricing as the underlying 
subscription plan, and (c) any added subscriptions will terminate on the same date as the 
underlying subscriptions.</p>
					<p>2.2	Usage Limits. Services and Content are subject to usage limits, including, for example, the quantities specified in Order Forms. Unless otherwise specified, (a) a quantity in an Order Form refers to Users, and the Service or Content may not be accessed by more than that number of Users, and (b) a User’s password may not be shared with any other individual. 
					   If You exceed a contractual usage limit, We may work with You to seek to reduce Your usage so that it conforms to that limit. If, notwithstanding Our efforts, You are unable or unwilling to abide by a contractual usage limit, You will execute an Order Form for additional quantities of the applicable Services or Content promptly upon Our request, and/or pay any 
					   invoice for excess usage.</p>
					<p>2.3	Your Responsibilities. You will (a) be responsible for Users’ compliance with this Agreement, Documentation and Order Forms, (b) be responsible for the accuracy, quality and legality of Your Data and the means by which You acquired Your Data, (c) use commercially reasonable efforts to prevent unauthorized access to or use of Services and Content, and notify 
					   Us promptly of any such unauthorized access or use, (d) use Services and Content only in accordance with this Agreement, Documentation, Order Forms and applicable laws and government regulations, and (e) comply with terms of service of any Thirty Party Applications with which You use Services or Content.</p>
					<p>2.4	Usage Restrictions. You will not and You will ensure that Users do not (a) make any Service or Content available to, or use any Service or Content for the benefit of, anyone other than You or Users, unless expressly stated otherwise in an Order Form (b) sell, resell, license, sublicense, distribute, make available, rent or lease any Service  or Content, 
					   (c) use a Service to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights, (d) use a Service or Thirty Party Application to store or transmit Malicious Code, (e) interfere with or disrupt the integrity or performance of any Service or third-party data 
					   contained therein, (f) attempt to gain unauthorized access to any Service or Content or its related systems or networks, (g) permit direct or indirect access to or use of any Service or Content in a way that circumvents a contractual usage limit, or use any of Our Services to access or use any of Our intellectual property except as permitted under this Agreement, 
					   an Order Form, or the  Documentation,  (h) copy  a Service or any part, feature, function or user interface thereof, (i) copy Content except as permitted herein or in an Order Form or the Documentation, (j) frame or mirror any part of any Service or Content, other than framing on Your own intranets or otherwise for Your  own internal  business purposes or as 
					   permitted in the Documentation, (k) access any Service or Content in order to build a competitive product or service, (l) reverse engineer any Service (except to the extent such restriction is prohibited by applicable law), (m) use any Third Party Application in violation of any license, terms of use or other agreement applicable to the use of such Third Party 
					   Application, (n) violate any applicable laws or regulations in using any Service, Content or any Third Party Application, (o) include and/or use with Your Data any content or materials of any kind (including, without limitation, graphics, images, fonts, sounds, animation, text, or visual effects) for which You (or User) do not have all rights, permissions and 
					   licenses necessary for such inclusion and/or use, including, without limitation, the right to grant to Us all rights and licenses set forth in Section 4.3, or (p) infringe, violate or misappropriate any third party’s intellectual property or proprietary rights or rights of publicity when using Your Data or any Service, Content or Third Party Application. Without 
					   limiting Our termination rights under Section 9.4 (Termination) or any other right or remedy available to Us, any use of the Services in breach of this Agreement, or Order Forms, by You or Users may result in Our immediate suspension of the Services, however We will use commercially reasonable efforts under the circumstances to provide You with notice and an 
					   opportunity to remedy such violation or threat prior to such suspension.</p>
					<p>2.5	Removal of Content. If We are required by a licensor to remove Content, or receive information that content provided to You (including, without limitation, Your Data) may violate applicable law or third-party rights, We may so notify You and in such event You will promptly remove such content (including, without limitation, Your Data) from Your systems. If 
					   We receive information that a Third Party Application hosted on a Service may violate applicable law or third-party rights, We may so notify You and in such event You will promptly disable such Third Party application or modify the Third Party Application to resolve the potential violation.  If You do not take required action in accordance with the above, We may 
					   disable the applicable Content, Service and/or Third Party Application until the potential violation is resolved. Nothing in this Section 2.5 shall limit any other remedy We may have against You.</p>
					<p><strong>3.	Fees and Payment for Purchased Services</strong></p>
					<p>3.1.	Fees. You will pay all fees specified in Order Forms. Except as otherwise specified herein or in an Order Form, (a) fees are based on Services and Content subscriptions purchased and not actual usage, (b) payment obligations are non-cancelable and fees paid are non-refundable, and (c) quantities purchased cannot be decreased during the relevant subscription 
					   term, unless mutually agreed to by the parties.</p>
					<p>3.2.	Invoicing and Payment. You will provide Us with valid and updated credit card information, or with a valid purchase order or alternative document reasonably acceptable to Us. If You provide credit card information to Us, You authorize Us to charge such credit card for all Purchased Services listed in the Order Form for the initial subscription term and any 
					   renewal subscription term(s) as set forth in Section 9.2 (Term of Purchased Subscriptions). Such charges shall be made in advance, either annually or in accordance with any different billing frequency stated in the applicable Order Form. If the Order Form specifies that payment will be by a method other than a credit card, We will invoice You in advance and 
					   otherwise in accordance with the relevant Order Form. Unless otherwise stated in the Order Form, invoiced charges are due net 30 days from the invoice date. You are responsible for providing complete and accurate billing and contact information to Us and notifying Us of any changes to such information.</p>
					<p>3.3.	Overdue Charges. If any invoiced amount is not received by Us by the due date, then without limiting Our rights or remedies, (a) those charges may accrue late interest at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower, and/or (b) We may condition future subscription renewals and Order Forms on 
					   payment terms shorter than those specified in Section 3.2 (Invoicing and Payment).</p>
					<p>3.4.	Suspension of Service and Acceleration. If any amount owing by You under this or any other agreement for Our services is 30 or more days overdue, We may, without limiting Our other rights and remedies, accelerate Your unpaid fee obligations under such agreements so that all such obligations become immediately due and payable, and suspend Our services to You 
					   until such amounts are paid in full. Other than for customers paying by credit card or direct debit whose payment has been declined, We will give You at least 15 days’ prior notice that Your account is overdue before suspending services to You.</p>
					<p>3.5.	Payment Disputes. We will not exercise Our rights under Section 3.3 (Overdue Charges) or 3.4 (Suspension of Service and Acceleration) above if You are disputing the applicable charges reasonably and in good faith and are cooperating diligently to resolve the dispute.</p>
					<p>3.6.	Taxes. Our fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”), unless specifically stated in the Order Form. You are responsible for paying all Taxes associated with Your purchases 
					   hereunder.  If We have the legal obligation to pay or collect Taxes for which You are responsible under this Section 3.6, We will invoice You and You will pay that amount unless You provide Us with a valid tax exemption certificate authorized by the appropriate taxing authority. For clarity, We are solely responsible for taxes assessable against Us based on Our 
					   income, property and employees.</p>
					<p><strong>4.	Proprietary Rights and Licenses</strong></p>
					<p>4.1.	Reservation of Rights. Subject to the limited rights expressly granted hereunder, We and Our licensors and Content providers reserve all of Our/their right, title and interest in and to the Services and Content, including all of Our/their related intellectual property rights. No rights are granted to You hereunder other than as expressly set forth herein.</p>
					<p>4.2.	Access to and Use of Content. You have the right to authorize Users to access and use applicable Content subject to the terms of applicable Order Forms, this Agreement and the Documentation.  You shall ensure that no person other than Your authorized Users are provided access to and use of the applicable Content.  Any User shall be obligated to comply with Our 
					   Terms of Use.  We may suspend or terminate the log-in credentials for any User at any time, without notice, if We determine in Our sole discretion that a User is not complying with the Terms of Use.  You must promptly revoke login credentials of any person who no longer is one of Your authorized Users and you must give Us at least two business days’ advance written 
					   notice of the name of any person whose access to Content is to be revoked (along with the effective date of such revocation), including without limitation, due to any User ceasing to be employed by You, or Your consultants, contractors, agents or other third parties with whom You transact business.  WE SHALL HAVE NO LIABILITY OF ANY KIND FOR ANY PERSON GAINING ACCESS 
					   TO CONTENT IF YOU HAVE FAILED TO GIVE SUCH NOTICE OF REVOCATION.</p>
					<p>4.3.	License to Host Your Data and Applications. You grant Us, Our Affiliates and applicable contractors a worldwide, non-exclusive, royalty-free, fully paid-up, limited-term license to host, copy, transmit and display Your Data, and any Thirty Party Applications and program code created by or for You using a Service or for use by You with the Services, as reasonably 
					   necessary for Us to provide the Services in accordance with this Agreement. Subject to the limited licenses granted herein, We acquire no right, title or interest from You or Your licensors under this Agreement in or to any of Your Data, Thirty Party Application or such program code.</p>
					<p>4.4.	License to Use Feedback. Anything that You make available to Us in using the Services (excluding Your Content), including, without limitation, any suggestion, enhancement request, recommendation, correction or other feedback, is referred to as "Feedback." By providing Us with Feedback, You hereby grant to Us an unlimited, transferable, irrevocable, sublicensable, 
					   nonexclusive, worldwide, perpetual, royalty-free, fully paid-up license to (a) use, reproduce, modify, edit, adapt, publish, translate, display, remove, retain, process, analyze, distribute and create derivative works of and compilations containing Feedback in all media now known or hereafter developed, and (b) use, modify and incorporate into any of Our or Our 
					   Affiliates’ services, in all media now known or hereafter developed, any Feedback and to offer any such services containing all or part of such User Content to any third party.  You hereby represent, warrant and covenant to Us that You are the sole and exclusive owner of the Feedback and have the right to provide the Feedback to Us and that the provision of such Feedback 
					   does not violate any agreement to which You are bound, and any Feedback that You provide to Us: (a) is not false, inaccurate or misleading, (b) does not contain any malware, viruses, Trojan horses, worms, or other automatic devices that are intended to damage, detrimentally interfere with or intercept any data or information from Our website or application; and (c) does 
					   not infringe or misappropriate any third party's intellectual property, publicity, privacy, confidentiality or other proprietary rights. We have no obligation to use any Feedback.</p>
					<p><strong>5.	Confidentiality</strong></p>
					<p>5.1.	Definition of Confidential Information. “Confidential Information” means all information disclosed by a party (“Disclosing Party”) to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. 
					   Your Confidential Information includes Your Data; Our Confidential Information includes the Services and Content; and Confidential Information of each party includes the terms and conditions of this Agreement and all Order Forms (including pricing), as well as business and marketing plans, technology and technical information, product plans and designs, and business processes 
					   disclosed by such party. However, Confidential Information does not include any information that (a) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (b) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (c) is received from 
					   a third party without breach of any obligation owed to the Disclosing Party, or (d) was independently developed by the Receiving Party without use of Disclosing Party’s Confidential Information.</p>
					<p>5.2.	The Receiving Party will (a) maintain the confidentiality of the Disclosing Party’s Confidential Information using the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) (b) not use any Confidential Information of the Disclosing Party for any purpose outside the scope of this 
					   Agreement and (c) except as otherwise authorized by the Disclosing Party in writing, limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates’ employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections not 
					   materially less protective of the Confidential Information than those herein. Neither party will disclose the terms of this Agreement or any Order Form to any third party other than its Affiliates, legal counsel and accountants without the other party’s prior written consent, provided that a party that makes any such disclosure to its Affiliate, legal counsel or accountants 
					   will remain responsible for such Affiliate’s, legal counsel’s or accountant’s compliance with this “Confidentiality” section. Notwithstanding the foregoing, We may disclose the terms of this Agreement and any applicable Order Form to a subcontractor or Thirty Party Application provider to the extent necessary to perform Our obligations to You under this Agreement, under terms 
					   of confidentiality materially as protective as set forth herein.</p>
					<p>5.3.	Compelled Disclosure. The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to 
					   contest the disclosure. If the Receiving Party is compelled by law to disclose the Disclosing Party’s Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access 
					   to that Confidential Information.</p>
					<p><strong>6.	REPRESENTATIONS, WARRANTIES, EXCLUSIVE REMEDIES and DISCLAIMERS</strong></p>
					<p>6.1.	Representations. Each party represents that it has validly entered into this Agreement and has the legal power to do so.</p>
					<p>6.2.	Our Warranties. We warrant that during an applicable subscription term (a) this Agreement, the Order Forms and the Documentation will accurately describe the applicable administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Your Data, (b) We will not materially decrease the overall security of the Services, (c) 
					   the Services will perform materially in accordance with the applicable Order Form, and (d) We will not materially decrease the overall functionality of the Services. For any breach of a warranty above, Your exclusive remedies are those described in the “Termination” and “Refund or Payment upon Termination” sections below.</p>
					<p>6.3.	Your Warranties.  You warrant that during the applicable subscription term (a) any use of Your Data will not infringe, violate or misappropriate any third party’s intellectual property or proprietary rights or rights of publicity, (b) Your Data will not be defamatory or libelous, and (c) You exclusively own all right, title and interest in and to Your Data (including all 
					   intellectual property rights therein) or You have and will maintain at all times all rights, permissions and licenses necessary to grant to Us all rights and licenses set forth in Section 4.3.</p>
					<p>6.4.	Disclaimers. EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. CONTENT 
					   AND BETA SERVICES ARE PROVIDED “AS IS,” EXCLUSIVE OF ANY WARRANTY WHATSOEVER. EACH PARTY DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD-PARTY HOSTING PROVIDERS.</p>
					<p><strong>7.	Mutual Indemnification</strong></p>
					<p>7.1.	Indemnification by Us. We will defend You against any claim, demand, suit or proceeding made or brought against You by a third party alleging that any Service infringes or misappropriates such third party’s intellectual property rights (a “Claim Against You”), and will indemnify You from any damages, attorney fees and costs finally awarded against You as a result of, or 
					   for amounts paid by You under a settlement approved by Us in writing of, a Claim Against You, provided You (a) promptly give Us written notice of the Claim Against You, (b) give Us sole control of the defense and settlement of the Claim Against You (except that We may not settle any Claim Against You unless it unconditionally releases You of all liability), and (c) give Us 
					   all reasonable assistance, at Our expense. If We receive information about an infringement or misappropriation claim related to a Service, We may in Our discretion and at no cost to You (i) modify the Services so that they are no longer claimed to infringe or misappropriate, without breaching Our warranties under “Warranties” above, (ii) obtain a license for Your continued 
					   use of that Service in accordance with this Agreement, or (iii) terminate Your subscriptions for that Service upon 30 days’ written notice and refund You any prepaid fees covering the remainder of the term of the terminated subscriptions. The above defense and indemnification obligations do not apply to the extent a Claim Against You arises from (x) Content, Your Data, a Thirty 
					   Party Application or Your use of the Services in violation of this Agreement, the Documentation or applicable Order Forms or (y) any warranty given by You in this Agreement being inaccurate or untrue.</p>
					<p>7.2.	Indemnification by You. You will defend Us against any claim, demand, suit or proceeding made or brought against Us (a) by a third party alleging that any of Your Data or Feedback is defamatory, libelous, or infringes or misappropriates such third party’s intellectual property rights, rights of privacy or rights of publicity or was obtained by You or User by unlawful means 
					   or in violation of any applicable license, terms of use or other agreement that is binding on You or User, or (b) arising from Your use of the Services or Content or any Third Party Application in violation of the Agreement, the Documentation, Order Form or applicable law (each a “Claim Against Us”), and You will indemnify Us from any damages, attorney fees and costs finally 
					   awarded against Us as a result of, or for any amounts paid by Us under a settlement approved by  You in writing of, a Claim Against Us, provided We (i) promptly give You written notice of the Claim Against Us, (ii) give You sole control of the defense and settlement of the Claim Against Us (except that You may not settle any Claim Against Us unless it unconditionally releases 
					   Us of all liability), and (iii) give You all reasonable assistance, at Your expense.</p>
					<p>7.3.	Exclusive Remedy. This Section 7 states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of claim described in this Section 7.</p>
					<p><strong>8.	Limitation of Liability</strong></p>
					<p>8.1.	Limitation of Liability. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EACH PARTY TOGETHER WITH ALL OF ITS AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY YOU AND YOUR AFFILIATES HEREUNDER FOR THE SERVICES GIVING RISE TO THE LIABILITY IN THE TWELVE MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING 
					   LIMITATION WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY BUT WILL NOT LIMIT YOUR AND YOUR AFFILIATES’ PAYMENT OBLIGATIONS UNDER THE “FEES AND PAYMENT” SECTION ABOVE.</p>
					<p>8.2.	Exclusion of Consequential and Related Damages. IN NO EVENT WILL EITHER PARTY OR ITS AFFILIATES HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOST PROFITS, REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF 
					   LIABILITY, EVEN IF A PARTY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY’S OR ITS AFFILIATES’ REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</p>
					<p><strong>9.	Term and Termination</strong></p>
					<p>9.1	Term of Agreement. This Agreement commences on the date You first accept it and continues until all subscriptions hereunder have expired or have been terminated.</p>
					<p>9.2	Term of Purchased Subscriptions. The term of each subscription shall be as specified in the applicable Order Form. Except as otherwise specified in an Order Form, subscriptions will automatically renew for additional periods  equal to the expiring subscription term or one year (whichever is shorter), unless either party gives the other notice of non-renewal at least 30 days 
					   before the end of the relevant subscription term. The per- unit pricing during any renewal term will increase by up to 5% of the current price, unless We provide You notice of different pricing at least 60 days prior to the applicable renewal term.</p>
					<p>9.3	Discount or Promotional Pricing.  Except as provided in the applicable Order Form, renewal of promotional or one-time priced subscriptions will be at Our applicable list price in effect at the time of the applicable renewal, unless otherwise agreed to by the parties prior to the renewal. Any renewal in which subscription volume for any Services has decreased from the prior 
					   term will result in re-pricing at renewal without regard to the prior term’s  per-unit pricing.</p>
					<p>9.4	Termination. A party may terminate this Agreement for cause (a) upon 30 days written notice to the other party of a material breach if such breach remains uncured at the expiration of such period, or (b) if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation, or assignment for the benefit 
					   of creditors.</p>
					<p>9.5	Refund or Payment upon Termination. If this Agreement is terminated by You in accordance with Section 9.4 (Termination), We will refund You any prepaid subscription fees covering the remainder of the term of all Order Forms after the effective date of termination. If this Agreement is terminated by Us in accordance with Section 9.4 (Termination), You will pay any unpaid 
					   subscription fees covering the remainder of the term of all Order Forms. In no event will termination relieve You of Your obligation to pay any fees payable to Us for the period prior to the effective date of termination.</p>
					<p>9.6	Your Data Portability and Deletion. Upon request by You made within 30 days after the effective date of termination or expiration of this Agreement, We will make Your Data available to You for export or download. After such 30-day period, We will have no obligation to maintain or provide any of Your Data, will thereafter delete or destroy all copies of Your Data in Our systems 
					   or otherwise in Our possession or control, unless legally prohibited.</p>
					<p>9.7	Surviving Provisions. The sections titled “Fees and Payment for Purchased Services,” “Proprietary Rights and Licenses,” “Confidentiality,” “Disclaimers,” “Mutual Indemnification,” “Limitation of Liability,” “Refund or Payment upon Termination,” “Customer Data Portability and Deletion,” “Surviving Provisions,” “Who You are Contracting With, Notices, Governing Law and Jurisdiction,” 
					   and “General Provisions” will survive any termination or expiration of this Agreement.</p>
					<p><strong>10.	Who You are Contracting With, Notices, Governing Law and Jurisdiction</strong></p>
					<p>10.1. General notices shall be provided to:<br />Cemi Group, LLC (d/b/a Breefly)<br />5084 Valentia Street, Denver, CO 80238<br />Attn: Legal Counsel<br />Email: notice@Breefly.com<br /></p>
					<p>10.2.	Manner of Giving Notice. Except as otherwise specified in this Agreement, all notices related to this Agreement will be in writing and will be effective upon (a) personal delivery, (b) the second business day after mailing, or (c) except for notices of termination or an indemnifiable claim (“Legal Notices”), which shall clearly be identifiable as Legal Notices, the day of 
					   sending by email. Billing-related notices to You will be addressed to the relevant billing contact designated by You. All other notices to You will be addressed to the relevant Services system administrator designated by You.</p>
					<p>10.3.	Agreement to Governing Law and Jurisdiction. These Terms shall be governed and construed in accordance with the laws of Colorado, United States, without regard to its conflict of law provisions.</p>
					<p>10.4.	No Agency. For the avoidance of doubt, We are entering into this Agreement as principal and not as agent for any other Breefly company. Subject to any permitted Assignment under Section 11.4, the obligations owed by Us under this Agreement shall be owed to You solely by Us and the obligations owed by You under this Agreement shall be owed solely to Us.</p>
					<p><strong>11.	General Provision</strong></p>
					<p>11.1.	Export Compliance. The Services, Content, other technology We make available, and derivatives thereof may be subject to export laws and regulations of the United States and other jurisdictions. Each party represents that it is not named on any U.S. government denied-party list. You shall not permit Users to access or use any Service or Content in a U.S. embargoed country 
					   (currently Cuba, Iran, North Korea, Sudan, Syria or Crimea) or in violation of any U.S. export law or regulation.</p>
					<p>11.2.	Anti-Corruption. You agree that You have not received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from any of Our employees or agents in connection with this Agreement. Reasonable gifts and entertainment provided in the ordinary course of business do not violate the above restriction to the extent permitted by applicable law. 
					   If You learn of any violation of the above restriction, You will use reasonable efforts to promptly notify Us. </p>
					<p>11.3.	Entire Agreement and Order of Precedence. This Agreement, including any Order Form and the Documentation, is the entire agreement between You and Us regarding Your use of Services and Content and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. Except as otherwise provided herein, no modification, 
					   amendment, or waiver of any provision of this Agreement will be effective unless in writing and signed by the party against whom the modification, amendment or waiver is to be asserted. The parties agree that any term or condition stated in Your purchase order or in any other of Your order documentation (excluding Order Forms) is void. In the event of any conflict or inconsistency among 
					   the following documents, the order of precedence shall be: (1) the applicable Order Form, (2) this Agreement, and (3) the Documentation.</p>
					<p>11.4.	Assignment.  Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably withheld); provided, however, either party may assign this Agreement in its entirety (together with all Order Forms), without the other party’s consent to its Affiliate or in connection 
					   with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their respective successors and permitted assigns.</p>
					<p>11.5.	Relationship of the Parties. The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties.</p>
					<p>11.5	    Third-Party Beneficiaries. There are no third-party beneficiaries under this Agreement</p>
					<p>11.6	    Waiver. No failure or delay by either party in exercising any right under this Agreement will constitute a waiver of that right.</p>
					<p>11.7	    Severability. If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law, the provision will be deemed null and void, and the remaining provisions of this Agreement will remain in effect.</p>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Privacy Policy</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {privacyPolicyHtml()}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </Container>
       
    </React.Fragment>
    </div>
    </div>
    </Grid>
 
    </div>
  );
}