import axios from 'axios';
import { API_LOCAL, ORG_BRAND, STAGE } from './constants';
import LocalSettings from './services/LocalSettings'
import moment from 'moment';

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error && error.response && error.response.status === 401) {
        window.location = "/logout";
    }
    return error;
});

const apiManager = {
    getSettings: async function () {
        const url = (API_LOCAL) + 'users/get-settings';
        try {

            const result = await axios.get(url,);

            console.log("settings", result.data)
            return result.data;
        }
        catch (ex) {
            console.log("settings", ex)
        }
        return {data: {settings: 'empty'}};
    },
    getSharedBriefs: async function (params) {
        const url = (API_LOCAL) + 'briefs/shared/get';
        const { skip, limit, isFavorites, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getMySharedBriefs: async function (params) {
        const url = (API_LOCAL) + 'briefs/myshared/get';
        const { skip, limit, isFavorites, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getUserSharesByAuthor: async function (params) {
        const url = (API_LOCAL) + 'briefs/shares/author/get';
        const { skip, limit, isFavorites, search, email } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;
            headers.email = email;

            const result = await axios.get(url, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getBriefUsers: async function (brief, params) {
        const url = (API_LOCAL) + `briefs/users/get/${brief._id}`;
        const { skip, limit, isFavorites, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getBriefs: async function (params) {
        const url = (API_LOCAL) + 'briefs/get?ms=' + moment().valueOf();
        const { skip, limit, isFavorites, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getBrief: async function (id) {
        const url = (API_LOCAL) + `briefs/get/${id}`;

        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getBriefcases: async function () {
        const url = (API_LOCAL) + 'briefs/briefcase/get';
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getAddressBook: async function () {
      const url = (API_LOCAL) + `users/ab`;
  
      try {
          let headers = this.getHeaders();
          if(!headers.token) {
            return[];
          }
         const result = await axios.get(url, { headers });

          return result.data;
      }
      catch (ex) {
          console.log(ex)
      }
      return [];
  },
    getAsset: async function (assetId) {
        const url = (API_LOCAL) + `assets/get/${assetId}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    setAsset: async function (assetId) {
        const url = (API_LOCAL) + `assets/set/${assetId}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    deleteAsset: async function (asset) {
        const url = (API_LOCAL) + `assets/delete/${asset._id}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.delete(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    duplicateBrief: async function (brief) {
        const url = (API_LOCAL) + `briefs/duplicate/${brief._id}`;
        try {
            const result = await axios.post(url, brief, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    deleteBrief: async function (brief) {
        const url = (API_LOCAL) + `briefs/delete/${brief._id}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.delete(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getAssets: async function (isPhotoFilter, params, search) {
        const url = (API_LOCAL) + 'assets/get';
        const { skip, limit } = params;
        try {
            let headers = { headers: this.getHeaders() };

            headers.headers.isPhotoFilter = isPhotoFilter;
            headers.headers.skip = skip;
            headers.headers.limit = limit;
            headers.headers.search = search;
            const result = await axios.get(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    inviteUser: async function (data, id) {
        LocalSettings.resetAB();  // forces next call to sharing to reload the AddressBook
        const url = (API_LOCAL) + `briefs/invite/${id}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    //this needs to be debugged, based off orgBulkInvite returning 401 error unauthorized, needs note handling
    inviteAffiliate: async function (inviteList, orgId) {
        LocalSettings.resetAB();  // forces next call to sharing to reload the AddressBook
        const url = (API_LOCAL) + `admin/organizations/bulk/invites/${orgId}`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, inviteList, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    //This is a new API call that does not currently exist 
    //invite list = .csv
    affiliateBulkInvite: async function (inviteList, note, orgId) {
        // const url = (API_LOCAL) + `admin/organizations/bulk/invites/${orgId}`;
        try {
            // const headers = this.getHeaders();
            // const result = await axios.post(url, inviteList, { headers });
            //return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getAffliliateLoginHistory: async function() {
      const url = (API_LOCAL) + 'organizations/affiliates/logins';
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getInvite: async function (id) {
        const url = (API_LOCAL) + `briefs/invite/get/${id}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    invitePublicUser: async function (data, id) {
      LocalSettings.resetAB();  // forces next call to sharing to reload the AddressBook
        const url = (API_LOCAL) + `briefs/invite/public/${id}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateFavorites: async function (favorite) {
        const url = (API_LOCAL) + 'users/favorites/update';
        try {
            const result = await axios.post(url, favorite, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {

            console.log(ex)
        }
        return {};
    },
    updateAccountDetails: async function (data) {
        const url = (API_LOCAL) + 'users/details/update';
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {

            console.log(ex)
        }
        return {};
    },
    getUserByToken: async function (token) {
        const url = (API_LOCAL) + 'users/token/get';
        try {
            let headers = this.getHeaders();
            headers.impersonate = token;
            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {

            console.log(ex)
        }
        return {};
    },
    updateAvatar: async function (data) {
        const url = (API_LOCAL) + 'users/avatar/update';
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {

            console.log(ex)
        }
        return {};
    },
    getHeaders: function () {
        const headers = {
            'token': sessionStorage.getItem('token'),
            'org-brand': ORG_BRAND,
            'stage': STAGE
        }
        return headers;
    },
    getUploadHeaders: function () {
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        return headers;
    },
    uploadFile: async function (file) {
        const formData = new FormData();
        formData.append('file', file);
        const url = (API_LOCAL) + 'briefs/image';
        try {
            const result = await axios.post(url, formData, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createBrief: async function (data) {
        const url = (API_LOCAL) + 'briefs/create';
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createTemplateBrief: async function (templateName) {
        const url = (API_LOCAL) + 'briefs/create-first-brief';
        try {
            const result = await axios.post(url, {templateName}, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getUploadKey: async function (file) {
        const url = (API_LOCAL) + 'files/uploads/key';
        try {
            const assetData = {
                contentType: file.type,
                name: file.name,
                size: file.size,
            };
            if( file.dataType) assetData.dataType = file.dataType;
            if( file.dataSubType) assetData.dataSubType = file.dataSubType;
            if( file.dateRange) assetData.dateRange = file.dateRange;          
            if( file.note) assetData.note = file.note;          
            const result = await axios.post(url, assetData, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return { key: '' };
    },
    uploadToS3: async function (url, file, assetId) {
        try {

            const context = this;
            var reader = new FileReader();
            reader.onload = async function () {

                var arrayBuffer = this.result;


                const response = await fetch(
                    new Request(url, {
                        method: 'PUT',
                        headers: {},
                        body: arrayBuffer
                    })
                );

                if (response.status !== 200) {
                    // The upload failed, so let's notify the caller.
                    //alert('the upload failed')
                    console.log(response);


                }
                else {
                    if (assetId) {

                        await context.setAsset(assetId)
                    }
                }

            }
            reader.readAsArrayBuffer(file);
            return { key: '' };


        }
        catch (ex) {
            console.log(ex)
        }
        return { key: '' };
    },
    getCSVUploads: async function() {
        const url = (API_LOCAL) + 'assets/get-csvuploads';
        try {
            const result = await axios.get(url,  { headers: this.getHeaders() });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    processCSV: async function(assetId, orgId, usedSeats, seats) {
        const url = (API_LOCAL) + 'files/process/csv';
        try {
            const result = await axios.post(url, {assetId, orgId, usedSeats, seats}, { headers: this.getHeaders() });
            //error handling is done in the BulkSubscriberForm
            // if( result.data.validCSV.error === true ) {
            //     alert('Sorry, the CSV format is incorrect. The column headers do not match the expected values.');
            // }
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getComments: async function (briefId) {
        const url = (API_LOCAL) + `comments/get/${briefId}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createComment: async function (data) {
        const { brief } = data;
        const url = (API_LOCAL) + `comments/create/${brief}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateComment: async function (data, isLike) {

        const { _id } = data;
        const url = (API_LOCAL) + `comments/${isLike ? 'like' : 'dislike'}/${_id}`;
        try {
            const result = await axios.post(url, data, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getAssetUrl: async function (assetId) {
        const url = (API_LOCAL) + `files/get/${assetId}`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return { key: '' };
    },
    updateAsset: async function (asset) {
        const url = (API_LOCAL) + `assets/update/${asset._id}`;
        try {
            const result = await axios.put(url, asset, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateBrief: async function (brief) {
        const url = (API_LOCAL) + 'briefs/update';
        try {
            const result = await axios.put(url, brief, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateBriefChart: async function (brief) {
        const url = (API_LOCAL) + 'briefs/update/chart';
        try {
            const result = await axios.put(url, brief, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getSubscription: async function () {
        const url = (API_LOCAL) + `subscriptions/get`;
        try {
            const result = await axios.get(url, { headers: this.getHeaders() });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPeople: async function (params) {
        const url = (API_LOCAL) + `people/get`;
        try {
            const { skip, limit, isFavorites, search } = params;

            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.isFavorites = isFavorites;
            headers.search = search;
            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getBriefPeople: async function (briefId, paging) {
        const url = (API_LOCAL) + `people/get/brief/${briefId}`;
        try {
            let headers = this.getHeaders();
            headers.limit = paging.limit;
            headers.skip = paging.skip;
            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    deleteInvite: async function (invite) {
        const url = (API_LOCAL) + `people/delete/${invite.id}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.delete(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    deleteShare: async function (brief) {
        const url = (API_LOCAL) + `people/stop/${brief._id}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.delete(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updatePublicShare: async function (share) {
        const url = (API_LOCAL) + 'briefs/public/update';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, { ...share }, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updatePassword: async function (values) {
        const url = (API_LOCAL) + 'users/password/update';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, { values }, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateAccount: async function (values) {
        const url = (API_LOCAL) + 'users/account/update';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, { values }, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },

    // TODO: CHECK getOwners and createMember as they are DUPES!
    getOwners: async function (params) {
;
        const url = (API_LOCAL) + `organizations/owners/get`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    createMember: async function (data) {
       
        const url = (API_LOCAL) + `organizations/members/create`;

        try {
            let headers = this.getHeaders();
            const result = await axios.post(url, data, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getOrgStats: async function (params) {
        const { search } = params;
        const url = (API_LOCAL) + `organizations/stats/get`;

        try {
            let headers = this.getHeaders();
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    // getMembers: async function(params) {
    //     const { search } = params;
    //     const url = (API_LOCAL) + `organizations/members/get`;

    //     try {
    //         let headers = this.getHeaders();
    //         headers.search = search;

    //         const result = await axios.get(url, { headers });

    //         return result.data;
    //     }
    //     catch(ex){ 
    //         console.log(ex)
    //     }
    //     return [];
    // },
    cancelSubscription: async function () {
        const url = (API_LOCAL) + 'subscriptions/cancel';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, {}, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateAccess: async function (person) {
        const url = (API_LOCAL) + 'people/access/update';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, person, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    sendEnterpriseEmail: async function () {
        const url = (API_LOCAL) + 'people/enterprise';
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, {}, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateTeam: async function (team) {
        const url = (API_LOCAL) + `admin/organizations/teams/update/${team.id}`;
        try {
            const headers = this.getHeaders();

            const result = await axios.put(url, team, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    deleteTeam: async function (team) {
        const url = (API_LOCAL) + `admin/organizations/teams/delete/${team.id}`;
        try {
            const headers = this.getHeaders();

            const result = await axios.delete(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getTeams: async function (team) {
        const url = (API_LOCAL) + `admin/organizations/teams/get/${team.id}`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    updatePlugin: async function (org) {
        const url = (API_LOCAL) + `admin/plugins/update/${org._id}`;
        try {
            const headers = this.getHeaders();

            const result = await axios.put(url, org, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateOrganization: async function (org) {
        const url = (API_LOCAL) + `admin/organizations/update/${org._id}`;
        try {
            const headers = this.getHeaders();

            const result = await axios.put(url, org, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getOrganization: async function (params) {
        const { id } = params;
        const url = (API_LOCAL) + `admin/organizations/get/${id}`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getOrganizationList: async function () {

        const url = (API_LOCAL) + `organizations/get/list`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getOrganizationBranding: async function (params) {
   
        const url = (API_LOCAL) + `organizations/get-branding/${params}`;

        try {
            let headers = this.getHeaders();
            const result = await axios.get(url, { headers });
            return result.data;
        }
        catch (ex) {
            console.log('get branding error: ', ex)

        }
        return [];
    },
    getSignInBranding: async function () {
        const url = (API_LOCAL) + `organizations/get-branding`;

        try {
            let headers = this.getHeaders();
            const result = await axios.get(url, { headers });
            return result.data;
        }
        catch (ex) {
            console.log('get branding error: ', ex)

        }
        return [];
    },
    getPortalBranding: async function (params) {
      const { key, orgId, memberId } = params;
      const url = (API_LOCAL) + `people/get-branding/${key}/${orgId}/${memberId}`;
  
      try {
        let headers = this.getHeaders();
        const result = await axios.get(url, { headers });
        return result.data;
      }
      catch (ex) {
        console.log('get portal branding error: ', ex)
  
      }
      return [];
    },
    getPlugin: async function (params) {
        const { id } = params;
        const url = (API_LOCAL) + `admin/plugins/get/${id}`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getOrganizations: async function (params) {
        const url = (API_LOCAL) + 'admin/organizations/get';
        const { skip, limit, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getPlugins: async function (params) {
        const url = (API_LOCAL) + 'admin/plugins/get';
        const { skip, limit, search } = params;
        try {
            let headers = this.getHeaders();
            headers.skip = skip;
            headers.limit = limit;
            headers.search = search;

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getOwners: async function (params) {
       
        const url = (API_LOCAL) + `admin/organizations/owners/get/${params._id}`;

        try {
            let headers = this.getHeaders();

            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    getMembers: async function (params) {
        const url = (API_LOCAL) + `admin/organizations/members/get/${params._id}`;

        try {
            let headers = this.getHeaders();
            headers.skip = 0;
            headers.limit = 100;
            headers.search = params.search ? params.search : '';
            const result = await axios.get(url, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    createMember: async function (data) {
       
        const url = (API_LOCAL) + `admin/organizations/members/create/${data._id}`;

        try {
            let headers = this.getHeaders();
            const result = await axios.post(url, data, { headers });

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return [];
    },
    deleteMember: async function (member) {
        const url = (API_LOCAL) + `admin/members/delete/${member._id}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.delete(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getReport: async function (report) {
        const url = (API_LOCAL) + `admin/reports/get`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, report, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIDatasetsInfo: async function (dataset) {
        const url = (API_LOCAL) + `datasets/pmi/info?dataset=${dataset}`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.get(url, headers);

            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIChartData: async function (filter) {
        const url = (API_LOCAL) + `datasets/pmi/filter`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, filter, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIChartTemplates: async function () {
        const url = (API_LOCAL) + `datasets/pmi/chart-templates`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.get(url, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createPMIChartAsset: async function (asset) {
        const url = (API_LOCAL) + `assets/create`;
        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.post(url, asset, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMIChartAssets: async function (asset = null) {
        const param = asset ? `?assetId=${asset}` : '';
        const url = `${API_LOCAL}datasets/pmi/get-user-assets${param}`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.get(url, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updatePMIChartAssets: async function (asset) {
        // const param = asset ? `?assetId=${asset}` : '';
        const url = `${API_LOCAL}datasets/pmi/update-user-assets`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.put(url, asset, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getSharedPMIChartAssets: async function (asset = null) {
        const param = asset ? `?assetId=${asset}` : '';
        const url = `${API_LOCAL}datasets/pmi/shared/get-user-assets${param}`;

        try {
            let headers = { headers: this.getHeaders() };
            const result = await axios.get(url, headers);
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getPMISentimentResponse: async function (query) {
        const url = `${API_LOCAL}datasets/pmi/sentiment/get-question-response`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, {query}, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    orgBulkInvite: async function (inviteList, orgId) {
        // for an owner or co-owner to update a team member or affilliate member
        LocalSettings.resetAB();  // forces next call to sharing to reload the AddressBook
 
        const url = (API_LOCAL) + `admin/organizations/bulk/invites/${orgId}`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, inviteList, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    orgUpdateMember: async function (memberData) {
        // for an owner or co-owner to update a team member or affilliate member
        const url = (API_LOCAL) + `organizations/member/update`;
        try {
            const headers = this.getHeaders();

            const result = await axios.post(url, memberData, { headers });

            return result.data;
            return ('success')
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    getVizData: async function (vizData) {
        // for an owner or co-owner to update a team member or affilliate member
        const url = (API_LOCAL) + `users/vizdata`;
        try {
            const headers = this.getHeaders();
            const result = await axios.get(url, { headers });
            return result.data;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    updateVizData: async function (vizData) {
        // for an owner or co-owner to update a team member or affilliate member
        const url = (API_LOCAL) + `users/vizdata/update`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, vizData, { headers });

            return true;
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    deleteVizData: async function (vizData) {
        //for an owner or co-owner to delte a vizCard
        const url = (API_LOCAL) + `users/vizdata/update`;
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, vizData, { headers });
            if (result) {
            return true;
            }
        }
        catch (ex) {
            console.log(ex)
        }
        return {};
    },
    createCheckoutSession: async function (stripeSession) {
      const url = (API_LOCAL) + 'subscriptions/create-checkout-session';
      try {
        const headers = this.getHeaders();
        console.log(headers);
        // Returns: checkout.session object
        const result = await axios.post(url, stripeSession, { headers }).catch(e => console.log(e.message));
        return result.data;
      } catch (ex) {
        console.log(ex);
      }
      return [];
    },
    createStripeSubscription: async function (price, quantity, trialPeriod = 0) {
      const url = (API_LOCAL) + 'subscriptions/create-subscription';
      try {
        const headers = this.getHeaders();

        const stripeRequest = {
          lineItems: [{price, quantity}],
          trialPeriod, // Days
        };

        // Returns: subscription object
        const result = await axios.post(url, stripeRequest, { headers }).catch(e => console.log(e.message));
        return result.data;
      } catch (ex) {
        console.log(ex);
      }
      return {};
    },
    crossgradeStripeSubscription: async function (subId, currPrice, newPrice, quantity = 1) {
      const url = (API_LOCAL) + 'subscriptions/crossgrade-subscription';
      try {
        const headers = this.getHeaders();

        const stripeRequest = {
          subId,
          currPrice,
          newPrice,
          quantity
        };

        // Returns: subscription object
        const result = await axios.post(url, stripeRequest, { headers }).catch(e => console.log(e.message));
        return result.data;
      } catch (ex) {
        console.log(ex);
      }
      return {};
    },
    cancelStripeSubscription: async function (subId) {
      const url = (API_LOCAL) + 'subscriptions/cancel-subscription/' + subId;
      try {
        const headers = this.getHeaders();

        // Returns: subscription object
        const result = await axios.get(url, { headers }).catch(e => console.log(e.message));
        return result.data;
      } catch (ex) {
        console.log(ex);
      }
      return {};
    },
    removeTeam: async function (email) {
        const url = (API_LOCAL) + 'subscriptions/removeTeam'
        console.log('to remove team')
        try {
            const headers = this.getHeaders();
            const result = await axios.post(url, { email }, { headers }).catch(e => console.log(e.message));
            return result.data;
        } catch (ex) {
            console.log(ex);
        }
        return {};
    },
    getPromoCode: async function (promoCode = 'none') { // TODO: Change default to `none` before launch!
      const url = (API_LOCAL) + `users/promocode/${promoCode}`;
;
      try {
        const headers = this.getHeaders();
            // Retreive promo code trial length and discounts (if any)
            const result = await axios.get(url, {headers}).catch(e => console.log(e.message));
            return result.data;
      } catch (ex) {
        console.log(ex)
      }
    },
    getTrialLength: async function (accountType = 'none') {
      const url = (API_LOCAL) + `users/auth/get-trial-length/${accountType}`;

      try {
        // const headers = this.getHeaders();
        const result = await axios.get(url).catch(e => console.log(e.message));
        return result.data.trial;
      } catch (ex) {
        console.log(ex)
      }
    },
    reAuthenticate: async function (data) {
        const url = (API_LOCAL) + 'users/auth/reauthenticate'
        try {
            const headers = this.getHeaders();

            //returns user data after subscription change bypassig logout login
            const result = await axios.post(url, data, {headers}).catch(e => console.log(e.message));
            return result.data;

        } catch (ex) {
            console.log(ex)
        }
        return {}
    },
    logEvent: async function(data) {
        const url = (API_LOCAL) + 'events/create/event'
        try {
            
            const headers = this.getHeaders();
            const result = await axios.post(url, data, {headers});
            return result;
        } catch (ex) {
            console.log(ex)
        }
        return {}
    }
}

export default apiManager;
