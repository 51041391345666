import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { faTrash, faArrowsAlt, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DowngradeModal(props) {
  const { classes, funcs, modal, history, diff } = props;

  const handleRemoveUsers = () => {
    history.push(`/owner/${diff}`)
  }

  const renderButton = () =>{
    if (diff > 0) {
      return(
        <Button onClick={handleRemoveUsers} color="primary">
            Remove users
        </Button>
      )
    }
    else {
      return(
        <Button onClick={funcs.downgradeStripeSubscription} color="primary">
            Downgrade Subscription
        </Button>
      )
    }
  }
  //MODAL FOR TEAM WITH TOO MANY USERS TO DOWNGRADE
  if (modal.isTeams) {
    return(
      <Dialog open={modal.isOpen} onClose={funcs.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Looking to lighten the load?</DialogTitle>
        <DialogContent>
          {diff > 0 ? 
            <DialogContentText>
              <p>We understand, and made it easy to downgrade your subscription. First, go to your Team Management page to remove the {diff} users from your team. Then return to this page to complete your downgrade.</p>            
            </DialogContentText> :
            <DialogContentText>
              <p>We understand, and made it easy to downgrade your subscription. Are you sure you wish to downgrade?</p>            
            </DialogContentText>
          }
            
        </DialogContent>
        <DialogActions>
          <Button onClick={funcs.handleClose} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          {renderButton()}
        </DialogActions>
      </Dialog>
    )
  } else {

    //MODAL FOR TEAM TO INDIVIDUAL. ALL MEMBERS BUT CURRENT OWNER REMOVED FROM TEAM 
    return (
        <Dialog open={modal.isOpen} onClose={funcs.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Downgrade subscription?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Are you sure you want to downgrade your subscription? All current team members will immediately lose access to their accounts and data.</p>
              {/* <p>Need to inset an additional step to make this harder to cancel. Enter text "downgrade" or something</p> */}
            </DialogContentText>
              
          </DialogContent>
          <DialogActions>
            <Button onClick={funcs.handleClose} color="primary">
            <span style={{fontWeight: 300}}>Cancel</span>
            </Button>
            <Button onClick={funcs.downgradeToIndividual} color="primary">
              Downgrade Subscription
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}
