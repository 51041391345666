import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';


import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CreateBriefButton from '../../components/CreateBriefButton';
import LinearIndeterminate from '../Briefs/components/LinearIndeterminate' 
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import * as am4core from "@amcharts/amcharts4/core";

// import BrandedCardTitle from '../OrganizationDetails/components/BrandedCardTitle';
import VizCard from './components/vizCard';
import TemplatesDialog from './components/templatesDialog';
import ChartCard from './components/ChartCard'

import './style.scss';
import SubscriptionModal from '../../components/SubscriptionModal';
am4core.addLicense("CH290132791")

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints]: {
            values: { md: 1130 },
        },
        '& .MuiTab-fullWidth': {
            minWidth: 'unset'
        },
        textField: {
        },
        '& .MuiButton-contained': {
            backgroundColor: 'var(--brand-color1) !important',
            // width: '60%',
            // marginLeft: '20%'
        },
      
    },
    searchbar: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        height: 100,
        paddingTop: 8,
        '@media (max-width: 650px)': {
            textAlign: 'left',
            paddingLeft: 46,
            height: 80,
        },
    },
    searchform: {
        width: '50%',
        '@media (max-width: 860px)': {
            width: '33%',
        },
        '@media (max-width: 500px)': {
            width: '40%',
            minWidth: 180,
        },
    },
    primaryCTA: {
        position: 'absolute',
        top: 8,
        left: 0,
        '@media (max-width: 650px)': {
            position: 'unset',
            marginTop: 24,
        },
    },
    mobileCTA: {
        position: 'absolute',
        top: 8,
        right: -24,

    },
    favDesktop: {
        fontSize: 14, color: '#333',
    },
    favMobile: {
        fontSize: 14, color: '#333',
        width: '100%'
    },
    filter: {
        fontSize: 14,
        marginRight: 24,
        color: "#888;",
        "&.active": {
          color: "#333"
        }
      },
}));


export default function Visualizations(props) {

    const classes = useStyles();
    const { user, apiManager, history, isMobile, refreshChild, settings } = props;

    const [briefs, setBriefs] = React.useState([]);
    const [brief, setBrief] = React.useState({});

    const [isLoadingCards, setIsLoadingCards] = React.useState(true);
    const [isLoadingAssets, setIsLoadingAssets] = React.useState(true);
    const [init, setInit] = React.useState(false);

    const [skip, setSkip] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(isMobile ? 24 : 12);
    const [count, setCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [showSnackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    // const [queryDate, setQueryDate] = React.useState('52 w/e 3.29.2020');
    const [org, setOrganization] = React.useState(false);

    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

    const [selectedPlugin, setPlugin] = React.useState('POS Insights');
    const [datasetInfo, setDatasetInfo] = React.useState(false);
    const [isOwner, setOwner] = React.useState(true);
    const [chartAssets, setChartAssets] = React.useState([]);
    const [isEditing, setIsEditing] = React.useState(false);

    //To Do these are currently hard coded. We might need to set the query date dynamically based off dataset in the future
    const [vizCards, setVizCards] = React.useState([
        {
            dataset: 'produce',
            aisleName: 'VEGETABLES',
            geo: 'Total US - Multi Outlet',
            product: 'TOTAL VEGETABLES',
            metrics: ['Dollar Sales', 'Volume Sales' ],
            key: uuidv4(),
            queryDate: "4 we 12-27-20",
            
        },
        {
            dataset: 'produce',
            aisleName: 'FRUIT',
            product: 'APPLES',
            geo: 'Total US - Multi Outlet',
            metrics: ['Dollar Sales', '% Stores Change vs YA' ],
            key: uuidv4(),
            queryDate: "4 we 12-27-20",
        },
        {
            dataset: 'floral',
            aisleName: '',
            geo: 'Total US - Multi Outlet',
            product: 'DEPT-FLORAL',
            metrics: ['Price per Unit', 'Price per Unit %Change vs YA' ],
            key: uuidv4(),
            queryDate: "4 we 11-29-20",   
        },
        {
            dataset: 'floral',
            aisleName: '',
            geo: 'Total US - Multi Outlet',
            product: 'FRESH CUT-AISLE-FLORAL',
            metrics: ['Dollar Sales', 'Unit Sales' ],
            key: uuidv4(),
            queryDate: "4 we 11-29-20", 
        },
    ])
    const getData = async (overrideSearch) => {
        // DEPRECATED:
        // const paths = history.location.pathname.split('/');
        // const id = paths[paths.length - 1]
    
        const params = {
          'id': user.orgId
        }
    
        const orgData = await apiManager.getOrganization(params);
    
        if (orgData) {
          setOrganization(orgData);
      }
    };
    const getDatasetInfo = async (newLimit, newPage, newSearch, isFavoritesOverride) => {
        // const sendLimit = newLimit ? newLimit : limit;
        // const sendPage = newPage != null ? newPage : page;
        setIsLoadingCards(true);
        const datasetInfoProduce = await apiManager.getPMIDatasetsInfo('produce');
        const datasetInfoFloral = await apiManager.getPMIDatasetsInfo('floral');
        datasetInfoFloral && datasetInfoProduce.info.datasets.push(datasetInfoFloral.info.datasets[0])
        setDatasetInfo(datasetInfoProduce && datasetInfoFloral ? [datasetInfoProduce, datasetInfoFloral] : false);
        const dataCards = await apiManager.getVizData();

        if( dataCards && dataCards.vizData && dataCards.vizData.vizData ) {
            // temporary backwards compatability.. remove any old-style cards
            if( dataCards.vizData.vizData[0] && dataCards.vizData.vizData[0].dataset) {
                setVizCards(dataCards.vizData.vizData);
            }
        }
        setIsLoadingCards(false)
    }

    const getChartAssets = async() => {
        setIsLoadingAssets(true);

        const response = await apiManager.getPMIChartAssets();
        console.log('response', response)
        const removeDeleted = response.assets.filter((chart) => !chart.deleted);
        const sortedCharts = removeDeleted.sort((a,b) => moment(b.date) - moment(a.date));
        setChartAssets(sortedCharts);
        setIsLoadingAssets(false);
    }
    useEffect(() => {
        getDatasetInfo();
        getChartAssets();
        getData();
    },[])
    // if (!init) {
    //     setInit(true);
    //     getDatasetInfo();
    //     getChartAssets();
    // }

    // const updateIntercom = (user) => {
    //     window.intercomSettings = {
    //         app_id: "zcl62xdv",
    //         name: `${user.firstname} ${user.lastname}`,
    //         email: `${user.email}`,
    //         created_at: `${user.created}`
    //     }
    //     window.Intercom('boot', window.intercomSettings);
    //     sessionStorage.setItem('intercom', JSON.stringify(window.intercomSettings));
    // }

    // updateIntercom(user);

    const createChart = () => {
        history.push(`/chart-builder`);  
    }

    const closeModal = () => {
        setCreateDialogOpen(false);
    }
    const updateVizCard = async(cardData, index, openEdit) => {
        //setIsEditing(true);
        let updatedCards = [...vizCards];
        updatedCards[index] = cardData;
        

       await apiManager.updateVizData(updatedCards);
        //if (response) {
            // setVizCards(updatedCards);
            // await apiManager.updateVizData(updatedCards)
           // return true;
        //}
        //    return index + 1;
        // } 
        // else {
        //     setIsEditing(true);
        //     return index;
        // }
    }
    const deleteVizCard = async(card, index) => {
        vizCards.splice(index, 1)
        setVizCards([...vizCards]); //spread to trigger rerender
        await apiManager.deleteVizData(vizCards);

    }
    const createCard = async() => {
        let newCard = {
            dataset: 'produce',
            aisleName: 'VEGETABLES',
            geo: 'Total US - Multi Outlet',
            product: 'TOTAL VEGETABLES',
            metrics: ['Dollar Sales', 'Volume Sales' ],
            key: uuidv4(),
            queryDate: "4 we 12-27-20",
        };
        let cards = [...vizCards];
        cards.push(newCard);
        setVizCards(cards);
        await apiManager.updateVizData(cards);
    }
    const [createError, setCreateError] = useState('')
    // create a brief from the selected chart

    // asset._id,
    //   asset.settings.title,
    //   asset.settings.subtitle,
    //   asset.settings.time,
    //   asset.settings.dataset,
    //   asset.settings,
    const createBriefWithChart = async (newAssetId, chartTitle, subtitle, time, dataset, settings, newAssetKey, newAssetDate) => {
       
        setIsLoadingCards(true);
        setIsLoadingAssets(true);

        // create a brief with a default chart for now...
        const brief = {
            title: `Brief Re: ${chartTitle}`,
            tags: [],
            briefcase: { value: 0 },
            key: uuidv4(),
            components: [
                {
                    "type": (0),
                    "content": {
                        "text": `Brief Re: ${chartTitle}`
                    },
                    "position": (0)
                },
                {
                    "type" : (6), 
                    "content" : {
                        title: chartTitle,
                        subTitle: `${subtitle}`,
                        assetId: newAssetId,
                        dataset,
                        settings, 
                        size: 600,
                        align: 'center',
                    }, 
                    "position" : (1),
                    key : newAssetKey,
                    date : newAssetDate,
                  } 
            ]
        }
        const result = await apiManager.createBrief(brief);
        if (result && result.insertedCount > 0) {
            //history.push(`/brief/edit/${result._id}`);
            closeModal();
            window.location = `/brief/edit/${result._id}`;
        }
        else {
            if(result && result.msg) {
                setCreateError(result.msg)
            } else {
                //   alert(result && result.msg ? result.msg : 'An error has occurred')
                setSnackMessage(result && result.msg ? result.msg : 'An error has occurred');
                setSnackBar(true);
            }
        }
        setIsLoadingCards(false);
        setIsLoadingAssets(false);
    }



    // close the create chart dialog
    // if not cancel, then create the asset and go from there.
    const handleCloseCreateChartDialog = async (action, data) => {
        setCreateDialogOpen(false);
   
        if( action !== 'cancel') {
            // first create the asset!
            const asset = {
                key: uuidv4(),
                contentType: 'customChartPMA',
                url: null,
                date: moment().valueOf(),
                deleted: false,
                dataset: data.dataset,
                settings: {
                    title: data.title,
                    subtitle: `${data.geo} - ${data.time}`,
                    type: data.chartType,
                    dataset: data.dataset,
                    geo: data.geo,
                    time: data.time,
                    dateRange: null,
                    metrics: data.metrics,
                    products: data.products,
                    chartColor: data.chartColor || null,
                    chartOptions: data.chartOptions || null,
                    plugin: {
                        org: "PMA",
                        dataset: data.dataset
                    }
                }
            }
            if (data.aisleName) {
                asset.settings.aisleName = data.aisleName;
                asset.settings['Aisle Name'] = data.aisleName;
            }
            if( data.dataset === 'sentiment') {
                asset.settings.question = data.question;
                asset.settings.fullSurvey = data.fullSurvey;
                asset.settings.querySegments = data.querySegments;
                asset.settings.compareTo = data.compareTo;
                asset.settings.fullSurvey = data.fullSurvey;
                asset.settings.subSegment = data.subSegment;
         
            }
   
            const newAsset = await apiManager.createPMIChartAsset(asset);
            await getChartAssets();
            if( action == 'createBrief') {
                // create the brief using this new asset
                createBriefWithChart(newAsset.createdId, data.title, data.subtitle, data.dataset, data.time, newAsset.result.date, newAsset.result.key);
            }
        }
    }
    const hideAsset = async (asset) => {
        let newAsset = {...asset}

        newAsset.deleted = true;
        newAsset.assetId = newAsset._id;
        console.log('newAsset', newAsset)
        await apiManager.updatePMIChartAssets(newAsset);
        getChartAssets();
    }
    const duplicateChart = async (asset) => {
        await apiManager.createPMIChartAsset(asset);
        getChartAssets();
    }
    const [numCharts, setNumCharts] = useState(2);
    const renderAssets = () => {
        if( isLoadingAssets) {
            return (
                <div>
                    <LinearIndeterminate />
                </div>
            )
        }

        if (!chartAssets || chartAssets.length === 0) {
            return null;
        }
        const chartChunks = chartAssets.filter((charts, index) => index < numCharts)
        console.log('chart chunks', chartChunks)
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
            >
            <Grid item xs={12}>
                <div style={{fontWeight: 600, fontSize: 18, marginTop: 18}}>Saved Charts</div>
            </Grid>
            
            {chartChunks.map((asset, index) => (
            <Grid item xs={12} sm={6} key={asset.key
}>
                <ChartCard 
                settings={settings}  
                apiManager={apiManager}
                index={index}
                asset={asset}
                createBriefWithChart={createBriefWithChart}
                hideAsset={hideAsset}
                duplicateChart={duplicateChart}
            />
            </Grid>
            ))}
            <Grid item xs={12} className="gentle-flex">
                {chartAssets.length - chartChunks.length >= 1 && (
                    <Button onClick={() => setNumCharts(numCharts + 2)} >Show More Charts</Button>
                )}
            </Grid>
            </Grid>
        )
    }
    const render = () => {
        return (
            <div className={`${classes.root} viz branded`}>
            {org && org.branding && org.branding.portallogo && (
                <div className='pageLogo' style={{marginTop:-56}}>
                <img src={org.branding.portallogo} width='125px' />
                </div>
            )}    
            {user.affiliateHostOrg && user.affiliateHostOrg.branding && user.affiliateHostOrg.branding.portallogo && (
                <div className='pageLogo' style={{marginTop:-56}}>
                <img src={user.affiliateHostOrg.branding.portallogo} width='125px' />
                </div>
            )}          
            { /* main CTA and searchbar */}
                <div className={'pageTitle'} style={{marginTop: 80, marginBottom: 40}}>
                    {selectedPlugin}
                    {/* {isOwner && (
                        <div className='upload'>
                            <Button variant='outlined'>Upload Data Set</Button>
                        </div>
                    )} */}

                </div>
                {/* top 4 panels */}
                <Grid
                    container
                    spacing={4}
                    // style={{ display: isLoading ? 'none' : '' }}
                >
                    {isLoadingCards && (
                        <div style={{height: 350, width: '100%'}}>
                        <LinearIndeterminate />
                        </div>
                    )}
                    {!isLoadingCards && datasetInfo && vizCards && vizCards.map((card, index) => (
                        <VizCard 
                            datasetInfo={datasetInfo[0]}
                            index={index}
                            cardData={card}
                            handleAdd={createCard}
                            handleChange={updateVizCard}
                            handleDelete={deleteVizCard}
                            apiManager={apiManager}
                            isEditing={isEditing && isEditing === index ? true : false}
                            key={card.key}
                            length={vizCards.length}
                            />                 
                    ))}
                    {vizCards.length === 0 && 
                    <Grid item xs={12} className="gentle-flex">
                        <Button onClick={createCard} variant="outlined">Add Card</Button>
                    </Grid>
                    }
 
                  
                   
                <div style={{width: '100%', marginTop: 32}}>
                    <div className='leftLine'/>
                        <Button 
                             onClick={() => setCreateDialogOpen(true)}
                            // onClick={createChart}
                            variant='contained' color='primary'><span style={{color: 'white'}}
                        >
                        <FontAwesomeIcon icon={faPlusCircle} color='primary' style={{marginRight: 12}} />
                            Create Chart
                            </span></Button>
                    <div className='rightLine' />

                </div>
                {renderAssets()}
                </Grid>
                {createDialogOpen && 
                <TemplatesDialog 
                    classes={classes} 
                    apiManager={apiManager} 
                    isOpen={createDialogOpen} 
                    handleClose={handleCloseCreateChartDialog} 
                    user={user}
                    isViz
                />}
                <Snackbar
                    open={showSnackBar}
                    autoHideDuration={3000}
                    className="error"
                    severity="info"
                    onClose={() => setSnackBar(false)}
                    variant="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="error">{snackMessage}</Alert>
                </Snackbar>
                {createError && 
                <SubscriptionModal 
                    open={Boolean(createError)}
                    message={createError}
                    onClose={() => setCreateError('')}
                    user={user}
                    modalTitle={"briefs"}
                    />
                }
            </div >
        );
    }

    return (render());
};


