import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import RemoveBrief from './RemoveBrief';
import BriefThumbnail from '../../Briefs/components/BriefThumbnail';
import RenderBriefInvites from './RenderBriefInvites'
import InviteExistingUser from '../../Brief/components/InviteExistingUser'

import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    panel: {
     //   width: '90%',
        padding: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    media: {
        height: 140,
    },
    avatar: {
        width: 60,
        height: 60,
        boxShadow: '1px 1px 10px #888888'
    },
    upDown: {
        fontSize: 28,
        display: 'inline-block',
        marginLeft: 24,
    },
    briefsection: {
        marginTop: 32,
        marginLeft: 4,
        marginBottom: 12,
    },
    brieftable: {
        fontSize: 18,
    },
    briefrow: {
        paddingTop: 12,
        paddingBottom: 14,
        borderBottom: '1px solid gray'
    },
    titlerow: {
        fontWeight: 900,

    },
    selectlabel: {
        fontSize: 11,
        fontWeight: 200,
    },
    checkbox: {
        paddingLeft: 8,
        marginRight: 12,
    },
    title: {
        maxWidth: '90%',
       //  whiteSpace: 'nowrap',
       height: 50,
       display: '-webkit-box',
       WebkitLineClamp: 2,
       WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 18,
        fontWeight: 600,
    },
    menuitem: {
        fontSize: 14,
        color: '#666'
    },
    avatarcolor1: {
        backgroundColor: '#25C2F4',
    },
    avatarcolor2: {
        backgroundColor: '#0052DD',
    },
    trashIcon: {
        position: 'absolute',
        right: '50px',
        top: '21px'
    },
    pointdown: {
        transform: 'rotate(0deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'
    },
    pointup: {
        transform:' rotate(-180deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'
    },
    mobilePanel: {
        paddingLeft: 12,
        paddingTop: 8,
        paddingBottom: 12,
    }
}));

export default function BriefPanel({ brief, apiManager, isMobile, handleDelete, updateAccess, refreshChild, user }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [briefData, setBriefData] = React.useState({});
    const [showSnackBar, setSnackBar] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackProps, setSnackProps] = React.useState({severity: 'error', classname: 'error'})

    const handleChange = (panel) => (event, isExpanded) => {
        if (!isEdit) {
            setExpanded(isExpanded ? panel : false);
        }
    };

    const togglePanel = () => {
        setExpanded(!expanded ? 'panel1' : false);
    }

    useEffect(() => {
        if(brief && brief.briefData && !briefData.created){
            setBriefData(brief.briefData)
        }
    }, [])
    

    const handleSave = async (invites) => {

        invites.id = brief._id;

        const result = await apiManager.inviteUser(invites, brief._id);
        setIsEdit(false);

        if(result.nonMemberEmails.length === 0){  
            setSnackMessage('Success! User was invited.');
            setSnackProps({severity: 'success', classname: 'success'});
            setSnackBar(true);
          }
          else if (result.nonMemberEmails.length !== 0 && result.memberEmails.length !== 0){ 
            setSnackMessage(`Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `);
           setSnackProps({severity: 'error', classname: 'error'});
           setSnackBar(true);
          } else {
            setSnackMessage(`Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `);
           setSnackProps({severity: 'error', classname: 'error'});
           setSnackBar(true);
          }
        // if (result) {
        //     // alert('User invited!')
        //     setSnackMessage('Success! User was invited.');
        //     setSnackProps({severity: 'success', classname: 'success'});
        //     setSnackBar(true);
        // }
        // else {
        //    //  alert('Something went wrong!')
        //    setSnackMessage('Sorry, something went wrong. Please try again.');
        //    setSnackProps({severity: 'error', classname: 'error'});
        //    setSnackBar(true);
        // }
    }

    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ width: '100%' }}>
                    <div className={classes.panel}>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    {/* photo if not mobile */}
                                    {!isMobile && (
                                        <td style={{ width: 100, textAlign: 'left' }}>
                                            <div >
                                                <BriefThumbnail brief={briefData} size="md" />
                                            </div>
                                        </td>
                                    )}
                                    {/* title and updated date */}
                                    <td style={{ textAlign: 'left' }}>
                                        <div className={isMobile ? classes.mobilePanel : ''}>
                                        <div className={classes.title}>
                                            {briefData && briefData.components && briefData.components.length > 0 ? brief.briefData.components[0].content.text : 'Unnamed Brief'}
                                        </div>
                                        {!isMobile && (
                                        <span style={{fontWeight: 500}}> {brief.briefData ? moment(brief.briefData.created).format('MMM D, YYYY') : 'Deleted'}</span>
                                        )}
                                        </div>
                                    </td>
                                    {/* interface */}
                                    <td style={{textAlign: 'right', width: '120px' }}>
                                        <div>
                                            <InviteExistingUser classes={classes} brief={brief} apiManager={apiManager} isMobile={isMobile} handleSave={handleSave} setIsEdit={setIsEdit} user={user}/>
                                            <RemoveBrief brief={brief} apiManager={apiManager} handleDelete={e => handleDelete(brief)} classes={classes} />
                                            <IconButton edge="start" color="inherit"  onClick={togglePanel} aria-label="close">
                                               {!isMobile && (
                                                <ExpandMoreIcon className={expanded ? classes.pointup : classes.pointdown}   />
                                               )}
                                        </IconButton>
                                        <Snackbar
                                            open={showSnackBar}
                                            autoHideDuration={3000}
                                            className={snackProps.classname}
                                            severity={snackProps.severity}
                                            onClose={() => setSnackBar(false)}
                                            variant={snackProps.severity}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Alert severity={snackProps.severity}>{snackMessage}</Alert>
                                        </Snackbar>
                                    </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                <ExpansionPanelDetails>
                    <span style={{ width: '100%' }}>
                        <RenderBriefInvites brief={brief} apiManager={apiManager} updateAccess={updateAccess} refreshChild={refreshChild} isOpen={expanded === 'panel1'} />
                    </span>

                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );

    // return (
    //     <div className={classes.root}>
    //         <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
    //             <ExpansionPanelSummary
    //                 expandIcon={<ExpandMoreIcon />}
    //                 aria-controls="panel1bh-content"
    //                 id="panel1bh-header"
    //             >
    //                 {!isMobile && (
    //                     <div style={{ float: 'left', paddingRight: '20px' }}>
    //                         <BriefThumbnail brief={brief && brief.briefData ? brief.briefData : {}} size="md" />
    //                     </div>
    //                 )}
    //                 <span>
    //                     <Typography gutterBottom variant="h5" component="h2">
    //                         <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}>{brief.briefData.components[0].content.text}</span>
    //                     </Typography>
    //                     <Typography variant="body2" color="textSecondary" component="p">
    //                         <span> {moment(brief.briefData.created).format('MM-DD-YYYY')}</span>
    //                     </Typography>
    //                 </span>
    //                 <div style={{ position: 'absolute', right: '80px', top: '21px' }}>
    //                     <InviteExistingUser classes={classes} brief={brief} apiManager={apiManager} isMobile={isMobile} handleSave={handleSave} setIsEdit={setIsEdit} user={user}/>
    //                 </div>
    //                 <RemoveBrief brief={brief} apiManager={apiManager} handleDelete={e => handleDelete(brief)} classes={classes} />
    //             </ExpansionPanelSummary>
    //             <ExpansionPanelDetails>
    //                 <span style={{ width: '100%' }}>
    //                     <RenderBriefInvites brief={brief} apiManager={apiManager} updateAccess={updateAccess} refreshChild={refreshChild} isOpen={expanded === 'panel1'} />
    //                 </span>

    //             </ExpansionPanelDetails>
    //         </ExpansionPanel>
    //     </div>
    // );
}