import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Grid
} from "@material-ui/core";
const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, apiManager, funcs, data, ...rest } = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    password: "",
    confirm: ""
  });

  const [error, setError] = useState();

  const updatePassword = async () => {
    if (values.password !== values.confirm) {
      funcs.openModal(false, "Passwords don't match!");
      return;
    }
    if (values.password.length < 8) {
      funcs.openModal(
        false,
        "Passwords must be a minimum of 8 characters in length"
      );
      return;
    }

    await funcs.updatePassword(values);

    setValues({
      password: "",
      confirm: ""
    });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <div style={{ marginLeft: 24, marginBottom: 12 }}>
          <h3 style={{ fontSize: 20 }}>Password</h3>
          <span>Update your password</span>
        </div>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            {error}
          </Grid>
        </Grid>
        <CardContent style={{ marginLeft: 12, marginRight: 12 }}>
          <Grid container spacing={2} row>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Confirm password"
                name="confirm"
                onChange={handleChange}
                type="password"
                value={values.confirm}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: 16, marginBottom: 12 }}>
          <Button 
            variant="outlined" 
            onClick={updatePassword}
            className={values.password.length < 8 || (values.password !== values.confirm) ? "filter-disabled" : ''}>
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
