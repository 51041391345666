import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { faTrash, faArrowsAlt, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function InviteSuccess(props) {

 
  const { classes, updateComponent, isOpen } = props;

  const [text, handleText] = React.useState('Invites successfully sent.');

    const [open, setOpen] = React.useState(isOpen)
 
 

  const handleClose = () => {
    setOpen(false);
  };
 
  return (
    <span>
          
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invites sent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
            
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Close</span>
          </Button>

        </DialogActions>
      </Dialog>
    </span>
  );
}
