import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { faTrash, faArrowsAlt, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DowngradeModal(props) {
  const { classes, funcs, open, history, diff } = props;

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={funcs.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Let's do this!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Your free trial is about to end soon, so act now to keep using your unlimited version without interruption. Just click below to enter your payment details.</p>
              <p>Not ready? No worries. You'll automatically be downgraded to a basic account with limited features once your trial ends. Upgrade anytime via the Billing page. </p>
            </DialogContentText>
              
          </DialogContent>
          <DialogActions>
            <Button onClick={funcs.handleClose} color="primary">
            <span style={{fontWeight: 300}}>Cancel</span>
            </Button>
            <Button onClick={funcs.stripePaymentInfo} color="primary">
              Upgrade Now
            </Button>
          </DialogActions>
        </Dialog>
    );
}
