import React, { useState, useEffect } from "react";
import "../css/chart-data.scss";
import {
  Grid,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import LinearIndeterminate from "../../Briefs/components/LinearIndeterminate";
import BrandedCardTitle from "../../DataDetails/components/BrandedCardTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle, faMinusCircle } from "@fortawesome/pro-light-svg-icons";
import isEmpty from "lodash/isEmpty";
import { type } from "@amcharts/amcharts4/core";
export default function ChartData(props) {
  const {
    updateComponent,
    isOpen,
    handleClose,
    component,
    updateChartData,
    chartType,
    updateCustomChart,
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataInput, setDataInput] = useState([]);
  const [compareDataInput, setCompareDataInput] = useState([]);
  const [wordCloudList, setWordCloudList] = useState(component.content.dataPoints && typeof component.content.dataPoints === 'string' ? component.content.dataPoints : "");
  const isWordCloud = component.content.dataPoints && typeof component.content.dataPoints === 'string';
  const defaultData = { y: "", label: "" };
  const [compareChart, setCompareChart] = useState(false)

  //load initial data
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    //dataPoints are user entered primary series
    //custom data points are user entered comparison series
    //pmaDataPoints are system generated data points currently displayed, this is set in ChartDisplay, and is holder.data in container
    const { dataPoints, customDataPoints, pmaDataPoints, metrics } = component.content || "";
    console.log(customDataPoints)
    if (pmaDataPoints){
      if (!isEmpty(pmaDataPoints)) {
        let parsedCustomData = pmaDataPoints.map((row, index) => ({
          x: index,
          y:  typeof row[metrics] === 'number' ? row[metrics] : parseFloat(row[metrics].replace(/[$,]+/g,"")),
          label: row.product,
        }));
        setCompareDataInput(parsedCustomData)
        let userInputData = pmaDataPoints.map((row, index) => ({
          y:  row.compare ? typeof row.compare === 'number' ? row.compare : parseFloat(row.compare.replace(/[$,]+/g,"")) : ''
        }))
        setDataInput(userInputData);
        setCompareChart(true)
      }
    } else  {
      if (!isEmpty(dataPoints)) {
        if (chartType !== "word") {
          console.log(dataPoints)
          let parsedData = dataPoints.map((row, index) => ({
            x: row.x || index,
            y:  row.y && typeof row.y === 'number' ? row.y : parseFloat(row.y),
            label: row.label.replace(/ \(.*/, ""),
          }));
          setDataInput(parsedData);
        } else {
          setDataInput(dataPoints);
        }
      } else {
        setDataInput([defaultData, defaultData, defaultData]);
      }
    }
    setUnit(component.content.unit)
  };
  const handleDataInput = (e, index) => {
    let items = [...dataInput];
    let newItem = {
      ...items[index],
      [e.target.name]: e.target.value
    };
    items[index] = newItem;
    setDataInput(items);
  };


  const [unit, setUnit] = useState("number");
  const update = async () => {
    setIsLoading(true);
   
    let parsedData = dataInput;
    console.log(parsedData)
      if (isWordCloud) {
        parsedData = wordCloudList;
      } else {
          parsedData = dataInput && dataInput.map((row, index) => ({
              x: index,
              y: row && row.y && typeof row.y === 'number' ? row.y : parseFloat(row.y.toString().replace(/,/g, '')),
              label: compareChart ? compareDataInput[index]['label'] : row.label
          }));
          parsedData = parsedData.filter(point => {
              return point.y !== "" && point.label !== ''
          })
      }
      
    let updatedComponent = {
      ...component,
      content:{
        ...component.content,
        unit,
      }
    }
    let removeComparison = false;
    if(compareChart){
      let hasData = 0;
      //loop through parsedData and see if there are any values that aren't null
      for(let i=0; i < parsedData.length; i++){
        if(parsedData[i].y){
          console.log(parsedData[i], typeof parsedData[i])
          hasData++
        }
      }
      if(hasData > 0){
        updatedComponent.content.customDataPoints = parsedData;
        
      } else {
        delete updatedComponent.content.customDataPoints;
        removeComparison = true;
        parsedData = null;
      }
    }
    console.log('updated Component', updatedComponent)
    !compareChart ? await updateChartData(updatedComponent, parsedData, chartType) :
    updateCustomChart(updatedComponent, parsedData, removeComparison)
  };
  const handleTabNewRow = e => {
    if(e.key === "Tab" && e.target.id == dataInput.length){
      setDataInput([...dataInput, { y: "", label: "" }])
    }
  }
  const handleRemoveRow = (data, index) => {
    let dataRemoveRow = [...data]
    if(dataRemoveRow.length>1){
      dataRemoveRow.splice(index, 1);
    } 
    setDataInput(dataRemoveRow)
  }
  const handleRemoveCompare = () => {
    let userInputData = [];
    for(let i=0; i < compareDataInput.length; i++){
      userInputData.push({y: ''})
    }
    setDataInput(userInputData);
  }
  const renderDataInput = () => {
    return (
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
          className="viz templateContainer"
        >
          <div
            className="viz-cardTitle viz-cardTitle--data"
            style={{ marginBottom: 28 }}
          >
            Enter your data below
          </div>
          <form>
            <div style={{ paddingTop: 24 }}>
              <span style={{ marginRight: compareChart ? '30%' : 110}}>
                {compareChart ? "Compare your data to the Insite data" : 'Enter your labels below i.e. "Product A"'}
              </span>
              {!compareChart && <FormControl style={{marginBottom: 20}}>
                <InputLabel>Unit</InputLabel>
                <Select
                  autoWidth
                  value={unit}
                  onChange={e => setUnit(e.target.value)}
                >
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="percent">% Percent</MenuItem>
                  <MenuItem value="dollar">$ Dollars</MenuItem>
                  <MenuItem value="euro">€ Euros</MenuItem>
                  <MenuItem value="pound">£ Pounds</MenuItem>
                </Select>
              </FormControl>}
              {compareChart && 
                <Button
                  onClick={() => handleRemoveCompare()}
                  style = {{minWidth: '25px', paddingTop: '25px'}}
                >Remove your data  <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{marginLeft: 10}}
                    />
                  </Button>}
                   
            </div>
            {!compareChart && dataInput.map((dataRow, index) => (
              <>
                <TextField
                  key={`Label ${index + 1}`}
                  label={`Label ${index + 1}`}
                  value={dataRow.label}
                  onChange={e => handleDataInput(e, index)}
                  style={{ width: "63%", marginRight: 25, marginBottom: 20 }}
                  name="label"
                />
                <TextField
                  key={`Value ${index + 1}`}
                  label={`Value ${index + 1}`}
                  id={index + 1}
                  value={dataRow.y}
                  onChange={e => handleDataInput(e, index)}
                  style={{ width: "23%", textAlign: "center", marginBottom: 20 }}
                  name="y"
                  onKeyDown={handleTabNewRow}
                />
                <Button
                  onClick={() => handleRemoveRow(dataInput)}
                  style = {{minWidth: '25px', maxWidth: '25px', paddingTop: '25px'}}
                >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                    />
                  </Button>
              </>
            ))}
            {compareChart && compareDataInput.map((dataRow, index) => (
              <>
                <TextField
                  key={`Label ${index + 1}`}
                  label={`PMA Product ${index + 1}`}
                  value={dataRow.label}
                  //onChange={e => handleDataInput(e, index)}
                  style={{ width: "33%", marginRight: 25, marginBottom: 20 }}
                  name="label"
                  disabled
                />
                <TextField
                  key={`PMA Data ${index + 1}`}
                  label={`${component.content.metrics} ${index + 1}`}
                  id={index + 1}
                  value={dataRow.y}
                  //onChange={e => handleDataInput(e, index)}
                  style={{ width: "25%", textAlign: "center", marginBottom: 20, marginRight: 25 }}
                  name="y"
                  disabled
                  //onKeyDown={handleTabNewRow}
                />
                <TextField
                  key={`Value2 ${index + 1}`}
                  label={`Your Data ${index + 1}`}
                  id={index + 1}
                  value={dataInput[index] ?  dataInput[index].y : ''}
                  onChange={e => handleDataInput(e, index)}
                  style={{ width: "25%", textAlign: "center", marginBottom: 20 }}
                  name="y"
                  //onKeyDown={handleTabNewRow}
                />
              </>
            ))}
          </form>
          {!compareChart && <div className="addButtonHolder">
          <Button
            onClick={() => setDataInput([...dataInput, { y: "", label: "" }])}
            style={{ padding: '6px 10px 6px 0px' }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ paddingRight: 10 }}
              size={"2x"}
            />
            Add another row
          </Button>
          <Button
            onClick={() => handleRemoveRow(dataInput)}
            style={{ padding: '6px 10px 6px 0px' }}

          >
            <FontAwesomeIcon
              icon={faMinusCircle}
              size={"2x"}
              style={{ paddingRight: 10 }}
            />
            Remove last row
          </Button>
          </div>}
        </Grid>
      </DialogContent>
    );
  };
  const renderWordInput = () => {
    return (
      <DialogContent>
        <Grid
        //   container
        //   direction="row"
        //   alignItems="left"
        //   spacing={3}
        //   className="viz templateContainer"
        >
          <div className="viz-cardTitle viz-cardTitle--wordCloud">
            Update your text below
          </div>
          <form>
            <span>
              {/* Separate each phrase with a comma for proper word cloud charting */}
            </span>
            <TextField
              label={"Update/endter your text here"}
              value={wordCloudList}
              onChange={e => setWordCloudList(e.target.value)}
              fullWidth
              multiline
              variant="outlined"
              className="viz-wordCloud-input"
              rows={12}
              style={{ marginTop: 24 }}
              className="viz-wordCloud-input"
            />
          </form>
        </Grid>
      </DialogContent>
    );
  };
  return (
    <span>
      <Dialog
        open={isOpen}
        onClose={(e, reason) => {if (reason !== 'backdropClick') {handleClose()}}}
        aria-labelledby="form-dialog-title"
        className="branded"
        fullWidth
        maxWidth="sm"
      >
        <BrandedCardTitle title="Update Chart Data" />
        {isLoading && (
          <DialogContent>
            <LinearIndeterminate />
          </DialogContent>
        )}
        {/* {chartType !== "word" && renderDataInput()}
        {chartType === "word" && renderWordInput()} */}
        {isWordCloud && renderWordInput()}
        {!isWordCloud && renderDataInput()}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span style={{ fontWeight: 300 }}>Cancel</span>
          </Button>
          <Button onClick={update} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
