import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
import FavoriteBrief from '../../Modals/FavoriteBrief';
import BriefThumbnail from '../../Briefs/components/BriefThumbnail';
import InviteExistingUser from '../../Brief/components/InviteExistingUser'
import RegisterSnackBar from '../../SignIn/components/RegisterSnackBar';
import SubscriptionModal from '../../../components/SubscriptionModal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment  } from "@fortawesome/free-solid-svg-icons";
import CardMenu from './CardMenu'

const DEFAULT_SNACK = {
  isOpen: false,
  message: '',
  isSuccessfull: false
}

const useStyles = makeStyles({
  root: {
    width: 250,
    margin: 'auto',
  },
  media: {
    height: 300,
    maxWidth: 450,
    maxHeight: 350
  },
  content: {
    position: 'relative',
    top: '-300px',
    left: '450px',
    height: '0px'
  },
  icon:{
    fontSize: 14,
    marginRight: -6,
    marginTop: -2,
  },
});

export default function BriefCard(props) {

  const classes = useStyles();
  const { history, apiManager, deleteBrief, updateSharing, brief, refreshChild, isMobile, refreshParent, user, hideClass } = props;
  const data = props;

  const fav = brief.favorite;

  const [snack, setSnack] = useState(DEFAULT_SNACK);

 //  const mentions = brief.mentions && brief.mentions > 0 ? brief.mentions : false;

  const mentions = brief.comments && brief.comments > 0 ? brief.comments : false;

    const closeModal = async () => {
        setSnack({...snack, isOpen: false});
        refreshChild();
      }
    
      const openModal = async (isSuccessfull, message) => {
        
        setSnack({...snack, isOpen: true, isSuccessfull: isSuccessfull, message: message});
        refreshChild();
      }

  const [id, setId] = React.useState(brief._id);
  
  const updateBrief = async(share) => {
    brief.sharing = share;
    await updateSharing(brief);
  }
  const [inviteError, setInviteError] = useState('')
  const handleSave = async (invites) => {
    
    invites.id = id;
     
    const result = await apiManager.inviteUser(invites, id);
    if (result.msg){
      setInviteError(result.msg)
    } else {
      if(result.nonMemberEmails.length === 0){  
      openModal(true, 'User(s) have been invited!')
      }
      else if (result.nonMemberEmails.length !== 0 && result.memberEmails.length !== 0){ 
        openModal(false, `Some User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')} `)
      } else {
        openModal(false, `User(s) have NOT been invited! Please check the emails below and try again. ${result.nonMemberEmails.join(', ')}`)
      } 
    }
  }
 
  
  const { invite } = brief;
  let access = invite && invite.access ? invite.access : 'admin';
  const style = invite ? { display: 'block' } : { display: 'block' };
  const detailsClick = () => {
    data.detailsClick();
  }
 

const handlePublicSave = async (invites) => {
  invites.id = id;
   
  const result = await apiManager.invitePublicUser(invites, id);
  if(result){  
    openModal(true, 'User(s) have been sent the guest link!')
  }
  else { 
    openModal(false, 'User(s) have NOT been emailed! Please check the emails and try again. ')
  }
}


  return (
    <Card className={classes.root}>
      <div style={{position: 'relative'}}>
      <CardActionArea
        onClick={detailsClick}
      >
        <BriefThumbnail brief={brief} size="sq350"/>
 
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="h2">
          { brief.title }
          </Typography> */}
          {brief.Owner.firstname && 
            <div style = {{ fontSize: 'small', height: 30}}> {brief.Owner.firstname} {brief.Owner.lastname}</div>}
          {brief.Owner.firstName &&
            <div style = {{ fontSize: 'small', height: 30}}> {brief.Owner.firstName} {brief.Owner.lastName}</div>}
          <div style={{
              height: 42, 
              fontSize: 14,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient : 'vertical',
              textOverflow: 'ellipsis', 
              overflow:'hidden',
              fontWeight: 700,
              }}>
             {brief.title}
          </div><br />
          <div style={style}>
              {/*{ brief.Owner.account }< br />
              { brief.Owner.email }<br /> */}

              { invite && invite.access.length > 0 && invite.access !== 'admin' ? invite.access.toUpperCase() : 'OWNER' }
              </div>
        </CardContent>

      </CardActionArea> 
      <CardActions style={{padding: 0, marginBottom: 8}}>
          <div style={{display:'inline-block', width:140, marginLeft: 16}}>
            { brief.favorite ? brief.favorite.isFavorite : null }
            { moment(brief.updated).format('MMM D, YYYY') }
        </div>
        {/* <div style={{ display: 'inline-flex', paddingLeft: '50%', paddingRight: 18}}> */}
          <div style={{ width: '100%', textAlign: 'right' }}>
          <div style={{ display: 'inline-flex', paddingTop: 2 }}>
            {mentions  && (

                // <div style={{ display: 'inline-block', marginTop: 9 }}>
                <IconButton aria-label="close" className={classes.icon} onClick={detailsClick}>
 
                   {mentions}&nbsp;<FontAwesomeIcon icon={faComment} color={`var(--brand-color2)`} />
                  </IconButton>
                // </div>
              )}
             
              { access != 'viewer' ? 
          <InviteExistingUser classes={classes} history={history} brief={brief} apiManager={apiManager}  isMobile={isMobile} handleSave={handleSave} handlePublicSave={handlePublicSave} user={user}/>
              : null }
          <FavoriteBrief classes={classes} brief={brief} apiManager={apiManager} refreshChild={refreshChild} />

        {(!isMobile || (access === 'admin')) && (
          <CardMenu refreshParent={refreshParent} history={history} hideEdit={false} brief={brief} deleteBrief={deleteBrief} access={access} apiManager={apiManager} user={user}/>
        )}
          </div>
          </div>
      </CardActions>
              <RegisterSnackBar snack={snack} closeModal={closeModal} classes={classes} />
              {!brief.visible && (
                  <div className={hideClass} style={{ height: '103%' }}
                     onClick={() => setInviteError('You have reached your plan limit. Please upgrade to view/edit this brief.')}
                  ></div>
              )

              }
              {inviteError &&
                  <SubscriptionModal
                      open={Boolean(inviteError)}
                      message={inviteError}
                      onClose={() => setInviteError('')}
                      user={user}
                      modalTitle={"sharing"}
                  />}
    </div>
    </Card>
  );
}