import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uuidv4 from 'uuid/v4';
import ToolTip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Tag from '../../../components/Tags';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BriefTagToolTip = withStyles((theme) => ({
  tooltip:{
    backgroundColor: 'var(--brand-color2) !important'
  },
  arrow:{
   color: 'var(--brand-color2) !important'
  }
}))(ToolTip)
export default function TagAsset(props) {
  const [tags, setTags] = useState('');
  const [tagList, setTagList] = useState([]);
  const [oldTags, setOldTags] = useState([]);

  const [text, handleText] = useState('');
 

  const { asset, updateTags, open, handleTagClose } = props;

  useEffect(() => {
    
    handleClickOpen()
  },[])

  const handleClickOpen = () => {
 
    handleText('Separate tags with a comma')
    if(asset){
      try {
        
        // setTags(asset.tags.join(', '))
        // setTagList(asset.tags);
      }
      catch(ex){
        setTags('')
        setTagList([])
      }
    }
  };

  useEffect(() =>{
    try{
      if(asset && asset.tags){
        setTagList(asset.tags)
        setOldTags(asset.tags)
      }
    }catch(ex){
      setTags('')
      setTagList([])
    }
  },[open])

  const handleTagInput = input => {
    const tl = input.split(',');
    const tlclean = tl.filter(tag => tag.trim() !== '');
    setTags(tl.join(','));
    // now put the array back together
    if(input.slice(-1)==','){
      setTagList(arr => [...arr, tlclean.at(-1)]);
    }
  }

  const removeTag = tagToDelete => {
        const newTagList = tagList.filter(tag => tag.trim() !== tagToDelete.trim());
        setTagList(newTagList);
        setOldTags(newTagList)
        setTags(tags.replace(tagToDelete+",",''))
  }

 

  const updateAsset = async () => {
      const tagsClean = tags.replace(',,', ',').replace(', ,',',');
      let output = [];
      if(tagsClean.indexOf(',') > -1 ){
          output = tagsClean.split(',');
          output = output.map(function(value){
              return value.trim();
          })
      }
      else {
          output.push(tagsClean)
      }
      if(output.at(-1) == ""){
        output.pop()
      }
      asset.tags = oldTags.concat(output);
    await updateTags(asset); 
    handleClose()
  };

  const handleClose = () => { 
    setTags('')
    handleTagClose()
  }

  return (
      <Dialog open={open} 
        onClose={handleTagClose} aria-labelledby="form-dialog-title" className="add-tags" 
        fullWidth 
        maxWidth='xs'>
        <DialogTitle id="form-dialog-title" >
            <span className="add-tags__title">Add Tags</span>
            <BriefTagToolTip title="Adding tags allows you to search and categorize your assets. Enter multiple tags by separating them with a comma." arrow placement="top-start">
              <Button>
                <FontAwesomeIcon size={'md'} icon={faQuestionCircle} className="add-tags__icon" />
              </Button>
            </BriefTagToolTip>
            <div className="add-tags__subhead">Separate tags with a comma</div>
        </DialogTitle>
        <DialogContent>
          <TextField  
            className="add-tags__input"
            id="tags"
            label="Enter Tags"
            type="text"
            fullWidth
            value={tags}
            multiline
             // rows='4'
            onChange={e => handleTagInput(e.target.value)}
          /><br /><br /> 
        
            <div className="add-tags__tag-display">
                <div style={{fontWeight: 600, marginBottom: 12}}>Tags for this asset:</div>
                {tagList && tagList.map(tagName => (
                    <div style={{display: 'inline-block'}} key={uuidv4()}>
                    <Tag tagName={tagName} removeTag={removeTag} className="add-tags__chip"/>
                    </div>
                ))} 
            </div>
        
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              <span className="add-tags__cancel">Cancel</span>
          </Button>
          <Button onClick={updateAsset} color="primary" className="add-tags__update">
            Update
          </Button>
        </DialogActions>
      </Dialog>
  );
}
