import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import defaultImage from '../../../images/Breefly_2020-Icon_150x150_Blue.png';
import { BREEFLY_ICON } from '../../../constants';
const defaultImage = BREEFLY_ICON;

const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  media: {
    height: 300,
    maxWidth: 450,
    maxHeight: 350
  },
  content: {
    position: 'relative',
    top: '-300px',
    left: '450px',
    height: '0px'
  }
});

export default function BriefThumbnail(props) {
//   const classes = useStyles();
  const { brief, image } = props;

  const size = props.size ? props.size : 'sm';
  const sizes = {
      'sm':  {
        height: 50,
        imgHeight: 50,
        width: 75,
        paddingLeft: 15,
        paddingTop: 0,
        borderBottom: 'none'
      },
      'md': {
        height: 100,
        imgHeight: 100,
        width: 150,
        paddingLeft: 30,
        paddingTop: 0,
        borderBottom: 'none'
      },
      'sq350' : {
        height: 200,
        imgHeight: 50,
        width: '100%',
        paddingLeft: '35%',  
        paddingTop: '50px', 
        borderBottom: '1px solid #e8e8e888'
      }
  }
  const getImageFromComponents = (components) => {

    const images = components ? components.filter(function(component, index){ 
        return component && index !== 0 &&  component.type === 1; 
    }) : [];
  
    const image = images.length > 0 ? `${images[0].content.url}` : null;
    //return ( image && (image.includes('.png') || image.includes('.jpg')) ? image : null)
    return image;
  }
 
  let firstPhotoUrl = image ? image : brief ? getImageFromComponents(brief.components) : null;
  if(firstPhotoUrl == "null"){
    firstPhotoUrl = null;
  }

  const pl = firstPhotoUrl ? 0 : sizes[size].paddingLeft;
  const pt = firstPhotoUrl ? 0 : sizes[size].paddingTop;
//   const imageStyle = firstPhotoUrl ? {objectFit: 'cover'} : null;
  const bsize = firstPhotoUrl ? 'cover' : '30%';    // was 45%

  const style = {
    url: firstPhotoUrl ? firstPhotoUrl : defaultImage,
    display: 'inline-block', 
    overflow: 'hidden', 
    height: sizes[size].height, 
    width: sizes[size].width, 
    paddingLeft: pl, 
    paddingTop: pt,  
    marginRight: 20,
    backgroundImage: `url(${firstPhotoUrl ? firstPhotoUrl : defaultImage })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: bsize,
    backgroundPosition: 'center',
    borderBottom: sizes[size].borderBottom
  }
  const id = brief && brief._id ? brief._id : '';

  const renderLinkImage = () => {
    return  (<a href={`/brief/details/${id}`}> 
    <div style={style}>
       
    </div>
    </a>)
  }

const renderImage = () => {
  return  ( 
  <div style={style}>
     
  </div>
  )
  }
 
  
  return (
    id.length > 0 ? renderLinkImage() : renderImage() 
    );
}