import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import Table from "@material-ui/core/Table";
//import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//import RemoveUser from "./RemoveUser";
//import LoadingIndicator from "../../../components/LoadingIndicator";
//import PaginationRounded from "../../../components/PaginationRounded";
import BriefThumbnail from "../../Briefs/components/BriefThumbnail";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RowMenu from "./RowMenu";
import { IconButton } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  titleCell: {
    width: "45%",
    "@media (max-width: 1225px)": {
      width: "40%"
    },
    "@media (max-width: 1005px)": {
      width: "35%"
    },
    "@media (max-width: 850px)": {
      width: "30%"
    },
    "@media (max-width: 500px)": {
      width: "calc(100% - 42px)",
      minWidth: 150
    }
  }
}));
/*eslint-disable eqeqeq*/  

const PersonPanel = props => {
  const classes = useStyles();
  const { 
  person,
  apiManager,
  //hideDetails,
  //handleDelete,
  updateAccess,
  //refreshChild,
  isOpen } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userBriefs, setBriefs] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [forcer, forceUpdate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);


  const getBriefs = async () => {
    setIsLoading(true);
    const response = await apiManager.getUserSharesByAuthor({
      skip: 0,
      limit: 50,
      search: "",
      email: person.email
    });
    if (response) {
      const { briefs, count } = response;
      let briefData = [];
      //setTotalItems(count);
      //setPageCount(  count > 1 ? Math.ceil(count/limit) : 1);

      briefs && briefs.forEach(ub => {
        ub.selected = false;
        briefData.push(ub);
      });
      setBriefs(briefData);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if(isOpen && !userBriefs){
      getBriefs();
    }
  },[isOpen, userBriefs])
  // if (isOpen && !init && !userBriefs) {
  //   setInit(true);
    
  // }

  const executeBulkSelection = async access => {
    await Promise.all(
      userBriefs.map(async invite => {
        const data = invite && invite.briefData ? invite.briefData : {};

        if (invite && invite.selected) {
          invite.brief = data._id;
          invite.id = data._id;

          invite.access = access;

          await updateAccess(invite);
        }
      })
    );

    setAnchorEl(null);
    await getBriefs();
  };

  const executeSelection = async (access, brief) => {
    if (brief) {
      person.brief = brief._id;
      person.id = brief._id;
    }
    person.access = access;

    await updateAccess(person);
    //setAnchorEl(null);
    //refreshChild()
    await getBriefs();
    //refreshChild()
  };

  const handleCheck = name => event => {
    if (name === "checkAll") {
      const value = !checkAll;
      setCheckAll(value);
      userBriefs.forEach(ub => {
        ub.selected = value;
      });
    } else {
      //setCheckAll(false);
      userBriefs.forEach(ub => {
        if (ub._id == name) {
          ub.selected = !ub.selected;
        }
      });
      forceUpdate(!forcer);
    }
  };

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const renderBriefs = () => {
    if(isLoading) {
      return(<div style={{width: '80%', margin: '4px auto'}}>
        <LinearProgress />
      </div>)
    }
    if (!userBriefs) {
      return (<div>There are no longer any briefs shared with this user.</div>);
    }
    return (
      <span>
        This user has access to the following briefs:
        <br />
        <br />
        <span>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }}>
                  <div style={{ fontSize: 12 }}>Select All</div>
                  <Checkbox
                    name="checkAll"
                    onChange={handleCheck("checkAll")}
                    value={checkAll}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="left" style={{ width: 190 }}></TableCell>
                <TableCell align="left" className={classes.titleCell}>
                  Brief Title
                </TableCell>
                <TableCell align="left">Publish Date</TableCell>
                <TableCell align="left">Access</TableCell>
                <TableCell align="left">
                  {" "}
                  <div
                    style={{
                      float: "right",
                      marginRight: 8,
                      position: "relative"
                    }}
                  >
                    <IconButton
                      aria-label="close"
                      className={classes.icon}
                      size="small"
                      onClick={openMenu}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} color={"primary"} />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            {userBriefs.map((brief, index) => (
              <TableRow key={Math.random()}>
                <TableCell align="left" style={{ width: 50, paddingLeft: 8 }}>
                  <Checkbox
                    name={brief._id}
                    checked={brief && brief.selected}
                    onChange={handleCheck(brief._id)}
                    value="checkAll"
                    color="primary"
                  />
                </TableCell>
                <TableCell align="left" style={{ width: 190 }}>
                  <BriefThumbnail
                    brief={brief && brief.briefData ? brief.briefData : {}}
                    size="md"
                  />
                </TableCell>
                <TableCell align="left">
                  <div style = {{ 
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      "-webkit-line-clamp": "1",
                      "-webkit-box-orient": "vertical"}}>
                    {brief.briefData
                      ? brief.briefData.components[0].content.text
                      : "Deleted"}
                  </div>
                </TableCell>
                <TableCell align="left" style={{ width: 120 }}>
                  {moment(brief.created).format("MM-DD-YYYY")}
                </TableCell>
                <TableCell align="left" style={{ width: 120, textTransform: 'capitalize' }}>
                  {brief.access == "admin" ? "Co-Owner" : brief.access}
                </TableCell>
                <TableCell align="left">
                  <RowMenu
                    executeSelection={executeSelection}
                    classes={classes}
                    brief={brief && brief.briefData ? brief.briefData : {}}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>

          <Menu
            id="people-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem
              onClick={e => executeBulkSelection("delete")}
              className={classes.menuitem}
            >
              Remove Access
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("viewer")}
              className={classes.menuitem}
            >
              Viewer
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("editor")}
              className={classes.menuitem}
            >
              Editor
            </MenuItem>
            <MenuItem
              onClick={e => executeBulkSelection("admin")}
              className={classes.menuitem}
            >
              Co-Owner
            </MenuItem>
          </Menu>
        </span>
      </span>
    );
  };

  return (
    renderBriefs()
  );
}
export default PersonPanel;
