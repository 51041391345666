import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { user, apiManager, updateUser, isMobile, refreshChild, layout: Layout, component: Component, settings, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout user={user} isMobile={isMobile} apiManager={apiManager}>
          <Component {...matchProps} 
                user={user} 
                apiManager={apiManager} 
                updateUser={updateUser} 
                isMobile={isMobile} 
                refreshChild={refreshChild}
                settings={settings}
                />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  user: PropTypes.object,
  updateUser: PropTypes.func
};

export default RouteWithLayout;
