import React from 'react'; 
  
import AddPhoto from '../../Modals/AddPhoto';  
import AddVideo from  '../../Modals/AddVideo';   
import ChangeImage from './ChangeImage' 
import ChangeVideo from './ChangeVideo' 
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/styles'; 

import {IconButton, Typography} from '@material-ui/core';
 
// import { faFont, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faFont, faChartPie, faLink, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },'@media (max-width: 500px)': {
        width: 55,
       },
    },
    expanded: {},
  })(MuiExpansionPanel);

  const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiExpansionPanelSummary);
  
  const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
      paddingLeft: 23,
      display: 'block',
      '@media (max-width: 500px)': {
        paddingLeft: 2,
       },
    },
  }))(MuiExpansionPanelDetails);

const AddPanel = ({ classes, isMobile, funcs  }) => {
    const [expanded, setExpanded] = React.useState(false);

    const { addParagraph, addImage, addVideo, addChart, addLink, updateImage, isEditImageOpen, closeChangeImage, isEditVideoOpen } = funcs;

    const handleExpansionChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    const closeExpansion = () => {
        if (isMobile) {
         setExpanded(false);
        }
    }

    const clickedAddParagraph = event => {
        
        closeExpansion();
        addParagraph(event);
    }

    const clickedAddChart = event => {
        closeExpansion();
        addChart(event);
    }

    const clickedAddLink = event => {
        closeExpansion();
        addLink(event);
    }

    const fixedStyle = isMobile ? {position: 'fixed', left: '0', bottom: 'calc(0vh + 6px)'} : {position: 'fixed', left: '0', top: '25%' };
  return (<div style={fixedStyle}>        
    <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleExpansionChange('panel1')}>
    <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
    <IconButton aria-label="close" >
                 <FontAwesomeIcon icon={faPlusCircle} />
    </IconButton> 
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
        
            <IconButton aria-label="close"  onClick={clickedAddParagraph}>
                 <FontAwesomeIcon icon={faFont} />
    </IconButton>
    <Typography>&nbsp;</Typography>
     <AddPhoto  handleSave={addImage} closePanel={closeExpansion} classes={classes}></AddPhoto>
     <div>&nbsp;</div>
     <AddVideo  handleSave={addVideo} closePanel={closeExpansion} classes={classes}></AddVideo>
     <div>&nbsp;</div>
     
    <IconButton aria-label="close" closePanel={closeExpansion} onClick={clickedAddChart}>
    <FontAwesomeIcon icon={faChartPie} />
    </IconButton>
    <div>&nbsp;</div>
    <IconButton aria-label="close" closePanel={closeExpansion} onClick={clickedAddLink}>
    <FontAwesomeIcon icon={faLink} />
    </IconButton>
    
            </ExpansionPanelDetails>
          </ExpansionPanel>
    
           <AddPhoto handleSave={updateImage} classes={classes} isOpen={isEditImageOpen} handleClose={closeChangeImage} replace />    
           <AddVideo handleSave={updateImage} classes={classes} isOpen={isEditVideoOpen} handleClose={closeChangeImage} replace />    
    </div>)
    
  }
  export default AddPanel