import React from 'react'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  Grid,
  Button,
  IconButton,
  TextField 
} from '@material-ui/core'; 
import EditIcon from '@material-ui/icons/Edit';

export default function EditBriefCase(props) {
  const [open, setOpen] = React.useState(false);
  const { classes, brief } = props;
  //const [title, setTitle] = React.useState(''); 
  //const [tags, setTags] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    switch(event.target.name){
      case 'title': 
        //setTitle(event.target.value)
        break;
        case 'tags': 
        //setTags(event.target.value)
        break; 
      default:
        break;
    } 
    
  }

  return (
    <div>
      <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{minWidth: '500px'}}>
        <DialogTitle id="form-dialog-title">Edit your briefcase</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
       <Grid
      item
      lg={12}
      sm={12}
      xl={12}
      xs={12}
    >
         <TextField
              className={classes.textField} 
              fullWidth 
              value={brief.name}
              label="Title"
              name="title"
              onChange={handleChange}
              type="text" 
              variant="outlined"
              required
            />
        </Grid>
        <Grid
      item
      lg={12}
      sm={12}
      xl={12}
      xs={12}
    >
         <TextField
              className={classes.textField} 
              fullWidth 
              label="Tags"
              name="tags"
              onChange={handleChange}
              type="text" 
              variant="outlined"
              required
            />
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          <span style={{fontWeight: 300}}>Cancel</span>
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
